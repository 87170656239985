import React, { useEffect } from 'react';
import './Popup.css';
import './CreatenewPassword.css';
import { connect } from "react-redux";
import { changeUserPassword, otpFlag, passwrdVerify } from "../../redux/user/user.action";
import { measurementUnit } from "../../redux/user/user.action";


const SelectMeasurementUnits = (props) => {
    const [password, setPassword] = React.useState('');
    const [oldPassword, setOldPassword] = React.useState('');
    const [email, setEmail] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [passAlert, setPssAlert] = React.useState(false);
    const [newPasswordDiv, setNewPasswordDiv] = React.useState(false);
    const [confirmAccount, setConfirmAccount] = React.useState(true);
    const [incorrectMsgDiv, setIncorrectMsgDiv] = React.useState(false);
    const [age, setAge] = React.useState('');
    const [unit, setUnit] = React.useState('');


    useEffect(() => {
        if (props.passwrdResposne === "Correct Paswword") {
            setIncorrectMsgDiv(false);
        } else if (props.passwrdResposne === "Incorrect password") {
            setConfirmAccount(true);
            setIncorrectMsgDiv(true);
        }
    }, [props.passwrdResposne]);

    useEffect(() => {
        var fetchedUnit = localStorage.getItem("mu");
        setUnit(fetchedUnit);
    }, []);


    const onSaveUnit = () => {
        props.measurementUnit({
            unit: unit,
            profileId: props.id
        });
        props.onMeasureClose()
    }

    const handleChange = (event) => {
        setUnit(event.target.value);
    };

    return (
        <>
            {confirmAccount ?
                <div className="PopuupContainer">
                    <div className="MeasurementContainer" >
                        <h1 className="cnfrmac-img">
                            <i style={{ paddingRight: "20px" }} class="fa fa-balance-scale"></i>
                            Select the measuring units</h1>
                        <div style={{ display: "flex", flexDirection: "row" }}>

                            <select
                                style={{cursor:'pointer'}}
                                onChange={handleChange}
                                className="dropdown-popup" >
                                <option selected={unit === "Imperial"} value="Imperial">Imperial (ft, lbs)</option>
                                <option selected={unit === "Metric"} value="Metric">Metric &nbsp; (cm, kg)</option>
                            </select>


                            <div className="measurebuttonbutn" onClick={onSaveUnit}>
                                <button className="btnpopup" style={{ outline: "none" }}>save
                                </button>

                            </div>
                        </div>
                    </div>
                </div> : null}

        </>
    )
}
const mapStateToProps = (store) => {
    return {
        id: store.user.user && store.user.user.id,
        emailOtpMatch: store.user.emailOtpMatch,
        passwrdResposne: store.user.passwrdResposne,
        updatedUnitFlag: store.therapyGoals.updatedUnitFlag,
    };
};

export default connect(mapStateToProps, { changeUserPassword, otpFlag, passwrdVerify, measurementUnit })(SelectMeasurementUnits);
