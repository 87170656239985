



import React, { useState} from "react";


import {  useHistory } from "react-router-dom";
import { connect,useDispatch } from "react-redux";




import ".././styles.css";
import welcome from "../../../../assets/img/welcome.png";
import mail from "../../../../assets/img/mail.png";

import left from  "../../../../assets/img/Left Accessory.png"; 
import smartphone from  "../../../../assets/img/smartphone (1).png"; 


import FooterSign from "../../../../components/Footers/FooterSign";

import '../../../../assets/styles/font.css';
import CustomSnackbar from "../../../../components/Alertmessage/AlertMsg";
import LodingIcon from "../../../../components/LoadingIcon";
import Box from '@mui/material/Box';

import { OTP_RESPONSE_EMAIL_FORGOT, FORGET_GET_OTP} from '../../../../redux/action.list'



const Forgotpassword = (props) => {
  
 

const [values, setValues] = React.useState({});

const [openSnack, setOpenSnack] = React.useState(false);
const [snackSeverity, setSnackSeverity] = React.useState('error');
const [snackMsg, setSnackmsg] = React.useState('');
const [anchororigin, setanchorOrigin] = useState({
  vertical: 'top',
  horizontal: 'center',
});
var history = useHistory();
const inputRefs = React.useRef([React.createRef(), React.createRef()]);
const [counter, setCounter] = React.useState(60);
const [resendOtpConditaion, setresendotpCondition] = React.useState("resendotp");
const [clickNextButton, SetClickNextButton] = React.useState(false);
const dispatch = useDispatch();






const handleSnackbar = () => {
  setOpenSnack(false);
  props.resetLoginErrorMsg();
};






return (
  <>
    <CustomSnackbar
      flag={openSnack}
      onClose={handleSnackbar}
      anchorOrigin={anchororigin}
      severity={snackSeverity}
      msg={snackMsg}
    />
    <div className="wrapperContainer">

      <div className="lg:flex">
        <div className="flex-1 leftSide">
          <div className="imgWrapper">
            <img src={welcome} className="image" alt="" />
          </div>
          <div className="LoginLeftContentWrapper py-16">
            Get Connected. Stay Informed. <br /> Collaborate.
          </div>
        </div>
        <div className="flex-1 rightSide">
          <div className="text_1">
            <div className="innerText">
              Welcome

              <div className="innerText2">
                <span style={{ color: "#6a768f" }}>to</span> Arc Connect
              </div>
            </div>
          </div>
          <div className="innerText3 fnt24P" >
                Reset Password
                </div>
          <div className="where1 fnt24P" >
                Where should the verification code be sent to?
                </div>


                
                  {/* <div className="inputFieldHeightWidthAdjustment"> 
         
          <div className="flex flex-wrap">
           <div className="w-full lg:w-full">  
           <div className="innerText4">
          <Button onClick={handleoption2} href='/restorepasswordwithemail'><img src={mail} alt="my image"  /> 
                  &nbsp; Email  address
                </Button>
                </div>
                </div>

              </div>

            </div>   */}
            
            <div className="nextButtonnnnOuter flex flex-wrap nextbutn">
                    <div
                      className="nextButtonnnn1 fnt32P  textinimageforgot w-full lg : w-full"
                      type="button"
                     
                      onClick={() =>
                         {
                          dispatch({
                            type: OTP_RESPONSE_EMAIL_FORGOT,
                            payload: {
                              OtpResponseEmailforgot: false,
                            },
                          })
                          history.push('/restorepasswordwithemail')}
                      }

                    ><div><img width={"30px"} src={mail} ></img></div>{clickNextButton ?
                       <Box sx={{
                        display: 'flex', width: "40px", height: "29px", 
                      }}>  <LodingIcon /></Box> : "Email Address"}<div></div>
                    </div>
                    </div> 
                    <div className="nextButtonnnnOuter1">
                    <div className="nextButtonnnnOuter flex flex-wrap nextbutn">
                    <div
                      className="nextButtonnnn1 fnt32P  textinimageforgot w-full lg : w-full"
                      type="button"
                     
                      onClick={() =>{
                        dispatch({
                          type: FORGET_GET_OTP,
                          payload: {
                            otpSent1: false,
                            otpToken1: '',
                            forgetOtp: '',
                          },
                        });
                         history.push('/restorepasswordwithphone')
                      }
                      }
                    ><div><img width={"20px"} src={smartphone} ></img></div>
                      
                      {clickNextButton ? <Box sx={{
                        display: 'flex', width: "40px", height: "29px"
                      }}>  <LodingIcon /></Box> : "Text Message"}<div></div>
                    </div>
                    
                   </div>
                   <div>
                    <div className="centeredLink" >
                      <a variant="contained" href="/" >
                        Login
                      </a>
                    </div>
                 </div>
                    </div> 
                   

                    


            

              
            
         
        </div>
      </div>
      
    </div>
    <div className="">
      <FooterSign />
    </div>
  </>
);
};
const mapStateToProps = (store) => {
return {
  msg: store.invitation.msg,
  otpSent: store.user.otpSent,
  otpToken: store.user.otpToken,
  user: store.user.user,
  isMatch: store.user.isMatch,
  loginOtp: store.user.loginOtp,
  loginErrorMsg: store.user.loginErrorMsg,
  otpexpireerror: store.user.otpexpireerror,
  resendotpcodelogin: store.user.resendotpcodelogin,
};
};
export default connect(mapStateToProps, {



})(Forgotpassword);
