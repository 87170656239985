import React from "react";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";

import Divider from "@material-ui/core/Divider";
// components
import { connect } from "react-redux";
import {
  fetchInvitation,
  updateProfile,
  updateProfilePic,
} from "../../redux/invitation/components/invitation.action";
import "../styles/cardClinicMiddle.css";
import { TableBody } from "@material-ui/core";
import userIcon from "../../assets/icons/userProfileIcon.svg";
import '../../assets/styles/font.css';
const CardClinicPicture = (props) => {
  return (
    <>
      <div
        className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg"
        style={{ marginTop: "80px" }}
      >
        <div className="block w-full overflow-x-auto">
          <div
            style={{
              position: "absolute",
              backgroundColor: "#f2f4f8",
              zIndex: "1",
              width: "100%",
              overflowX: "hidden",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="titleforActiveadmin fnt24P">
                Active Care Site Admin list :
              </div>

              <div
                className="countAdminNumberUserProfile fnt24P"
                style={{ padding: "16px 5px", fontWeight: "bold" }}
              >
                {props.totalCountofCLinicAdmin
                  ? props.totalCountofCLinicAdmin
                  : 0}
              </div>
            </div>
          </div>
          <Divider className="DividerforCard" />

          <div style={{ height: "220px" }}>
            {props.activeCareSiteAdmin && props.activeCareSiteAdmin.length ? (
              <Table className="items-center w-full ">
                <TableBody>
                  {props.activeCareSiteAdmin.map((item) => {
                    return (
                      <TableRow>
                        <TableCell
                          className=""
                          style={{ borderBottom: "none !important" }}
                        >
                          {item.photo_uuid ? (
                            <Avatar
                              src={`https://ucarecdn.com/${item.photo_uuid}/`}
                            />
                          ) : (
                            <Avatar src={userIcon} />
                          )}
                        </TableCell>
                        <TableCell>
                          <div className="emaildata fnt21P">{item.email}</div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <p
                className="fnt24P"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: "20px 0px",
                  color: '#6A768F',
                }}
              >
                There are no Care Site Admins{" "}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    invitationList: store.invitation.invitationList,
    clinicItem: store.invitation.clinicProfileUpdate,
    activeCareSiteAdmin: store.invitation.activeCareSiteAdmin,
    totalCountofCLinicAdmin: store.invitation.totalCountofCLinicAdmin,
  };
};

export default connect(mapStateToProps, {
  updateProfile,
  updateProfilePic,
  fetchInvitation,
})(CardClinicPicture);
