/*eslint-disable*/
import React from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { fetchInvitation } from "../../redux/invitation/components/invitation.action";
import { getAcceptSharing } from "../../redux/sharing/sharing.action";
import { logIn, pageHeading, loadProfile } from "../../redux/user/user.action";
import { selectedUserChat, selectedGroupChat } from "../../redux/quickbloxmessaging/quickblox.action"
import "./styles.css";
import HelpDesk from "../../assets/img/helpdesk.svg";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Invitation from "../../assets/img/group_add_black_24dp.svg";
import Notification from "../../assets/img/Notification.svg";
import Message from "../../assets/img/Message.svg";
import Report from "../../assets/img/report.svg";
import Dashboard from "../../assets/img/dashboard.svg";
import ARCLogo from "../../assets/img/Updated logo.png";
import DashboardPNG from "../../assets/icons/dashboard_white_24dp.png";
import invitationPNG from "../../assets/icons/invitation.png";
import messagePNG from "../../assets/icons/message.png";
import notificationPNG from "../../assets/icons/notification.png";
import reportPNG from "../../assets/icons/report.png";
import Badge from "@mui/material/Badge";
import "../../../src/assets/styles/font.css";

const useStyles = makeStyles((theme) => ({
  helpcenter: {
    backgroundColor: "#F26930",
    paddingLeft: "62px",
    paddingRight: "62px",
    boxShadow: "#6A768F80 0px 3px 6px",
    borderColor: "transparent",
    textTransform: "capitalize",
    color: "white",
    border: "none",
    "&:hover": {
      backgroundColor: "#F26930",
      boxShadow: "#6A768F80 0px 3px 6px",
      border: "none",
      color: "white",
      borderColor: "transparent",
      textTransform: "capitalize",
    },
  },
  icons: {
    minWidth: "0px",
    backgroundColor: "white",
    boxShadow: "#6A768F80 0px 3px 6px",
    border: "none",
    borderColor: "transparent",
    "&:hover": {
      backgroundColor: "#f26930",
      boxShadow: "#6A768F80 0px 3px 6px",
      color: "white!important",
    },
  },
}));

const Sidebar = (props) => {
  const classes = useStyles();
  const [collapseShow, setCollapseShow] = React.useState(false);
  const history = useHistory();

  const [dashboardColor, setDashBoardColor] = React.useState("#F26930");
  const [notificationColor, setNotificationColor] = React.useState("white");
  const [messageColor, setMessageColor] = React.useState("white");
  const [reportColor, setReportColor] = React.useState("white");
  const [invitationColor, setInvitationColor] = React.useState("white");
  const [colorCombo, setColorCombo] = React.useState("#6A768F");
  const [dashboardText, setDashBoardText] = React.useState("");
  const [notificationText, setNotificationText] = React.useState("");
  const [reportsText, setReportsText] = React.useState("");
  const [dashboardIcon, setDashBoardIcon] = React.useState(DashboardPNG);
  const [notificationIcon, setNotificationIcon] =
    React.useState(notificationPNG);
  const [reportsIcon, setReportsIcon] = React.useState(reportPNG);
  const [messagesIcon, setMessagesIcon] = React.useState(messagePNG);
  const [invitationIcon, setInvitationIcon] = React.useState(invitationPNG);

  const dispatch = useDispatch();

  function dashboard() {
    setDashBoardIcon(DashboardPNG);
    setDashBoardColor("#F26930");
    setNotificationColor("white");
    setReportColor("white");
    setMessageColor("white");
    setInvitationColor("white");
    setDashBoardText("Dashboard");
    setReportsText("");
    setNotificationText("");
    dispatch(pageHeading("Dashboard"));
    history.push("/app");
    setColorCombo("#36434B");
    setInvitationIcon(invitationPNG);
    setReportsIcon(reportPNG);
    setNotificationIcon(notificationPNG);
    setMessagesIcon(messagePNG);
    setCollapseShow(false);
  }
  function notification() {
    setDashBoardIcon(Dashboard);
    setInvitationIcon(invitationPNG);
    setReportsIcon(reportPNG);
    setNotificationIcon(Notification);
    setMessagesIcon(messagePNG);
    setNotificationColor("#F26930");
    setReportColor("white");
    setMessageColor("white");
    setInvitationColor("white");
    setDashBoardColor("white");
    setNotificationText("Notifications");
    setDashBoardText("");
    setReportsText("");
    history.push("/app/notificationSummary");
    setColorCombo("#36434B");
    setCollapseShow(false);
    dispatch(pageHeading("Notifications"));
  }
  function reports() {
    setDashBoardIcon(Dashboard);
    setReportsIcon(Report);
    setMessagesIcon(messagePNG);
    setInvitationIcon(invitationPNG);
    setNotificationIcon(notificationPNG);
    setReportColor("#F26930");
    setMessageColor("white");
    setInvitationColor("white");
    setDashBoardColor("white");
    setNotificationColor("white");
    setReportsText("Reports");
    setNotificationText("");
    setDashBoardText("");
    setColorCombo("#36434B");
    dispatch(pageHeading("Patients"));
    history.push("/app/Reports");
    setCollapseShow(false);
  }
  function messages() {
    setDashBoardIcon(Dashboard);
    setReportsIcon(Report);
    setReportsIcon(reportPNG);
    setMessagesIcon(Message);
    setInvitationIcon(invitationPNG);
    setNotificationIcon(notificationPNG);
    setMessageColor("#F26930");
    setInvitationColor("white");
    setDashBoardColor("white");
    setNotificationColor("white");
    setReportColor("white");
    dispatch(pageHeading("Messages"));
    history.push("/app/chat");
    setColorCombo("#36434B");
    setCollapseShow(false);
    props.selectedUserChat(null)
    props.selectedGroupChat(null)

  }
  function invitation() {
    setDashBoardIcon(Dashboard);
    setReportsIcon(reportPNG);
    setMessagesIcon(messagePNG);
    setInvitationIcon(Invitation);
    setNotificationIcon(notificationPNG);
    setInvitationColor("#F26930");
    setDashBoardColor("white");
    setNotificationColor("white");
    setReportColor("white");
    setMessageColor("white");
    setCollapseShow(false);
    dispatch(pageHeading("Invitations"));
    props.user.role === "abmadmin"
      ? history.push("/app/invitation")
      : props.user.role.toLowerCase() === "clinicadmin"
        ? history.push("/app/clinicadmin")
        : props.user.role.toLowerCase() === "superadmin"
          ? history.push("/app/satable")
          : props.user.role.toLowerCase() === "clinician"
            ? history.push("/app/cliniciantable")
            : "";
  }

  function helpfunc() {
    setDashBoardIcon(Dashboard);
    setReportsIcon(reportPNG);
    setMessagesIcon(messagePNG);
    setInvitationIcon(invitationPNG);
    setNotificationIcon(notificationPNG);
    history.push("/app/help");
    setInvitationColor("white");
    setDashBoardColor("white");
    setNotificationColor("white");
    setReportColor("white");
    setMessageColor("white");
    dispatch(pageHeading("Help Center"));
    setCollapseShow(false);
  }

  let unreadMsgCount = 0;
  if (props.allChats.length > 0) {
    for (let i = 0; i <= props.allChats.length - 1; i++) {
      unreadMsgCount += props.allChats[i].unread_messages_count;
    }
  }



  return (
    <>
      <nav className="fixed top-0 md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden bg-white flex flex-wrap items-center justify-between md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch  md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <div style={{ display: "block", justifyContent: "flex-start" }}>
          {!collapseShow && (
              <button
                className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                type="button"
                onClick={() => setCollapseShow(true)}
              >
                <i className="fas fa-bars"></i>
              </button>
            )}
            
            {collapseShow && (
              <button
                className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                type="button"
                onClick={() => setCollapseShow(false)}
              >
                <i className="fas fa-times"></i>
              </button>
            )}

            {collapseShow ? (
              <div
                className=" md:hidden"
                style={{
                  background: "rgb(242, 244, 248)",
                  width: "224px",
                  padding: "16px",
                }}
              >
                {/* dashboard */}
                <div
                  style={{ display: "flex" }}
                  className="notificationDiv"
                  onClick={() => {
                    dashboard();
                  }}
                >
                  <div>
                    <Button
                      className={classes.icons}
                      style={{
                        backgroundColor:
                          props.sideBarActive === "Dashboard"
                            ? "#F26930"
                            : "white",
                      }}
                    >
                      <img
                        src={
                          props.sideBarActive === "Dashboard"
                            ? DashboardPNG
                            : Dashboard
                        }
                        height="12px"
                        width="12px"
                      />
                    </Button>
                  </div>
                  <div className="notification-collapseBar">Dashboard </div>
                </div>

                {/* notification */}
                {/*props.user.role === "clinicadmin" ? (*/}
                {props.userRole.role === "clinicadmin" ||
                props.userRole.role === "clinician" ? (
                  <div
                    className="messageDiv"
                    onClick={() => {
                      notification();
                    }}
                  >
                    <div>
                      <Button
                        className={classes.icons}
                        style={{
                          backgroundColor:
                            props.sideBarActive === "Notifications"
                              ? "#F26930"
                              : "white",
                        }}

                      >

                        <img
                          src={
                            props.sideBarActive === "Notifications"
                              ? Notification
                              : notificationPNG
                          }
                          height="12px"
                          width="12px"
                        />
                      </Button>
                    </div>

                    <div className="message-collapseBar">Notifications</div>
                  </div>
                ) : null}

                {/* reports */}
                {props.userRole.role === "clinicadmin" ||
                  props.userRole.role === "clinician" ? (
                  <div
                    className="messageDiv"
                    onClick={() => {
                      reports();
                    }}
                  >
                    <div>
                      <Button
                        className={classes.icons}
                        style={{
                          backgroundColor:
                            props.sideBarActive === "Patients"
                              ? "#F26930"
                              : "white",
                        }}
                      >

                        <img
                          src={
                            props.sideBarActive === "Patients"
                              ? Report
                              : reportPNG
                          }
                          className="sidebarIconWrapper"
                          height="12px"
                          width="12px"
                        />
                      </Button>
                    </div>
                    <div className="message-collapseBar">Patients</div>
                  </div>
                ) : null}

                {/* messages */}
                <div
                  className="messageDiv"
                  onClick={() => {
                    messages();
                  }}
                >
                  <div>
                    <Button
                      className={classes.icons}
                      style={{
                        backgroundColor:
                          props.sideBarActive === "Messages"
                            ? "#F26930"
                            : "white",
                      }}
                    >
                      <img
                        src={
                          props.sideBarActive === "Messages"
                            ? Message
                            : messagePNG
                        }
                        height="12px"
                        width="12px"
                      />
                    </Button>
                  </div>
                  <div className="message-collapseBar">Messages</div>
                  <Badge
                    badgeContent={unreadMsgCount}
                    sx={{
                      "& .MuiBadge-badge": {
                        color: "white",
                        backgroundColor: "#f26930",
                        position: "inherit",
                      },
                    }}
                  ></Badge>
                </div>

                {/* invitation */}
                <div
                  className="messageDiv"
                  onClick={() => {
                    invitation();
                  }}
                >
                  <div>
                    <Button
                      className={classes.icons}
                      style={{
                        backgroundColor:
                          props.sideBarActive === "Invitations"
                            ? "#F26930"
                            : "white",
                      }}
                    >
                      <img
                        src={
                          props.sideBarActive === "Invitations"
                            ? Invitation
                            : invitationPNG
                        }
                        height="12px"
                        width="12px"
                      />
                    </Button>
                  </div>
                  <Badge
                    badgeContent={
                      props.pendingInvitation &&
                        props.pendingInvitation.total_count && props.pendingInvitation.total_count !== '0'
                        ? props.pendingInvitation.total_count
                        : null
                    }
                    color="success"
                  >
                    <div className="message-collapseBar">Invitations</div>
                  </Badge>
                </div>

                {/* help desk */}
                {props.userRole.role === "clinicadmin" ||
                  props.userRole.role === "clinician" ? (
                  <div className="helpDeskChip-collapsebar">
                    <Button
                      className={classes.helpcenter}
                      onClick={() => {
                        helpfunc();
                      }}
                    >
                      Help Center
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className="container">
            <div className="abmImgDiv">
              <img src={ARCLogo} alt=" " className="abmLogoImg" />
            </div>
            {localStorage.getItem("isAssociatedClinic") == 0 ?
              null : <div
                className="notificationDiv"
                onClick={() => {
                  dashboard();
                }}
              >
                <div>
                  <Button
                    id="mybutton"
                    style={{
                      backgroundColor:
                        props.sideBarActive === "Dashboard" ? "#F26930" : "",
                    }}


                    className={classes.icons}
                  >
                    <img
                      src={
                        props.sideBarActive === "Dashboard"
                          ? DashboardPNG
                          : Dashboard
                      }
                      className="sidebarIconWrapper"
                    />
                  </Button>
                </div>
                <div className="notification fnt24P">Dashboard</div>
              </div>}
            {localStorage.getItem("isAssociatedClinic") == 0 ? null : 
            
            props.userRole.role === "clinicadmin" ||
            props.userRole.role === "clinician" ? (
              <div
                className="notificationDiv"
                onClick={() => {
                  notification();
                }}
              >
                <div>
                  <Button
                    id="mybutton"
                    style={{
                      backgroundColor:
                        props.sideBarActive === "Notifications" ? "#F26930" : "",
                    }}

                    className={classes.icons}
                  >
                    <img
                      src={
                        props.sideBarActive === "Notifications"
                          ? Notification
                          : notificationPNG
                      }
                      className="sidebarIconWrapper"
                    />
                  </Button>
                </div>

                <div className="notification fnt24P">Notifications</div>
              </div>
            ) : null}

            {props.userRole.role === "clinicadmin" ||
              props.userRole.role === "clinician" ? (
              <div
                className="notificationDiv"
                onClick={() => {
                  reports();
                }}
              >
                <div>
                  <Button
                    id="mybutton"
                    style={{
                      backgroundColor:
                        props.sideBarActive === "Patients" ? "#F26930" : "",
                    }}

                    className={classes.icons}
                  >
                    <img
                      src={
                        props.sideBarActive === "Reports" ? Report : reportPNG
                      }
                      className="sidebarIconWrapper"
                    />
                  </Button>
                </div>
                <div className="notification fnt24P">Patients</div>
              </div>
            ) : null}

            <div
              className="notificationDiv"
              onClick={() => {
                messages();
              }}
            >
              <div>
                <Button
                  id="mybutton"
                  style={{
                    backgroundColor:
                      props.sideBarActive === "Messages" ? "#F26930" : "",
                  }}

                  className={classes.icons}
                >
                  <img
                    src={
                      props.sideBarActive === "Messages" ? Message : messagePNG
                    }
                    className="sidebarIconWrapper"
                  />
                </Button>
              </div>
              <div className="notification fnt24P">Messages</div>
              <Badge
                badgeContent={unreadMsgCount}
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "#f26930",
                    position: "inherit",
                  },
                }}
              ></Badge>
            </div>

            <div
              className="notificationDiv"
              onClick={() => {
                invitation();
              }}
            >
              <div>
                <Button
                  id="mybutton"
                  style={{
                    backgroundColor:
                      props.sideBarActive === "Invitations" ? "#F26930" : "",
                  }}
                  className={classes.icons}
                >
                  <img
                    src={
                      props.sideBarActive === "Invitations"
                        ? Invitation
                        : invitationPNG
                    }
                    className="sidebarIconWrapper"
                  />
                </Button>
              </div>

              <div className="notification fnt24P">Invitations</div>
              <Badge
                badgeContent={
                  props.pendingInvitation && props.pendingInvitation.total_count && props.pendingInvitation.total_count !== '0'
                    ? props.pendingInvitation.total_count
                    : null
                }
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "#f26930",
                    position: "inherit",
                  },
                }}
              ></Badge>
            </div>

            {props.userRole.role === "clinicadmin" ||
              props.userRole.role === "clinician" ? (
              <div className="helpImgDiv">
                <img src={HelpDesk} alt=" " width="200px" height="140px" />
              </div>
            ) : null}

            {props.userRole.role === "clinicadmin" ||
              props.userRole.role === "clinician" ? (
              <div className="helpDeskChip">
                <Button
                  className={classes.helpcenter}
                  onClick={() => {
                    helpfunc();
                  }}
                >
                  Help Center
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </nav>
    </>
  );
};
const mapStateToProps = (store) => {
  return {
    user: store.user.user,
    userRole: store.user.user,
    sideBarActive: store.user.sideBarRoles,
    pendingInvitation: store.user.pendingInvitation,
    allChats: store.quickblox.allChats,
    isAssociatedClinic: store.user.isAssociatedClinic
  };
};
export default connect(mapStateToProps, {
  logIn,
  fetchInvitation,
  getAcceptSharing,
  loadProfile,
  selectedGroupChat, selectedUserChat
})(Sidebar);
