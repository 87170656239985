import React, { useEffect } from "react";
import "./CardSettings.css";
import InputField from "../../components/Inputs_Validation";

import { useParams } from 'react-router-dom';
// components

import { connect } from "react-redux";

import {
  loadOtherUserProfileForViewing,
  againappearpopup
} from "../../redux/invitation/components/invitation.action";
import { pageHeading } from "../../redux/user/user.action";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { } from "../../redux/user/user.action";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import LoadingIcon from '../../components/LoadingIcon'
const useStyles = makeStyles((theme) => ({
  avt1: {
    height: "150px",
    width: "150px",
    objectFit: "fill",
  },

}));

const NonPatientUsercard = ({
  OtherUserProfile,

  loadOtherUserProfileForViewing,
  pageHeading,
  againappearpopup
}) => {

  const { id } = useParams();
  const [phoneValue, setPhoneValue] = React.useState(OtherUserProfile && OtherUserProfile.phone);
  const [lodingprofile, setLoadingProfile] = React.useState(false)



  useEffect(() => {
    pageHeading('User Info');
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  }, []);





  useEffect(() => {
    if(!OtherUserProfile){
    // console.log('id in user info',id,OtherUserProfile)
    loadOtherUserProfileForViewing(id)
    }
  }, []);




  return (
    <>

      {Object.keys(OtherUserProfile && OtherUserProfile).length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
          <LoadingIcon />
        </div>
      ) : (
        <div className="containers">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="UserprofileUpdated">
              <div>
                {lodingprofile === true ? <div>
                  <LoadingIcon />
                </div> : <div>
                  {OtherUserProfile && OtherUserProfile.photo_uuid ? (
                    <div>
                      <Avatar
                        src={`https://ucarecdn.com/${OtherUserProfile.photo_uuid}/`}
                        // className={classes.avt1}
                        className="userProfile-image"
                        style={{cursor:'default'}}
                      />
                    </div>
                  ) : (
                    <Avatar
                      src={require("../../assets/img/user1.svg").default}
                      // className={classes.avt1}
                      className="userProfile-image"
                      style={{ cursor: "default" }}
                      backgroundSize='cover'
                      backgroundPosition='top center'
                    // className="userProfile-image"
                    />
                  )}
                </div>
                }
              </div>
            </div>
          </div>
          <form>
            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block lable-text text-blueGray-600 text-md font-bold mb-2"
                    // htmlFor="grid-password"
                    style={{ color: "#858fa4", fontWeight: "800" }}
                  >
                    User name
                  </label>
                  <InputField
                    type="text"
                    className="uname"
                    disabled={true}
                    validation="required"
                    name="user_name"
                    defaultValue={
                        OtherUserProfile && OtherUserProfile.user_name
                        ? OtherUserProfile.user_name
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block lable-text text-blueGray-600 text-md font-bold mb-2"
                    // htmlFor="grid-password"
                    style={{ color: "#858fa4" }}
                  >
                    First name
                  </label>
                  <InputField
                    id="fNameId"
                    disabled={true}
                    type="text"
                    className="classfortextfieldinuserdetailsleft"
                    validation="required|max:35|alphabetsOnly"
                    name="first_name"
                    defaultValue={
                        OtherUserProfile && OtherUserProfile.first_name
                        ? OtherUserProfile.first_name
                        : ""
                    }

                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block lable-text text-blueGray-600 text-md font-bold mb-2"
                    // htmlFor="grid-password"
                    style={{ color: "#858fa4" }}
                  >
                    Last name
                  </label>
                  <InputField
                    pr={3}
                    m={2}
                    id="lNameId"
                    disabled={true}
                    type="text"
                    className="lname"
                    validation="required|max:35|alphabetsOnly"
                    name="last_name"
                    defaultValue={
                        OtherUserProfile && OtherUserProfile.last_name
                        ? OtherUserProfile.last_name
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md font-bold mb-2 "
                    // htmlFor="grid-password"
                    style={{ color: "#78909C" }}
                  >
                    Address line 1
                  </label>
                  <InputField
                    type="text"
                    disabled={true}
                    className="classfortextfieldinuserdetailsleft"
                    validation="required|max:50"
                    name="Addressline1"

                    defaultValue={
                        OtherUserProfile &&
                        OtherUserProfile.address &&
                        OtherUserProfile.address.Addressline1
                        ? OtherUserProfile.address.Addressline1
                        : ""
                    }

                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md font-bold mb-2"
                    // htmlFor="grid-password"
                    style={{ color: "#78909C" }}
                  >
                    Address line 2
                  </label>
                  <InputField
                    id="lNameId"
                    type="text"
                    disabled={true}
                    className="lname"
                    validation="required|max:50"
                    name="Addressline2"
                    defaultValue={
                        OtherUserProfile &&
                        OtherUserProfile.address &&
                        OtherUserProfile.address.Addressline2
                        ? OtherUserProfile.address.Addressline2
                        : ""
                    }

                  />
                </div>
              </div>
            </div>

            <div className="flex flex-wrap mt-3 cardsetting">




              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md font-bold mb-2"
                    // htmlFor="grid-password"
                    style={{ color: "#78909C" }}
                  >
                    Country
                  </label>

                  <div>
                  <InputField
                      type="text"
                      className="classfortextfieldinuserdetailsleft"
                      disabled={true}
                      validation="required|alphabetsOnly|max:35"
                      name="country"
                      defaultValue={
                        OtherUserProfile &&
                        OtherUserProfile.address &&
                        OtherUserProfile.address.country
                          ? OtherUserProfile.address.country
                          : ""
                      }
                    />
                  </div>

                </div>

              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md font-bold mb-2"
                    // htmlFor="grid-password"
                    style={{ color: "#78909C" }}
                  >
                    State
                  </label>
                    <InputField
                      // id="cityId"
                      type="text"
                      disabled={true}
                      className="lname"
                      validation="required|alphabetsOnly|max:35"
                      name="state"

                      defaultValue={
                        OtherUserProfile &&
                        OtherUserProfile.address &&
                        OtherUserProfile.address.state
                          ? OtherUserProfile.address.state
                          : ""
                      }

                    />
                  

                </div>
              </div>
            </div>

            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-md font-bold mb-2 "
                    //   htmlFor="grid-password"
                      style={{ color: "#78909C" }}
                    >
                      City
                    </label>

                    <InputField
                      type="text"
                      className="classfortextfieldinuserdetailsleft"
                      disabled={true}
                      validation="required|alphabetsOnly|max:35"
                      name="city"
                      defaultValue={
                        OtherUserProfile &&
                        OtherUserProfile.address &&
                        OtherUserProfile.address.city
                          ? OtherUserProfile.address.city
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block text-blueGray-600 text-md font-bold mb-2"
                    //   htmlFor="grid-password"
                      style={{ color: "#78909C" }}
                    >
                      Zip
                    </label>
                    <InputField
                      // id="countryId"
                      disabled={true}
                      className="lname"
                      type="text"
                      validation="required|alphanumeric|min:3|max:9"
                      name="Zip"
                      defaultValue={
                        OtherUserProfile &&
                        OtherUserProfile.address &&
                        OtherUserProfile.address.Zip
                          ? OtherUserProfile.address.Zip
                          : ""
                      }

                    />
                  </div>
                </div>
            </div>


            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md font-bold mb-2"
                    // htmlFor="grid-password"
                    style={{ color: "#78909C" }}
                  >
                    Phone
                  </label>
                  <PhoneInput
                    className="PhoneinputForUserProfile"
                    international
                    value={OtherUserProfile.phone}
                    placeholder=""
                    inputProps={{
                      name: "phonenumber",
                      required: true,
                    }}
                    disabled={true}
                    onChange={setPhoneValue}
                    inputStyle={{
                      width: "100%",
                      borderTop: "none",
                      borderBottom: "1px solid",
                      background: "transparent",
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blue-600 text-md font-bold mb-2"
                    // htmlFor="grid-password"
                    style={{ color: "#78909C" }}
                  >
                    Email
                  </label>
                  <InputField
                    type="text"
                    className="lname disabledinput"
                    disabled={true}
                    value={OtherUserProfile.email}
                    name="email"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

      {/* )} */}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    OtherUserProfile:store.invitation.OtherUserProfile
  };
};

export default connect(mapStateToProps, {
  loadOtherUserProfileForViewing,
  pageHeading,
  againappearpopup
})(NonPatientUsercard);

