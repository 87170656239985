import {
  SIGNUP_GET_OTP,
  SIGNUP_OTP_MATCHES,
  SIGNUP_OTP_DOESNOT_MATCHES,
  SIGNUP_ERROR,
  INVITE_LINK_VERIFICATION,
  RESET_ALL_STATE,
  OTP_RESEND_REGISTER,
  OTP_EXPIRE_REGISTER
} from '../action.list';

let defaultState = {
  signupotpSent: false,
  signupotpToken: null,
  signupemail: null,
  signuppicture: {},
  signupisMatch: false,
  signupuser: null,
  isNotMatch: false,
  signuperror: null,
  otp: null,
  linkVerifyingFlag: false,
  linkVerifiedMsg: '',
  resendotpregister: '',
  otpexpireregister : false,
};

export const registrationReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SIGNUP_GET_OTP:
      return { ...state, ...payload };
    case SIGNUP_OTP_MATCHES:
      return { ...state, ...payload };
    case SIGNUP_OTP_DOESNOT_MATCHES:
      return { ...state, ...payload };
    case SIGNUP_ERROR:
      return { ...state, ...payload };
    case INVITE_LINK_VERIFICATION:
      return { ...state, ...payload };
    case RESET_ALL_STATE:
      return defaultState;
    case OTP_RESEND_REGISTER:
      return { ...state, ...payload }
    case OTP_EXPIRE_REGISTER:
      return { ...state, ...payload }
    default:
      return state;
  }
};