import {
  GET_ALL_INVITATION_LIST,
  GET_EACH_ITEM,
  GET_ALL_CLINIC_LIST,
  GET_ALL_PATIENT_LIST,
  ADDING_INVITAION_OR_CLINICNAME,
  RESET,
  INVITE_POP_UP_OPEN,
  UPDATE_CLINIC_PROFILE,
  FILTER_OPEN_POPUP,
  INVITING_NEWCLINIC,
  LOGIN_USER_INCOGNO,
  MSG,
  REGISTER_ALERT_MSG,
  VERIFYUSER_GET_OTP,
  OTP_RESEND_NEWUSER,
  REGISTER_ERROR_MSG,
  VERIFY_NEW_USER_ERROR_MSG,
  NEWUSER_OTP_DOESNOT_MATCHES,
  NEWUSER_OTP_MATCHES,
  OTP_EXPIRE_NEWUSER,
  ADDING_CLINIC_ERROR_MSG,
  CHECK_ENTERED_EMAIL,
  PICTURE_UPDATED_ALERT_MSG,
  PROFILE_UPDATE_ALERT_MSG,
  PROFILE_UPDATE_ERROR_MSG,
  USER_PROFILE_UPDATED_RESULT,
  CLINIC_USER_UPDATE_ALERT_MSG,
  CLINIC_USER_UPDATE_ERROR_MSG,
  CLINIC_PROFILE_UPLOAD_PICTURE,
  GET_CLINIC_DETAILS,
  ADDING_CLINIC_ALERT_MSG,
  ADDING_CLINIC,
  USER_PROFILE_UPLOAD_RESULT,
  OTHERUSER_PROFILE_UPLOAD_RESULT,
  HELP_CENTER_RESULT,
  EXITING_ACCOUNTNUMBER,
  HELP_DESK_ALERT_MSG,
  HELP_DESK_ERROR_MSG,
  ACTIVE_CLINIC_ADMIN,
  ACTIVE_CLINIC,
  SEARCH_INVITATION,
  FILTER_INVITATION,
  RESEND_INVITE_RES,
  ACCEPT_INVITE,
  REJECT_INVITE,
  SEARCH_FILTER_BOOLEAN,
  PAGINATED_INVITATION_LIST,
  GET_PATIENT_LIST,
  INACTIVE_PATIENT_ALERT,
  GET_PATIENT_ID,
  ACTIVE_INACTIVE_RES_STATUS_PAT_PROF,
  SUPERADMIN_DASHBOARD_COUNT,
  PAGINATED_SUPERADMIN_DASHBOARD_CARESITE_LIST,
  RESET_ALL_STATE,
  TOTAL_SENT_RECEIVED_INVITATION_COUNT,
  INVITATION_FLAG_VALUE,
  IF_DONT_HAVE_ANYC_CLINIC
} from "../../action.list";
let defaultState = {
  invitationList: [],
  inviteItem: null,
  //changes
  clinicList: [],
  // clinicItem : null,
  allcliniclist: [],
  //changes
  patientList: [],
  addUserInvitation: [],
  flagInput: false,

  //popup
  openPopUp: false,

  clinicProfile: [],
  clinicProfileUpdate: [],
  //FILTER POPUP
  filterPopup: false,
  loginIncogno: [],

  //invite alert popup
  msg: null,

  //Inviting for new clinic
  invitingClinicList: [],

  //updation
  updateuserdetails: [],

  personalClinic: [],

  //alert msg for update clinic user details
  clinicuserUpdateMsg: null,
  clinicuserErrorMsg: null,
  addingClinicSuccessAlert: "",
  invitingClinicList: [],

  //register alert message
  registerAlertMessage: null,

  //register error message
  registerErrorMessage: null,
  // profilePic alert message
  profilePicUpdateAlertMessage: null,
  // profile alert message
  profileUpdateAlertMessage: "",
  // profile error message
  profileUpdateErrorMessage: null,

  // adding clinic error msg
  addingclinicerrormsg: null,

  // email  verification from backend
  checkingEmail: null,
  checkingExistingEmail: null,
  flagEmail: false,

  flageUserProfile: false,
  UserProfileUpdatedData: {},
  UserProfileUpdated: [],
  OtherUserProfile:[],

  ClinicProfileUpdate: {},
  addingClinicAlert: "",

  checkingAccountnumber: null,
  flagAccountnumber: false,
  registerEmailFlag: false,
  clinicProfileUpdateResponse: null,

  //active care site admin by dhanush modifiled by poovarasan
  activeCareSiteAdmin: [],
  totalCountofCLinicAdmin: 0,

  //active clinic site by dhanush
  activeClinic: [],
  activeClinicRowCount: 0,

  //search and filter by dhanush
  searchInvitation: [],
  filterinvitation: [],
  searchfilterboolean: false,

  resendInviteMsg: "",
  invitationActionFlag: false,
  paginatedInvitationList: [],
  getPatientStat: [],
  patientIdFromRed: '',

  activeInactiveStatusSnack: false,
  invitationFlag: false,
  invitationFlagUserAlreadyExist: false,
  superadminDashboardCountList: {},
  superadminCareSiteList: [],
  total_received_invitation: "0",
  total_sent_invitation: "0",
  invitationFlagValue: {},
  sentInvitationFlagValue: {},
  if_dont_have_any_clinic : []
};
export const invitation = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_INVITATION_LIST:
      return { ...state, ...payload };
    case GET_EACH_ITEM:
      return { ...state, ...payload };
    case GET_ALL_CLINIC_LIST:
      return { ...state, ...payload };
    case GET_ALL_PATIENT_LIST:
      return { ...state, ...payload };
    case ADDING_INVITAION_OR_CLINICNAME:
      return { ...state, ...payload };
    case RESET:
      return { ...state, ...payload };
    case INVITE_POP_UP_OPEN:
      return { ...state, ...payload };
    case UPDATE_CLINIC_PROFILE:
      return { ...state, ...payload };
    case FILTER_OPEN_POPUP:
      return { ...state, ...payload };
    case INVITING_NEWCLINIC:
      return { ...state, ...payload };
    case CHECK_ENTERED_EMAIL: {
      return { ...state, ...payload };
    }
    case LOGIN_USER_INCOGNO:
      return { ...state, ...payload };
    case MSG:
      return { ...state, ...payload };
    case REGISTER_ALERT_MSG:
      return { ...state, ...payload };
    case VERIFY_NEW_USER_ERROR_MSG:
        return { ...state, ...payload };
    case VERIFYUSER_GET_OTP:
      return { ...state, ...payload };
    case OTP_RESEND_NEWUSER  :
      return { ...state, ...payload };
    case NEWUSER_OTP_DOESNOT_MATCHES  :
        return { ...state, ...payload };
    case OTP_EXPIRE_NEWUSER  :
        return { ...state, ...payload };
    case NEWUSER_OTP_MATCHES  :
        return { ...state, ...payload };
    case REGISTER_ERROR_MSG:
      return { ...state, ...payload };
    case ADDING_CLINIC_ALERT_MSG:
      return { ...state, ...payload };

    case LOGIN_USER_INCOGNO:
      return { ...state, ...payload };
    case MSG:
      return { ...state, ...payload };
    case REGISTER_ALERT_MSG:
      return { ...state, ...payload };
    case REGISTER_ERROR_MSG:
      return { ...state, ...payload };
    case PICTURE_UPDATED_ALERT_MSG:
      return { ...state, ...payload };

    case PROFILE_UPDATE_ERROR_MSG:
      return { ...state, ...payload };
    case ADDING_CLINIC_ERROR_MSG:
      return { ...state, ...payload };
    case PROFILE_UPDATE_ALERT_MSG:
      return { ...state, ...payload };
    case USER_PROFILE_UPDATED_RESULT:
      return { ...state, ...payload };
    case CLINIC_USER_UPDATE_ALERT_MSG:
      return { ...state, ...payload };
    case CLINIC_USER_UPDATE_ERROR_MSG:
      return { ...state, ...payload };
    case CLINIC_PROFILE_UPLOAD_PICTURE:
      return { ...state, ...payload };
    case USER_PROFILE_UPLOAD_RESULT:
      return { ...state, ...payload };
    case OTHERUSER_PROFILE_UPLOAD_RESULT:
      return { ...state, ...payload };
    case ADDING_CLINIC:
      return { ...state, ...payload };
    case HELP_CENTER_RESULT:
      return { ...state, ...payload };
    case EXITING_ACCOUNTNUMBER: {
      return { ...state, ...payload };
    }
    case UPDATE_CLINIC_PROFILE:
      return { ...state, ...payload };
    case HELP_DESK_ALERT_MSG:
      return { ...state, ...payload };
    case HELP_DESK_ERROR_MSG:
      return { ...state, ...payload };
    case ACTIVE_CLINIC_ADMIN:
      return { ...state, ...payload };
    case ACTIVE_CLINIC:
      return { ...state, ...payload };
    case SEARCH_INVITATION:
      return { ...state, ...payload };
    case FILTER_INVITATION:
      return { ...state, ...payload };
    case RESEND_INVITE_RES:
      return { ...state, ...payload };
    case ACCEPT_INVITE:
      return { ...state, ...payload };
    case REJECT_INVITE:
      return { ...state, ...payload };
    case SEARCH_FILTER_BOOLEAN:
      return { ...state, ...payload };
    case PAGINATED_INVITATION_LIST:
      return { ...state, ...payload };
    case GET_PATIENT_LIST:
      return { ...state, ...payload };
    case INACTIVE_PATIENT_ALERT:
      return { ...state, ...payload };
    case GET_PATIENT_ID:
      return { ...state, ...payload };
    case ACTIVE_INACTIVE_RES_STATUS_PAT_PROF:
      return { ...state, ...payload };
    case SUPERADMIN_DASHBOARD_COUNT:
      return { ...state, ...payload };
    case PAGINATED_SUPERADMIN_DASHBOARD_CARESITE_LIST:
      return { ...state, ...payload };
    case TOTAL_SENT_RECEIVED_INVITATION_COUNT:
      return { ...state, ...payload };
    case INVITATION_FLAG_VALUE:
      return { ...state, ...payload };
    case IF_DONT_HAVE_ANYC_CLINIC :
      return {...state , ...payload};
    case RESET_ALL_STATE:
      return defaultState;

    default:
      return state;
  }
};
