import apiAbmBlaze, {
  UPDATE_DEVICE_SETTINGS,
  LOAD_DEVICE_SETTINGS,
  ADD_NOTE,
  FETCH_NOTE,
} from "../../api";
import {
  NUMBER_OF_THERAPYS,
  FETCH_NOTE_DATA,
  LOAD_THERAPIES,
} from "../action.list";

//signup without registration link
export const loadDevice = (val) => async (dispatch) => {
  dispatch({
    type: LOAD_THERAPIES,
    payload: {
      loadTherapise: [],
    },
  });
  if (val) {
    apiAbmBlaze
      .post(LOAD_DEVICE_SETTINGS, {
        pairId: val,
      })
      .then(async (res) => {
        if (res.status === 200) {
          await dispatch({
            type: LOAD_THERAPIES,
            payload: {
              loadTherapise: res.data.response.rows,
            },
          });
        }
      });
  }
};

export const updateDevice = (val) => async (dispatch) => {
  return new Promise((success,fail)=>{
    apiAbmBlaze
    .post(UPDATE_DEVICE_SETTINGS, {
      therapyDuration: val.therapyDuration ? val.therapyDuration : "",
      days_of_week: val.days_of_week ? val.days_of_week : "",
      pairId: val.pairId ? val.pairId : "",
      profile_Id: val.profileId ? val.profileId : "",
      created_by: val.created_by ? val.created_by : "",
    })
    .then(res=>{
      if (res.status === 200) {
        dispatch(loadDevice(val.pairId));
      }
      success(res)
    })
    .catch(e=>{
      fail(e)
    })
  })
};

export const addNote = (val) => async (dispatch) => {
  apiAbmBlaze
    .post(ADD_NOTE, {
      addnote: val.value ? val.value : "",
      profile_id: val.profile_id ? val.profile_id : "",
    })
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: FETCH_NOTE_DATA,
          payload: {
            fetchnote: { note: val.value },
          },
        });
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
    });
};

export const fetchNotedata = (val) => async (dispatch) => {
  if (val) {
    apiAbmBlaze
      .get(`${FETCH_NOTE}?profile_id=${val}`)
      .then(async (res) => {
        if (res.data) {
          await dispatch({
            type: FETCH_NOTE_DATA,
            payload: {
              fetchnote: res.data[0],
            },
          });
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }
};
