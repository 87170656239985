import React, { useEffect } from "react";
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@mui/material/DialogContent';
import '../styles/inActivePopUp.css';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './style.css';
import Button from "@material-ui/core/Button";
import { Cancel } from "@mui/icons-material";
import { Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import apiAbmBlaze, { EDIT_PATIENT_ID } from '../../api';
import { set_updated_patient } from '../../redux/user/user.action';
import { useDispatch } from "react-redux";


const Tags = ({ data, handleDelete }) => {
    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                padding: "0.4rem",
                margin: "0 0.5rem 0 0",
                justifyContent: "center",
                alignContent: "center",
                background: "#e1ecf4",
                borderRadius: "6px",
                color: "#000000f2",
                border: "1px solid #00000042",
            }}
        >
            <Stack direction='row' gap={1}>
                <Typography>{data}</Typography>
                <Cancel
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        handleDelete(data);
                    }}
                />
            </Stack>
        </Box>
    );
};

function InputTags(props) {
    const [tags, SetTags] = useState([]);
    const tagRef = useRef();
    const [inputerror,setinputerror] = useState({val:false,msg:""})

    useEffect(() => {
        if (props.patient_id)
            SetTags(props.patient_id)
    }, [props.patient_id]);

    const handleDelete = (value) => {
        const newtags = tags.filter((val) => val !== value);
        SetTags(newtags);
        props.updateTags(newtags)
    };

    const handleOnSubmit = (e) => {
        // e.preventDefault();
        console.log('in here',!tagRef.current.value)
        if(inputerror.val===false){
        SetTags([ tagRef.current.value]);
        
        props.updateTags([ tagRef.current.value])
        // editPatientId()
        tagRef.current.value = "";
    }
    };
    useEffect(()=>{
        // console.log('heree',props.clickSubmit,'tagRef.current.value.length',tagRef.current.value.length)
        if(props.clickSubmit===true){
            if(!tagRef.current.value)
        {setinputerror({val:true,msg:"Enter a Patient ID  to continue."})}
        else{
            handleOnSubmit();
        }
           props.setClickSubmit(false);
        
    }
    },[props.clickSubmit])

    return (
        <Box sx={{ flexGrow: 1 }}>
            
                <TextField
                    inputRef={tagRef}
                    fullWidth='xl'
                    variant='standard'
                    size='small'
                    sx={{ margin: "1rem 0" }}
                    margin='none'
                    placeholder={tags.length < 2 ? "Enter patient id" : ""}
                    defaultValue={props.patient_id}
                    onChange={(value) => {
                        console.log('value.target.value',value.target.value)
                        if(!value.target.value)
                        {
                            setinputerror({val:true,msg:"Enter a Patient ID  to continue."})
                        }
                        else if (value.target.value&&value.target.value.length>25)
                           {
                           setinputerror({val:true,msg:"Must be less than 25 characters."})
                           }
                        else
                        {
                            setinputerror({val:false,msg:""});
                        }
                    }}
                    InputProps={{
                        // startAdornment: (
                        //     <Box sx={{ margin: "0 0.2rem 0 0", display: "flex" }}>
                        //         {tags.map((data, index) => {
                        //             return (
                        //                 <Tags data={data} handleDelete={handleDelete} key={index} />
                        //             );
                        //         })}
                        //     </Box>
                        // ),
                    }}
                />
                <p style={{
                      color: 'red',
                      fontSize: '12px',
                      textAlign: 'left',
                      paddingTop: '12px',
                      marginLeft: '2px',
                  }}>{inputerror.val?inputerror.msg:''}</p>
        </Box>
    );
}

const PatientIdPopup = (props) => {
    const dispatch = useDispatch();
    const [tags, SetTags] = useState(props&&props.patient_id);
    const [clickSubmit, setClickSubmit] = useState(false);
    const updateTags = (data) => {
        if (data) {
            SetTags(data)
            editPatientId(data)
            props.closePopup();
        }
    }

    const editPatientId = (data) => {
        apiAbmBlaze
            .post(EDIT_PATIENT_ID, {
                profile_id: props.profile_id,
                patient_tags: data,
                address: props.address
            })
            .then(async (res) => {
                if (res && res.data && res.data.patient_id) {
                    dispatch(set_updated_patient(res.data.patient_id))
                }
            })
    }

    const onSubmit = () => {
        setClickSubmit(true)
    }

    return (
        <div className="media-patient">
            <Dialog
                open={props.isopen}
                onClose={props.closePopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title media-patient">
                    <div className="Ptient-heading-container">
                        <div>
                            <label
                                className="block  text-blueGray-600 text-md font-bold mb-2"
                                style={{ color: '#36434B',marginLeft: "17px",
                                marginTop: "5px" }}
                            >
                                Patient ID
                            </label>
                        </div>
                        <div className="iconForCancelptient">
                            <CloseIcon onClick={props.closePopup} style={{ cursor: "pointer" ,marginRight : "13px" }} />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className="media-patient">
                    <DialogContentText id="alert-dialog-description">
                        <div className="flex flex-wrap mt-3">
                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">

                                    <InputTags 
                                        patient_id={props.patient_id}
                                        updateTags={updateTags}
                                        clickSubmit={clickSubmit}
                                        setClickSubmit={setClickSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </DialogContentText>

                    <div className="Submit-button-Ptient">
                        
                            <Button style={{ color: "#434973" }} className="buttonforPtientId"
                                onClick={onSubmit}
                                // disabled={tags===props.patient_id?true:false}
                            >
                                Submit
                            </Button> 
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default PatientIdPopup;