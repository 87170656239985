import React from "react";

// components

import NonPatientUsercard from "../../components/Cards/NonPatientUsercard";

export default function Settings(props) {
  
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <NonPatientUsercard />
        </div>
      </div>
    </>
  );
}
