import React, { useState, useEffect, useRef } from "react";
import "./CardSettings.css";
import Button from "@material-ui/core/Button";
import InputField from "../../components/Inputs_Validation";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import editImage from '../../assets/img/editimage.png';
// components
import { Widget } from "@uploadcare/react-widget";
import { connect } from "react-redux";
import axios from "axios";
import {
  updateProfile,
  UserProfileUpload,
  loadProfileForUpdate,
  againappearpopup
} from "../../redux/invitation/components/invitation.action";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { pageHeading } from "../../redux/user/user.action";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { } from "../../redux/user/user.action";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import LoadingIcon from '../../components/LoadingIcon'
import apiAbmBlaze from "../../api";
import Box from '@mui/material/Box';
import { alphabetsOnlyWithSpace } from "../../utils";
const useStyles = makeStyles((theme) => ({
  avt1: {
    height: "150px",
    width: "150px",
    objectFit: "fill",
  },

}));

const CardSettings = ({
  updateProfile,
  profileData,
  store,
  msg,
  profileUpdateAlertMessage,
  profileUpdateErrorMessage,
  UserProfileUpdatedData,
  UserProfileUpload,
  UserProfileUpdated,
  flageUserProfile,
  newEmailSet,
  loadProfileForUpdate,
  pageHeading,
  againappearpopup
}) => {
  const inputRefs = React.useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);
  const classes = useStyles();
  const [values, setValues] = React.useState({});
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [state, setState] = React.useState({});
  const [stateValue, setStateValue] = React.useState({});
  const [stateError, setStateError] = React.useState({val:false,msg:'ok'})
  const [countryError, setCountryError] = React.useState({val:false,msg:'ok'})
  const [contries, setCountry] = React.useState({});
  const [contriesValue, setCountryValue] = React.useState();
  const [saveClickEvent, setSaveClickEvent] = React.useState(false);
  const[uuid,setuuid]=React.useState(null)
  // const defaultProps = {
  //   options: top100Films,
  //   getOptionLabel: ({option:state}) => option,

  // };
  useEffect(() => {

    setCountryValue(
      UserProfileUpdated && UserProfileUpdated.address &&
        UserProfileUpdated.address.country ?
        UserProfileUpdated.address.country
        : "United States")
  }, [UserProfileUpdated])

  useEffect(() => {

    setStateValue(
      UserProfileUpdated && UserProfileUpdated.address &&
        UserProfileUpdated.address.state ?
        UserProfileUpdated.address.state
        : "")
  }, [UserProfileUpdated])

  const [OpenErrorSnack, setOpenErrorSnack] = React.useState(false);

  const [isopen, setIsopen] = React.useState(false);
  const [phoneValue, setPhoneValue] = React.useState(UserProfileUpdated && UserProfileUpdated.phone);
  const [lodingprofile, setLoadingProfile] = React.useState(false)
  const [valid,setvalid]=React.useState(false);
  const handleErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };
  const [userPic, setUserPic] = React.useState("");
  const [customeSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });
  const handleSnackbar = () => {
    setOpenSnack(false);
  };
  useEffect(() => {
    pageHeading('User Profile');
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    stateData();
    countryData();
  }, []);

  function stateData() {
    apiAbmBlaze.get("/js/state.json").then((response) => {
      setState(response.data.state);
    })
  }
  function countryData() {
    apiAbmBlaze.get('js/country.json').then((response) => {
      setCountry(response.data.country)
    }).catch((err) => {
      console.log("err from country", err)
    })
  }
  const checkValueExists = (value) => {
    console.log('values in check',value)
    return state.some((obj) => obj.name === value);
  };
  const matchKeys = () => {

    if(phoneValue !== UserProfileUpdated.phonenumber){
        return false
    }
       else if(contriesValue !== UserProfileUpdated?.address?.country){
        return false
       }
       else if(contriesValue ==='United States' && stateValue !== UserProfileUpdated?.address?.state){
        return false
       }
        else{
    const valueKeys = Object.keys(values);
    for (const key of valueKeys) {
      if (Object.keys(UserProfileUpdated.address).includes(key)) {
         
          if (values[key] !== UserProfileUpdated.address[key]) {
            return false;
          }

      } else {
        console.log('values',values,'key',key,'UserProfileUpdated',UserProfileUpdated)
        if (values[key] !== UserProfileUpdated[key]) {
          return false;
        }
      }
    }
  
    return true;
  };
}
  useEffect(() => {
    loadProfileForUpdate();
  }, []);
  useEffect(() => {
    if (profileUpdateAlertMessage === "success") {
      setValues({})
      setOpenSnack(true);
      setSnackMsg("Profile was updated successfully");
      setSnackSeverity("success");
    } else if (profileUpdateErrorMessage === "error400") {
      setOpenErrorSnack(true);
      setSnackMsg("Profile was not updated. Please check the highlighted field and try again.");
      setSnackSeverity("error");
    }
    return () => {
      againappearpopup();
    }
  }, [profileUpdateAlertMessage, profileUpdateErrorMessage]);
  const handleInputChange = (name, value) => {
      setValues({
        ...values,
        [name]: value,
      });
  };

  const onSave = async () => {
    setIsopen(true);
    setSaveClickEvent(true);
    let isValid1 = true;
    if(countryError.val===true||stateError.val===true){isValid1=false}
    else{
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid1 = inputRefs.current[i].current.validate();
      if (!valid1) {
        isValid1 = false;
      }
    }
  }
        let isValid = true;
        let incorrectstate = false;
        
        if (isValid === true && isValid1=== true) {
            let _state = '';
            let _is_state = UserProfileUpdated.address && UserProfileUpdated.address.state;
            if(_is_state===stateValue&&stateValue===UserProfileUpdated.address.state){_state=stateValue}
            else{
            if (_is_state && stateValue !== UserProfileUpdated.address.state) {
                _state = stateValue
            } else if (!_is_state) {
                _state = stateValue
            }  else {
              _state = values.state
            }
          }
            const isValueExists = checkValueExists(_state);
        if (contriesValue ==='United States' && !isValueExists ){incorrectstate=true;}

      
      if(!incorrectstate===true){
        console.log("phone number++", _state, UserProfileUpdated.state);
        updateProfile({ values, country: contriesValue, state: _state }, profileData.id, UserProfileUpdated, phoneValue);
        UserProfileUpload(uuid);
        }
        else if( incorrectstate===true){
            setSnackSeverity("error")
            setSnackMsg('Please select an appropriate state from the dropdown')
            setOpenSnack(true)
            setSaveClickEvent(false);
        }
    }
    else if( isValid1===false){
        setSnackSeverity("error")
        setSnackMsg('Please fix the inputs with errors')
        setOpenSnack(true)
        setSaveClickEvent(false);
    }
  }

  useEffect(() => {
    console.log('çaleed on change in address',values)
    const isMatch = matchKeys();
    setvalid(isMatch);
  }, [values, UserProfileUpdated,phoneValue,contriesValue,stateValue]);
  useEffect(() => {
    if (UserProfileUpdated && Object.keys(UserProfileUpdated).length > 0)
      setLoadingProfile(false);
    setSaveClickEvent(false);
  }, [UserProfileUpdated])
  useEffect(()=>{
    if(uuid!=null){
      setUserPic(uuid);
      setLoadingProfile(false);
    }
  },[uuid])


  const widgetApi = useRef();

  return (
    <>
      <AlertSnackBar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cupSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />
      {Object.keys(UserProfileUpdated && UserProfileUpdated).length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
          <LoadingIcon />
        </div>
      ) : (
        <div className="containers">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="UserprofileUpdated">
              <div>
                {lodingprofile === true ? <div>
                  <LoadingIcon />
                </div> : <div>
                  {UserProfileUpdated && UserProfileUpdated.photo_uuid ? (
                    <div>
                      <Avatar
                        src={`https://ucarecdn.com/${uuid != null? uuid :UserProfileUpdated.photo_uuid}/`}
                        // className={classes.avt1}
                        className="userProfile-image"
                      />
                    </div>
                  ) : (
                    uuid?<Avatar
                    src={`https://ucarecdn.com/${uuid != null? uuid :UserProfileUpdated.photo_uuid}/`}
                    // className={classes.avt1}
                    className="userProfile-image"
                  />:
                    <Avatar
                      src={require("../../assets/img/user1.svg").default}
                      // className={classes.avt1}
                      className="userProfile-image"
                      style={{ cursor: "pointer" }}
                      backgroundSize='cover'
                      backgroundPosition='top center'
                    // className="userProfile-image"
                    />
                  )}
                </div>
                }
                <div className="editpendcilbackground">
                  <img src={editImage} onClick={() => widgetApi.current.openDialog()} className="editPencilProfileIocn" />
                </div>
              </div>
              <div style={{ display: "none" }}>
                <Widget
                  styles={{ display: "none" }}
                  ref={widgetApi}
                  crop="1:1"
                  // crop="free, 16:9, 4:3, 5:4, 1:1"
                  publicKey="39664162a25226f9bff2"
                  id="file"
                  onFileSelect={(file) => {
                    if (file) {
                      file.progress((info) => {
                      }
                      );
                      file.done((info) => {
                        setLoadingProfile(true);
                      }
                      );
                    }

                  }}
                  onChange={(info) => {
                    let uid = info.uuid + '/' + info.cdnUrlModifiers;
                    setUserPic(uid)
                    setuuid(uid)
                    // UserProfileUpload(uid);
                  }}
                />
              </div>
            </div>
          </div>
          <form>
            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block lable-text text-blueGray-600 text-md font-bold mb-2"
                    htmlFor="grid-password"
                    style={{ color: "#858fa4", fontWeight: "800" }}
                  >
                    User name
                  </label>
                  <InputField
                    type="text"
                    className="uname"
                    validation="required"
                    ref={inputRefs.current[0]}
                    handleInputChange={handleInputChange}
                    disabled={true}
                    name="user_name"
                    defaultValue={
                      UserProfileUpdated && UserProfileUpdated.user_name
                        ? UserProfileUpdated.user_name
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block lable-text text-blueGray-600 text-md font-bold mb-2"
                    htmlFor="grid-password"
                    style={{ color: "#858fa4" }}
                  >
                    First name
                  </label>
                  <InputField
                    id="fNameId"
                    type="text"
                    className="classfortextfieldinuserdetailsleft"
                    ref={inputRefs.current[1]}
                    handleInputChange={handleInputChange}
                    validation="required|max:35|alphabetsOnly"
                    name="first_name"
                    defaultValue={
                      UserProfileUpdated && UserProfileUpdated.first_name
                        ? UserProfileUpdated.first_name
                        : ""
                    }

                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block lable-text text-blueGray-600 text-md font-bold mb-2"
                    htmlFor="grid-password"
                    style={{ color: "#858fa4" }}
                  >
                    Last name
                  </label>
                  <InputField
                    pr={3}
                    m={2}
                    id="lNameId"
                    type="text"
                    className="lname"
                    ref={inputRefs.current[2]}
                    handleInputChange={handleInputChange}
                    validation="required|max:35|alphabetsOnly"
                    name="last_name"
                    defaultValue={
                      UserProfileUpdated && UserProfileUpdated.last_name
                        ? UserProfileUpdated.last_name
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md font-bold mb-2 "
                    htmlFor="grid-password"
                    style={{ color: "#78909C" }}
                  >
                    Address line 1
                  </label>
                  <InputField
                    type="text"
                    className="classfortextfieldinuserdetailsleft"
                    handleInputChange={handleInputChange}
                    ref={inputRefs.current[3]}
                    validation="required|max:50"
                    name="Addressline1"

                    defaultValue={
                      UserProfileUpdated &&
                        UserProfileUpdated.address &&
                        UserProfileUpdated.address.Addressline1
                        ? UserProfileUpdated.address.Addressline1
                        : ""
                    }

                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md font-bold mb-2"
                    htmlFor="grid-password"
                    style={{ color: "#78909C" }}
                  >
                    Address line 2
                  </label>
                  <InputField
                    id="lNameId"
                    type="text"
                    className="lname"
                    ref={inputRefs.current[4]}
                    handleInputChange={handleInputChange}
                    validation="required|max:50"
                    name="Addressline2"
                    defaultValue={
                      UserProfileUpdated &&
                        UserProfileUpdated.address &&
                        UserProfileUpdated.address.Addressline2
                        ? UserProfileUpdated.address.Addressline2
                        : ""
                    }

                  />
                </div>
              </div>
            </div>

            <div className="flex flex-wrap mt-3 cardsetting">




              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md font-bold mb-2"
                    htmlFor="grid-password"
                    style={{ color: "#78909C" }}
                  >
                    Country
                  </label>

                  <div>
                    <Autocomplete
                      // freeSolo
                      // disableClearable
                      clearIcon={null}
                      popupIcon={null}
                      options={contries}

                      // value={"United States"}
                      getOptionLabel={(option) => option.country ||
                        contriesValue



                      }
                      onChange={(e, value) => {

                        setCountryValue(value && value.country)
                        if(!value){
                          setCountryError({val:true,msg:'Required'});
                          return;
                        }
                        else{
                          setCountryError({val:false,msg:'ok'})
                        }
                        // let flag = alphabetsOnlyWithSpace(value.country);
                        // if(flag){
                        //   flag = value.country.length>35?false:true
                        //   setCountryError(flag?{val:false,msg:'ok'}:{val:true,msg:'This input must be no longer than 35 characters long'});
                        // }
                        // else{
                        //   setCountryError({val:true,msg:'should contain only alphabets and/or space'});
                        // }
                      }}
                      autoHighlight
                      id="disable-close-on-select"
                      country
                      renderInput={(params) => (

                        <TextField {...params}
                          onChange={(e) => { }}
                          variant="standard" />
                      )}
                      defaultValue={
                        UserProfileUpdated &&
                          UserProfileUpdated.address &&
                          UserProfileUpdated.address.country
                          ? UserProfileUpdated.address.country
                          : ""
                      }
                    />
                    <p style={{
                        color: 'red',
                        fontSize: '12px',
                        textAlign: 'left',
                        paddingTop: '12px',
                        marginLeft: '2px',
                    }}>{countryError.val?countryError.msg:''}</p>
                  </div>

                </div>

              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md font-bold mb-2"
                    htmlFor="grid-password"
                    style={{ color: "#78909C" }}
                  >
                    State
                  </label>

                  {contriesValue === "United States" ?

                    <div>
                      <Autocomplete
                      // freeSolo
                      // disableClearable
                      clearIcon={null}
                      options={state}
                      popupIcon={null}

                      getOptionLabel={(option) => option.name || stateValue}
                      onChange={(e, value) => {
                        setStateValue(value && value.name);
                        if(!value){
                          setStateError({val:true,msg:'Required'});
                          return;
                        }
                        else{
                          setStateError({val:false,msg:'ok'})
                        }
                        let flag = alphabetsOnlyWithSpace(value.name);
                        if(flag){
                          flag = value.name.length>35?false:true
                          setStateError(flag?{val:false,msg:'ok'}:{val:true,msg:'This input must be no longer than 35 characters long'});
                        }
                        else{
                          setStateError({val:true,msg:'should contain only alphabets and/or space'});
                        }
                      }}
                      autoHighlight
                      id="disable-close-on-select"
                      country
                      renderInput={(params) => (

                        <TextField {...params} variant="standard" />
                      )}
                      defaultValue={
                        UserProfileUpdated &&
                          UserProfileUpdated.address &&
                          UserProfileUpdated.address.state
                          ? UserProfileUpdated.address.state
                          : ""
                      }
                    />
                    <p style={{
                        color: 'red',
                        fontSize: '12px',
                        textAlign: 'left',
                        paddingTop: '12px',
                        marginLeft: '2px',
                    }}>{stateError.val?stateError.msg:''}</p>
                    </div>
                    :
                    <InputField
                      // id="cityId"
                      type="text"
                      className="lname"
                      ref={inputRefs.current[6]}
                      handleInputChange={handleInputChange}
                      validation="required|alphabetsOnly|max:35"
                      name="state"

                      defaultValue={
                        UserProfileUpdated &&
                          UserProfileUpdated.address &&
                          UserProfileUpdated.address.state
                          ? UserProfileUpdated.address.state
                          : ""
                      }

                    />
                  }

                </div>
              </div>
            </div>

            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-md font-bold mb-2 "
                      htmlFor="grid-password"
                      style={{ color: "#78909C" }}
                    >
                      City
                    </label>

                    <InputField
                      type="text"
                      className="classfortextfieldinuserdetailsleft"
                      ref={inputRefs.current[5]}
                      handleInputChange={handleInputChange}
                      validation="required|alphabetsOnly|max:35"
                      name="city"
                      defaultValue={
                        UserProfileUpdated &&
                          UserProfileUpdated.address &&
                          UserProfileUpdated.address.city
                          ? UserProfileUpdated.address.city
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block text-blueGray-600 text-md font-bold mb-2"
                      htmlFor="grid-password"
                      style={{ color: "#78909C" }}
                    >
                      Zip
                    </label>
                    <InputField
                      // id="countryId"
                      className="lname"
                      type="text"
                      handleInputChange={handleInputChange}
                      validation="required|alphanumeric|min:3|max:9"
                      ref={inputRefs.current[7]}
                      name="Zip"
                      defaultValue={
                        UserProfileUpdated &&
                          UserProfileUpdated.address &&
                          UserProfileUpdated.address.Zip
                          ? UserProfileUpdated.address.Zip
                          : ""
                      }

                    />
                  </div>
                </div>
            </div>


            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md font-bold mb-2"
                    htmlFor="grid-password"
                    style={{ color: "#78909C" }}
                  >
                    Phone
                  </label>
                  <PhoneInput
                    className="PhoneinputForUserProfile"
                    international
                    value={UserProfileUpdated.phone}
                    placeholder=""
                    inputProps={{
                      name: "phonenumber",
                      required: true,
                    }}
                    disabled={true}
                    onChange={setPhoneValue}
                    inputStyle={{
                      width: "100%",
                      borderTop: "none",
                      borderBottom: "1px solid",
                      background: "transparent",
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blue-600 text-md font-bold mb-2"
                    htmlFor="grid-password"
                    style={{ color: "#78909C" }}
                  >
                    Email
                  </label>
                  <InputField
                    type="text"
                    className="lname disabledinput"
                    disabled={true}
                    value={newEmailSet ? newEmailSet : profileData.email}
                    handleInputChange={handleInputChange}
                    name="email"
                  />
                </div>
              </div>
            </div>

            <div
              className="edit_profileupdate"
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "56px",
                marginRight: "56px",
              }}
            >
              <Button
                style={{ color: "#434973" }}
                className="buttonforuserUpdate"
                onClick={() => onSave()}
                disabled={valid}
              >
                {/*  Submit */}
                {saveClickEvent ? <Box sx={{
                  display: 'flex', width: "50px", height: "50px"
                }}>  <LoadingIcon /> </Box> : "Submit"}
              </Button>
            </div>
            {/* <div className="userProfile_paasswordandsecurity">
              <a href="/app/PasswordAndSecurity">Password & Security</a>
            </div> */}
          </form>
        </div>
      )}

      {/* )} */}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    profileData: store.user.user,
    profileUpdateAlertMessage: store.invitation.profileUpdateAlertMessage,
    profileUpdateErrorMessage: store.invitation.profileUpdateErrorMessage,
    UserProfileUpdated: store.invitation.UserProfileUpdated,
    flageUserProfile: store.invitation.flageUserProfile,
    newEmailSet: store.user.newEmailSet,
    msg: store.invitation.msg,
  };
};

export default connect(mapStateToProps, {
  updateProfile,
  // userProfileDetails,
  UserProfileUpload,
  loadProfileForUpdate,
  pageHeading,
  againappearpopup
})(CardSettings);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AlertSnackBar = ({
  flag,
  onClose,
  anchorOrigin,
  msg,
  severity,
}) => {
  return (
    <Snackbar
      anchorOrigin={
        anchorOrigin ? anchorOrigin : { vertical: "top", horizontal: "right" }
      }
      open={flag}
      autoHideDuration={4000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity ? severity : "success"}>
        {msg}
      </Alert>
    </Snackbar>
  );
};
