// import { Roles } from "config";

// Components
import Dashboard from "../screens/PrivateRoutes/Dashboard";
import Settings from "../views/admin/Settings.js";
import Chat from "../screens/chats/chatBody/ChatBody";
import Invitation from "../screens/PrivateRoutes/Invitations";
import SATable from "../screens/PrivateRoutes/SA_InvitationTable";
import Clinicadmin from "../screens/PrivateRoutes/Clinical_Admin";
import ClinicianTable from "../screens/PrivateRoutes/Clinician_Table";
import Signup from "../views/auth/Register";
import NotificationSettings from "../views/admin/NotificationSettings/NotificationSettings";
import NotificationSummary from "../screens/PrivateRoutes/NotificationSummary";
import CaresiteSettingDashboard from "../views/admin/CaresiteSettingDashboard/CaresiteSettingDashboard";
import Helpcenter from "../components/HelpCenter/Helpcenter";
import Report from "../components/Report/Report";
import PatientProfile from "../screens/PrivateRoutes/PatientProfile";
import PasswordAndSecurity from "../components/Cards/PasswordAndSecurity";
import ClinicProfileDetails from "../screens/ClinicProfile/ClinicProfile";
// import NonPatientUsercard from "../components/Cards/NonPatientUsercard.js";
import UserInfo from "../views/admin/UserInfo.js"

const PrivateRoutes = [
  {
    component: Dashboard,
    path: "/",
    title: "Dashboard",
    exact: true,
    permission: ["SUPER_ADMIN", "ADMIN", "CLINIC_ADMIN", "CLINICIAN"],
  },

  {
    component: Settings,
    path: "/settings",
    title: "Settings",
    permission: ["SUPER_ADMIN", "ADMIN", "CLINIC_ADMIN", "CLINICIAN"],
  },
  {
    component: UserInfo,
    path: "/userinfo/:id",
    title: "User Info",
    permission: ["SUPER_ADMIN", "ADMIN", "CLINIC_ADMIN", "CLINICIAN"],
  },
  {
    component: NotificationSettings,
    path: "/notificationsettings",
    title: "NotificationSettings",
    permission: ["CLINIC_ADMIN","CLINICIAN"],
  },
  {
    component: Signup,
    path: "/signup",
    title: "Signup",
  },

  {
    component: Chat,
    path: "/chat",
    title: "Message",
    permission: ["SUPER_ADMIN", "ADMIN", "CLINIC_ADMIN", "CLINICIAN"],
  },

  //------------------------------FOR INVITATIONS FOR DIFFERENT ROLES------------------------
  //SUPER ADMIN INVITATION
  {
    component: SATable,
    path: "/satable",
    title: "SATable",
    permission: ["SUPER_ADMIN"],
  },

  //ABM ADMIN INVITATION
  {
    component: Invitation,
    path: "/invitation",
    title: "Invitation",
    permission: ["ADMIN"],
  },

  //CLINIC ADMIN INVITATION
  {
    component: Clinicadmin,
    path: "/clinicadmin",
    title: "Clinicadmin",
    permission: ["CLINIC_ADMIN"],
  },

  //CLINIC INVITATION
  {
    component: ClinicianTable,
    path: "/cliniciantable",
    title: "ClinicianTable",
    permission: ["CLINICIAN"],
  },

  //------------------------------------------------0-------------------------------------------

  {
    component: NotificationSummary,
    path: "/notificationSummary",
    title: "Notification Summary",
    permission: ["CLINIC_ADMIN",  "CLINICIAN"],
  },
 
  {
    component: CaresiteSettingDashboard,
    path: "/caresitesettingdashboard",
    title: "CaresiteSettingDashboard",
    permission: ["CLINIC_ADMIN", "CLINICIAN"],
  },

  {
    component: Helpcenter,
    path: "/help",
    title: "helpcenter",
    permission: ["CLINIC_ADMIN", "CLINICIAN",],
  },

  {
    component: Report,
    path: "/Reports",
    title: "Reports",
    permission: ["CLINIC_ADMIN", "CLINICIAN"],
  },

  {
    component: PatientProfile,
    path: "/PatientProfile",
    title: "PatientProfile",
    permission: ["SUPER_ADMIN", "ADMIN", "CLINIC_ADMIN", "CLINICIAN"],
  },

  {
    component: PasswordAndSecurity,
    path: "/PasswordAndSecurity",
    title: "PasswordAndSecurity",
    permission: ["SUPER_ADMIN", "ADMIN", "CLINIC_ADMIN", "CLINICIAN"],
  },

  {
    component: ClinicProfileDetails,
    path: "/clinicprofiledetails",
    title: "ClinicProfileDetails",
    permission: ["SUPER_ADMIN", "ADMIN"],
  },
];

export default PrivateRoutes;
