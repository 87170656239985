import React, { useEffect, useState } from "react";
import "./styles.css";
import userIocnPatientProfile from "../../../assets/img/userIocnPatientProfile.svg";
import Pen from "../../../assets/img/pen.svg";
import MedicalCondition from "../../../assets/icons/medicalCondition.svg";
import Info from "../../../assets/img/patient_profile/infoicon.svg";
import PersonalInformation from "../../../assets/icons/personalInformation.svg";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ProgressBar from "../../../components/ProgressGuage";
import AdherenceElementWeeksNew from "../../../components/AdheranceScoreElement";
import AreaGraph from "../../../components/AreaGraph";
import AlertSnackBar from "../../../components/Alertmessage/AlertMsg";

import AreaGraphAdheranceScore from "../../../components/AreaGraphAdheranceScore";
import SetNewTheorpyGoalModal from "../../../components/ModalPopup/SetNewTheorpyGoalModal";
import Checkbox from "@material-ui/core/Checkbox";
import activetop from "../../../assets/img/activetop.svg";
import { styled } from "@mui/material/styles";
import PDF from "../../../assets/img/pdf.png";
import Notes from "../../../assets/img/note.svg";
import MSG from "../../../assets/img/msg.svg";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import Divider from "@material-ui/core/Divider";
import InactiveIcon from "../../../assets/icons/inActiveTherapy.svg";
import Personalinformation from "../../../components/PersonalInformation/Personalinformation";
import MedicalInformation from "../../../components/MedicalCondition/MedicalInformation";
import AddNotePopup from "../../../components/AddNote/AddNote";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import { connect, useSelector } from "react-redux";
import { fetchNotedata } from "../../../redux/therapyGoals/therapyGoals.action";
import InfoPopup from "../../../components/InfoModelPopup";
import PatientProfileGraph from "../../../components/PatientProfileGraph";
import DatePicker from "react-datepicker";
import PtientIdPopUp from "../../../components/PtientIdPopUp/index";
import ARCLogo from "../../../assets/img/Updated logo.png";
import ABMLogo from "../../../assets/img/ABMLogo.png";

import {
  reportsSearch,
  adheranceScoreDateRange,
  adheranceScoreGraphMap,
  deliveredParameters,
  getCharts,
  adheranceScoreMap,
  hmrGraph,
  hmrGraphDateRange,
  adherenceGeaphBoolean,
  hmrGraphBoolean,
  pageHeading,
  navReportSearchToPatientProfile,
  patient_Profile_Therapy_Graph,
  patient_Profile_Therapy_ProgressBar,
  set_updated_patient,
  reportsSearchUser,
} from "../../../redux/user/user.action";
import { loadDevice } from "../../../redux/therapyGoals/therapyGoals.action";
import { getPatientStatus,loadProfileForUpdate } from "../../../redux/invitation/components/invitation.action";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Link, useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import excelIcon from "../../../../src/assets/img/excel-1-129882.png";
import apiAbmBlaze, {
  DOWNLOAD_PATIENT_ADHERE_SCORE_HISTORY,
} from "../../../api";
import PatientToggleButton from "../../../components/PatientToggleButton";
import { useLocation } from "react-router-dom";
import DeliveredParametersGraph from "../../../components/DeliveredParametersGraph";
// import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  icons: {
    minWidth: "0px",
    backgroundColor: "white",
    boxShadow: "#6A768F80 0px 3px 6px",
    border: "none",
    borderColor: "transparent",
    "&:hover": {
      backgroundColor: "#F26930",

      boxShadow: "#6A768F80 0px 3px 6px",
    },
  },
  buttonsOfDate: {
    backgroundColor: "#F2F4F8",
    marginLeft: "11px",
    boxShadow: "#6A768F80 0px 3px 6px",
    color: "#3D446F",
    fontFamily: "Roboto",
    marginBottom: "8px",
    textTransform: "capitalize",
    fontWeight: "400",
  },
  buttonsOfSelect: {
    backgroundColor: "#F2F4F8",
    marginLeft: "11px",
    boxShadow: "#6A768F80 0px 3px 6px",
    color: "#3D446F",
    fontFamily: "Roboto",
    fontSize: "14px",
    marginBottom: "8px",
    textTransform: "initial",
    fontWeight: "500",
    width: "120px",
    marginTop: "10px",
  },
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 20,
  width: 30,
  height: 30,
  boxShadow: "#6A768F80 0px 3px 6px",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",

  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#f26930",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 30,
    height: 30,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#f26930",
  },
});

//Changes done by dhanush

const InAcThIcon = styled("span")(({ theme }) => ({
  borderRadius: 10,
  width: 20,
  height: 20,
  boxShadow: "#6A768F80 0px 3px 6px",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",

  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const InAcThCheckedIcon = styled(InAcThIcon)({
  backgroundColor: "#f26930",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#f26930",
  },
});

const PatienProfile = ({
  navFromReportSearchToPatientProfile,
  fetchNotedata,
  adheranceScoreGraphMap,
  deliveredParameters,
  getCharts,
  adheranceScoreDateRange,
  adheranceScoreMap,
  hmrGraph,
  hmrGraphDateRange,
  adherenceGeaphBoolean,
  hmrGraphBoolean,
  adherancegraphbooleanval,
  hmrgraphbooleanval,
  hmrGraphDateRangeval,
  adheranceScoreGraphDateRangeval,
  pageHeading,

  patient_Profile_Therapy_Graph,
  therapyGraph,
  patient_Profile_Therapy_ProgressBar,
  ADS_Details,
  patient_details,
  updatedPatientId,
  set_updated_patient,
  reportsSearchUser,
  particularUserReportSearchVal,
  loadDevice,
  getPatientStatus,
}) => {
  const [showModalSetTheorpy, setshowModalSetTheorpy] = React.useState(false);
  const [inActiveAlert, setInActiveAlert] = React.useState(false);
  const classes = useStyles();
  const [selectButtonWeek, setSelectButtonWeek] = React.useState("white");
  const [selectButtonMonth, setSelectButtonMonth] = React.useState("#F2F4F8");
  const [selectButtonDateRange, setSelectButtonDateRange] =
    React.useState("#F2F4F8");

  const [selectButtonWeek2, setSelectButtonWeek2] = React.useState("white");
  const [selectButtonMonth2, setSelectButtonMonth2] = React.useState("#F2F4F8");
  const [selectButtonDateRange2, setSelectButtonDateRange2] =
    React.useState("#F2F4F8");
  const [personalInfoOpen, setPersonalInfoOpen] = React.useState(false);

  const [medicalInfoOpen, setMedicalInfoOpen] = React.useState(false);
  const [openPatientIdPopup, setOpenPatientIdPopup] = React.useState(false);
  const [addNote, setAddNote] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorE3, setAnchorE3]=  React.useState(null);
  const [currentDurationHMR, setCurrentDurationHMR] = React.useState("week");
  const [currentDurationASH, setCurrentDurationASH] = React.useState("week");

  // Delivered Parametrs
  const [range, setRange] = React.useState("week");
  const [anchorE4, setAnchorE4] = React.useState(null);
  const [currentDurationDelivered, setCurrentDurationDelivered] = React.useState("week");
  const [deliveredDateRange, setDeliveredDateRange] = React.useState({from:undefined,to:undefined});
  const [deliveredDateRangeonsubmitfrom, setDeliveredDateRangeonsubmitfrom] = React.useState(undefined);
  const [deliveredDateRangeonsubmitto, setDeliveredDateRangeonsubmitto] = React.useState(undefined);
  const [HMRFromDate, setHMRFromDate] = React.useState(null);
  const [HMRToDate, setHMRToDate] = React.useState(null);
  const [HMRFromDateonsubmit, setHMRFromDateonsubmit] = React.useState(null);
  const [HMRToDateonsubmit, setHMRToDateonsubmit] = React.useState(null);

  const [reportData, setReportData] = React.useState(undefined);
  const [pdfDates, setPdfDates] = React.useState({
    from:undefined,to:undefined
  });
  const [anchorE5, setAnchorE5] = React.useState(null);
  const [downloadingReport,setDownloadingReport] = useState('none');
  const [openSnack, setOpenSnack] = useState(false)
  const [downloadingTransmission,setDownloadingTransmission] = useState('none');
  const [openSnackTransmission, setOpenSnackTransmission] = useState(false)
  
  const handleSnack = () => {
    setDownloadingReport('none');
    setOpenSnack(false);
  }
  const handleSnackTransmission = () => {
    setDownloadingTransmission('none');
    setOpenSnackTransmission(false);
  }

  const [adDateRangeTo, setAdDateRangeTo] = React.useState(null);
  const [adDateRangeFrom, setAdDateRangeFrom] = React.useState(null);
  const [adDateRangeToonsubmit, setAdDateRangeToonsubmit] = React.useState(null);
  const [adDateRangeFromonsubmit, setAdDateRangeFromonsubmit] = React.useState(null);
  const [prevscore, setprevscore] = React.useState();
  const [activeToggle, setActiveToggle] = React.useState("INACTIVE");
  const [inActiveToggle, setInActiveToggle] = React.useState(null);
  const [infoPopup, setInfoPopup] = React.useState(false);
  const [opentherapySelectDate, setOpentherapySelectDate] =
    React.useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "days").toDate()
  );
  const enddatefordatepicker = moment().toDate()
  const [therapyGraphIndex, setTherapyGraphIndex] = useState(0);
  const defaultValue = moment().format('YYYY-MM-DD')
  const [transmissiondate_to,settransmissiondate_to]= React.useState(null);
  const [transmissiondate_from,settransmissiondate_from] = React.useState(null);
  const weeks = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const firstdatehmrweek = `${moment()
    .subtract(7, "d")
    .utc()
    .format("DD MMM yyyy")}`
    const firstdatehmrmonth = `${moment()
      .subtract(1, "M")
      .utc()
      .format("DD MMM yyyy")}`
      const finaldatehmr = `${moment()
        .subtract(1, "d")
        .utc()
        .format("DD MMM yyyy")}`
  const hmrValue = ["0", "0", "0", "0", "0", "0", "0"];
  const hmrDays = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ];

  const adscore = ["0", "0", "0", "0", "0", "0", "0"];
  const adscoredays = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ]; 
  const nextdateforadsto = adDateRangeTo?moment(adDateRangeTo).clone().add(1,'day').format('YYYY-MM-DD') : null 
  const maxfortrendfrom = moment(Math.min.apply(null,[new Date(defaultValue),deliveredDateRange.to?new Date(deliveredDateRange.to):new Date(defaultValue)])).format('YYYY-MM-DD')
  const maxforhmrfrom = moment(Math.min.apply(null,[new Date(defaultValue),HMRToDate?new Date(HMRToDate):new Date(defaultValue)])).format('YYYY-MM-DD')
  const maxforadhfrom = moment(Math.min.apply(null,[new Date(defaultValue),adDateRangeTo?new Date(nextdateforadsto):new Date(defaultValue)])).format('YYYY-MM-DD')
  const maxforpdffrom = moment(Math.min.apply(null,[new Date(defaultValue),pdfDates.to?new Date(pdfDates.to):new Date(defaultValue)])).format('YYYY-MM-DD')
  const maxfortransfrom = moment(Math.min.apply(null,[new Date(defaultValue),transmissiondate_to?new Date(transmissiondate_to):new Date(defaultValue)])).format('YYYY-MM-DD')

  const patientDetails = useSelector((store) => {
    return store.user.navFromReportSearchToPatientProfile;
  });
  const deliveredParametersData = useSelector(store => store.user.deliveredParameters);

  useEffect(()=>{
    (async () => {
      let result = await fetchReportData(patientDetails.profile_id,loggedInUser.id);
      console.log('PATIENT',result)
      setReportData(result)
    })();
  },[patientDetails])

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let decriptQuery = ""
  let paramQueryForRefresh = 0

  try {

    decriptQuery = Buffer.from(query.get("p"), 'base64').toString('ascii')

    paramQueryForRefresh = JSON.parse(decriptQuery) ? JSON.parse(decriptQuery) : 0;
  }
  catch (err) {
    console.log('[Error]', err)
  }

  const querryParamspatientDetails = paramQueryForRefresh;


  const therapyHistory = useSelector((store) => {
    return store.therapyGoals.loadTherapise;
  });

  const hmrGraphvalue = useSelector((store) => {
    return store.user.hmrGraphval;
  });
  const hmrGraphFirstdate = useSelector((store) => {
    return store.user.hmrGraphvalfirstdate;
  });
  //console.log('hmrGraphvalfirstdate',hmrGraphFirstdate)
  const adheranceScoreGraphvalue = useSelector((store) => {
    return store.user.adheranceScoreGraph;
  });

  const [newDataArrived,setNewDataArrived] = React.useState(false);
  useEffect(()=>{
    setNewDataArrived(false)
    if(newDataArrived){
      setTimeout(()=>{
        generatePDF()
      },4000)
    }
  },[newDataArrived])

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    let d = moment(),from,to,fromads,toads;
    from = d.clone().add(-7,'days').format('YYYY-MM-DD');
    fromads=d.clone().add(-8,'days').format('YYYY-MM-DD');
    toads=d.clone().add(-2,'days').format('YYYY-MM-DD');
    to = d.clone().add(-1,'day').format('YYYY-MM-DD');
    if (patientDetails && patientDetails.profile_id) {
      fetchprevadsscore(patientDetails.profile_id);
      adheranceScoreMap(patientDetails.profile_id);
      adherenceGeaphBoolean(false);
      // adheranceScoreGraphMap("week", patientDetails.profile_id);
      adheranceScoreGraphMap(fromads,toads, patientDetails.profile_id);
      deliveredParameters({from,to,type:'week'},patientDetails.profile_id);
      hmrGraph("week", patientDetails.profile_id,firstdatehmrweek,finaldatehmr);
    } else {
      fetchprevadsscore(querryParamspatientDetails.profile_id ? querryParamspatientDetails.profile_id : null);
      adheranceScoreMap(querryParamspatientDetails.profile_id ? querryParamspatientDetails.profile_id : null);
      adherenceGeaphBoolean(false);
      // adheranceScoreGraphMap("week", querryParamspatientDetails.profile_id ? querryParamspatientDetails.profile_id : null);
      adheranceScoreGraphMap(fromads,toads, querryParamspatientDetails.profile_id ? querryParamspatientDetails.profile_id : null);
      deliveredParameters({from,to,type:'week'}, querryParamspatientDetails.profile_id ? querryParamspatientDetails.profile_id : null);
      hmrGraph("week", querryParamspatientDetails.profile_id ? querryParamspatientDetails.profile_id : null,firstdatehmrweek,finaldatehmr);
    }
    pageHeading("Patient Profile");
    reportsSearchUser({
      profile_id: querryParamspatientDetails.profile_id ? querryParamspatientDetails.profile_id : null,
      clinic_id: querryParamspatientDetails.clinic_id ? querryParamspatientDetails.clinic_id : null,
    });
    loadDevice(querryParamspatientDetails.pair_id);
    getPatientStatus(querryParamspatientDetails.profile_id);// TODO: This is giving error due to undefined profile_id

    return () => set_updated_patient();
  }, []);

  useEffect(() => {
    patient_Profile_Therapy_Graph(
      {
        profile_id: querryParamspatientDetails.profile_id,
        pair_id: querryParamspatientDetails.pair_id
      },
      moment(startDate).format("YYYY-MM-D")
    );
  }, [startDate]);

  let loggedInUser = useSelector(store=>store.user.user);
  const adheranceScore = useSelector((store) => {
    return store.user.adheranceScoreMap;
  });

// fetches yesterdays score returns "-" if score doesnt exist else returns conatenated array of most recent adherence score and date
  async function fetchprevadsscore(profileId){
    let res;
    try{
      res = await apiAbmBlaze.post('/api/fetchprevadsscore',{profileId:profileId,yesterday:moment().add(-1, "day").format("YYYY-MM-DD"),firstday:moment().add(-7, "day").format("YYYY-MM-DD")});
      // console.log('resss',res);
      if(res.data.rows[0].coalesce!='-'){
      const a=res.data.rows[0].coalesce.split(',')
      if(moment().add(-1, "day").format("YYYY-MM-DD")==a[1])
      {// { console.log('inside if',parseFloat(a[0]))
        setprevscore(parseFloat(a[0]))
        
      }
      else{console.log('inside else')
        setprevscore(0)
      }

      // console.log('arr',a);
    }
    else{
      setprevscore(-1)
    }
  }
    catch(er){
      console.log('FETCH ERR',er);
    }
  }
  //adherance score graph part by dhanush plzz dont change
  const adgrval =
    adheranceScoreGraphvalue && adheranceScoreGraphvalue.length > 0
      ? adheranceScoreGraphvalue.map((item) => {
        return item.adherence_score === "NaN"
          ? 0
          : item.adherence_score * 100;
      })
      : adscore;

  const adscoregraphdays =
    adheranceScoreGraphvalue && adheranceScoreGraphvalue.length > 0
      ? adheranceScoreGraphvalue.map((item) => {
        return moment(item.date_day).utc().format("D MMM");
      })
      : adscoredays;

  //HMR graph part by dhanush plzz dont change
  const hmrgrval =
    hmrGraphvalue && hmrGraphvalue.length > 0
      ? hmrGraphvalue.map((item) => {
        return item.hmr_max_of_day === null ? 0 : item.hmr_max_of_day;
      })
      : hmrValue;

  const hmrscoregraphdays =
    hmrGraphvalue && hmrGraphvalue.length > 0
      ? hmrGraphvalue.map((item) => {
        return moment(item.timestamp).utc().format("D MMM");
      })
      : hmrDays;

  //adherance score date range graph part by dhanush plzz dont change
  const addateRange =
    adheranceScoreGraphDateRangeval &&
      adheranceScoreGraphDateRangeval.length > 0
      ? adheranceScoreGraphDateRangeval.map((item) => {
        return item.adherence_score === "NaN" ? 0 : item.adherence_score * 100;
      })
      : adscore;
  const adscoregraphdaysdaterange =
    adheranceScoreGraphDateRangeval &&
      adheranceScoreGraphDateRangeval.length > 0
      ? adheranceScoreGraphDateRangeval.map((item) => {
        return moment(item.date_day).utc().format("D MMM");
      })
      : adscoredays;

  //HMR date range part by dhanush plzz dont change
  const hmrdateRange =
    hmrGraphDateRangeval && hmrGraphDateRangeval.length > 0
      ? hmrGraphDateRangeval.map((item) => {
        return item.max === null ? 0 : item.max;
      })
      : hmrValue;
  const hmrscoregraphdaysdaterange =
    hmrGraphDateRangeval && hmrGraphDateRangeval.length > 0
      ? hmrGraphDateRangeval.map((item) => {
        return moment(item.timestamp).utc().format("D MMM");
      })
      : hmrDays;

  function weekFunc() {
    let d = moment(),from,to;
    from = d.clone().add(-8,'days').format('YYYY-MM-DD');
    to = d.clone().add(-2,'days').format('YYYY-MM-DD');
    setSelectButtonWeek("white");
    setSelectButtonMonth("#F2F4F8");
    setSelectButtonDateRange("#F2F4F8");
    adheranceScoreGraphMap(from,to, patientDetails.profile_id);
    // adheranceScoreGraphMap("week", patientDetails.profile_id);
    adherenceGeaphBoolean(false);
    setCurrentDurationASH("week");
  }
  function monthFunc() {
    let d = moment(),from,to;
    from = d.clone().add(-1,'month').add(-1,'days').format('YYYY-MM-DD');
    to = d.clone().add(-2,'days').format('YYYY-MM-DD');
    setSelectButtonMonth("white");
    setSelectButtonWeek("#F2F4F8");
    setSelectButtonDateRange("#F2F4F8");
    adheranceScoreGraphMap(from,to, patientDetails.profile_id);
    // adheranceScoreGraphMap("month", patientDetails.profile_id);
    
    adherenceGeaphBoolean(false);
    setCurrentDurationASH("month");
  }

  function weekFunc2() {
    setSelectButtonWeek2("white");
    setSelectButtonMonth2("#F2F4F8");
    setSelectButtonDateRange2("#F2F4F8");
    hmrGraph("week", patientDetails.profile_id,firstdatehmrweek,finaldatehmr);
    hmrGraphBoolean(false);
    setCurrentDurationHMR("week");
  }
  function monthFunc2() {
    setSelectButtonMonth2("white");
    setSelectButtonDateRange2("#F2F4F8");
    setSelectButtonWeek2("#F2F4F8");
    hmrGraph("month", patientDetails.profile_id,firstdatehmrmonth,finaldatehmr);
    hmrGraphBoolean(false);
    setCurrentDurationHMR("month");
  }

  function deliveredParaWeek(){
    let d = moment(),from,to;
    from = d.clone().add(-7,'days').format('YYYY-MM-DD');
    to = d.clone().add(-1,'days').format('YYYY-MM-DD');
    // setDeliveredDateRange({from,to})
    setRange("week");
    deliveredParameters({from,to,type:'week'},patientDetails.profile_id);
    setCurrentDurationDelivered('week');
  }

  function deliveredParaMonth(){
    let d = moment(),from,to;
    from = d.clone().add(-1,'month').format('YYYY-MM-DD');
    to = d.clone().add(-1,'days').format('YYYY-MM-DD');
    // setDeliveredDateRange({from,to})
    setRange("month");
    deliveredParameters({from,to,type:'month'},patientDetails.profile_id);
    setCurrentDurationDelivered('month')
  }

  const showSetTheorapyGoalHnadler = () => {
    setshowModalSetTheorpy(true);
  };
  const callBack = () => {
    setshowModalSetTheorpy(false);
  };

  function personalInfoHandler() {
    setPersonalInfoOpen(true);
  }

  function medicalInfoHandler() {
    setMedicalInfoOpen(true);
  }
  function patientIdPopup() {
    setOpenPatientIdPopup(true);
  }
  const closePatientIdPopup = () => {
    setOpenPatientIdPopup(false);
  };
  const callbackMI = () => {
    setMedicalInfoOpen(false);
  };

  const callbackPI = () => {
    setPersonalInfoOpen(false);
  };
  function InAcThCheckbox(props) {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        color="default"
        checkedIcon={<InAcThCheckedIcon />}
        icon={<InAcThIcon />}
        checked={props.checked}
      />
    );
  }

  const openAddNotePopup = () => {
    setAddNote(true);
    fetchNotedata(navFromReportSearchToPatientProfile);
  };

  const CloseAddNotePopup = () => {
    setAddNote(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // setCurrentDurationASH("daterange");
  };
  const handleClickDelivered = (event) => {
    setAnchorE4(event.currentTarget);
    // setCurrentDurationDelivered("daterange");
  }
  const handleClickcsv = (event) => {
    setAnchorE3(event.currentTarget);
    setCurrentDurationASH("daterange");
    
  };

  const handleClickpdf = (event) => {
    setAnchorE5(event.currentTarget)
  }
  const handleclosepdf = (event) => {
    setAnchorE5(null);
    // setPdfDates({from:undefined,to:undefined})
  }

  const handleCloseonsubmit = () => {
    setAnchorEl(null);
    setSelectButtonDateRange("white");
    setSelectButtonMonth("#F2F4F8");
    setSelectButtonWeek("#F2F4F8");
    setCurrentDurationASH("daterange");
    setAdDateRangeFromonsubmit(adDateRangeFrom)
    setAdDateRangeToonsubmit(adDateRangeTo)
    // adheranceScoreDateRange(
    //   adDateRangeFromonsubmit,
    //   adDateRangeToonsubmit,
    //   patientDetails.profile_id
    // );
    adherenceGeaphBoolean(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  }
  useEffect(async()=>{
    await adheranceScoreDateRange(adDateRangeFromonsubmit, adDateRangeToonsubmit, patientDetails.profile_id);
  },[adDateRangeFromonsubmit,adDateRangeToonsubmit])
  const handleCloseDelivered = () => {
    setAnchorE4(null);
  }
  const handleSubmitDelivered = () => {
    setAnchorE4(null);
    setRange("daterange");
    setCurrentDurationDelivered("daterange");
    setDeliveredDateRangeonsubmitfrom(deliveredDateRange.from)
    setDeliveredDateRangeonsubmitto(deliveredDateRange.to)
  }
  const handlecloseforcsv =()=>{
    setAnchorE3(null);
  }
  useEffect(()=>{
    deliveredParameters({from:deliveredDateRangeonsubmitfrom,to:deliveredDateRangeonsubmitto,type:'range'},patientDetails.profile_id);
  },[deliveredDateRangeonsubmitfrom,deliveredDateRangeonsubmitto])
  const handlecloseforcsvonsubmit =()=>{
    setAnchorE3(null);
    if(transmissiondate_to && transmissiondate_from){
      generateCSV(transmissiondate_to,transmissiondate_from);
    }
  }
  const handelClick2 = (event) => {
    setAnchorE2(event.currentTarget);
    // setCurrentDurationHMR("daterange");
  };

  const handleClose2onsubmit = () => {
    setAnchorE2(null);
    setSelectButtonMonth2("#F2F4F8");
    setSelectButtonDateRange2("white");
    setSelectButtonWeek2("#F2F4F8");
    setCurrentDurationHMR("daterange")
    setHMRFromDateonsubmit(HMRFromDate)
    setHMRToDateonsubmit(HMRToDate)
    // hmrGraphDateRange(HMRFromDateonsubmit?HMRFromDateonsubmit:null, HMRToDate?HMRToDateonsubmit:null, patientDetails.profile_id);
    hmrGraphBoolean(true);
  };
  const handleClose2 = () =>{
    setAnchorE2(null);
  }
  useEffect(async()=>{
    await hmrGraphDateRange(HMRFromDateonsubmit, HMRToDateonsubmit, patientDetails.profile_id);
  },[HMRFromDateonsubmit,HMRToDateonsubmit])

  const callTherapyToggles = (val) => {
    // if (val == false) {
    //   setInActiveToggle("INACTIVE");
    //   setActiveToggle(null);
    // } 
    // else {
      setActiveToggle("ACTIVE");
      setInActiveToggle(null);
    // }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const open2 = Boolean(anchorE2);
  const id2 = open2 ? "simple-popover" : undefined;
  
  const open3 = Boolean(anchorE3);
  const id3 = open3 ? "simple-popover" : undefined;

  const open4 = Boolean(anchorE4);
  const id4 = open4? "simple-popover" : undefined;

  const open5 = Boolean(anchorE5);
  const id5 = open5? "simple-popover" : undefined;

  const fetchReportData = async (profile_id,loggedUserId) => {
    let reportData;
    try{
      reportData = await apiAbmBlaze.post('/api/patientreport/getPatientReportData',{profile_id:profile_id,loggedUserId});
      return {
        patient_data: reportData.data.data.rows[0],
        user: reportData.data.user.rows[0]
      }
    }
    catch(err){
      console.log('ReportData Error',err)
    }
    return reportData;
  }

  const formatPhone = (value, previousValue) => {
    // return nothing if no value
    if (!value) return value;
  
   // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    if (!previousValue || value.length > previousValue.length) {
  
     // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue;
  
     // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  
  
     if (cvLength < 9)
      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  
     return `+${currentValue.slice(0, 1)} (${currentValue.slice(1, 4)}) ${currentValue.slice(4, 7)}-${currentValue.slice(7, 11)}`;
    }
  };

  const preparePdf = async () => {
    setAnchorE5(null);
    //hmr
    setCurrentDurationASH("daterange")
    setCurrentDurationHMR("daterange")
    setCurrentDurationDelivered("daterange")
    setSelectButtonMonth2("#F2F4F8");
    setSelectButtonDateRange2("white");
    setSelectButtonWeek2("#F2F4F8");
    // setHMRFromDate(pdfDates.from);
    // setHMRToDate(pdfDates.to);
    console.log('Before',hmrGraphvalue)
    try{
      await hmrGraphDateRange(HMRFromDate, HMRToDate, patientDetails.profile_id);
    }
    catch(e){
      console.log('ERR prepare',e)
    }
    console.log('Fetch complete',hmrGraphvalue)
    hmrGraphBoolean(true);

    // adh
    setSelectButtonDateRange("white");
    setSelectButtonMonth("#F2F4F8");
    setSelectButtonWeek("#F2F4F8");
    setAdDateRangeFrom(pdfDates.from);
    setAdDateRangeTo(pdfDates.to);
    await adheranceScoreDateRange(
      adDateRangeFrom,
      adDateRangeTo,
      patientDetails.profile_id
    );
    adherenceGeaphBoolean(true);

    // Trend
    handleSubmitDelivered();
  }
  const generatePDF = async () => {
    
    var pdf = new jsPDF('p', 'mm', 'a4');

    let {patient_data,user} = reportData;
    let user_fullname = user?.first_name + ' '+user?.last_name;
    // console.log(patient_data,user);patientDetails.patient_id?.patient_id
    const list2 = [
      [`Patient Name:`,`${patientDetails.first_name} ${patientDetails.last_name}`,'Phone:',`${formatPhone(patientDetails.phone)}`],
      ['Patient ID:',`${patientDetails.patient_id?(patientDetails.patient_id?.patient_id?patientDetails.patient_id.patient_id:''):''}`,'Address:',`${patientDetails.address?.address_line_1}`],
      ['Email Address:',`${patientDetails.email}`,'City, State, Zip:',`${patientDetails.address?.city?patientDetails.address?.city+',':''} ${patientDetails.address?.state} ${patientDetails.address?.zip}`],
      ['Product Name:',`BiWaze Cough`,'Serial Number:',`${patient_data?.device_id?patient_data?.device_id:''}`]
    ];
    const weekObj = therapyHistory[0]?.days_of_week;
    let weekString = '';
    for(let attr in weekObj){
      if(weekObj[attr]){
        weekString = weekString+' '+attr.charAt(0).toUpperCase()+attr.slice(1);
      }
    }
    let updatedBy = ` ${therapyHistory[0]?.creator_firstname?therapyHistory[0]?.creator_firstname:''} ${therapyHistory[0]?.creator_lasttname?therapyHistory[0]?.creator_lasttname:''}`;
    const therapyGoal = [
      ['Therapies per day:',`${therapyHistory[0]?.times_per_day?therapyHistory[0]?.times_per_day:''}`,'Days to perform Therapy:',weekString],
      ['Active Date:',`${moment(therapyHistory[0]?.created_at).format("DD MMM YYYY")}`,'Updated By:',updatedBy]
    ];

    let images = [['topdf','Adherence Score Trend'],['hmrtopdf','Hour Meter Reading'],['deliveredtopdf','Delivered Therapy Graph']];
    let imageIndex = 0;

    const pageSize =  pdf.internal.pageSize;
    let fontSize = pdf.getFontSize();
    let remaingHeight = pageSize.height;
    let x = 20,y = 20;
    let done = false;
    let printedGoals = false;

    while(!done){
      x = 20;y = 20;remaingHeight = pageSize.height;
      pdf.setFontSize(18);
      pdf.text('Patient Report for BiWaze Cough',x,y);
      
      pdf.addImage(ARCLogo,'JPEG',167,9,20,18);
      pdf.setLineWidth(0.5)
      pdf.line(20,29,190,29);
      y = 40;
      fontSize = pdf.getFontSize();
      pdf.setFont('helvetica','normal');
      pdf.setFontSize(16);
      pdf.text('Patient Information',x,y);
      y = y+10;

      pdf.setFontSize(10.5);
      for(let j = 0; j< 4; j++){
        pdf.text(list2[j][0],x,y);
        pdf.text(list2[j][1],x+27,y);
        pdf.text(list2[j][2],x+96,y);
        pdf.text(list2[j][3],x+125,y)
        y = y + 7;
      }
      y = y + 7;
      if(!printedGoals){
        pdf.setFontSize(16);
        pdf.text('Therapy Goal',x,y);
        y = y + 7;
        pdf.setFontSize(10.5);
        therapyGoal.forEach((arr,j)=>{
          pdf.text(arr[0],x,y);
          pdf.text(arr[1],x+40,y)
          pdf.text(arr[2],x+75,y);
          pdf.text(arr[3],x+125,y);
          if(j===0){
            y = y + 5;
          }
          else{
            y = y + 7;
          }
        });
        y = y - 7;

        printedGoals = true;
      }

      y = y + 15;
      // pdf.setFont('helvetica','bold');
      fontSize = pdf.getFontSize();
      for(let k = imageIndex; k < images.length; k++ ){
        let element = document.getElementsByClassName(images[imageIndex][0]);
        element = element[0];
        let image = await html2canvas(element);
        let width = 190;
        let height = image.height * width / image.width;
        remaingHeight = pageSize.height - y;
        if(remaingHeight > (height + 20)){
          y = y + 5;
          x = 20;
          pdf.setFontSize(16);
          pdf.text(images[imageIndex][1],x,y);
          y = y + 2;
          try{
            pdf.addImage(image.toDataURL('images/png',1),'JPEG',x,y,width-20,height-10);
          }
          catch(err){
            console.log('Image not included',err)
          }
          imageIndex += 1;
          y = y + height;
        }
        else{
          pdf.setFontSize(9.5)
          pdf.setFont('helvetica','normal');
          pdf.text(`Report generated by ${user_fullname}`,x,285);
          pdf.text(`${moment().format('DD MMMM YYYY')}`,x,290);
          pdf.addImage(ABMLogo,'JPEG',170,277,22,15.6);
          pdf.addPage();
          break;
        }
        if(imageIndex === images.length){
          console.log('Done-true')
          done = true;
        }
      }
      pdf.setFontSize(9.5)
      pdf.setFont('helvetica','normal');
      pdf.text(`Report generated by ${user_fullname}`,x,285);
      pdf.text(`${moment().format('DD MMMM YYYY')}`,x,290);
      pdf.addImage(ABMLogo,'JPEG',170,277,22,15.6);
    }
    pdf.save(`${patientDetails.first_name?patientDetails.first_name:'Patient'}-Details.pdf`);
    return;
  };

  const closeInfo = () => {
    setInfoPopup(false);
  };

  const getAderanceDetails = (val) => {
    patient_Profile_Therapy_ProgressBar(val);
  };
  //done poovarasan
  const therapyGraphSelectDate = (val) => {
    setOpentherapySelectDate(val);
  };

  const generateCSV = (val1,val2) => {
    setDownloadingTransmission('started');
    apiAbmBlaze
      .post(DOWNLOAD_PATIENT_ADHERE_SCORE_HISTORY, {
        profile_id: patient_details.profile_id,todate:val1,fromdate:val2
      })
      .then(async (res) => {
        setDownloadingTransmission('completed')
        if (res.data.val) {
          formatAndDownload(res.data.val);
        }
      })
      .catch((error) => {
        setDownloadingTransmission('failed');
        setOpenSnackTransmission(true);
        console.log("Error in generateCSV==>", error);
      });
  };

  const downloadFn = (data, reportName) => {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", reportName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const objectToCSV = (data) => {
    //get header
    const csvRows = [];
    if (data[0]) {
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));
      //loops over the row
      for (const row of data) {
        const values = headers.map((header) => {
          if (typeof row[header] == "object" || Array.isArray(row[header])) {
            return JSON.stringify(row[header]);
          }
          const escaped = (" " + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      //from escaped comma seprater val
      return csvRows.join("\n");
    }
  };

  const formatAndDownload = (data) => {
    // console.log('csvdata',data)
    let csv_patient_details = `Patient Name: ${patient_details.first_name} ${patient_details.last_name
      }\nPatient Email: ${patient_details.email
      }\nPatient Status:${patient_details.status}\nFrom Date:${moment(
        transmissiondate_from
      ).format("DD MMM YYYY")}\nTo Date:${moment(
        transmissiondate_to
      ).format("DD MMM YYYY")}\n\n`;
      if(data.length>0){downloadFn(
        csv_patient_details + objectToCSV(data),
        `${patient_details.first_name}_${moment(
          transmissiondate_from
        ).format("DD MMM YYYY")} to ${moment(
          transmissiondate_to
        ).format("DD MMM YYYY")}`
      );}
      else{
    downloadFn(
      csv_patient_details + `No Data Found`,
      `${patient_details.first_name}_${moment(
        transmissiondate_from
      ).format("DD MMM YYYY")} to ${moment(
        transmissiondate_to
      ).format("DD MMM YYYY")}`
    );}
  };

  const getPatientId = () => {
    if (updatedPatientId) {
      if (updatedPatientId.length) {
        return updatedPatientId.length > 1
          ? updatedPatientId.join(",")
          : updatedPatientId[0];
      } else {
        return "Patient Id";
      }
    } else if (
      patientDetails.patient_id &&
      patientDetails.patient_id.patient_id &&
      patientDetails.patient_id.patient_id.length
    ) {
      return patientDetails.patient_id.patient_id.length > 1
        ? patientDetails.patient_id.patient_id.join(",")
        : patientDetails.patient_id.patient_id[0];
    } else {
      return "Patient Id";
    }
  };

  return (
    <div className="PatienProfileWrapper">
      <SetNewTheorpyGoalModal
        showModalSetTheorpy={showModalSetTheorpy}
        callback={callBack}
      />

      <MedicalInformation isopen={medicalInfoOpen} callBack={callbackMI} />
      <Personalinformation isopen={personalInfoOpen} callBack={callbackPI} />
      {/* <AddNotePopup open={addNote} onClose={CloseAddNotePopup} /> */}
      {openPatientIdPopup ? (
        <PtientIdPopUp
          closePopup={closePatientIdPopup}
          isopen={openPatientIdPopup}
          patient_id={
            updatedPatientId && updatedPatientId.length
              ? updatedPatientId
              : patientDetails.patient_id &&
              patientDetails.patient_id.patient_id
          }
          profile_id={patientDetails.profile_id}
        />
      ) : null}

      {/* Section 1 */}
      <InfoPopup open={infoPopup} onClose={closeInfo} />
      <AlertSnackBar msg="Download Failed" flag={openSnack} anchorOrigin={{ vertical: "top", horizontal: "center" }} severity='error' onClose={handleSnack}  />
      <AlertSnackBar msg="Download Failed" flag={openSnackTransmission} anchorOrigin={{ vertical: "top", horizontal: "center" }} severity='error' onClose={handleSnackTransmission}  />

      
        <div id="divToPrint1">
          <div className="section1_part_1">
            <div className="section1_part_2">
              <div className="section1_part_2_img">
                {patientDetails && patientDetails.photo_uuid ? (
                  <img
                    alt=""
                    src={`https://ucarecdn.com/${patientDetails.photo_uuid}/`}
                    className="avt_img"
                  />
                ) : (
                  <img src={userIocnPatientProfile} className="avt_img" />
                )}
              </div>
              <div className="section1_part_2_names">
                <div className="section1_part_2_name">
                  {patientDetails.first_name} {patientDetails.last_name}
                </div>
                <div className="flex_display section1_part_2_name_2">
                  <div className="patient-profile-patient-id-wrapper">
                    <span>Patient Id - {getPatientId()}</span> &nbsp; 
                    <span>
                      <img
                      src={Pen}
                      width="20px"
                      height="20px"
                      style={{ cursor: "pointer", float:'right' }}
                      onClick={() => {
                        patientIdPopup();
                      }}
                    /></span>
                  </div>
                </div>
                <div
                  className="flex_display icon_condition"
                  style={{width:"fit-content"}}
                  onClick={() => {
                    medicalInfoHandler();
                  }}
                >
                  <Button className={classes.icons}>
                    <img src={MedicalCondition} height="12px" width="12px" />
                  </Button>
                  <div className="section1_part_2_name_3">Health Summary</div>
                </div>

                <div
                  className="flex_display icon_condition"
                  style={{width:"fit-content"}}
                  onClick={() => {
                    personalInfoHandler();
                  }}
                >
                  <Button className={classes.icons}>
                    <img src={PersonalInformation} height="12px" width="12px" />
                  </Button>
                  <div className="section1_part_2_name_3">Personal Info</div>
                </div>
                <div className="flex_display icon_condition section1_part_2_name_3">
                  <p>BiWaze Cough - {reportData?.patient_data?.device_id}</p>
                </div>
              </div>
            </div>

            <div className="section1_part_3">
              <div className=" section1_part_3_item">
                <div style={{ display: "flex" }}>
                  <div className="InactiveTextToggle">
                    {inActiveToggle ? inActiveToggle : " "}
                  </div>

                  {/* <div>
                    <PatientToggleButton
                      inactive="Inactive"
                      active="Active"
                      callTherapyToggle={callTherapyToggles}
                    />
                  </div>

                  <div className="activeTextToggle">
                    {activeToggle ? activeToggle : " "}
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* //second div */}
          <div className="flex flex-wrap">
            <div className="w-full lg : w-full adheranceScoreDiv">
              Adherence Score
            </div>
          </div>

          <div style={{ marginLeft: "70px", marginRight: "80px" }}>
            <div className="adheranceScoreDiv2">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="adheranceScoreDiv2_part1">
                  <img
                    src={Info}
                    height="25px"
                    width="25px"
                    onClick={() => setInfoPopup(true)}
                  />
                </div>
                <div
                  className="progressBarDiv"
                  style={{
                    position: "relative",
                    paddingRight: "55px",
                    bottom: "60px",
                  }}
                >
                  <ProgressBar
                    size={127}
                    progress={
                     prevscore== undefined?0:prevscore* 100}
                    />
                </div>
                <div className="dateDiv adheranceScoreDiv2_part2">
                  {/* {moment(
                    adheranceScore.length &&
                    adheranceScore[adheranceScore.length - 2].date_day
                  )
                    .utc()
                    .format("DD MMM yyyy")} */}
                </div>
              </div>
              <div className="todayScore">Today's score</div>

              <div className="AdherenceElementWeeksNew-Wrapper">
                <AdherenceElementWeeksNew
                  borderTop={true}
                  adherence_score={adheranceScore}
                  getAderanceDetails={getAderanceDetails}
                  patientDetails={patientDetails}
                  ADS_Details={ADS_Details}
                />
              </div>
            </div>
          </div>
          <div className="therapy_details">
            <div className="therapy_details_heading fnt24P">Therapy Details</div>
            <div className="therapy_details_graph">
              <div className="therapy_details_graph_heading_top ">
                <div className="therapy_details_graph_heading_top_heading">
                  <p className="therapy_details_graph_heading_top_heading_content">
                    Pause Pressure{" "}
                    <span style={{ color: "#def3a9" }}>
                      <Brightness1Icon />
                    </span>
                  </p>
                  <p className="therapy_details_graph_heading_top_heading_content">
                    Insp Pressure{" "}
                    <span style={{ color: "#afedfb" }}>
                      <Brightness1Icon />
                    </span>
                  </p>
                  <p className="therapy_details_graph_heading_top_heading_content">
                    Exp Pressure{" "}
                    <span style={{ color: "#f2b094" }}>
                      <Brightness1Icon />
                    </span>
                  </p>
                </div>
                <div>
                  <p className="fnt21P therapy_details_graph_heading_top_heading_date_display"style={{ paddingRight:'20px' }} >
                    {moment(startDate).format(" DD MMM yyyy")}
                  </p>
                  <div style={{ paddingRight:'20px' }} >
                    <Button
                      className={classes.buttonsOfSelect}
                      style={{ backgroundColor: selectButtonWeek }}
                      onClick={() => therapyGraphSelectDate(true)}
                    >
                      Select Date
                    </Button>
                    {opentherapySelectDate && (
                      <div style={{ position: "absolute", zIndex: "99" }}>
                        <DatePicker
                          selected={startDate}
                          maxDate={enddatefordatepicker}
                          showMonthDropdown
                          showYearDropdown
                          minDate= {new Date("2022-01-01")}
                          dropdownMode="select"
                          onChange={(e) => {
                            therapyGraphSelectDate(false);
                            setStartDate(e);
                            setTherapyGraphIndex(0);
                          }}
                          inline
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div className={therapyGraph.length>7 && therapyGraph?"therapy_details_graph_heading":"therapy_details_graph_heading_withoutscroll"}>
              {/* <div className="therapy_details_graph_heading_part1">
                   <p>Pressure</p> 
                </div> */}
                <div className={therapyGraph.length>7 && therapyGraph?'therapy_details_graph_heading_part2':'therapy_details_graph_heading_part3'}>
                  {therapyGraph.length && therapyGraph
                    ? therapyGraph.map((item, index) => {
                      let color = "#F2F4F8";
                      if (index == therapyGraphIndex) {
                        color = "white";
                      }
                      return (
                        <div className="w-2/12 lg:w-2/12" key={index}>
                          {item.timestamp ? <div style={{ fontSize: '13px', paddingLeft: '11px', textAlign: 'center', color: 'grey', paddingBottom: '4px' }}>
                            {moment(item.timestamp).format("hh:mm A")}</div> : null}
                          <Button
                            className={classes.buttonsOfDate}
                            style={{ backgroundColor: color }}
                            onClick={() => setTherapyGraphIndex(index)}
                          >
                            Therapy {index + 1}
                          </Button>
                        </div>
                      );
                    })
                    : null}
                </div>
              </div>
              <PatientProfileGraph
                therapyGraph={
                  therapyGraph.length ? therapyGraph[therapyGraphIndex] : null
                }
              />
            </div>
          </div>
          <div className="hmr-graph-heading">
          Therapy Trends
          </div>
          <div className="area-graph1 deliveredtopdf">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="area-graph-innerdiv"></div>
                <div>
                  <div
                    className="flex flex-row flex-wrap"
                    style={{ padding: "16px 16px" }}
                  >
                    <div className="w-2/12 lg:w-2/12">
                      <Button
                        className={classes.buttonsOfDate}
                        style={{ backgroundColor: range==='week'?'white':'#F2F4F8' }}
                        onClick={() => {
                          deliveredParaWeek();
                        }}
                      >
                        Week
                      </Button>
                    </div>
                    <div className="w-2/12 lg:w-2/12">
                      <Button
                        className={classes.buttonsOfDate}
                        style={{ backgroundColor: range==='month'?'white':'#F2F4F8' }}
                        onClick={() => {
                          deliveredParaMonth();
                        }}
                      >
                        Month
                      </Button>
                    </div>
                    <div>
                      <Button
                        aria-describedby={id4}
                        variant="contained"
                        className={classes.buttonsOfDate}
                        style={{
                          backgroundColor: range==='daterange'?'white':'#F2F4F8',
                          marginBptton: "20px",
                        }}
                        onClick={handleClickDelivered}
                      >
                        Date Range
                      </Button>
                      <Popover
                        id={id4}
                        open={open4}
                        anchorEl={anchorE4}
                        onClose={handleCloseDelivered}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Typography sx={{ p: 2 }}>
                          <div className="dataRangeFormContainer">
                            <form>
                              <div className="fromdataRange">
                                <label>From :</label>
                              </div>
                              <input
                                className="inputfeildsforallclinicProfile"
                                type="date"
                                defaultValue={deliveredDateRange.from}
                                max={maxfortrendfrom}
                                style={{ backgroundColor: "transparent" }}
                                onChange={(e) => {
                                  setDeliveredDateRange(d=>{
                                    return {...d,from:e.target.value}
                                  })
                                }}
                              />
                              <div className="todataRange">
                                <label>To :</label>
                              </div>
                              <input
                                type="date"
                                defaultValue={deliveredDateRange.to}
                                min={deliveredDateRange.from?deliveredDateRange.from:null}
                                max={defaultValue}
                                className="inputfeildsforallclinicProfile"
                                style={{ backgroundColor: "transparent" }}
                                disabled={!deliveredDateRange.from}
                                onChange={(e) => {
                                  // setAdDateRangeTo(e.target.value);
                                  setDeliveredDateRange(d=>{
                                    return {...d,to:e.target.value}
                                  })
                                }}
                              />
                              {/*  {adDateRangeTo == null && adDateRangeFrom == null ? (
                              <div className="dataRangeButton">
                                <Button style={{ width: "100%" }}>Submit</Button>
                              </div>
                            ) : ( */}
                              {(deliveredDateRange.to && deliveredDateRange.from) == undefined ?
                                <div
                                  className="dataRangeButton"
                                >
                                  <Button disabled={true} style={{ width: "100%" }}>Submit</Button>
                                </div> : <div
                                  className="dataRangeButton"
                                  onClick={handleSubmitDelivered}
                                >
                                  <Button disabled={false} style={{ width: "100%" }}>Submit</Button>
                                </div>}
                                {/* <div className="dataRangeButton">
                                  <Button disabled={true} style={{ width: "100%" }}>Submit</Button>
                                </div> */}

                              {/*   )} */}
                            </form>
                          </div>
                        </Typography>
                      </Popover>
                    </div>
                  </div>
                  <div className="fnt21P" style={{
                    textAlign: "right",
                    marginRight: "16px",
                    marginBottom: "20px",
                    fontWeight: "500",
                    color: "rgb(117 117 120)"
                  }}>
                    {" "}
                    {currentDurationDelivered === "week"
                      ? `${moment()
                        .subtract(7, "d")
                        .utc()
                        .format("DD MMM yyyy")} -${moment().add(-1,'day')
                          .utc()
                          .format(" DD MMM yyyy")}`
                      : currentDurationDelivered === "month"
                        ? `${moment()
                          .subtract(1, "M")
                          .utc()
                          .format(" DD MMM yyyy")}  -${moment().add(-1,'day')
                            .utc()
                            .format(" DD MMM yyyy")}`
                        : currentDurationDelivered === "daterange"
                          ? deliveredDateRangeonsubmitfrom && deliveredDateRangeonsubmitto && `${moment(deliveredDateRangeonsubmitfrom).format(" DD MMM yyyy")} - ${moment(
                            deliveredDateRangeonsubmitto
                          ).format(" DD MMM yyyy")}`
                          : null}
                  </div>
                </div>
            </div>
            <div>
              <DeliveredParametersGraph graphData={deliveredParametersData} dates={currentDurationDelivered==='week'?({
                from:moment().add(-7,'days').format('YYYY-MM-DD'),
                to:moment().add(-1,'days').format('YYYY-MM-DD')
              }):(currentDurationDelivered==='month'?{
                from:moment().add(-1,'M').format('YYYY-MM-DD'),
                to:moment().add(-1,'days').format('YYYY-MM-DD')
              }: {from:deliveredDateRangeonsubmitfrom,to:deliveredDateRangeonsubmitto})} />
            </div>
          </div>
        </div>
        {/* Done by poovarasan */}
        {/* THerapy goal */}
        <div id="divToPrint2">
          <div
            className="flex flex-wrap therapy_goal_section_heading"
            style={{ marginTop: "24px" }}
          >
            <div className="adheranceScoreDiv1">Therapy Goal</div>
            {patientDetails.pair_id === null ? (
              <div className="setModalButtonTheorpy" style={{ opacity: "0.5" }}>
                <Button className="setButton">
                  <i className="fas fa-lungs"></i>
                  <span>Set new therapy goals</span>
                </Button>
              </div>
            ) : (
              <div className="setModalButtonTheorpy">
                <Button
                  className="setButton"
                  onClick={showSetTheorapyGoalHnadler}
                >
                  <i className="fas fa-lungs"></i>
                  <span>Set new therapy goals</span>
                </Button>
              </div>
            )}
          </div>

          {/* Active therapy goal */}
          <div
            style={{ marginLeft: "64px", marginRight: "64px", marginTop: "16px" }}
            className = 'therapygoaltopdf'
          >
            {therapyHistory &&
              therapyHistory.slice(0, 1).map((therapy, index) => {
                return (
                  <div
                    className="activecard"
                    style={{ boxShadow: "#6A768F80 0px 3px 6px" }}
                    key={index}
                  >
                    <div style={{ display: "flex" }}>
                      <div className="ActiveBackGround">
                        <img src={activetop} className="activeIcon" />
                        <div className="activeText">Active</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "30px",
                          width: "100%",
                        }}
                      >
                        <div className="ActivetherapyDate">
                          {" "}
                          {moment(therapy.created_at).format("DD MMM YYYY")}&nbsp;-&nbsp;Present
                        </div>
                        <div className="ActiveTheropyName">
                          {patientDetails.first_name} {patientDetails.last_name}
                        </div>
                        <div className="activeCardWrapper">
                          <div className="activeTheorpyId">{getPatientId()}</div>
                          <div style={{ display: "flex", marginRight: "16px" }}>
                            <div className="ActiveTherapyNum">
                              {therapy.times_per_day}
                            </div>
                            <div className="ActiveTherapyGoals">
                              Therapies per days
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="borderInTherapycard"></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "8px",
                        marginRight: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <InAcThCheckbox checked={therapy.days_of_week.mon} />
                      <InAcThCheckbox checked={therapy.days_of_week.tue} />
                      <InAcThCheckbox checked={therapy.days_of_week.wed} />
                      <InAcThCheckbox checked={therapy.days_of_week.thu} />
                      <InAcThCheckbox checked={therapy.days_of_week.fri} />
                      <InAcThCheckbox checked={therapy.days_of_week.sat} />
                      <InAcThCheckbox checked={therapy.days_of_week.sun} />
                    </div>
                    <div className="weeksactiveDiv">
                      {weeks.map((week, index) => {
                        return (
                          <div className="inActiveWeeks" key={index}>
                            {week}
                          </div>
                        );
                      })}
                    </div>
                    <div className="inActivebottomCard">
                      {/* {console.log("therapyHistory_from_patient_profile", therapyHistory)} */}
                      <span
                        style={{ color: "#929bae", fontWeight: "normal" }}
                      >
                        set by
                      </span>{" "}
                      {therapyHistory[0].creator_firstname+ " " +therapyHistory[0].creator_lasttname}
                    </div>
                  </div>
                );
              })}
          </div>

          {/* therapy goal history */}
          <div className="flex flex-wrap" style={{ marginTop: "32px" }}>
            <div className="w-full lg : w-full therapyGoalHistory">
              Therapy Goal History
            </div>
          </div>

          {/* scrollable therapy thistory */}
          <div
            style={{ marginLeft: "64px", marginRight: "64px", marginTop: "16px" }}
            className="Therapygoalhistorydata"
          >
            {therapyHistory.length > 0 ? (
              <div className="activecontainer" id="activeCardScrollBottom">
                {therapyHistory &&
                  therapyHistory.slice(1).map((item, index) => {
                    return (
                      <div className="activediv2" key={index}>
                        <div style={{ display: "flex" }}>
                          <div className="inActiveBackGround">
                            <img src={InactiveIcon} className="inactiveIcon" />
                            <div className="inactiveText">Inactive</div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "16px",
                            }}
                          >
                            <div className="inActivetherapyDate">
                              {moment(`${item.created_at}`)
                                .utc()
                                .format("DD MMM YYYY")}&nbsp;
                              -
                              &nbsp;{moment(therapyHistory[index].created_at).format(
                                "DD MMM YYYY"
                              )}
                            </div>
                            <div className="inActiveTherapyName">
                              {patientDetails.first_name}{" "}
                              {patientDetails.last_name}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="inActiveTherapyID">
                                {getPatientId()}
                              </div>
                              <div style={{ display: "flex" }}>
                                <div className="inActiveTherapyNum">
                                  {item.times_per_day}
                                </div>
                                <div className="inActiveTherapyGoals">
                                  Therapies per day
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="borderInTherapycard"></div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginLeft: "8px",
                            marginRight: "8px",
                            marginTop: "8px",
                          }}
                        >
                          <InAcThCheckbox checked={item.days_of_week.mon} />
                          <InAcThCheckbox checked={item.days_of_week.tue} />
                          <InAcThCheckbox checked={item.days_of_week.wed} />
                          <InAcThCheckbox checked={item.days_of_week.thu} />
                          <InAcThCheckbox checked={item.days_of_week.fri} />
                          <InAcThCheckbox checked={item.days_of_week.sat} />
                          <InAcThCheckbox checked={item.days_of_week.sun} />
                        </div>

                        <div className="weeksInactiveDiv">
                          {weeks.map((week, index) => {
                            return (
                              <div className="inActiveWeeks" key={index}>
                                {week}
                              </div>
                            );
                          })}
                        </div>

                        <div className="inActivebottomCard">
                          <span
                            style={{ color: "#929bae", fontWeight: "normal" }}
                          >
                            set by
                          </span>{" "}
                          {`${item.creator_firstname} ${item.creator_lasttname}`}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </div>

          <div className="hmr-graph-heading">Hour Meter Reading (HMR) graph</div>

          <div className="area-graph hmrtopdf">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="area-graph-innerdiv">Duration (mins)</div>
              <div>
                <div
                  className="flex flex-row flex-wrap"
                  style={{ padding: "16px 16px" }}
                >
                  <div className="w-2/12 lg:w-2/12">
                    <Button
                      className={classes.buttonsOfDate}
                      style={{ backgroundColor: selectButtonWeek2 }}
                      onClick={() => {
                        weekFunc2();
                      }}
                    >
                      Week
                    </Button>
                  </div>
                  <div className="w-2/12 lg:w-2/12">
                    <Button
                      className={classes.buttonsOfDate}
                      style={{ backgroundColor: selectButtonMonth2 }}
                      onClick={() => {
                        monthFunc2();
                      }}
                    >
                      Month
                    </Button>
                  </div>
                  <div className="w-2/12 lg:w-2/12">
                    <Button
                      aria-describedby={id2}
                      variant="contained"
                      className={classes.buttonsOfDate}
                      style={{
                        backgroundColor: selectButtonDateRange2,
                        marginBptton: "20px",
                      }}
                      onClick={handelClick2}
                    >
                      Date Range
                    </Button>
                    <Popover
                      id={id2}
                      open={open2}
                      anchorEl={anchorE2}
                      onClose={handleClose2}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        <div className="dataRangeFormContainer">
                          <form>
                            <div className="fromdataRange">
                              <label>From :</label>
                            </div>
                            <input
                              className="inputfeildsforallclinicProfile"
                              type="date"
                              // min={HMRToDate}
                              max={maxforhmrfrom}
                              style={{ backgroundColor: "transparent" }}
                              defaultValue={HMRFromDate}
                              onChange={(e) => {
                                setHMRFromDate(e.target.value);
                              }}
                            />
                            <div className="todataRange">
                              <label>To :</label>
                            </div>
                            <input
                              type="date"
                              className="inputfeildsforallclinicProfile"
                              style={{ backgroundColor: "transparent" }}
                              defaultValue={HMRToDate}
                              min={HMRFromDate?HMRFromDate:null}
                              disabled={!HMRFromDate}
                              max={defaultValue}
                              onChange={(e) => {
                                setHMRToDate(e.target.value);
                              }}
                            />
                            {HMRToDate === null || HMRFromDate === null ? <div
                              className="dataRangeButton"
                              // onClick={handleClose2}
                            >
                              <Button disabled={true} style={{ width: "100%" }}>Submit</Button>
                            </div> : <div
                              className="dataRangeButton"
                              onClick={handleClose2onsubmit}
                            >
                              <Button disabled={false} style={{ width: "100%" }}>Submit</Button>
                            </div>}
                          </form>
                        </div>
                      </Typography>
                    </Popover>
                  </div>
                </div>
                <div
                  className="fnt21P"
                  style={{
                    textAlign: "right",
                    marginRight: "16px",
                    marginBottom: "20px",
                    fontWeight: "500",
                    color: "rgb(117 117 120)"
                  }}
                >
                  {" "}
                  {/* 1 Jan 2022 - 31 Jan 2022 */}
                  {currentDurationHMR === "week"
                    ? `${moment()
                      .subtract(7, "d")
                      .utc()
                      .format("DD MMM yyyy")} -${moment().subtract(1,'day')
                        .utc()
                        .format(" DD MMM yyyy")}`
                    : currentDurationHMR === "month"
                      ? `${moment()
                        .subtract(1, "M")
                        .utc()
                        .format(" DD MMM yyyy")}  -${moment().subtract(1,'day')
                          .utc()
                          .format(" DD MMM yyyy")}`
                      : currentDurationHMR === "daterange"
                        ? HMRToDateonsubmit && HMRFromDateonsubmit && ` ${moment(
                          HMRFromDateonsubmit
                        ).format(" DD MMM yyyy")} - ${moment(HMRToDateonsubmit).format(" DD MMM yyyy")}`
                        : ""}
                </div>
              </div>
            </div>
            <AreaGraph
              xaxislabel={
                hmrgraphbooleanval == true
                  ? hmrscoregraphdaysdaterange
                  : hmrscoregraphdays
              }
              value={hmrGraphvalue}
              firstdate={hmrGraphFirstdate}
            />
          </div>

          {/* third compoenet heading */}
          <div className="adherance-score-history">Adherence Score History</div>

          {/* //Adherance grph */}
          <div className="area-graph topdf">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="area-graph-innerdiv">Percentage</div>
              <div>
                <div
                  className="flex flex-row flex-wrap"
                  style={{ padding: "16px 16px" }}
                >
                  <div className="w-2/12 lg:w-2/12">
                    <Button
                      className={classes.buttonsOfDate}
                      style={{ backgroundColor: selectButtonWeek }}
                      onClick={() => {
                        weekFunc();
                      }}
                    >
                      Week
                    </Button>
                  </div>
                  <div className="w-2/12 lg:w-2/12">
                    <Button
                      className={classes.buttonsOfDate}
                      style={{ backgroundColor: selectButtonMonth }}
                      onClick={() => {
                        monthFunc();
                      }}
                    >
                      Month
                    </Button>
                  </div>
                  <div>
                    <Button
                      aria-describedby={id}
                      variant="contained"
                      className={classes.buttonsOfDate}
                      style={{
                        backgroundColor: selectButtonDateRange,
                        marginBptton: "20px",
                      }}
                      onClick={handleClick}
                    >
                      Date Range
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        <div className="dataRangeFormContainer">
                          <form>
                            <div className="fromdataRange">
                              <label>From :</label>
                            </div>
                            <input
                              className="inputfeildsforallclinicProfile"
                              type="date"
                              max={maxforadhfrom}
                              defaultValue={moment(adDateRangeFrom).add(1,'day').format('YYYY-MM-DD')}
                              style={{ backgroundColor: "transparent" }}
                              onChange={(e) => {
                                let selecteddaste=moment(e.target.value).subtract(1 ,'days').format('YYYY-MM-DD')
                                setAdDateRangeFrom(selecteddaste);
                              }}
                            />
                             <div className="todataRange">
                              <label>To :</label>
                            </div>
                            <input
                              type="date"
                              className="inputfeildsforallclinicProfile"
                              style={{ backgroundColor: "transparent" }}
                              max={defaultValue}
                              min={adDateRangeFrom?moment(adDateRangeFrom).add(1,'day').format('YYYY-MM-DD'):null}
                              disabled={!adDateRangeFrom}
                              defaultValue={moment(adDateRangeTo).add(1,'day').format('YYYY-MM-DD')}
                              onChange={(e) => {
                                
                                let selecteddaste=moment(e.target.value).subtract(1 ,'days').format('YYYY-MM-DD')
                                setAdDateRangeTo(selecteddaste);
                              }}
                            />
                            {/*  {adDateRangeTo == null && adDateRangeFrom == null ? (
                            <div className="dataRangeButton">
                              <Button style={{ width: "100%" }}>Submit</Button>
                            </div>
                          ) : ( */}
                            {adDateRangeTo == null || adDateRangeFrom == null ?
                              <div
                                className="dataRangeButton"
                              /*  onClick={handleClose} */
                              >
                                <Button disabled={true} style={{ width: "100%" }}>Submit</Button>
                              </div> : <div
                                className="dataRangeButton"
                                onClick={handleCloseonsubmit}
                              >
                                <Button disabled={false} style={{ width: "100%" }}>Submit</Button>
                              </div>}

                            {/*   )} */}
                          </form>
                        </div>
                      </Typography>
                    </Popover>
                  </div>
                </div>
                <div className="fnt21P" style={{
                  textAlign: "right",
                  marginRight: "16px",
                  marginBottom: "20px",
                  fontWeight: "500",
                  color: "rgb(117 117 120)"
                }}>
                  {" "}
                  {currentDurationASH === "week"
                    ? `${moment()
                      .subtract(7, "d")
                      .utc()
                      .format("DD MMM yyyy")} -${moment().add(-1,'day')
                        .utc()
                        .format(" DD MMM yyyy")}`
                    : currentDurationASH === "month"
                      ? `${moment()
                        .subtract(1, "M")
                        .utc()
                        .format(" DD MMM yyyy")}  -${moment().add(-1,'day')
                          .utc()
                          .format(" DD MMM yyyy")}`
                      : currentDurationASH === "daterange"
                        ? adDateRangeToonsubmit && adDateRangeFromonsubmit && `${moment(
                          adDateRangeFromonsubmit
                        ).add(1,'days').format(" DD MMM yyyy")} - ${moment(adDateRangeToonsubmit).add(1,'days').format(" DD MMM yyyy")}`
                        : null}
                </div>
              </div>
            </div>
            <AreaGraphAdheranceScore
              xaxislabel={
                adherancegraphbooleanval == true
                  ? adscoregraphdaysdaterange
                  : adscoregraphdays
              }
              value={adherancegraphbooleanval == true ? addateRange : adgrval}
              currentDurationASH={currentDurationASH}
            />
          </div>

          {/* Hidden Graph */}
          
        </div>
      

      <div
        style={{
          marginLeft: "72px",
          marginRight: "68px",
          paddingBottom: "72px",
        }}
      >
        <div className="setTheorypFooter">
          <div className="download-adherencescore-histroy-wrapper">
            <span>{downloadingReport==='started'?'Downloading...':'Download'}</span>
            <img
              src={PDF}
              className="pdfimage"
              title="Download PDF"
              alt="Pdf icon"
              // onClick={handleClickpdf}
              onClick={async (event)=>{
                handleClickpdf(event);
                // getCharts(undefined,patientDetails.profile_id,querryParamspatientDetails.pair_id,loggedInUser.id,querryParamspatientDetails.clinic_id,'2023-04-03','2023-04-24');
              }}
            />
            <Popover
                id={id5}
                open={open5}
                anchorE5={anchorE5}
                onClose={handleclosepdf}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 400, left: 450 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography sx={{ p: 2 }}>
                  <div className="dataRangeFormContainer">
                    <form>
                      <div className="fromdataRange">
                        <label>From :</label>
                      </div>
                      <input
                        className="inputfeildsforallclinicProfile"
                        type="date"
                        defaultValue={pdfDates.from}
                        max={maxforpdffrom}
                        style={{ backgroundColor: "transparent" }}
                        onChange={(e) => {
                          setPdfDates(d=>{
                            return {...d,from:e.target.value}
                          })
                        }}
                      />
                      <div className="todataRange">
                        <label>To :</label>
                      </div>
                      <input
                        id="dateRequired" type="date" name="dateRequired"
                         defaultValue={pdfDates.to}
                         min={pdfDates.from?pdfDates.from:null}
                         disabled={!pdfDates.from}
                         max={defaultValue}
                        //  key={`${Math.floor((Math.random() * 1000))}-min`}
                        className="inputfeildsforallclinicProfile"
                        style={{ backgroundColor: "transparent" }}
                        onChange={(e) => {
                          setPdfDates(d=>{
                            return {...d,to:e.target.value}
                          })
                        }}
                      />
                      {(pdfDates.from&&pdfDates.to) == undefined ?
                        <div
                          className="dataRangeButton"
                        
                        >
                          <Button disabled={true} style={{ width: "100%" }}>Submit</Button>
                        </div> : <div
                          className="dataRangeButton"
                          onClick={async ()=>{
                            // await preparePdf();
                            // setNewDataArrived(true);
                            let patientFullname = (patientDetails.first_name?patientDetails.first_name:'')+(patientDetails.last_name?(patientDetails.first_name?' '+patientDetails.last_name:patientDetails.last_name):'');
                            setAnchorE5(null);
                            setDownloadingReport('started')
                            try{
                              await getCharts(undefined,patientDetails.profile_id,querryParamspatientDetails.pair_id,loggedInUser.id,querryParamspatientDetails.clinic_id,pdfDates.from,pdfDates.to,patientFullname);
                              setDownloadingReport('completed')
                            }
                            catch(e){
                              setDownloadingReport('failed')
                              setOpenSnack(true)
                            }
                            // setPdfDates({from:undefined,to:undefined});
                          }}
                        >
                          <Button disabled={false} style={{ width: "100%" }}>Submit</Button>
                        </div>}

                      {/*   )} */}
                    </form>
                  </div>
                </Typography>
            </Popover>
            
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span>{downloadingTransmission==='started'?'Downloading Transmissions...':'Download Transmissions'}</span>
            <img
              src={excelIcon}
              className="excelIcon"
              title="Download CSV"
              alt="Excel icon"
              onClick={handleClickcsv}
            />
          </div>
          <Popover
                      id={id3}
                      open={open3}
                      anchorEl={anchorE3}
                      onClose={handlecloseforcsv}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        <div className="dataRangeFormContainer">
                          <form>
                            <div className="fromdataRange">
                              <label>From :</label>
                            </div>
                            <input
                              className="inputfeildsforallclinicProfile"
                              type="date"
                              max={maxfortransfrom}
                              defaultValue={transmissiondate_from}
                              style={{ backgroundColor: "transparent" }}
                              onChange={(e) => {
                                settransmissiondate_from(e.target.value);
                              }}
                            />
                            <div className="todataRange">
                              <label>To :</label>
                            </div>
                            <input
                               id="dateRequired" type="date" name="dateRequired"
                               max={defaultValue}
                               min={transmissiondate_from?transmissiondate_from:null}
                               disabled={!transmissiondate_from}
                               defaultValue={transmissiondate_to}
                              //  key={`${Math.floor((Math.random() * 1000))}-min`}
                              className="inputfeildsforallclinicProfile"
                              style={{ backgroundColor: "transparent" }}
                              onChange={(e) => {
                                settransmissiondate_to(e.target.value);
                              }}
                            />
                            {transmissiondate_to === null || transmissiondate_from === null ?
                              <div
                                className="dataRangeButton"
                              
                              >
                                <Button disabled={true} style={{ width: "100%" }}>Submit</Button>
                              </div> : <div
                                className="dataRangeButton"
                                onClick={handlecloseforcsvonsubmit}
                              >
                                <Button disabled={false} style={{ width: "100%" }}>Submit</Button>
                              </div>}

                            {/*   )} */}
                          </form>
                        </div>
                      </Typography>
            </Popover>
          {/* <div className="Msg">
            <div className="footerIcon">
              <img src={MSG} />
            </div>
            <p>
              <Link to="/app/chat">message</Link>
            </p>
          </div> */}
          {/* <div className="notes" onClick={() => openAddNotePopup()}>
            <div className="footerIcon">
              <img src={Notes} />
            </div>
            <p>note</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    navFromReportSearchToPatientProfile:
      store.user.navFromReportSearchToPatientProfile?.profile_id,
    patient_details: store.user.navFromReportSearchToPatientProfile,
    adherancegraphbooleanval: store.user.adherancegraphboolean,
    hmrgraphbooleanval: store.user.hmrgraphboolean,
    hmrGraphDateRangeval: store.user.hmrGraphDateRange,
    deliveredParameters: store.user.deliveredParameters,
    adheranceScoreGraphDateRangeval: store.user.adheranceScoreGraphDateRange,
    adheranceScoreGraphDateRangeval: store.user.adheranceScoreGraphDateRange,
    therapyGraph: store.user.patientProfileTherapyGraph,
    ADS_Details: store.user.patientProfile_ADS_ProgressBar,
    updatedPatientId: store.user.updated_patient_id,
    // particularUserReportSearchVal: store.user.particularUserReportSearchVal,
  };
};

export default connect(mapStateToProps, {
  fetchNotedata,
  navReportSearchToPatientProfile,
  reportsSearch,
  loadDevice,
  adheranceScoreGraphMap,
  adheranceScoreMap,
  adheranceScoreDateRange,
  hmrGraph,
  hmrGraphDateRange,
  deliveredParameters,
  getCharts,
  adherenceGeaphBoolean,
  hmrGraphBoolean,
  pageHeading,
  patient_Profile_Therapy_Graph,
  patient_Profile_Therapy_ProgressBar,
  set_updated_patient,
  reportsSearchUser,
  getPatientStatus,
})(PatienProfile);
