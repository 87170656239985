import React from "react";
import './notificationSummaryCards.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
//slider
import Slider from '@mui/material/Slider';
import ToggleButton from "react-toggle-button";
import "../../assets/styles/font.css";

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "yellow",
      },
      track: {
        color: 'red'
      },
      rail: {
        color: 'black'
      }
    }
  }
});


const useStyles = makeStyles((theme) => ({
  mycard: {
    backgroundColor: '#FFFFFF',
    boxShadow: '#6A768F80 0px 3px 6px',
    borderRadius: '8px',
    marginRight: '30px',
    marginBottom: '46px',
 
  },
  cardcontent: {
    color: '#3D446F',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    padding: '0px',
   
  },
  cardactions: {
    display: 'flex',
  },
  slider: {
    width: 150,
  },
  root: {
    width: 190,
  },
  rail: {
    color: "yellow"
  },
  track: {
    color: "green"
  }
}));

const NotificationSummaryCards = (props) => {
  const classes = useStyles();
  const [adherenceValue, setAdherenceValue] = React.useState(75);
  const [spo2Value, setspo2Value] = React.useState(0);

  const adherenceSlider = (event, newValue) => {
    setAdherenceValue(newValue);
  };

  const spo2Slider = (event, newValue) => {
    setspo2Value(newValue);
  };

  const notificationSummary = useSelector((store) => {
      return store.notification.notificationSummary
  })


  
  function valueLabelFormatAdherence() {
    const units = "%";
    
  return `0 - ${(notificationSummary != null && notificationSummary.length) &&  notificationSummary[0].notification_settings.adherence ? notificationSummary[0].notification_settings.adherence  : 0 }${units}` //done by dhanush
  }

  function valueLabelFormatSpO() {
    const units = "%";
   
  return `0 - ${(notificationSummary != null && notificationSummary.length) && notificationSummary[0].notification_settings.spo2 ? notificationSummary[0].notification_settings.spo2   : 0 }${units}` // done by dhanush
  }

  return (
    <div>

      <Card variant="outlined" className={classes.mycard} style={{ background: props.cardcolor }}>
        <CardContent className={classes.cardcontent}>
          {props.cardContent}
        </CardContent>
        <div >
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {props.description === 'Adherence' ?

              <div className="notifySummaryBlueBgImage">
                <div
                  className="sliderWrapper">
                  <ThemeProvider theme={muiTheme}>
                    <Slider
                     
                      value = {(notificationSummary != null && notificationSummary.length) && notificationSummary[0].notification_settings.adherence ? notificationSummary[0].notification_settings.adherence  : 0} //done by dhanush
                      onChange={adherenceSlider}
                      valueLabelDisplay="on"
               
                      disabled={true}
                      aria-label="percent"
                      getAriaValueText={valueLabelFormatAdherence}
                      valueLabelFormat={valueLabelFormatAdherence}
                     
                    />
                  </ThemeProvider>
                </div>
                <div className="descriptionwrapper fnt32P">{props.description}</div>
              </div>
              : null}

            {props.description === 'SpO2' ?
              <div className="notifySummaryRedBgImage">
                <div
                  className="sliderWrapper"
                >
                  <Slider
                   
              value = {(notificationSummary != null && notificationSummary.length) && notificationSummary[0].notification_settings.spo2 ? notificationSummary[0].notification_settings.spo2   : 0 } //done by dhanush
                   onChange={spo2Slider}
                    valueLabelDisplay="on"
                    aria-labelledby="range-slider"
                    disabled={true}
                    aria-label="percent"
                    getAriaValueText={valueLabelFormatSpO}
                    valueLabelFormat={valueLabelFormatSpO}
                   
                  />
                </div>
                <div className="descriptionwrapper fnt32P">{props.description}</div>
              </div>
              : null}

            {props.descriptions === 'Therapy Adjustments' ?
              <div className="notifySummaryGreenBgImage">
                <div className="radio_button_notification_summary">
                  <div className="offtextWrapper fnt32P">OFF </div>
                  <ToggleButton
                    inactiveLabel={''}
                    activeLabel={''}
                    colors={{
                      active: {
                        base: '#8ea817',
                      }
                    }}
                   
                 value = {(notificationSummary != null && notificationSummary.length) &&  notificationSummary[0].notification_settings.therapyAdjustment ? notificationSummary[0].notification_settings.therapyAdjustment : false } //done by dhanush
                 />
                  <div className="ontextWrapper fnt32P">ON </div>
                </div>
                <div className="descriptionwrapper fnt32P">{props.descriptions}</div>

               
              </div>
              : null}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default NotificationSummaryCards;

