
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import "./CaresiteSettingDashboard.css";
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



export default function InactiveAlert(props) {


    const excuted = () => {
      props.excuted(true);
      props.callBackToParent();  
    }
    
    const handleClose = () => {
     
        props.callBackToParent();   
    
    }
    
    return (
        <div style={{width:"650px"}}>
            <Dialog
                open={props.isOpen}
                onClose={props.callBackToParent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className="inActiveHedingTitle" style={{color: "#6a768f"}}>
                    Withdraw Profile
                    </div>
                </DialogTitle>
                <DialogContent style={{padding:"0px 20px 20px 20px"}}>
                    <DialogContentText id="alert-dialog-description">
                        <div className="SubTitleforInactive" style={{textAlign:"center" ,color: "rgb(118 118 118)",fontWeight : "400",fontFamily : "Roboto"}}>
                        You will no longer be able to view the patient's information. 
                        <br></br>Do you want to proceed?
                        </div>
                    </DialogContentText>
                </DialogContent>

                {/* <DialogActions> */}
                <div className="bottomContainerForAlert">
                <Button className="cancelButtonRevokeAlert" onClick={excuted}>Yes</Button>
                    <div className="vl"></div>
                    <Button  className="cancelButtonRevokeAlert" onClick={handleClose}>No</Button>
                </div>
                {/* </DialogActions> */}
            </Dialog>
        </div>
    );
}



