import React from "react";
import whiteSeparatortwo from "../../../src/assets/img/Group 16174.png";

const SeparatorBottomSection = () => {
    return (
        <div> 
                <img src={whiteSeparatortwo} style={{ width : "100%"}}></img>
        </div>
    );
} 
export default SeparatorBottomSection;