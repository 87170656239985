import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import './style.css';
const InputField = forwardRef((props, ref) => {
  const [value, setValue] = React.useState(props.defaultValue);
  const [error, setError] = React.useState('');
  const [isDirty, setIsDirty] = React.useState(false);
  const [passHide, setPassHide] = React.useState(false);
  const [passwordConditions, setPasswordConditions] = React.useState([]);
  const [isPasswordField, setIsPasswordField] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = React.useState(false);
  const inputRef = React.useRef();
  // const [showPasswordConditions, setShowPasswordConditions] = useState(false);
  useEffect(() => {
    if (isDirty) {
      setError('');

      validate();
      props.handleInputChange(props.name, value);
    }
  }, [value]);

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);
  useEffect(() => {
    setIsPasswordField(props.validation && props.validation.includes('passwordpattern'));
  }, [props.validation]);
  
const handleFocus = () => {
  setFocused(true);

  // Check if password pattern is present in validation rules
  const hasPasswordPattern = props.validation && props.validation.includes('passwordpattern');
  setIsPasswordField(hasPasswordPattern);

  // Set isPasswordField based on whether password pattern is present
  setIsPasswordFocused(true);
};

const handleBlur = () => {
  setFocused(false);

  // Check if password pattern is present in validation rules
  const hasPasswordPattern = props.validation && props.validation.includes('passwordpattern');

  // Set isPasswordField based on whether password pattern is present
  setIsPasswordField(hasPasswordPattern);
  setIsPasswordFocused(false);
};

  var pattern = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
  );
  const focusInput = () => {
    // Check if the inputRef is available
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const validate = () => {
    //return true if is valid
    //else return false

     if(props.submitclicked){
       setFocused(true)
       setIsPasswordFocused(true)}
    if (props.validation) {
      const rules = props.validation.split('|');
      // console.log("RULES:",rules)
      const conditions = [];
      for (let i = 0; i < rules.length; i++) {
        const current = rules[i];

        if (current === 'required') {
          if (!value) {
            let Email = 'Email';
            let Firstname = 'First name';
            let Lastname = 'Last name';
            let Phonenumber = "Phone number";
            let Username = 'Username';
            let Username_email = 'Username or email';
            conditions.push({
              text: 'Password must be at least 8 characters',
              isValid: false,
            },
            {text: 'Contains at least one uppercase letter',
            isValid: false,
            },
            {
              text: 'Contains at least one lowercase letter',
              isValid: false,
            },
            {
              text: 'Contains at least one digit',
              isValid: false,
            },
            {
              text: 'Contains at least one special character',
              isValid: false,
            }
            );
            setPasswordConditions(conditions);
            setError("Required ");
            return false;
          }
        }

        const pair = current.split(':');
        // console.log("Pair:===>",pair)
        switch (pair[0]) {
          case 'min':
            if (value&&value.length < pair[1]) {
              setError(`Must be at least ${pair[1]} characters`);
              return false;
            }
            break;
          case 'max':
            if (value&&value.length > pair[1]) {
              setError(
                `This input must be no longer than ${pair[1]} characters long`
              );
              return false;
            }
            break;
          case 'emailpattern':
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
              setError(` Invalid  ${props.name} `);
              return false;
            }
            break;
          case 'userpattern':
            if (!/^[0-9a-zA-Z.: @_-]+$/.test(value)) {
              setError(
                `charcters allowed ['A-Z','a-z','0-9', '@', '.', '-', '_', ':' ]`
              );
              return false;
            }
            break;
            case 'passwordpattern':
              if (current === 'passwordpattern') {
                if (value && value.length >= 8) {
                  // console.log('coming here ',value)
                  conditions.push({
                    text: 'Password must be at least 8 characters',
                    isValid: true,
                  });
                } else {
                  conditions.push({
                    text: 'Password must be at least 8 characters',
                    isValid: false,
                  });
                }
                if (/[A-Z]/.test(value)) {
                  conditions.push({
                    text: 'Contains at least one uppercase letter',
                    isValid: true,
                  });
                } else {
                  conditions.push({
                    text: 'Contains at least one uppercase letter',
                    isValid: false,
                  });
                }
                if (/[a-z]/.test(value)) {
                  conditions.push({
                    text: 'Contains at least one lowercase letter',
                    isValid: true,
                  });
                } else {
                  conditions.push({
                    text: 'Contains at least one lowercase letter',
                    isValid: false,
                  });
                }
                if (/\d/.test(value)) {
                  conditions.push({
                    text: 'Contains at least one digit',
                    isValid: true,
                  });
                } else {
                  conditions.push({
                    text: 'Contains at least one digit',
                    isValid: false,
                  });
                }
                if (/[-+_!@#$%^&*.,?]/.test(value)) {
                  conditions.push({
                    text: 'Contains at least one special character',
                    isValid: true,
                  });
                } else {
                  conditions.push({
                    text: 'Contains at least one special character',
                    isValid: false,
                  });
                }
              }
              setPasswordConditions(conditions);
              // console.log('returning',!(conditions.some(obj=>!obj.isValid)))
              if(conditions.some(obj=>!obj.isValid)){return false}
            break;
            case 'alphabetsOnly':{
              let regx = /^[A-Za-z]*$/
              let flagi = false;
              let arr = value.split(' ')
              for(let i = 0; i < arr.length; i++){
                if(!regx.test(arr[i])){
                  flagi = true;
                  break;
                }
              }
              if(flagi){
                setError(`should contain only alphabets and/or space`);
                return false;
              }
              break;
            };
            case 'genericPhonenumber':{
              const regex = /^[0-9]+$/;
              if(!regex.test(value)){
                setError('Enter valid phone number');
                return false;
              }
              break;
            }
            case 'alphanumeric':{
              let regx = /^[a-zA-Z0-9]+$/;
              
              if(!regx.test(value)){
                setError(`should contain only alphanumeric chars`);
                return false;
              }
              break;
            }
          case 'passwordmatch':
            if (!value) {
              setError(`Password must match `);
              return false;
            } else if (value != props.password) {
              //console.log('value',value,props.password)
              setError(`Password must match `);
              return false;}
              else if (value === props.password) {
                console.log('value1',value)
                setError('');
            }
            break;
          case 'onlynumber':
            if (!/^[0-9]+$/.test(value)) {
              //isNaN(value)
              setError(`Please enter valid ${props.name}`);
              return false;
            }
            break;
          case 'website':if(!value){break}
            if (!/^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/.test(value)) {
              //isNaN(value)

              setError(`Please enter valid ${props.name}`);
              return false;
            }
            break;
          case 'accountnumber':
            if (!/^[A-Z {3}]+[0-9{9}]+$/.test(value)) {
              setError(`charcters allowed ['A-Z','0-9' ]`);
              return false;
            }
            break;
          case 'username':
            if (!/^[0-9a-zA-Z._-]+$/.test(value)) {
              //isNaN(value)
              setError(`Charcters Allowed ['A-Z','a-z','0-9','.', '-', '_' ]`);
              return false;
            }
            break;
          default:
            // return false;
            break;
        }
      }
    }

    return true;
  };

  useImperativeHandle(ref, () => {
    return {
      validate: () => validate(),
      focusInput,
    };
  });

  return (
    <div>
      <input
      ref={inputRef}
        key={props.key}
        type={props.isPassword && passHide ? "text" : props.type}
        className={props.className}
        placeholder={props.placeholder}
        name={props.name}
        onChange={(e) => {
          setValue(e.target.value);
          setIsDirty(true);
        }}
        autoFocus={props.autofocus}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={props.value}
        onKeyDown={props.onKeyDown}
        defaultValue={props.defaultValue ? props.defaultValue : value}
        disabled={props.disabled}
        style={{paddingRight:'40px'}}
      />

      {props.isPassword ? <div className={props.feildinchangepwd?"passwrdHideIconforchangepwd":"passwrdHideIcon"}>
        <button
          onClick={() => setPassHide(!passHide)}
        >
          {
            passHide ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash"></i>
          }
        </button>
      </div> : null}
       {focused && isPasswordFocused && isPasswordField  ? (
        <>
                    
          <p
            style={{
              color: 'red',
              fontSize: '12px',
              textAlign: 'left',
              paddingTop: '12px',
              marginLeft: '2px',
            }}
          >
            {error}
          </p>
          {passwordConditions.map((condition, index) => (
            <p
              key={index}
              style={{
                color: condition.isValid ? 'green' : 'red',
                fontSize: '12px',
                textAlign: 'left',
                paddingTop: '12px',
                marginLeft: '2px',
              }}
            >
              {condition.isValid ? (
                <i className="fa fa-check" style={{ marginRight: '5px' }} />
              ) : (
                <i className="fa fa-times" style={{ marginRight: '5px' }} />
              )}
              {condition.text}
            </p>
          ))}
        </>
      ) :      <p
      style={{
        color: 'red',
        fontSize: '12px',
        textAlign: 'left',
        paddingTop: '12px',
        marginLeft: '2px',
      }}
    >
      {error}
    </p>}
    </div>
  );
});

export default InputField;

// i remove the name from error(line_number->43) -> bishal

/* `${props.name === 'email' ?
                Email : props.name === 'firstname' ?
                  Firstname : props.name === 'lastname' ?
                    Lastname : props.name === 'username' ?
                      Username : props.name === 'username_email' ?
                        Username_email : props.name === 'phonenumber' ?
                          Phonenumber : props.name} is required` */