import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logIn, logOut, pageHeading } from "../../redux/user/user.action";
import "./UserDropdown.css"
import {
  UserProfileUpload
} from '../../redux/invitation/components/invitation.action';
import Popover from "@material-ui/core/Popover";
import '../../assets/styles/font.css';
import { makeStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import profilePicture from "../../../src/assets/img/Top nav profile icon.png";

const useStyles = makeStyles((theme) => ({
  avt: {
    height: "60px",
    width: "60px",
  },

}));

const UserDropdown = (props) => {
  const classes = useStyles();
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const [viewDropDown, setViewDropDown] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
    setViewDropDown(false);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    // localStorage.removeItem("roles");
    // localStorage.removeItem("user");

    // history.push("/");               //commented by sujan 27-01-2022
    // props.logIn(null);               //commented by sujan 27-01-2022
    props.logOut();
    props.pageHeading("Dashboard")
    // window.location.reload();        //commented by sujan 27-01-2022
  };

  const handleViewDropDown = () => {
    setViewDropDown(true);
  };
  useEffect(() => {
    props.UserProfileUpload();
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setViewDropDown(false);
    setAnchorEl(null);
  };

  const handleSubclose = () => {
    setViewDropDown(false);
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className="text-blueGray-500 "
        style={{ cursor: 'pointer' }}
        ref={btnDropdownRef}
        onClick={handleClick}
      >
        <div className="lg:ml-12" >
          <div className="items-center flex">
            <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">

              {props.UserProfileUpdated.photo_uuid ? <Avatar
                alt="profile pic"
                className={classes.avt}
                src={`https://ucarecdn.com/${props.UserProfileUpdated.photo_uuid}/`}
              /> : props.user.photo_uuid ?
                <Avatar
                  alt="..."
                  className={classes.avt}
                  src={`https://ucarecdn.com/${props.user.photo_uuid}/`}
                />
                :
                <img src={profilePicture} alt="default pic"></img>
                // <Avatar
                //   alt=".."
                //   className={classes.avt}
                //   src={`https://ucarecdn.com/${props.UserProfileUpdated.photo_uuid}/`}
                // />
              }
            </span>

          </div>
          {/* <TocIcon /> */}
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="UserdropdownWrapper"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          ref={popoverDropdownRef}
          style={{ width: '260px' }}
        >

          <ul>
            {props.user.role.toLowerCase() === "clinicadmin" ? (
              <li>
                <Link
                  onClick={() => {
                    closeDropdownPopover();
                  }}
                  className="dropdownTextWrapper fnt24P"
                  to="/app/caresitesettingdashboard"
                >
                  {" "}

                  Care Site settings
                </Link>
                <hr></hr>
              </li>
            ) : null}


            {(props.user.role.toLowerCase() === "abmadmin") || (props.user.role.toLowerCase() === "superadmin") || (props.user.role.toLowerCase() === "clinicadmin") ||
              (props.user.role.toLowerCase() === "clinician") ? (
              <li>
                <div
                  onClick={() => {
                    handleViewDropDown();
                  }}
                  className="dropdownTextWrapper fnt24P"
                >
                  User profile
                </div>
              </li>) : null}
            <hr></hr>

            <li onClick={() => handleLogout()}>
              <Link
                className="dropdownTextWrapper fnt24P"
                to="/"
              >
                Log Out
              </Link>
            </li>
          </ul>
          {viewDropDown ?
            <div style={{ display: 'flex', justifyContent: 'flex-start', boxShadow: "0px 0px 6px #6A768F38" }}>
              <div className="subDropDown">
                <ul style={{ width: '100%' }}>
                  <li>
                    <Link
                      onClick={() => {
                        handleSubclose();
                      }}
                      className="dropdownTextWrapper fnt24P"
                      to="/app/settings"
                    >
                      Edit user profile
                    </Link>
                  </li>
                  <hr />
                  <li>
                    <Link
                      onClick={() => {
                        handleSubclose()
                      }}
                      className="dropdownTextWrapper fnt24P"
                      to="/app/PasswordAndSecurity"
                    >
                      {/*  Password &amp; */} Settings
                    </Link>
                  </li>
                </ul>
              </div>
              <i className="fas fa-caret-right fa-2x drpopdowntooltipWrapper"></i>

            </div>
            : null
          }
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    user: store.user.user,
    UserProfileUpdated: store.invitation.UserProfileUpdated,
  };
};

export default connect(mapStateToProps, { logIn, logOut, pageHeading, UserProfileUpload })(UserDropdown);


