import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from "@material-ui/lab/Alert";

const InActiveSnackBar = (props) => {
    return(
        <Snackbar
        anchorOrigin={
          props.anchorOrigin
            ? props.anchorOrigin
            : { vertical: 'top', horizontal: 'center' }
        }
        open={props.flag}
        onClose={props.onClose}
      >
        <Alert onClose={props.onClose} severity={props.severity}>
          {props.msg}
        </Alert>
      </Snackbar>
    )
}

export default InActiveSnackBar;