/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import "../styles/inviteModal.css";
// import InviteTopIcon from "../../assets/img/newInvitation.svg";
import InputField from "../Inputs_Validation/index";
import { connect } from "react-redux";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  fetchInvitation,
  addInvitationItem,
  // fetchclinic,
  addingClinicName,
  updateExistingCLinicList,
  reSet,
  checkEmail,
  closeInvitationSuccesPopup,
} from "../../redux/invitation/components/invitation.action";

// imported components
import { makeStyles } from "@material-ui/core/styles";
import SucssesPopup from "./SucssesPopup";
import { pageHeading } from "../../redux/user/user.action";
import apiAbmBlaze, {
  GET_CARESITE_LIST_FOR_INVITATION,
} from "../../../src/api";
import Box from "@mui/material/Box";
import LoadingIcon from "../../components/LoadingIcon";
import MultiSelectDropdown from "../MultiSelectDropdown";
//import InvitationClinicTableInPopUp from "./InvitationClinicTableInPopUp";
import InvitationConfirmPopup from "./InvitationConfirmPopup";
const useStyles = makeStyles((theme) => ({
  buttons: {
    backgroundColor: "#F2F4F8",
    boxShadow: "#6A768F80 0px 3px 6px",
    color: "#3D446F",
    fontFamily: "Roboto",
    fontSize: "14px",
    marginBottom: "8px",
    textTransform: "capitalize",
    fontWeight: "bold",
    padding: "10px 30px",
    borderRadius: "5px",
    textAlign: "center",
    width: "100%",
  },
}));

const InviteModalPopup = (props) => {
  const [role, setRole] = React.useState("");
  const [clinicList, setClinicList] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [values, setValues] = React.useState({});
  const inputRefs = React.useRef([React.createRef()]);
  const [userSpecificCL, setUserSpecificCL] = React.useState([]);
  const [emailValid, setEmailValid] = React.useState(false);
  const [enableRole, setEnableRole] = React.useState(false);
  const [showInviteButton, setShowInviteButton] = React.useState(false);
  const [clinicSelected, setClinicSelected] = React.useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = React.useState(false);
  const [enableSubmit, setEnableSubmit] = React.useState(false);
  const [detectChange, setDetectChange] = React.useState(false);
  const [selectedRoleFrColr, setSelectedRoleFrColr] = React.useState(null);
  const [showLoading, setShowLoading] = React.useState(false);
  const options = {
    day: 'numeric',
    month: 'long',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'shortGeneric',
  };
  const [showLoadingForRoleButtons, setShowLoadingForRoleButtons] =
    React.useState(false);
  const [
    showInviteButtonByOveridingCondition,
    setShowInviteButtonByOveridingCondition,
  ] = React.useState(false);
  const [showMsgToUser, setShowMsgToUser] = React.useState(true);
  const [msgToUser, setMsgToUser] = React.useState("");
  const [showRoleButtons, setShowRoleButtons] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  // const selectedBckGrnd = "#cfe6f5";
  const classes = useStyles();
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const [emailValue, setEmailValue] = React.useState(true);
  const [emailValuefromInput, setEmailValuefromInput] = React.useState();
  const [confirmOpenPopup, setconfirmOpenPopup] = React.useState(false);
  const [sendinvitionClick, setSendInvitationClick] = React.useState(false);
  useEffect(() => {
    props.fetchInvitation(props.userRole);
    // props.fetchclinic();
    props.pageHeading("Invitations");
  }, []);

  useEffect(() => {
    if (props.invitationFlag === true) {
      props.callBack();
      props.reSet();
      setShowInviteButtonByOveridingCondition(false);
      setEnableSubmit(false);
      setShowInviteButton(false);
      setRole(null);
      setOpenSuccessPopup(true);
    }
  }, [props.invitationFlag]);

  useEffect(() => {
    clinicConditionHandler();
    setRole(props.checkingEmail);
  }, [props.checkingEmail, props.flagEmail, email]);

  useEffect(() => {
    if (showLoadingForRoleButtons) {
      setShowRoleButtons(true);
      setShowLoadingForRoleButtons(false);
    }
  }, [props.detectCheckingEmailChange]);

  useEffect(() => {
    clinicConditionHandler();
  }, [detectChange]);

  const handleChange = (value) => {
    if (showInviteButtonByOveridingCondition)
      setShowInviteButtonByOveridingCondition(false);
    setClinicList("");
    if (props.userRole === "superadmin" && value === "abmadmin") {
      setShowInviteButtonByOveridingCondition(true);
    } else {
      setShowLoading(true);
      getCareSiteList(); ///backend call to get care-site list
    }
    setSelectedRoleFrColr(value);
    setDetectChange(!detectChange);
    if (value) {
      setRole(value);
      if (value !== "abmadmin") {
        setEnableRole(true);
      } else {
        setEnableSubmit(true);
        setClinicSelected(true);
        setEnableRole(false);
      }
    }

    if (
      props.userRole === "abmadmin" &&
      (value === "patient" ||
        value === "clinician" ||
        value === "abmadmin" ||
        value === "superadmin")
    ) {
      setMsgToUser(
        "You shall invite only clinic admins to the care site, the selected user is holding the above role."
      );
      setShowMsgToUser(true);
    }
  };

  const handleChangeclinician = (value) => {
    setClinicList("");
    setSelectedRoleFrColr(value);
    setShowLoading(true);
    getCareSiteList(); //backend call to get care-site list
    if (value === "patient") {
      setRole(value);
      setEnableSubmit(true);
      setClinicSelected(true);
      setEnableRole(true); //updated by sujan 03/02/2022
    }
  };

  const handleChange3 = (value) => {
    setClinicList("");
    setSelectedRoleFrColr(value);
    setShowLoading(true);
    getCareSiteList(); //backend call to get care-site list
    if (value === "clinicadmin" || value === "patient") {
      setEnableRole(true);
    } else {
      setMsgToUser(
        "You shall invite patients to the care site, the selected user is holding the above role!"
      );
      setShowMsgToUser(true);
    }
    if (props.userRole === "superadmin") {
      if (value === "patient" || value === "clinician") {
        setMsgToUser(
          "User email already registered with the above role! As a Super-admin, you can invite only ABM Admin or Care site Admin roles."
        );
        // setMsgToUser("You shall invite abm admins and clinic admins to the care site, the selected user is holding the above role!")
        setShowMsgToUser(true);
      }
      if (value === "abmadmin") {
        setMsgToUser("User is already holding this role.");
        setShowMsgToUser(true);
      }
    }
  };

  const handleSelectMultiDropdownChange = (value) => {
    if (value) {
      setClinicSelected(true);
      setClinicList(value);
    } else {
      setClinicList("");
    }
  };

  const handleSelectChange = (event) => {
    if (event.target.value) {
      setClinicSelected(true);
      setClinicList(event.target.value);
    } else {
      setClinicList("");
    }
  };

  const onSubmit = async () => {
    let sentdate=new Date();
    sentdate.setDate(sentdate.getDate() + 7)
    let expirydate = sentdate.toLocaleTimeString('en-US',options)
      // console.log('expirydate',expirydate)
    setSendInvitationClick(true);
    setEmailValid(false);
    setEmailValue(true);
    if (clinicList !== "" || enableSubmit) {
      props.addInvitationItem({
        role: role,
        email: email,
        clinicId: clinicList,
        expirydate: expirydate,
      });

      if (props.addUserInvitation.length > 0) {
        // props.callBack();
        setRole(null);
        // setShowInviteButtonByOveridingCondition(false);
      } else {
        setRole(null);
        // props.reSet();
        // props.callBack();
        // setEnableSubmit(false);
        // setShowInviteButton(false);
        // setShowInviteButtonByOveridingCondition(false);
      }
    }
  };

  const handleInputChange = (name, value) => {
    setEmailValuefromInput(value);
    setEmailValue(true);
    setShowRoleButtons(false);
    setShowMsgToUser(false);
    setClinicList("");
    setSelectedRoleFrColr(null);
    setShowInviteButton(false);
    setEnableRole(false);
    setRole(null);
    setEmailValid(false);
    var valid = false;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      valid = inputRefs.current[i].current.validate();
    }

    if (valid === true) {
      setEmailValid(true);
      setEmail(value);
    } else {
      setShowLoadingForRoleButtons(false);
      setEmailValid(false);
      setRole(null);
    }

    if (value.length > 0) {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const getCareSiteList = () => {
    apiAbmBlaze
      .post(GET_CARESITE_LIST_FOR_INVITATION, {
        email: email,
        loggedUserProfileId: props.loggedUserId,
      })
      .then(async (res) => {
        if (res.data) {
          setUserSpecificCL(res.data);
          setShowLoading(false);
        }
      });
  };

  const clinicConditionHandler = () => {
    setShowInviteButton(false);
    if (props.userRole === "superadmin") {
      if (!emailValid) {
        setShowInviteButton(false);
      } else if (
        props.flagEmail &&
        (props.checkingEmail === "abmadmin" ||
          props.checkingEmail === "clinicadmin")
      ) {
        setShowInviteButton(true);
      } else if (
        props.flagEmail &&
        (props.checkingEmail === "clinician" ||
          props.checkingEmail === "patient" ||
          props.checkingEmail === "superadmin")
      ) {
        setShowInviteButton(false);
      } else if (props.flagEmail === false && emailValid) {
        setShowInviteButton(true);
      }
    } else if (props.userRole === "abmadmin") {
      if (!emailValid) {
        setShowInviteButton(false);
      } else if (props.flagEmail && props.checkingEmail === "clinicadmin") {
        setShowInviteButton(true);
      } else if (
        props.flagEmail &&
        (props.checkingEmail === "clinician" ||
          props.checkingEmail === "patient" ||
          props.checkingEmail === "superadmin" ||
          props.checkingEmail === "abmadmin")
      ) {
        setShowInviteButton(false);
      } else if (props.flagEmail === false && emailValid) {
        setShowInviteButton(true);
      }
    } else if (props.userRole === "clinicadmin") {
      if (!emailValid) {
        setShowInviteButton(false);
      } else if (
        (props.flagEmail && props.checkingEmail === "clinicadmin") ||
        props.checkingEmail === "clinician" ||
        props.checkingEmail === "patient"
      ) {
        setShowInviteButton(true);
      } else if (
        props.flagEmail &&
        (props.checkingEmail === "superadmin" ||
          props.checkingEmail === "abmadmin")
      ) {
        setShowInviteButton(false);
      } else if (props.flagEmail === false && emailValid) {
        setShowInviteButton(true);
      }
    } else if (props.userRole === "clinician") {
      if (!emailValid) {
        setShowInviteButton(false);
      } else if (props.flagEmail && props.checkingEmail === "patient") {
        setShowInviteButton(true);
      } else if (!props.flagEmail) {
        setShowInviteButton(true);
      }
    }
  };

  const successClosePopup = () => {
    props.closeInvitationSuccesPopup();
    setSendInvitationClick(false);
    setOpenSuccessPopup(false);
  };
  const onKeyDownEmail = (e) => {
    if (e.key === "Enter") {
      if (emailValid && e.target.value) {
        setShowLoadingForRoleButtons(true);
        props.checkEmail(e.target.value);
      }
    }
  };
  const nextCheckingHnadler = (value) => {
    inputRefs.current[0].current.validate()
    if (emailValid && value) {
      setShowLoadingForRoleButtons(true);
      props.checkEmail(value);
      setEmailValue(false);
    }
  };

  const valReset = () => {
    setEmailValid(false);
    setShowInviteButton(false);
    setEnableRole(false);
    setEmailValue(true);
    setEnableSubmit(false);
    setShowInviteButtonByOveridingCondition(false);
    setClinicList("");
    setSendInvitationClick(false)
  };

  return (
    <>
      <SucssesPopup
        open={openSuccessPopup}
        onClose={successClosePopup}
        email={email}
        msg={
          props.invitationFlagUserAlreadyExist
            ? "Invitation has been sent to"
            : // "Notification email has been sent! Since user is already associated with ARC portal and shall accept the invitation from received invitation list."
            // "User is already part of ARC portal and hence directly been added to the selected care site, intimation has been sent to user email to accept the invitation from Received invitation list !"
            ""
        }
      />
      <InvitationConfirmPopup
        open={confirmOpenPopup}
        callBack={props.callBack}
        callBackConfirmOtp={setconfirmOpenPopup}
        valReset={valReset}
      />
      <Dialog
        open={props.isopen}
        onClose={() => {
          setClinicList(""); //added by sujan 02/02/2022
          setShowMsgToUser(false);
          setEmailValid(false);
          setEnableRole(false);
          setShowInviteButtonByOveridingCondition(false);
          setEnableSubmit(false);
          props.callBack();
          setEmailValue(true);
        }}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="inviteModalContainer">
          <div
            className="modalheader"
            style={{ marginTop: "-15px", color: "#3D446F" }}
          >
            <div className="forheadertitle">Invitation</div>
            <div>
              <CloseOutlinedIcon
                onClick={() => {
                  /*   props.callBack(); */
                  // props.reSet();
                  // setEmailValid(false);
                  // setShowInviteButton(false);
                  // setEnableRole(false);
                  setRole(null);
                  // setEnableSubmit(false);
                  // setEmailValue(true);
                  // setClinicList(""); //added by sujan 02/02/2022
                  // setShowInviteButtonByOveridingCondition(false);
                  setShowMsgToUser(false);
                  setconfirmOpenPopup(true);
                }}
                className="closeIcon"
                style={{ marginRight: "11px" }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent
          className="inviteModalBack"
          style={{ backgroundColor: "#f2f4f8" }}
        >
          <div style={{ minHeight: "500px" }}>
            {/* "340px" */}
            <div className="invitationImageWrapper">
              {/*  <div style={{
                                borderRadius: "50%",
                                backgroundColor: "white",
                                boxShadow: "#6a768f80 0px 3px 6px",
                                padding: "20px"
                            }}>
                                <div className="iconForemail">
                                    <img src={InviteTopIcon} alt="" />
                                </div>
                            </div> */}
            </div>
            <div
              className="flex flex-wrap inputcontainer"
              style={{ marginTop: "-27px" }}
            >
              <div className="lg:w-6/12 px-4 emailInputmediaQuery">
                <div className="mt-5">
                  <InputField
                    type="email"
                    className="emailInputInvitation"
                    placeholder="Email Address"
                    ref={inputRefs.current[0]}
                    handleInputChange={handleInputChange}
                    validation="required|emailpattern"
                    name="email"
                  //onKeyDown={onKeyDownEmail}
                  />
                </div>
              </div>
              <div className="lg:w-6/12 px-4 dropDownMediaQuery">
                {emailValue === false ? (
                  <div className="mt-5">
                    {showLoadingForRoleButtons ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "-17px",
                        }}
                      >
                        <LoadingIcon />
                      </Box>
                    ) : (
                      <>
                        {showRoleButtons ? (
                          <>
                            {emailValid === true &&
                              props.userRole === "superadmin" &&
                              props.flagEmail !== true ? (
                              <div className="mt-5">
                                <select
                                  className="select-role-dropdown"
                                  onChange={(e) => {
                                    /*  e.target.value === "abmadmin"
                                      ? handleChange("abmadmin")
                                      : handleChange("clinicadmin") */
                                    if (e.target.value === "abmadmin") {
                                      handleChange("abmadmin");
                                    } else if (
                                      e.target.value === "clinicadmin"
                                    ) {
                                      handleChange("clinicadmin");
                                    }
                                    // console.log("event_value", e.target.value);
                                  }}
                                >
                                  <option>Select Role</option>
                                  <option value="abmadmin">Abm Admin</option>
                                  <option value="clinicadmin">
                                    {" "}
                                    Care Site Admin{" "}
                                  </option>
                                </select>
                              </div>
                            ) : emailValid === true &&
                              props.userRole === "superadmin" &&
                              props.flagEmail === true ? (
                              
                              <div className="mt-5">
                                <select
                                  className="select-role-dropdown"
                                  onChange={() => {
                                    handleChange3(props.checkingEmail);
                                  }}
                                >
                                  <option>Select Role</option>
                                  <option
                                    value={props.checkingEmail}
                                  >
                                    {" "}
                                    {props.checkingEmail === "superadmin"
                                      ? "Super Admin"
                                      : props.checkingEmail === "clinicadmin"
                                      ? "Care Site Admin"
                                      : props.checkingEmail === "abmadmin"
                                        ? "Abm Admin"
                                        : props.checkingEmail === "clinician"
                                          ? "Clinician"
                                          : props.checkingEmail === "patient"
                                            ? "Patient"
                                            : null}
                                  </option>
                                </select>
                              </div>
                            ) : emailValid === true &&
                              props.userRole === "abmadmin" &&
                              props.flagEmail === true ? (
                              
                              <div className="mt-5">
                                <select
                                  className="select-role-dropdown"
                                  onClick={() => {
                                    handleChange(props.checkingEmail);
                                  }}
                                >
                                  <option>Select Role</option>
                                  <option value={props.checkingEmail}>
                                    {" "}
                                    {props.checkingEmail === "superadmin"
                                      ? "Super Admin"
                                      : props.checkingEmail === "clinicadmin"
                                      ? "Care Site Admin"
                                      : props.checkingEmail === "abmadmin"
                                        ? "Abm Admin"
                                        : props.checkingEmail === "clinician"
                                          ? "Clinician"
                                          : props.checkingEmail === "patient"
                                            ? "Patient"
                                            : null}
                                  </option>
                                </select>
                              </div>
                            ) : emailValid === true &&
                              props.userRole === "abmadmin" &&
                              props.flagEmail !== true ? (
                              <div className="mt-5">
                                <select
                                  className="select-role-dropdown"
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      handleChange("clinicadmin");
                                    }
                                  }}
                                >
                                  <option>Select Role</option>
                                  <option value="clinicadmin">
                                    {" "}
                                    Care Site Admin
                                  </option>
                                </select>
                              </div>
                            ) : emailValid === true &&
                              props.userRole === "clinicadmin" &&
                              props.flagEmail !== true ? (
                              <div>

                                <div className="mt-5">
                                  <select
                                    className="select-role-dropdown"
                                    onChange={(e) =>
                                      e.target.value === "clinicadmin"
                                        ? handleChange("clinicadmin")
                                        : e.target.value === "clinician"
                                          ? handleChange("clinician")
                                          : handleChange("patient")
                                    }
                                  >
                                    <option>Select Role</option>
                                    <option value="clinicadmin">
                                      {" "}
                                      Care Site Admin
                                    </option>
                                    <option value="clinician">
                                      {" "}
                                      Clinician
                                    </option>
                                    <option value="patient"> Patient</option>
                                  </select>
                                </div>
                              </div>
                            ) : emailValid === true &&
                              props.userRole === "clinicadmin" &&
                              props.flagEmail === true ? (
                              
                              <div className="mt-5">
                                <select
                                  className="select-role-dropdown"
                                  onChange={() => {
                                    handleChange(props.checkingEmail);
                                  }}
                                >
                                  <option>Select Role</option>
                                  <option value={props.checkingEmail}>
                                    {" "}
                                    {props.checkingEmail === "superadmin"
                                      ? "Super Admin"
                                      : props.checkingEmail === "clinicadmin"
                                      ? "Care Site Admin"
                                      : props.checkingEmail === "abmadmin"
                                        ? "Abm Admin"
                                        : props.checkingEmail === "clinician"
                                          ? "Clinician"
                                          : props.checkingEmail === "patient"
                                            ? "Patient"
                                            : null}
                                  </option>
                                </select>
                              </div>
                            ) : emailValid === true &&
                              props.userRole === "clinician" &&
                              props.flagEmail !== true ? (
                              <div className="mt-5">
                                <select
                                  className="select-role-dropdown"
                                  onChange={(e) => {
                                    if (e.target.value === "patient") {
                                      handleChangeclinician("patient");
                                    }
                                  }}
                                >
                                  <option>Select Role</option>
                                  <option value="patient">Patient</option>
                                </select>
                              </div>
                            ) : emailValid === true &&
                              props.userRole === "clinician" &&
                              props.flagEmail === true ? (
                             
                                <div className="mt-5">
                                  <select className="select-role-dropdown">
                                    <option>Select Role</option>
                                    <option
                                      onChange={() => {
                                        handleChange3(props.checkingEmail);
                                      }}
                                      value={props.checkEmail}
                                    >
                                      {" "}
                                      {props.checkingEmail === "superadmin"
                                      ? "Super Admin"
                                      : props.checkingEmail === "clinicadmin"
                                        ? "Care Site Admin"
                                        : props.checkingEmail === "abmadmin"
                                          ? "Abm Admin"
                                          : props.checkingEmail === "clinician"
                                            ? "Clinician"
                                            : props.checkingEmail === "patient"
                                              ? "Patient"
                                              : null}
                                    </option>
                                  </select>
                                </div>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                ) : (
                  <Button
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      height: "37px",
                      background: "#fff",
                    }}
                    className={classes.buttons}
                    onClick={() => nextCheckingHnadler(emailValuefromInput)}
                  >
                    Next
                  </Button>
                )}
              </div>
            </div>

            {enableRole && showInviteButton ? (
              <div className="flex flex-wrap">
                <div className="w-full lg:w-12/12 px-4 mt-2 serachContainer">
                  <div className="relative w-full mb-3">
                    {showLoading ? (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <LoadingIcon />
                      </Box>
                    ) : (
                      <>
                        {userSpecificCL.length ? (
                          <form>
                            <MultiSelectDropdown
                              options={userSpecificCL}
                              handleOnChange={handleSelectMultiDropdownChange}
                            />
                          </form>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            User is already part of all care sites which you are
                            part of and no other care sites found to invite!
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {showMsgToUser ? (
              <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                {msgToUser}
              </div>
            ) : null}

            {showInviteButtonByOveridingCondition ||
              (clinicList !== "" &&
                userSpecificCL.length > 0 &&
                showInviteButton &&
                (enableRole || enableSubmit) &&
                clinicSelected) ? (
              <div
                style={{
                  backgroundColor: "transparent",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  className="buttonForSendInvition"
                  onClick={() => onSubmit()}
                  style={{    display: 'flex', justifyContent: 'center'}}
                >
                  {sendinvitionClick ? <Box sx={{
                    display: 'flex', width: "34px", height: "24px", marginTop : '-9px'
                  }}>  <LoadingIcon /></Box> : "Send Invitation"}
                </button>
              </div>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    addUserInvitation: store.invitation.addUserInvitation,
    checkingEmail: store.invitation.checkingEmail,
    flagEmail: store.invitation.flagEmail,
    userRole: store.user.user.role,
    invitationFlag: store.invitation.invitationFlag,
    loggedUserId: store.user.user && store.user.user.id,
    detectCheckingEmailChange: store.invitation.checkingEmail + new Date(), //  //added this to detect any changes in 'store.invitation.checkingEmail', if value is null/same as before useeffect will not run
    invitationFlagUserAlreadyExist:
      store.invitation.invitationFlagUserAlreadyExist,
  };
};

export default connect(mapStateToProps, {
  fetchInvitation,
  updateExistingCLinicList,
  addInvitationItem,
  // fetchclinic,
  addingClinicName,
  reSet,
  checkEmail,
  closeInvitationSuccesPopup,
  pageHeading,
})(InviteModalPopup);
