import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
 const CustomsSnackbar = (
  {
  flag,
  onClose,
  anchorOrigin,
  msg,
  severity,
}) => {
  return (
      
    <Snackbar
      anchorOrigin={
        anchorOrigin
          ? anchorOrigin
          : { vertical: "top", horizontal: "center" }
      }
      open={flag}
      onClose={onClose}
      autoHideDuration={6000}
    >
      <Alert onClose={onClose} severity={severity ? severity : "error" } >
        Something went wrong! Please try again later.
      </Alert>
    </Snackbar>
  );
};

export default CustomsSnackbar ;
