import React, { useEffect } from "react";
import caresiteIcon from "../../assets/img/caresiteIcon.svg";
/* import '../styles/cardClinicPicture.css'; */
import "./styles.css";
import Home from "../../assets/icons/helpcenter/home.svg";
import phone from "../../assets/icons/helpcenter/phone.svg";
import Website from "../../assets/icons/helpcenter/website.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avt: {
    height: "250px",
    width: "200px",
  },
}));

const FooterCard = (props) => {
  const classes = useStyles();
  const style = {
    width: "100%",
    maxWidth: 360,
    bgcolor: "background.paper",
  };
  var mystr = ""
  

  return (
    <div className="care-site-details">
      <div className="bottomContainerInFooterCard">
        <div className="ABMLogocontainerInFooterCard">
          {props.picture ? (
            <a href={props.website} target='_blank'>
              <img
              src={`https://ucarecdn.com/${props.picture}/`}
              alt=""
              className="profilepicuploadImageWrapper1"
              style={{
                cursor: "pointer",
                width: "200px",
                borderRadius: "50%",
                height: "200px",
              }}
            />
            </a>
          ) : (
            <a href={props.website} target='_blank'>
              <img
              src={caresiteIcon}
              style={{
                // cursor: "pointer",
                width: "250px",
                // borderRadius: "50%",
                height: "200px",
              }}
            />
            </a>
          )}
        </div>

        <div className="CareSiteDetailsContainer">
          <div className="ClinicNameHeading fnt32P">
            {props.clinicName ? props.clinicName : ""}
          </div>

          <div className="bottomSectionContainer1InFooterCard">
            <div className="EmailIconInFooterCard">
              <img src={Home} />
            </div>
            <div className="addressContainer fnt24P">
              {props.address ? props.address.trim() : ""}{", "}
              {props.city ?props.city.trim() : ""}{", "}
              {props.state ? props.state.trim():""}{", "}
              {props.postal ? props.postal.trim() : ""}
            </div>
          </div>

          <div className="bottomSectionContainer1InFooterCard">
            <div className="phoneIconInFooterCard ">
              <img src={phone} />
            </div>
            <div className="customservice fnt24P">
              {props.phonenumber ? '+'+props.phonenumber : "+91000000000"}
            </div>
          </div>

          <div className="bottomSectionContainer1InFooterCard">
            <div className="websiteIconInFooterIcon">
              <img src={Website} />
            </div>
            <div className="customservice fnt24P">
              <a href={props.website} target='_blank'>
                {props.website ? props.website : "Website is not added"}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterCard;
