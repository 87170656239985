import React from "react";
// import {
//   Chart as ChartJS,
//   // CategoryScale,
//   // LinearScale,
//   // BarElement,
//   // Title,
//   // Tooltip,
//   // Legend,
// } from "chart.js";

import ChartJS from "chart.js/auto"
// import { Bar } from "react-chartjs-2";
// import Popover from "@mui/material/Popover";
import Info from "@mui/icons-material/Info";
// import Cancel from "@mui/icons-material/Cancel";
import "./style.css";
// import zoomPlugin from "chartjs-plugin-zoom";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   // zoomPlugin
// );
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import wave from "../../assets/icons/wave.svg"

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: '#f5f5f9',
    color: '#6a768f',
    width: '160px',
    fontSize: '14px',
    // maxWidth: 220,
    // fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: '0px'
  },
}));

const options = {
  responsive: true,
  // maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
    tooltip: {
      callbacks: {
        footer: (item) => {
          let timeTaken = item[0].dataset.time[item[0].dataIndex];
          return "Time Taken:  " + timeTaken / 100 + " sec";
        },
      },
    },
    // labels: {
    //   render: 'image',
    //   textMargin: 10,
    //   images: [
    //     {
    //       src: 'https://i.stack.imgur.com/9EMtU.png',
    //       width: 20,
    //       height: 20
    //     },
    //     null,
    //     {
    //       src: 'https://i.stack.imgur.com/9EMtU.png',
    //       width: 20,
    //       height: 20
    //     },
    //     null
    //   ]
    // }
  },

  scales: {
    y: {
      // display:false,
      beginAtZero: true,
      max: 70,
      min: -70,
      ticks: {
        // forces step size to be 50 units
        stepSize: 10
      }
    }
  }
};

export default function PatientProfileGraph(props) {

  const chartIndex = React.useRef(0);








  // React.useEffect(() => {
  //   // console.log("CHartREf:", chartRef.current);
  //   // chartRef
  // }, [chartRef.current])

  let cycleMeasurement = props.therapyGraph &&
    props.therapyGraph.cycle_measurements ? props.therapyGraph.cycle_measurements : []
  chartIndex.current = cycleMeasurement.length;
  let cycles =
    props.therapyGraph &&
      props.therapyGraph.val &&
      props.therapyGraph.val.CYCLES
      ? props.therapyGraph.val.CYCLES
      : [];
  const labels = cycles.length
    ? cycles.map((item, index) => {
      // return `Cycle ${index + 1}`;
      return ''

    })
    : [];

  const greens = cycles.length
    ? cycles.map((item, index) => {
      if (cycleMeasurement.length > index)
        return "#def3a9";
      else return 'lightgrey'
    })
    : [];

  const blue = cycles.length
    ? cycles.map((item, index) => {
      if (cycleMeasurement.length > index)
        return "#afedfb";
      else return 'lightgrey'
    })
    : [];

  const red = cycles.length
    ? cycles.map((item, index) => {
      if (cycleMeasurement.length > index)
        return "#f2b094";
      else return 'lightgrey'
    })
    : [];

  let pausePressure = cycles.length
    ? cycles.map((item, index) => {
      // if (cycleMeasurement.length > index)
      return parseInt(item.P_P);
      // else
      //   return 0
    })
    : [];
  let pausePressureTime = cycles.length
    ? cycles.map((item) => {
      return parseInt(item.P_T);
    })
    : [];
  let inspPressure = cycles.length
    ? cycles.map((item, index) => {
      // if (cycleMeasurement.length > index)
      return parseInt(item.I_P);
      // else
      //   return 0
    })
    : [];
  let inspPressureTime = cycles.length
    ? cycles.map((item) => {
      return parseInt(item.I_T);
    })
    : [];
  let expPressure = cycles.length
    ? cycles.map((item, index) => {
      // if (cycleMeasurement.length > index)
      return parseInt(item.E_P);
      // else
      //   return 0
    })
    : [];
  let expPressureTime = cycles.length
    ? cycles.map((item) => {
      return parseInt(item.E_T);
    })
    : [];

  // console.log('pausePressure:', pausePressure);
  const data = {
    labels,
    datasets: [
      {
        label: "Pause Pressure",
        data: pausePressure.length ? pausePressure : [0, 0, 0, 0, 0],
        backgroundColor: greens,
        time: pausePressureTime.length ? pausePressureTime : [0, 0, 0, 0, 0],
        // barThickness: 80,
      },
      {
        label: "Insp Pressure",
        data: inspPressure.length ? inspPressure : [0, 0, 0, 0, 0],
        backgroundColor: blue,
        time: inspPressureTime.length ? inspPressureTime : [0, 0, 0, 0, 0],
        // barThickness: 80,
      },
      {
        label: "Exp Pressure",
        data: expPressure.length ? expPressure : [0, 0, 0, 0, 0],
        backgroundColor: red,
        time: expPressureTime.length ? expPressureTime : [0, 0, 0, 0, 0],
        // barThickness: 80,
      },
    ],
  };

  const chartRef = React.useRef(null);
  const [chartInstance, setChartInstance] = React.useState(null);

  var rectangleSet = false;


  // React.useEffect(() => {
  //   var myChart = '';
  //   if (chartRef.current) {
  //     myChart = new ChartJS(chartRef.current, {
  //       type: "bar",
  //       data: {
  //         labels: labels,
  //         datasets: data.datasets,
  //       },
  //       options: {
  //         scales: {
  //           yAxes: [
  //             {
  //               ticks: {
  //                 beginAtZero: true
  //               }
  //             }
  //           ]
  //         }
  //       }
  //     });
  //   }
  //   return () => {
  //     chartRef.current = null;
  //     myChart.destroy();
  //   };
  // },
  //   [labels, data]
  // );

  const backgroundHighlighter = {
    legend: {
      display: false,
    },
    id: 'backgroundHighlighter',
    beforeDatasetsDraw(chart, args, options) {
      if (chart) {
        const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y }, data } = chart;
        ctx.fillStyle = 'rgba(0,0,0,0.1)';

        // const startValue = data.datasets[0].data.find(value => value == 0);
        // const startIndex = data.datasets[0].data.indexOf(startValue);

        // console.log("cycleMeasurement:", chartIndex.current)



        for (let i = 0; i < x._gridLineItems.length - 1; i++) {
          if (!(i % 2)) {
            ctx.fillStyle = '#8fcdd91f';
            // ctx.fillStyle = '#f2f7f8';
            let newWidth = x._gridLineItems[i + 1].x1 - x._gridLineItems[i].x1
            // console.log("I:", x._gridLineItems[i].x1, x._gridLineItems[i + 1].x1, newWidth)
            ctx.fillRect(x._gridLineItems[i].x1, top, newWidth, height)
          }
        }



        // if (x._gridLineItems[chartIndex.current] && x._gridLineItems[chartIndex.current].x1) {

        // ctx.fillRect(x._gridLineItems[chartIndex.current].x1, top, width, height)
        // console.log("CHARTZZ:", x);
        // ctx.update();
        // }
      }

    },
    // beforeDraw(chart, args, options) {
    //   if (chart) {
    //     const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y }, data } = chart;

    //     // const startValue = data.datasets[0].data.find(value => value == 0);
    //     // const startIndex = data.datasets[0].data.indexOf(startValue);
    //     // console.log("cycleMeasurement:", chartIndex.current)
    //     // if (x._gridLineItems[chartIndex.current] && x._gridLineItems[chartIndex.current].x1) {

    //     // ctx.fillRect(x._gridLineItems[chartIndex.current].x1, top, width, height)
    //     // console.log("CHARTZZ Beforedraw:", data);
    //     // console.log("CHARTZZ Beforedraw:  XX:", x);

    //     // ctx.update();

    //   }

    // }

  }
  const chartConfig = {
    type: 'bar',
    data: data,
    // animation: false,
    maintainAspectRatio: false,
    responsive: true,
    options: {
      scales: {
        y: {
          // display:false,
          beginAtZero: true,
          max: 70,
          min: -70,
          ticks: {
            // forces step size to be 50 units
            stepSize: 10
          },
          grid: {
            // display: false
            color: ["transparent", "transparent", "transparent", "transparent", "transparent", "transparent", "transparent", "lightgrey"],
          }
        },
        x: {
          grid: {
            display: false
            // color: "transparent",
          }
        }
      },

      animation: {
        // onComplete: function () {
        //   if (!rectangleSet && chartInstance) {
        //     var scale = window.devicePixelRatio;

        //     var sourceCanvas = chartInstance.chart.canvas;
        //     var copyWidth = chartInstance.scales['y-axis-0'].width - 10;
        //     var copyHeight = chartInstance.scales['y-axis-0'].height + chartInstance.scales['y-axis-0'].top + 10;

        //     var targetCtx = document.getElementById("axis-Test").getContext("2d");

        //     targetCtx.scale(scale, scale);
        //     targetCtx.canvas.width = copyWidth * scale;
        //     targetCtx.canvas.height = copyHeight * scale;

        //     targetCtx.canvas.style.width = `${copyWidth}px`;
        //     targetCtx.canvas.style.height = `${copyHeight}px`;
        //     targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth * scale, copyHeight * scale, 0, 0, copyWidth * scale, copyHeight * scale);

        //     var sourceCtx = sourceCanvas.getContext('2d');

        //     // Normalize coordinate system to use css pixels.

        //     sourceCtx.clearRect(0, 0, copyWidth * scale, copyHeight * scale);
        //     rectangleSet = true;
        //   }
        // },
        // onProgress: function () {
        //   if (rectangleSet === true &&chartInstance) {
        //     var copyWidth = chartInstance.scales['y-axis-0'].width;
        //     var copyHeight = chartInstance.scales['y-axis-0'].height + chartInstance.scales['y-axis-0'].top + 10;

        //     var sourceCtx = chartInstance.chart.canvas.getContext('2d');
        //     sourceCtx.clearRect(0, 0, copyWidth, copyHeight);
        //   }
        // },
      },
      plugins: {
        legend: {
          display: false,
        }
      }
    },
    plugins: [backgroundHighlighter]
  }

  const chartContainer = React.useRef(null);

  React.useEffect(() => {
    try {
      if (chartContainer && chartContainer.current) {
        const newChartInstance = new ChartJS(chartContainer.current, chartConfig);
        setChartInstance(newChartInstance);
      }
    }
    catch (err) {
      console.log("err:", err)
    }
  }, [chartContainer]);


  React.useEffect(() => {
    if (chartInstance) {
      // console.log("chartInstance:", chartInstance);
      chartInstance.data = data;
      chartInstance.update();
      // console.log("chartInstance.data.datasets[0]", chartInstance.data.datasets[0])
    }

  }, [chartInstance, data, labels])

  return <div>


    {/* <Bar ref={chartRef} options={options} data={data} /> */}
    <text  style={{fontWeight:'bold', fontSize:'14px',fontFamily:'Roboto',paddingLeft:'25px'}}>Therapy Mode: {`${props.therapyGraph && props.therapyGraph.val?props.therapyGraph.val.MODE:''}`}</text>
   
    <TherapyFooter
      cycles={cycles}
      cycleMeasurement={cycleMeasurement}
    />
    <canvas ref={chartContainer} />

    {/* <div class="chartWrapper">
      <div class="chartAreaWrapper">
        <div class="chartAreaWrapper2">
          <canvas ref={chartContainer} id="chart-Test" height="400" width="1200"></canvas>
        </div>
      </div>
      <canvas id="axis-Test" height="300" width="0"></canvas>
    </div> */}


  </div >

}


const TherapyFooter = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [selectedCycleIndex, setSelectedCycleIndex] = React.useState(0);
  const id = open ? "simple-popover" : undefined;


  const onClickCycleInfo = (e) => {
    let evenObj = e.currentTarget;
    setAnchorEl(evenObj);
  }


  const TherapyDetails = (props) => {
    // console.log("PROPS:", props)
    if (props.cycleMeasurement && props.cycleMeasurement.length > props.index) {


      let _PIP = props.cycleMeasurement[props.index].cycle_m.P_IP ? props.cycleMeasurement[props.index].cycle_m.P_IP : '';
      let _E_V = props.cycleMeasurement[props.index].cycle_m.E_V ? props.cycleMeasurement[props.index].cycle_m.E_V : '';
      let _P_F = props.cycleMeasurement[props.index].cycle_m.P_F ? props.cycleMeasurement[props.index].cycle_m.P_F : '';
      let _O_F = props.cycles[props.index].O_F && props.cycles[props.index].O_F === '1'? 'On' : 'Off';
      let _SPO2 = props.cycleMeasurement[props.index].cycle_m.SPO2 && props.cycleMeasurement[props.index].cycle_m.SPO2 !== 'false' ? props.cycleMeasurement[props.index].cycle_m.SPO2 : 'NA';

      return (
        <div style={{ fontSize: '14px', wwidth: '160px', height: '160px', background: 'white' }}>
          <div style={{ width: '100%', textAlign: 'center', color: '#4c527a', fontWeight: '600', padding: '10px', fontSize: '16px' }}> Cycle {props.index + 1}</div>

          <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <div style={{ padding: '4px', paddingLeft: '10px' }}>PIP :</div>
              <div style={{ padding: '4px', paddingLeft: '10px' }}>Ex Vol :</div>
              <div style={{ padding: '4px', paddingLeft: '10px' }}>PFlow :</div>
              <div style={{ padding: '4px', paddingLeft: '10px' }}>Spo2 :</div>
              <div style={{ padding: '4px', paddingLeft: '10px' }}>OSC :</div>
            </div>
            <div style={{ width: '50%', color: '#f26c39', fontWeight: '600' }}>
              <div style={{ padding: '4px', paddingLeft: '0px' }}>{_PIP} cmH<sub>2</sub>O</div>
              <div style={{ padding: '4px', paddingLeft: '0px' }}>{_E_V} ml</div>
              <div style={{ padding: '4px', paddingLeft: '0px' }}>{_P_F} lpm</div>
              <div style={{ padding: '4px', paddingLeft: '0px' }}>{_SPO2}</div>
              <div style={{ padding: '4px', paddingLeft: '0px' }}>{_O_F}</div>
            </div>
          </div>

        </div>)
    } else {
      return null
    }

  }

  const handleClose = () => {
    setAnchorEl(null);
    // setCurrentIndex(0);
    // setADS_Details([]);
  };
  return (
    <>
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ top: "-135px", left: '-70px' }}
      >
        <div style={{ width: '160px', height: '150px', margin: 'auto' }}> */}

      {/* </div>
      </Popover> */}
      <div style={{ display: 'flex', width: '100%', paddingLeft: "30px" }}>

        {props.cycles.map((item, index) => {

          if (props.cycleMeasurement.length > index) {

            return <div
              style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              {/* {index} */}
              <span style={{ color: "#86cb86", cursor: 'pointer' }}
              >
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <TherapyDetails
                        cycleMeasurement={props.cycleMeasurement}
                        index={index}
                        cycles={props.cycles} />
                    </React.Fragment>
                  }
                >
                  {/* <Button>HTML</Button> */}
                  <Info />
                </HtmlTooltip>

                {/* <Info /> */}
              </span>
              {props.cycles[0].O_F && props.cycles[0].O_F=='1'?<div>
            <img src={wave} width='100px' height='100px'></img>
            </div>:null}
              <div style={{ fontSize: "12px", color: 'grey' }}>Cycle {index + 1}</div>
            </div>
          } else {
            return <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <div style={{ height: '24px' }}>
                {/* <Cancel /> */}
              </div>

              <div style={{ fontSize: "12px", color: 'grey' }}><strike>Cycle {index + 1}</strike></div>
            </div>
          }
        })}
      </div>
    </>
  )

}