/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { userChatConversion } from "../../../redux/user/user.action";
import {
  getAcceptSharing,
  showChatWindow,
} from "../../../redux/sharing/sharing.action";
import {
  chatDialogCreate,
  selectedGroupChat,
  selectedUserChat,
} from "../../../redux/quickbloxmessaging/quickblox.action";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import chatbutton_svg from "../../../assets/img/chatbutton_svg.svg";
import LoadingIcon from "../../../components/LoadingIcon";
import { Avatar } from "@material-ui/core";

const GroupChatList = (props) => {
  const [checkbox, setCheckbox] = useState();
  useEffect(() => {
    props.getAcceptSharing();
    props.selectedUserChat(null)
  }, []);

  const onSave = () => {
  };

  const sharingAccepted = useSelector((store) => {
    return store.sharing.acceptedSharing;
  });

  useEffect(() => {
    if (sharingAccepted) {
      let sharing_accepted = sharingAccepted.map((item) => {
        return {
          ...item,
          isSelected: false,
        };
      });

      if (sharing_accepted) {
        setCheckbox(sharing_accepted);
      }
    }
  }, [sharingAccepted]);

  const InAcThIcon = styled("span")(({ theme }) => ({
    borderRadius: 10,
    width: 20,
    height: 20,
    boxShadow: "#6A768F80 0px 3px 6px",

    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",

    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 20,
    width: 30,
    height: 30,
    boxShadow: "#6A768F80 0px 3px 6px",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",

    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));


  const InAcThCheckedIcon = styled(InAcThIcon)({
    backgroundColor: "#f26930",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#f26930",
    },
  });

  const selectChat = (e) => {
    for (
      let index = 0;
      index < e.currentTarget.parentNode.children.length;
      index++
    ) {
      e.currentTarget.parentNode.children[index].classList.remove("active");
    }
    e.currentTarget.classList.add("active");
  };
  const getChat = (id) => {
    props.userChatConversion(id);
  };

  function InAcThCheckbox(props) {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        disableRipple
        color="default"
        checkedIcon={<InAcThCheckedIcon />}
        icon={<InAcThIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        onChange={(event) => {
          onCheck(props.item, props.index, event.target.checked);
          // }
        }}
      />
    );
  }

  const onCheck = (item, index, checkedState) => {
    let selecedItemDict = item;

    selecedItemDict.isSelected = checkedState;

    let checkedBox = checkbox;
    checkedBox[index] = selecedItemDict;

    setCheckbox(checkedBox);
  };

  const onSubmit = () => {
    let selected = checkbox.filter((item) => item.isSelected === true);

    if (selected) {

      let id = selected.map((item) => {

        return item.quickblox_id;
      });

      if (id) {
        props.chatDialogCreate(id);
        props.showChatWindow(true);
        props.selectedGroupChat(selected);

      }
    }
  };
  return (
    <>
      {props.acceptedSharingFlag ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <LoadingIcon />
        </div>
      ) : (
        <div>
          {checkbox &&
            checkbox.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="scrollgropchat">
                    <div
                      className="chatlist__item_for_group"
                      style={{ animationDelay: `0.${props.animationDelay}s` }}
                    >
                      <div className="MultiUserContainer">
                        <div>
                          <div className="singleUserInDirectChat">
                            {!item.photo_uuid ? (
                              <i
                                className="fa fa-user userforchatgroup"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <Avatar
                                // className="singleUser"
                                src={
                                  item.photo_uuid ? (
                                    `https://ucarecdn.com/${item.photo_uuid}/`
                                  ) : (
                                    <i
                                      className="fa fa-user userforchat"
                                      aria-hidden="true"
                                    ></i>
                                  )
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="userMetaforGroup">
                        <p className="user">
                          {item.first_name || item.last_name
                            ? `${item.first_name ? item.first_name : ""} ${item.last_name ? item.last_name : ""
                            }`
                            : item.email}
                        </p>
                      </div>

                      <InAcThCheckbox
                        item={item}
                        index={index}
                        onChecked={onCheck}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

          <hr />
        </div>
      )}
      <div
        className="edit_profileupdate"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        onClick={() => onSubmit()}
      >
        <img alt="Save"
          src={chatbutton_svg}
          style={{ width: "80px", marginTop: "10px", cursor: "pointer" }}
          onClick={() => onSave()}
        />
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    chatUsers: store.user.chatUsers,
    acceptedSharingUsers: store.sharing.acceptedSharing,
    acceptedSharingFlag: store.sharing.acceptedSharingFlag,
  };
};
export default connect(mapStateToProps, {
  userChatConversion,
  getAcceptSharing,
  chatDialogCreate,
  showChatWindow,
  selectedGroupChat,
  selectedUserChat,
})(GroupChatList);
