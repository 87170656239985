import React from "react";
import { connect } from "react-redux";
import NotifySummary from '../../../components/Cards/notifficationSummary'

const NotificationSummary = () => {
    return(
        <div>
            <NotifySummary />
        </div>
    )
}

export default NotificationSummary;