import React from "react";
import "./styles.css";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import iconSquare from "../../assets/img/IconSquare.svg";
import LeftArrow from "../../assets/icons/leftArrrow.svg";
import "../../assets/styles/font.css";

const useStyles = makeStyles((theme) => ({
  mycard: {
    backgroundColor: "#FFFFFF",
    boxShadow: "#6A768F38 0px 0px 6px",
    borderRadius: "6px",
    marginRight: "25px",
    marginBottom: "32px",
    paddingBottom:'24px'
  },
  cardcontent: {
    color: "#3D446F",
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: "bold",
    padding: "0px",
    marginTop: "16px",
    marginLeft: "16px",
  },
  cardactions: {
    display: "flex",
  },
}));

const ClinicCard = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Card variant="outlined" className={classes.mycard}>
        <CardContent className={classes.cardcontent}>
          <div className="fnt28P" style={{ marginBottom: '16px'}}>
          {props.cardContent}
          </div>
        </CardContent>

        { props.cardContent === "Therapy" ?
        <div
          className="cardSpace fnt21P"
          // onClick={() => {
          //   props.updateTherapyOnclick();
          // }}
        >
          <u>{props.therapy}</u>
        </div> : <div className="cardSpace"></div>}

        <div
          className="section1"
          onClick={() => {
            props.thirdRowOnCLick();
          }}
        >
          <div style={{ display: "flex",paddingTop:"8px"}}>
            <div className="section1Div1">
              <img src={props.iconthirdRow} alt="" className="cardImageWrapper" style={{width:'51px',height:'51px'}}/>
            </div>
            <div className="section1Div2">
              <div className="desc fnt24P">{props.thirdRow}</div>
              <div className="nums fnt32P">{props.thirdRowNum}</div>
            </div>
          </div>
          <div className="section1Div3">
            <img src={LeftArrow} alt="" className="rightArrowImgWrapper" />
          </div>
        </div>
     
        <div
          className="section2"
          style={{ paddingRight: "0px",cursor: "pointer"}}
          onClick={() => {
            props.secondRowOnCLick();
          }}

        >
          
          <div style={{ display: "flex", paddingTop:'8px'  }} >
            
            <div className="section2Div1">
              <img
                src={props.iconSecondRow}
                alt=""
                className="cardImageWrapper"
                style={{width:'51px',height:'51px'}}
              />
            </div>
           
            <div className="section2Div2" >
              <div className="desc2 fnt24P">{props.secondRow}</div>
              <div className="nums2 fnt32P">{props.secondRowNum}</div>
            </div>
          </div>

          <div className="section2Div3">
            <img src={LeftArrow} alt="" className="rightArrowImgWrapper" />
          </div>
        </div> 

        <div
          className="sectionThree"
          style={{ display: "flex", paddingTop:'8px' }}
          onClick={() => {
            props.firstRowOnCLick();
          }}
        >
          <div style={{ display: "flex" }}>
            <div className="section3Div1">
              <img src={props.icon} alt="" className="cardImageWrapper" style={{width:'51px',height:'51px'}} />
            </div>
            <div className="section3Div2">
              <div className="desc3 fnt24P">{props.total}</div>
              <div className="nums3 fnt32P">{props.number}</div>
            </div>
          </div>

          <div className="section3Div3">
            <img src={LeftArrow} alt="" className="rightArrowImgWrapper" />
          </div>
        </div> 
        
      </Card>
    </div>
  );
};

export default ClinicCard;
