import React, { useRef, useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import AlertSnackBar from "../Alertmessage/AlertMsg";
import './styles.css';
import '../../../src/assets/styles/font.css';
import profilepicupload from '../../assets/img/profilepicupload.svg';
import editImage from '../../assets/img/editimage.png';
import { Widget } from "@uploadcare/react-widget";
import InputField from "../../components/Inputs_Validation";
import { connect } from "react-redux";
import { fetchInvitation, updateProfile, updateClinicProile, fetchClinicProfile, clinicPictureUpdate } from '../../redux/invitation/components/invitation.action';
import PhoneInput from "react-phone-input-2";
import LoadingIcon from '../../components/LoadingIcon';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import apiAbmBlaze from "../../api";
import Box from '@mui/material/Box';
import { alphabetsOnlyWithSpace } from "../../utils";
const CaresiteDetailsPopup = (props) => {
    const [newImage, setNewImage] = useState('');
    const [showPopup, setShowPopUp] = useState(false);
    const [clinicdata, setclinicdata] = React.useState("");
    //const [phoneValue, setPhoneValue] = React.useState(props.clinicItemData.phonenumber);
    const [phoneValue, setPhoneValue] = React.useState(clinicdata.phonenumber);
    const [lodingprofile, setLoadingProfile] = React.useState(false);
    const [state, setState] = React.useState({});
    const [stateValue, setStateValue] = React.useState("");
    const [contries, setCountry] = React.useState({});
    const [contriesValue, setCountryValue] = React.useState();
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackSeverity, setSnackSeverity] = React.useState("");
    const [snackMsg, setSnackMsg] = React.useState("");
    const [valid,setvalid]=React.useState(false);
    const [stateError, setStateError] = React.useState({val:false,msg:'ok'})
    const [countryError, setCountryError] = React.useState({val:false,msg:'ok'})
    const inputRefs = React.useRef([
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
    ]);

    const [values, setValues] = React.useState({});
    const [saveClickEventForCareSiteDetails, setSaveClickEventForCareSiteDetails] = React.useState(false);
    const getNewImage = (pic) => {

        setShowPopUp(false);

        props.fetchClinicDataHandler.picture = pic;

        props.updateClinicProfileHandler(props.fetchClinicDataHandler);

    };

    const widgetApi = useRef();
    function closewithoutsave(){
         setPhoneValue(clinicdata.phonenumber);
        setValues({});
        setStateError({val:false,msg:'ok'})
        setCountryError({val:false,msg:'ok'})
       setSaveClickEventForCareSiteDetails(false);
       props.callBack()
    }
    const handleSnackbar = () => {
        setOpenSnack(false);
      };
    const handleInputChange = (name, value) => {

        setValues({
            ...values,
            [name]: value,
        });
    };




    useEffect(() => {

        setCountryValue(
            clinicdata.address && clinicdata.address.country ?
                clinicdata.address.country
                : "United States")
                setPhoneValue(clinicdata.phonenumber)
    }, [clinicdata])

    useEffect(() => {
        setStateValue(
            clinicdata.address && clinicdata.address.state ?
                clinicdata.address.state
                : "")
    }, [clinicdata])


    useEffect(() => {
        setSaveClickEventForCareSiteDetails(false);
        if (props.searchedClinic && props.searchedClinic.length) {
            setclinicdata(props.searchedClinic[0])
        }
    }, [props.searchedClinic,clinicdata]);


    useEffect(() => {
        setSaveClickEventForCareSiteDetails(false);
        if (props.ClinicProfileUpdate && Object.keys(props.ClinicProfileUpdate).length > 0)
            /*  props.callBack(); */
            setLoadingProfile(false);
        stateData();
        countryData();
    }, [props.ClinicProfileUpdate]);

    // useEffect(() => {
    //     fetchClinicProfile();
    //         console.log(props.clinicItemData);

    // },  [props.clinicItemData])

    function stateData() {
        apiAbmBlaze.get("/js/state.json").then((response) => {
            setState(response.data.state);
        })
    }
    function countryData() {
        apiAbmBlaze.get('js/country.json').then((response) => {
            setCountry(response.data.country)
        }).catch((err) => {
            console.log("err from country", err)
        })
    }
    const matchKeys = () => {

        if(phoneValue !== clinicdata.phonenumber){
            return false
        }
           else if(contriesValue !== clinicdata?.address?.country){
            return false
           }
           else if(contriesValue ==='United States' && stateValue !== clinicdata?.address?.state){
            return false
           }
            else{
        const valueKeys = Object.keys(values);
        for (const key of valueKeys) {
          if (Object.keys(clinicdata.address).includes(key)) {
             
              if (values[key] !== clinicdata.address[key]) {
                return false;
              }

          } else {
            if (values[key] !== clinicdata[key]) {
              return false;
            }
          }
        }
      
        return true;
      };
    }
      
      useEffect(() => {
        const isMatch = matchKeys();
        setvalid(isMatch);
      }, [values, clinicdata,phoneValue,contriesValue,stateValue]);
      
      const checkValueExists = (value) => {
        return state.some((obj) => obj.name === value);
      }; 
    
      const onSubmit = async () => {
        setSaveClickEventForCareSiteDetails(true);
        let isValid1 = true;
        if(countryError.val===true||stateError.val===true){isValid1=false}
        else{
       for (let i = 0; i < inputRefs.current.length; i++) {
       if (inputRefs.current[i].current === null) {
        continue;
       }
       const valid1 = inputRefs.current[i].current.validate();
       if (!valid1) {
        isValid1 = false;
      }
    }
  }
        let isValid = true;
        let incorrectstate = false;
        
        if (isValid === true && isValid1=== true) {
            let _state = '';
            let _is_state = clinicdata.address && clinicdata.address.state;
            if(_is_state===stateValue&&stateValue===clinicdata.address.state){_state=stateValue}
            else{
            if (_is_state && stateValue !== clinicdata.address.state) {
                _state = stateValue
            } else if (!_is_state) {
                _state = stateValue
            }  else {
              _state = values.state
            }
          }
            
            const isValueExists = checkValueExists(_state);
        if (contriesValue ==='United States' && !isValueExists ){incorrectstate=true;}
        if(!incorrectstate===true){
            console.log("phone number++", _state, clinicdata.state);
            props.updateClinicProile({ ...clinicdata, ...values, phonenumber: phoneValue ? phoneValue : clinicdata.phonenumber, country: contriesValue, state: _state });
            setSnackSeverity("success")
            setSnackMsg('Care Site Details updated successfully')
            setOpenSnack(true)
            props.callBack()}
            else if( incorrectstate===true){
                setSnackSeverity("error")
                setSnackMsg('Please select an appropriate state from the dropdown')
                setOpenSnack(true)
                setSaveClickEventForCareSiteDetails(false);
            }
        }
        else if( isValid1===false){
            setSnackSeverity("error")
            setSnackMsg('Please fix the inputs with errors')
            setOpenSnack(true)
            setSaveClickEventForCareSiteDetails(false);
        }
    };


    return (
        <div>
            <AlertSnackBar 
             msg={snackMsg}
             flag={openSnack}
             onClose={handleSnackbar}
             anchorOrigin={{vertical: "top",horizontal: "center",}}
             severity={snackSeverity}
            />
            <Dialog
                open={props.isopen}
                onClose={closewithoutsave}
                maxWidth="lg" className="caresitedetailsPopupWrapper">
                <DialogTitle style={{ backgroundColor: '#F2F7F8' }}>
                    <div style={{ display: 'flex', marginTop: '40px', backgroundColor: '#F2F7F8' }}>
                        <div style={{ paddingLeft: '50px', fontSize: "24px", color: "#3D446F" }} className="caresitedetailsHeading fnt24P">Care Site details</div>

                        <CloseIcon
                            onClick={closewithoutsave}
                            className="close-addcaresite"
                            style={{cursor:'pointer'}}
                        />
                    </div>
                </DialogTitle>

                <DialogContent
                    className="caresitedetailsContentWrapper"
               
                >
                    <form>
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0  ">

                            <div className="w-full lg:w-12/12 mt-3 profilepicuploadWrapper1 mb-3">
                                {
                                    lodingprofile === true ? <div>
                                        <LoadingIcon />
                                    </div> :
                                        props.ClinicProfileUpdate.clinic_puuid ?
                                            <div>
                                                <img
                                                    src={`https://ucarecdn.com/${props.ClinicProfileUpdate.clinic_puuid}/`}
                                                    alt=""
                                                    className="profilepicuploadImageWrapper"
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </div> : clinicdata.clinic_puuid ? <img
                                                src={`https://ucarecdn.com/${clinicdata.clinic_puuid}/`}
                                                alt=""
                                                className="profilepicuploadImageWrapper"
                                                style={{ cursor: "pointer" }}
                                            /> : <img src={profilepicupload} className="profilepicuploadImageWrapper"></img>
                                }

                                <div className="editiconWrapperForcaresite"
                                    onClick={() => widgetApi.current.openDialog()}
                                >
                                    <img src={editImage} className="editImageUploadWrapper"></img>
                                </div>

                                <div style={{ display: "none" }}>
                                    <Widget
                                        styles={{ display: "none" }}
                                        ref={widgetApi}
                                        crop="1:1"
                                        publicKey="596917165e796f402dbd"
                                        id="file"
                                        onFileSelect={(file) => {


                                            if (file) {
                                                file.progress();
                                                file.done((info) => {
                                                    setLoadingProfile(true);
                                                }
                                                );
                                            }
                                        }}
                                        onChange={(info) => {
                                            let uid = info.uuid + '/' + info.cdnUrlModifiers;
                                            setNewImage(uid);
                                            props.clinicPictureUpdate(uid, { id: clinicdata.c_id });
                                        }}
                                    />
                                </div>
                            </div>

                            {/* account number */}
                            <div id="care-site-details-scroll">
                                <div className="flex flex-wrap mt-3">
                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <div className="abmaccount" style={{ marginTop: "20px" }}>

                                                <label
                                                    className="block mb-2 inputHeadingWraper fnt24P"
                                                >
                                                    Account number
                                                </label>
                                                <InputField
                                                    type="text"
                                                    className="inputFieldforcaresitePopup1 fnt24P"
                                                    ref={inputRefs.current[0]}
                                                    handleInputChange={handleInputChange}
                                                    disabled
                                                    name="accountnumber"
                                                    id="outlined-number"
                                                    label="Account number"
                                                    value={values.accountnumber}
                                                    defaultValue={clinicdata.accountnumber ? `${clinicdata.accountnumber}` : ' '}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <div className="abmaccount" style={{ marginTop: "20px" }}>

                                                <label
                                                    className="block mb-2 inputHeadingWraper  fnt24P"
                                                >
                                                    Care Site name
                                                </label>
                                                <InputField
                                                    type="text"
                                                    className="inputFieldforcaresitePopupi fnt24P"
                                                    ref={inputRefs.current[1]}
                                                    handleInputChange={handleInputChange}
                                                    validation="required|min:6|alphabetsOnly|max:35"
                                                    placeholder=""
                                                    name="clinicname"
                                                    value={values.clinicname}
                                                    defaultValue={clinicdata.clinicname ? `${clinicdata.clinicname}` : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* address line 1 and 2 */}
                                <div className="flex flex-wrap mt-3">
                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <div className="abmaccount">
                                                <label
                                                    className="block mb-2 inputHeadingWraper fnt24P"
                                                >
                                                    Address line 1
                                                </label>
                                                <InputField
                                                    type="text"
                                                    className="inputFieldforcaresitePopup1 fnt24P"
                                                    ref={inputRefs.current[2]}
                                                    handleInputChange={handleInputChange}
                                                    validation="required|max:50"
                                                    placeholder=""
                                                    name="addressline1"
                                                    value={values.addressline1}
                                                    defaultValue={clinicdata.address ? `${clinicdata.address.addressline1}` : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <div className="abmaccount">
                                                <label
                                                    className="block mb-2 inputHeadingWraper fnt24P"
                                                >
                                                    Address line 2
                                                </label>
                                                <InputField
                                                    type="text"
                                                    className="inputFieldforcaresitePopupi fnt24P"
                                                    ref={inputRefs.current[3]}
                                                    handleInputChange={handleInputChange}
                                                    validation="required|max:50"
                                                    placeholder=""
                                                    name="addressline2"
                                                    value={values.addressline2}
                                                    defaultValue={clinicdata.address ? `${clinicdata.address.addressline2}` : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* city and state */}
                                <div className="flex flex-wrap mt-3">

                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <div className="abmaccount">
                                                <label
                                                    className="block mb-2 countryinputHeadingWraper fnt24P"
                                                >
                                                    Country
                                                </label>
                                                <Autocomplete
                                                    options={contries}
                                                    clearIcon={null}
                                                    popupIcon={null}
                                                    // value={"United States"}
                                                    getOptionLabel={(option) => option.country ||
                                                        contriesValue



                                                    }
                                                    onChange={(e, value) => {

                                                        setCountryValue(value && value.country)
                                                        if(!value){
                                                          setCountryError({val:true,msg:'Required'});
                                                          return;
                                                        }
                                                        else{
                                                          setCountryError({val:false,msg:'ok'})
                                                        }
                                                    }}
                                                    autoHighlight
                                                    id="disable-close-on-select"
                                                    country
                                                    renderInput={(params) => (

                                                        <TextField {...params}
                                                            onChange={(e) => {  }}
                                                            variant="standard" />
                                                    )}
                                                    defaultValue={clinicdata.address ? `${clinicdata.address.country}` ? `${clinicdata.address.country}` : `${clinicdata.country}` : ''}
                                                    style={{width:'88%'}}
                                                /><p style={{
                                                    color: 'red',
                                                    fontSize: '12px',
                                                    textAlign: 'left',
                                                    paddingTop: '12px',
                                                    marginLeft: '2px',
                                                }}>{countryError.val?countryError.msg:''}</p>

                                                {/* <InputField
                                                type="text"
                                                className="inputFieldforcountry fnt24P"
                                                ref={inputRefs.current[7]}
                                                handleInputChange={handleInputChange}
                                                validation="required"
                                                placeholder=""
                                                name="country"
                                                value={values.country}
                                                defaultValue={clinicdata.address ? `${clinicdata.address.country}`?`${clinicdata.address.country}`:`${clinicdata.country}` : ''}
                                            /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <div className="abmaccount">
                                                <label
                                                    className="block mb-2 inputHeadingWraper fnt24P"
                                                >
                                                    State
                                                </label>
                                                {contriesValue === "United States" ?
                                                     <div>
                                                    <Autocomplete
                                                        options={state}
                                                        clearIcon={null}
                                                        popupIcon={null} 
                                                        getOptionLabel={(option) => option.name || stateValue
                                                        }
                                                        onChange={(e, value) => {

                                                            setStateValue(value && value.name);
                                                            if(!value){
                                                            setStateError({val:true,msg:'Required'});
                                                             return;
                                                                     }
                                                            else{
                                                            setStateError({val:false,msg:'ok'})
                                                                }
                                                            let flag = alphabetsOnlyWithSpace(value.name);
                                                            if(flag){
                                                            flag = value.name.length>35?false:true
                                                            setStateError(flag?{val:false,msg:'ok'}:{val:true,msg:'This input must be no longer than 35 characters long'});
                                                                    }
                                                            else{
                                                            setStateError({val:true,msg:'should contain only alphabets and/or space'});
                                                                }
                                                        }}
                                                        autoHighlight
                                                        id="disable-close-on-select"

                                                        renderInput={(params) => (

                                                            <TextField {...params} variant="standard" />
                                                        )}
                                                        defaultValue={clinicdata.address ? `${clinicdata.address.state}` : ''}
                                                        // className="inputHeadingWraper2forstate fnt24P"
                                                    /><p style={{
                                                        color: 'red',
                                                        fontSize: '12px',
                                                        textAlign: 'left',
                                                        paddingTop: '12px',
                                                        marginLeft: '2px',
                                                    }}>{stateError.val?stateError.msg:''}</p>
                                                    </div>
                                                    :
                                                    <InputField
                                                        type="text"
                                                        className="inputFieldforcaresitePopupi fnt24P"
                                                        ref={inputRefs.current[5]}
                                                        handleInputChange={handleInputChange}
                                                        validation="required|alphabetsOnly"
                                                        placeholder=""
                                                        name="state"
                                                        value={values.state}
                                                        defaultValue={clinicdata.address ? `${clinicdata.address.state}` : ''}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>




                                    {/* zip and country */}
                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <div className="abmaccount">
                                                <label
                                                    className="block mb-2 inputHeadingWraper fnt24P"
                                                >
                                                    City
                                                </label>
                                                <InputField
                                                    type="text"
                                                    className="inputFieldforcaresitePopup1 fnt24P"
                                                    ref={inputRefs.current[4]}
                                                    handleInputChange={handleInputChange}
                                                    validation="required|alphabetsOnly"
                                                    placeholder=""
                                                    name="city"
                                                    value={values.city}
                                                    defaultValue={clinicdata.address ? `${clinicdata.address.city}` : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <div className="abmaccount">
                                                <label
                                                    className="block mb-2 inputHeadingWraper fnt24P"
                                                >
                                                    Zip
                                                </label>
                                                <InputField
                                                    type="text"
                                                    className="inputFieldforcaresitePopupi fnt24P"
                                                    ref={inputRefs.current[6]}
                                                    handleInputChange={handleInputChange}
                                                    validation="required|alphanumeric|min:3|max:9"
                                                    placeholder=""
                                                    name="postal"
                                                    value={values.postal}
                                                    defaultValue={clinicdata.address ? `${clinicdata.address.postal}` : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* phone and email */}
                                <div className="flex flex-wrap mt-3">
                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <div className="abmaccount">
                                                <label
                                                    className="block mb-2 inputHeadingWraper fnt24P"
                                                >
                                                    Phone
                                                </label>
                                                <PhoneInput
                                                    className="inputFieldforcaresitePopup1 fnt24P"
                                                    international
                                                    placeholder=""
                                                    value={clinicdata.phonenumber}
                                                    inputProps={{
                                                        name: "phonenumber",
                                                        required: true,
                                                    }}
                                                    onChange={setPhoneValue}
                                                    inputStyle={{
                                                        width: "100%",
                                                        border: "none !important",
                                                        background: "#fff",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <div className="abmaccount">
                                                <label
                                                    className="block mb-2 inputHeadingWraper2 fnt24P"
                                                >
                                                    Email
                                                </label>
                                                <InputField
                                                    type="text"
                                                    className="lastnameinputHeadingWraper2 fnt24P"
                                                    ref={inputRefs.current[9]}
                                                    handleInputChange={handleInputChange}
                                                    validation="required"
                                                    placeholder=""
                                                    name="email"
                                                    value={values.email}
                                                    defaultValue={clinicdata.email ? `${clinicdata.email}` : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>  */}
                                </div>

                                {/* website */}
                                <div className="flex flex-wrap mt-3">
                                    <div className="w-full lg:w-12/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <div className="abmaccount">
                                                <label
                                                    className="block mb-2 inputHeadingWraper fnt24P"
                                                >
                                                    Website
                                                </label>
                                                <InputField
                                                    type="text"
                                                    className="inputFieldforcaresitePopup fnt24P"
                                                    ref={inputRefs.current[7]}
                                                    handleInputChange={handleInputChange}
                                                    validation="required|website"
                                                    placeholder=""
                                                    name="website"
                                                    value={values.website}
                                                    defaultValue={clinicdata.website ? `${clinicdata.website}` : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="updatebtnCenter">
                        <Button
                            // variant="contained"
                            onClick={() => onSubmit()}
                            disabled={valid}
                        >
                            <div className="submitBtnforCaresitePopup fnt32P"> {saveClickEventForCareSiteDetails ? <Box sx={{
                                display: 'flex', width: "50px", height: "46px", marginLeft: "70px"
                            }}>  <LoadingIcon /> </Box> : "Submit"}</div>

                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (store) => {
    // console.log("store in care site",store.invitation.clinicProfileUpdateResponse);
    return {
        profileData: store.user.user,
        // clinicItemUpdatedData: store.invitation.clinicProfileUpdateResponse,
        clinicItemData: store.invitation.clinicProfileUpdate,
        clinicuserUpdateMsg: store.invitation.clinicuserUpdateMsg,
        clinicuserErrorMsg: store.invitation.clinicuserErrorMsg,
        ClinicProfileUpdate: store.invitation.ClinicProfileUpdate
    };
};

export default connect(mapStateToProps, {
    fetchInvitation, updateProfile, updateClinicProile, clinicPictureUpdate, fetchClinicProfile
})(CaresiteDetailsPopup);
