



import React, { useState, useEffect } from "react";



import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
 
  
  checkvaliduser,
  
  forgotpasswordconfirmotp,

  
  resetforgetErrorMsg,

  
  resend_otp_forgot,
  makeOtpResendforgotEmpty
} from "../../../../redux/user/user.action";

import {
 
  AlertMsg,
} from "../../../../redux/invitation/components/invitation.action";

import { makeStyles } from "@material-ui/core/styles";
import InputField from "../../../../components/Inputs_Validation/index";
import ".././styles.css";
import welcome from "../../../../assets/img/welcome.png";
import back from "../../../../assets/img/backbutton.png";

import OtpInput from "react-otp-input";
import FooterSign from "../../../../components/Footers/FooterSign";

import '../../../../assets/styles/font.css';
import CustomSnackbar from "../../../../components/Alertmessage/AlertMsg";
import LodingIcon from "../../../../components/LoadingIcon";
import Box from '@mui/material/Box';
import ResetPassword from "../ResetPassword";





const Restorepasswordwithphone = (props) => {
  

const [otp, setopt] = useState("");
const [values, setValues] = React.useState({});

const [openSnack, setOpenSnack] = React.useState(false);
const [snackSeverity, setSnackSeverity] = React.useState('error');
const [snackMsg, setSnackmsg] = React.useState('');
const [anchororigin, setanchorOrigin] = useState({
  vertical: 'top',
  horizontal: 'center',
});
const [minutes,setminutes]=useState(4);
  const [seconds,setseconds]=useState('00');
const inputRefs = React.useRef([React.createRef(), React.createRef()]);
const [counter, setCounter] = React.useState(300);
const [resendOtpConditaion, setresendotpCondition] = React.useState("resendotp");
const [clickNextButton, SetClickNextButton] = React.useState(false);
const [valid,setvalid ]=React.useState(false);
const otpInputRef = React.useRef();
const handleInputChange = (name, value) => {
  if (value.length > 0) {
    setValues({
      ...values,
      [name]: value,
    });
  }
};


// console.log('otpsent',props.otpSent)
const submit = async () => {
  SetClickNextButton(true);
  let isValid = true;
  for (let i = 0; i < inputRefs.current.length; i++) {
    if (inputRefs.current[i].current === null) {
      continue;
    }
    const valid = inputRefs.current[i].current.validate();
    if (!valid) {
      isValid = false;
    }
  }
  if (isValid === true) {
    setvalid(true)
    console.log('values',values)
    props.checkvaliduser(values);
    /*   SetClickNextButton(false); */
  }
  else{
    SetClickNextButton(false)
    setOpenSnack(true);
    setSnackmsg("Please fix the input erros")
  }
};


useEffect(() => {
  if (resendOtpConditaion === "resendotp") {
    setresendotpCondition("mailmsg");
  }

  if (props.otpSent === true) {
    SetClickNextButton(false);
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    setresendotpCondition("otpexpire")
  }
  if (counter === 0) {
    setresendotpCondition("resendotp");
  }
  return () => props.makeOtpResendforgotEmpty();

}, [counter, props.otpSent]);


const resendCode = () => {
  props.makeOtpResendforgotEmpty();
  setresendotpCondition("mailmsg");
  props.resend_otp_forgot(values);
  setopt('');
  otpInputRef.current.focusInput(0);
}

function timer1(counter) {
  // console.log('counter',counter)
      setminutes(Math.floor(counter / 60));
      let sec =  counter % 60;
      if (Math.floor((sec / 10))==0){setseconds('0'+sec.toString()); }
      else {setseconds(sec.toString());
      }
       
    }
    useEffect(()=>timer1(counter),[counter])
useEffect(() => {
  if (props.resendotpcodeforgot === "resendOtpforgot" && props.otpSent === true) {
    setCounter(299);
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    setresendotpCondition("otpexpire")
  }
}, [props.resendotpcodeforgot, props.otpSent])



const submitOTP = () => {
  SetClickNextButton(true);
  if (
    otp.length === 4 &&
    props.otpToken /* && otp === props.loginOtp */
  ) {
    let code = otp;
    props.forgotpasswordconfirmotp({ code, token: props.otpToken });
    setOpenSnack(false);
  } else {
    SetClickNextButton(false);
    setSnackmsg("Incorrect code! Please try again")
    setOpenSnack(true);
  }
};


useEffect(() => {
  if (props.forgotisMatch ) {
    SetClickNextButton(false);
    
    
  }
}, [props.forgotisMatch])




const handleChange = (otp) => {
  setopt(otp);
};

const handleSnackbar = () => {
  setOpenSnack(false);
  props.resetforgetErrorMsg();
};


useEffect(() => {
  console.log('duuuudddee',props)
  if (props.forgetErrorMsg) {
    
    setOpenSnack(true);
    setSnackmsg(props.forgetErrorMsg);
    SetClickNextButton(false);
  } else if (props.forgetotpexpireerror === 404) {
    setOpenSnack(true);
    setSnackmsg("Verification code is expired! Please try again with new one");
    SetClickNextButton(false);
  }
  else if (props.forgetotpexpireerror === 400) {
    setOpenSnack(true);
    setSnackmsg("Incorrect Code. Please check your code and try again.")
  } else {
    setOpenSnack(false);
    props.resetforgetErrorMsg();
  }

}, [props.forgetErrorMsg, props.forgetotpexpireerror])



return (props.forgotisMatch? <ResetPassword profile_id={props.profile_id} />:
  <>
    <CustomSnackbar
      flag={openSnack}
      onClose={handleSnackbar}
      anchorOrigin={anchororigin}
      severity={snackSeverity}
      msg={snackMsg}
    />
    
    <div className="wrapperContainer">

      <div className="lg:flex">
        <div className="flex-1 leftSide">
          <div className="imgWrapper">
            <img src={welcome} className="image" alt="" />
          </div>
          <div className="LoginLeftContentWrapper py-16">
            Get Connected. Stay Informed. <br /> Collaborate.
          </div>
        </div>
        <div className="flex-1 rightSide">
        <Link to='/forgotpassword'>
        <div className="backbutton">
            <img   src={back} alt="my image" />
            </div></Link>
          <div className="text_1">
          
            <div className="innerText">
            
              Welcome
              

              <div className="innerText2">
              
                <span style={{ color: "#6a768f" }}>to</span> Arc Connect
              </div>
            </div>
          </div>
          
          
           
            
            <div className="inputFieldHeightWidthAdjustment">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-full">
                <div className="signInEmailAddress fnt24P" >
                   Enter registered Phone number
 
                
                </div>
                <div>
                  <InputField
                    type="Phonenumber"
                    className="phoneInputforgot fnt24P"
                    placeholder="   Phone number"
                    disabled={props.otpSent}
                    ref={inputRefs.current[0]}
                    handleInputChange={handleInputChange}
                    validation="required|max:15|genericPhonenumber"
                    name="phonenumber"
                    autofocus={true}
                  />
                  

                </div>

              </div>

            </div>
            </div>
            
            {props.otpSent === true ? (
              <div>

                <div className="otpMsg fnt21P">
                <p>We have sent a text to the phone number we have on file.<br></br>
                <br></br>
                Please check your phone and enter the verification code:</p>
                </div>

                <div className="InputOpt">
                  <OtpInput
                    ref={otpInputRef}
                    value={otp}
                    type="password"
                    onChange={handleChange}
                    numInputs={4}
                    inputStyle={{
                      width: "80px",
                      fontSize: "18px",
                      padding: "21px",
                      marginLeft: "7px",
                      borderRadius: "13px",
                      boxShadow: "#6A768F80 0px 3px 6px",
                      border: "none",
                    }}
                    shouldAutoFocus={true}
                    separator={<span></span>}
                  />
                </div>
                <div className="otp-time">
                  {
                    resendOtpConditaion === "resendotp" ? <p id="resnedotp">Your earlier Verification code has been expired ! <span>
                      <a style={{ color: "#f26930", cursor: "pointer" }} onClick={() => resendCode()}>Resend Verification code</a></span></p> : null
                  }
                  {
                    resendOtpConditaion === "otpexpire" ? <p id="otpexpire">Your Verification code Expire in : <span>{minutes}:{seconds} sec</span> </p> : null
                  }

                  {resendOtpConditaion === "mailmsg" ? <p style={{ color: "green" }}>New Verification code has been sent to your registered mobile!</p> : null}
                </div>
                <div className="nextButtonnnnOuter">
                  <div
                    className="nextButtonnnn next fnt32P"
                    type="button"
                      onClick={() =>
                        props.otpSent === true ? submitOTP() : submit()
                      }
                  >
                    <div>
                      {clickNextButton  && valid? <Box sx={{
                        display: 'flex', width: "40px", height: "29px", marginTop: "-5px"
                      }}>  <LodingIcon /></Box> : "Submit"}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {
                  <div className="nextButtonnnnOuter flex flex-wrap nextbutn">
                    <div
                      className="nextButtonnnn fnt32P  next w-full lg : w-full"
                      type="button"
                     
                      onClick={() =>
                        props.otpSent === true ? submitOTP() : submit()
                      }
                    >
                      {/*   Next */}
                      {clickNextButton && valid ? <Box sx={{
                        display: 'flex', width: "40px", height: "29px", marginTop: "-5px"
                      }}>  <LodingIcon /></Box> : "Next"}
                    </div>
                    <a
                      href="/"
                      style={{ cursor: "pointer", textAlign:"center",paddingTop:"20px",fontWeight:"bold" , color:"#F26930",textDecorationColor:"#F26930", textDecoration:"underline" }}
                    >
                      Login
                    </a>
                  </div>
                  
                }
                
               
              </div>
            )}
             
         
         
        </div>
      </div>
    </div>
    <div className="">
      <FooterSign />
    </div>
  </>
);
};
const mapStateToProps = (store) => {
return {
  msg: store.invitation.msg,
  otpSent: store.user.otpSent1,
  otpToken: store.user.otpToken1,
  user: store.user.user,
  isMatch: store.user.isMatch,
  forgotisMatch:store.user.forgotisMatch,
  profile_id:store.user.profile_id,
  forgetOtp: store.user.forgetOtp,
  forgetErrorMsg: store.user.forgetErrorMsg,
  forgetotpexpireerror: store.user.forgetotpexpireerror,
  resendotpcodeforgot: store.user.resendotpcodeforgot,
};
};
export default connect(mapStateToProps, {


AlertMsg,

checkvaliduser,

forgotpasswordconfirmotp,


resetforgetErrorMsg,
resend_otp_forgot,
makeOtpResendforgotEmpty
})(Restorepasswordwithphone);
