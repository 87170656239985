import React from "react";
import { connect } from "react-redux";
import eyeIcon from "../../../assets/img/eye.svg";
import { userChatConversion } from "../../../redux/user/user.action";
import {
  loadMessagesToId,
  selectedUserChat,
  currentDiaglogID,
} from "../../../redux/quickbloxmessaging/quickblox.action";

import { showChatWindow } from "../../../redux/sharing/sharing.action";

import moment from "moment";
const ChatListItems = (props) => {
  return (
    <div>
      {props.allDialogs && props.allDialogs.length > 0 ? (
        props.allDialogs.map((item, index) => {
          var userChat = item.avatars ? item.avatars.length : "";
          var userChatCount = userChat - 2;
          let lastseen =
            item.last_message_date_sent &&
            moment(item.last_message_date_sent*1000).fromNow();
          if (lastseen !== null) {
            return (

              <div
                key={index}
                className="chatlist__item"
                style={{ animationDelay: `0.${props.animationDelay}s` }}
                onClick={() => {
                  props.loadMessagesToId(item._id, item.last_message_id);
                  props.selectedUserChat(item);
                  props.showChatWindow(true);
                  props.currentDiaglogID(item);
                }}
              >
                {item.avatars.filter(Boolean).length === 0 ? (
                  <div className="MultiUserContainer">
                    <div>
                      <div className="firstImage">
                        <i
                          style={{ padding: "16px", marginTop: "16px" }}
                          className="fa fa-user userforchat"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="MultiUserContainer">
                    {item.avatars.length === 2 ? (
                      <div>
                        <div className="firstImage">
                          {item.avatars[0] ? (
                            <img src={item.avatars[0]} />
                          ) : (
                            <i
                              style={{ padding: "16px", marginTop: "16px" }}
                              className="fa fa-user userforchat"
                              aria-hidden="true"
                            ></i>
                          )}
                        </div>
                        <div className="secondImage">
                          {item.avatars[1] ? (
                            <img src={item.avatars[1]} />
                          ) : (
                            <i
                              className="fa fa-user userforchat"
                              aria-hidden="true"
                              style={{
                                position: "absolute",
                                top: "29px",
                                left: "28px",
                                padding: "15px",
                                fontSize: "14px",
                              }}
                            ></i>
                          )}
                        </div>
                      </div>
                    ) : null}
                    {item.avatars.length > 2 ? (
                      <div>
                        <div className="firstImage">
                          <img src={item.avatars.filter(Boolean)[0]} />
                        </div>
                        <div className="secondImage">
                          {item.avatars.filter(Boolean)[1] ? (
                            <img src={item.avatars.filter(Boolean)[1]} />
                          ) : (
                            <i
                              className="fa fa-user userforchat"
                              aria-hidden="true"
                              style={{
                                position: "absolute",
                                top: "29px",
                                left: "25px",
                                padding: "15px",
                                fontSize: "14px",
                              }}
                            ></i>
                          )}
                        </div>
                        <div className="MoreUserCount">
                          <span style={{ marginLeft: "3px" }}>
                            {userChatCount}+
                          </span>
                        </div>
                      </div>
                    ) : null}
                    {item.avatars.length === 1 ? (
                      <div>
                        <div
                          className="singleUser"
                          style={{ marginTop: "15px" }}
                        >
                          {item.avatars[0] ? (
                            <img src={item.avatars[0]} />
                          ) : (
                            <i
                              className="fa fa-user userforchat"
                              aria-hidden="true"
                            ></i>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}

                <div className="userMeta">
                  <p className="user">{item.name}</p>
                  {<span className="activeTime">{item.last_message}</span>}
                </div>
                {" "}
                {item.unread_messages_count > 0 ?
                  <div className="Allmsg">
                    <span className="msg_count_data">{item.unread_messages_count}</span>
                  </div>  : 
                    <div className="No_msg"></div>
                  }
                {" "}

                {item.last_message_date_sent == null ? (
                  <div>
                    <div className="eyeIcon"></div>
                    <div className="lastTime">{lastseen}</div>
                  </div>
                ) : (
                  <div>
                    <div className="eyeIcon">
                      <img src={eyeIcon} className="eyemsg" />
                    </div>
                  </div>
                )}
                {item.unread_messages_count.length === 0 ? (
                  ""
                ) : (
                  <div className="lastTime">{lastseen}</div>
                )}
              </div>

            );
          }
        })
      ) : (
        <p> No messages found </p>
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    currentUserProfile: store.user.user,
    chatProfilesByQbId: store.quickblox.profileByQBID,
  };
};
export default connect(mapStateToProps, {
  userChatConversion,
  loadMessagesToId,
  selectedUserChat,
  currentDiaglogID,
  showChatWindow,
})(ChatListItems);
