import React from "react";

import Route from "./routes";

const App = () => {
  return (
    <>
      <Route />
    </>
  );
};
export default App;
