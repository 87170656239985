/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import "./styles.css"; // Import the CSS file
import {
  deleteDeviceStats,
  fetchDevicePerformanceStats,
} from "../../../redux/devices/devices.action";
import { connect } from "react-redux";
import { objectToCSV } from "../../../utils";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Suspense } from "react";
import LoadingIcon from "../../../components/LoadingIcon";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DatePicker from "react-datepicker";

const DeviceStatsTable = ({
  deviceStats,
  fetchDevicePerformanceStats,
  deleteDeviceStats,
}) => {
  const allColumns = [
    { Header: "ID", accessor: "id", defaultSelected: true },
    { Header: "Device Type", accessor: "device_type", defaultSelected: true },
    { Header: "Core", accessor: "core", defaultSelected: true },
    { Header: "Device ID", accessor: "device_id", defaultSelected: true },
    {
      Header: "Memory",
      accessor: "memory_usage_percentage",
      defaultSelected: true,
    },
    { Header: "CPU", accessor: "cpu_usage_percentage", defaultSelected: true },
    { Header: "Temp", accessor: "temperature", defaultSelected: true },
    { Header: "Test", accessor: "test", defaultSelected: true },
    { Header: "Timestamp", accessor: "created_at", defaultSelected: true },
    {
      Header: "T Start Time",
      accessor: "theraphy_start_time",
      defaultSelected: true,
    },
    {
      Header: "T End Time",
      accessor: "theraphy_end_time",
      defaultSelected: true,
    },
    { Header: "Profile", accessor: "profile", defaultSelected: true },
    { Header: "Test Type", accessor: "test_type", defaultSelected: false },
    { Header: "Usr", accessor: "usr", defaultSelected: false },
    { Header: "Sys", accessor: "sys", defaultSelected: false },
    { Header: "Nic", accessor: "nic", defaultSelected: false },
    { Header: "Idle", accessor: "idle", defaultSelected: false },
    { Header: "IO", accessor: "io", defaultSelected: false },
    { Header: "IRQ", accessor: "irq", defaultSelected: false },
    { Header: "SIRQ", accessor: "sirq", defaultSelected: false },
    { Header: "Mode", accessor: "mode", defaultSelected: false },
    { Header: "Oscillation", accessor: "oscillation", defaultSelected: false },
    { Header: "Frequency", accessor: "frequency", defaultSelected: false },
    { Header: "Amplitude", accessor: "amplitude", defaultSelected: false },
    { Header: "Cycle", accessor: "cycle", defaultSelected: false },
    { Header: "Flow", accessor: "flow", defaultSelected: false },
    {
      Header: "Pause Pressure",
      accessor: "pause_pressure",
      defaultSelected: false,
    },
    {
      Header: "Insp Pressure",
      accessor: "insp_pressure",
      defaultSelected: false,
    },
    {
      Header: "Exp Pressure",
      accessor: "exp_pressure",
      defaultSelected: false,
    },
    { Header: "Duration", accessor: "duration", defaultSelected: false },
    {
      Header: "Test Description",
      accessor: "test_dec",
      defaultSelected: false,
    },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    allColumns
      .filter((column) => column.defaultSelected)
      .map((column) => column.accessor)
  );
  const [headers, setHeaders] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const handleSetProfiledata = (data) => {
    const profileData = data.row.original.profile
      ? data.row.original.profile
      : "";
    if (profileData) {
      const jsonData = profileData ? JSON.parse(profileData) : [];
      const headers = jsonData.length > 0 ? Object.keys(jsonData[0]) : [];
      setJsonData(jsonData);
      setHeaders(headers);
    }
  };
  const CustomProfileCell = ({ value }) => {
    return (
      <>
        {value.row.original.profile && (
          <p
            onClick={(e) => {
              openProfileDialog();
              handleSetProfiledata(value);
            }}
          >
            View
          </p>
        )}
      </>
    );
  };
  const CustomTimeStampCell = ({ value }) => {
    return (
      <>
        {value.row.original.created_at && (
          <p>{new Date(value.row.original.created_at).toLocaleString()}</p>
        )}
      </>
    );
  };
  const CustomStartTimeStampCell = ({ value }) => {
    return (
      <>
        {value.row.original.theraphy_start_time && (
          <p>
            {new Date(value.row.original.theraphy_start_time).toLocaleString()}
          </p>
        )}
      </>
    );
  };
  const CustomEndTimeStampCell = ({ value }) => {
    return (
      <>
        {value.row.original.theraphy_end_time && (
          <p>
            {new Date(value.row.original.theraphy_end_time).toLocaleString()}
          </p>
        )}
      </>
    );
  };
  const columns = useMemo(() => {
    return allColumns.filter((column) =>
      selectedColumns.includes(column.accessor)
    );
  }, [selectedColumns]);

  const handleCheckboxChange = (columnAccessor) => {
    setSelectedColumns((prevSelectedColumns) => {
      if (prevSelectedColumns.includes(columnAccessor)) {
        // Remove column if it was selected
        return prevSelectedColumns.filter((col) => col !== columnAccessor);
      } else {
        // Add column if it wasn't selected
        return [...prevSelectedColumns, columnAccessor];
      }
    });
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);

  const openProfileDialog = () => {
    setIsProfileDialogOpen(true);
  };

  const closeProfileDialog = () => {
    setIsProfileDialogOpen(false);
  };
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };
  const [isCalenderDialogOpen, setIsCalenderDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date(new Date().getTime()- 10 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());
  const [filterDate, setFilterDate] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const openCalenderDialog = () => {
    setIsCalenderDialogOpen(true);
  };

  const closeCalenderDialog = () => {
    setIsCalenderDialogOpen(false);
  };
  const handleClearCalender = () => {
    setFilterDate(false);
    setIsCalenderDialogOpen(false);
    setEndDate(new Date());
    setStartDate(new Date(new Date().getTime()- 10 * 60 * 1000));
    setFetchTrigger(true);
  };
  const handleFetchDateRange = () => {
    setFilterDate(true);
    setIsCalenderDialogOpen(false);
    setFetchTrigger(true);
  };

  const DateTimePicker = ({ label, selectedDate, onChange }) => (
    <div style={{ margin: "15px" }}>
      <label style={{ margin: "10px" }}>{label}</label>
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={5}
        timeCaption="Time"
        dateFormat="MMMM d, yyyy h:mm aa"
        className="datePicker"
        utcOffset={0}
      />
    </div>
  );
  const handleDeleteConfirm = async () => {
    await deleteDeviceStats();
    closeDeleteDialog();
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(jsonData, null, 2));
  };

  const firstHalf = allColumns.slice(0, Math.ceil(allColumns.length / 2));
  const secondHalf = allColumns.slice(Math.ceil(allColumns.length / 2));
  const [isResolutionAcceptable, setIsResolutionAcceptable] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [downloadOption, setDownloadOption] = useState("csv");
  const [gotoActive, setGotoActive] = useState(false);
  const [average, setAverage] = useState(
    deviceStats.averages || {
      averageSingleCoreMemoryUsage: 0.0,
      averageSingleCoreCpuUsage: 0.0,
      averageDualCoreMemoryUsage: 0.0,
      averageDualCoreCpuUsage: 0.0,
      averageSingleCoreTemp: 0.0,
      averageDualCoreTemp: 0.0,
    }
  );
  const [data, setData] = useState(deviceStats.pageData ?? []);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [password, setPassword] = useState("");
  const [totalPages, settotalPages] = useState(deviceStats.totalPages - 1 ?? 1);
  const [totalRecords, setTotalRecords] = useState(deviceStats.setTotalRecords);
  const [searchQuery, setSearchQuery] = useState("");
  const checkResolution = () => {
    // Get the screen width and height
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const minWidth = 800;
    const minHeight = 600;
    const isAcceptable = screenWidth >= minWidth && screenHeight >= minHeight;
    setIsResolutionAcceptable(isAcceptable);
  };

  useEffect(() => {
    checkResolution();
    window.addEventListener("resize", checkResolution);
    return () => {
      window.removeEventListener("resize", checkResolution);
    };
  }, []);
  useEffect(() => {
    setData(deviceStats.pageData);
    settotalPages(deviceStats.totalPages - 1);
    setAverage(deviceStats.averages);
    setTotalRecords(deviceStats.totalRecords);
  }, [deviceStats]);
  const handleFirstPage = () => {
    setPageIndex(0);
  };

  const handlePreviousPage = () => {
    setPageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextPage = () => {
    setPageIndex((prevIndex) => prevIndex + 1);
  };

  const handleLastPage = () => {
    setPageIndex(Math.max(0, totalPages));
  };

  const handlePageSizeChange = (e) => {
    setPageIndex(0);
    setPageSize(Number(e.target.value));
  };
  const handleQuery = (value) => {
    setSearchQuery(value);
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  useEffect(async () => {
    if (fetchTrigger) {
      refresh();
      setFetchTrigger(false);
    }
  }, [filterDate, fetchTrigger]);
  useEffect(() => {
    setFetchTrigger(true);
  }, [pageIndex, pageSize, searchQuery]);
  useEffect(() => {
    if (isLoading && deviceStats.pageData?.length < 1) {
      setLoading(true);
    }
  }, [deviceStats]);
  const refresh = () => {
    setLoading(true);
    const searchDateRange = filterDate
      ? {
          startDate: startDate && startDate.toISOString(),
          endDate: endDate && endDate.toISOString(),
        }
      : null;
    fetchDevicePerformanceStats(
      pageIndex,
      pageSize,
      searchQuery,
      searchDateRange
    ).then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };
  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const exportCsv = () => {
    const exportedData = rows.map((row) => row.original);
    const csv = objectToCSV(exportedData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download =
      "DeviceStats " + new Date().toLocaleString("en-IN") + ".csv";
    link.click();
  };

  const exportData = () => {
    const exportedData = rows.map((row) => row.original);
    downloadExcel(exportedData);
  };
  const calculateAverages = (table) => {
    const singleCoreData = table.filter((entry) => entry.core === "Single");
    const dualCoreData = table.filter((entry) => entry.core === "Dual");

    const singleCoreAverages = {
      "Avg. CPU Single": calculateAverage(
        singleCoreData,
        "cpu_usage_percentage"
      ),
      "Avg. MEM Single": calculateAverage(
        singleCoreData,
        "memory_usage_percentage"
      ),
      "Avg. Temp Single": calculateAverage(singleCoreData, "temperature"),
    };

    const dualCoreAverages = {
      "Avg. CPU Dual": calculateAverage(dualCoreData, "cpu_usage_percentage"),
      "Avg. MEM Dual": calculateAverage(
        dualCoreData,
        "memory_usage_percentage"
      ),
      "Avg. TEMP Dual": calculateAverage(dualCoreData, "temperature"),
    };

    return { singleCoreAverages, dualCoreAverages };
  };

  const downloadExcel = (table) => {
    const worksheet = XLSX.utils.json_to_sheet(table);
    const { singleCoreAverages, dualCoreAverages } = calculateAverages(table);

    const singleCoreAveragesWorksheet = XLSX.utils.json_to_sheet([
      singleCoreAverages,
    ]);
    const dualCoreAveragesWorksheet = XLSX.utils.json_to_sheet([
      dualCoreAverages,
    ]);

    const workbook = {
      Sheets: {
        "All Stats": worksheet,
        "Single Core Averages": singleCoreAveragesWorksheet,
        "Dual Core Averages": dualCoreAveragesWorksheet,
      },
      SheetNames: ["All Stats", "Single Core Averages", "Dual Core Averages"],
    };

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    saveAsExcelFile(
      excelBuffer,
      "Device Stats " + new Date().toLocaleString("en-IN")
    );
  };
  const calculateAverage = (table, key) => {
    const values = table.map((entry) =>
      key === "cpu_usage_percentage" || key === "temperature"
        ? parseFloat(entry[key].replace("%", "").replace("°C", ""))
        : parseFloat(entry[key])
    );
    const sum = values.reduce((acc, value) => acc + value, 0);
    const average = sum / values.length;
    return isNaN(average) ? 0 : average;
  };
  const saveAsExcelFile = (buffer, fileName) => {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(data, fileName + EXCEL_EXTENSION);
  };

  const handleDownloadOptionChange = (option) => {
    setDownloadOption(option);
  };
  const darkTheme = {
    cardHeader: {
      // backgroundColor: "#f2f2f2", // Set your desired background color
      padding: "15px",
      borderRadius: "8px",
      marginBottom: "15px",
      color: "black",
    },
    averageInfo: {
      display: "block",
      padding: "8px",
      borderRadius: "4px",
      margin: "5px",
      color: "black",
    },
    highlightSingleCore: {
      backgroundColor: "#ffd699",
    },
    highlightDualCore: {
      backgroundColor: "#99ff99",
    },
    search: {
      width: "50%",
      borderRadius: "15px",
      color: "black",
    },
    glass: { borderRadius: "20px", backgroundColor: "rgb(54 33 33 / 57%)" },
    goto: {
      width: "60px",
      color: "black",
      borderRadius: "15px",
    },
    gotoActive: {
      width: "60px",
      color: "white",
      borderRadius: "15px",
    },
    showRows: { borderRadius: "150px", width: "150px" },
  };
  const styles = { ...darkTheme };
  return (
    <>
      <div className="background">
        {
          <Suspense
            fallback={
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                }}
              >
                <LoadingIcon />
              </div>
            }
          >
            {password !== "DeviceStats@1234abm" && (
              <div className="flex justify-center items-center h-screen bg-gradient-to-r from-green-400 to-blue-500">
                <div
                  className="glassmorphic-card p-8 bg-opacity-30 backdrop-blur-md rounded-md shadow-lg"
                  style={styles.glass}
                >
                  <div className="password-input-container">
                    <div className="flex items-center">
                      <label
                        htmlFor="password"
                        className="text-lg font-semibold mb-2 text-gray-800"
                      >
                        {/* Password:{" "} */}
                      </label>
                      {"  "}
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                        style={{ borderRadius: "10px" }}
                        className="py-2 px-4 border rounded-md shadow-md  focus:outline-cyan focus:ring focus:border-blue-300 w-full password-input"
                        required
                        placeholder="Enter your password"
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="ml-2 cursor-pointer"
                      >
                        <i
                          className={`fa ${
                            showPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                          style={{ color: "white" }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {password === "DeviceStats@1234abm" && (
              <div className="table-container card-container">
                <div className="">
                  <div className="search-bar card-header">
                    <input
                      className="card-header input-field"
                      style={styles.search}
                      type="text"
                      value={searchQuery || ""}
                      onChange={(e) => handleQuery(e.target.value)}
                      placeholder="Search..."
                    />
                    <button
                      onClick={openCalenderDialog}
                      style={{ marginRight: "1em" }}
                    >
                      <i
                        style={{ color: filterDate ? "#c7ea48" : "" }}
                        className="fa fa-calendar"
                      ></i>
                    </button>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setPageIndex(0);
                        refresh();
                      }}
                    >
                      {isResolutionAcceptable && <> Refresh </>}{" "}
                      <i className="fas fa-sync"></i>
                    </div>
                    <div>
                      <button onClick={openDialog}>
                        {" "}
                        {isResolutionAcceptable && <> Select Columns </>}{" "}
                        <i className="fa fa-th-list"></i>
                      </button>
                      {isDialogOpen && (
                        <div>
                          <Dialog
                            open={isDialogOpen}
                            onClose={closeDialog}
                            fullWidth="md"
                          >
                            <div
                              style={{
                                backgroundColor: "#f2f4f8",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <h2
                                style={{
                                  margin: 0,
                                  fontSize: "x-large",
                                  padding: "15px",
                                }}
                              >
                                Choose columns to display in the table.
                              </h2>
                              <div style={{ padding: "15px" }}>
                                <CloseOutlinedIcon
                                  onClick={closeDialog}
                                  className="closeIcon"
                                />
                              </div>
                            </div>

                            <DialogContent>
                              {isDialogOpen && (
                                <div
                                  className="dialog"
                                  style={{
                                    justifyContent: "center",
                                    display: "flex",
                                    gap: "20px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th style={{ minWidth: "45px" }}></th>
                                          <th>Column Name</th>
                                        </tr>
                                      </thead>
                                      <tbody style={{ cursor: "pointer" }}>
                                        {firstHalf.map((column) => (
                                          <tr
                                            key={column.accessor}
                                            onClick={() =>
                                              handleCheckboxChange(
                                                column.accessor
                                              )
                                            }
                                          >
                                            <td>
                                              {selectedColumns.indexOf(
                                                column.accessor
                                              ) !== -1 && (
                                                <i className="fa fa-check-square"></i>
                                              )}
                                            </td>
                                            <td>{column.Header}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th style={{ minWidth: "45px" }}></th>
                                          <th>Column name</th>
                                        </tr>
                                      </thead>
                                      <tbody style={{ cursor: "pointer" }}>
                                        {secondHalf.map((column) => (
                                          <tr
                                            key={column.accessor}
                                            onClick={() =>
                                              handleCheckboxChange(
                                                column.accessor
                                              )
                                            }
                                          >
                                            <td>
                                              {selectedColumns.indexOf(
                                                column.accessor
                                              ) !== -1 && (
                                                <i className="fa fa-check-square"></i>
                                              )}
                                            </td>
                                            <td>{column.Header}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}
                            </DialogContent>
                          </Dialog>
                        </div>
                      )}
                    </div>{" "}
                    {/* <span> */}
                    {/* {isResolutionAcceptable && <>Export Data as</>} */}
                    {/* <label>Select Columns:</label> */}
                    {isResolutionAcceptable && (
                      <select
                        style={{ borderRadius: "150px", width: "10%" }}
                        className="card-header"
                        value={downloadOption}
                        onChange={(e) =>
                          handleDownloadOptionChange(e.target.value)
                        }
                      >
                        <option value="csv">CSV</option>
                        <option value="excel">Excel</option>
                      </select>
                    )}
                    <button>
                      <i
                        onClick={() =>
                          downloadOption &&
                          (downloadOption === "csv"
                            ? exportCsv()
                            : exportData())
                        }
                        className="fa fa-download"
                      ></i>
                    </button>
                    <button
                      onClick={openDeleteDialog}
                      style={{ marginRight: "1em" }}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                    {/* <i className="fa fa-download" ></i> */}
                    {/* </span> */}
                  </div>
                  <div className="card-header" style={styles.cardHeader}>
                    <span
                      style={{
                        ...styles.averageInfo,
                        ...styles.highlightSingleCore,
                      }}
                    >
                      Avg. Single Core Mem:{" "}
                      <b>
                        {Number(average.averageSingleCoreMemoryUsage).toFixed(
                          2
                        ) ?? 0}
                      </b>
                    </span>
                    <span
                      style={{
                        ...styles.averageInfo,
                        ...styles.highlightDualCore,
                      }}
                    >
                      Avg. Dual Core Mem:{" "}
                      <b>
                        {Number(average.averageDualCoreMemoryUsage).toFixed(
                          2
                        ) ?? 0}
                      </b>
                    </span>
                    <span
                      style={{
                        ...styles.averageInfo,
                        ...styles.highlightSingleCore,
                      }}
                    >
                      Avg. Single Core CPU:{" "}
                      <b>
                        {Number(average.averageSingleCoreCpuUsage).toFixed(2) ??
                          0}
                      </b>
                    </span>
                    <span
                      style={{
                        ...styles.averageInfo,
                        ...styles.highlightDualCore,
                      }}
                    >
                      Avg. Dual Core CPU:{" "}
                      <b>
                        {Number(average.averageDualCoreCpuUsage).toFixed(2) ??
                          0}
                      </b>
                    </span>
                    <span
                      style={{
                        ...styles.averageInfo,
                        ...styles.highlightSingleCore,
                      }}
                    >
                      Avg. Single Core Temp:{" "}
                      <b>
                        {Number(average.averageSingleCoreTemp).toFixed(2) ?? 0}
                      </b>
                    </span>
                    <span
                      style={{
                        ...styles.averageInfo,
                        ...styles.highlightDualCore,
                      }}
                    >
                      Avg. Dual Core Temp:{" "}
                      <b>
                        {Number(average.averageDualCoreTemp).toFixed(2) ?? 0}
                      </b>
                    </span>
                  </div>

                  <div
                    style={{ height: "55vh", overflowY: "scroll" }}
                    className="card-table"
                  >
                    {isLoading && (
                      <div
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          textAlign: "center",
                        }}
                      >
                        <LoadingIcon />
                      </div>
                    )}
                    <table
                      {...getTableProps()}
                      className="data-table animate-pulse"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      {(!isLoading || rows.length > 0) && (
                        <tbody {...getTableBodyProps()}>
                          {rows &&
                            rows.map((row) => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => {
                                    // Check if the current column is the "profile" column
                                    if (cell.column.id === "profile") {
                                      // Render your custom cell for the "profile" column
                                      return (
                                        <td {...cell.getCellProps()}>
                                          <CustomProfileCell value={cell} />
                                          {/* {`Custom Profile Cell: ${cell.render('Cell')}`} */}
                                        </td>
                                      );
                                    } else if (
                                      cell.column.id === "created_at"
                                    ) {
                                      // Render your custom cell for the "profile" column
                                      return (
                                        <td {...cell.getCellProps()}>
                                          <CustomTimeStampCell value={cell} />
                                          {/* {`Custom Profile Cell: ${cell.render('Cell')}`} */}
                                        </td>
                                      );
                                    } else if (
                                      cell.column.id === "theraphy_start_time"
                                    ) {
                                      // Render your custom cell for the "profile" column
                                      return (
                                        <td {...cell.getCellProps()}>
                                          <CustomStartTimeStampCell
                                            value={cell}
                                          />
                                          {/* {`Custom Profile Cell: ${cell.render('Cell')}`} */}
                                        </td>
                                      );
                                    } else if (
                                      cell.column.id === "theraphy_end_time"
                                    ) {
                                      // Render your custom cell for the "profile" column
                                      return (
                                        <td {...cell.getCellProps()}>
                                          <CustomEndTimeStampCell
                                            value={cell}
                                          />
                                          {/* {`Custom Profile Cell: ${cell.render('Cell')}`} */}
                                        </td>
                                      );
                                    } else {
                                      // Render the default cell for other columns
                                      return (
                                        <td {...cell.getCellProps()}>
                                          {cell.render("Cell")}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                              );
                            })}
                        </tbody>
                      )}
                    </table>
                  </div>

                  <div className="card-footer">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {totalPages + 1}
                      </strong>{" "}
                    </span>
                    <button
                      className="pagination-button"
                      onClick={handleFirstPage}
                      disabled={pageIndex === 0}
                    >
                      <i className="fa fa-angle-double-left" />{" "}
                      {isResolutionAcceptable && <> First Page </>}
                    </button>
                    <button
                      className="pagination-button"
                      onClick={handlePreviousPage}
                      disabled={pageIndex === 0}
                    >
                      <i className="fa fa-angle-left" />{" "}
                      {isResolutionAcceptable && <> Previous Page </>}
                    </button>
                    <span>
                      {/* {isResolutionAcceptable && <> Go to:</>} */}
                      <input
                        className="card-header input-field"
                        style={gotoActive ? styles.goto : styles.gotoActive}
                        type="number"
                        value={pageIndex + 1}
                        onChange={(e) => {
                          const pageNumber = e.target.value
                            ? Number(e.target.value) - 1
                            : 0;
                          setPageIndex(pageNumber);
                        }}
                        onBlur={() => setGotoActive(false)}
                      />
                    </span>
                    <button
                      className="pagination-button"
                      onClick={handleNextPage}
                      disabled={pageIndex === totalPages}
                    >
                      {isResolutionAcceptable && <> Next Page </>}
                      <i className="fa fa-angle-right" />
                    </button>
                    <button
                      className="pagination-button"
                      onClick={handleLastPage}
                      disabled={pageIndex === totalPages}
                    >
                      {isResolutionAcceptable && <> Last Page </>}{" "}
                      <i className="fa fa-angle-double-right" />
                    </button>
                    Total: {totalRecords}
                    <select
                      style={styles.showRows}
                      className="card-header"
                      value={pageSize}
                      onChange={handlePageSizeChange}
                    >
                      {[10, 50, 100, 200, 500, 1000].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Profile Data Dialog */}
                {isProfileDialogOpen && (
                  <div>
                    <Dialog
                      className="table-container"
                      open={isProfileDialogOpen}
                      onClose={closeProfileDialog}
                      fullWidth
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h2
                          style={{
                            margin: 0,
                            fontSize: "x-large",
                            padding: "15px",
                          }}
                        >
                          Profile Data
                        </h2>
                        <div style={{ padding: "15px" }}>
                          <CloseOutlinedIcon
                            onClick={closeProfileDialog}
                            className="closeIcon"
                          />
                        </div>
                      </div>

                      <DialogContent>
                        {isProfileDialogOpen && (
                          <div
                            className="table-container"
                            style={{
                              justifyContent: "center",
                              gap: "20px",
                              overFlow: "scroll",
                            }}
                          >
                            {/* {` ${cell.render('Cell')}`} */}
                            <table border="1">
                              <thead>
                                <tr>
                                  {headers.map((header) => (
                                    <th key={header}>{header}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(jsonData) &&
                                  jsonData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                      {headers.map((header, colIndex) => (
                                        <td key={colIndex}>{row[header]}</td>
                                      ))}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            <br />
                            <h3>
                              {" "}
                              <b> Raw JSON </b>{" "}
                              <button
                                style={{
                                  float: "right",
                                  padding: "8px 12px",
                                  backgroundColor: "#4CAF50",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={copyToClipboard}
                              >
                                <i className="fa fa-copy"> {""}</i> Copy Data
                              </button>
                            </h3>
                            <br />
                            <div style={{ width: "800px", overflow: "auto" }}>
                              <p>{JSON.stringify(jsonData)}</p>
                            </div>
                          </div>
                        )}
                      </DialogContent>
                    </Dialog>
                  </div>
                )}
                {isDeleteDialogOpen && (
                  <div>
                    <Dialog
                      open={isDeleteDialogOpen}
                      onClose={closeDeleteDialog}
                      fullWidth
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h2
                          style={{
                            margin: 0,
                            fontSize: "x-large",
                            padding: "15px",
                          }}
                        >
                          Warning
                        </h2>
                        <div style={{ padding: "15px" }}>
                          <CloseOutlinedIcon
                            onClick={closeDeleteDialog}
                            className="closeIcon"
                          />
                        </div>
                      </div>

                      <DialogContent>
                        {isDeleteDialogOpen && (
                          <div
                            className="table-container"
                            style={{
                              justifyContent: "center",
                              gap: "20px",
                            }}
                          >
                            <div>
                              <p>
                                Are you sure you want to delete the data from
                                Data Base?
                              </p>
                            </div>
                          </div>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={closeDeleteDialog} color="primary">
                          No
                        </Button>
                        <Button
                          onClick={() => {
                            handleDeleteConfirm();
                          }}
                          color="secondary"
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                )}
                {isCalenderDialogOpen && (
                  <div>
                    <Dialog
                      open={isCalenderDialogOpen}
                      onClose={closeDeleteDialog}
                      fullWidth
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          backgroundColor: "lightgray",
                        }}
                      >
                        <h2
                          style={{
                            margin: 0,
                            fontSize: "x-large",
                            padding: "15px",
                          }}
                        >
                          Pick Date Time Range
                        </h2>
                        <div style={{ padding: "15px" }}>
                          <CloseOutlinedIcon
                            onClick={closeCalenderDialog}
                            className="closeIcon"
                          />
                        </div>
                      </div>

                      <DialogContent>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            minHeight: "50vh",
                          }}
                        >
                          <div>
                            <DateTimePicker
                              label="Start Date and Time:"
                              selectedDate={startDate}
                              onChange={(date) => {
                                setStartDate(new Date(date));
                              }}
                            />
                          </div>{" "}
                          <div>
                            {" "}
                            <DateTimePicker
                              label="End Date and Time:"
                              selectedDate={endDate}
                              onChange={(date) => setEndDate(new Date(date))}
                            />
                          </div>
                        </div>
                        <div>
                          {endDate < startDate && (
                            <p style={{ color: "red" }}>
                              {" "}
                              End Date Time should be greater than Start Date
                              and Time
                            </p>
                          )}
                        </div>
                      </DialogContent>
                      <DialogActions style={{ backgroundColor: "lightgray" }}>
                        <Button onClick={handleClearCalender} color="primary">
                          Clear
                        </Button>
                        <Button
                          onClick={handleFetchDateRange}
                          color="secondary"
                          autoFocus
                        >
                          Fetch
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                )}
              </div>
            )}
          </Suspense>
        }
      </div>
    </>
  );
};
const mapStateToProps = (store) => {
  return {
    deviceStats: store.devices.deviceStats,
  };
};
export default connect(mapStateToProps, {
  fetchDevicePerformanceStats,
  deleteDeviceStats,
})(DeviceStatsTable);
