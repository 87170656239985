import React, { useEffect } from "react";
// components
import CardClinicProfile from "../../components/Cards/CardClinicProfile";
import CardClinicMiddle from "../../components/Cards/CardClinicMiddle";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateClinicProile,
  clinicPictureUpdate,
  cleareResourse,
  fetchActiveCareSiteAdmin,
} from "../../redux/invitation/components/invitation.action";
import CareSiteDetailsCards from "../../components/CareSiteDetailsCards";
import { getCareSiteDetailsById } from "../../redux/careSite/caresite.action";

const ClinicProfile = ({
  getCareSiteDetailsById,
  careSiteDetails,
  fetchActiveCareSiteAdmin,
}) => {
  let location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  var clinicId = queryParams.get("c_id");

  useEffect(() => {
    if (clinicId) {
      getCareSiteDetailsById(clinicId);
      fetchActiveCareSiteAdmin(clinicId);
    } else {
      console("Missing params in for fetching caresite");
    }
  }, []);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 ">
          <CardClinicProfile clinicItemData={careSiteDetails} />
          <div style={{ margin: "40px" }}>
            {careSiteDetails && careSiteDetails.c_id ? (
              <CareSiteDetailsCards
                clinicName={
                  careSiteDetails &&
                  careSiteDetails.clinicname &&
                  careSiteDetails.clinicname
                }
                city={
                  careSiteDetails &&
                  careSiteDetails.address &&
                  careSiteDetails.address.city
                }
                state={
                  careSiteDetails &&
                  careSiteDetails.address &&
                  careSiteDetails.address.state
                }
                country={
                  careSiteDetails &&
                  careSiteDetails.address &&
                  careSiteDetails.address.country
                }
                address={
                  careSiteDetails &&
                  careSiteDetails.address &&
                  careSiteDetails.address.addressline1
                }
                postal={
                  careSiteDetails &&
                  careSiteDetails.address &&
                  careSiteDetails.address.postal
                }
                phonenumber={careSiteDetails && careSiteDetails.phonenumber}
                website={careSiteDetails && careSiteDetails.website}
                picture={careSiteDetails && careSiteDetails.clinic_puuid}
                link="Care site details"
              />
            ) : null}
          </div>
        </div>

        <div
          className="w-full lg:w-4/12 px-4"
          style={{
            boxShadow: "rgb(178 183 193) -1px -4px 4px 0px",
            marginTop: "-47px",
            zIndex: 1,
          }}
        >
          <CardClinicMiddle
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    clinicItem: store.invitation.clinicProfileUpdate,
    invitationList: store.invitation.invitationList,
    clinicProfileUpdateResponse: store.invitation.clinicProfileUpdateResponse,
    ClinicProfileUpdate: store.invitation.clinicProfileUpdate,
    totalCountofCLinicAdmin: store.invitation.totalCountofCLinicAdmin,
    activeCareSiteAdmin: store.invitation.activeCareSiteAdmin,
    careSiteDetails: store.caresite.caresitedetails,
  };
};

export default connect(mapStateToProps, {
  updateClinicProile,
  clinicPictureUpdate,
  cleareResourse,
  getCareSiteDetailsById,
  fetchActiveCareSiteAdmin,
})(ClinicProfile);
