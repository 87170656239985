import React from "react";
import { connect } from "react-redux";

import CardUsers from "../../../components/Cards/InvitationTable";

import "./invitation.css";

import { invitationlink } from "../../../redux/user/user.action";

const Invitation = (props) => {

  return (
    <div className="container">
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12">
          <CardUsers />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    hospitals: store.user.hospitalList.hospitals,
  };
};

export default connect(mapStateToProps, { invitationlink })(Invitation);
