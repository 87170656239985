import React, { useEffect } from "react";
import { Redirect, useRouteMatch, useHistory } from "react-router-dom";
import { GetAllowedRoutes, isLoggedIn, isLoggedInUser } from "../utils";
import { PrivateRoutesConfig } from "../config";
import { connect } from "react-redux";
import { logIn } from "../redux/user/user.action";
// import { TopNav } from 'components/common';
import MapAllowedRoutes from "../routes/MapAllowedRoutes";
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import FooterAdmin from "../components/Footers/FooterAdmin.js";
import SessionExpiryDialog from "../components/SessionExpiryDialog"
import { Suspense } from "react";
import LoadingIcon from "../components/LoadingIcon";
function PrivateRoutes(props) {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const match = useRouteMatch("/app");
  let allowedRoutes = [];

  useEffect(() => {
    if (props.isSessionExpired) {
      setOpen(true)
    }
  }, [props.isSessionExpired])

  if (isLoggedInUser()) {
    const user = JSON.parse(isLoggedInUser());

    props.logIn(user);
  }
  if (isLoggedIn()) allowedRoutes = GetAllowedRoutes(PrivateRoutesConfig);

  else return <Redirect to="/" />;

  return (
    <>
      <SessionExpiryDialog open={open} />
      {history.location.pathname === "/app/signup" ? (
        <MapAllowedRoutes
          routes={allowedRoutes}
          prefix={match.path}
          basePath="/app"
          isAddNotFound
        />
      ) : (
        <>
          <AdminNavbar />
          <div className="relative md:ml-64 bg-blueGray-100">
            <Sidebar />
            <div className=" relative md : relative mx-auto w-full md:pt-32">
              <Suspense
                fallback={
                  <div
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      textAlign: "center",
                    }}
                  >
                    <LoadingIcon />
                  </div>
                }
              >
                <MapAllowedRoutes
                  routes={allowedRoutes}
                  prefix={match.path}
                  basePath="/app"
                  isAddNotFound
                />

                <FooterAdmin />
              </Suspense>
            </div>
          </div>
        </>
      )}
    </>
  );
}
const mapStateToProps = (store) => {
  return {
    list: store.user.list,
    login: store.user.credentials,
    userLogin: store.user.credentials.usercredentials,
    isSessionExpired: store.user.isSessionExpired
  };
};
export default connect(mapStateToProps, { logIn })(PrivateRoutes);
