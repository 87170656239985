//This component is created by dhanush on 13-01-2022

import React from 'react'
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import userIcon from "../../assets/icons/userProfileIcon.svg";
import myIcon from '../../assets/icons/attachmentIcon.png'
import './styles.css';
import "../../../src/assets/styles/font.css";
import moment from "moment";
import { useHistory } from "react-router-dom";




const ClinicAdminTabelSingleComponent = (props) => {
  const history = useHistory();
  const [orderDirection, setOrderDirection] = React.useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = React.useState("lastname");


  const handleRequestSort = (event, property) => {
    const isAscending = (valueToOrderBy === property && orderDirection === "asc");
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
    props.getSelectedTabFunction({
      clinicId: props.clinicId,
      range1: props.fromDate,
      range2: props.toDate,
      norange: props.isSelected === "Missed 3 or more days"?'day':props.selectRange,
      search: props.val,
      page: props.page,
      property,
      order:isAscending ? "desc" : "asc"})
  };

  const createSortHandler = (property) => (event) => {

    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {


    if (b[orderBy] < a[orderBy]) {

      return -1;
    }
    if (b[orderBy] > a[orderBy]) {

      return 1;
    }

    return 0;
  }

  function getComparator(order, orderBy) {

    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }


  const sortedRowInformation = (rowArray, comparator) => {

    const stabilizedRowArray = rowArray.map((el, index) =>
      [el, index]
    );

    stabilizedRowArray.sort((a, b) => {

      const order = comparator(a[0], b[0]);

      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRowArray.map((el) => el[0]);
  };

  const navToPatientProfile = (item, clinicid) => {
    // console.log("PROPS dashboard_data:", props.dashboard_data)
    // return;
    props.clear_patient_profile();
    let query = Buffer.from(JSON.stringify(
      {
        profile_id: item && item.profile_id ? item.profile_id : null,
        clinic_id: clinicid ? clinicid : null,
        pair_id: item && item.pair_id ? item.pair_id : null,
      })).toString('base64');
    if (query) {

      history.push(`/app/PatientProfile?p=${query}`);
    }

  }

  return (
    <div >
      {props.isSelected === "Transmission Without Deviations" && props.dashboard_data && props.dashboard_data.length ?
        <div>
          <table className='mainTable'>
            <tbody>
              <tr className='tableRow'>
                <th className='tabelHeadingImg'>
                  <div style={{ padding: '10px' }}>

                  </div>

                </th>
                <th className='tabelHeading' key="last_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "last_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("last_name")}
                  >

                    <div className='tableHeadingTop fnt24P' >
                      Last Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading' key="first_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "first_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("first_name")}
                  >
                    <div className='tableHeadingTop fnt24P' >
                      First Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading' key="total_transmission">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "total_transmission" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("total_transmission")}
                  >
                    <div className='tableHeadingTop fnt24P' >
                      Total transmission
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading' key="deviations">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy == "deviations" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("deviations")}
                  >
                    <div className='tableHeadingTop fnt24P' >
                      Deviation
                    </div>
                  </TableSortLabel>
                </th>
              </tr>

              {props.dashboard_data && props.dashboard_data.length > 0 ? (
                props.dashboard_data.map((item, index) => {
                  // console.log("Transmission Without Deviations:", item)
                  return (
                    <tr className='tabelDescAndRowAfterMapping'
                      key={index}
                      onClick={() => navToPatientProfile(item, props.clinicId)}

                    >
                      <td className='tabelDescAndRowAfterMapping'>
                        <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                          <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                          </Avatar>
                        </div>
                      </td>

                      <td className='tabelHeading'>
                        <div className='tableDesc fnt22P'>

                          {item.last_name ? item.last_name : '-'}
                        </div>
                      </td>

                      <td className='tabelHeading'>
                        <div className='tableDesc fnt22P'>

                          {item.first_name ? item.first_name : '-'}
                        </div>
                      </td>

                      <td className='tabelHeading'>
                        <div className='tableDesc fnt22P'>

                          {item.total_transmission ? item.total_transmission : '-'}
                        </div>
                      </td>

                      <td className='tabelHeading'>
                        <div className='tableDesc fnt22P'>

                          {/* {item.deviations} */}
                          {item.deviations == true ? (
                            <Chip
                              label="yes"
                              style={{
                                boxShadow: "#6A768F80 0px 3px 6px",
                                backgroundColor: "#f2f8f2",
                                color: "#4d547a",
                              }}
                            />
                          ) : (
                            <Chip
                              label="no"
                              style={{
                                boxShadow: "#6A768F80 0px 3px 6px",
                                backgroundColor: "#f2f8f2",
                                color: "red",
                              }}
                            />
                          )}

                        </div>
                      </td>

                    </tr>
                  );

                })
              ) : <tr>
                <td colSpan="6" className='nodatafoundWrapper fnt22P' >No data found</td>
              </tr>}



            </tbody>

          </table>
        </div>
        : null}

      {props.isSelected === "Transmission With Deviations" && props.dashboard_data && props.dashboard_data.length ?
        <div >
          <table className='mainTable' >
            <tbody>
              <tr className='tableRow'>
                <th className='tabelHeadingImg'>
                  <div style={{ padding: '10px' }}>

                  </div>
                </th>
                <th className='tabelHeading1' key="last_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "last_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("last_name")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Last Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading1' key="firstname">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "firstname" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("firstname")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      First Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading1' key="total_transmission">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "total_transmission" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("total_transmission")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Total transmission
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading1' key="deviations">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "deviations" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("deviations")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Deviation
                    </div>
                  </TableSortLabel>
                </th>

                <th className='tabelHeading1' key="deviationpercentage">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "deviationpercentage" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("deviationpercentage")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Deviation Percentage
                    </div>
                  </TableSortLabel>
                </th>
              </tr>



              {props.dashboard_data && props.dashboard_data.length > 0 ? (
                props.dashboard_data.map((item, index) => {
                  // console.log("Transmission With Deviations:", item);
                  return (
                    <tr className='tabelDescAndRowAfterMapping'
                      key={index}
                      onClick={() => navToPatientProfile(item, props.clinicId)}
                    >
                      <td className='tabelDescAndRowAfterMapping'>
                        <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                          <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                          </Avatar>
                        </div>
                      </td>

                      <td className='tabelHeading1'>
                        <div className='tableDesc fnt22P'>

                          {item.last_name ? item.last_name : '-'}
                        </div>
                      </td>

                      <td className='tabelHeading1'>
                        <div className='tableDesc fnt22P'>

                          {item.first_name ? item.first_name : '-'}
                        </div>
                      </td>

                      <td className='tabelHeading1'>
                        <div className='tableDesc fnt22P'>

                          {item.total_transmission ? item.total_transmission : '-'}
                        </div>
                      </td>

                      <td className='tabelHeading1'>
                        <div className='tableDesc fnt22P'>

                          {/* {item.deviations} */}
                          {item.deviations == true ? (
                            <Chip
                              label="yes"
                              style={{
                                boxShadow: "#6A768F80 0px 3px 6px",
                                backgroundColor: "#f2f8f2",
                                color: "#4d547a",
                              }}
                            />
                          ) : (
                            <Chip
                              label="no"
                              style={{
                                boxShadow: "#6A768F80 0px 3px 6px",
                                backgroundColor: "#f2f8f2",
                                color: "red",
                              }}
                            />
                          )}
                        </div>
                      </td>

                      <td className='tabelHeading1'>
                        <div className='tableDesc fnt22P'>

                          {item.devation_percentage ? item.devation_percentage : '-'}
                        </div>
                      </td>

                    </tr>
                  );

                })
              ) : <tr>
                <td colSpan="6" className='nodatafoundWrapper fnt22P' >No data found</td>
              </tr>}
            </tbody>
          </table>
        </div>
        : null}

      {props.isSelected === "Total Transmission" && props.dashboard_data && props.dashboard_data.length ?
        <div >
          <table className='mainTable'>
            <tbody>
              <tr className='tableRow'>
                <th className='tabelHeadingImg'>
                  <div style={{ padding: '10px' }}>

                  </div>

                </th>
                <th className='tabelHeading2' key="last_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "last_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("last_name")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Last Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading2' key="first_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "first_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("first_name")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      First Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading2' key="total_transmission">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "total_transmission" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("total_transmission")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Total transmission
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading2' key="deviations">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "deviations" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("deviations")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Deviation
                    </div>
                  </TableSortLabel>
                </th>

                <th className='tabelHeading2' key="devation_percentage">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "devation_percentage" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("devation_percentage")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Deviation Percentage
                    </div>
                  </TableSortLabel>
                </th>
              </tr>



              {props.dashboard_data && props.dashboard_data.length > 0 ? (
                props.dashboard_data.map((item, i) => {
                  // console.log("Total Transmission:", item)

                  return (
                    <tr className='tabelDescAndRowAfterMapping'
                      key={i}
                      onClick={() => navToPatientProfile(item, props.clinicId)}
                    >
                      <td className='tabelDescAndRowAfterMapping' >
                        <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                          <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                          </Avatar>
                        </div>
                      </td>

                      <td className='tabelHeading2'>
                        <div className='tableDesc fnt22P'>

                          {item.last_name ? item.last_name : '-'}
                        </div>
                      </td>

                      <td className='tabelHeading2'>
                        <div className='tableDesc fnt22P'>

                          {item.first_name ? item.first_name : '-'}
                        </div>
                      </td>

                      <td className='tabelHeading2'>
                        <div className='tableDesc fnt22P'>

                          {item.total_transmission ? item.total_transmission : '-'}
                        </div>
                      </td>

                      <td className='tabelHeading2'>
                        <div className='tableDesc fnt22P'>


                          {item.deviations == true ? (
                            <Chip
                              label="yes"
                              style={{
                                boxShadow: "#6A768F80 0px 3px 6px",
                                backgroundColor: "#f2f8f2",
                                color: "#4d547a",
                              }}
                            />
                          ) : (
                            <Chip
                              label="no"
                              style={{
                                boxShadow: "#6A768F80 0px 3px 6px",
                                backgroundColor: "#f2f8f2",
                                color: "red",
                              }}
                            />
                          )}
                        </div>
                      </td>

                      <td className='tabelHeading1'>
                        <div className='tableDesc fnt22P'>

                          {item.devation_percentage == 0 ? 0 : item.devation_percentage ? item.devation_percentage : '-'}
                        </div>
                      </td>

                    </tr>
                  );

                })
              ) : <tr>
                <td colSpan="6" className='nodatafoundWrapper fnt22P'>No data found</td>
              </tr>}
            </tbody>
          </table>
        </div>
        : null}

      {props.isSelected === "Patients who have never transmitted" && props.dashboard_data && props.dashboard_data.length ?
        <div >
          <table className='mainTable'>
            <tbody>
              <tr className='tableRow'>
                <th className='tabelHeadingImg'>
                  <div style={{ padding: '10px' }}>

                  </div>

                </th>
                <th className='tabelHeading3' key="last_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "last_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("last_name")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Last Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading3' key="first_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "first_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("first_name")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      First Name
                    </div>
                  </TableSortLabel>
                </th>
              </tr>

              {props.dashboard_data && props.dashboard_data.length > 0 ? (
                props.dashboard_data.map((item, index) => {
                  // console.log("Patients who was never transmitted:", item, props.clinicId)
                  return (
                    <tr className='tabelDescAndRowAfterMapping' key={index}
                      onClick={() => navToPatientProfile(item, props.clinicId)}>
                      <td className='tabelDescAndRowAfterMapping'>
                        <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                          <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                          </Avatar>
                        </div>
                      </td>

                      <td className='tabelHeading3'>
                        <div className='tableDesc fnt22P'>

                          {item.last_name ? item.last_name : '-'}
                        </div>
                      </td>

                      <td className='tabelHeading3'>
                        <div className='tableDesc fnt22P'>

                          {item.first_name ? item.first_name : '-'}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : <tr>
                <td colSpan="6" className='nodatafoundWrapper fnt22P'>No data found</td>
              </tr>}
            </tbody>
          </table>
        </div>
        : null}

      {props.isSelected === "Adherence below 75%" && props.dashboard_data && props.dashboard_data.length ?
        <div >
          <table className='mainTable'>
            <tbody>
              <tr className='tableRow'>
                <th className='tabelHeadingImg'>
                  <div className='tableHeadingTop fnt24P'>

                  </div>

                </th>
                <th className='tabelHeading4' key="last_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "last_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("last_name")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Last Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading4' key="first_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "first_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("first_name")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      First Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading4' key="ab75">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "ab75" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("ab75")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Adherance score below 75%
                    </div>
                  </TableSortLabel>
                </th>

              </tr>



              {props.dashboard_data && props.dashboard_data.length > 0 ? (
                props.dashboard_data.map((item, index) => {
                  // console.log("Adherence below 75%:", item)
                  return (
                    <tr className='tabelDescAndRowAfterMapping' key={index}
                      onClick={() => navToPatientProfile(item, props.clinicId)}
                    >
                      <td className='tabelDescAndRowAfterMapping'>
                        <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                          <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                          </Avatar>
                        </div>
                      </td>

                      <td className='tabelHeading4'>

                        <div className='tableDesc fnt22P'>

                          {item.last_name ? item.last_name : '-'}
                        </div>

                      </td>

                      <td className='tabelHeading4'>

                        <div className='tableDesc fnt22P'>

                          {item.first_name ? item.first_name : '-'}
                        </div>

                      </td>

                      <td className='tabelHeading4'>

                        <div className='tableDesc fnt22P'>
                          {item.ads_score ? `${(item.ads_score * 100).toFixed(0)}` : '-'}

                        </div>

                      </td>

                    </tr>
                  );

                })
              ) : <tr>
                <td colSpan="6" className='nodatafoundWrapper fnt22P'>No data found</td>
              </tr>}
            </tbody>
          </table>
        </div>
        : null}

      {props.isSelected === "Adherence below 50%" && props.dashboard_data && props.dashboard_data.length ?
        <div >
          <table className='mainTable'>
            <tbody>
              <tr className='tableRow'>
                <th className='tabelHeadingImg'>
                  <div style={{ padding: '10px' }}>

                  </div>

                </th>
                <th className='tabelHeading4' key="last_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "last_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("last_name")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Last Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading4' key="first_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "first_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("first_name")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      First Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading4' key="ab50">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "ab50" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("ab50")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Adherance score below 50%
                    </div>
                  </TableSortLabel>
                </th>

              </tr>



              {props.dashboard_data && props.dashboard_data.length > 0 ? (
                props.dashboard_data.map((item, index) => {
                  // console.log("Adherence below 50%:", item)

                  return (
                    <tr className='tabelDescAndRowAfterMapping' key={index}
                      onClick={() => navToPatientProfile(item, props.clinicId)}
                    >
                      <td className='tabelDescAndRowAfterMapping'>
                        <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                          <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                          </Avatar>
                        </div>
                      </td>

                      <td className='tabelHeading4'>
                        <div className='tableDesc fnt22P'>

                          {item.last_name ? item.last_name : '-'}
                        </div>
                      </td>

                      <td className='tabelHeading4'>
                        <div className='tableDesc fnt22P'>

                          {item.first_name ? item.first_name : '-'}
                        </div>
                      </td>

                      <td className='tabelHeading4'>
                        <div className='tableDesc fnt22P'>

                          {item.ads_score ? `${(item.ads_score * 100).toFixed(0)}` : '-'}
                        </div>
                      </td>

                    </tr>
                  );

                })
              ) : <tr>
                <td colSpan="6" className='nodatafoundWrapper fnt22P'>No data found</td>
              </tr>}
            </tbody>
          </table>
        </div>
        : null}

      {props.isSelected === "Adherence below 25%" && props.dashboard_data && props.dashboard_data.length ?
        <div >
          <table className='mainTable'>
            <tbody>
              <tr className='tableRow' >
                <th className='tabelHeadingImg'>
                  <div className='tableHeadingTop fnt24P'>

                  </div>

                </th>
                <th className='tabelHeading4' key="last_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "last_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("last_name")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Last Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading4' key="first_name">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "first_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("first_name")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      First Name
                    </div>
                  </TableSortLabel>
                </th>
                <th className='tabelHeading4' key="ab25">
                  <TableSortLabel
                    active={true}
                    direction={
                      valueToOrderBy === "ab25" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("ab25")}
                  >
                    <div className='tableHeadingTop fnt24P'>
                      Adherance score below 25%
                    </div>
                  </TableSortLabel>
                </th>

              </tr>



              {props.dashboard_data && props.dashboard_data.length > 0 ? (
                props.dashboard_data.map((item, index) => {
                  // console.log("Adherence below 25%:", item)
                  return (
                    <tr className='tabelDescAndRowAfterMapping' key={index}
                      onClick={() => navToPatientProfile(item, props.clinicId)}
                    >
                      <td className='tabelDescAndRowAfterMapping'>
                        <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                          <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                          </Avatar>
                        </div>
                      </td>

                      <td className='tabelHeading4'>

                        <div className='tableDesc fnt22P'>

                          {item.last_name ? item.last_name : '-'}
                        </div>

                      </td>

                      <td className='tabelHeading4'>


                        <div className='tableDesc fnt22P'>

                          {item.first_name ? item.first_name : '-'}
                        </div>

                      </td>

                      <td className='tabelHeading4'>

                        <div className='tableDesc fnt22P'>

                          {item.ads_score ? `${(item.ads_score * 100).toFixed(0)}` : '-'}
                        </div>

                      </td>

                    </tr>
                  );

                })
              ) : <tr>
                <td colSpan="6" className='nodatafoundWrapper fnt22P'>No data found</td>
              </tr>}
            </tbody>
          </table>
        </div>
        : null}

      {props.isSelected === "Update Therapy Goals" && props.dashboard_data && props.dashboard_data.length ?
        <div >
          <table className='mainTable'>
            <tr className='tableRow' >
              <th className='tabelHeadingImg'>
                <div className='tableHeadingTop fnt24P'>

                </div>

              </th>
              <th className='tabelHeadin5' key="lastname">
                <TableSortLabel
                  active={valueToOrderBy === "last_name"}
                  direction={
                    valueToOrderBy === "last_name" ? orderDirection : "asc"
                  }
                  onClick={createSortHandler("last_name")}
                >
                  <div className='tableHeadingTop fnt24P'>
                    Last Name
                  </div>
                </TableSortLabel>
              </th>
              <th className='tabelHeadin5' key="first_name">
                <TableSortLabel
                  active={valueToOrderBy === "first_name"}
                  direction={
                    valueToOrderBy === "first_name" ? orderDirection : "asc"
                  }
                  onClick={createSortHandler("first_name")}
                >
                  <div className='tableHeadingTop fnt24P'>
                    First Name
                  </div>
                </TableSortLabel>
              </th>
              <th className='tabelHeadin5' key="updatedgoals">
                <TableSortLabel
                  active={valueToOrderBy === "updatedgoals"}
                  direction={
                    valueToOrderBy === "updatedgoals" ? orderDirection : "asc"
                  }
                  onClick={createSortHandler("updatedgoals")}
                >
                  <div className='tableHeadingTop fnt24P'>
                    Goals Updated on
                  </div>
                </TableSortLabel>
              </th>

            </tr>



            {props.dashboard_data && props.dashboard_data.length > 0 ? (
              props.dashboard_data.map((item, index) => {
                // console.log("Update Therapy Goals:", item)

                return (
                  <tr className='tabelDescAndRowAfterMapping'
                    onClick={() => navToPatientProfile(item, props.clinicId)}
                  >
                    <td className='tabelDescAndRowAfterMapping'>
                      <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                        <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                        </Avatar>
                      </div>
                    </td>

                    <td className='tabelHeadin5'>
                      <div className='tableDesc fnt22P'>

                        {item.last_name ? item.last_name : '-'}
                      </div>
                    </td>

                    <td className='tabelHeadin5'>
                      <div className='tableDesc fnt22P'>

                        {item.first_name ? item.first_name : '-'}
                      </div>
                    </td>

                    <td className='tabelHeadin5'>
                      <div className='tableDesc fnt22P'>

                        {item.created_at ?
                          moment(item.created_at).format("DD-MMM-YYYY")
                          : "-"}
                      </div>
                    </td>
                  </tr>
                );

              })
            ) : <tr>
              <td></td>
              <td></td>
              <td className='nodatafoundWrapper fnt22P'>No data found</td>
              <td></td>
            </tr>}

          </table>
        </div>
        : null}

      {props.isSelected === "Missed 3 or more days" && props.dashboard_data && props.dashboard_data.length ?
        <div >
          <table className='mainTable'>
            <tr className='tableRow' >
              <th className='tabelHeadingImg'>
                <div className='tableHeadingTop fnt24P'>
                </div>
              </th>
              <th className='tabelHeadin5' key="lastname">
                <TableSortLabel
                  active={valueToOrderBy === "last_name"}
                  direction={
                    valueToOrderBy === "last_name" ? orderDirection : "asc"
                  }
                  onClick={createSortHandler("last_name")}
                >
                  <div className='tableHeadingTop fnt24P'>
                    Last Name
                  </div>
                </TableSortLabel>
              </th>
              <th className='tabelHeadin5' key="first_name">
                <TableSortLabel
                  active={valueToOrderBy === "first_name"}
                  direction={
                    valueToOrderBy === "first_name" ? orderDirection : "asc"
                  }
                  onClick={createSortHandler("first_name")}
                >
                  <div className='tableHeadingTop fnt24P'>
                    First Name
                  </div>
                </TableSortLabel>
              </th>
            </tr>


            {props.dashboard_data && props.dashboard_data.length > 0 ? (
              sortedRowInformation(
                props.dashboard_data,
                getComparator(orderDirection, valueToOrderBy)
              ).map((item, index) => {
                // console.log("Update Therapy Goals:", item)
                return (
                  <tr className='tabelDescAndRowAfterMapping'
                    onClick={() => navToPatientProfile(item, props.clinicId)}
                  >
                    <td className='tabelDescAndRowAfterMapping'>
                      <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                        <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                        </Avatar>
                      </div>
                    </td>

                    <td className='tabelHeadin5'>
                      <div className='tableDesc fnt22P'>

                        {item.last_name ? item.last_name : '-'}
                      </div>
                    </td>

                    <td className='tabelHeadin5'>
                      <div className='tableDesc fnt22P'>

                        {item.first_name ? item.first_name : '-'}
                      </div>
                    </td>

                    {/* <td className='tabelHeadin5'>
                      <div className='tableDesc fnt22P'>

                        {item.created_at ?
                          moment(item.created_at).format("DD-MMM-YYYY")
                          : "-"}
                      </div>
                    </td> */}
                  </tr>
                );

              })
            ) : <tr>
              <td></td>
              <td></td>
              <td className='nodatafoundWrapper fnt22P'>No data found</td>
              <td></td>
            </tr>}

          </table>
        </div>
        : null}
    </div>
  )
}

export default ClinicAdminTabelSingleComponent;