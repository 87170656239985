import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Avatar from "@mui/material/Avatar";
import {
  navReportSearchToPatientProfile,
  makeReportSearchNull,
  pageHeading,
} from "../../redux/user/user.action";
import { loadDevice } from "../../redux/therapyGoals/therapyGoals.action";
import {
  getPatientStatus,
  getPatientIdRedFunc,
} from "../../redux/invitation/components/invitation.action";
import { connect, useDispatch, useSelector } from "react-redux";
import userIcon from "../../assets/icons/userProfileIcon.svg";
import { Link } from "react-router-dom";

const ReportDataTabel = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [orderDirection, setOrderDirection] = React.useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = React.useState("username");
  // const [paramQuery, setParamQuery] = React.useState(0);
  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const sortedRowInformation = (rowData, comparator) => {
    const stabilizedRowArray = rowData.map((el, index) => [el, index]);
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRowArray.map((el) => el[0]);
  };

  const navPatientProfile = (item, index) => {

    let query = Buffer.from(JSON.stringify(
      {
        profile_id: item && item.profile_id ? item.profile_id : null,
        clinic_id: item && item.clinic_id ? item.clinic_id : null,
        pair_id: item && item.pair_id ? item.pair_id : null,
        // first_name :item && item.first_name ?  item.first_name : null,
        // last_name : item && item.last_name ? item.last_name : null,
        // patient_id : item && item.patient_id ?  item.patient_id : null,
        // sub_patient_id : item && item.patient_id && item.patient_id.patient_id ? item.patient_id.patient_id : null,
        // photo_uuid : item &&  item.photo_uuid ? item.photo_uuid : null,
        // pair_id : item && item.pair_id ? item.pair_id : null
      })).toString('base64');
    dispatch(navReportSearchToPatientProfile(item));
    dispatch(loadDevice(item.pair_id));//api req
    props.pageHeading("Patient Profile");
    dispatch(makeReportSearchNull());
    dispatch(getPatientStatus(item.profile_id));//API REQ
    dispatch(getPatientIdRedFunc(item.profile_id));
    history.push(`/app/PatientProfile?p=${query}`);
  };

  return (
    <>
      <div id="table-scroll-report-data-table">
        <TableContainer style={{ maxHeight: "270px" }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            key="usertable"
            stickyHeader
          >
            <TableHead>
              <TableRow style={{ background: "#f6f9fc" }}>
                <TableCell></TableCell>
                <TableCell
                  key="username"
                  style={{ width: "15%", color: "#6a768f" }}
                >
                  User image
                </TableCell>
                <TableCell style={{ width: "20%", color: "#6a768f" }}>
                  <TableSortLabel
                    // style={{ color: "#8898aa" }}
                    active={valueToOrderBy === "last_name"}
                    direction={
                      valueToOrderBy === "last_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("last_name")}
                  >
                    Last name
                  </TableSortLabel>
                </TableCell>
                <TableCell key="first_name" style={{ width: "20%" }}>
                  <TableSortLabel
                    style={{ color: "#6a768f" }}
                    active={valueToOrderBy === "first_name"}
                    direction={
                      valueToOrderBy === "first_name" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("first_name")}
                  >
                    First name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  key="clinicname"
                  style={{ width: "45%", color: "#6a768f" }}
                >
                  <TableSortLabel
                    // style={{ color: "#8898aa" }}
                    active={valueToOrderBy === "clinicname"}
                    direction={
                      valueToOrderBy === "clinicname" ? orderDirection : "asc"
                    }
                    onClick={createSortHandler("clinicname")}
                  >
                    Care Site name
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody key="userTableMap">
              {props.reportdata && props.reportdata.length > 0 ? (
                sortedRowInformation(
                  props.reportdata,
                  getComparator(orderDirection, valueToOrderBy)
                ).map((item, index) => {
                  return (
                    <>
                      <TableRow
                        hover
                        key={index}
                        className="reportmapdiv"
                        onClick={() => {
                          navPatientProfile(item);
                        }}
                        style={{ height: "10%" }}
                      >
                        <TableCell></TableCell>
                        <TableCell
                          key="uniqueId2"
                          className="report-image-data"
                        >
                          <div>
                            {item.photo_uuid ? (
                              <img
                                src={`https://ucarecdn.com/${item.photo_uuid}/`}
                                className="avatar-image-report"
                              />
                            ) : (
                              <img
                                src={userIcon}
                                className="avatar-image-report"
                              />
                            )}
                          </div>
                        </TableCell>
                        <TableCell key="uniqueId4">
                          {item.last_name ? item.last_name : "-"}
                        </TableCell>
                        <TableCell key="uniqueId3">
                          {item.first_name ? item.first_name : "-"}
                        </TableCell>
                        <TableCell key="uniqueId5">
                          {item.clinicname ? item.clinicname : "-"}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan="6"
                    style={{
                      textAlign: "center",
                      fontSize: "19px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                  >
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {};
};

export default connect(mapStateToProps, {
  pageHeading,
  loadDevice,
})(ReportDataTabel);
