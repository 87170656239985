import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "./abmAdminDashboard.css";
import Avatar from "@material-ui/core/Avatar";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import myIcon from "../../../assets/icons/attachmentIcon.png";
import SeparatorTopSection from "../../../components/SeparatorTopSection";
import { pageHeading, searchpatientforsuperadmin,clear_patient_profile,navReportSearchToPatientProfile} from "../../../redux/user/user.action";
import CloseIcon from "@material-ui/icons/Close";
import { getCareSiteDetailsById } from "../../../redux/careSite/caresite.action";
//----validations----
import InputField from "../../../components/Inputs_Validation";
import MuiAlert from "@material-ui/lab/Alert";
import CustomsSnackbar from "../../../components/Alertmessage/Snackbar";
import Snackbar from "@material-ui/core/Snackbar";
import "../../../views/auth/styless.css";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import apiAbmBlaze,{CHECK_IF_DEVICE_IS_CONNECTED} from "../../../api";
import { alphabetsOnlyWithSpace } from "../../../utils";
// components
import { connect,useDispatch } from "react-redux";
import { loadProfile } from "../../../redux/user/user.action";
import Box from '@mui/material/Box';
import LoadingIcon from "../../../components/LoadingIcon";
import {
  // fetchclinic,
  fetchInvitation,
  addClinicList,
  openInvitePopup,
  fetchClinicProfile,
  openFilterPopup,
  checkaccountnumber,
  fetchActiveCareSiteAdmin,
  fetchActiveClinicSite,
  fetchEnrolledListCount,
  superadminDashboardPagination,
  againappearpopup,
  loadOtherUserProfileForViewing
} from "../../../redux/invitation/components/invitation.action.js";

//SORTING
import TableSortLabel from "@material-ui/core/TableSortLabel";

//dropdown
import { useTheme } from "@material-ui/core/styles";
import DashboardCards from "../../../components/Cards/dashboardCards";
import DashboardsubCards from "../../../components/Cards/dashboardsubCards";
// invite popup
import { InviteModalPopup } from "../../../components/ModalPopup";
//select country
import Select from "react-select";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../../assets/styles/font.css";
import Pagination from "../../../components/Pagination";
const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  patientlistpaper: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    textAlign: "center",
  },

  paperwrapper: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    height: "225px",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const AbmadminDashboard = ({
  fetchInvitation,
  addClinicList,
  addingClinicSuccessAlert,
  addingClinicexistingAlert,
  existingclinic,
  addingclinicerrormsg,
  user,
  flagAccountnumber,
  checkingAccountnumber,
  checkaccountnumber,
  loadProfile,
  fetchActiveClinicSite,
  fetchEnrolledListCount,
  superadminDashboardCountList,
  superadminDashboardPagination,
  superadminCareSiteList,
  pageHeading,
  searchpatientforsuperadmin,
  searchedpatients,
  againappearpopup,
  loadOtherUserProfileForViewing,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  //patient table
  const [isPatientSelected, setPatientSelected] = React.useState('clinician');
  //SORT
  const [orderDirection, setOrderDirection] = React.useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = React.useState("");

  //popup modal
  const [showModal, setShowModal] = React.useState(false);
  //Alert message
  const [sucsess, setSucsess] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });

  //dropdown
  const theme = useTheme();
  const [showInviteModal, setShowInviteModal] = React.useState(false);
  //invite popup
  const [isopen, setOpen] = React.useState(false);

  const handleSnackbar = () => {
    setOpenSnack(false);
  };
  const scrollRef = React.useRef(null);
  const [snackErrorMessage, setSnackErrorMessage] = React.useState(
    "Something went wrong.Try again later"
  );

  const [openErrorSnack, setOpenErrorSnack] = React.useState(false);

  const handleErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  const [customeErrorSnackbarAnchor, setCustomeErrorSnackbarAnchor] = useState({
    vertical: "top",

    horizontal: "center",
  });

  const [snackErrorSeverity, setSnackErrorSeverity] = React.useState("");
  const [caresitetableSearchVal, setCaresitetableSearchVal] =
    React.useState("");
  const [page, setPage] = React.useState(1);

  var valCaresiteData = new Object();
  valCaresiteData.search = caresitetableSearchVal;
  valCaresiteData.page = page;

  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  },[])
  useEffect(() => {
    loadProfile();
    pageHeading("Dashboard");
    superadminDashboardPagination(valCaresiteData);
  }, []);

  useEffect(() => {
    fetchInvitation(user.role);
    fetchEnrolledListCount(user.role);
    // fetchclinic();
  }, []);

  useEffect(() => {
    fetchActiveClinicSite();
  }, []);

  const navToPatientProfile = async (item) => {
    clear_patient_profile();
   
    let query = Buffer.from(JSON.stringify(
      {
        profile_id: item && item.item.profile_id ? item.item.profile_id : null,
        clinic_id: item.item.clinic_id ? item.item.clinic_id : null,
        pair_id: item&&item.item2 && item.item2.pair_id ? item.item2.pair_id : null,
      })).toString('base64');
    if (query) {
      dispatch(navReportSearchToPatientProfile(item));
      history.push(`/app/PatientProfile?p=${query}`);
    

  }
}
  

  const checkifconnectedtodevice = async (val)  => {
    if(val.role !== 'patient'){
      if(val.status === 'accepted'){
       loadOtherUserProfileForViewing(val.profile_id)
      history.push(`/app/userinfo/${val.profile_id}`);
      }
      else if (val.status === 'pending'){
        setSnackMsg(`${val.email} has not accepted the invitaion`)
        setSnackSeverity("warning")
        setOpenSnack(true)
      }
      else if (val.status === 'rejected'){
        setSnackMsg(`${val.email} has rejected the invitation`)
        setSnackSeverity("warning")
        setOpenSnack(true)
      }
    }
    else if (val.status === 'pending'){
      setSnackMsg(`${val.email} has not accepted the invitation`)
      setSnackSeverity("warning")
      setOpenSnack(true)
    }
    else if (val.status === 'rejected'){
      setSnackMsg(`${val.email} has rejected the invitation`)
      setSnackSeverity("warning")
      setOpenSnack(true)
    }
    else{
    await apiAbmBlaze
      .post(CHECK_IF_DEVICE_IS_CONNECTED, {
        profile_id:val.profile_id
      })
      .then(async (res) => {
        if (res.data) {
          navToPatientProfile({item:val,item2:res.data.rows[0]})
        }
      })
      .catch((error) => {
        console.log("ERROR checkifconnectedtodevice", error);
      });
  };
}
  //-------------SORTING THE TABLE DATAS----------
  const handleRequestSort = (event, property) => {
    if (isPatientSelected=='caresite'){
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
    superadminDashboardPagination({ ...valCaresiteData, page, property, order: isAscending ? "desc" : "asc" });
  }else{
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
    searchpatientforsuperadmin({ roletype:isPatientSelected, page, property, order: isAscending ? "desc" : "asc" });
  }};
  

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    if (orderBy === "clinicname") {

      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    } else {
      if ("address" in a && "address" in b) {
        if (b.address[orderBy] < a.address[orderBy]) {
          return -1;
        }
        if (b.address[orderBy] > a.address[orderBy]) {
          return 1;
        }
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRowArray.map((el) => el[0]);
  };

  const showPopUpHandler = (clinicians) => {
    setShowModal(true);
  };

  const callBack = () => {
    setSucsess(false);
    setShowModal(false);
  };

  const submit = (val) => {
    setTimeout(() => {
      setSucsess(false);
    }, 5000);
    setSucsess(true);

    addClinicList(val);
    setShowModal(false);
  };


  //---------------SENDING INVITATION LINK TO CHILD AND GETTING THE VALUE FROM CHILD----------

  useEffect(() => {
    if (addingClinicSuccessAlert) {
      setOpenSnack(true);
      setSnackMsg("Clinic added successfully");
      setSnackSeverity("success");
    } else if (addingclinicerrormsg) {
      setOpenSnack(true);
      setSnackMsg("Clinic was not added. Please check the highlighted field and try again.");
      setSnackSeverity("error");
    }
    else if(addingClinicexistingAlert){
      setOpenSnack(true);
      setSnackMsg(`Clinic was not added.${existingclinic.clinicname} already exists.`);
      setSnackSeverity("error");
      }
    return () => {
      againappearpopup();
    };
  }, [addingClinicSuccessAlert, addingclinicerrormsg,addingClinicexistingAlert]);
  //invite modal popup
  const invitePopupforcaresite = (caresites) => {
    setOpen(true);
  };
  const callback = () => {
    setShowInviteModal(false);
    setOpen(false);
  };

  //search for care site table
  const handleSearchforCaresiteTable = (val1) => {
    if(isPatientSelected=='caresite'){
    superadminDashboardPagination({ ...valCaresiteData, page: 1 });
    setPage(1);
  }
  else{
    
    searchpatientforsuperadmin({  page: 1, roletype: isPatientSelected , search_val:val1})
  }};
  const  handlecardclick = async (role) =>  {
    
    setPatientSelected(role)
    scrollRef.current.scrollIntoView({ behavior: "smooth" })
   
  };
  useEffect(() => {
    if(isPatientSelected!=='caresite'){
      // scrollRef.current.scrollIntoView({ behavior: "smooth" })
    searchpatientforsuperadmin({  page: 1, roletype: isPatientSelected })
    
  }}, [isPatientSelected])
  

  // const handleEnterCaresiteTable = (event) => {
  //   if (event.key === "Enter") {
  //     superadminDashboardPagination({ ...valCaresiteData, page: 1 });
  //     setPage(1);
  //   }
  // };

  const changingPage = (event, value) => {
    if(isPatientSelected!=='caresite'){
      setPage(value);
      searchpatientforsuperadmin({  page: value, roletype: isPatientSelected, property:valueToOrderBy, order: orderDirection  })

    }
    else{
    setPage(value);
    superadminDashboardPagination({ ...valCaresiteData, page: value });
  }};

  const navigateCareSite = (id) => {
    history.push(`/app/clinicprofiledetails?c_id=${id}`);
  };

  const headCells = [
    {
      id: 'address.city',
    }]
  return (
    <React.Fragment>
      <div style={{ width: "100%" }}>
        <Popup
          showModal={showModal}
          callBack={callBack}
          clinicLink={submit}
          flagAccountnumber={flagAccountnumber}
          checkingAccountnumber={checkingAccountnumber}
          checkaccountnumber={checkaccountnumber}
          addingClinicSuccessAlert={addingClinicSuccessAlert}
          addingclinicerrormsg={addingclinicerrormsg}
          addingClinicexistingAlert={addingClinicexistingAlert}
          setOpenSnack={setOpenSnack}
          setSnackMsg={setSnackMsg}
          setSnackSeverity={setSnackSeverity}
        />

        <InviteModalPopup
          isopen={isopen}
          callBack={callback}
          invitationlink={submit}
        />
        <div className="careSiteHeading fnt32P">
          <div
            style={{
              color: "#3D446F",
              fontFamily: "Roboto",
              fontWeight: "600",
              marginTop: "5px",
            }}
          >
            Enrolled Care Sites
          </div>
          <div
            className="fnt32P"
            style={{
              color: "#F26930",
              fontFamily: "Roboto",
              fontWeight: "600",
              marginLeft: "15px",
              paddingTop: "4px",
            }}
          >
            {superadminDashboardCountList.active_caresites}
          </div>
        </div>

        {/* ====cards=====   */}
        <div
          className="flex flex-wrap"
          style={{ marginLeft: "64px", marginRight: "20px", marginTop: "40px" }}
        >
          <div className="w-full lg:w-4/12">
            <DashboardCards
              role="Patients"
              img="img1"
              handlecardclick={handlecardclick}
              cardDescription="View all active Care sites"
              cardcolor="#F2F8F2"
            ></DashboardCards>
          </div>

          <div className="w-full lg:w-4/12">
            <DashboardCards
              role="clinicians"
              img="img2"
              cardDescription="Add Care Site"
              showPopUpHandler={showPopUpHandler}
              cardcolor="#F3FCFE"
            ></DashboardCards>
          </div>

          <div className="w-full lg:w-4/12">
            <DashboardCards
              role="care sites"
              img="img3"
              cardDescription="Invite Care Site Admin"
              cardcolor="#FEF4F0"
              invitePopupforcaresite={invitePopupforcaresite}
            ></DashboardCards>
          </div>
        </div>

        {/* =====sub cards==== */}
        <div
          className="flex flex-wrap"
          style={{ marginLeft: "64px", marginRight: "4px" ,justifyContent:"space-between"}}
        >
          <div className="w-full lg:w-4/12">
            <DashboardsubCards
              role="Patients"
              rolecount={superadminDashboardCountList.active_patients}
              handlecardclick={handlecardclick}
            ></DashboardsubCards>{" "}
          </div>

          <div className="w-full lg:w-4/12">
            <DashboardsubCards
              role="Clinicians"
              rolecount={superadminDashboardCountList.active_clinicians}
              handlecardclick={handlecardclick}
            ></DashboardsubCards>{" "}
          </div>

          <div className="w-full lg:w-4/12">
            <DashboardsubCards 
              role="Care Site Admins"
              rolecount={superadminDashboardCountList.active_clinicadmins}
              handlecardclick={handlecardclick}
            ></DashboardsubCards>{" "}
          </div>
        </div>
        <div ref={scrollRef} style={{height:'50px'}}></div>
        <SeparatorTopSection />

        <div
          className={classes.root}
          style={{ overflow: "hidden", backgroundColor: "white" }}
        >
          {isPatientSelected=='caresite'?
          <div className="PatientslistHeadingWrapper fnt32P">
            Active Care Site list
          </div>:isPatientSelected=='patient'?<div className="PatientslistHeadingWrapper fnt32P">
            Enrolled Patients
          </div>:isPatientSelected=='clinician'?<div className="PatientslistHeadingWrapper fnt32P">
            Enrolled Clinicians
          </div>:isPatientSelected=='clinicadmin'?<div className="PatientslistHeadingWrapper fnt32P">
            Enrolled Care Site Admins
          </div>:null}
          <Grid item xs={12}>
            <AlertSnackBar
              msg={snackMsg}
              flag={openSnack}
              onClose={handleSnackbar}
              key={"cusSnackbar"}
              anchorOrigin={customeSnackbarAnchor}
              severity={snackSeverity}
            />
            <CustomsSnackbar
              msg={snackErrorMessage}
              flag={openErrorSnack}
              onClose={handleErrorSnackbar}
              anchorOrigin={customeErrorSnackbarAnchor}
              severity={snackErrorSeverity}
            />
            {/* search button */}
            <div className="searchbarWrapperSuperadmin">
              <div
                className="add-icon"
                style={{ backgroundColor: "#F2F7F8", marginLeft: "28px" }}
              >
                <i
                  className="fas fa-search fa-lg"
                  style={{ paddingTop: "12px" }}
                ></i>
                {isPatientSelected=='caresite'?
                <input
                  id="searchBox"
                  type="text"
                  placeholder="Name, City, State, Zip"
                  className="report_search"
                  // onKeyDown={(event) => handleEnterCaresiteTable(event)}
                  onChange={(event) => {
                    handleSearchforCaresiteTable(event.target.value);
                    setCaresitetableSearchVal(event.target.value)
                  }}
                  style={{ backgroundColor: "#F2F7F8" }}
                // value={caresitetableSearchVal}
                />:
                <input
                  id="searchBox"
                  type="text"
                  placeholder="First Name, Last Name, Email, Status"
                  className="report_search"
                  // onKeyDown={(event) => handleEnterCaresiteTable(event)}
                  onChange={(event) => {
                    handleSearchforCaresiteTable(event.target.value);
                    setCaresitetableSearchVal(event.target.value)
                  }}
                  style={{ backgroundColor: "#F2F7F8" }}
                // value={caresitetableSearchVal}
                />}
              </div>
            </div>

            {/* clinic table */}
             {isPatientSelected==='patient'?
             <div
             className="block w-full overflow-x-auto"
             style={{ marginBottom: "82px" }}
           >
             <div
               style={{
                 paddingLeft: "72px",
                 backgroundColor: "white",
                 paddingTop: "5px",
               }}
             >
               <table className="mainTableAbmDashboard">
                 <tbody>
                   <tr className="tableRow">
                     <th className="tabelHeadingImgAbmDashboard">
                       <div style={{ padding: "10px" }}></div>
                     </th>
                     <th className="tabelHeadingAbmDashboard" key="clinicname">
                       <TableSortLabel
                         active={true}
                         direction={
                           valueToOrderBy === "last_name"
                             ? orderDirection
                             : "asc"
                         }
                         onClick={createSortHandler("last_name")}
                       >
                         <div className="tableHeadingTop fnt32P">
                           Last Name
                         </div>
                       </TableSortLabel>
                     </th>

                     <th className="tabelHeadingAbmDashboard">
                       <TableSortLabel
                         active={true}
                         direction={
                           valueToOrderBy === "first_name"
                             ? orderDirection
                             : "asc"
                         }
                         onClick={createSortHandler("first_name")}
                       >
                         <div className="tableHeadingTop fnt32P">First Name</div>
                       </TableSortLabel>
                     </th>

                     <th className="tabelHeadingAbmDashboard" >
                       <TableSortLabel
                         active={true}
                         direction={
                           valueToOrderBy === "status"
                             ? orderDirection
                             : "asc"
                         }
                         onClick={createSortHandler("status")}
                       >
                         <div className="tableHeadingTop fnt32P">Status</div>
                       </TableSortLabel>
                     </th>
                     <td className="tabelHeadingAbmDashboard">
                       <TableSortLabel
                         active={true}
                         direction={
                           valueToOrderBy == "email"
                             ? orderDirection
                             : "asc"
                         }
                         onClick={createSortHandler("email")}
                       >
                         <div className="tableHeadingTop fnt32P">Email</div>
                       </TableSortLabel>
                     </td>
                   </tr>

                   {searchedpatients &&
                     searchedpatients.length > 0 ? (
                     sortedRowInformation(
                      searchedpatients,
                       getComparator(orderDirection, valueToOrderBy)
                     ).map((item, index) => {

                       return (

                         <tr
                           className="tabelDescAndRowAfterMapping"
                           key={index}
                           onClick={() => checkifconnectedtodevice(item)}
                         >
                           <td
                             className="tabelDescAndRowAfterMapping"
                           >
                             <div
                               style={{
                                 padding: "8px",
                                 display: "flex",
                                 flexDirection: "row",
                                 justifyContent: "center",
                               }}
                             >
                               {!item.photo_uuid ? (
                                 <Avatar
                                   className="abmdashboardImageListWrapper"
                                   src="../../../assets/img/hospital-logo.jpg"
                                 />
                               ) : (
                                 <Avatar
                                   src={`https://ucarecdn.com/${item.photo_uuid}/`}
                                   alt="hospital"
                                 />
                               )}
                             </div>
                           </td>

                           <td className="tabelHeadingAbmDashboard">
                             <div
                               className="fnt24P"
                               style={{
                                 textAlign: "center",
                                 fontFamily: "Roboto",
                                 padding: "10px",
                               }}
                             >
                               {item.last_name?item.last_name:'-'}
                             </div>
                           </td>

                           <td className="tabelHeadingAbmDashboard">
                             <div
                               className="fnt24P"
                               style={{
                                 textAlign: "center",
                                 fontFamily: "Roboto",
                                 padding: "10px",
                               }}
                             >
                               {item.first_name?item.first_name:'-'}
                             </div>
                           </td>

                           <td className="tabelHeadingAbmDashboard">
                             <div
                               className="fnt24P"
                               style={{
                                 textAlign: "center",
                                 fontFamily: "Roboto",
                                 padding: "10px",
                               }}
                             >
                               {item.status==='pending'?'Pending':'accepted'?'Accepted':null}
                             </div>
                           </td>

                           <td className="tabelHeadingAbmDashboard">
                             <div
                               className="fnt24P"
                               style={{
                                 textAlign: "center",
                                 fontFamily: "Roboto",
                                 padding: "10px",
                               }}
                             >
                               {item.email}
                             </div>
                           </td>
                         </tr>
                         // </Link>
                       );
                     })
                   ) : (
                     <tr>
                       <td
                         colSpan="6"
                         className="nodatafoundWrapperSuperadmin fnt22P"
                       >
                         No data found
                       </td>
                     </tr>
                   )}
                 </tbody>
               </table>
             </div>

             {searchedpatients && searchedpatients.length > 0 ? (
               <div className="pagination">
                 <div>
                   <Pagination
                     count={Math.ceil(
                       searchedpatients[0].total_count / 10
                     )}
                     page={page}
                     onChange={changingPage}
                   />
                 </div>
               </div>
             ) : null}
           </div>: isPatientSelected==='clinician'?
           <div
              className="block w-full overflow-x-auto"
              style={{ marginBottom: "82px" }}
            >
              <div
                style={{
                  paddingLeft: "72px",
                  backgroundColor: "white",
                  paddingTop: "5px",
                }}
              >
                <table className="mainTableAbmDashboard">
                  <tbody>
                    <tr className="tableRow">
                      <th className="tabelHeadingImgAbmDashboard">
                        <div style={{ padding: "10px" }}></div>
                      </th>
                      <th className="tabelHeadingAbmDashboard">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "last_name"
                              ? orderDirection
                              : "asc"
                          }
                          onClick={createSortHandler("last_name")}
                        >
                          <div className="tableHeadingTop fnt32P">Last Name</div>
                        </TableSortLabel>
                      </th>
                      <th className="tabelHeadingAbmDashboard" key="clinicname">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "first_name"
                              ? orderDirection
                              : "asc"
                          }
                          onClick={createSortHandler("first_name")}
                        >
                          <div className="tableHeadingTop fnt32P">
                            First Name
                          </div>
                        </TableSortLabel>
                      </th>

                      <th className="tabelHeadingAbmDashboard" >
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "status"
                              ? orderDirection
                              : "asc"
                          }
                          onClick={createSortHandler("status")}
                        >
                          <div className="tableHeadingTop fnt32P">Status</div>
                        </TableSortLabel>
                      </th>
                      <td className="tabelHeadingAbmDashboard">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy == "email"
                              ? orderDirection
                              : "asc"
                          }
                          onClick={createSortHandler("email")}
                        >
                          <div className="tableHeadingTop fnt32P">Email</div>
                        </TableSortLabel>
                      </td>
                    </tr>

                    {searchedpatients &&
                      searchedpatients.length > 0 ? (
                      sortedRowInformation(
                        searchedpatients,
                        getComparator(orderDirection, valueToOrderBy)
                      ).map((item, index) => {

                        return (

                          <tr
                            className="tabelDescAndRowAfterMapping"
                            key={index}
                            onClick={() => checkifconnectedtodevice(item)}
                          >
                            <td
                              className="tabelDescAndRowAfterMapping"
                            >
                              <div
                                style={{
                                  padding: "8px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                {!item.photo_uuid ? (
                                  <Avatar
                                    className="abmdashboardImageListWrapper"
                                    src="../../../assets/img/hospital-logo.jpg"
                                  />
                                ) : (
                                  <Avatar
                                    src={`https://ucarecdn.com/${item.photo_uuid}/`}
                                    alt="hospital"
                                  />
                                )}
                              </div>
                            </td>

                            <td className="tabelHeadingAbmDashboard">
                              <div
                                className="fnt24P"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Roboto",
                                  padding: "10px",
                                }}
                              >
                                {item.last_name?item.last_name:'-'}
                              </div>
                            </td>

                            <td className="tabelHeadingAbmDashboard">
                              <div
                                className="fnt24P"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Roboto",
                                  padding: "10px",
                                }}
                              >
                                {item.first_name?item.first_name:'-'}
                              </div>
                            </td>

                            <td className="tabelHeadingAbmDashboard">
                              <div
                                className="fnt24P"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Roboto",
                                  padding: "10px",
                                }}
                              >
                                {item.status==='pending'?'Pending':'accepted'?'Accepted':null}
                              </div>
                            </td>

                            <td className="tabelHeadingAbmDashboard">
                              <div
                                className="fnt24P"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Roboto",
                                  padding: "10px",
                                }}
                              >
                                {item.email}
                              </div>
                            </td>
                          </tr>
                          // </Link>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          className="nodatafoundWrapperSuperadmin fnt22P"
                        >
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {searchedpatients && searchedpatients.length > 0 ? (
                <div className="pagination">
                  <div>
                    <Pagination
                      count={Math.ceil(
                        searchedpatients[0].total_count / 10
                      )}
                      page={page}
                      onChange={changingPage}
                    />
                  </div>
                </div>
              ) : null}
            </div>:isPatientSelected==='clinicadmin'?
            <div
              className="block w-full overflow-x-auto"
              style={{ marginBottom: "82px" }}
            >
              <div
                style={{
                  paddingLeft: "72px",
                  backgroundColor: "white",
                  paddingTop: "5px",
                }}
              >
                <table className="mainTableAbmDashboard">
                  <tbody>
                    <tr className="tableRow">
                      <th className="tabelHeadingImgAbmDashboard">
                        <div style={{ padding: "10px" }}></div>
                      </th>
                      <th className="tabelHeadingAbmDashboard">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "last_name"
                              ? orderDirection
                              : "asc"
                          }
                          onClick={createSortHandler("last_name")}
                        >
                          <div className="tableHeadingTop fnt32P">Last Name</div>
                        </TableSortLabel>
                      </th>
                      <th className="tabelHeadingAbmDashboard" key="clinicname">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "first_name"
                              ? orderDirection
                              : "asc"
                          }
                          onClick={createSortHandler("first_name")}
                        >
                          <div className="tableHeadingTop fnt32P">
                            First Name
                          </div>
                        </TableSortLabel>
                      </th>

                      <th className="tabelHeadingAbmDashboard" >
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "status"
                              ? orderDirection
                              : "asc"
                          }
                          onClick={createSortHandler("status")}
                        >
                          <div className="tableHeadingTop fnt32P">Status</div>
                        </TableSortLabel>
                      </th>
                      <td className="tabelHeadingAbmDashboard">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy == "email"
                              ? orderDirection
                              : "asc"
                          }
                          onClick={createSortHandler("email")}
                        >
                          <div className="tableHeadingTop fnt32P">Email</div>
                        </TableSortLabel>
                      </td>
                    </tr>

                    {searchedpatients &&
                      searchedpatients.length > 0 ? (
                      sortedRowInformation(
                        searchedpatients,
                        getComparator(orderDirection, valueToOrderBy)
                      ).map((item, index) => {

                        return (

                          <tr
                            className="tabelDescAndRowAfterMapping"
                            key={index}
                            onClick={() => checkifconnectedtodevice(item)}
                          >
                            <td
                              className="tabelDescAndRowAfterMapping"
                            >
                              <div
                                style={{
                                  padding: "8px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                {!item.photo_uuid ? (
                                  <Avatar
                                    className="abmdashboardImageListWrapper"
                                    src="../../../assets/img/hospital-logo.jpg"
                                  />
                                ) : (
                                  <Avatar
                                    src={`https://ucarecdn.com/${item.photo_uuid}/`}
                                    alt="hospital"
                                  />
                                )}
                              </div>
                            </td>

                            <td className="tabelHeadingAbmDashboard">
                              <div
                                className="fnt24P"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Roboto",
                                  padding: "10px",
                                }}
                              >
                               {item.last_name?item.last_name:'-'}
                              </div>
                            </td>

                            <td className="tabelHeadingAbmDashboard">
                              <div
                                className="fnt24P"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Roboto",
                                  padding: "10px",
                                }}
                              >
                                {item.first_name?item.first_name:'-'}
                              </div>
                            </td>

                            <td className="tabelHeadingAbmDashboard">
                              <div
                                className="fnt24P"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Roboto",
                                  padding: "10px",
                                }}
                              >
                                {item.status==='pending'?'Pending':'accepted'?'Accepted':null}
                              </div>
                            </td>

                            <td className="tabelHeadingAbmDashboard">
                              <div
                                className="fnt24P"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Roboto",
                                  padding: "10px",
                                }}
                              >
                                {item.email}
                              </div>
                            </td>
                          </tr>
                          // </Link>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          className="nodatafoundWrapperSuperadmin fnt22P"
                        >
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {searchedpatients && searchedpatients.length > 0 ? (
                <div className="pagination">
                  <div>
                    <Pagination
                      count={Math.ceil(
                        searchedpatients[0].total_count / 10
                      )}
                      page={page}
                      onChange={changingPage}
                    />
                  </div>
                </div>
              ) : null}
            </div>:
            <div
              className="block w-full overflow-x-auto"
              style={{ marginBottom: "82px" }}
            >
              <div
                style={{
                  paddingLeft: "72px",
                  backgroundColor: "white",
                  paddingTop: "5px",
                }}
              >
                <table className="mainTableAbmDashboard">
                  <tbody>
                    <tr className="tableRow">
                      <th className="tabelHeadingImgAbmDashboard">
                        <div style={{ padding: "10px" }}></div>
                      </th>
                      <th className="tabelHeadingAbmDashboard" key="clinicname">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "clinicname"
                              ? orderDirection
                              : "asc"
                          }
                          onClick={createSortHandler("clinicname")}
                        >
                          <div className="tableHeadingTop fnt32P">
                            Site name
                          </div>
                        </TableSortLabel>
                      </th>

                      <th className="tabelHeadingAbmDashboard">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "city"
                              ? orderDirection
                              : "asc"
                          }
                          onClick={createSortHandler("city")}
                        >
                          <div className="tableHeadingTop fnt32P">City</div>
                        </TableSortLabel>
                      </th>

                      <th className="tabelHeadingAbmDashboard" >
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "state"
                              ? orderDirection
                              : "asc"
                          }
                          onClick={createSortHandler("state")}
                        >
                          <div className="tableHeadingTop fnt32P">State</div>
                        </TableSortLabel>
                      </th>
                      <td className="tabelHeadingAbmDashboard">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy == "postal"
                              ? orderDirection
                              : "asc"
                          }
                          onClick={createSortHandler("postal")}
                        >
                          <div className="tableHeadingTop fnt32P">Zip</div>
                        </TableSortLabel>
                      </td>
                    </tr>

                    {superadminCareSiteList &&
                      superadminCareSiteList.length > 0 ? (
                        superadminCareSiteList.map((item, index) => {

                        return (

                          <tr
                            className="tabelDescAndRowAfterMapping"
                            key={index}
                            onClick={() => navigateCareSite(item.c_id)}
                          >
                            <td
                              className="tabelDescAndRowAfterMapping"
                            >
                              <div
                                style={{
                                  padding: "8px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                {!item.clinic_puuid ? (
                                  <Avatar
                                    className="abmdashboardImageListWrapper"
                                    src="../../../assets/img/hospital-logo.jpg"
                                  />
                                ) : (
                                  <Avatar
                                    src={`https://ucarecdn.com/${item.clinic_puuid}/`}
                                    alt="hospital"
                                  />
                                )}
                              </div>
                            </td>

                            <td className="tabelHeadingAbmDashboard">
                              <div
                                className="fnt24P"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Roboto",
                                  padding: "10px",
                                }}
                              >
                                {item.clinicname}
                              </div>
                            </td>

                            <td className="tabelHeadingAbmDashboard">
                              <div
                                className="fnt24P"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Roboto",
                                  padding: "10px",
                                }}
                              >
                                {item.address && item.address.city
                                  ? item.address.city
                                  : ""}
                              </div>
                            </td>

                            <td className="tabelHeadingAbmDashboard">
                              <div
                                className="fnt24P"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Roboto",
                                  padding: "10px",
                                }}
                              >
                                {item.address && item.address.state
                                  ? item.address.state
                                  : ""}
                              </div>
                            </td>

                            <td className="tabelHeadingAbmDashboard">
                              <div
                                className="fnt24P"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Roboto",
                                  padding: "10px",
                                }}
                              >
                                {item.address && item.address.postal
                                  ? item.address.postal
                                  : ""}
                              </div>
                            </td>
                          </tr>
                          // </Link>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          className="nodatafoundWrapperSuperadmin fnt22P"
                        >
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {superadminCareSiteList && superadminCareSiteList.length > 0 ? (
                <div className="pagination">
                  <div>
                    <Pagination
                      count={Math.ceil(
                        superadminCareSiteList[0].total_count / 10
                      )}
                      page={page}
                      onChange={changingPage}
                    />
                  </div>
                </div>
              ) : null}
            </div>}
          </Grid>
        </div>

        <table id="table"></table>
      </div>
    </React.Fragment>
  );
};

AbmadminDashboard.defaultProps = {
  color: "light",
};

AbmadminDashboard.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
const mapStateToProps = (store) => {
  return {
    allClinicList: store.invitation.allcliniclist,
    invitationList: store.invitation.invitationList,
    user: store.user.user,
    patientList: store.invitation.patientList,
    adminTable: store.user.adminTable,
    addUserInvitation: store.invitation.addUserInvitation,
    flagInput: store.invitation.flagInput,
    addingClinicSuccessAlert: store.invitation.addingClinicSuccessAlert,
    addingClinicexistingAlert: store.invitation.addingClinicexistingAlert,
    existingclinic: store.invitation.existingclinic,
    addingclinicerrormsg: store.invitation.addingclinicerrormsg,
    addingClinicAlert: store.invitation.addingClinicAlert,
    checkingAccountnumber: store.invitation.checkingAccountnumber,
    flagAccountnumber: store.invitation.flagAccountnumber,
    superadminDashboardCountList: store.invitation.superadminDashboardCountList,
    searchedpatients:store.user.searchedpatients,
    superadminCareSiteList: store.invitation.superadminCareSiteList,
    userRole: store.user.user,
  };
};

export default connect(mapStateToProps, {
  // fetchclinic,
  fetchInvitation,
  addClinicList,
  openInvitePopup,
  fetchClinicProfile,
  openFilterPopup,
  loadProfile,
  checkaccountnumber,
  fetchActiveClinicSite,
  fetchEnrolledListCount,
  superadminDashboardPagination,
  pageHeading,
  searchpatientforsuperadmin,
  againappearpopup,
  loadOtherUserProfileForViewing,
  fetchActiveCareSiteAdmin,
  getCareSiteDetailsById,
})(AbmadminDashboard);

const Popup = (props) => {
  const inputRefs = React.useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const newClinicObj = {};
  const classes = useStyles();
  const [values, setValues] = React.useState({});
  // const [countryValue, setCountryValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [accountnumberError, setAccountnumberError] = React.useState("");
  const [state, setState] = React.useState({});
  const [stateValue, setStateValue] = React.useState("");
  const [contries, setCountry] = React.useState("United States");
  const [contriesValue1, setCountryValue1] = React.useState("United States");
  const [saveClickButtonLoad, setSaveClickButtonLoad] = React.useState(false);
  const [stateError, setStateError] = React.useState({val:false,msg:'ok'})
  const [countryError, setCountryError] = React.useState({val:false,msg:'ok'})
  const onAddClinicHandler = (val) => {
    newClinicObj.key = Math.random().toString();
    newClinicObj.accountnumber = values.accountnumber;
    newClinicObj.clinicname = values.clinicname;
    newClinicObj.phonenumber = phoneValue;
    newClinicObj.addressline1 = values.addressline1;
    newClinicObj.addressline2 = values.addressline2;
    newClinicObj.city = values.city;
    newClinicObj.state = values.state?values.state:stateValue;
    newClinicObj.country = contriesValue1;
    newClinicObj.postal = values.postal;
    newClinicObj.email = values.email;
    newClinicObj.website = values.website;

    props.clinicLink(newClinicObj);
  };

  const handleInputChange = (name, value) => {
    if (value.length > 0) {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    // console.log("props.addingClinicAlert", props.addingClinicSuccessAlert,props.addingClinicexistingAlert,props.addingclinicerrormsg);
    if (props.addingClinicSuccessAlert === "success"||props.addingClinicexistingAlert==="success"||props.addingclinicerrormsg) {
      setValues({})
      setStateValue("")
      return setSaveClickButtonLoad(false);
    }
  }, [saveClickButtonLoad, props.addingClinicSuccessAlert,props.addingClinicexistingAlert,props.addingclinicerrormsg])

  useEffect(() => {
    if (props.flagAccountnumber) {
      setAccountnumberError("accountnumber alraedy exists");
    } else {
      setAccountnumberError("");
    }

  }, [props.flagAccountnumber]);
  useEffect(() => {

    stateData();
    countryData();
  }, [])
  function stateData() {
    apiAbmBlaze.get("/js/state.json").then((response) => {
      setState(response.data.state);
    })
  }
  function countryData() {
    apiAbmBlaze.get('js/country.json').then((response) => {
      setCountry(response.data.country)
    }).catch((err) => {
      console.log("err from country", err)
    })
  }
  const checkValueExists = (value) => {
    console.log('values in check',value)
    return state.some((obj) => obj.name === value);
  };
  const onSubmit = async () => {
    let isValid = true;
    let incorrectstate = false;
    for (let i = 0; i < inputRefs.current.length; i++) {
      console.log(countryError.val,stateError.val)
     if(countryError.val===true ||stateError.val===true){isValid=false;console.log('Vaidating country_state')}
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate();

      if (!valid) {
        isValid = false;
      }
    }
    if (props.addingClinicAlert === "create") {
      isValid = false;
    } else {
      if (isValid === true) {
        const isValueExists = checkValueExists(!(contriesValue1 ==='United States')?values.state:stateValue);
        if (contriesValue1 ==='United States' && !isValueExists ){incorrectstate=true;}
        
        // console.log("values+++", contriesValue1, stateValue)
        if(!incorrectstate){
        addClinicList({
          ...values,         // Spread values first
          country: contriesValue1,
          state: values.state !== undefined ? values.state : stateValue,
        });
        
        onAddClinicHandler(values);
        setSaveClickButtonLoad(true);
      }
      else {
            props.setSnackSeverity("error")
            props.setSnackMsg('Please select an appropriate state from the dropdown')
            props.setOpenSnack(true)
      }
    }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg("Please fix the inputs with errors");
        props.setSnackSeverity("error");
      }
    }
  };
  return (
    <>
      <div className="formk">
        <Dialog open={props.showModal} maxWidth="md" className="dialogwrapper">
          {" "}
          <DialogTitle
            id="alert-dialog-title"
            className="dialogHeadingwrapper"
          >
            {" "}
            {"Add Care Site"}
            <CloseIcon
              onClick={props.callBack}
              className="close-addcaresite"
              style={{cursor:'pointer'}}
            />
          </DialogTitle>
          <DialogContent
            style={{
              background: "#F2F4F8",
            }}
          >
            <form>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0  formBackground">
                <div className=" mt-3">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <div className="abmaccount" >
                        <div style={{ display: "flex" }}>
                          <label
                            className="block text-xs font-bold mb-2  careprofileLabel"
                            htmlFor="grid-password"
                          >
                            Account Number

                          </label>
                          <p
                            style={{
                              color: 'gray',
                              fontSize: '18px',
                              textAlign: 'left',
                              paddingTop: '3px',
                              marginLeft: '2px',

                            }}
                          >
                            {/* * */}
                          </p>
                        </div>
                        <InputField
                          type="text"
                          className="inputfeildsforallProfile"
                          ref={inputRefs.current[0]}
                          handleInputChange={handleInputChange}
                          validation="|max:20|min:4"
                          name="accountnumber"
                        />
                        {accountnumberError}
                      </div>
                    </div>
                  </div>

                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <div className="abmaccount1">
                        <div style={{ display: "flex" }}>
                          <label
                            className="block careprofileLabel text-blueGray-600 text-xs font-bold mb-2 mt-5"
                            htmlFor="grid-password"
                          >
                            Care Site Name
                          </label>
                          <p
                            style={{
                              color: 'gray',
                              fontSize: '18px',
                              textAlign: 'left',
                              paddingTop: '22px',
                              marginLeft: '2px',

                            }}
                          >
                            *
                          </p>
                        </div>
                        <InputField
                          type="text"
                          className="inputfeildsforallProfile"
                          ref={inputRefs.current[1]}
                          handleInputChange={handleInputChange}
                          validation="required|min:1|max:50|alphabetsOnly"
                          placeholder=""
                          name="clinicname"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap mt-3">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <div className="abmaccount">
                        <div style={{ display: "flex" }}>
                          <label
                            className="block text-blueGray-600 careprofileLabel text-xs font-bold mb-2 mt-5"
                            htmlFor="grid-password"
                          >
                            Address line 1
                          </label>
                          <p
                            style={{
                              color: 'gray',
                              fontSize: '18px',
                              textAlign: 'left',
                              paddingTop: '20px',
                              marginLeft: '2px',

                            }}
                          >
                            {/* * */}
                          </p>
                        </div>
                        <InputField
                          type="text"
                          className="inputfeildsforallcare1"
                          ref={inputRefs.current[2]}
                          handleInputChange={handleInputChange}
                          // validation="required"
                          placeholder=""
                          name="addressline1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <div className="abmaccount">
                        <div style={{ display: "flex" }}>
                          <label
                            className="block text-blueGray-600 careprofileLabel2 text-xs font-bold mb-2 mt-5"
                            htmlFor="grid-password"
                          >
                            Address line 2
                          </label>
                          <p
                            style={{
                              color: 'gray',
                              fontSize: '18px',
                              textAlign: 'left',
                              paddingTop: '20px',
                              marginLeft: '2px',

                            }}
                          >
                            {/* * */}
                          </p>
                        </div>
                        <InputField
                          type="text"
                          className="inputfeildsforallcare2"
                          ref={inputRefs.current[3]}
                          handleInputChange={handleInputChange}
                          // validation="required"
                          placeholder=""
                          name="addressline2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap mt-3">

                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <div className="abmaccount">
                        <div style={{ display: "flex" }}>
                          <label
                            className="block careprofileLabel2 text-blueGray-600 text-xs font-bold mb-2 mt-5"
                            htmlFor="grid-password"
                          >
                            Country
                          </label>
                          <p
                            style={{
                              color: 'gray',
                              fontSize: '18px',
                              textAlign: 'left',
                              paddingTop: '22px',
                              marginLeft: '2px',

                            }}
                          >
                            *
                          </p>
                        </div>
                        {/* <Select
                          options={options}
                          value={countryValue}
                          validation="required"
                          className="inputfeildsforallcarecountry"
                          name="country"
                          onChange={setCountryValue}
                          inputStyle={{
                            background: "#f2f4f8",
                          }}
                        /> */}
                        <Autocomplete
                          options={contries}
                          clearIcon={null}
                          popupIcon={null}

                          getOptionLabel={(option) => option.country ||
                            contriesValue1



                          }
                          defaultValue={"United States"}
                          onChange={(e, value) => {

                            setCountryValue1(value && value.country)
                            if(!value){
                              setCountryError({val:true,msg:'Required'});
                              return;
                            }
                            else{
                              setCountryError({val:false,msg:'ok'})
                            }
                          }}
                          autoHighlight
                          id="disable-close-on-select"
                          country
                          renderInput={(params) => (

                            <TextField {...params}
                              onChange={(e) => { }}

                              variant="standard" />
                          )}

                        /><p style={{
                          color: 'red',
                          fontSize: '12px',
                          textAlign: 'left',
                          paddingTop: '12px',
                          marginLeft: '2px',
                      }}>{countryError.val?countryError.msg:''}</p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <div className="abmaccount">
                        <div style={{ display: "flex" }}>
                          <label
                            className="block careprofileLabel2 text-blueGray-600 text-xs font-bold mb-2 mt-5"
                            htmlFor="grid-password"
                          >
                            State
                          </label>
                          <p
                            style={{
                              color: 'gray',
                              fontSize: '18px',
                              textAlign: 'left',
                              paddingTop: '22px',
                              marginLeft: '2px',

                            }}
                          >
                            *
                          </p>
                        </div>
                        {contriesValue1 === "United States" ?
                          <div>
                          <Autocomplete
                            options={state}
                            clearIcon={null}
                            popupIcon={null}
                            getOptionLabel={(option) => option.name || stateValue
                            }
                            onChange={(e, value) => {

                              setStateValue(value && value.name)
                              if(!value){
                                console.log('error raised')
                                setStateError({val:true,msg:'Required'});
                                return;
                              }
                              else{
                                setStateError({val:false,msg:'ok'})
                              }
                              let flag = alphabetsOnlyWithSpace(value.name);
                              if(flag){
                                flag = value.name.length>35?false:true
                                setStateError(flag?{val:false,msg:'ok'}:{val:true,msg:'This input must be no longer than 35 characters long'});
                              }
                              else{
                                setStateError({val:true,msg:'should contain only alphabets and/or space'});
                              }
                            }}
                            autoHighlight
                            id="disable-close-on-select"

                            renderInput={(params) => (

                              <TextField {...params} variant="standard" />
                            )}

                          /> <p style={{
                            color: 'red',
                            fontSize: '12px',
                            textAlign: 'left',
                            paddingTop: '12px',
                            marginLeft: '2px',
                        }}>{stateError.val?stateError.msg:''}</p>
                        </div>
                          :
                          <InputField
                            type="text"
                            className="inputfeildsforallcare2"
                            ref={inputRefs.current[5]}
                            handleInputChange={handleInputChange}
                            validation="required|alphabetsOnly|max:35"
                            placeholder=""
                            name="state"
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap mt-3">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <div className="abmaccount">
                        <div style={{ display: "flex" }}>
                          <label
                            className="block careprofileLabel text-blueGray-600 text-xs font-bold mb-2 mt-5"
                            htmlFor="grid-password"
                          >
                            City
                          </label>
                          <p
                            style={{
                              color: 'gray',
                              fontSize: '18px',
                              textAlign: 'left',
                              paddingTop: '22px',
                              marginLeft: '2px',

                            }}
                          >
                            *
                          </p>
                        </div>
                        <InputField
                          type="text"
                          className="inputfeildsforallcare1"
                          ref={inputRefs.current[4]}
                          handleInputChange={handleInputChange}
                          validation="required|alphabetsOnly|max:35"
                          placeholder=""
                          name="city"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <div className="abmaccount">
                        <div style={{ display: "flex" }}>
                          <label
                            className="block careprofileLabel text-blueGray-600 text-xs font-bold mb-2 mt-5"
                            htmlFor="grid-password"
                          >
                            Zip
                          </label>
                          <p
                            style={{
                              color: 'gray',
                              fontSize: '18px',
                              textAlign: 'left',
                              paddingTop: '22px',
                              marginLeft: '2px',

                            }}
                          >
                            *
                          </p>
                        </div>
                        <InputField
                          type="text"
                          className="inputfeildsforallcare1"
                          ref={inputRefs.current[6]}
                          handleInputChange={handleInputChange}
                          validation="required|alphanumeric|min:3|max:9"
                          placeholder=""
                          name="postal"
                        />
                      </div>
                    </div>
                  </div>

                </div>

                <div className="flex flex-wrap mt-3">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <div className="abmaccount">
                        <div style={{ display: "flex" }}>
                          <label
                            className="block careprofileLabel text-blueGray-600 text-xs font-bold mb-2 mt-5"
                            htmlFor="grid-password"
                          >
                            Phone
                          </label>
                          <p
                            style={{
                              color: 'gray',
                              fontSize: '18px',
                              textAlign: 'left',
                              paddingTop: '22px',
                              marginLeft: '2px',

                            }}
                          >
                            {/* * */}
                          </p>
                        </div>
                        <PhoneInput
                          className="inputfeildsforallcarephone"
                          international
                          value={phoneValue}
                          placeholder=""
                          country={'us'}
                          inputProps={{
                            name: "phonenumber",
                            // required: true,
                          }}
                          onChange={setPhoneValue}
                          inputStyle={{
                            width: "100%",
                            border: "none !important",
                            background: "#fff",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <div className="abmaccount">
                        <div style={{ display: "flex" }}>
                          <label
                            className="block careprofileLabel2 text-blueGray-600 text-xs font-bold mb-2 mt-5"
                            htmlFor="grid-password"
                          >
                            Email
                          </label>
                          <p
                            style={{
                              color: 'gray',
                              fontSize: '18px',
                              textAlign: 'left',
                              paddingTop: '20px',
                              marginLeft: '2px',

                            }}
                          >
                            *
                          </p>
                        </div>
                        <InputField
                          type="text"
                          className="inputfeildsforallcare2"
                          ref={inputRefs.current[8]}
                          handleInputChange={handleInputChange}
                          validation="required"
                          placeholder=""
                          name="email"
                        />
                      </div>
                    </div>
                  </div> */}
                </div>

                <div>
                  <div className="flex flex-wrap mt-3">
                    <div className="w-full lg:w-12/12 px-4">
                      <div className="relative w-full mb-3">
                        <div className="abmaccount">
                          <div style={{ display: "flex" }}>
                            <label
                              className="block careprofileLabel text-blueGray-600 text-xs font-bold mb-2 mt-5"
                              htmlFor="grid-password"
                            >
                              Website
                            </label>
                            <p
                              style={{
                                color: 'gray',
                                fontSize: '18px',
                                textAlign: 'left',
                                paddingTop: '22px',
                                marginLeft: '2px',

                              }}
                            >
                              {/* * */}
                            </p>
                          </div>
                          <InputField
                            type="text"
                            className="inputfeildsforallProfile"
                            ref={inputRefs.current[9]}
                            handleInputChange={handleInputChange}
                            validation="website"
                            placeholder=""
                            name="website"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="updatebuttonCenter">
              <Button
                variant="contained"
                onClick={onSubmit}
                className="buttonforUpdate"
              >
                {/* Submit */}
                {saveClickButtonLoad ? <Box sx={{
                  display: 'flex', width: "40px", height: "40px"
                }}>  <LoadingIcon /></Box> : "Submit"}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AlertSnackBar = ({
  flag,
  onClose,
  anchorOrigin,
  msg,
  severity,
}) => {
  return (
    <Snackbar
      anchorOrigin={
        anchorOrigin ? anchorOrigin : { vertical: "top", horizontal: "right" }
      }
      open={flag}
      onClose={onClose}
      autoHideDuration={6000}
    >
      <Alert onClose={onClose} severity={severity ? severity : "success"}>
        {msg}
      </Alert>
    </Snackbar>
  );
};
