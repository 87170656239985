import {
  LOGIN_IN,
  CURRENT_USER_CHAT,
  CURRENT_USER_PROFILE,
  // SIGN_IN,
  INVITATION_SUPER_ADMIN,
  INVITATION_ADMIN,
  INVITATION_CLINICAL_ADMIN,
  INVITATION_CLINIC,
  INVITE_POP_UP_OPEN,
  SUPERADMIN_DASHBOARD_POPUP,
  LOGIN_GET_OTP,
  FORGET_GET_OTP,
  OTP_MATCHES,
  FORGOT_OTP_MATCHES,
  PASSWORD_UPDATED,
  USER_CLINIC_DATA,
  SIDEBAR_ROLES,
  DASHBOARD_OPTIONS,
  REPORT_SEARCH,
  REPORT_PROFILE_ID,
  NAV_REPORT_SEARCH_TO_PATIENT_PROFILE,
  ADHERANCE_SCORE_GRAPH_MAP,
  DELIVERED_PARAMETERS,
  ADHERANCE_SCORE_MAP,
  ADHERANCE_SCORE_GRAPH_DATERANGE,
  DASHBOARD_VAL,
  DASHBOARD_USER_DATA,
  PENDING_REQUESTS,
  HMR_GRAPH_DATE_RANGE,
  HMR_GRAPH,
  HMR_GRAPH_BOOLEAN,
  ADHERANCE_GRAPH_BOOLEAN,
  PASSWRD_CHECK,
  USER_NEW_EMAIL,
  USER_FORGOT_EMAIL,
  EMAIL_UPDATED,
  EMAIL_FORGOT,
  USER_NEW_PHONE,
  PASSWORD_UPDATE,
  PASSWORD_UPDATE_FAILED,
  NOTIFICATION_SUMMARY_PATIENTTABLE,
  NOTIFICATION_SUMMARY_PATIENTREPORT,
  ENROLL_CLINICIAN_PATIENT,
  ACTIVE_PATIENTS,
  SEARCH_CLINICS,
  SEARCH_CLINICIANS,
  SEARCHED_PATIENTS,
  FETCH_CLINIC_ID,
  SEARCH_PATIENTS,
  ACTIVE_CLINICADMINS,
  ACTIVE_CLINICIANS,
  INACTIVE_PATIENTS,
  All_PATIENTS,
  PATIENT_PROFILE_THERAPY_GRAPH,
  LOGIN_ERROR_MSG,
  FORGET_ERROR_MSG,
  FORGET_ERROR_MAIL,
  IS_SESSION_EXPIRED,
  PATIENT_PROFILE_ADS_PROGRESSBAR,
  UPDATED_PATIENT_ID,
  RESET_ALL_STATE,
  OTP_EXPIRE_ERROR,
  FORGOTOTP_EXPIRE_ERROR,
  PASSWORD_UPDATE_ERROR,
  OTP_RESEND_LOGIN,
  OTP_RESEND_FORGOT,
  OTP_RESEND_FORGOTEMAIL,
  OTP_RESPONSE_EMAIL,
  OTP_RESPONSE_EMAIL_FORGOT,
  OTP_DOESNOT_MATCHES_PASSWORD_EMAIL,
  OTP_DOESNOT_MATCHES_FORGOT_PASSWORD_EMAIL,
  OTP_EXPIRE_PASSWORD_EMAIL,
  OTP_EXPIRE_FORGOTPASSWORD_EMAIL,
  OTP_RESPONSE_PHONE,
  OTP_DOESNOT_MATCHES_PASSWORD_PHONE,
  OTP_EXPIRE_PHONE,
  UPDATED_UNIT_VALUE,
  REPORT_SEARCH_PRTICULAR_USER,
} from "../action.list";
/* import list from "../../constant/list"; */
import chatUsers from "../../constant/chatUsers";
// import login from "../login";
// import hospitalsList from "../hospital";

const usercredentials = '';//login.userDetails;
const hospitals = '';//hospitalsList.hospitals;
const doctor = {};//list.doctorList;
const patient = {}//list.patientList;
const device = {}//list.deviceList;
const userTable = {}//list.userTable;
const clincTable = {}//list.clincTable;
const superAdminTable = {}//list.superAdminTable;
const adminTable = {}//list.adminTable;
const clinicInviteTable = {}// list.clinicInviteTable;

// const { totalDoctors, totalPatients, totalDevices } = list;

let defaultState = {
  list: {
    doctor,
    patient,
    device,
    userTable,
    clincTable,
    superAdminTable,
    adminTable,
  },
  clinicInviteTable: clinicInviteTable,
  analysis: {},//{ totalDoctors, totalPatients, totalDevices },
  // usercredentials : {username, password},
  users: [],//list.users,
  chatUsers: chatUsers.allChatUsers,
  curUserChat: null,
  curUserProfile: null,
  credentials: { usercredentials },
  hospitalList: { hospitals },
  user: null,
  clincTable: clincTable,
  userTable: userTable,
  adminTable: adminTable,
  superAdminTable: superAdminTable,
  otpSent: false,
  otpToken: null,
  userDetail: null,
  isMatch: false,
  userLogIn: [],
  sideBarRoles: "Dashboard",
  reportSearchVal: [],
  reportProfileId: null,
  navFromReportSearchToPatientProfile: {},
  sideBarSubRoles: " ",
  userLogInFlag: false,
  adheranceScoreMap: [],
  adheranceScoreGraph: {},
  adheranceScoreGraphDateRange: [],
  dashboard_val: {},
  pendingInvitation: {},
  passwrdResposne: "",
  passwordNotUpdated:"",
  newEmailSet: "",
  emailToken: null,
  emailOtpMatch: false,
  newPhoneSet: "",
  phoneToken: null,
  passwordUpdate: "",
  dashboard_data: [],
  hmrGraphval: [],
  hmrGraphDateRange: [],
  hmrgraphboolean: false,
  adherancegraphboolean: false,
  mobileOtp: "",
  emailOtp: "",
  loginOtp: null,
  notification_summary_table: [],
  sm_PatientTable_totalCount: null,
  enrolledCounts: [],
  activePatientsList: [],
  activeClinicianList: [],
  activeClinicadminList: [],
  searchedPatient: [],
  searchedClinician: [],
  searchedpatients:[],
  searchedClinic: [],
  fetchedClinicByID: [],
  inactivePatientsList: [],
  allPatientsList: [],
  patientProfileTherapyGraph: [],
  patientProfile_ADS_ProgressBar: [],
  loginErrorMsg: "",
  caresiteuserList: [],
  isSessionExpired: false,
  dataCount: 0,
  dashboard_totalCount: null,
  updated_patient_id: null,
  otpexpireerror: null,// otp expire error
  resendotpcodelogin: "",
  OtpResponseEmail: false,
  OtpNotMatchPasswordEmail: false,
  OtpEpirePasswordEmail: false,
  OtpResponsePhone: false,
  OtpNotMatchPhone: false,
  OTpexpirePhone : false,
  particularUserReportSearchVal : {},

};

export const userReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_IN:
      return { ...state, ...payload };
    case LOGIN_GET_OTP:
      return { ...state, ...payload };
      case FORGET_GET_OTP:
      return { ...state, ...payload };
    case OTP_MATCHES:
      return { ...state, ...payload };
      case FORGOT_OTP_MATCHES:
      return { ...state, ...payload };
      case PASSWORD_UPDATED:
      return { ...state, ...payload };
    case CURRENT_USER_CHAT:
      return { ...state, ...payload };
    case CURRENT_USER_PROFILE:
      return { ...state, ...payload };
    case INVITATION_ADMIN:
      return {
        ...state,
        adminTable: [...state.adminTable, action.payload.invitationAdmin],
      };
    case INVITATION_CLINICAL_ADMIN:
      return {
        ...state,
        clincTable: [...state.clincTable, action.payload.invitationAdmin],
      };
    case INVITATION_CLINIC:
      return {
        ...state,
        clinicInviteTable: [
          ...state.clinicInviteTable,
          action.payload.invitationAdmin,
        ],
      };
    case INVITATION_SUPER_ADMIN:
      return {
        ...state,
        superAdminTable: [
          ...state.superAdminTable,
          action.payload.invitationAdmin,
        ],
      };
    case USER_CLINIC_DATA:
      return { ...state, ...payload };
    case SIDEBAR_ROLES:
      return { ...state, ...payload };
    case REPORT_SEARCH:
      return { ...state, ...payload };
    case REPORT_PROFILE_ID:
      return { ...state, ...payload };
    case NAV_REPORT_SEARCH_TO_PATIENT_PROFILE:
      return { ...state, ...payload };
    case ADHERANCE_SCORE_MAP:
      return { ...state, ...payload };
    case ADHERANCE_SCORE_GRAPH_MAP:
      return { ...state, ...payload };
    case DELIVERED_PARAMETERS: return {...state,...payload};
    case ADHERANCE_SCORE_GRAPH_DATERANGE:
      return { ...state, ...payload };

    case DASHBOARD_VAL:
      return { ...state, ...payload };
    case PENDING_REQUESTS:
      return { ...state, ...payload };
    case PASSWRD_CHECK:
      return { ...state, ...payload };
    case USER_NEW_EMAIL:
      return { ...state, ...payload };
    case USER_FORGOT_EMAIL:
        return { ...state, ...payload };
    case EMAIL_UPDATED:
      return { ...state, ...payload };
    case EMAIL_FORGOT:
      return { ...state, ...payload };
    case USER_NEW_PHONE:
      return { ...state, ...payload };
    case PASSWORD_UPDATE:
      return { ...state, ...payload };
    case PASSWORD_UPDATE_FAILED:
      return { ...state, ...payload };
    case DASHBOARD_USER_DATA:
      return { ...state, ...payload };

    case PENDING_REQUESTS:
      return { ...state, ...payload };

    case HMR_GRAPH:
      return { ...state, ...payload };

    case HMR_GRAPH_DATE_RANGE:
      return { ...state, ...payload };

    case HMR_GRAPH_BOOLEAN:
      return { ...state, ...payload };

    case ADHERANCE_GRAPH_BOOLEAN:
      return { ...state, ...payload };
    case NOTIFICATION_SUMMARY_PATIENTTABLE:
      return { ...state, ...payload };
    case NOTIFICATION_SUMMARY_PATIENTREPORT:
      return { ...state, ...payload };
    case ENROLL_CLINICIAN_PATIENT:
      return { ...state, ...payload };
    case ACTIVE_PATIENTS:
      return { ...state, ...payload };
    case SEARCH_CLINICS:
      return { ...state, ...payload };
    case SEARCH_CLINICIANS:
      return { ...state, ...payload };
    case SEARCHED_PATIENTS:
        return { ...state, ...payload };
    case FETCH_CLINIC_ID:
      return { ...state, ...payload };
    case SEARCH_PATIENTS:
      return { ...state, ...payload };
    case ACTIVE_CLINICADMINS:
      return { ...state, ...payload };
    case ACTIVE_CLINICIANS:
      return { ...state, ...payload };
    case INACTIVE_PATIENTS:
      return { ...state, ...payload };
    case All_PATIENTS:
      return { ...state, ...payload };

    case PATIENT_PROFILE_THERAPY_GRAPH:
      return { ...state, ...payload };
    case LOGIN_ERROR_MSG:
      return { ...state, ...payload };
    case FORGET_ERROR_MSG:
      return { ...state, ...payload };
      case FORGET_ERROR_MAIL:
        return { ...state, ...payload };
    case IS_SESSION_EXPIRED:
      return { ...state, ...payload };
    case PATIENT_PROFILE_ADS_PROGRESSBAR:
      return { ...state, ...payload };
    case UPDATED_PATIENT_ID:
      return { ...state, ...payload };
    case RESET_ALL_STATE:
      return defaultState;
    case OTP_EXPIRE_ERROR:
      return { ...state, ...payload };
    case FORGOTOTP_EXPIRE_ERROR:
      return { ...state, ...payload };
    case PASSWORD_UPDATE_ERROR:
      return { ...state, ...payload };
    case OTP_RESEND_LOGIN:
      return { ...state, ...payload }
      case OTP_RESEND_FORGOT:
      return { ...state, ...payload }
    case OTP_RESEND_FORGOTEMAIL:
      return { ...state, ...payload }
    case OTP_RESPONSE_EMAIL:
      return { ...state, ...payload }
    case OTP_RESPONSE_EMAIL_FORGOT:
        return { ...state, ...payload }
    case OTP_DOESNOT_MATCHES_PASSWORD_EMAIL:
      return { ...state, ...payload }
    case OTP_DOESNOT_MATCHES_FORGOT_PASSWORD_EMAIL:
      return { ...state, ...payload }
    case OTP_EXPIRE_PASSWORD_EMAIL:
      return { ...state, ...payload }
    case   OTP_EXPIRE_FORGOTPASSWORD_EMAIL:
      return { ...state, ...payload }
    case OTP_RESPONSE_PHONE:
      return { ...state, ...payload }
    case OTP_DOESNOT_MATCHES_PASSWORD_PHONE:
      return { ...state, ...payload }
    case OTP_EXPIRE_PHONE :
      return {...state,...payload}
    case UPDATED_UNIT_VALUE:
      return { ...state, ...payload }
    case REPORT_SEARCH_PRTICULAR_USER: 
      return { ...state, ...payload }     
    default:
      return state;
  }
};
