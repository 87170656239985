import React, { useState } from "react";
import { connect } from "react-redux";
import NotFound from "../404NotFound";
import {  useHistory } from "react-router-dom";
import {
  updateExistingCLinicList,
  // addUserByinvitationlink,
} from "../../../redux/invitation/components/invitation.action";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useLocation } from "react-router-dom";
import { base64Decode } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const AcceptInvitation = ({
  updateExistingCLinicList,
}) => {
  let history = useHistory();
  let location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("param1");
  const [data, setData] = useState(param1 ? JSON.parse(base64Decode(param1)) : null);
  const classes = useStyles();
  const onSubmit = () => {
    if (data) {
      updateExistingCLinicList(data);
      history.push("/");
    }
  };
  return (
    <>
      {data ? (
        <div>
          <div style={{ padding: "40px" }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "30px",
              }}
            >
              You have been invited to register for{" "}
              {data.clinicname[data.clinicname.length - 1]} clinic{" "}
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={classes.root}>
              <Button
                variant="contained"
                onClick={() => onSubmit()}
                color="primary"
              >
                Accept
              </Button>
            </div>
            <div className={classes.root}>
              <Button
                variant="contained"
                onClick={() => history.push("/")}
                color="secondary"
              >
                Reject
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};
const mapStateToProps = (store) => {
  return { inviteData: store.user.inviteData };
};
export default connect(mapStateToProps, {
  updateExistingCLinicList,
})(AcceptInvitation);
