import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { invitationLink } from '../../constant/constantRoutes';
import { connect } from 'react-redux';


const ResendSuccess = (props) => {
 

    return (
        <>
            <Dialog 
              open={props.open}
              onClose={props.onClose}
              fullWidth="md"
            >
                <DialogTitle style={{ backgroundColor: "#f2f4f8" }}>
                    <div style={{ display:'flex', justifyContent:'flex-end'}}>
                <CloseOutlinedIcon
                 onClick={props.onClose} 
                className="closeIcon" />
                   </div>
                </DialogTitle>
                <DialogContent style={{ backgroundColor: "#f2f4f8" }}>
                    <div style={{display:'flex', justifyContent:'center', marginBottom:'88px', marginTop:'48px'}}>
                    <img src={require('../../assets/img/SuccessIcon.svg').default} 
                    style={{width:'160px', height:'160px'}}
                    ></img>
                    </div>
                    <div style={{display:'flex', justifyContent:'center', flexDirection:'column', margin:'auto', marginBottom:'88px'}}>
                    <div style={{ color: '#6A768F', fontSize:'32px', fontWeight:'500', display:'flex', justifyContent:'center'}}>You have successfully resent the</div>
                    <div style={{display:'flex',justifyContent:'center', flexDirection:'row'}}><p style={{ color: '#6A768F', fontSize:'32px', fontWeight:'500'}}>invitation.</p> </div></div>
                </DialogContent>
            </Dialog>
        </>
    );
}

const mapStateToProps = (store) => {
    return { };
  };
  
export default connect(mapStateToProps, {})(ResendSuccess);