import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import "./chatContent.css";
import { connect, useSelector } from "react-redux";
import attachfileIcon from "../../../assets/img/attatch.png";
import { directChat } from "../../../redux/sharing/sharing.action";
import {
  submitSendMessage,
  uploadFilesAndGetIds,
  getSrcFromAttachmentId,
} from "../../../redux/quickbloxmessaging/quickblox.action";
import { MessageBox } from "react-chat-elements";
import moment from "moment";
import eyeIcon from "../../../assets/img/eye.svg";
import { Avatar } from "@material-ui/core";
const ChatContent = (props) => {
  const [messages, handleMessages] = useState([]);

  const [msg, setMsg] = useState();
  const messagesEndRef = React.createRef();
  const scrollToBottom = () => {
    let e = document.getElementById("scrollview_msgcontent");
    if (e)
      e.scrollTop = e.scrollHeight;
  };

  const onStateChange = (e) => {
    setMsg(e.target.value);
  };
  const contentRef = useRef(null);


  const OpponentsData = useSelector((store) => {
    return store.quickblox.opponentsDetails;
  });

  useEffect(() => {
    const quickblox_id = props.userQID.quickbloxId
      ? props.userQID.quickbloxId
      : null;

    handleMessages(
      props.prevConversation &&
      props.prevConversation.map((messageItem) => {
        const {
          _id,
          message,
          date_sent,
          sender_id,
          attachments = [],
        } = messageItem;
        let text = message;
        let type = "text";
        let attach = null;
        if (attachments.length > 0) {
          attach = attachments[0];
          text = "";
          if (attach.type === "image") {
            type = "photo";
          } else {
            type = "file";
          }
        }
        let title = "";
        let photo;
        for (const item of Object.entries(OpponentsData)) {
          if (sender_id == item[0]) {
            title =
              item[1].first_name || item[1].last_name
                ? `${item[1].first_name ? item[1].first_name : ""} ${item[1].last_name ? item[1].last_name : ""
                }`
                : item[1].email;
            photo = item[1].photo_uuid ? item[1].photo_uuid : null;
            break;
          }
        }

        return {
          _id,
          position: sender_id === quickblox_id ? "right" : "left",
          type,

          photo,
          title,
          text,
          date: new Date(date_sent * 1000),
          sender_id,
          data: {
            uri: attach ? getSrcFromAttachmentId(attach.id) : "",
          },
        };
      })
    );

    if (
      props.prevConversation &&
      props.prevConversation.length &&
      props.selectedUserFlag &&
      props.conversationFlag
    ) {
      scrollToBottom();
    }
  }, [props.prevConversation]);

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.code === "Enter") {
      onMsgSend();
    }
  };

  const onMsgSend = () => {
    if (msg && props.chatDialogId) {
      props.submitSendMessage(props.chatDialogId, msg);
      setMsg("");
    } else {
      alert("Some required parameters are missing");
    }
  };
  const prepareToUpload = useCallback((e) => {
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      props.uploadFilesAndGetIds(files[i], props.chatDialogId);
    }
    e.target.value = null;
  }, []);
  const setimage = () => {
    return {
      data: {
        uri: require("../../../assets/icons/attachmentIcon.png"),
      },
    };
  };

  return (
    <>
      {props.showChatWindowFlag == false ? (
        <div className="outChatDiv">
          <div className="top_chat"></div>
          <div className="main__chatcontent"></div>
        </div>
      ) : (
        <div className="main__chatcontent">
          <div className="content__header">
            <div className="UpperUser">
              {!props.selectedUserFlag ? (
                <div className="leftsideImage">
                  <i className="fa fa-user chatContentIcon" aria-hidden="true"></i>
                  <p className="nicName">BS</p>
                </div>
              ) : (
                //Chant content header 
                <div className="leftsideImage">
                  <div style={{ display: "flex" }}>
                    {props.selectedUserChat && props.selectedUserChat.avatars
                      ? props.selectedUserChat.avatars.map((item, index) => {

                        let name =
                          props.selectedUserChat.shortName.split(" + ");
                        return item ? (
                          <div
                            style={{
                              padding: "6px 10px",
                            }}
                            key={index}
                          >
                            <img
                              className="topImg"
                              style={{ width: "40px", height: "40px" }}
                              src={item}
                            />
                            {name ? (
                              <p
                                style={{
                                  textAlign: "center",
                                  fontWeight: "500",
                                  paddingTop: "5px",
                                }}
                              >
                                {props.selectedUserChat.longName}
                              </p>
                            ) : null}
                          </div>
                        ) : (
                          <div>
                            <div
                              style={{
                                padding: "0px 10px",
                                position: "relative",
                                bottom: "10px",
                              }}
                              key={index}
                            >
                              <i
                                className="fa fa-user userforchat"
                                aria-hidden="true"
                                style={{ fontSize: "12px", padding: "15px" }}
                              ></i>
                            </div>
                            {name ? (
                              <p
                                style={{
                                  textAlign: "center",
                                  fontWeight: "500",
                                }}
                              >
                                {props.selectedUserChat.longName}
                              </p>
                            ) : null}
                          </div>
                        );
                      })
                      :
                      //added some code by dhanush new chat and it is working 
                      props.selectedUserChat ?
                        <div>
                          {props.selectedUserChat.photo_uuid ?

                            //------------------

                            <div
                              style={{
                                padding: "6px 10px",
                              }}

                            >
                              <Avatar
                                className="topImg"
                                style={{ width: "40px", height: "40px" }}
                                src={`https://ucarecdn.com/${props.selectedUserChat.photo_uuid}/`}
                              />
                              {props.selectedUserChat.first_name && props.selectedUserChat.last_name ? (
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "500",
                                    paddingTop: "5px",
                                  }}
                                >
                                  {props.selectedUserChat.first_name+' '+props.selectedUserChat.last_name}
                                </p>
                              ) : null}
                            </div>

                            //------------------0---------------
                            :
                            //------------------------
                            <div>
                              <div
                                style={{
                                  padding: "0px 10px",
                                  position: "relative",
                                  bottom: "10px",
                                }}
                              // key={index}
                              >
                                <i
                                  className="fa fa-user userforchat"
                                  aria-hidden="true"
                                  style={{ fontSize: "12px", padding: "15px" }}
                                ></i>
                              </div>
                              {props.selectedUserChat.first_name && props.selectedUserChat.last_name ? (
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "500",
                                  }}
                                >
                                  {props.selectedUserChat.first_name+' '+props.selectedUserChat.last_name}
                                </div>
                              ) : null}
                            </div>

                            //------------------------ 0 --------------
                          }
                        </div>
                        //Added by dhanush for new group and it is working
                        : props.selectedGroup && props.selectedGroup ?
                          props.selectedGroup.map((item, index) => {
                            return (
                              <div style={{
                                padding: "6px 10px",
                              }}>
                                {item.photo_uuid ?
                                  <Avatar
                                    src={`https://ucarecdn.com/${item.photo_uuid}/`}
                                    style={{}}
                                  >
                                  </Avatar>
                                  :
                                  <div
                                    style={{
                                      padding: "0px 10px",
                                      position: "relative",
                                      bottom: "10px",
                                    }}
                                    key={index}
                                  >
                                    <i
                                      className="fa fa-user userforchat"
                                      aria-hidden="true"
                                      style={{ fontSize: "12px", padding: "15px" }}
                                    ></i>
                                  </div>
                                }

                                {item.first_name && item.last_name ? (
                                  <p
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "500",
                                      paddingTop: "5px",
                                    }}
                                  >
                                    {item.first_name+ ' '+ item.last_name}
                                  </p>
                                ) : null}

                              </div>
                            )

                          })
                          : null}
                  </div>
                </div>

                //------------------------0------------------

              )}
            </div>
          </div>
          <div
            className="content__body"
            id="scrollview_msgcontent"
            ref={messagesEndRef}
          >
            <div className="chat__items">
              <div className="msgReverseDiv">
                {props.selectedUserFlag && props.conversationFlag ? (
                  messages.map((message) => {
                    return (
                      <div className="msgWrapper">
                        <div
                          key={message._id}
                          className={
                            message.position === "right"
                              ? "msgImgDivLeft"
                              : "msgImgDivRight"
                          }
                        >
                          <div className="userImage">
                            {props.selectedUserFlag &&
                              message.position === "left" ? (
                              <div>
                                {message.photo ? (
                                  <img
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "50%",
                                    }}
                                    src={`https://ucarecdn.com/${message.photo}/`}
                                  />
                                ) : (
                                  <i
                                    className="fa fa-user chatContentIcon"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </div>
                            ) : (
                              <div>
                                {message.photo ? (
                                  <img
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "50%"
                                    }}
                                    src={`https://ucarecdn.com/${message.photo}/`}
                                  />
                                ):(
                                  <i
                                    className="fa fa-user chatContentIcon"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </div>
                            )}
                          </div>
                          <div
                            className={
                              message.position === "right"
                                ? "msgOuterRight"
                                : "msgOuterLeft"
                            }
                            style={{ wordBreak: 'break-word' }}
                          >
                            {message.position === "right" ? (
                              <div>
                                {" "}
                                <p className="rightUserName">
                                  {message.title}
                                </p>{" "}
                              </div>
                            ) : (
                              <div>
                                {" "}
                                <p className="leftUserName">
                                  {message.title}
                                </p>{" "}
                              </div>
                            )}
                            <MessageBox
                              className={
                                message.position === "right"
                                  ? "msgRight fnt21P"
                                  : "msgLeft fnt21P"
                              }
                              type={message.type}
                              text={message.text}
                              // title={message.title}
                              titleColor="#e73d1a"
                              position={message.position}
                              onLoad={() =>
                                contentRef.current &&
                                contentRef.current.scrollToBottom()
                              }
                              // date={message.date}
                              dateString={moment(message.date).format(
                                "D MMM H:mm:s"
                              )}
                              data={message.data}
                              notch={false}
                              onPhotoError={setimage}
                              onClick={() =>
                                message.data.uri
                                  ? window.open(message.data.uri)
                                  : null
                              }
                              renderAddCmp={() => (
                                // Date time on chat content
                                <div
                                  className={
                                    message.position === "right"
                                      ? "msgInnerTimeRight"
                                      : "msgInnerTimeLeft"
                                  }
                                >
                                  <p
                                    style={{ fontSize: "12px" }}
                                    className={
                                      message.position === "right"
                                        ? "msgsenttimeright"
                                        : "msgsenttimeLeft"
                                    }
                                  >
                                    {message.date &&
                                      moment(message.date).fromNow()}
                                  </p>
                                  <img
                                    src={eyeIcon}
                                    className={
                                      message.position === "right"
                                        ? "eyeOnMsg"
                                        : "eyeOnMsgLeft"
                                    }
                                    style={{}}
                                  />
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="msgWrapper">
                    <div className="noChatDiv">
                      <div className="noChatDivMsg">
                        <p>Start your chat</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div ref={messagesEndRef} />
            </div>
          </div>
          <div className="content__footer">
            <label>
              <div className="sendFile">
                <img src={attachfileIcon} />
              </div>
              <input
                onChange={(e) => prepareToUpload(e)}
                // disabled={chatInitialization}
                className="chatDialogAttachInput"
                type="file"
                accept="image/*"
              />
            </label>

            <div className="sendNewMessage">
              <input
                type="text"
                placeholder="Type your messages"
                onChange={onStateChange}
                value={msg}
                onKeyPress={handleKeypress}
              />
              <button
                onClick={onMsgSend}
                type="button"
                className="btnSendMsg"
                id="sendMsgBtn"
              >
                <i className="fa fa-arrow-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  // console.log('[store.quickblox.seletedUser]', store.quickblox.selectedGroup)
  return {
    curUserChat: store.user.curUserChat,
    userQID: store.user.user,
    curUserProfile: store.user.curUserProfile,
    selectedUserChat: store.quickblox.seletedUser,
    selectedUserFlag: store.quickblox.selectedUserFlag,
    allMessageItem: store.quickblox.allMessageItem,
    prevConversation: store.quickblox.prevConversation,
    conversationFlag: store.quickblox.conversationFlag,
    conversation: store.quickblox.conversation,
    chatDialogId: store.quickblox.chatDialogId,
    showChatWindowFlag: store.sharing.showChatWindowFlag,
    selectedGroup: store.quickblox.selectedGroup,
    opponentsDetails: store.quickblox.opponentsDetails,
  };
};
export default connect(mapStateToProps, {
  directChat,
  submitSendMessage,
  uploadFilesAndGetIds,
})(ChatContent);
