import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "./ClinicAdminDashboard.css";
import Button from "@material-ui/core/Button";
// components
import { connect } from "react-redux";
import {
  userLoginData,
  pageHeading,
  loadProfile,
  fetchDashboardDetails,
  userTotalTransmitted,
  userWithDeviation,
  userWithoutDeviation,
  userNeverTransmitted,
  userADSU75,
  userADSU50,
  userADSU25,
  userPendingData,
  logIn,
  updateTherapyGoalsRedux,
  make_dashboard_data_empty,
  clear_patient_profile,
  userMissedtherapy
} from "../../../redux/user/user.action";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import {
  fetchInvitation,
  addClinicList,
  openInvitePopup,
  fetchClinicProfile,
  openFilterPopup,
} from "../../../redux/invitation/components/invitation.action.js";
import SearchIcon from "../../../assets/icons/searchIcon.png";
import Select from "../../../components/Select";
import ClinicCard from "../../../components/ClinicAdminClinicCard";
import Bulb from "../../../assets/img/anouncementBulb.svg";
//import FifthIcon from "../../../assets/img/Invitations.png";
import FifthIcon from "../../../assets/img/Invitations.svg";
import SixthIcon from "../../../assets/img/Missed 3 or more days.png";
import SeventhIcon from "../../../assets/icons/Never transmitted icon.png";
import EightIcon from "../../../assets/img/With deviation icon.png";
import IconSquare from "../../../assets/img/IconSquare.svg";
import WD from "../../../assets/icons/Without deviation icon.png";
import Pagination from "../../../components/Pagination";
import InvitationTable from "../../../components/Cards/InvitationTable.js";
import AnnouncementCards from "../../../components/Cards/AnnouncementCards";
import TwentyfivePercent from "../../../assets/img/25%.png";
import fiftyPercent from "../../../assets/img/50%.png";
import seventyfivePercent from "../../../assets/img/75%.png";
import ClinicadminTabelSingleComponent from "../../../components/ClinicadminTabelSingleComponent";
import "../../../assets/styles/font.css";
import moment from "moment";
import SeparatorBottomSection from "../../../components/SeparatorBottomSection";
import SeparatorTopSection from "../../../components/SeparatorTopSection";
import excelIcon from "../../../assets/img/excel-1-129882.png";
import LoadingIcon from "../../../components/LoadingIcon";

const useStyles = makeStyles((theme) => ({
  buttonsOfDate: {
    backgroundColor: "#F2F4F8",
    marginLeft: "11px",
    boxShadow: "#6A768F80 0px 3px 6px",
    color: "#3D446F",
    fontFamily: "Roboto",
    marginBottom: "8px",
    textTransform: "capitalize",
    fontWeight: "400",
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const ClinicadminDashboard = ({
  userLoginData,
  user,
  loadProfile,
  fetchDashboardDetails,
  dashboardAnalytics,
  userSpClinicList,
  userTotalTransmitted,
  userWithDeviation,
  userWithoutDeviation,
  userNeverTransmitted,
  userADSU75,
  userADSU50,
  userADSU25,
  dashboard_data,
  pendingInvitation,
  userPendingData,
  allChats,
  dashboard_totalCount,
  updateTherapyGoalsRedux,
  make_dashboard_data_empty,
  clear_patient_profile,
  userMissedtherapy
}) => {
  const classes = useStyles();
  const history = useHistory();

  //checkbox select Day, Week, Month, DateRange

  //Done by Poovarasan
  const [currDay, setCurrDay] = React.useState(new Date());
  const [selectRange, setRelectRange] = React.useState("week");
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [clinicId, setClinicId] = React.useState();
  const [clinicName, setClinicName] = React.useState();
  const [userTableNumber, setUserTableNumber] = React.useState();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [searchInputValue, setSearchInputValue] = React.useState("");
  const [filterButtonIndex, setFilterButtonIndex] = React.useState(2);
  //For onClick
  const [isSelected, setIsSelected] = React.useState("");
  const [nameofSelected, setNameofSelected] = React.useState("");
  const [BottomText, setBottomText] = React.useState("");

  const scrollRef = React.useRef(null);
  const [childComponentKey, setChildComponentKey] = React.useState(0);


  const [clincDashDateFrom, setClinicDashDateFrom] = React.useState(
    moment().subtract(7, "d").utc().format("DD MMM yyyy")
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const defaultValue = moment().format('YYYY-MM-DD')
  const maxfortrendfrom = moment(Math.min.apply(null,[new Date(defaultValue),toDate?new Date(toDate):new Date(defaultValue)])).format('YYYY-MM-DD')

  const [buttonEnable, setButtonEnable] = React.useState(false);
  const array1 =['Missed 3 or more days','Adherence below 75%','Adherence below 25%','Adherence below 50%','Patients who have never transmitted']
  const array2 =['Adherence below 75%','Adherence below 25%','Adherence below 50%']
  useEffect(() => {
    let value = userSpClinicList.rows ? userSpClinicList.rows : null;
    if (value && value.length) {
      try {
        let local_currClinic = localStorage.getItem("currClinic");
        let _local_currClinic = JSON.parse(
          local_currClinic ? local_currClinic : "{}"
        );
        let obj = value.findIndex((o) => o.c_id === _local_currClinic.clinicId);

        if (
          _local_currClinic.clinicId &&
          _local_currClinic.clinicName &&
          obj !== -1
        ) {
          setClinicId(_local_currClinic.clinicId);
        } else {
          let clinic_details = {
            clinicId: value[0].c_id,
            clinicName: value[0].clinicname,
          };
          setClinicId(value[0].c_id);
          localStorage.setItem("currClinic", JSON.stringify(clinic_details));
        }
      } catch (err) {
        console.log("ClinicAdminDashboard Catch:", err);
      }
    }
  }, [userSpClinicList]);

  useEffect(() => {
    let value = userSpClinicList.rows ? userSpClinicList.rows : null;
    if (value && value.length && !clinicId) {
      // setClinicId(value[0].c_id);
    }
  }, [userSpClinicList]);

  useEffect(() => {
    if (clinicId) {
      fetchDashboardDetails({
        norange: selectRange,
        clinicId: clinicId,
      });
      onSearch();
    }
  }, [clinicId]);
 //console.log('dashval',dashboardAnalytics)
 useEffect(() => {
  // Check if the target element exists in the DOM
  console.log('scrollref',scrollRef.current)
  console.log('scrollkey',childComponentKey)
  if (scrollRef.current) {
    // Scroll to the target element
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [childComponentKey]);
const rerenderChildComponent = () => {
  // Update the key to trigger a re-render of the child component
  setChildComponentKey(prevKey => prevKey + 1);
};
  const clearIsSelected = () => {
    setIsSelected("");
    setNameofSelected("");
  };

  //filter : Day button
  const dateFunc = () => {
    onFilterChange("day");
    fetchDashboardDetails({
      clinicId: clinicId,
      range1: null,
      range2: null,
      norange: "day",
    });
    setFilterButtonIndex(1);
    setRelectRange("day");
    setClinicDashDateFrom(moment().utc().format(" DD MMM yyyy"));
    setFromDate(null);
    setToDate(null);
    setSearchInputValue("");
  };

  //filter : Week button
  const weekFunc = () => {
    onFilterChange("week");
    fetchDashboardDetails({
      clinicId: clinicId,
      range1: null,
      range2: null,
      norange: "week",
    });
    setFilterButtonIndex(2);
    setRelectRange("week");
    setClinicDashDateFrom(
      moment().subtract(7, "d").utc().format(" DD MMM yyyy")
    );
    setFromDate(null);
    setToDate(null);
    setSearchInputValue("");
  };

  //filter : Month button
  const monthFunc = () => {
    onFilterChange("month");
    fetchDashboardDetails({
      clinicId: clinicId,
      range1: null,
      range2: null,
      norange: "month",
    });
    setFromDate(null);
    setToDate(null);
    setSearchInputValue("");
    setFilterButtonIndex(3);
    setRelectRange("month");
    setClinicDashDateFrom(
      moment().subtract(1, "M").utc().format(" DD MMM yyyy")
    );
  };

  //---------------SENDING INVITATION LINK TO CHILD AND GETTING THE VALUE FROM CHILD----------

  function newTransmission() {
    setIsSelected("Total Transmission");
    setNameofSelected("Total");
    make_dashboard_data_empty();
    setBottomText(dashboardAnalytics.newtransmitted);
    setUserTableNumber(1);
    userTotalTransmitted({
      clinicId: clinicId,
      range1: fromDate,
      range2: toDate,
      norange: selectRange,
      property: 'first_name',
      order: 'asc'
    });
    // scrollRef.current.scrollIntoView({ behavior: "smooth" })
    rerenderChildComponent()
    setPage(1);
    setSearchInputValue("");
  }

  function withDeviation() {
    setIsSelected("Transmission With Deviations");
    setNameofSelected("With Deviations");
    make_dashboard_data_empty();
    setBottomText(dashboardAnalytics.withdeviation);
    setUserTableNumber(2);
    userWithDeviation({
      clinicId: clinicId,
      range1: fromDate,
      range2: toDate,
      norange: selectRange,
      property: 'first_name',
      order: 'asc'
    });
    // scrollRef.current.scrollIntoView({ behavior: "smooth" })
    rerenderChildComponent()
    setPage(1);
    setSearchInputValue("");
  }

  function withoutDeviation() {
    setIsSelected("Transmission Without Deviations");
    setNameofSelected("Without Deviations");
    make_dashboard_data_empty();
    setBottomText(dashboardAnalytics.withoutdeviation);
    setUserTableNumber(3);
    userWithoutDeviation({
      clinicId: clinicId,
      range1: fromDate,
      range2: toDate,
      norange: selectRange,
      property: 'first_name',
      order: 'asc'
    });
    // scrollRef.current.scrollIntoView({ behavior: "smooth" })
    rerenderChildComponent()
    setPage(1);
    setSearchInputValue("");
  }

  function neverTransmitted() {
    setIsSelected("Patients who have never transmitted");
    setNameofSelected("Never Transmitted");
    make_dashboard_data_empty();
    setBottomText(dashboardAnalytics.never_transmited);
    setUserTableNumber(4);
    userNeverTransmitted({
      clinicId: clinicId,
      range1: fromDate,
      range2: toDate,
      norange: selectRange,
      property: 'first_name',
      order: 'asc'
    });
    // scrollRef.current.scrollIntoView({ behavior: "smooth" })
    rerenderChildComponent()
    setPage(1);
    setSearchInputValue("");
  }

  function ab75() {
    setIsSelected("Adherence below 75%");
    setNameofSelected("Adherence below 75%");
    make_dashboard_data_empty();
    setBottomText(dashboardAnalytics.lessthan75percent);
    setUserTableNumber(7);
    userADSU75({
      clinicId: clinicId,
      range1: fromDate,
      range2: toDate,
      norange: selectRange,
      property: 'first_name',
      order: 'asc'
    });
    // scrollRef.current.scrollIntoView({ behavior: "smooth" })
    rerenderChildComponent()
    setPage(1);
    setSearchInputValue("");
  }

  function ab50() {
    setIsSelected("Adherence below 50%");
    setNameofSelected("Adherence below 50%");
    make_dashboard_data_empty();
    setBottomText(dashboardAnalytics.lessthan50percent);
    setUserTableNumber(8);
    userADSU50({
      clinicId: clinicId,
      range1: fromDate,
      range2: toDate,
      norange: selectRange,
      property: 'first_name',
      order: 'asc'
    });
    // scrollRef.current.scrollIntoView({ behavior: "smooth" })
    rerenderChildComponent()
    setPage(1);
    setSearchInputValue("");
  }

  function ab25() {
    setIsSelected("Adherence below 25%");
    setNameofSelected("Adherence below 25%");
    make_dashboard_data_empty();
    setBottomText(dashboardAnalytics.lessthan25percent);
    setUserTableNumber(9);
    userADSU25({
      clinicId: clinicId,
      range1: fromDate,
      range2: toDate,
      norange: selectRange,
      property: 'first_name',
      order: 'asc'
    });
    // scrollRef.current.scrollIntoView({ behavior: "smooth" })
    rerenderChildComponent()
    setPage(1);
    setSearchInputValue("");
  }

  function updateTherapyGoals() {
    setIsSelected("Update Therapy Goals");
    setNameofSelected("Update Therapy Goals");
    make_dashboard_data_empty();
    // setBottomText("Update Therapy Goals");
    setBottomText(dashboardAnalytics.updatedgoals);
    setUserTableNumber(6);
    updateTherapyGoalsRedux({
      clinicId: clinicId,
      range1: fromDate,
      range2: toDate,
      norange: selectRange,
      property: 'first_name',
      order: 'asc'
    });
    // scrollRef.current.scrollIntoView({ behavior: "smooth" })
    rerenderChildComponent()
    setPage(1);
    setSearchInputValue("");
  }

  function missedtherapygoals() {
    setIsSelected("Missed 3 or more days");
    setNameofSelected("Missed 3 or more days");
    make_dashboard_data_empty();
    setBottomText(dashboardAnalytics.missedtherapy);
    setUserTableNumber(5);
    userMissedtherapy({
      clinicId: clinicId,
      range1: fromDate,
      range2: toDate,
      norange: 'day',
      property: 'first_name',
      order: 'asc'
    });
    // scrollRef.current.scrollIntoView({ behavior: "smooth" })
    rerenderChildComponent()
    setPage(1);
    setSearchInputValue("");
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Used for Popover
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const callSelect = (val) => {
    if (val !== null) {
      setClinicId(val.c_id);
      setClinicName(val.clinicname);
    }
  };
  //Done by Poovarasan used for search fecthing and filtering table data
  const onSearch = (val, pagenumber) => {
    switch (userTableNumber) {
      case 1:
        userTotalTransmitted({
          clinicId: clinicId,
          range1: fromDate,
          range2: toDate,
          norange: selectRange,
          search: val,
          page: pagenumber,
        });
        break;
      case 2:
        userWithDeviation({
          clinicId: clinicId,
          range1: fromDate,
          range2: toDate,
          norange: selectRange,
          search: val,
          page: pagenumber,
        });
        break;
      case 3:
        userWithoutDeviation({
          clinicId: clinicId,
          range1: fromDate,
          range2: toDate,
          norange: selectRange,
          search: val,
          page: pagenumber,
        });
        break;
      case 4:
        userNeverTransmitted({
          clinicId: clinicId,
          range1: fromDate,
          range2: toDate,
          norange: selectRange,
          search: val,
          page: pagenumber,
        });
        break;
      case 5:
        userMissedtherapy({
          clinicId: clinicId,
          range1: fromDate,
          range2: toDate,
          norange: 'day', //selectRange,
          search: val,
          page: pagenumber,
        });
        break;
      case 6:
        updateTherapyGoalsRedux({
          clinicId: clinicId,
          range1: fromDate,
          range2: toDate,
          norange: selectRange,
          search: val,
          page: pagenumber,
        });
        break;
      case 7:
        userADSU75({
          clinicId: clinicId,
          range1: fromDate,
          range2: toDate,
          norange: selectRange,
          search: val,
          page: pagenumber,
        });
        break;
      case 8:
        userADSU50({
          clinicId: clinicId,
          range1: fromDate,
          range2: toDate,
          norange: selectRange,
          search: val,
          page: pagenumber,
        });
        break;
      case 9:
        userADSU25({
          clinicId: clinicId,
          range1: fromDate,
          range2: toDate,
          norange: selectRange,
          search: val,
          page: pagenumber,
        });
        break;

      default:
    }
  };

  const onFilterChange = (range) => {
    switch (userTableNumber) {
      case 1:
        userTotalTransmitted({
          clinicId: clinicId,
          range1: null,
          range2: null,
          norange: range,
          search: "",
          page: 1,
        });
        break;
      case 2:
        userWithDeviation({
          clinicId: clinicId,
          range1: null,
          range2: null,
          norange: range,
          search: "",
          page: 1,
        });
        break;
      case 3:
        userWithoutDeviation({
          clinicId: clinicId,
          range1: null,
          range2: null,
          norange: range,
          search: "",
          page: 1,
        });
        break;
      case 4:
        userNeverTransmitted({
          clinicId: clinicId,
          range1: null,
          range2: null,
          norange: range,
          search: "",
          page: 1,
        });
        break;
      case 5: userMissedtherapy({
        clinicId: clinicId,
        range1: null,
        range2: null,
        norange: range,
        search: "",
        page: 1,
      });
        break;
      case 6:
        updateTherapyGoalsRedux({
          clinicId: clinicId,
          range1: null,
          range2: null,
          norange: range,
          search: "",
          page: 1,
        });
        break;
      case 7:
        userADSU75({
          clinicId: clinicId,
          range1: null,
          range2: null,
          norange: range,
          search: "",
          page: 1,
        });
        break;
      case 8:
        userADSU50({
          clinicId: clinicId,
          range1: null,
          range2: null,
          norange: range,
          search: "",
          page: 1,
        });
        break;
      case 9:
        userADSU25({
          clinicId: clinicId,
          range1: null,
          range2: null,
          norange: range,
          search: "",
          page: 1,
        });
        break;

      default:
    }
  };

  const getSelectedTabFunction = () => {
    switch (userTableNumber) {
      case 1: return userTotalTransmitted;
      case 2: return userWithDeviation;
      case 3: return userWithoutDeviation;
      case 4: return userNeverTransmitted;
      case 5: return userMissedtherapy;
      case 6: return updateTherapyGoalsRedux;
      case 7: return userADSU75;
      case 8: return userADSU50;
      case 9: return userADSU25;
      default: return undefined;
    }
  };

  const changingPage = (event, value) => {
    setPage(value);
    onSearch("", value);
  };

  const setDownload_file = () => {
    const csvData = objectToCSV(dashboard_data);

    download(csvData);
  };
  Array.prototype.remove = function(index) {
    this.splice(index, 1);
  }
  const objectToCSV = (data) => {
    //get header
    const csvRows = [];
    if (data[0]) {
      const headers = Object.keys(data[0]);
      let removeIndexArray = headers.indexOf("profile_id");
      if (removeIndexArray >= 0){
          headers.remove(removeIndexArray); 
      }
      removeIndexArray = headers.indexOf("photo_uuid");
      if (removeIndexArray >= 0){
          headers.remove(removeIndexArray); 
      }
      removeIndexArray = headers.indexOf("pair_id");
      if (removeIndexArray >= 0){
          headers.remove(removeIndexArray); 
      }
      removeIndexArray = headers.indexOf("total_count");
      if (removeIndexArray >= 0){
          headers.remove(removeIndexArray); 
      }


      
      csvRows.push(headers.join(","));

      //loops over the row
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = (" " + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }

      return csvRows.join("\n");
    }
  };

  const download = (data) => {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.setAttribute("hidden", "");
    a.setAttribute("href", url);

    a.setAttribute(
      "download",
      `patients-${nameofSelected}-${clincDashDateFrom}-${moment(currDay).format(
        " DD MMM yyyy"
      )}-${clinicName}`
    );

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  useEffect(async () => {
    // fetchclinic(); //commented by dhanush on 3-2-2022
    await loadProfile();
   await userLoginData(user.id);
   userPendingData(user);
  }, []);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    console.log('process.env',process.env)
    document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#F2F7F8" }}>
        {/* <div className="w-full lg:w-12/12 clinicDashboardHeading">
          Care Site
        </div> */}
        <div className="flex flex-row flex-wrap justify-between">
          <div className="w-10/12 lg:w-10/12 selectDiv1">
            <Select
              options={userSpClinicList.rows}
              callSelect={callSelect}
              defClinic={
                userSpClinicList &&
                  userSpClinicList.rows &&
                  userSpClinicList.rows.length
                  ? userSpClinicList.rows[0].clinicname
                  : "No clinics found"
              }
            />
          </div>

          <div className="w-2/12 lg:w-2/12 dateRangeWrapper fnt24P">
            {'Dashboard data as of: ' + moment(currDay).add(-1,'day').format(" DD MMM yyyy")}
            {/* {filterButtonIndex === 1
              ? moment(currDay).format(" DD MMM yyyy")
              : filterButtonIndex === 2
                ? `${moment(currDay)
                  .subtract(7, "days")
                  .format(" DD MMM yyyy")} - ${moment(currDay).format(
                    " DD MMM yyyy"
                  )}`
                : filterButtonIndex === 3
                  ? `${moment(currDay)
                    .subtract(1, "month")
                    .format(" DD MMM yyyy")} - ${moment(currDay).format(
                      " DD MMM yyyy"
                    )}`
                  : `${moment(fromDate).format(" DD MMM yyyy")} - ${moment(
                    toDate
                  ).format(" DD MMM yyyy")}`} */}
          </div>
        </div>

        <div
          className="flex flex-wrap"
          style={{
            marginLeft: "80px",
            marginRight: "20px",
            marginTop: "32px",
            borderBottomLeftRadius: "30px",
          }}
        >
          <div className="w-full lg:w-4/12">
            <ClinicCard
              cardContent="Therapy"
              total="Adherence below 75%"
              number={dashboardAnalytics.lessthan75percent}
              secondRow="Adherence below 50%"
              secondRowNum={dashboardAnalytics.lessthan50percent}
              thirdRow="Adherence below 25%"
              thirdRowNum={dashboardAnalytics.lessthan25percent}
              icon={seventyfivePercent}
              iconthirdRow={TwentyfivePercent}
              iconSecondRow={fiftyPercent}
              firstRowOnCLick={ab75}
              secondRowOnCLick={ab50}
              thirdRowOnCLick={ab25}
            // updateTherapyOnclick={updateTherapyGoals}
            />
          </div>

          <div className="w-full lg:w-4/12">
            <ClinicCard
              cardContent="Patients"
              total="Update Therapy Goals"
              //  number={dashboardAnalytics.invitation}
              number={dashboardAnalytics.updatedgoals}
              secondRow="Missed 3 or more days"
              secondRowNum={dashboardAnalytics.missedtherapy}
              thirdRow="Never Transmitted"
              thirdRowNum={dashboardAnalytics.total_count}
              icon={FifthIcon}
              iconSecondRow={SixthIcon}
              iconthirdRow={SeventhIcon}
              firstRowOnCLick={updateTherapyGoals}
              //firstRowOnCLick={() => { history.push(user.role === "clinicadmin" ? '/app/clinicadmin': '/app/cliniciantable') }}
              secondRowOnCLick={missedtherapygoals}
              thirdRowOnCLick={neverTransmitted}
            />
          </div>

          <div className="w-full lg:w-4/12">
            <ClinicCard
              cardContent="Transmissions"
              total="Total"
              number={dashboardAnalytics.newtransmitted}
              secondRow="Without Deviations"
              secondRowNum={dashboardAnalytics.withoutdeviation}
              thirdRow="With Deviations"
              thirdRowNum={dashboardAnalytics.withdeviation}
              icon={IconSquare}
              iconSecondRow={WD}
              iconthirdRow={EightIcon}
              firstRowOnCLick={newTransmission}
              secondRowOnCLick={withoutDeviation}
              thirdRowOnCLick={withDeviation}
            />
          </div>
        </div>

        {isSelected === "" ? null : (
          <div>
            <SeparatorTopSection />
            {isSelected !== "" &&
              dashboard_data &&
              !dashboard_data.length &&
              dashboard_totalCount === null ? (
              <div
                style={{
                  display: "flex",
                  backgroundColor: "white",
                  height: "25px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingIcon />
              </div>
            ) : null}
            <div
              className="flex flex-wrap justify-between "
              style={{ backgroundColor: "white" }}
            >
              <div className="w-8/12 lg : w-8/12 tableDivBeforeAnouncement fnt32P">
                {isSelected}
              </div>
            </div>
          </div>
        )}

        {isSelected === "" ? null : isSelected !== "Invitations" ? (
          <>
            <div
              className={classes.root}
              style={{ overflow: "hidden", backgroundColor: "white" }}
            >
              <Grid item xs={12}>
                <div className="flex flex-row flex-wrap justify-between">
                  <div className="w-4/12 lg:w-4/12 ClinicAdminTableSearchBar">
                    <div
                      className="add-icon"
                      style={{ backgroundColor: "#F2F7F8" }}
                    >
                      <i
                        className="fas fa-search fa-lg"
                        style={{ paddingTop: "12px" }}
                      ></i>
                      <input
                        type="text"
                        placeholder="Search"
                        value={searchInputValue}
                        className="report_search fnt24P"
                        onChange={(e) => {
                          setSearchInputValue(e.target.value);
                          onSearch(e.target.value);
                        }}
                        // onKeyDown={onKeyDownSearchValues}
                        style={{ backgroundColor: "#F2F7F8" }}
                      />
                    </div>
                  </div>
                  {array1.includes(isSelected) ? null :
                    <div
                      className="flex fldateFuncex-row flex-wrap"
                      style={{ marginTop: "24px", marginRight: "40px" }}
                    >
                      <div className="w-2/12 lg:w-2/12">
                        <Button
                          className={classes.buttonsOfDate}
                          style={{
                            backgroundColor:
                              filterButtonIndex == 1 ? "white" : "#F2F4F8",
                          }}
                          onClick={() => {
                            dateFunc();
                          }}
                        >
                          Day
                        </Button>
                      </div>
                      <div className="w-2/12 lg:w-2/12 fnt22P">
                        <Button
                          className={classes.buttonsOfDate}
                          style={{
                            backgroundColor:
                              filterButtonIndex == 2 ? "white" : "#F2F4F8",
                          }}
                          onClick={() => {
                            weekFunc();
                          }}
                        >
                          Week
                        </Button>
                      </div>
                      <div className="w-2/12 lg:w-2/12 fnt22P">
                        <Button
                          className={classes.buttonsOfDate}
                          style={{
                            backgroundColor:
                              filterButtonIndex == 3 ? "white" : "#F2F4F8",
                          }}
                          onClick={() => {
                            monthFunc();
                          }}
                        >
                          Month
                        </Button>
                      </div>
                      <div className="w-2/12 lg:w-2/12 fnt22P">
                        <div>
                          <Button
                            aria-describedby={id}
                            variant="contained"
                            className={classes.buttonsOfDate}
                            style={{
                              backgroundColor:
                                filterButtonIndex == 4 ? "white" : "#F2F4F8",
                              marginBptton: "20px",
                            }}
                            onClick={handleClick}
                          >
                            Date Range
                          </Button>
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Typography sx={{ p: 1 }}>
                              <div className="dataRangeFormContainer">
                                <div className="todataRange fnt22P">
                                  <label>From :</label>
                                </div>
                                <input
                                  type="date"
                                  className="inputfeildsforallclinicProfile fnt22P"
                                  style={{ backgroundColor: "transparent" }}
                                  defaultValue={fromDate}
                                  max={maxfortrendfrom}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    setFromDate(e.target.value);
                                    setRelectRange(null);
                                  }}
                                />
                                <div className="fromdataRange fnt22P">
                                  <label>To :</label>
                                </div>
                                <input
                                  className="inputfeildsforallclinicProfile fnt22P"
                                  type="date"
                                  defaultValue={toDate}
                                  min={fromDate?fromDate:null}
                                  max={defaultValue}
                                  // value={toDate}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    setToDate(e.target.value);
                                    setRelectRange(null);
                                  }}
                                  style={{ backgroundColor: "transparent" }}
                                />
                                {/*  {toDate == "" && fromDate == "" ?
                              <div className="dataRangeButton fnt22P">
                                <Button style={{ width: '100%' }}>
                                  <span className="fnt22P">Submit</span>
                                </Button>
                              </div>
                              : */}
                                {fromDate === null || toDate === null ? (
                                  <div className="dataRangeButton fnt22P">
                                    <Button
                                      disabled={true}
                                      onClick={() => {
                                        // console.log("onclicksubmit");
                                        setFilterButtonIndex(4);
                                        handleClose();
                                        fetchDashboardDetails({
                                          clinicId: clinicId,
                                          range1: fromDate,
                                          range2: toDate,
                                          norange: null,
                                        });
                                        onSearch();
                                        setPage(1);
                                        setSearchInputValue("");
                                      }}
                                      style={{ width: "100%" }}
                                    >
                                      <span className="fnt22P">Submit</span>
                                    </Button>
                                  </div>
                                ) : (
                                  <div className="dataRangeButton fnt22P">
                                    <Button
                                      disabled={false}
                                      onClick={() => {
                                        // console.log("onclicksubmit");
                                        setFilterButtonIndex(4);
                                        handleClose();
                                        fetchDashboardDetails({
                                          clinicId: clinicId,
                                          range1: fromDate,
                                          range2: toDate,
                                          norange: null,
                                        });
                                        onSearch();
                                        setPage(1);
                                        setSearchInputValue("");
                                      }}
                                      style={{ width: "100%" }}
                                    >
                                      <span className="fnt22P">Submit</span>
                                    </Button>
                                  </div>
                                )}

                                {/*   }
                               */}
                              </div>
                            </Typography>
                          </Popover>
                        </div>
                      </div>
                    </div>
                    }
                </div>

                {/* Table */}
                 {array2.includes(isSelected) ? 
                  <div className="dateRangeWrapperAtTable fnt24P">
                  
                     {`${moment(currDay).add(-1,'day').format(" DD MMM yyyy")}`}
                     </div>
                  
                 : isSelected=='Missed 3 or more days'||'Patients who have never transmitted'?null:
                 <div className="dateRangeWrapperAtTable fnt24P">
                    {filterButtonIndex === 1
                      ? moment(currDay).format(" DD MMM yyyy")
                      : filterButtonIndex === 2
                        ? `${moment(currDay)
                          .subtract(7, "days")
                          .format(" DD MMM yyyy")} - ${moment(currDay).format(
                            " DD MMM yyyy"
                          )}`
                        : filterButtonIndex === 3
                          ? `${moment(currDay)
                            .subtract(1, "month")
                            .format(" DD MMM yyyy")} - ${moment(currDay).format(
                              " DD MMM yyyy"
                            )}`
                          : `${moment(fromDate).format(" DD MMM yyyy")} - ${moment(
                            toDate
                          ).format(" DD MMM yyyy")}`}
                  </div> }
                <div ref={scrollRef}>
                  <div style={{ marginLeft: "80px", marginRight: "32px" }}>
                    <ClinicadminTabelSingleComponent
                      key={childComponentKey}
                      isSelected={isSelected}
                      dashboard_data={dashboard_data}
                      clinicId={clinicId}
                      clear_patient_profile={clear_patient_profile}
                      page = {page}
                      getSelectedTabFunction={getSelectedTabFunction()}
                      tabNumber = {userTableNumber}
                      fromDate={fromDate}
                      toDate={toDate}
                      selectRange={selectRange}
                      val={searchInputValue}
                    // make_dashboard_data_empty={make_dashboard_data_empty}
                    />
                  </div>
                  {dashboard_data.length > 0 ? (
                    <div className="totalCountsAtPatientProfileTable fnt24P">
                      {page * limit >= dashboard_totalCount
                        ? dashboard_totalCount
                        : page * limit}
                      /{dashboard_totalCount}
                    </div>
                  ) : (
                    <div className="totalCountsAtPatientProfileTable fnt24P">
                      {" "}
                      0/0{" "}
                    </div>
                  )}

                  {dashboard_data.length > 0 ? (
                    <div className="PaginationWrapper">
                      <div
                        className="downloadwrapper fnt24P"
                      >
                        <div style={{ paddingTop: "8px" }}> Download </div>
                        <img src={excelIcon} className="excelIconWrapper" onClick={setDownload_file} style={{cursor:'pointer'}}></img>
                      </div>
                      {dashboard_totalCount ? (
                        <div>
                          <Pagination
                            count={Math.ceil(dashboard_totalCount / limit)}
                            page={page}
                            onChange={changingPage}
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </Grid>
            </div>
          </>
        ) : null}

        {/* Footer */}

        {isSelected === "" ? (
          <div>
            <SeparatorTopSection />
            <div style={{ backgroundColor: "white" }}>
              <div className="flex flex-wrap anouncenementHeadingWrapper">
                <div className="w-2/12 lg:w-2/12">
                  <img src={Bulb} alt=" " className="anouncementBulb" />
                </div>

                <div className="w-10/12 lg:w-10/12 anouncenement fnt32P">
                  Announcement
                </div>
              </div>

              <div className="gradientSeparatorWrapper">
                <div
                  className="flex flex-wrap "
                  style={{
                    marginLeft: "80px",
                    marginRight: "38px",
                    marginTop: "48px",
                    marginBottom: "32px",
                  }}
                >
                  <div className="w-full lg:w-4/12">
                    <AnnouncementCards description="AnnouncementOne" />
                  </div>
                  <div className="w-full lg:w-4/12">
                    <AnnouncementCards description="AnnouncementTwo" />
                  </div>
                  <div className="w-full lg:w-4/12">
                    <AnnouncementCards description="AnnouncementThree" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <SeparatorBottomSection />
            <div style={{ backgroundColor: "#F2F7F8" }}>
              <div className="flex flex-wrap anouncenementHeadingWrapper">
                <div className="w-2/12 lg:w-2/12">
                  <img src={Bulb} alt=" " className="anouncementBulb" />
                </div>

                <div className="w-10/12 lg:w-10/12 anouncenement fnt32P">
                  Announcement
                </div>
              </div>

              <div
                className="flex flex-wrap"
                style={{
                  marginLeft: "80px",
                  marginRight: "38px",
                  marginTop: "48px",
                  marginBottom: "32px",
                }}
              >
                <div className="w-full lg:w-4/12">
                  <AnnouncementCards description="AnnouncementOne" />
                </div>
                <div className="w-full lg:w-4/12">
                  <AnnouncementCards description="AnnouncementTwo" />
                </div>
                <div className="w-full lg:w-4/12">
                  <AnnouncementCards description="AnnouncementThree" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    invitationList: store.invitation.invitationList,
    user: store.user.user,
    patientList: store.invitation.patientList,
    adminTable: store.user.adminTable,
    dashboardAnalytics: store.user.dashboard_val,
    dashboard_totalCount: store.user.dashboard_totalCount,
    dashboard_data: store.user.dashboard_data,
    addUserInvitation: store.invitation.addUserInvitation,
    flagInput: store.invitation.flagInput,
    userSpClinicList: store.user.userLogIn,
    userClinicList: store.user.userLogIn.length
      ? []
      : "rows" in store.user.userLogIn
        ? store.user.userLogIn.rows
        : [],
    pendingInvitation: store.user.pendingInvitation,
    allChats: store.quickblox.allChats,
    user: store.user.user,
  };
};

export default connect(mapStateToProps, {
  // fetchclinic,
  fetchInvitation,
  addClinicList,
  openInvitePopup,
  fetchClinicProfile,
  openFilterPopup,
  userLoginData,
  pageHeading,
  loadProfile,
  fetchDashboardDetails,
  userTotalTransmitted,
  userWithDeviation,
  userWithoutDeviation,
  userNeverTransmitted,
  userADSU75,
  userADSU50,
  userADSU25,
  userPendingData,
  logIn,
  updateTherapyGoalsRedux,
  make_dashboard_data_empty,
  clear_patient_profile,
  userMissedtherapy
})(ClinicadminDashboard);
