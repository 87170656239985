import apiAbmBlaze, {
  GET_INVITATION,
  ADD_INVITATION_ITEM,
  GET_CLINIC,
  GET_USER_CLINIC_DETAILS,
  ADD_CLINIC_LIST,
  UPDATE_CLINIC_LIST,
  UPDATE_EXSITING_USER,
  VERIFY_USER_PHONE_NUMBER,
  RESEND_OTP_NEWUSER,
  CONFIRM_NEWUSER_PHONENUMBER_OTP,
  UPDATE_PROFILE_DATA,
  CHECK_EMAIL,
  USER_PROFILE_UPLOAD,
  CLINIC_PROFILE_PICTURE_UPDATE,
  SEND_SHARING_REQ,
  ACCEPT_SHARING_REQ,
  EXITING_ACCOUNT_NUMBER,
  RESEND_INVITATION,
  ACTIVE_CARE_SITE_ADMIN,
  INVITATION_SEARCH,
  ACTIVE_CLINIC_SITE,
  REVOKE_USER,
  REVOKE_USERS,
  ACCEPT_INVITATION,
  REJECT_INVITATION,
  INVITATION_PAGE_LIST,
  INACTIVE_PATIENT,
  GET_PATIENT_STATUS,
  GET_PROFILE_FOR_UPDATE,
  SUPERADMIN_DASHBOARD_ENROLLED_LIST_COUNT,
  SUPERADMIN_DASHBOARD_CARESITE_LIST,
  GET_SENT_RECEIVED_INVITATION_COUNT,
  RESEND_REJECTED_INVITATION
} from "../../../api";
import {
  OTP_MATCHES,
  GET_ALL_INVITATION_LIST,
  GET_EACH_ITEM,
  GET_ALL_CLINIC_LIST,
  LOGIN_IN,
  ADDING_INVITAION_OR_CLINICNAME,
  RESET,
  INVITE_POP_UP_OPEN,
  UPDATE_CLINIC_PROFILE,
  FILTER_OPEN_POPUP,
  LOGIN_USER_INCOGNO,
  MSG,
  PROFILE_ID,
  CLINIC_USER_UPDATE_ALERT_MSG,
  CLINIC_USER_UPDATE_ERROR_MSG,
  ADDING_CLINIC_ALERT_MSG,
  REGISTER_ALERT_MSG,
  REGISTER_ERROR_MSG,
  VERIFYUSER_GET_OTP,
  NEWUSER_OTP_DOESNOT_MATCHES,
  NEWUSER_OTP_MATCHES,
  OTP_RESEND_NEWUSER,
  VERIFY_NEW_USER_ERROR_MSG,
  PROFILE_UPDATE_ALERT_MSG,
  PROFILE_UPDATE_ERROR_MSG,
  ADDING_CLINIC_ERROR_MSG,
  CHECK_ENTERED_EMAIL,
  USER_PROFILE_UPDATED_RESULT,
  USER_PROFILE_UPLOAD_RESULT,
  OTHERUSER_PROFILE_UPLOAD_RESULT,
  CLINIC_PROFILE_UPLOAD_PICTURE,
  ADDING_CLINIC,
  EXITING_ACCOUNTNUMBER,

  ACTIVE_CLINIC_ADMIN,
  ACTIVE_CLINIC,
  SEARCH_INVITATION,
  RESEND_INVITE_RES,
  ACCEPT_INVITE,
  REJECT_INVITE,
  PAGINATED_INVITATION_LIST,
  INACTIVE_PATIENT_ALERT,
  GET_PATIENT_LIST,
  GET_PATIENT_ID,
  ACTIVE_INACTIVE_RES_STATUS_PAT_PROF,
  IS_SESSION_EXPIRED,
  SUPERADMIN_DASHBOARD_COUNT,
  PAGINATED_SUPERADMIN_DASHBOARD_CARESITE_LIST,
  TOTAL_SENT_RECEIVED_INVITATION_COUNT,
  INVITATION_FLAG_VALUE,
  IF_DONT_HAVE_ANYC_CLINIC,
  OTP_EXPIRE_NEWUSER
} from "../../action.list";

import { getCareSiteDetailsById } from "../../careSite/caresite.action";

import Alert from "@material-ui/lab/Alert";
import { invitationLink } from "../../../constant/constantRoutes";
import React from "react";
import store from "../../../store";
import { userPendingData } from "../../user/user.action";
import { base64Encode } from "../../../utils";

export const fetchInvitation = (values) => async (dispatch) => {
  apiAbmBlaze
    .get(`${GET_INVITATION}?role=${values}`)
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: GET_ALL_INVITATION_LIST,
          payload: {
            invitationList: res.data.rows,
          },
        });
      }
    })
    .catch(async (error) => {
      console.log("ERROR", error);
      let err = error.toJSON();
      if (err.status === 401) {
        await dispatch({
          type: IS_SESSION_EXPIRED,
          payload: { isSessionExpired: true },
        });
      }
    });
};

//pagination, search, display for invitation table
export const invitationPagination = (val) => async (dispatch) => {
  apiAbmBlaze
    .post(INVITATION_PAGE_LIST, {
      searchquery: val.search,
      id: val.id,
      invitationType: val.sentViewTable,
      role: val.userRole,
      index: val.page,
      property:val.property,
      order:val.order
    })
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: PAGINATED_INVITATION_LIST,
          payload: {
            paginatedInvitationList: res.data.rows,
          },
        });
        dispatch(getSentReceivedInvitationCount())
        dispatch(
          userPendingData({ role: val.userRole ? val.userRole.toLowerCase() : "" })
        );
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
    });
};

export const addInvitationItem = (val) => async (dispatch) => {
  const state = store.getState();
  await dispatch({
    type: GET_EACH_ITEM,
    payload: {
      invitationFlag: false,
    },
  });
  apiAbmBlaze
    .post(ADD_INVITATION_ITEM, {
      email: val.email ? val.email.toLowerCase() : "",
      role: val.role ? val.role.toLowerCase() : "",
      phone: "null",
      clinicId: val.clinicId ? val.clinicId : "",
      status: "pending",
      expirydate: val.expirydate,
    })
    .then(async (res) => {
      if (res.data) {
        dispatch(fetchInvitation(state.user.user.role));
        if (res.data.message && res.data.message === "User already exist") {
          await dispatch({
            type: GET_EACH_ITEM,
            payload: {
              inviteItem: res.data,
              invitationFlag: true,
              invitationFlagUserAlreadyExist: true,
            },
          });
        } else {
          await dispatch({
            type: GET_EACH_ITEM,
            payload: {
              inviteItem: res.data,
              invitationFlag: true,
              invitationFlagUserAlreadyExist: false,
            },
          });
        }


        //token used instead of encoded
        if (res.data.token) {
          console.log(
            "Registration Link",
            invitationLink + "/signup?param1=" + res.data.token
          );
        } else {
          console.log(
            "Registration Link ==>", "User is already part of arc portal. Intimation has been sent to user email to accept the invitation from Received invitation list "
          );
        }

        dispatch(
          invitationPagination({
            search: "",
            id: val.profile_id ? val.profile_id : "",
            sentViewTable: "sentInvitation",
            userRole: val.role ? val.role.toLowerCase() : "",
            page: 1,
          })
        );
        if (res.data && res.data.user && res.data.user.email)
          dispatch(reguestSharing(res.data.user.email));
        dispatch(reSet());
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
    });
};

export const closeInvitationSuccesPopup = () => async (dispatch) => {
  await dispatch({
    type: GET_EACH_ITEM,
    payload: {
      invitationFlag: false,
      invitationFlagUserAlreadyExist: false,
    },
  });
};

export const resendInvitationLink = (val) => async (dispatch) => {
  if (val) {
    apiAbmBlaze
      .post(RESEND_INVITATION, {
        email: val.email ? val.email.toLowerCase() : "",
        role: val.role ? val.role.toLowerCase() : "",
        clinicId: val.clinic_id ? val.clinic_id : "",
        clinicname: val.clinicname ? val.clinicname.toLowerCase() : "",
        status: "pending",
        profileId: val.profile_id ? val.profile_id : "",
        clinicUserId: val.clinic_user_id ? val.clinic_user_id : "",
        expirydate: val.expirydate,
      })
      .then(async (res) => {
        if (res.data) {
          await dispatch({
            type: RESEND_INVITE_RES,
            payload: {
              resendInviteMsg: res.data.message,
            },
          });
          console.log(
            "Resend Invitation Link",
            invitationLink + "/signup?param1=" + res.data.token
          );
        }
      });
  }
};

export const revokeUser = (val) => async (dispatch) => {
  if (val) {

    let _currentTable = "sentInvitation";
    if (val.from && val.from === "sentInvitation") {
      _currentTable = "sentInvitation";
    } else if (val.status === "accepted" || val.status === "blocked") {
      _currentTable = "receivedInvitation";
    }
    apiAbmBlaze
      .post(REVOKE_USER, {
        status: val.status ? val.status.toLowerCase() : "",
        clinicUserId: val.clinic_user_id ? val.clinic_user_id : "",
        profile_id: val.profile_id ? val.profile_id : "",
      })
      .then(async (res) => {
        if (res.data) {

          await dispatch({
            type: IF_DONT_HAVE_ANYC_CLINIC,
            payload: {
              if_dont_have_any_clinic: res.data.command,
            },
          });
        }

        if (res.status == 200) {
          dispatch(
            invitationPagination({
              search: "",
              id: val.profile_id ? val.profile_id : "",
              sentViewTable: _currentTable,
              userRole: val.role ? val.role.toLowerCase() : "",
              page: 1,
            })
          );
        }
      });
  }
};

//for recevied invitation actions
export const invitationAction = (val) => async (dispatch) => {
  // console.log("inside invitationAction", val);
  await dispatch({
    type: INVITATION_FLAG_VALUE,
    payload: {
      invitationFlagValue: val,
    },
  });
}

export const revokeUsers = (val) => async (dispatch) => {
  if (val) {
    apiAbmBlaze
      .post(REVOKE_USERS, {
        email: val.email ? val.email.toLowerCase() : "",
        role: val.role ? val.role.toLowerCase() : "",
        clinicId: val.clinic_id ? val.clinic_id : "",
        clinicname: val.clinicname ? val.clinicname.toLowerCase() : "",
        status: val.status ? val.status.toLowerCase() : "",
        profileId: val.profile_id ? val.profile_id : "",
        clinicUserId: val.clinicUserId ? val.clinicUserId : "",
      })
      .then(async (res) => {
        if (res.status == 200) {
        }
      });
  }
};
export const resendinLinkClinicName = (val) => async (dispatch) => {
  if (val) {
    prompt(
      "please press ctrl+c to copy the text below",

      invitationLink + "/signup?param1=" + base64Encode(JSON.stringify(val))
    );
  }
};

export const openInvitePopup = (val) => async (dispatch) => {
  await dispatch({
    type: INVITE_POP_UP_OPEN,
    payload: {
      openPopUp: val,
    },
  });
};

export const updateInvitationItem = (val) => async (dispatch) => {
  apiAbmBlaze
    .post(UPDATE_EXSITING_USER, {
      username: val.username ? val.username : "",
      password: val.password ? val.password : "",
      firstname: val.firstname ? val.firstname : "",
      lastname: val.lastname ? val.lastname : "",
      phone: val.phonenumber ? val.phonenumber : "",
      email: val.email ? val.email : "",
      role: val.role ? val.role : "",
      clinic_id: val.clinic_id ? val.clinic_id : "",
      profile_id: val.profileId ? val.profileId : "",
      status: val.status ? val.status : ""
    })
    .then(async (res) => {
      if (res.data.profiledata[0].id) {
        dispatch(
          acceptSharing({
            id: res.data.profiledata[0].id,
            requestSentFrom: val.requestSentFrom,
          })
        );
      }
      if (res.status === 200) {
        dispatch({
          type: REGISTER_ALERT_MSG,
          payload: { registerAlertMessage: "redirect" },
        });
      } else if (res.status === 400) {
        dispatch({
          type: REGISTER_ERROR_MSG,
          payload: { registerErrorMessage: "error400" },
        });
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
    });
};

export const verifyuserphonenumber = (val) => async (dispatch) => {
  console.log('userphonenumber',val)
  if(val.enteredphonenumber){
    await dispatch({
      type: VERIFY_NEW_USER_ERROR_MSG,
      payload: { VerifyNewuserErrorMsg: "" },
    });
  apiAbmBlaze
    .post(VERIFY_USER_PHONE_NUMBER, {
      enteredphonenumber:val.enteredphonenumber
    })
    .then(async (res) => {
      console.log('response',res)
      if (res.status === 200) {
        await dispatch({
          type: VERIFYUSER_GET_OTP,
          payload: {
            verifynewuserotpSent: res.data.verifynewuserotpSent,
            verifynewuserotpToken: res.data.token,
            verifynewuserOtp: res.data.otp,
          },
        });
        await dispatch({
          type: VERIFY_NEW_USER_ERROR_MSG,
          payload: { VerifyNewuserErrorMsg: "" },
        });
      }
      if (res.status === 209) {
        console.log('this',res.data.message)
        await dispatch({
          type: VERIFYUSER_GET_OTP,
          payload: {
            verifynewuserotpSent: res.data.verifynewuserotpSent,
            
          },
        });
        await dispatch({
          type: VERIFY_NEW_USER_ERROR_MSG,
          payload: { VerifyNewuserErrorMsg: res.status },
        });
      }
      if (res.status === 222) {
        console.log('this1',res.data.message)
        await dispatch({
          type: VERIFYUSER_GET_OTP,
          payload: {
            verifynewuserotpSent:false,
            
          },
        });
        await dispatch({
          type: VERIFY_NEW_USER_ERROR_MSG,
          payload: { VerifyNewuserErrorMsg: res.status },
        });
      }
    })
    .catch(async (error) => {
      if (error.response) {
        if (error.response.status === 400) {
          // console.log('registered',error.toJSON())
          await dispatch({
            type: VERIFY_NEW_USER_ERROR_MSG,
            payload: { VerifyNewuserErrorMsg: error.response.message },
          });
        }}
      })
    }
  };

  export const verifynewuserconfirmOTP = (val) => async (dispatch) => {
    const { code, token } = val;
    dispatch({
      type: NEWUSER_OTP_DOESNOT_MATCHES,
      payload: {
        newuserisNotMatch: false
      },
    });
    dispatch({
      type: OTP_EXPIRE_NEWUSER,
      payload: {
        otpexpirenewuser: false
      },
    });
    apiAbmBlaze
      .post(CONFIRM_NEWUSER_PHONENUMBER_OTP, {
        code,
        token,
        
      })
      .then(async (res) => {
        
        if (res.status === 200) {
          await dispatch({
            type: NEWUSER_OTP_MATCHES,
            payload: {
              newuserisMatch: res.data.signupisMatch,
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch({
            type: OTP_EXPIRE_NEWUSER,
            payload: {
              otpexpirenewuser: error.response.status
            },
          });
        }
        if (error.response.status === 400) {
          dispatch({
            type: NEWUSER_OTP_DOESNOT_MATCHES,
            payload: {
              newuserisNotMatch: error.response.status
            },
          });
        }
        console.log('Error----', error.response.status);
      });
  };


  export const resend_otp_newuser = (val) => async (dispatch) => {
    var enteredphonenumber = val
    if(enteredphonenumber) {
      await dispatch({
        type: VERIFY_NEW_USER_ERROR_MSG,
        payload: { VerifyNewuserErrorMsg: "" },
      });
    apiAbmBlaze
      .post(RESEND_OTP_NEWUSER, {
        enteredphonenumber
      })
      .then(async (res) => {
        if (res.data) {
          await dispatch({
            type: OTP_RESEND_NEWUSER,
            payload: {
              resendotpnewuser: "resendOtpnewuser"
            }
          });
  
          await dispatch({
            type: VERIFYUSER_GET_OTP,
            payload: {
              verifynewuserotpSent: res.data.verifynewuserotpSent,
              verifynewuserotpToken: res.data.token,
              verifynewuserOtp: res.data.otp,
            },
          });
          await dispatch({
            type: VERIFY_NEW_USER_ERROR_MSG,
            payload: { VerifyNewuserErrorMsg: "" },
          });
        
        if (res.status === 209) {
          console.log('this',res.data.message)
          await dispatch({
            type: VERIFYUSER_GET_OTP,
            payload: {
              verifynewuserotpSent: res.data.verifynewuserotpSent,
              
            },
          });
          await dispatch({
            type: VERIFY_NEW_USER_ERROR_MSG,
            payload: { VerifyNewuserErrorMsg: res.status },
          });
        }
        if (res.status === 222) {
          console.log('this1',res.data.message)
          await dispatch({
            type: VERIFYUSER_GET_OTP,
            payload: {
              verifynewuserotpSent:false,
              
            },
          });
          await dispatch({
            type: VERIFY_NEW_USER_ERROR_MSG,
            payload: { VerifyNewuserErrorMsg: res.status },
          });
        }
        }
      })
      .catch(async(error) => {
        if (error.response.status === 400) {
          await dispatch({
            type: VERIFY_NEW_USER_ERROR_MSG,
            payload: { VerifyNewuserErrorMsg: error.response.message },
          });
      }
    })
  }};

export const updateInvitationClinicName = (val) => async (dispatch) => {
  if (val.id) {
    apiAbmBlaze
      .put(`${ADD_INVITATION_ITEM}/${val.id}`, val)
      .then(async (res) => {
        if (res.status === 200) {
          dispatch(
            AlertMsg(
              "User is already part of application hence after login  would appear with the invitation to the hospital !"
            )
          );
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }
};

export const updateInvitationList = (val) => async (dispatch) => {
  if (val.id) {
    apiAbmBlaze
      .put(`${ADD_INVITATION_ITEM}/${val.id}`, val)
      .then(async (res) => {
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }
};

export const logIn = (user) => async (dispatch) => {
  localStorage.setItem("user", JSON.stringify(user));
  await dispatch({
    type: LOGIN_IN,
    payload: {
      user: user,
    },
  });
};

//User profile update
export const updateProfile =
  (val, id, details_doesnt_exist, phonenumber) => async (dispatch) => {
    // console.log("from action", val)
    if (id) {

      apiAbmBlaze
        .post(UPDATE_PROFILE_DATA, {
          username: val.values.username
            ? val.values.username
            : details_doesnt_exist.user_name,
          firstname: val.values.first_name
            ? val.values.first_name
            : details_doesnt_exist.first_name,
          lastName: val.values.last_name
            ? val.values.last_name
            : details_doesnt_exist.last_name,
          Addressline1: val.values.Addressline1
            ? val.values.Addressline1
            : details_doesnt_exist.address.Addressline1,
          Addressline2: val.values.Addressline2
            ? val.values.Addressline2
            : details_doesnt_exist.address.Addressline2,
          Zip: val.values.Zip ? val.values.Zip : details_doesnt_exist.address.Zip,
          country: val.country
            ? val.country : details_doesnt_exist.address.country,

          city: val.values.city ? val.values.city : details_doesnt_exist.address.city,
          state: val.state ? val.state : details_doesnt_exist.address.state,
          id: id,
        })
        .then(async (res) => {
          // console.log("UserProfileUpdated+++", res.data)
          await dispatch({
            type: USER_PROFILE_UPDATED_RESULT,
            payload: { UserProfileUpdatedData: res.data },
          });
          await dispatch({
            type: USER_PROFILE_UPLOAD_RESULT,
            payload: {
              UserProfileUpdated: res.data.UserprofileUpdate,
            },
          });
          if (res.status == 200) {
            // console.log("response.status", res.status)
            await dispatch({
              type: PROFILE_UPDATE_ALERT_MSG,
              payload: { profileUpdateAlertMessage: "success" },
            });
          }
        })

        .catch((error) => {
          if (error) {
            console.log("errorfrom ++", error)
            dispatch({
              type: PROFILE_UPDATE_ERROR_MSG,
              payload: { profileUpdateErrorMessage: "error400" },
            });
          }
        });
    } else {
      console.log("Missing inputs");
    }
  };

export const updateProfilePic = (val, id) => async (dispatch) => {
  if (id) {
    apiAbmBlaze
      .put(`${ADD_INVITATION_ITEM}/${id}`, val)
      .then(async (res) => {
        if (res.data) {
          dispatch(logIn(res.data));
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }
};

export const updateExistingCLinicList = (val) => async (dispatch) => {
  if (val.id) {
    apiAbmBlaze
      .put(`${ADD_INVITATION_ITEM}/${val.id}`, val)
      .then(async (res) => {
        if (res.data) {
          dispatch(reSet());
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }
};
export const loginUser = (val) => async (dispatch) => {
  if (val.email) {
    apiAbmBlaze
      .get(
        `${ADD_INVITATION_ITEM}?email=${val.email}&&status=${val.status
        }&&password=${base64Encode(val.password)}`
      )
      .then(async (res) => {
        if (res.data.length > 0) {
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }
};
export const addClinicList = (val) => async (dispatch) => {
  // console.log("val", val, val.country, val.state)
  apiAbmBlaze
    .post(ADD_CLINIC_LIST, {
      clinicname: val.clinicname ? val.clinicname : "",
      email: val.email ? val.email : "",
      accountnumber: val.accountnumber ? val.accountnumber : "",
      phonenumber: val.phonenumber ? val.phonenumber : "",
      addressline1: val.addressline1 ? val.addressline1 : "",
      addressline2: val.addressline2 ? val.addressline2 : "",
      city: val.city ? val.city : "",
      state: val.state ? val.state: "",
      country: val.country ? val.country : "",
      postal: val.postal ? val.postal : "",
      website: val.website ? val.website : "",
      status: "active",
    })
    .then(async (res) => {
      if (res.status === 200) {
        if (res.data.message === "Successfully created new Clinic") {
          dispatch({
            type: ADDING_CLINIC_ALERT_MSG,

            payload: {
              addingClinicSuccessAlert: "success",
            },
          });
        } 
        else if (res.data.message === "User already exist") {
          console.log('coming here bruh',res)
          dispatch({
            type: ADDING_CLINIC_ALERT_MSG,

            payload: {
              addingClinicexistingAlert: "success",
              existingclinic: res.data.user
            },
          });
        }  
        else {
          dispatch({
            type: ADDING_CLINIC,
            payload: {
              addingClinicAlert: "create",
            },
          });
        }
        dispatch(fetchEnrolledListCount());
        dispatch(superadminDashboardPagination({ page: 1, search: "" }));
      }
    })
    .catch((error) => {
      if (error) {
        dispatch({
          type: ADDING_CLINIC_ERROR_MSG,
          payload: {
            addingclinicerrormsg: true,
          },
        });
      }
    });
};
export const againappearpopup = () => async (dispatch) => {
  dispatch({
    type: ADDING_CLINIC_ALERT_MSG,
    payload: {
      addingClinicSuccessAlert: "",
      addingClinicexistingAlert:"",
      existingclinic:null

    },
  });
  dispatch({
    type: ADDING_CLINIC_ERROR_MSG,
    payload: {
      addingclinicerrormsg: "",
    },
  });
  dispatch({
    type: PROFILE_UPDATE_ALERT_MSG,
    payload: {
      profileUpdateAlertMessage: false,
    },
  });
  dispatch({
    type: CLINIC_USER_UPDATE_ERROR_MSG,
    payload: {
      clinicuserErrorMsg: false,
    },
  });
};

export const fetchclinic = () => async (dispatch) => {
  apiAbmBlaze
    .get(GET_CLINIC)
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: GET_ALL_CLINIC_LIST,
          payload: {
            allcliniclist: res.data,
          },
        });
      }
    })
    .catch(async (error) => {
      console.log("ERROR", error);
      let err = error.toJSON();
      if (err.status === 401) {
        await dispatch({
          type: IS_SESSION_EXPIRED,
          payload: { isSessionExpired: true },
        });
      }
    });
};

export const profile_id = (val) => async (dispatch) => {
  if (val) {
    let id = val;
    apiAbmBlaze
      .post(GET_USER_CLINIC_DETAILS, {
        id: id,
      })
      .then(async (res) => {
        if (res.status === 200) {
          await dispatch({
            type: PROFILE_ID,
            payload: {
              personalClinic: res.data,
            },
          });
        }
      })

      .catch((error) => {
        console.log("ERROR", error);
      });
  }
};

export const addingClinicName = (val) => async (dispatch) => {
  apiAbmBlaze
    .get(`${ADD_INVITATION_ITEM}?email=${val.email}`)
    .then(async (res) => {
      await dispatch({
        type: ADDING_INVITAION_OR_CLINICNAME,
        payload: {
          addUserInvitation: res.data,
          flagInput: true,
        },
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
    });
};

export const reSet = () => async (dispatch) => {
  await dispatch({
    type: RESET,
    payload: {
      addUserInvitation: [],
      flagInput: false,
    },
  });
};
export const acceptinLinkClinicName = (val) => async (dispatch) => {
  alert(
    "User is already part of application hence after login  would appear with the invitation to the hospital !"

  );
  dispatch(reSet());
};

export const fetchClinicProfile = (val) => async (dispatch) => {
  await dispatch({
    type: UPDATE_CLINIC_PROFILE,
    payload: {
      clinicProfileUpdate: val,
    },
  });
};

export const userloginIncog = (val) => async (dispatch) => {
  await dispatch({
    type: LOGIN_USER_INCOGNO,
    payload: {
      loginIncogno: val,
    },
  });
};


export const updateClinicUserSnckMsg = () => async (dispatch) => {
  dispatch({
    type: CLINIC_USER_UPDATE_ALERT_MSG,
    payload: {
      clinicuserErrorMsg: false,
      clinicuserUpdateMsg: false,
    },
  });

}


export const updateClinicProile = (val) => async (dispatch) => {
  // console.log("val", val)
  if (val) {

    let accountnumber = val.accountnumber;
    let email = val.email;
    let address = {
      "addressline1": val.addressline1 ? val.addressline1 : val.address.addressline1,
      "addressline2": val.addressline2 ? val.addressline2 : val.address.addressline2,
      "city": val.city ? val.city : val.address.city,
      "country": val.country ? val.country : val.address.country,
      "postal": val.postal ? val.postal : val.address.postal,
      "state": val.state ? val.state : val.address.state
    };
    let clinicname = val.clinicname;
    let phonenumber = val.phonenumber ? val.phonenumber : "";
    let website = val.website;
    let id = val.c_id;

    apiAbmBlaze
      .put(UPDATE_CLINIC_LIST, {
        accountnumber,
        clinicname,
        phonenumber,
        "address": address,
        website,
        email,
        id,
      })
      .then(async (res) => {
        if (res.data) {
          await dispatch(getCareSiteDetailsById(id));
          await dispatch({
            type: UPDATE_CLINIC_PROFILE,
            payload: {
              clinicProfileUpdateResponse: res.data.updatedClinicData[0],
            },
          });
          if (res.status === 200) {
            dispatch({
              type: CLINIC_USER_UPDATE_ERROR_MSG,
              payload: {
                clinicuserErrorMsg: false,
              },
            });
            dispatch({
              type: CLINIC_USER_UPDATE_ALERT_MSG,
              payload: {
                clinicuserUpdateMsg: true,
              },
            });
            dispatch({
              type: CLINIC_USER_UPDATE_ERROR_MSG,
              payload: {
                clinicuserErrorMsg: false,
              },
            });
          }
        }
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: CLINIC_USER_UPDATE_ALERT_MSG,
            payload: {
              clinicuserUpdateMsg: false,
            },
          });
          dispatch({
            type: CLINIC_USER_UPDATE_ERROR_MSG,
            payload: {
              clinicuserErrorMsg: true,
            },
          });
        }
      });
  }
};

export const cleareResourse = (val) => async (dispatch) => {
  dispatch({
    type: UPDATE_CLINIC_PROFILE,
    payload: {
      clinicProfileUpdateResponse: "",
    },
  });
};
export const openFilterPopup = (val) => async (dispatch) => {
  await dispatch({
    type: FILTER_OPEN_POPUP,
    payload: {
      filterPopup: val,
    },
  });
};

export const AlertMsg = (val) => async (dispatch) => {
  await dispatch({
    type: MSG,
    payload: {
      msg: val,
    },
  });
  setTimeout(() => {
    dispatch({
      type: MSG,
      payload: {
        msg: null,
      },
    });
  }, 5000);
};

// Email check for invitation
export const checkEmail = (val) => async (dispatch) => {
  apiAbmBlaze
    .get(`${CHECK_EMAIL}?email=${val}`)
    .then(async (res) => {
      if (res.status === 200) {
        if (res.data !== "User not found") {
          await dispatch({
            type: CHECK_ENTERED_EMAIL,
            payload: {
              checkingEmail: res.data,
              flagEmail: true,
              registerEmailFlag: true,
            },
          });
        } else {
          await dispatch({
            type: CHECK_ENTERED_EMAIL,
            payload: {
              checkingEmail: null,
              flagEmail: false,
              registerEmailFlag: false,
            },
          });
        }
      } else if (res.data === "User not found") {
        await dispatch({
          type: CHECK_ENTERED_EMAIL,
          payload: {
            checkingEmail: null,
            flagEmail: false,
            registerEmailFlag: false,
          },
        });
      }
    })

    .catch((error) => {
      console.log("ERROR", error);
    });
};

//get profile for updating the user profile
export const loadProfileForUpdate = () => async (dispatch) => {
  apiAbmBlaze
    .get(GET_PROFILE_FOR_UPDATE)
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: USER_PROFILE_UPLOAD_RESULT,
          payload: {
            UserProfileUpdated: res.data.profile,
          },
        });
      }
    })
    .catch(async (error) => {
      console.log("ERROR", error);
      let err = error.toJSON();
      if (err.status === 401) {
        await dispatch({
          type: IS_SESSION_EXPIRED,
          payload: { isSessionExpired: true },
        });
      }
    });
};
//fetching userdetails of other user
export const loadOtherUserProfileForViewing = (profile_id) => async (dispatch) => {
  // console.log('in invitaion action',profile_id)
  dispatch({
    type: OTHERUSER_PROFILE_UPLOAD_RESULT,
    payload: {
      OtherUserProfile: [],
    },
  });
  apiAbmBlaze
    .post("/api/getotheruserprofile",{
      otheruserprofileID:profile_id
    })
    .then(async (res) => {
      // console.log(' res',res.data)
      if (res.data) {
        dispatch({
          type: OTHERUSER_PROFILE_UPLOAD_RESULT,
          payload: {
            OtherUserProfile: res.data.profile,
          },
        });
      }
    })
    .catch(async (error) => {
      console.log("ERROR", error);
      let err = error.toJSON();
      if (err.status === 401) {
        await dispatch({
          type: IS_SESSION_EXPIRED,
          payload: { isSessionExpired: true },
        });
      }
    });
};
//fetching clinic profile
export const UserProfileUpload = (val) => async (dispatch) => {
  dispatch({
    type: USER_PROFILE_UPLOAD_RESULT,
    payload: {
      flageUserProfile: true,
    },
  });
  if (val) {
    apiAbmBlaze
      .post(USER_PROFILE_UPLOAD, {
        uuid: val,
      })
      .then(async (res) => {
        if (res.data) {
          dispatch({
            type: USER_PROFILE_UPLOAD_RESULT,
            payload: {
              UserProfileUpdated: res.data.updatedData,
              flageUserProfile: false,
            },
          });
          dispatch({
            type: OTP_MATCHES,
            payload: {
              user: res.data.updatedData,
            },
          });
          localStorage.setItem("user", JSON.stringify(res.data.updatedData));
        }
      });
  }
};

export const clinicPictureUpdate = (val, id) => async (dispatch) => {
  apiAbmBlaze
    .post(CLINIC_PROFILE_PICTURE_UPDATE, {
      clinic_Profile_uuid: val,
      clinic_id: id.id,
    })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: CLINIC_PROFILE_UPLOAD_PICTURE,
          payload: {
            ClinicProfileUpdate: res.data.updatedDataClinicPicture[0],
          },
        });
      }
    });
};

//Requesting sharing by poovarasan
export const reguestSharing = (val) => async (dispatch) => {
  apiAbmBlaze
    .post(SEND_SHARING_REQ, {
      email: val,
    })
    .then(async (res) => {
    });
};

//Accepting sharing by poovarasan
export const acceptSharing = (val) => async (dispatch) => {
  const { id, requestSentFrom } = val;
  if (id && requestSentFrom) {
    apiAbmBlaze
      .post(ACCEPT_SHARING_REQ, {
        id,
        requestSentFrom,
      })
      .then(async (res) => {
        if (res.data) {
        }
      });
  } else {
    console.log("[From Acepted Sharing] Missing parameter");
  }
};

// exiting accountnumber
export const checkaccountnumber = (val) => async (dispatch) => {
  apiAbmBlaze
    .get(`${EXITING_ACCOUNT_NUMBER}?accountnumber=${val}`)
    .then(async (res) => {
      if (res.status === 400 && res.send !== "User not found") {
        await dispatch({
          type: EXITING_ACCOUNTNUMBER,
          payload: {
            checkingAccountnumber: res.data,
            flagAccountnumber: true,
          },
        });
      } else if (res.send === "User not found") {
        await dispatch({
          type: EXITING_ACCOUNTNUMBER,
          payload: {
            checkingAccountnumber: null,
            flagAccountnumber: false,
          },
        });
      }
    })

    .catch((error) => {
      console.log("ERROR", error);
    });
};

export const acceptInvitation = (val) => async (dispatch) => {
  const { clinic_user_id, status } = val;
  if (status == "pending") {
    apiAbmBlaze
      .post(ACCEPT_INVITATION, { clinic_user_id: clinic_user_id })
      .then(async (res) => {
        if (res.data.message == "success") {
          await dispatch({
            type: ACCEPT_INVITE,
            payload: {
              invitationActionFlag: true,
            },
          });
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }
};

export const rejectInvitation = (val, user) => async (dispatch) => {
  // console.log(" IM GETING CALLWS:", val)
  const { clinic_user_id, status } = val;
  if (status == "rejected") {
    apiAbmBlaze
      .post(REJECT_INVITATION, { clinic_user_id: clinic_user_id })
      .then(async (res) => {
        if (res.data.message == "success") {
          await dispatch({
            type: REJECT_INVITE,
            payload: {
              invitationActionFlag: true,
            },
          });
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  } else if (status == "resend") {
    apiAbmBlaze
      .post(RESEND_REJECTED_INVITATION, { clinic_user_id: clinic_user_id })
      .then(async (res) => {
        if (res.data.message == "success") {
          // if (res.status == 200) {
          dispatch(
            invitationPagination({
              search: "",
              id: user.profile_id ? user.profile_id : "",
              sentViewTable: 'sentInvitation',
              userRole: user.role ? user.role.toLowerCase() : "",
              page: 1,
            })
          );
          // }
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }
};

//Active care site admin by Dhanush



export const fetchActiveCareSiteAdmin = (val) => async (dispatch) => {
  if (!val) {
    return false;
  }

  apiAbmBlaze
    .post(ACTIVE_CARE_SITE_ADMIN, { clinicID: val })
    .then(async (response) => {
      // console.log("[response]", response.data);
      if (response.data) {
        await dispatch({
          type: ACTIVE_CLINIC_ADMIN,
          payload: {
            activeCareSiteAdmin: response.data.val,
            totalCountofCLinicAdmin: response.data.val.length,
          },
        });
      }
    })

    .catch((err) => {
      console.log("[ERROR]", err);
    });

};

export const searchInvitation =
  (profileId, searchElement) => async (dispatch) => {
    apiAbmBlaze
      .post(INVITATION_SEARCH, { profile_id: profileId, email: searchElement })
      .then(async (response) => {
        if (response.status === 200) {
          await dispatch({
            type: SEARCH_INVITATION,
            payload: {
              searchInvitation: response.data.response.rows,
            },
          });
        }
      })
      .catch((err) => {
        console.log("[error]", err);
      });
  };

export const fetchActiveClinicSite = () => (dispatch) => {
  apiAbmBlaze
    .get(`${ACTIVE_CLINIC_SITE}`)
    .then(async (response) => {
      if (response.data) {
        await dispatch({
          type: ACTIVE_CLINIC,
          payload: {
            activeClinic: response.data.response.rows,
            activeClinicRowCount: response.data.response.rowCount,
          },
        });
      }
    })
    .catch((err) => {
      console.log("[ERROR]", err);
    });
};

export const inActiveAlertFunc = (profileId, val, clinic_id) => async (dispatch) => {
  apiAbmBlaze
    .post(INACTIVE_PATIENT, { profileId, status: val, clinic_id })
    .then(async (response) => {
      if (response.status === 200) {
        await dispatch({
          type: ACTIVE_INACTIVE_RES_STATUS_PAT_PROF,
          payload: {
            activeInactiveStatusSnack: true,
          },
        });
      }
    })
    .catch((err) => {
      console.log("[Err]", err);
    });
};

export const getPatientStatus = (profileId) => async (dispatch) => {
  apiAbmBlaze
    .post(GET_PATIENT_STATUS, { profileId })
    .then(async (response) => {
      if (response.status == 200) {
        await dispatch({
          type: GET_PATIENT_LIST,
          payload: {
            getPatientStat: response.data.response.rows[0],
          },
        });
      }
    })
    .catch((err) => {
      console.log("[Err]", err);
    });
};

export const getPatientIdRedFunc = (profileId) => async (dispatch) => {
  await dispatch({
    type: GET_PATIENT_ID,
    payload: {
      patientIdFromRed: profileId,
    },
  });
};

export const fetchEnrolledListCount = (values) => async (dispatch) => {
  apiAbmBlaze
    .get(`${SUPERADMIN_DASHBOARD_ENROLLED_LIST_COUNT}?role=${values}`)
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: SUPERADMIN_DASHBOARD_COUNT,
          payload: {
            superadminDashboardCountList: res.data.rows[0],
          },
        });
      }
    })
    .catch(async (error) => {
      console.log("ERROR", error);
    });
};

//pagination, search, display for caresite table for superadmin and abmadmin

export const superadminDashboardPagination = (val) => async (dispatch) => {
  apiAbmBlaze
    .post(SUPERADMIN_DASHBOARD_CARESITE_LIST, {
      searchquery: val.search,
      index: val.page,
      property: val.property,
      order: val.order
    })
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: PAGINATED_SUPERADMIN_DASHBOARD_CARESITE_LIST,
          payload: {
            superadminCareSiteList: res.data.rows,
          },
        });
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
    });
};


export const getSentReceivedInvitationCount = () => async (dispatch) => {
  apiAbmBlaze
    .get(GET_SENT_RECEIVED_INVITATION_COUNT)
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: TOTAL_SENT_RECEIVED_INVITATION_COUNT,
          payload: {
            total_received_invitation: res.data.received_count ? res.data.received_count : '0',
            total_sent_invitation: res.data.sent_count ? res.data.sent_count : '0',
          },
        });
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
    });
};