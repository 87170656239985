import React from 'react';
import './Components/role.css';
import {  useSelector} from 'react-redux';
import UserDropdown from '../../components/Dropdowns/UserDropdown.js';
import '../../../src/assets/styles/font.css';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avt: {
    height : "150px",
    width : "150px",
  },
  
}));

const Navbar = () => {
  const classes = useStyles();
const sideBarPages = useSelector((store)=>{
  return store.user.sideBarRoles
})
const userrole = useSelector((store)=>{
  return store.user.user.role
})
  return (
    <>
      {/* Navbar */}
      <nav className="fixed lg: fixed top-0 left-0 w-full z-10 bg-white md:flex-row md:flex-nowrap  flex items-center p-4">
        <div className='navbarWrapper'>
        <div
          className=" items-center md:px-10"  
          style={{
            display: 'flex',
            // width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {/* Brand */}
          <div
           className="text-sm lg:inline-block font-bold fnt48P"
           onClick={(e) => e.preventDefault()}>
             {sideBarPages=='Dashboard' && (userrole =='clinicadmin'||userrole =='clinician')?'Care Site Dashboard':sideBarPages}
           </div>
            <ul className="flex-col md:flex-row list-none items-center md:flex">
              <UserDropdown />
            </ul>
          </div>
          </div>
        {/* </div> */}
      </nav>
      {/* End Navbar */}
    </>
  );
};

export default Navbar;
