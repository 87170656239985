export const LOGIN_IN = "LOGIN_IN";

//USER CHAT
export const CURRENT_USER_CHAT = "CURRENT_USER_CHAT";
export const CURRENT_USER_PROFILE = "CURRENT_USER_PROFILE";
export const SIGN_IN = "SIGN_IN";

//ABM SUPER ADMIN

export const INVITATION_SUPER_ADMIN = "INVITATION_SUPER_ADMIN";

//ABM ADMIN

export const INVITATION_ADMIN = "INVITATION_ADMIN";

//CLINICAL ADMIN

export const INVITATION_CLINICAL_ADMIN = "INVITATION_CLINICAL_ADMIN";

//CLINIC

export const INVITATION_CLINIC = "INVITATION_CLINIC";

//INVITATION
export const GET_ALL_INVITATION_LIST = "GET_ALL_INVITATION_LIST";
export const GET_EACH_ITEM = "GET_EACH_ITEM";
export const ADDING_INVITAION_OR_CLINICNAME = "ADDING_INVITAION_OR_CLINICNAME";
export const RESET = "RESET";

//CLINICLIST
export const GET_ALL_CLINIC_LIST = "GET_ALL_CLINIC_LIST";

// PATIENTLIST

export const GET_ALL_PATIENT_LIST = "GET_ALL_PATIENT_LIST";

// Invite abm admin open popup

export const INVITE_POP_UP_OPEN = "INVITE_POP_UP_OPEN";

//filter open popup

export const FILTER_OPEN_POPUP = "FILTER_OPEN_POPUP";

export const UPDATE_CLINIC_PROFILE = "UPDATE_CLINIC_PROFILE";

//New clinic invite

export const INVITING_NEWCLINIC = "INVITING_NEWCLINIC";

export const LOGIN_USER_INCOGNO = "LOGIN_USER_INCOGNO";
export const MSG = "MSG";

//Login to Sailjs server

export const LOGIN_GET_OTP = "LOGIN_GET_OTP";
export const FORGET_GET_OTP ="FORGET_GET_OTP";
export const VERIFYUSER_GET_OTP ="VERIFYUSER_GET_OTP";
export const OTP_MATCHES = "OTP_MATCHES";
export const NEWUSER_OTP_DOESNOT_MATCHES = "NEWUSER_OTP_DOESNOT_MATCHES";
export const NEWUSER_OTP_MATCHES = "NEWUSER_OTP_MATCHES";
export const FORGOT_OTP_MATCHES = "FORGOT_OTP_MATCHES";
export const PASSWORD_UPDATED  = "PASSWORD_UPDATED"



//signup to sailsjs serever
export const SIGNUP_GET_OTP = "SIGNUP_GET_OTP";
export const SIGNUP_OTP_MATCHES = "SIGNUP_OTP_MATCHES";
export const SIGNUP_OTP_DOESNOT_MATCHES = "SIGNUP_OTP_DOESNOT_MATCHES";
export const SIGNUP_ERROR = "SIGNUP_ERROR";

//updateprofile
export const UPDATE_PROFILE_ITEM = "UPDATE_PROFILE_ITEM";
export const UPDATE = "UPDATE";
export const PROFILE_ID = "PROFILE_ID";

//forgot password
export const CHECKVALIDUSER = "CHECKVALIDUSER";
export const CHECKVALIDUSEREMAIL = "CHECKVALIDUSEREMAIL";

// alert msg for updating  clinic user details
export const CLINIC_USER_UPDATE_ALERT_MSG = "CLINIC_USER_UPDATE_ALERT_MSG";
export const CLINIC_USER_UPDATE_ERROR_MSG = "CLINIC_USER_UPDATE_ERROR_MSG";

// alert msg for adding clinic Details
export const ADDING_CLINIC_ALERT_MSG = "ADDING_CLINIC_ALERT_MSG";

//Register alert message popup
export const REGISTER_ALERT_MSG = "REGISTER_ALERT_MSG";

//register error message popup
export const REGISTER_ERROR_MSG = "REGISTER_ERROR_MSG";
// profile picturew update alert message popup
export const PICTURE_UPDATED_ALERT_MSG = "PICTURE_UPDATED_ALERT_MSG";
// profile update alert message
export const PROFILE_UPDATE_ALERT_MSG = "PROFILE_UPDATE_ALERT_MSG";
//profile error message popup
export const PROFILE_UPDATE_ERROR_MSG = "PROFILE_UPDATE_ERROR_MSG";

//login user data
export const USER_CLINIC_DATA = "USER_CLINIC_DATA";

// adding clinic error alert message
export const ADDING_CLINIC_ERROR_MSG = "ADDING_CLINIC_ERROR_MSG";

// checking email entered
export const CHECK_ENTERED_EMAIL = "CHECK_ENTERED_EMAIL";

//Headings and subHeadigs at nav bar
export const SIDEBAR_ROLES = "SIDEBAR_ROLES";

export const REPORT_SEARCH = "REPORT_SEARCH";
export const USER_PROFILE_UPDATED_RESULT = "USER_PROFILE_UPDATED_RESULT";
// Sharing

export const GET_ACCEPTED_SHARING_REDUX = "GET_ACCEPTED_SHARING_REDUX";
export const CANCEL_SHARING_REQ_REDUX = "CANCEL_SHARING_REQ_REDUX";
export const REVOKE_SHARING_REQ_REDUX = "REVOKE_SHARING_REQ_REDUX";
export const ACCEPT_SHARING_REQ_REDUX = "ACCEPT_SHARING_REQ_REDUX";
export const SEND_SHARING_REQ_REDUX = "SEND_SHARING_REQ_REDUX";
// export const USER_PROFILE_UPDATED_RESULT = "USER_PROFILE_UPDATED_RESULT";
export const ACCEPTED_SHARING_REDUX = "ACCEPTED_SHARING_REDUX";

/* userprofile update */
export const USER_PROFILE_UPLOAD_RESULT = "USER_PROFILE_UPLOAD_RESULT";
export const OTHERUSER_PROFILE_UPLOAD_RESULT = "OTHERUSER_PROFILE_UPLOAD_RESULT";

/* clinic profile update */

export const CLINIC_PROFILE_UPLOAD_PICTURE = "CLINIC_PROFILE_UPLOAD_PICTURE";
//Notification settings
export const NOTIFICATION_SETTINGS = "NOTIFICATION_SETTINGS";
export const NOTIFICATION_SETTINGS_SUCCESS = "NOTIFICATION_SETTINGS_SUCCESS";
//Notification Summary
export const GET_NOTIFICATION_SUMMARY = "GET_NOTIFICATION_SUMMARY";
//clinicDetails
export const GET_CLINIC_DETAILS = " GET_CLINIC_DETAILS";
export const ADDING_CLINIC = "ADDING_CLINIC";

//notification selected clinic id
export const NOTIFICATION_CID = "NOTIFICATION_CID";

//Chat

export const REPORT_PROFILE_ID = "REPORT_PROFILE_ID";

export const NAV_REPORT_SEARCH_TO_PATIENT_PROFILE =
  "NAV_REPORT_SEARCH_TO_PATIENT_PROFILE";
/* help center list */
export const HELP_CENTER_RESULT = "HELP_CENTER_RESULT";
export const DIRECT_CHAT = "DIRECT_CHAT";

//set new therapy goals
export const NUMBER_OF_THERAPYS = "NUMBER_OF_THERAPYS";
//Messaging
export const SELECTED_USER_CHAT = "SELECTED_USER_CHAT";
export const DIALOGS = "DIALOGS";
export const PREV_CONVERSATION = "PREV_CONVERSATION";
export const OCCUPANTS_IDS = "OCCUPANTS_IDS";
//QuickBlox Items

export const GET_ALL_MESSAGE = "GET_ALL_MESSAGE";
export const CURR_DIALOG = "CURR_DIALOG";

//Get Profile by QuickBlox Id
export const GET_PROFILE_QUICKBLOXID = "GET_PROFILE_QUICKBLOXID";

export const PROFILE_QUICKBLOXID = "PROFILE_QUICKBLOXID";
export const CHAT_DIALOG_ID = "CHAT_DIALOG_ID";

// exiting accountnumber
export const EXITING_ACCOUNTNUMBER = "EXITING_ACCOUNTNUMBER";
//add note
export const FETCH_NOTE_DATA = "FETCH_NOTE_DATA";

//For Loading Therapies

export const LOAD_THERAPIES = "LOAD_THERAPIES";
export const HELP_DESK_ALERT_MSG = "HELP_DESK_ALERT_MSG";

export const HELP_DESK_ERROR_MSG = "HELP_DESK_ERROR_MSG";

export const SHOW_CHAT_WINDOW = "SHOW_CHAT_WINDOW";

export const SELECTED_GROUP_CHAT = "SELECTED_GROUP_CHAT";

export const OPPONENENT_DETAILS = "OPPONENENT_DETAILS";

//active care site admin by dhanush

export const ACTIVE_CLINIC_ADMIN = "ACTIVE_CLINIC_ADMIN";

//active clinic site by dhanush
export const ACTIVE_CLINIC = "ACTIVE_CLINIC";

//search Invitation by dhanush
export const SEARCH_INVITATION = "SEARCH_INVITATION";

//filter invitation by dhanush

export const FILTER_INVITATION = "FILTER_INVITATION";

export const ADHERANCE_SCORE_MAP = "ADHERANCE_SCORE_MAP";

export const ADHERANCE_SCORE_GRAPH_MAP = "ADHERANCE_SCORE_GRAPH_MAP";

export const DELIVERED_PARAMETERS = "DELIVERED_PARAMETERS";

export const ADHERANCE_SCORE_GRAPH_DATERANGE =
  "ADHERANCE_SCORE_GRAPH_DATERANGE";
export const HMR_GRAPH = "HMR_GRAPH";
export const HMR_GRAPH_DATE_RANGE = "HMR_GRAPH_DATE_RANGE";

export const DASHBOARD_VAL = "DASHBOARD_VAL";
export const INVITE_LINK_VERIFICATION = "INVITE_LINK_VERIFICATION";

export const RESEND_INVITE_RES = "RESEND_INVITE_RES";

export const DASHBOARD_USER_DATA = "DASHBOARD_USER_DATA";
export const PENDING_REQUESTS = "PENDING_REQUESTS";

export const HMR_GRAPH_BOOLEAN = "HMR_GRAPH_BOOLEAN";
export const ADHERANCE_GRAPH_BOOLEAN = "ADHERANCE_GRAPH_BOOLEAN";

export const SEARCH_FILTER_BOOLEAN = "SEARCH_FILTER_BOOLEAN";
export const PASSWRD_CHECK = "PASSWRD_CHECK";

export const USER_NEW_EMAIL = "USER_NEW_EMAIL";
export const USER_FORGOT_EMAIL = "USER_FORGOT_EMAIL";

export const EMAIL_UPDATED = "EMAIL_UPDATED";
export const EMAIL_FORGOT = "EMAIL_FORGOT";

export const USER_NEW_PHONE = "USER_NEW_PHONE";

export const PASSWORD_UPDATE = "PASSWORD_UPDATE";
export const PASSWORD_UPDATE_FAILED = "PASSWORD_UPDATE_FAILED";

//Notification Summary Table by poovarasan
export const NOTIFICATION_SUMMARY_PATIENTTABLE =
  "NOTIFICATION_SUMMARY_PATIENTTABLE";
export const ACCEPT_INVITE = "ACCEPT_INVITE";

export const REJECT_INVITE = "REJECT_INVITE";

export const PAGINATED_INVITATION_LIST = "PAGINATED_INVITATION_LIST";

export const GET_PATIENT_LIST = "GET_PATIENT_LIST";
export const INACTIVE_PATIENT_ALERT = "INACTIVE_PATIENT_ALERT";
export const GET_PATIENT_ID = "GET_PATIENT_ID";
//NOTIFICATION_SUMMARY_PATIENT_REPORT
export const NOTIFICATION_SUMMARY_PATIENTREPORT =
  "NOTIFICATION_SUMMARY_PATIENTREPORT";

//by dhanush active res staus in patient profile
export const ACTIVE_INACTIVE_RES_STATUS_PAT_PROF =
  "ACTIVE_INACTIVE_RES_STATUS_PAT_PROF";

// Clinicsite dashboard
export const ENROLL_CLINICIAN_PATIENT = "ENROLL_CLINICIAN_PATIENT";

export const ACTIVE_PATIENTS = "ACTIVE_PATIENTS";

export const ACTIVE_CLINICIANS = "ACTIVE_CLINICIANS";

export const ACTIVE_CLINICADMINS = "ACTIVE_CLINICADMINS";

export const SEARCH_PATIENTS = "SEARCH_PATIENTS";

export const SEARCH_CLINICIANS = "SEARCH_CLINICIANS";

export const SEARCHED_PATIENTS ="SEARCHED_PATIENTS";

export const FETCH_CLINIC_ID = "FETCH_CLINIC_ID";

export const SEARCH_CLINICS = "SEARCH_CLINICS";

export const INACTIVE_PATIENTS = "INACTIVE_PATIENTS";

export const All_PATIENTS = "All_PATIENTS";

export const GET_CARE_SITE_LIST = "GET_CARE_SITE_LIST";

//Patient Profile Therapy Graph by poovarasan
export const PATIENT_PROFILE_THERAPY_GRAPH = "PATIENT_PROFILE_THERAPY_GRAPH";
export const PATIENT_PROFILE_ADS_PROGRESSBAR =
  "PATIENT_PROFILE_ADS_PROGRESSBAR";

export const LOGIN_ERROR_MSG = "LOGIN_ERROR_MSG";
export const FORGET_ERROR_MSG = "FORGET_ERROR_MSG" ;
export const VERIFY_NEW_USER_ERROR_MSG = "VERIFY_NEW_USER_ERROR_MSG" ;
export const FORGET_ERROR_MAIL = "FORGET_ERROR_MAIL" ;

export const IS_SESSION_EXPIRED = "IS_SESSION_EXPIRED";

//NOTIFICATION_SETTINGS_SELECTED_ID
export const NOTIFICATION_SETTINGS_SELECTED_ID =
  "NOTIFICATION_SETTINGS_SELECTED_ID";
//fetching number of clinics, patients, clinicians for superadmin dashboard
export const SUPERADMIN_DASHBOARD_COUNT = "SUPERADMIN_DASHBOARD_COUNT";

//pagination, search, display for caresite table for superadmin and abmadmin
export const PAGINATED_SUPERADMIN_DASHBOARD_CARESITE_LIST =
  "SUPERADMIN_DASHBOARD_CARESITE_LIST";

export const UPDATED_PATIENT_ID = "UPDATED_PATIENT_ID";

//Care site details for super admin done by poovarasan
export const CARE_SITE_DETAILS_FETCH = "CARE_SITE_DETAILS_FETCH";
// export const UPDATED_PATIENT_ID = "UPDATED_PATIENT_ID";

export const RESET_ALL_STATE = "RESET_ALL_STATE";

export const TOTAL_SENT_RECEIVED_INVITATION_COUNT = "TOTAL_SENT_RECEIVED_INVITATION_COUNT";


export const OTP_EXPIRE_ERROR = "OTP_EXPIRE_ERROR";
export const FORGOTOTP_EXPIRE_ERROR = "FORGOTOTP_EXPIRE_ERROR";
export const PASSWORD_UPDATE_ERROR = "PASSWORD_UPDATE_ERROR";

export const OTP_RESEND_LOGIN = "OTP_RESEND_LOGIN";
export const OTP_RESEND_FORGOT = "OTP_RESEND_FORGOT";
export const OTP_RESEND_FORGOTEMAIL = "OTP_RESEND_FORGOTEMAIL";


export const OTP_RESEND_REGISTER = "OTP_RESEND_REGISTER";
export const OTP_RESEND_NEWUSER= "OTP_RESEND_NEWUSER";

export const OTP_EXPIRE_REGISTER = "OTP_EXPIRE_REGISTER";

export const OTP_EXPIRE_NEWUSER = "OTP_EXPIRE_NEWUSER";


export const OTP_RESPONSE_EMAIL = "OTP_RESPONSE_EMAIL";
export const OTP_RESPONSE_EMAIL_FORGOT = "OTP_RESPONSE_EMAIL_FORGOT";


export const OTP_DOESNOT_MATCHES_PASSWORD_EMAIL = "OTP_DOESNOT_MATCHES_PASSWORD_EMAIL";
export const OTP_DOESNOT_MATCHES_FORGOT_PASSWORD_EMAIL = "OTP_DOESNOT_MATCHES_FORGOT_PASSWORD_EMAIL";

export const OTP_EXPIRE_PASSWORD_EMAIL = "OTP_EXPIRE_PASSWORD_EMAIL";
export const OTP_EXPIRE_FORGOTPASSWORD_EMAIL ="OTP_EXPIRE_FORGOTPASSWORD_EMAIL";

export const OTP_RESPONSE_PHONE = "OTP_RESPONSE_PHONE";


export const OTP_DOESNOT_MATCHES_PASSWORD_PHONE = "OTP_DOESNOT_MATCHES_PASSWORD_PHONE";

export const OTP_EXPIRE_PHONE = "OTP_EXPIRE_PHONE";

export const INVITATION_FLAG_VALUE = "INVITATION_FLAG_VALUE";
export const IF_DONT_HAVE_ANYC_CLINIC = "IF_DONT_HAVE_ANYC_CLINIC";

export const UPDATED_UNIT_VALUE = "UPDATED_UNIT_VALUE";
//Fetching single patient user on report search
export const REPORT_SEARCH_PRTICULAR_USER = "REPORT_SEARCH_PRTICULAR_USER";

export const FETCH_DEVICE_PERFORMANCE_STATS = "FETCH_DEVICE_PERFORMANCE_STATS";

export const DELETE_DEVICE_PERFORMANCE_STATS = "DELETE_DEVICE_PERFORMANCE_STATS";