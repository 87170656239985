import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { connect } from "react-redux";

const InfoPopup = (props) => {
  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} fullWidth="md">
        <DialogTitle style={{ backgroundColor: "white" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseOutlinedIcon onClick={props.onClose} className="closeIcon" />
          </div>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "white" }}>
          <div
            style={{
              fontSize: "18px",
              fontFamily: "roboto",
              fontWeight: "500",
              color: "#3D446F",
            }}
          >
            The adherence score looks back over the past
            <span
              style={{
                color: "#F26930",
              }}
            >
              {"  "}7 days.
            </span>
          </div>
          <br />
          <div
            style={{
              fontSize: "18px",
              fontFamily: "roboto",
              fontWeight: "500",
              color: "#3D446F",
            }}
          >
            It is a calculation based upon the therapy completed compared to the therapy goals set in Arc Connect. 
            The score is based upon a weighted average calculation. A higher weight is given to therapies performed more recent than therapies performed earlier in the week.
          </div>
          <br />
          <br />
          <br />
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return {};
};

export default connect(mapStateToProps, {})(InfoPopup);
