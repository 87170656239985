import { NOTIFICATION_SETTINGS, GET_NOTIFICATION_SUMMARY, NOTIFICATION_CID, IS_SESSION_EXPIRED, NOTIFICATION_SETTINGS_SELECTED_ID } from '../action.list';
import apiAbmBlaze, { ADD_NOTIFICATION_SETTING, GET_NOTIFICATION } from '../../api';


export const addNotificationSettings = (val) => async (dispatch) => {

  await dispatch({
    type: NOTIFICATION_SETTINGS_SELECTED_ID,
    payload: {
      notificationSettingsSelectedId: { clinicId: val.clinicId, clinicName: val.clinicName }
    }
  })

  return new Promise((resolve,reject)=>{
    apiAbmBlaze
    .post(ADD_NOTIFICATION_SETTING, {
      clinicId: val.clinicId,
      days: val.days,
      recipients: val.recipients,
      adherence: val.adherence,
      spo2: val.spo2,
      therapyAdjustment: val.therapyAdjustment.value ? val.therapyAdjustment.value : (val.therapyAdjustment.value === false) ? false : val.therapyAdjustment,
    })
    .then(async (res) => {
      if (res.data) {
        if(res.data.message!=='No new changes'){
          await dispatch({
            type: NOTIFICATION_SETTINGS,
            payload: {
              notificationSettingSave: res.data.updatedNotificationData[0],
              // noficationsuccess:res.data.status
            },
          });
        }
      }
      if (res.status === 200) {
        let value = "success";
        if(res.data.message!=='No new changes'){
          await dispatch({
            type: NOTIFICATION_SETTINGS,
            payload: {
              noficationsuccess: value
            },
  
          });
          resolve('success')
        }
        else{
          resolve('Nochange')
        }
        
      }
    })
    .catch((error) => {
      console.log('ERROR', error);
      reject('fail')
    });
  })
}
export const repetedNotificationpopup = () => async (dispatch) => {
  await dispatch({
    type: NOTIFICATION_SETTINGS,
    payload: {

      noficationsuccess: ""
    },

  });

}
export const fetchNotificationSummary = (val) => async (dispatch) => {
  if (val > 0 || val !== null) {
    apiAbmBlaze
      .get(`${GET_NOTIFICATION}?clinicId=${val}`)
      .then(async (res) => {

        if (res.data) {
          await dispatch({
            type: GET_NOTIFICATION_SUMMARY,
            payload: {
              notificationSummary: res.data.rows,
              notificationSettingsData: res.data.rows[0],
            },
          });
        }
      })
      .catch(async (error) => {
        console.log('ERROR', error);
        let err = error.toJSON();
        if (err.status === 401) {
          await dispatch({
            type: IS_SESSION_EXPIRED,
            payload: { isSessionExpired: true },
          });
        }
      });
  } else {
    await dispatch({
      type: GET_NOTIFICATION_SUMMARY,
      payload: {
        notificationSummary: null,
      },
    })
  }
};

export const notificationData = (val, clinicName) => async (dispatch) => {
  await dispatch({
    type: NOTIFICATION_CID,
    payload: { notificationCid: val, notificationCNm: clinicName },
  });
}