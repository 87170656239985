/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { createPopper } from "@popperjs/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "./CaresiteSettingDashboard.css";
import Avatar from "@material-ui/core/Avatar";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Menu from "@material-ui/core/Menu";
import Excel from "../../../assets/icons/excel.png"
import {
  revokeUser,
  loadOtherUserProfileForViewing
} from "../../../redux/invitation/components/invitation.action";
import Pagination from "../../../components/Pagination";
import SeparatorTopSection from "../../../components/SeparatorTopSection";
import SeparatorBottomSection from "../../../components/SeparatorBottomSection";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import userIcon from "../../../assets/icons/userProfileIcon.svg";
//----validations----
import InputField from "../../../components/Inputs_Validation";
import MuiAlert from "@material-ui/lab/Alert";
import CustomsSnackbar from "../../../components/Alertmessage/Snackbar";
import Snackbar from "@material-ui/core/Snackbar";
import LoadingIcon from '../../../components/LoadingIcon'
// components
import { connect } from "react-redux";


import {
  addClinicList,
  openInvitePopup,
  openFilterPopup,
  updateClinicProile,

} from "../../../redux/invitation/components/invitation.action.js";

import {
  enrolledCount, searchClinician,
  searchClinic, fetchClinicByID,
} from "../../../redux/user/user.action.js";

//SORTING

import TableSortLabel from "@material-ui/core/TableSortLabel";


//dropdown
import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import CaresitesettingsCards from '../../../components/Cards/CaresitesettingsCards';
// import InviteModalPopup from '../../../components/ModalPopup/InviteModalPopup';
import FooterCard from "../../../components/FooterCard";

import Select from "../../../components/Select"
//  invitation dialog
import {
  InviteModalPopup
} from "../../../components/ModalPopup";
import "../../../../src/screens/PrivateRoutes/abmAdminDashboard/abmAdminDashboard.css";
import "../../../../src/screens/PrivateRoutes/clinicAdminDashboard/ClinicAdminDashboard.css";
import RevokeModal from "./RevokeModal";
import CaresiteDetailsPopup from "../../../../src/components/CaresiteDetailsPopup";




const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  patientlistpaper: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    textAlign: "center",

  },

  paperwrapper: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    height: "225px",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

}));


//dropdown


const CaresiteSettingDashboard = ({

  addClinicList,
  addingClinicSuccessAlert,
  addingclinicerrormsg,
  userSpClinicList,
  enrolledCount,
  user,
  enrolledCounts,
  revokeUser,
  loadOtherUserProfileForViewing,
  searchedPatient,
  searchedClinician,
  searchedClinic,
  searchClinician,
  searchClinic,

}) => {
  const classes = useStyles();
  const history = useHistory();

  //---patient table----
  const [isPatientSelected, setPatientSelected] = React.useState('clinician');

  //SORT
  const [orderDirection, setOrderDirection] = React.useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = React.useState("");

  //search
  const [text, setText] = React.useState("");

  //popup modal
  const [showModal, setShowModal] = React.useState(false);

  //Alert message
  const [sucsess, setSucsess] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });

  //dropdown
  const [showInviteModal, setShowInviteModal] = React.useState(false);
  //invite popup
  const [isopen, setOpen] = React.useState(false);
  const [clinicId, setClinicId] = React.useState(null);
  const [anchorAccept, setAnchorAccept] = React.useState(null);
  const [openSelectAccept, setOpenSelectAccept] = React.useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = React.useState(false);
  const [activePatient, setActivePatient] = React.useState('white');
  // const [inactivePatient, setInactivePatient] = React.useState('#6A768F38');

  //care site details popup
  const [careSiteDetailsPopupOpen, setcareSiteDetailsPopupOpen] = React.useState(false);
  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  const [snackErrorMessage, setSnackErrorMessage] = React.useState(
    "Something went wrong.Try again later"
  );

  const [openErrorSnack, setOpenErrorSnack] = React.useState(false);

  const handleErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  const [customeErrorSnackbarAnchor, setCustomeErrorSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });

  const [snackErrorSeverity, setSnackErrorSeverity] = React.useState("");




  const closeRevokePopUp = () => {
    setIsPopUpOpen(false);
  };




  //download

  function setDownload_file() {
    let datas = [];
    if (isPatientSelected === 'clinician') {
      datas = searchedClinician.map(row => ({
        lastname: row.last_name,
        firstname: row.first_name,
        speciality: row.speciality,
        city: row.address.city,
        state: row.address.state,
        zip: row.address.Zip,
      }))

    }
    else if (isPatientSelected === 'patient' && searchedClinician.length === 0) {
      datas = searchedClinician.map(row => ({
        lastname: row.last_name,
        firstname: row.first_name,
        city: row.address.city,
        state: row.address.state,
        zip: row.address.zip,

      }))
    }
    else if (isPatientSelected === 'patient' && searchedClinician.length !== 0) {
      datas = searchedClinician.map(row => ({
        lastname: row.last_name,
        firstname: row.first_name,
        city: row.address.city,
        state: row.address.state,
        zip: row.address.zip,

      }))

    }

    else if (isPatientSelected === 'clinicadmin') {
      datas = searchedClinician.map(row => ({
        lastname: row.last_name,
        firstname: row.first_name,
        clinicname: row.clinicname,
      }))
    }



    const csvData = objectToCSV(datas)

    download(csvData)
  }
  const objectToCSV = (data) => {
    //get header
    const csvRows = [];

    if (data[0]) {
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      //loops over the row
      for (const row of data) {
        const values = headers.map(header => {

          const escaped = (' ' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }

      //from escaped comma seprater val
      return csvRows.join('\n')
    }
  }

  const download = (data) => {

    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.setAttribute("hidden", "");
    a.setAttribute("href", url);

    if (isPatientSelected === 'clinician') {
      a.setAttribute("download", searchedClinician.length > 1 ? "All_Enrolled_Clinician" : ` Enrolled_Clinician ${searchedClinician[0].first_name} ${searchedClinician[0].last_name} `);
    }
    else if (isPatientSelected === 'patient') {
      a.setAttribute("download", searchedClinician.length > 1 ? "All_Enrolled_Ptient" : ` Enrolled_Ptient ${searchedClinician[0].first_name} ${searchedClinician[0].last_name} `);

    } else if (isPatientSelected === 'clinicadmin') {
      a.setAttribute("download", searchedClinician.length > 1 ? "All_Enrolled_Clinicadmin" : ` Enrolled_Clinicadmin ${searchedClinician[0].first_name} ${searchedClinician[0].last_name} `);

    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

  }

  //-------------SORTING THE TABLE DATAS----------
  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
    searchClinician({ clinic_id: clinicId, index: page, roletype: isPatientSelected, status: 'accepted', property, order: isAscending ? "desc" : "asc" })
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  // function descendingComparator(a, b, orderBy) {
  //   if (orderBy === "last_name") {

  //     if (b[orderBy] < a[orderBy]) {
  //       return -1;
  //     }
  //     if (b[orderBy] > a[orderBy]) {
  //       return 1;
  //     }
  //   } else {
  //     if ("address" in a && "address" in b) {
  //       if (b.address[orderBy] < a.address[orderBy]) {
  //         return -1;
  //       }
  //       if (b.address[orderBy] > a.address[orderBy]) {
  //         return 1;
  //       }
  //     }
  //   }
  //   if (orderBy === "first_name") {

  //     if (b[orderBy] < a[orderBy]) {
  //       return -1;
  //     }
  //     if (b[orderBy] > a[orderBy]) {
  //       return 1;
  //     }
  //   }

  //   return 0;
  // }


  // function getComparator(order, orderBy) {
  //   return order === "desc"
  //     ? (a, b) => descendingComparator(a, b, orderBy)
  //     : (a, b) => -descendingComparator(a, b, orderBy);
  // }

  // const sortedRowInformation = (rowArray, comparator) => {
  //   const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
  //   stabilizedRowArray.sort((a, b) => {
  //     const order = comparator(a[0], b[0]);
  //     if (order !== 0) return order;
  //     return a[1] - b[1];
  //   });
  //   return stabilizedRowArray.map((el) => el[0]);
  // };


  const submit = (val) => {
    setTimeout(() => {
      setSucsess(false)

    }, 5000)
    setSucsess(true);

    addClinicList(val);
    setShowModal(false);
  };


  //---------------SENDING INVITATION LINK TO CHILD AND GETTING THE VALUE FROM CHILD----------

  useEffect(() => {
    if (addingClinicSuccessAlert) {
      setOpenSnack(true);
      setSnackMsg("Clinic has been added successfully !!");
      setSnackSeverity("success");
    } else if (addingclinicerrormsg) {
      setOpenSnack(true);
      setSnackMsg(
        "Something went wrong.Try again later"
      );
      setSnackSeverity("error");
    }
  }, [addingClinicSuccessAlert, addingclinicerrormsg]);



  // pagination testing
  const [page, setPage] = React.useState(1);
  const [activeListpage, setactiveListpage] = React.useState(1);

  const viewTable = (arg) => {
    if (arg === 'patient') {
      setPatientSelected('patient');
    } else if (arg === 'clinicadmin') {
      setPatientSelected('clinicadmin');
    } else {
      setPatientSelected('clinician')
    }
  }

  useEffect(() => {
    searchClinician({ clinic_id: clinicId, index: 1, roletype: isPatientSelected, status: 'accepted' })
  }, [isPatientSelected])


  //invite modal popup

  const invitePopupforPatients = () => {
    setOpen(true);
  };
  const callback = () => {
    setShowInviteModal(false);
    setOpen(false);
  }
  const [selectedclinicdetalis, setselectedclinicdetalis] = React.useState({})

  const callSelect = (val) => {
    setselectedclinicdetalis(val)

  }

  const [footercarePhoneno, setfootercarePhoneno] = React.useState("");
  const [footercareName, setfootercareName] = React.useState("");
  const [footercareWebsite, setfootercareWebsite] = React.useState("");
  const [footercareArea, setfootercareArea] = React.useState("");
  const [footercareclinicpuuid, setfootercareclinicpuuid] = React.useState("");

  const scrollRef = React.useRef(null);


  useEffect(() => {
    if (searchedClinic && searchedClinic.length) {
      setfootercarePhoneno(searchedClinic[0].phonenumber)
      setfootercareWebsite(searchedClinic[0].website)
      setfootercareName(searchedClinic[0].clinicname)
      setfootercareArea(searchedClinic[0].address)
      setfootercareclinicpuuid(searchedClinic[0].clinic_puuid)
    }
  }, [searchedClinic])

  useEffect(() => {
    document.documentElement.scrollTop = 0;

    document.scrollingElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (searchedClinician && searchedClinician.length > 0) {
      setactiveListpage(searchedClinician[0].total_count)
    }
  }, [searchedClinician])

  useEffect(() => {
    setClinicId(selectedclinicdetalis.c_id)
    enrolledCount(clinicId, user.id);
    searchClinician({ clinic_id: clinicId, index: 1, roletype: isPatientSelected, status: 'accepted' })
    searchClinic(clinicId, user.id)
  }, [selectedclinicdetalis, clinicId]);


  useEffect(() => {
    let value = userSpClinicList.rows ? userSpClinicList.rows : null;
    if (value && value.length && !clinicId) {
      setClinicId(value[0].c_id);
      if (clinicId) {
        enrolledCount(clinicId, user.id);
        searchClinician({ clinic_id: clinicId, index: 1, roletype: isPatientSelected, status: 'accepted' })
        searchClinic(clinicId, user.id)
      }
    }
    else {
      if (clinicId) {
        enrolledCount(clinicId, user.id);
        searchClinician({ clinic_id: clinicId, index: 1, roletype: isPatientSelected, status: 'accepted' })
        searchClinic(clinicId, user.id)
      }
    }

  }, [userSpClinicList]);


  const handleCloseSelectAccept = (user, status) => {

    setOpenSelectAccept(false);
    setIsPopUpOpen(false);
  }

  const [targetRevoke, settargetRevoke] = React.useState(" ");


  const handleClickSelectPending = (e, item) => {
    setOpenSelectAccept(true)
    if (item) {
      settargetRevoke(item.clinic_user_id)
      setAnchorAccept(e.currentTarget)

    }
  }

  const excuted = (val) => {
    revokeUser({ ...user, clinic_user_id: targetRevoke, status: "blocked" });
    enrolledCount(clinicId, user.id);
    searchClinician({ clinic_id: clinicId, index: 1, roletype: isPatientSelected, status: 'accepted' });
    //  setOpenSelectAccept(false)
    closeRevokePopUp();
  };


  function careSiteDetailsPopupOpenHandler() {
    setcareSiteDetailsPopupOpen(true);
  }

  const callbackPI = () => {
    setcareSiteDetailsPopupOpen(false);

    setTimeout(() => {
      searchClinic(clinicId, user.id)
    }, 2000);
  };


  // const patientHandler = (val) => {
  //   if (val === "active") {
  //     //   setInactivePatient("#6A768F38");
  //     setActivePatient("white");
  //     searchClinician({ clinic_id: clinicId, index: 1, roletype: isPatientSelected, status: 'accepted' });
  //   } else if (val == "inactive") {
  //     //  setInactivePatient("white");
  //     setActivePatient("#6A768F38");
  //     searchClinician({ clinic_id: clinicId, index: 1, roletype: isPatientSelected, status: 'blocked' });

  //   }
  // }
  //pagination

  const changingPage = (event, value) => {
    setPage(value);
    searchClinician({ clinic_id: clinicId, search_val: text, index: value, roletype: isPatientSelected, status: 'accepted' })
  };

  const openingrevoke = () => {
    setIsPopUpOpen(true)
    setOpenSelectAccept(false);
  }

  //search for caresite admin table
  const handleSearchCaresite = (event) => {
    setText(event.target.value);
    if (event.target.value.length) {
      searchClinician({ clinic_id: clinicId, search_val: event.target.value, index: 1, roletype: isPatientSelected, status: 'accepted' })
    } else {
      searchClinician({ clinic_id: clinicId, index: 1, roletype: isPatientSelected, status: 'accepted' })
    }
  };

  // const handleSearchforCaresite = (event) => {
  //   setCareSiteSearchVal(event.target.value);
  // }
  // const handleEnterCaresite = (event) => {
  //   if (event.key === 'Enter') {
  //     searchClinician({ clinic_id: clinicId, search_val: event.target.value, index: 1, roletype: isPatientSelected, status: 'accepted' })
  //     } 
  // }

  //search  for patients table
  const handleSearchsearchPatient = (event) => {
    setText(event.target.value);
    if (event.target.value.length) {
      searchClinician({ clinic_id: clinicId, search_val: event.target.value, index: 1, roletype: isPatientSelected, status: 'accepted' })
    } else {
      searchClinician({ clinic_id: clinicId, index: 1, roletype: isPatientSelected, status: 'accepted' })
    }
  };

  // const handleSearchforPatients = (event) => {
  //   setpatientSearchVal(event.target.value);
  // }
  // const handleEnterPatients = (event) => {
  //   if (event.key === 'Enter') {
  //     searchClinician({ clinic_id: clinicId, search_val: event.target.value, index: 1, roletype: isPatientSelected, status: 'accepted' })
  //     } 
  // }

  //search for clinicians table
  const handleSearchClinicians = (event) => {
    setText(event.target.value);
    if (event.target.value.length) {
      searchClinician({ clinic_id: clinicId, search_val: event.target.value, index: 1, roletype: isPatientSelected, status: 'accepted' })
    } else {
      searchClinician({ clinic_id: clinicId, index: 1, roletype: isPatientSelected, status: 'accepted' })
    }
  };
  // const handleSearchforClinicians = (event) => {
  //   setclinicianSearchVal(event.target.value);
  // }
  // const handleEnterClinicians = (event) => {
  //   if (event.key === 'Enter') {
  //     searchClinician({ clinic_id: clinicId, search_val: event.target.value, index: 1, roletype: isPatientSelected, status: 'accepted' })
  //     } 
  // }
  const checkifconnectedtodevice = async (val)  => {
    // console.log('hello',userRole.role, userRole.role === "superadmin")
        if(val.profile_id){
        await loadOtherUserProfileForViewing(val.profile_id)
      history.push(`/app/userinfo/${val.profile_id}`);
}else{
  console.log('val without profile_id',val)
}
  }
  const handelData = (item) => {

    if (item.pair_id) {
      let query = Buffer.from(JSON.stringify(
        {
          profile_id: item && item.profile_id ? item.profile_id : null,
          clinic_id: item && item.clinic_id ? item.clinic_id : null,
          pair_id: item && item.pair_id ? item.pair_id : null,
        })).toString('base64');
      history.push(`/app/PatientProfile?p=${query}`);
    } else {
      // alert("OOPS!! NO PAIR ID.")
      setOpenSnack(true)
      setSnackMsg(`${item.first_name} ${item.last_name} is not associated with any device`)
    }
  }
  return (
    <React.Fragment>
      {/* searchedClinic.length == 0 */false ? (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
          <LoadingIcon />
        </div>
      ) : (<>
        <RevokeModal
          isOpen={isPopUpOpen}
          callBackToParent={closeRevokePopUp}
          excuted={excuted}
        >
        </RevokeModal>

        <CaresiteDetailsPopup isopen={careSiteDetailsPopupOpen} callBack={callbackPI}
          clinicId={selectedclinicdetalis.clinicId ? selectedclinicdetalis : userSpClinicList &&
            userSpClinicList.rows &&
            userSpClinicList.rows.length !== 0
            ? userSpClinicList.rows[0]
            : ""}
          searchedClinic={searchedClinic}
        />

        <div style={{ width: '100%' }}>

          <InviteModalPopup
            isopen={isopen}
            callBack={callback}
            invitationlink={submit}
          />
 <AlertSnackBar
                    msg={snackMsg}
                    flag={openSnack}
                    onClose={handleSnackbar}
                    key={"cusSnackbar"}
                    anchorOrigin={customeSnackbarAnchor}
                    severity={"warning"}
                  />
          <div className="w-full lg:w-12/12 notificationSummaryHeading1 fnt32P" style={{ marginLeft: "-7px" }}>
            Care Site
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", paddingRight: "30px" }} >
            <div className="lg:w-6/12 notificationSummaryDropdown" style={{ marginLeft: "72px" }}>
              <Select
                // placeholder="Select an option"
                options={userSpClinicList.rows}
                callSelect={callSelect}
                defClinic={
                  userSpClinicList &&
                    userSpClinicList.rows &&
                    userSpClinicList.rows.length !== 0
                    ? userSpClinicList.rows[0].clinicname
                    : ""
                }
              />

            </div>

            <div className="lg:w-6/12" style={{ display: "flex", flexDirection: "row-reverse" }}>
              <button id="careSiteDetailBTN" className="careSiteDetailsButn"
                onClick={() => {
                  //  getClinicId(item);
                  careSiteDetailsPopupOpenHandler();
                }}
              >
                <b> Care Site details</b>
              </button>
            </div>
          </div>


          <div className="flex flex-wrap" style={{ marginLeft: '72px', marginRight: '20px', marginTop: '32px' }}>
            <div className="w-full lg:w-4/12">
              <CaresitesettingsCards
                role="Patients"
                rolecount={enrolledCounts.enrolled_patients}
                view="View"
                invite="Invite"
                imagepath="/assets/img/add patients.svg"
                // viewPatientsTable={viewPatientsTable}
                viewTable={viewTable}
                invitePopupforPatients={invitePopupforPatients}
                scrollRef={scrollRef}
              ></CaresitesettingsCards>
            </div>

            <div className="w-full lg:w-4/12">
              <CaresitesettingsCards
                role="clinicians"
                rolecount={enrolledCounts.enrolled_clinicians}
                view="View"
                invite="Invite"
                imagepath="/assets/img/add clinicians.png"
                // viewclinicianTable={viewclinicianTable}
                viewTable={viewTable}
                invitePopupforPatients={invitePopupforPatients}
                scrollRef={scrollRef}
              ></CaresitesettingsCards>
            </div>


            <div className="w-full lg:w-4/12">
              <CaresitesettingsCards
                role="Care site Admin"
                rolecount={enrolledCounts.enrolled_clinicadmins}
                view="View"
                invite="Invite"
                imagepath="/assets/img/Enrolled illustraion.png"
                invitePopupforPatients={invitePopupforPatients}
                // viewclinicTable={viewclinicTable}
                viewTable={viewTable}
                scrollRef={scrollRef}
              ></CaresitesettingsCards>
            </div>

          </div>
          <div ref={scrollRef}></div>
          {isPatientSelected === 'clinicadmin' || isPatientSelected === 'patient' || isPatientSelected === 'clinician' ?

            <SeparatorTopSection />

            : null}
          <div style={{ backgroundColor: "white" }}>

            <div className="flex anouncenementHeadingWrapper">
            </div>
            {isPatientSelected === 'clinicadmin' ? (
              <div>
                <div className="caresitePatientslistHeadingWrapper" style={{
                  marginLeft: "69px",
                  marginBottom: "24px"
                }}>
                  Care Site admins list </div>
                <div className={classes.root} style={{ overflow: "hidden" }}>

                  <AlertSnackBar
                    msg={snackMsg}
                    flag={openSnack}
                    onClose={handleSnackbar}
                    key={"cusSnackbar"}
                    anchorOrigin={customeSnackbarAnchor}
                    severity={snackSeverity}
                  />
                  <CustomsSnackbar
                    msg={snackErrorMessage}
                    flag={openErrorSnack}
                    onClose={handleErrorSnackbar}
                    // key={"errorSnackbar"}
                    anchorOrigin={customeErrorSnackbarAnchor}
                    severity={snackErrorSeverity}
                  />

                  <div className="searchbarWrapper" style={{ marginBottom: "30px", marginLeft: "68px" }}>
                    <div className="add-icon" style={{ backgroundColor: '#F2F7F8' }}>
                      <div style={{ marginTop: "10px" }}>
                        <i className="fas fa-search" />
                      </div>
                      <input
                        id="searchBox"
                        type="text"
                        placeholder="Search"
                        className="report_search"
                        onChange={handleSearchCaresite}
                        // onKeyDown={(event) => handleEnterCaresite(event)} 
                        // onChange={(event) => handleSearchforCaresite(event)}
                        // value={careSiteSearchVal}
                        style={{ backgroundColor: '#F2F7F8' }}
                      />
                    </div>

                  </div>

                  {/* clinic table */}

                  <div style={{ marginRight: '40px', marginLeft: "66px" }}>
                    <Menu
                      id="simple-menu-10"
                      anchorEl={anchorAccept}
                      keepMounted
                      open={openSelectAccept}
                      onClose={handleCloseSelectAccept}
                    >
                      <MenuItem onClick={openingrevoke}>Remove</MenuItem>
                    </Menu>
                    <table className='mainTable '>
                      <tr className='tableRow'>
                        <td className='tabelHeadingImg'>
                          <div style={{ padding: '10px' }}>

                          </div>

                        </td>
                        <td className='tabelHeading' key="last_name"
                          style={{ width: "22.5%" }}>
                          <TableSortLabel
                            // src={myIcon}
                            active={true}
                            direction={
                              valueToOrderBy === "last_name" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("last_name")}
                          >

                            <div className='tableHeadingTop' >
                              Last Name
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading' key="first_name"
                          style={{ width: "22.5%" }}>
                          <TableSortLabel
                            active={true}
                            direction={
                              valueToOrderBy === "first_name" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("first_name")}
                          >
                            <div className='tableHeadingTop' >
                              First Name
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading' key="total_transmission"
                          style={{ width: "22.5%" }}>
                          <TableSortLabel
                            active={true}
                            direction={
                              valueToOrderBy === "total_transmission" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("total_transmission")}
                          >
                            <div className='tableHeadingTop' >
                              Care Site
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading'>
                          <div className='tableHeadingTop' >
                            Action
                          </div>
                        </td>
                      </tr>

                      {searchedClinician && searchedClinician.length > 0 ?
                        searchedClinician.map((item, index) => {
                          return (

                            <>
                              
                              <tr className='tabelDescAndRowAfterMapping' key={index}>
                                <td className='tabelDescAndRowAfterMapping' onClick={(e) => {
                                        checkifconnectedtodevice(item);
                                      }}>
                                  <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                                  <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                                  </Avatar>
                                  </div>
                                </td>
                                <td className='tabelHeading' onClick={(e) => {
                                        checkifconnectedtodevice(item);
                                      }}>
                                  <div className='tableDesc'>

                                    {item.last_name}
                                  </div>
                                </td>
                                <td className='tabelHeading' onClick={(e) => {
                                        checkifconnectedtodevice(item);
                                      }}>
                                  <div className='tableDesc'>

                                    {item.first_name}
                                  </div>
                                </td>

                                <td className='tabelHeading' onClick={(e) => {
                                        checkifconnectedtodevice(item);
                                      }}>
                                  <div className='tableDesc'>

                                    {item.clinicname}
                                  </div>
                                </td>
                                <td className='tabelHeadingcsdpl' style={{ height: "40px" }} >

                                  <div className="selectImage"
                                    onClick={(e) => {
                                      handleClickSelectPending(e, item);
                                    }}
                                  >
                                  </div>

                                </td>

                              </tr>
                            </>

                          )

                        }) : <tr>
                          <td colSpan="6" className='nodatafoundWrapper fnt22P'>No data found</td>
                        </tr>}


                    </table>
                    <br>
                    </br>
                    {searchedClinician && searchedClinician.length ?
                      <div className="pagination" style={{ marginRight: "20px", paddingTop: '20px', paddingBottom: '20px', display: "flex", justifyContent: "space-between", paddingRight: "35px" }}>
                        <div className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" style={{ marginLeft: "-29px", display: "flex" }}   >
                        <div
                        className="downloadwrappercaresitesettings fnt24P">
                        <div style={{ paddingTop: "8px" }}> Download &nbsp;&nbsp;&nbsp; </div>
                        <img src={Excel} alt="download" style={{cursor:"pointer"}} width="30px" height="30px" onClick={setDownload_file}></img>
                      </div>
                           {/* </div>  */}
                           </div>
                          
                      
                      
                    
                        <div>
                          <Pagination
                            count={Math.ceil(activeListpage / 10)}
                            page={page}
                            onChange={changingPage}
                          />
                        </div>

                      </div> : null}
                  </div>
                  <br>
                  </br>
                </div>
              </div>
            ) : null}

            {isPatientSelected === 'patient' ?
              (
                <div >
                  <div className="caresitePatientslistHeadingWrapper" style={{
                    marginLeft: "69px",
                    marginBottom: "24px"
                  }}>  Patients list</div>

                  <div style={{ display: "flex", justifyContent: "space-between", paddingRight: "35px" }}>
                    <div className="searchbarWrapper" style={{ marginBottom: "30px", marginLeft: "68px" }}>
                      <div className="add-icon" style={{ backgroundColor: '#F2F7F8' }} >
                        <div style={{ marginTop: "10px" }}>
                          <i className="fas fa-search" />
                        </div>
                        <input
                          id="searchBox"
                          type="text"
                          placeholder="Search"
                          className="report_search"
                          style={{ backgroundColor: '#F2F7F8' }}
                          onChange={handleSearchsearchPatient}
                        />
                      </div>
                    </div>
                    <div>
                      {/* <Button style={{ backgroundColor: activePatient, marginRight: "20px", boxShadow: "#6A768F80 0px 3px 6px", fontFamily: 'Roboto', }} id='activePatient' onClick={() => patientHandler('active')}>Active</Button> */}
                      {/* <Button style={{ backgroundColor: inactivePatient, boxShadow: "#6A768F80 0px 3px 6px", fontFamily: 'Roboto', }} id='inactivePatient' onClick={() => patientHandler('inactive')}>Inactive</Button> */}
                    </div>
                  </div>
                  <div style={{ marginRight: '40px', marginLeft: "66px" }}>
                  <Menu
                    id="simple-menu-10"
                    anchorEl={anchorAccept}
                    keepMounted
                    open={openSelectAccept}
                    onClose={handleCloseSelectAccept}

                  >
                    <MenuItem onClick={openingrevoke}>Remove</MenuItem>
                  </Menu>
                    <table className='mainTable'>
                      <tr className='tableRow'>
                        <td className='tabelHeadingImg'>
                          <div style={{ padding: '10px' }}>
                          </div>
                        </td>
                        <td className='tabelHeading' key="last_name" style={{ width: "15%" }}>
                          <TableSortLabel
                            active={true}
                            direction={
                              valueToOrderBy === "last_name" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("last_name")}
                          >
                            <div className='tableHeadingTop' >
                              Last Name
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading' key="first_name" style={{ width: "15%" }}>
                          <TableSortLabel
                            active={true}
                            direction={
                              valueToOrderBy === "first_name" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("first_name")}
                          >
                            <div className='tableHeadingTop' >
                              First Name
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading' key="city" style={{ width: "15%" }}>
                          <TableSortLabel
                            active={true}
                            direction={
                              valueToOrderBy === "city" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("city")}
                          >
                            <div className='tableHeadingTop' >
                              City
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading' key="country" style={{ width: "15%" }}>
                          <TableSortLabel
                            active={true}
                            direction={
                              valueToOrderBy === "country" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("country")}
                          >
                            <div className='tableHeadingTop' >
                              State
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading' key="postal" style={{ width: "15%" }}>
                          <TableSortLabel
                            active={true}
                            direction={
                              valueToOrderBy === "postal" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("postal")}
                          >
                            <div className='tableHeadingTop' >
                              Zip
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading' key="deviations" style={{ width: "15%" }}>
                          <div className='tableHeadingTop' >
                            Access
                          </div>
                        </td>
                      </tr>
                      {searchedClinician && searchedClinician.length ? searchedClinician.map((item, index) => {
                        return (
                          <>
                            

                            <tr className='tabelDescAndRowAfterMapping' key={index} onClick={() => handelData(item)}>
                              <td className='tabelDescAndRowAfterMapping' >
                                <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                                <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                                </Avatar>
                                </div>
                              </td>
                              <td className='tabelHeadingcsdpl'>
                                <div className='tableDesc'>

                                  {item.last_name ? item.last_name : "-"}
                                </div>
                              </td>
                              <td className='tabelHeadingcsdpl'>
                                <div className='tableDesc'>

                                  {item.first_name ? item.first_name : "-"}
                                </div>
                              </td>

                              <td className='tabelHeadingcsdpl'>
                                <div className='tableDesc'>

                                  {item.address && item.address.city ? item.address.city : "-"}
                                </div>
                              </td>
                              <td className='tabelHeadingcsdpl'>
                                <div className='tableDesc' >

                                  {item.address && item.address.state ? item.address.state : "-"}
                                </div>
                              </td>
                              <td className='tabelHeadingcsdpl'>
                                <div className='tableDesc' >

                                  {item.address && item.address.zip ? item.address.zip : "-"}
                                </div>
                              </td>
                              <td className='tabelHeadingcsdpl' style={{ height: "40px" }}>

                                <div className="selectImage" onClick={(e) => {
                                  handleClickSelectPending(e, item);
                                }}>
                                </div>

                              </td>

                            </tr>
                          </>
                        )
                      }) : <tr>
                        <td colSpan="7" className='nodatafoundWrapper fnt22P'>No data found</td>
                      </tr>}
                    </table>
                  </div>
                  <br>
                  </br>
                  {searchedClinician && searchedClinician.length ?
                    <div className="pagination" style={{ marginRight: "20px", paddingTop: '20px', paddingBottom: '20px', display: "flex", justifyContent: "space-between", paddingRight: "35px" }}>
                      <div className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" style={{ marginLeft: "36px", display: "flex" }}
                      >
                         <div
                        className="downloadwrappercaresitesettings fnt24P">
                        <div style={{ paddingTop: "8px" }}> Download &nbsp;&nbsp;&nbsp; </div>
                        <img src={Excel} alt="Download" style={{cursor:"pointer"}} width="30px" height="30px" onClick={setDownload_file}></img>
                      </div>
                        </div>
                      <div>
                        <Pagination
                          count={Math.ceil(activeListpage / 10)}
                          page={page}
                          onChange={changingPage}
                        />
                      </div>

                    </div> : null}

                </div>
              ) : isPatientSelected === 'patient' && searchedPatient.length !== 0 ?
                <div >
                  <div className="caresitePatientslistHeadingWrapper" style={{
                    marginLeft: "69px",
                    marginBottom: "24px"
                  }}>  Patients list</div>

                  <div style={{ display: "flex", justifyContent: "space-between", paddingRight: "35px" }}>
                    <div className="searchbarWrapper" style={{ marginBottom: "30px", marginLeft: "68px" }}>
                      <div className="add-icon" >
                        <div style={{ marginTop: "10px" }}>
                          <i className="fas fa-search" />
                        </div>
                        <input
                          id="searchBox"
                          type="text"
                          placeholder="Search"
                          className="report_search"
                        // onChange={handleSearchsearchPatient}
                        />
                      </div>
                    </div>
                    <div>
                      {/* <Button style={{ backgroundColor: activePatient, marginRight: "20px", boxShadow: "#6A768F80 0px 3px 6px", fontFamily: 'Roboto' }} id='activePatient' onClick={() => patientHandler('active')}>Active</Button> */}
                      {/* <Button style={{ backgroundColor: inactivePatient, boxShadow: "#6A768F80 0px 3px 6px", fontFamily: 'Roboto' }} id='inactivePatient' onClick={() => patientHandler('inactive')}>Inactive</Button> */}
                    </div>
                  </div>
                  <div style={{ marginRight: '40px', marginLeft: "66px" }}>
                  <Menu
                    id="simple-menu-10"
                    anchorEl={anchorAccept}
                    keepMounted
                    open={openSelectAccept}
                    onClose={handleCloseSelectAccept}

                  >
                    <MenuItem onClick={openingrevoke}>Remove</MenuItem>
                  </Menu>
                    <table className='mainTable'>
                      <tr className='tableRow'>
                        <td className='tabelHeadingImg'>
                          <div style={{ padding: '10px' }}>
                          </div>
                        </td>
                        <td className='tabelHeading' key="last_name" style={{ width: "15%" }}>
                          <TableSortLabel
                            // src={myIcon}
                            active={true}
                            direction={
                              valueToOrderBy === "last_name" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("last_name")}>

                            <div className='tableHeadingTop' >
                              Last Name
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading' key="first_name" style={{ width: "15%" }}>
                          <TableSortLabel
                            active={true}
                            direction={
                              valueToOrderBy === "first_name" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("first_name")}
                          >
                            <div className='tableHeadingTop' >
                              First Name
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading' key="address.city" style={{ width: "15%" }}>
                          <TableSortLabel
                            active={true}
                            direction={
                              valueToOrderBy === "address.city" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("city")}
                          >
                            <div className='tableHeadingTop' >
                              City
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading' key="total_transmission" style={{ width: "15%" }}>
                          <TableSortLabel
                            active={true}
                            direction={
                              valueToOrderBy === "total_transmission" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("total_transmission")}
                          >
                            <div className='tableHeadingTop' >
                              State
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading' key="total_transmission" style={{ width: "15%" }}>
                          <TableSortLabel
                          >
                            <div className='tableHeadingTop' >
                              Zip
                            </div>
                          </TableSortLabel>
                        </td>
                        <td className='tabelHeading' key="deviations" style={{ width: "15%" }}>
                          <TableSortLabel
                            active={true}
                            direction={
                              valueToOrderBy === "deviations" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("deviations")}


                          >
                            <div className='tableHeadingTop' >
                              Action
                            </div>
                          </TableSortLabel>

                        </td>
                      </tr>
                      {searchedPatient && searchedPatient.length > 0 ?
                        searchedPatient.map((item, index) => {

                          return (
                            <>
                              

                              <tr className='tabelDescAndRowAfterMapping' key={index}>
                                <td className='tabelDescAndRowAfterMapping'>
                                  <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                                  <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                                  </Avatar>
                                  </div>
                                </td>
                                <td className='tabelHeadingcsdpl'>
                                  <div className='tableDesc'
                                  >

                                    {item.last_name ? item.last_name : "-"}
                                  </div>
                                </td>
                                <td className='tabelHeadingcsdpl'>
                                  <div className='tableDesc'
                                  >

                                    {item.first_name ? item.first_name : "-"}
                                  </div>
                                </td>

                                <td className='tabelHeadingcsdpl'>
                                  <div className='tableDesc'
                                  >

                                    {item.address.city ? item.address.city : "-"}
                                  </div>
                                </td>
                                <td className='tabelHeadingcsdpl'>
                                  <div className='tableDesc'
                                  >

                                    {item.address.state ? item.address.state : "-"}
                                  </div>
                                </td>
                                <td className='tabelHeadingcsdpl'>
                                  <div className='tableDesc'
                                  >

                                    {item.address.zip ? item.address.zip : "-"}
                                  </div>
                                </td>
                                <td className='tabelHeadingcsdpl' style={{ height: "40px" }}>

                                  <div className="selectImage" onClick={(e) => {
                                    handleClickSelectPending(e, item);
                                  }}>
                                  </div>

                                </td>

                              </tr>
                            </>
                          )
                        }) : <tr>
                          <td colSpan="7" className='nodatafoundWrapper fnt22P'>No data found</td>
                        </tr>}

                    </table>
                  </div>
                  <br>
                  </br>

                  {searchedClinician && searchedClinician.length ?
                    <div className="pagination" style={{ marginRight: "20px", paddingTop: '20px', paddingBottom: '20px', display: "flex", justifyContent: "space-between", paddingRight: "35px" }}>
                      <div className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" style={{ marginLeft: "36px", display: "flex" }}
                      >
                         <div
                        className="downloadwrappercaresitesettings fnt24P">
                        <div style={{ paddingTop: "8px" }}> Download &nbsp;&nbsp;&nbsp; </div>
                        <img src={Excel} alt="Download" style={{cursor:"pointer"}} width="30px" height="30px" onClick={setDownload_file}></img>
                      </div> 
                      </div>
                      <div>
                        <Pagination
                          count={Math.ceil(activeListpage / 10)}
                          page={page}
                          onChange={changingPage}
                        />
                      </div>

                    </div> : null}
                </div> : null}

            {isPatientSelected === 'clinician' ?

              (
                <div>
                  <div className="caresitePatientslistHeadingWrapper" style={{
                    marginLeft: "69px",
                    marginBottom: "24px"
                  }}>
                    Clinicians list </div>
                  <div className={classes.root} style={{ overflow: "hidden" }}>
                    <AlertSnackBar
                      msg={snackMsg}
                      flag={openSnack}
                      onClose={handleSnackbar}
                      key={"cusSnackbar"}
                      anchorOrigin={customeSnackbarAnchor}
                      severity={snackSeverity}
                    />
                    <CustomsSnackbar
                      msg={snackErrorMessage}
                      flag={openErrorSnack}
                      onClose={handleErrorSnackbar}

                      anchorOrigin={customeErrorSnackbarAnchor}
                      severity={snackErrorSeverity}
                    />
                    <div className="searchbarWrapper" style={{ marginBottom: "30px", marginLeft: "64px" }}>
                      <div className="add-icon" style={{ backgroundColor: '#F2F7F8' }}>
                        <div style={{ marginTop: "10px" }}>
                          <i className="fas fa-search" />
                        </div>
                        <input
                          id="searchBox"
                          type="text"
                          placeholder="Search"
                          className="report_search"
                          // onKeyDown={(event) => handleEnterClinicians(event)} 
                          // onChange={(event) => handleSearchforClinicians(event)}
                          // value={clinicianSearchVal}
                          style={{ backgroundColor: '#F2F7F8' }}
                          onChange={handleSearchClinicians}
                        />
                      </div>
                    </div>

                    {/* clinic table */}
                    <div style={{ marginRight: '40px', marginLeft: "66px" }}>
                    <Menu
                      id="simple-menu-10"
                      anchorEl={anchorAccept}
                      keepMounted
                      open={openSelectAccept}
                      onClose={handleCloseSelectAccept}

                    >
                      <MenuItem onClick={openingrevoke}>Remove</MenuItem>
                    </Menu>
                      <table className='mainTable'>
                        <tr className='tableRow'>
                          <td className='tabelHeadingImg'>
                            <div style={{ padding: '10px' }}>

                            </div>

                          </td>
                          <td className='tabelHeading' key="last_name"
                            style={{ width: "12%" }} >
                            <TableSortLabel
                              // src={myIcon}
                              active={true}
                              direction={
                                valueToOrderBy === "last_name" ? orderDirection : "asc"
                              }
                              onClick={createSortHandler("last_name")}
                            >

                              <div className='tableHeadingTop'>
                                Last Name
                              </div>
                            </TableSortLabel>
                          </td>
                          <td className='tabelHeading' key="first_name"
                            style={{ width: "12%" }}>
                            <TableSortLabel
                              active={true}
                              direction={
                                valueToOrderBy === "first_name" ? orderDirection : "asc"
                              }
                              onClick={createSortHandler("first_name")}
                            >
                              <div className='tableHeadingTop' >
                                First Name
                              </div>
                            </TableSortLabel>
                          </td>
                          {/* <td className='tabelHeading' key="speciality"
                            style={{ width: "12%" }}>
                            <TableSortLabel
                              active={true}
                              direction={
                                valueToOrderBy === "speciality" ? orderDirection : "asc"
                              }
                              onClick={createSortHandler("speciality")}
                            >
                              <div className='tableHeadingTop' >
                                Speciality
                              </div>
                            </TableSortLabel>
                          </td> */}
                          <td className='tabelHeading'
                            //  key="address.city"
                            style={{ width: "12%" }}>
                            <TableSortLabel
                              active={true}
                              direction={
                                valueToOrderBy === "city" ? orderDirection : "asc"
                              }
                              onClick={createSortHandler("city")}
                            >
                              <div className='tableHeadingTop' >
                                City
                              </div>
                            </TableSortLabel>
                          </td>
                          <td className='tabelHeading'
                            // key="address.state"
                            style={{ width: "12%" }}>
                            <TableSortLabel
                              active={true}
                              direction={
                                valueToOrderBy === "state" ? orderDirection : "asc"
                              }
                              onClick={createSortHandler("state")}
                            >
                              <div className='tableHeadingTop' >
                                State
                              </div>
                            </TableSortLabel>
                          </td>
                          <td className='tabelHeading'
                            // key="address.zip"
                            style={{ width: "12%" }}>
                            <TableSortLabel
                              active={true}
                              direction={
                                valueToOrderBy === "zip" ? orderDirection : "asc"
                              }
                              onClick={createSortHandler("zip")}
                            >
                              <div className='tableHeadingTop' >
                                Zip
                              </div>
                            </TableSortLabel>
                          </td>
                          <td className='tabelHeading' key="deviations"
                            style={{ width: "12%" }}>
                            <div className='tableHeadingTop' >
                              Acces
                            </div>
                          </td>
                        </tr>

                        {
                          searchedClinician && searchedClinician.length ? searchedClinician.map((item, index) => {
                            return (
                              <>
                                

                                <tr className='tabelDescAndRowAfterMapping' key={index}>
                                  <td className='tabelDescAndRowAfterMapping' style={{ width: "10%" }} onClick={(e) => {
                                        checkifconnectedtodevice(item);
                                      }}>
                                    <div style={{ padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                                    <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}>
                                    </Avatar>
                                    </div>
                                  </td>
                                  <td className='tabelHeadingcsdcl' onClick={(e) => {
                                        checkifconnectedtodevice(item);
                                      }}>
                                    <div className='tableDesc'
                                    >

                                      {item.last_name ? item.last_name : "-"}
                                    </div>
                                  </td>
                                  <td className='tabelHeadingcsdcl' onClick={(e) => {
                                        checkifconnectedtodevice(item);
                                      }}  >
                                    <div className='tableDesc'
                                    >

                                      {item.first_name ? item.first_name : "-"}
                                    </div>
                                  </td>
                                  {/* <td className='tabelHeadingcsdcl'>
                                    <div className='tableDesc'
                                    >

                                      {item.speciality ? item.speciality : "-"}
                                    </div>
                                  </td> */}
                                  <td className='tabelHeadingcsdcl' onClick={(e) => {
                                        checkifconnectedtodevice(item);
                                      }}>
                                    <div className='tableDesc'
                                    >

                                      {item.address && item.address.city ? item.address.city : "-"}
                                    </div>
                                  </td>
                                  <td className='tabelHeadingcsdcl' onClick={(e) => {
                                        checkifconnectedtodevice(item);
                                      }}>
                                    <div className='tableDesc'
                                    >

                                      {item.address && item.address.state ? item.address.state : "-"}
                                    </div>
                                  </td>
                                  <td className='tabelHeadingcsdcl' onClick={(e) => {
                                        checkifconnectedtodevice(item);
                                      }}>
                                    <div className='tableDesc'
                                    >

                                      {item.address && item.address.zip ? item.address.zip : "-"}
                                    </div>
                                  </td>


                                  <td className='tabelHeadingcsdpl' style={{ height: "40px" }}>

                                    <div className="selectImage"
                                      onClick={(e) => {
                                        handleClickSelectPending(e, item);
                                      }}
                                    >
                                    </div>
                                  </td>
                                </tr>
                              </>)
                          }) : <tr>
                            <td colSpan="8" className='nodatafoundWrapper fnt22P'>No data found</td>
                          </tr>}
                      </table>
                    </div>
                    <br>
                    </br>

                    {searchedClinician && searchedClinician.length ?
                      <div className="pagination" style={{ marginRight: "20px", paddingTop: '20px', paddingBottom: '20px', display: "flex", justifyContent: "space-between", paddingRight: "35px" }}>
                        <div className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" style={{ marginLeft: "36px", display: "flex" }}
                        >
                           <div
                        className="downloadwrappercaresitesettings fnt24P">
                        <div style={{ paddingTop: "8px" }}> Download &nbsp;&nbsp;&nbsp; </div>
                        <img src={Excel} alt="Download" style={{cursor:"pointer"}} width="30px" height="30px" onClick={setDownload_file}></img>
                      </div>
                       </div>
                        <div>
                          <Pagination
                            count={Math.ceil(activeListpage / 10)}
                            page={page}
                            onChange={changingPage}
                          />
                        </div>

                      </div> : null}
                  </div>
                </div>
              ) : null}
          </div>
          <table id="table"></table>

          {isPatientSelected === 'clinicadmin' || isPatientSelected === 'patient' || isPatientSelected === 'clinician' ?
            <SeparatorBottomSection />
            : null}
          <div style={{ backgroundColor: "#F2F7F8", paddingBottom: '80px' }}>
            <div className="flex flex-wrap anouncenementHeadingWrapper">
              <div className="lg:w-10/12 caresiteDashboardbottomCard">

                <FooterCard
                  clinicName={footercareName}
                  city={footercareArea ? `${footercareArea.city}, ` : " "}
                  address={footercareArea ? `${footercareArea.addressline1}, ` : " "}
                  state={footercareArea ? `${footercareArea.state}, ` : " "}
                  postal={footercareArea ? `${footercareArea.postal}` : " "}
                  phonenumber={footercarePhoneno ? footercarePhoneno : " "}
                  website={footercareWebsite ? footercareWebsite : " "}
                  link="Care site details"
                  callbackfromfooter={careSiteDetailsPopupOpenHandler}
                  footercareclinicpuuid={footercareclinicpuuid}
                />
              </div>
            </div>
          </div>
        </div>

      </>)}
    </React.Fragment>
  );
};

CaresiteSettingDashboard.defaultProps = {
  color: "light",
};

CaresiteSettingDashboard.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
const mapStateToProps = (store) => {
  return {
    allClinicList: store.invitation.allcliniclist,
    invitationList: store.invitation.invitationList,
    user: store.user.user,
    patientList: store.invitation.patientList,
    adminTable: store.user.adminTable,
    clinicListName: store.invitation.allcliniclist,
    addUserInvitation: store.invitation.addUserInvitation,
    flagInput: store.invitation.flagInput,
    addingClinicSuccessAlert: store.invitation.addingClinicSuccessAlert,
    addingclinicerrormsg: store.invitation.addingclinicerrormsg,
    clinicItem: store.invitation.clinicProfileUpdate,
    userSpClinicList: store.user.userLogIn,
    enrolledCounts: store.user.enrolledCounts,
    searchedClinician: store.user.searchedClinician,
    searchedClinic: store.user.searchedClinic,
  };
};

export default connect(mapStateToProps, {
  // fetchInvitation,
  addClinicList,
  openInvitePopup,
  openFilterPopup,
  updateClinicProile,
  enrolledCount,
  revokeUser,
  loadOtherUserProfileForViewing,
  searchClinician,
  fetchClinicByID,
  searchClinic,

})(CaresiteSettingDashboard);

const Popup = (props) => {
  const inputRefs = React.useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);
  const newClinicObj = {};
  const classes = useStyles();
  const [values, setValues] = React.useState({});

  const onAddClinicHandler = (val) => {
    newClinicObj.key = Math.random().toString();
    newClinicObj.accountnumber = values.accountnumber;
    newClinicObj.clinicname = values.clinicname;
    newClinicObj.phonenumber = values.phonenumber;
    newClinicObj.address = values.address;
    newClinicObj.city = values.city;
    newClinicObj.country = values.country;
    newClinicObj.postal = values.postal;
    newClinicObj.website = values.website;

    props.clinicLink(newClinicObj);
  };

  const handleInputChange = (name, value) => {
    if (value.length > 0) {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const onSubmit = async () => {
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate();

      if (!valid) {
        isValid = false;
      }
    }
    if (isValid === true) {
      onAddClinicHandler(values);
    }
  };

  return (
    <>
      <Dialog
        open={props.showModal}
        maxWidth="md"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            background: "rgba(14, 165, 233)",
            color: "white",
            fontSize: "1rem",
          }}
        >
          {"Add Clinic"}
          <IconButton
            aria-label="close"
            onClick={props.callBack}
            style={{
              position: "absolute",
              right: "20px",
              top: "6px",
              color: "white",
            }}
          >
            <CloseIcon style={{cursor:'pointer'}} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div>
            <form>
              <div className="accountwrapper">
                <div className="accountColWrapper">
                  <label
                    className="block text-blueGray-600 text-xs font-bold mb-2 mt-3"
                    htmlFor="grid-password"
                  >
                    Account Number
                  </label>
                  <InputField
                    type="text"
                    className="inputwrapper"
                    ref={inputRefs.current[0]}
                    handleInputChange={handleInputChange}
                    validation="required|max:12|min:12|accountnumber"
                    placeholder="Account number"
                    name="accountnumber"
                  />

                </div>

                <div className="accountColWrapper">
                  <label
                    className="block text-blueGray-600 text-xs font-bold mb-2 mt-3"
                    htmlFor="grid-password"
                  >
                    Care Site Name
                  </label>

                  <InputField
                    type="text"
                    className="inputwrapper"
                    ref={inputRefs.current[1]}
                    handleInputChange={handleInputChange}
                    validation="required|min:6"
                    placeholder="Clinic name"
                    name="clinicname"
                  />
                </div>

                <div className="accountColWrapper">
                  <label
                    className="block text-blueGray-600 text-xs font-bold mb-2 mt-3"
                    htmlFor="grid-password"
                  >
                    Phone Number
                  </label>
                  <InputField
                    type="number"
                    className="inputwrapper"
                    ref={inputRefs.current[2]}
                    handleInputChange={handleInputChange}
                    validation="required|min:10|max:10|onlynumber"
                    placeholder="Phone number"
                    name="phonenumber"
                  />

                </div>
              </div>

              <div className="accountColWrapper">
                <label
                  className="block text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="address"
                >
                  Address
                </label>
                <InputField
                  type="text"
                  className="addressinputwrapper"
                  ref={inputRefs.current[3]}
                  handleInputChange={handleInputChange}
                  validation="required"
                  placeholder="Street name"
                  name="address"
                />
              </div>

              <div className="accountwrapper">
                <div className="accountColWrapper">
                  <label
                    className="block text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="address"
                  >
                    City
                  </label>
                  <InputField
                    type="text"
                    className="inputwrapper"
                    ref={inputRefs.current[4]}
                    handleInputChange={handleInputChange}
                    validation="required"
                    placeholder="city"
                    name="city"
                  />

                </div>

                <div className="accountColWrapper">
                  <label
                    className="block text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="address"
                  >
                    Country
                  </label>
                  <InputField
                    type="text"
                    className="inputwrapper"
                    ref={inputRefs.current[5]}
                    handleInputChange={handleInputChange}
                    validation="required"
                    placeholder="country"
                    name="country"
                  />
                </div>

                <div className="accountColWrapper">
                  <label
                    className="block text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="address"
                  >
                    Postal Code
                  </label>
                  <InputField
                    type="number"
                    className="inputwrapper"
                    ref={inputRefs.current[6]}
                    handleInputChange={handleInputChange}
                    validation="required|min:6|max:6|onlynumber"
                    placeholder="postal code"
                    name="postal"
                  />
                </div>
              </div>

              <div className="accountColWrapper">
                <label
                  className="block text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="address"
                >
                  Website
                </label>
                <InputField
                  type="url"
                  className="addressinputwrapper"
                  ref={inputRefs.current[7]}
                  handleInputChange={handleInputChange}
                  validation="required|website"
                  placeholder="website"
                  name="website"
                />
              </div>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} color="primary">
            Save
          </Button>
          <Button onClick={props.callBack} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AlertSnackBar = (
  {
    flag,
    onClose,
    anchorOrigin,
    msg,
    severity,
  }) => {
  return (

    <Snackbar
      anchorOrigin={
        anchorOrigin
          ? anchorOrigin
          : { vertical: "top", horizontal: "right" }
      }
      open={flag}
      //   autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity ? severity : "success"}>
        {msg}
      </Alert>
    </Snackbar>
  );
};
