import React from 'react'
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const AbmAdminActivatePopUp = (props) => {


  function onSubmit() {
    props.statusActiveCallBackToParent("Blocked");
  }


  return (
    <Dialog
      open={props.isOpen}
      onClose={props.callBackToParent}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >


      <DialogTitle
        id="alert-dialog-title"
        style={{ background: "rgba(14, 165, 233)", color: "white", fontSize: "1rem" }}
      >Confirmation

        <IconButton
          aria-label="close"
          onClick={props.callBackToParent}
          style={{
            position: "absolute",
            right: "20px",
            top: "6px",
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <h1>
            Are you Sure You want to Activate ?
          </h1>
        </div>
        <DialogActions>
          <Button onClick={props.callBackToParent} color="primary">
            No
          </Button>
          <Button onClick={() => { onSubmit() }} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default AbmAdminActivatePopUp;


