import React from 'react'
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import './AbmAdminRevokPopUp.css';

const useStyles = makeStyles((theme) => ({
  buttons: {
    backgroundColor: '#F2F4F8',
    boxShadow: '#6A768F80 0px 3px 6px',
    color: '#3D446F',
    fontFamily: 'Roboto',
    fontSize: '14px',
    marginBottom: '8px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    padding: '10px 0px',
    borderRadius: '10px',
    fontWeight: 'bold',
    textAlign: 'center',
    width: "40%",
    marginTop: "20px",
  },
}));

const AbmAdminRevokePopUp = (props) => {
  const classes = useStyles();


  function onSubmit() {
    props.statusRevokeCallBackToParent();
  }
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.callBackToParent}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        className="inviteModalContainer"
      >Confirmation
      </DialogTitle>
      <DialogContent className="revokecardbody" style={{ backgroundColor: "#f2f4f8", minWidth: "580px" }}>
        <div style={{ padding: '20px' }}>
          {props.successValue === 'accepted' ?
            <h3 className="middletextForrevokepopup">
              Are you sure to accept the invitation from {props.actionCaresiteValue} ?
            </h3>
            : props.successValue === 'resend' ?
              <h3 className="middletextForrevokepopup">
                Are you sure to resend invitation to {props.actionUserValue} from {props.actionCaresiteValue}?
              </h3>
              : props.successValue === 'revoke' ? 
              <h3 className="middletextForrevokepopup">
                Are you sure you want to revoke access?
              {/* Are you sure to resend invitation to {props.actionUserValue} from {props.actionCaresiteValue}? */}
            </h3>
              : <h3 className="middletextForrevokepopup">
                Are you sure you want to withdraw the invitation sent to {props.actionUserValue} from {props.actionCaresiteValue}
                {/* Are you sure you want to remove {props.actionUserValue} from {props.actionCaresiteValue}? */}
              </h3>}
        </div>

        <div className="bottomContainerForrevoke">
        <Button className="cancelbuttonforrevoke" onClick={() => { onSubmit() }} autoFocus>
            Yes
          </Button>
          <div className="vl"></div>
          <Button className="cancelbuttonforrevoke" onClick={props.callBackToParent}>
            No
          </Button>
          
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default AbmAdminRevokePopUp;


