import {
  SELECTED_USER_CHAT,
  GET_ALL_MESSAGE,
  PREV_CONVERSATION,
  OCCUPANTS_IDS,
  CHAT_DIALOG_ID,
  SELECTED_GROUP_CHAT,
  OPPONENENT_DETAILS,
  CURR_DIALOG,
} from "../action.list";

import apiAbmBlaze, { GET_PROFILE_QUICKID } from "../../api";

import QB from "quickblox/quickblox";
import store from "../../../src/store";
import uniq from "lodash/uniq";

const QB_CREDS = {
  appId: process.env.REACT_APP_APPID || 99021, // QUICKBLOX_APP_ID
  // authKey: "K-4gFbD59PZuEms", // QUICKBLOX_AUTH_KEY
  accountKey: process.env.REACT_APP_ACCOUNTKEY || "VsMF7MN7WjB-aXy2mpxq",
};

const QB_CONFIG = {
  debug: {
    mode: 0, // the SDK will be printing logs to console.log
  },
  on: {
    sessionExpired: function () {
      alert("Session is expired, the page will be reload.");
    },
  },
};

const CONSTANTS = {
  DIALOG_TYPES: {
    CHAT: 3,
    GROUPCHAT: 2,
    PUBLICCHAT: 1,
  },
  ATTACHMENT: {
    TYPE: "image",
    BODY: "[attachment]",
    MAXSIZE: 2 * 1000000, // set 2 megabytes,
    MAXSIZEMESSAGE: "Image is too large. Max size is 2 mb.",
  },
  NOTIFICATION_TYPES: {
    NEW_DIALOG: "1",
    UPDATE_DIALOG: "2",
    LEAVE_DIALOG: "3",
  },
};

let chat_userId = null;
let chat_token = null;
let currentDialogID = null;
export const chatInit = (profile) => async (dispatch) => {
  const { quickbloxId, quickblox_token } = profile;
  chat_userId = quickbloxId;
  chat_token = quickblox_token;

  QB.init(
    quickblox_token,
    Number(QB_CREDS.appId),
    null,
    QB_CREDS.accountKey,
    QB_CONFIG
  );
  const userCredentials = {
    userId: quickbloxId,
    password: quickblox_token,
  };

  QB.chat.connect(userCredentials, (error, contactList) => {
    const state = store.getState();
    const currDialog = state.quickblox.currDialog;
    dispatch(chatDialogList(true));
    function onMessage(userId, message) {
      dispatch(chatDialogList(true));
      if (
        currDialog === message.dialog_id ||
        currentDialogID === message.dialog_id
      ) {
        dispatch(loadMessagesToId(message.dialog_id, message.id));
        dispatch(chatDialogList(true));
      }
    }

    QB.chat.onMessageListener = onMessage;
  });
};

export const chatDialogList = (isInit) => async (dispatch) => {
  const filters = {};

  QB.chat.dialog.list(filters, (error, dialogs) => {
    if (!error) {
      dispatch({
        type: GET_ALL_MESSAGE,
        payload: {
          allMessageItem: dialogs,
        },
      });

      let all_occupants = [];

      dialogs && dialogs.items && dialogs.items.forEach((_dialog) => {
        all_occupants = all_occupants.concat(_dialog.occupants_ids);
      });

      all_occupants = uniq(all_occupants);

      dispatch(loadOccupantsProfiles(all_occupants));
    }
  });
};
export const chatDialogCreate = (occupants_ids) => async (dispatch) => {
  dispatch({
    type: PREV_CONVERSATION,
    payload: {
      prevConversation: [],
      conversationFlag: false,
    },
  });
  const params = {
    type: CONSTANTS.DIALOG_TYPES.CHAT,
    occupants_ids,
  };

  if (occupants_ids.length > 1) {
    params.type = CONSTANTS.DIALOG_TYPES.GROUPCHAT;
    params.name = "Group dialog";
  }
  QB.chat.dialog.create(params, (error, conversation) => {
    if (conversation) {
      dispatch(chatDialogList(true));
      dispatch({
        type: CHAT_DIALOG_ID,
        payload: {
          chatDialogId: conversation._id,
        },
      });
      currentDialogID = conversation._id;
    }
    if (
      conversation &&
      conversation.last_message &&
      conversation.last_message == null
    ) {
      dispatch({
        type: OCCUPANTS_IDS,
        payload: {
          occupants_ids: occupants_ids,
          conversation: conversation,
          conversationFlag: false,
        },
      });
      dispatch(
        loadMessagesToId(conversation._id, conversation.last_message_id)
      );
      dispatch({
        type: CURR_DIALOG,
        payload: { currDialog: conversation._id },
      });
    } else {
      dispatch({
        type: OCCUPANTS_IDS,
        payload: {
          occupants_ids: occupants_ids,
          conversation: conversation,
          conversationFlag: true,
        },
      });

    }
  });
};
export const loadMessagesToId = (dialogId, messageId) => async (dispatch) => {
  let allMessages = [];
  let limit = 50;
  let skip = 0;
  let canLoadMore = false;
  let found = false;
  currentDialogID = dialogId;
  dispatch({
    type: CURR_DIALOG,
    payload: { currDialog: dialogId },
  });


  const loadAndCheck = function () {
    if (found) {
      dispatch({
        type: PREV_CONVERSATION,
        payload: {
          prevConversation: allMessages,
          conversationFlag: true,
          selectedUserFlag: true,
        },
      });
      if (allMessages[0].chat_dialog_id === dialogId) {
        dispatch({
          type: CHAT_DIALOG_ID,
          payload: {
            chatDialogId: dialogId,
          },
        });
      }
      return;
    }

    const params = {
      chat_dialog_id: dialogId,
      sort_desc: "date_sent",
      limit,
      skip,
    };

    QB.chat.message.list(params, (error, messages) => {
      if (!error && messages.items) {
        allMessages = allMessages.concat(messages.items);
        canLoadMore = messages.items.length === limit;

        for (let i = 0; i < messages.items.length; i++) {
          const item = messages.items[i];
          if (item._id === messageId) {
            found = true;
            break;
          }
        }

        if (!found) {
          skip += limit;
        }
        loadAndCheck();
      }
    });
  };

  loadAndCheck();
};

export const selectedUserChat = (val) => async (dispatch) => {
  if (val) {
    dispatch(loadOpoonentsProfiles(val.occupants_ids));
  }
  await dispatch({
    type: SELECTED_USER_CHAT,
    payload: { seletedUser: val, selectedUserFlag: true },
  });
};
export const currentDiaglogID = (val) => async (dispatch) => {
  await dispatch({
    type: CURR_DIALOG,
    payload: { currDialog: val._id },
  });
};

export const selectedGroupChat = (val) => async (dispatch) => {

  await dispatch({
    type: SELECTED_GROUP_CHAT,
    payload: { selectedGroup: val, selectedUserFlag: true },
  });
};

export const sendMessage = (dialog, msg, callback) => async (dispatch) => {
  const message = JSON.parse(JSON.stringify(msg));
  const jidOrUserId =
    dialog.xmpp_room_jid || getRecipientUserId(dialog.occupants_ids);

  try {
    if (dialog && dialog.xmpp_room_jid) {
      QB.chat.muc.join(jidOrUserId, function (error, result) {
        if (result) {
          message.id = QB.chat.send(jidOrUserId, msg);
          message.extension.dialog_id = dialog._id;
        }
      });
    } else if (dialog && !dialog.xmpp_room_jid) {
      message.id = QB.chat.send(jidOrUserId, msg);
      message.extension.dialog_id = dialog._id;
    }
    const newMessage = fillNewMessageParams(chat_userId, message);

    if (newMessage) {
      dispatch(loadMessagesToId(newMessage.chat_dialog_id, newMessage._id));
      dispatch(chatDialogList(true));
    }
  } catch (e) {
    if (e.name === "ChatNotConnectedError") {
    }
  }

  try {
  } catch (e) {
    console.log("[error]", e);
    if (e.name === "ChatNotConnectedError") {
    }
  }
};
export const submitSendMessage =
  (dialogId, message, attachments = [], callback) =>
    async (dispatch) => {
      currentDialogID = dialogId;
      dispatch({
        type: CURR_DIALOG,
        payload: { currDialog: dialogId },
      });
      const state = store.getState();
      const allDialogs = state.quickblox.allMessageItem.items;
      const dialog = allDialogs.find((d) => d._id === dialogId);

      const msg = {
        type: dialog.type === 3 ? "chat" : "groupchat",
        body: message,
        extension: {
          save_to_history: 1,
          dialog_id: dialogId,
        },
        markable: 1,
      };

      if (attachments.length > 0) {
        msg.extension.attachments = attachments;
      }
      //   // Don't send empty message
      if (!msg.body) return false;

      dispatch(sendMessage(dialog, msg, callback));
    };

export const getRecipientUserId = function (users) {
  if (users && users.length === 2) {
    return users.filter(function (user) {
      if (user !== chat_userId) {
        return user;
      }
    })[0];
  }
};
const fillNewMessageParams = function (userId, msg) {
  const message = {
    _id: msg.id,
    attachments: [],
    created_at: Date.now(),
    date_sent: +msg.extension.date_sent || Math.floor(Date.now() / 1000),
    delivered_ids: [userId],
    message: msg.body,
    read_ids: [userId],
    sender_id: userId,
    chat_dialog_id: msg.extension.dialog_id,
    selfReaded: userId === chat_userId,
    read: 0,
  };

  if (msg.extension.attachments) {
    const attachments = msg.extension.attachments;

    for (let i = 0; i < attachments.length; i++) {
      attachments[i].src = getSrcFromAttachmentId(attachments[i].id);
    }

    message.attachments = attachments;
  }

  if (message.message === CONSTANTS.ATTACHMENT.BODY) {
    message.message = "";
  }

  if (msg.extension.notification_type) {
    message.notification_type = msg.extension.notification_type;
  }

  if (msg.extension.new_occupants_ids) {
    message.new_occupants_ids = msg.extension.new_occupants_ids;
  }

  message.status =
    userId !== chat_userId ? getMessageStatus(message) : undefined;
  return message;
};
const getMessageStatus = (message) => {
  if (message.sender_id !== chat_userId) {
    return undefined;
  }

  const deleveredToOcuupants = checkIsMessageDeliveredToOccupants(message),
    readedByOcuupants = checkIsMessageReadedByOccupants(message),
    status = !deleveredToOcuupants
      ? "sent"
      : readedByOcuupants
        ? "read"
        : "delivered";

  return status;
};
const checkIsMessageReadedByOccupants = (message) => {
  const readIds = message.read_ids,
    isReaded = readIds.some(function (id) {
      return id !== chat_userId;
    });

  return isReaded;
};
const checkIsMessageDeliveredToOccupants = (message) => {
  const deliveredIds = message.delivered_ids,
    isDelivered = deliveredIds.some(function (id) {
      return id !== chat_userId;
    });
  return isDelivered;
};
export const getSrcFromAttachmentId = (id) => {
  return QB.content.publicUrl(id) + ".json?token=" + chat_token;
};

export const loadOccupantsProfiles = (val) => async (dispatch) => {
  const state = store.getState();
  let allDialogue = state.quickblox.allMessageItem.items;
  let currentUserProfile = state.user.user;

  let filterdata = val.filter((item) => {
    return item != chat_userId;
  });
  if (filterdata) {
    apiAbmBlaze
      .post(GET_PROFILE_QUICKID, { quickBloxID: filterdata })
      .then(async (res) => {
        let dialogs = allDialogue.map((item) => {
          const dialogUsers = getDialogUsers(
            item,
            res.data.profile,
            currentUserProfile
          );
          const _avatars = dialogUsers.map((user) => {
            return UC_getImageUrl(user.photo_uuid);
          });
          return {
            ...item,
            name:
              getDialogName(item, res.data.profile, currentUserProfile) &&
                Array.isArray(
                  getDialogName(item, res.data.profile, currentUserProfile)
                    .longName
                )
                ? getDialogName(
                  item,
                  res.data.profile,
                  currentUserProfile
                ).longName.join(" + ")
                : getDialogName(item, res.data.profile, currentUserProfile)
                  .longName,
            longName:
              getDialogName(item, res.data.profile, currentUserProfile) &&
                Array.isArray(
                  getDialogName(item, res.data.profile, currentUserProfile)
                    .longName
                )
                ? getDialogName(
                  item,
                  res.data.profile,
                  currentUserProfile
                ).longName.join(" + ")
                : getDialogName(item, res.data.profile, currentUserProfile)
                  .longName,
            shortName:
              getDialogName(item, res.data.profile, currentUserProfile) &&
                Array.isArray(
                  getDialogName(item, res.data.profile, currentUserProfile)
                    .shortName
                )
                ? getDialogName(
                  item,
                  res.data.profile,
                  currentUserProfile
                ).shortName.join(" + ")
                : getDialogName(item, res.data.profile, currentUserProfile)
                  .shortName,
            // longName,
            avatars: _avatars,
          };
        });
        if (dialogs) {
          dispatch({
            type: GET_ALL_MESSAGE,
            payload: {
              allChats: dialogs,
            },
          });
        } else {
          alert("Failed to get dialog");
        }
      })
      .catch((error) => {
        console.log("Error----", error);
      });
  }
};

export const loadOpoonentsProfiles = (val) => async (dispatch) => {
  if (val) {
    apiAbmBlaze
      .post(GET_PROFILE_QUICKID, { quickBloxID: val })
      .then(async (res) => {
        await dispatch({
          type: OPPONENENT_DETAILS,
          payload: { opponentsDetails: res.data.profile },
        });
      });
  }
};

export const getDialogUsers = (dialog, users, profile) => {
  const dialogUsers = [];

  dialog && dialog.occupants_ids && dialog.occupants_ids.forEach((occupant_id) => {
    if (occupant_id !== profile.quickbloxId) {
      const user = users[occupant_id];
      if (user) {
        dialogUsers.push(user);
      }
    }
  });
  return dialogUsers;
};

export const UC_getImageUrl = (uuid) => {
  if (!uuid || (uuid && uuid.length <= 0)) {
    return null;
  } else {
    return `https://ucarecdn.com/${uuid}/`;
  }
};
export const getDialogName = (dialog, users, profile) => {
  const userProfile = users[getRecipientUserId(dialog.occupants_ids)];
  if (userProfile) {
    return {
      longName: userProfile.first_name
        ? `${userProfile.first_name ? userProfile.first_name : ""} ${userProfile.last_name ? userProfile.last_name : ""
        }`
        : userProfile.email,
      shortName: userProfile.first_name
        ? `${userProfile.first_name
          ? userProfile.first_name.charAt(0).toUpperCase()
          : ""
        }${userProfile.last_name
          ? userProfile.last_name.charAt(0).toUpperCase()
          : ""
        }`
        : userProfile.email,
    };
  } else {
    const userNames = [];
    const userHeaderNames = [];

    dialog.occupants_ids.forEach((occupant_id) => {
      if (occupant_id !== profile.quickblox_id) {
        const user = users[occupant_id];
        if (user) {
          userHeaderNames.push(
            user.first_name
              ? `${user.first_name ? user.first_name.charAt(0).toUpperCase() : ""
              }${user.last_name ? user.last_name.charAt(0).toUpperCase() : ""
              }`
              : user.email
          );
          userNames.push(
            user.first_name
              ? `${user.first_name ? user.first_name : ""}${user.last_name ? user.last_name : ""
              }`
              : user.email
          );
        }
      }
    });

    return { shortName: userHeaderNames, longName: userNames };
  }
};
export const abCreateAndUpload = function (file) {
  return new Promise((resolve, reject) => {
    QB.content.createAndUpload(
      {
        public: false,
        file: file,
        name: file.name,
        type: file.type,
        size: file.size,
      },
      function (err, response) {
        if (err) {
          reject(err);
        } else {
          resolve(response);
        }
      }
    );
  });
};

export const uploadFilesAndGetIds =
  (file, dialogId, callback) => async (dispatch) => {
    let dialogID = currentDialogID;
    if (file.size >= CONSTANTS.ATTACHMENT.MAXSIZE) {
      return alert(CONSTANTS.ATTACHMENT.MAXSIZEMESSAGE);
    }

    // TODO set preview

    abCreateAndUpload(file)
      .then((response) => {
        // TODO remove preview

        const attachments = [
          {
            id: response.uid,
            type:
              response.content_type == "image/png" ||
                response.content_type == "image/jpeg" ||
                response.content_type == "image/gif" ||
                response.content_type == "image/jpg" ||
                response.content_type == "image/tiff"
                ? CONSTANTS.ATTACHMENT.TYPE
                : "file",
          },
        ];
        dispatch(
          submitSendMessage(dialogID, CONSTANTS.ATTACHMENT.BODY, attachments)
        );
      })
      .catch((err) => {
        // TODO remove preview

        alert("ERROR: " + err.detail);
      });
  };
