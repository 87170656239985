import { FETCH_DEVICE_PERFORMANCE_STATS ,DELETE_DEVICE_PERFORMANCE_STATS} from "../action.list";
export const INIT_DEVICE_STATS ={
  pageData: [],
  totalPages: 1,
  averages: {
    averageSingleCoreMemoryUsage: 0.0,
    averageSingleCoreCpuUsage: 0.0,
    averageDualCoreMemoryUsage: 0.0,
    averageDualCoreCpuUsage: 0.0,
    averageSingleCoreTemp: 0.0,
    averageDualCoreTemp: 0.0,
  },
  totalRecords:0
}
let defaultState = {deviceStats:{...INIT_DEVICE_STATS}}
export const deviceReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DEVICE_PERFORMANCE_STATS:
      return { ...state, ...payload };
    case DELETE_DEVICE_PERFORMANCE_STATS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
