import {
  ACCEPTED_SHARING_REDUX,
  DIRECT_CHAT,
  GET_ACCEPTED_SHARING_REDUX,
  SHOW_CHAT_WINDOW,
  RESET_ALL_STATE
} from "../action.list";

let defaultState = {
  acceptedSharing: [],
  directChat: false,
  acceptedSharingFlag: false,
  showChatWindowFlag: false,
};

export const sharingReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACCEPTED_SHARING_REDUX:
      return { ...state, ...payload };
    case DIRECT_CHAT:
      return { ...state, ...payload };
    case GET_ACCEPTED_SHARING_REDUX:
      return { ...state, ...payload };
    case SHOW_CHAT_WINDOW:
      return { ...state, ...payload };
      case RESET_ALL_STATE:
        return defaultState;
    default:
      return state;
  }
};
