import React, { useEffect } from 'react';
import './Popup.css';
import './CreatenewPassword.css';
import piccreate from '../../assets/img/lock_reset_black.svg';
import { connect } from "react-redux";
import { changeUserPassword, otpFlag, passwrdVerify } from "../../redux/user/user.action";
import InputField from "../../components/Inputs_Validation/index";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { base64Encode } from '../../utils';


const CreatenewPassword = (props) => {
    const [password, setPassword] = React.useState('');
    const [oldPassword, setOldPassword] = React.useState('');
    const [email, setEmail] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [passAlert, setPssAlert] = React.useState(false);
    const [newPasswordDiv, setNewPasswordDiv] = React.useState(false);
    const [confirmAccount, setConfirmAccount] = React.useState(true);
    const [incorrectMsgDiv, setIncorrectMsgDiv] = React.useState(false);
    const [valid,setvalid]=React.useState(false);
    const [passHide, setPassHide] = React.useState(false);
    const [passHideCreateNewPassword, setPassHideCreateNewPassword] = React.useState(false);
    const [passHideConfirmPassword, setPassHideConfirmPassword] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const handleSnackbar = () => {
        setOpenSnack(false);
    };
    const [snackSeverity, setSnackSeverity] = React.useState("");
    const [msg, setmsg] = React.useState("");
    const [values, setValues] = React.useState({});
    const inputRefs = React.useRef([
        React.createRef(),
        React.createRef(),
        React.createRef(),
      ]);
    const scrollRef = React.useRef(null);
    useEffect(() => {
        if (props.passwrdResposne === "Correct Paswword") {
            setIncorrectMsgDiv(false);
            setOpenSnack(false);
        } else if (props.passwrdResposne === "Incorrect password") {
        setmsg("Incorrect Password! Please check your password and try again.")
        setSnackSeverity('error')
        setOpenSnack(true)
        }
        //setTimeout(()=>{setIncorrectMsgDiv(false)},3000);
    }, [props.passwrdResposne]);
    useEffect(() => {
        if (props.sameasoldpassword === "New password cannot be same as Old password!") {
        setmsg("New password cannot be same as Old password!")
        setSnackSeverity('error')
        setOpenSnack(true)
        } 
        else if (props.emailOtpMatch) {
            setEmail(true);
            props.onCreatePswdClose(); 
            } 
    }, [props.sameasoldpassword,props.passwordUpdated]);
    const emailpopup = () => {
        if (!values.confirmpassword){
        setmsg("Please Confirm your Password to continue.")
        setSnackSeverity('error')
        setOpenSnack(true)
        }
        
            
        if (values.password === values.confirmpassword) {
            let newConfirmPassword = base64Encode(values.confirmpassword);
            props.changeUserPassword({ newConfirmPassword, id: props.id });
            }
        else {
            setPssAlert(true);
        }
        
    }
    const onSubmit = async () => {
        let isValid = true;
        for (let i = 0; i < inputRefs.current.length; i++) {
          if (inputRefs.current[i].current === null) {
            continue;
          }
          const valid = inputRefs.current[i].current.validate();
          
          if (!valid) {
            inputRefs.current[i].current.focusInput();
            isValid = false;
            setvalid(false)
            throw 'password required'
          }
          else{setvalid(true)}
        }}
    const checkPassword = async () => {
        try{
            await onSubmit();
            setEmail(!email);
            scrollRef.current.scrollIntoView({ behavior: "smooth" })
        }
        catch(er){
            console.log('fn checkPassword Error',er)
        }
    }
    const alert = "Passowrd does not match!";

    const oldPasswordCheck = () => {
        if(!values.oldpassword)
        {
        setmsg("Please enter your password to continue!")
        setSnackSeverity('error')
        setOpenSnack(true)
        }
        else{
        if(!(values.oldpassword.length<6)){
        props.passwrdVerify(values.oldpassword?values.oldpassword:null, props.id);
        setNewPasswordDiv(true);
            }
        }
        }
    const handleInputChange = (name, value) => {
          setValues({
            ...values,
            [name]: value,
          });
      };

    const changeHandler = () => {
        setIncorrectMsgDiv(false);
        props.otpFlag(false)
    }
    return (
        <>
           <RegisterAlertMessage
                flag={openSnack}
                onClose={handleSnackbar}
                severity={snackSeverity}
                key={"cupSnackbar"}
                msg={msg}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            />
            <div className="PopuupContainer">
                {confirmAccount ?
                    <div className='w-full'>
                        <h1 className="cnfrmac">
                            <i className="fa fa-user-circle confirmaccount"></i>Confirm your account password</h1>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className="inputfield">
                                <InputField
                                    type={"password"}
                                    className="inputfiedforchangepwd fnt24P"
                                    ref={inputRefs.current[0]}
                                    handleInputChange={handleInputChange}
                                    placeholder="Password"
                                    validation="required|min:6"
                                    name="oldpassword"
                                    isPassword={true}
                                    feildinchangepwd={true}
                                />

                                {/* {incorrectMsgDiv && props.passwrdResposne === "Incorrect password" ?
                                    <div>
                                        <p className="alertmsg">Incorrect Password</p>
                                    </div>
                                    :
                                    null
                                } */}
                                {/* <div className='buttonEyeIocnForChnagePassword'>
                                    <button
                                        onClick={() => setPassHide(!passHide)}
                                        style={{ marginTop: incorrectMsgDiv && props.passwrdResposne === "Incorrect password" ? "-51px" : "0px" }}
                                    >
                                        {
                                            passHide ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash"></i>
                                        }
                                    </button>
                                </div> */}
                            </div>
                           <div className='buttonbutn'> {props.passwrdResposne == "Correct Paswword" && newPasswordDiv ?
                    <button className="btnpopupInactive" style={{ outline: "none" }}>
                      Next
                    </button>
                    : <button className="btnpopup" onClick={oldPasswordCheck} style={{ outline: "none" }}>
                      Next
                    </button>}</div>
                        </div>

                    </div>
                    : null}

                {newPasswordDiv && props.passwrdResposne === "Correct Paswword" ?
                    <div className="EnterEmailContainer" style={{ borderTop: "6px solid #e8f4f8" }} ref={scrollRef}>
                        <h1 className="cnfrmac-img">
                            <img src={piccreate} alt="" className="piccreate" />
                            <span style={{paddingLeft:'8.5px'}}>Create new password</span></h1>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className="inputfield">
                            <InputField
                      type={"password"}
                      className="inputfiedforchangepwd fnt24P"
                      ref={inputRefs.current[1]}
                      handleInputChange={handleInputChange}
                      placeholder="New  Password"
                      validation="required|passwordpattern"
                      name="password"
                      
                      isPassword={true}
                      feildinchangepwd={true}
                    />
                                {/* <div className='buttonEyeIocnForChnagePassword'>
                                    <button
                                        onClick={() => setPassHideCreateNewPassword(!passHideCreateNewPassword)}
                                    >
                                        {
                                            passHideCreateNewPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash"></i>
                                        }
                                    </button>
                                </div> */}
                            </div>
                         <div className='buttonbutn'> {  email && valid && values?.password?.length  >= 8 ?
                    <button className="btnpopupInactive" style={{ outline: "none" }}>
                      Next
                    </button>
                    : <button className="btnpopup" onClick={checkPassword} style={{ outline: "none" }}>
                      Next
                    </button>}</div>
                        </div>

                        {
                            email && valid && values?.password?.length  >= 8 ?
                                <div className="EnterEmailContainer" style={{ borderTop: "6px solid #e8f4f8" }}>

                                    <h1 className="cnfrmac-img" >
                                        <img src={piccreate} alt="" className="piccreate" />
                                        <span style={{paddingLeft:'5.5px'}}>Confirm password</span></h1>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div className="inputfield">
                                        <InputField
                                             type={"password"}
                                             className="inputfiedforchangepwd fnt24P"
                                             ref={inputRefs.current[2]}
                                             handleInputChange={handleInputChange}
                                             password={values?.password}
                                             placeholder="Confirm Password"
                                             validation="required|passwordmatch"
                                             name="confirmpassword"
                                             isPassword={true}
                                             feildinchangepwd={true}
                                             />
                                            {/* <div className='buttonEyeIocnForChnagePassword'>
                                                <button
                                                    onClick={() => setPassHideConfirmPassword(!passHideConfirmPassword)}
                                                >
                                                    {
                                                        passHideConfirmPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash"></i>
                                                    }
                                                </button>
                                            </div> */}
                                        </div>
                                        {/* {passAlert ? <p className="alertmsg">{alert}</p> : null} */}
                                        <div className="buttonbutn">
                                            <button  className="btnpopup" onClick={emailpopup}>Save</button>
                                        </div>
                                    </div>

                                </div>

                                : null
                        }
                    </div>
                    : null}
            </div>
        </>
    )
}
const mapStateToProps = (store) => {
    return {
        id: store.user.user && store.user.user.id,
        emailOtpMatch: store.user.emailOtpMatch,
        passwrdResposne: store.user.passwrdResposne,
        sameasoldpassword:store.user.passwordNotUpdated,
        passwordUpdated:store.user.passwordUpdate
    };
};

export default connect(mapStateToProps, { changeUserPassword, otpFlag, passwrdVerify })(CreatenewPassword);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export const RegisterAlertMessage = (props) => {
    return (
        <Snackbar

            anchorOrigin={
                props.anchorOrigin
                    ? props.anchorOrigin
                    : { vertical: "top", horizontal: "center" }
            }
            open={props.flag}

            onClose={props.onClose}
            autoHideDuration={5000}
        >
            <Alert
                onClose={props.onClose}
                severity={props.severity ? props.severity : "error"}

            >
                {props.msg}
            </Alert>
        </Snackbar>
    );
};
