import React from "react";
import './NotificationSettingsSlider.css';
import { makeStyles } from "@material-ui/core/styles";

//slider
import Slider from '@mui/material/Slider';
import { classicNameResolver } from "typescript";


const useStyles = makeStyles((theme) => ({
  root : {

  },
  rail : {
    color : 'red'
  },
  track : {
    color : 'black'
  },
}));





const NotificationSettingsSliderforclinician = (props) => {
  const classes = useStyles();
    // const classes = useStyles();
    const [adherenceValue, setAdherenceValue] = React.useState(props.adherenceForSlCl);
    const [spo2Value, setspo2Value] = React.useState(props.spO2ForSlCl);
    const adherenceSlider = (event, newValue) => {
        setAdherenceValue(newValue);
        props.calladherence(newValue)
      };

      const spo2Slider = (event, newValue) => {
        setspo2Value(newValue);
        props.callSpO2(newValue);
      };

      function valueLabelFormatAdherence(adherenceValue) {
        const units = "%";
        return `0 - ${adherenceValue}${units}`;
      }

      function valueLabelFormatSpO(spo2Value) {
        const units = "%";
        return `0 - ${spo2Value}${units}`;
      }

    return (
        <div>
                {props.description === 'Adherence' ?
                     <div
                     className="sliderWidth"
                    //  className={classes.slider}
                    //  style={{ marginTop:'56px', width:'980px'}}
                   >
                       <Slider
                         value={adherenceValue}
                        //  onChange={adherenceSlider}
                         valueLabelDisplay="on"
                         aria-labelledby="range-slider"
                         aria-valuetext="%"
                         aria-label="percent"
                         getAriaValueText={valueLabelFormatAdherence}
                         valueLabelFormat={valueLabelFormatAdherence}
                         size="medium"
                        //  sx = {{
                        //   '.MuiSlider-rail': {
                        //     color : 'red',
                        //   },
                        //   '.MuiSlider-track' : {
                        //     color : 'black'
                        //   }

                        //  }}
                       />                  
                   </div>
                    : null}

                    {props.description === 'SpO2' ?
                        <div
                        className="sliderWidth"
                        // className={classes.slider}
                        // style={{marginTop:'56px', width:'980px'}}
                      >                     
                          <Slider className = {`${classes.track} ${classes.rail}`}
                            value={spo2Value}
                            // onChange={spo2Slider}
                            valueLabelDisplay="on"
                            aria-labelledby="range-slider"
                            aria-label="percent"
                            getAriaValueText={valueLabelFormatSpO}
                            valueLabelFormat={valueLabelFormatSpO}
                          />                      
                      </div>
                        : null}
        </div>
    );
};

export default NotificationSettingsSliderforclinician;

