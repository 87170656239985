import apiAbmBlaze, { SIGNUP, CONFIRM_SIGNUP_OTP, LINK_VERIFICATION, RESEND_OTP_REGISTER } from "../../api";
import {
  SIGNUP_GET_OTP,
  SIGNUP_OTP_MATCHES,
  SIGNUP_OTP_DOESNOT_MATCHES,
  SIGNUP_ERROR,
  INVITE_LINK_VERIFICATION,
  OTP_RESEND_REGISTER,
  OTP_EXPIRE_REGISTER
} from '../../redux/action.list';

//signup without registration link
export const userRegistarion = (userPic, val) => async (dispatch) => {
  apiAbmBlaze
    .post(SIGNUP, {
      username: val.username ? val.username : '',
      password: val.password ? val.password : '',
      firstname: val.firstname ? val.firstname : '',
      lastname: val.lastname ? val.lastname : '',
      phone: val.phonenumber ? val.phonenumber : '',
      email: val.email ? val.email.toLowerCase() : '',
      role: 'clinician',
      status: 'pending',
      photo_uuid: userPic
    })
    .then(async (res) => {
      if (res.status === 200) {
        await dispatch({
          type: SIGNUP_GET_OTP,
          payload: {
            signupotpSent: res.data.otpSent,
            signupotpToken: res.data.token,
            signupemail: res.data.signupemail,
            otp: res.data.otp,
            signuppicture: res.data.user
          },
        });
      } else if (res.status !== 200) {
        await dispatch({
          type: SIGNUP_ERROR,
          payload: {
            signuperror: res.data.signuperror,
          },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SIGNUP_ERROR,
        payload: {
          signuperror: err,
        },
      });
      console.log('Error----', err);
    });
};

export const signupconfirmOTP = (val) => async (dispatch) => {
  const { code, token, signupemail } = val;
  dispatch({
    type: SIGNUP_OTP_DOESNOT_MATCHES,
    payload: {
      isNotMatch: false
    },
  });
  dispatch({
    type: OTP_EXPIRE_REGISTER,
    payload: {
      otpexpireregister: false
    },
  });
  apiAbmBlaze
    .post(CONFIRM_SIGNUP_OTP, {
      code,
      token,
      signupemail,
    })
    .then(async (res) => {
      
      if (res.status === 200) {
        await dispatch({
          type: SIGNUP_OTP_MATCHES,
          payload: {
            signupisMatch: res.data.signupisMatch,
          },
        });
        localStorage.setItem('authtoken', res.data.authtoken);
      }
    })
    .catch((error) => {
      if (error.response.status === 404) {
        dispatch({
          type: OTP_EXPIRE_REGISTER,
          payload: {
            otpexpireregister: error.response.status
          },
        });
      }
      if (error.response.status === 400) {
        dispatch({
          type: SIGNUP_OTP_DOESNOT_MATCHES,
          payload: {
            isNotMatch: error.response.status
          },
        });
      }
      console.log('Error----', error.response.status);
    });
};

export const linkVerificaion = (val) => async (dispatch) => {
  apiAbmBlaze
    .post(LINK_VERIFICATION, {
      token: val,
    })
    .then(async (res) => {
      if (res.data.message == "Link verified") {
        await dispatch({
          type: INVITE_LINK_VERIFICATION,
          payload: { linkVerifyingFlag: false, linkVerifiedMsg: res.data.message }
        })
      } else if (res.data.message == "Link Expired") {
        await dispatch({
          type: INVITE_LINK_VERIFICATION,
          payload: { linkVerifyingFlag: false, linkVerifiedMsg: res.data.message }
        })
      }
    })
    .catch((error) => {
      console.log('Error----', error);
    });
}

//resend otp for register
export const resend_otp_register = (val) => async (dispatch) => {
  var email = val.email ? val.email.toLowerCase() : ''
  apiAbmBlaze
    .post(RESEND_OTP_REGISTER, {
      email
    })
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: OTP_RESEND_REGISTER,
          payload: {
            resendotpregister: "resendOtpregister"
          }
        });

        await dispatch({
          type: SIGNUP_GET_OTP,
          payload: {
            signupotpSent: res.data.otpSent,
            signupotpToken: res.data.token,
            signupemail: res.data.signupemail,
            otp: res.data.otp,
            signuppicture: res.data.user
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
}