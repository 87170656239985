import React from "react";
import './dashboardsubCards.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from "@material-ui/core/styles";
import cardImage from '../../assets/img/notifiactionSummaryCardImage1.png';
import '../../../src/assets/styles/font.css';

const useStyles = makeStyles((theme) => ({

    mycard: {
        backgroundColor: '#FFFFFF',
        boxShadow: '#6A768F80 0px 3px 6px',
        borderRadius: '8px',
        marginRight: '48px',
        marginBottom: '32px',
        height: '126px',
        width:'90%',
        backgroundImage: `url(${cardImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'noRepeat',
    },
    cardcontent: {
        color: '#3D446F',
        fontSize: '16px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        padding: '0px',
        marginTop: '16px',
    },
    cardactions: {
        display: 'flex',
    }
}));

const DashboardsubCards = (props) => {
    const classes = useStyles();
    return (
        <div>
            <Card variant="outlined" className={classes.mycard}  onClick={() => {if (props.role === 'Clinicians') {
                                                                                                                     props.handlecardclick("clinician")
                                                                                                                  } else if (props.role === 'Patients') {
                                                                                                                     props.handlecardclick("patient")
                                                                                                                  } else if(props.role === 'Care Site Admins')
                                                                                                                  {
                                                                                                                    props.handlecardclick("clinicadmin")
                                                                                                                  }
                                                                                                                  }} style={{cursor:'pointer'}}>
                <CardContent className={classes.cardcontent}>
                    {props.cardContent}
                </CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    {props.role === 'Patients' ?
                        <img
                            className="dashboardSubcardimageWrapper"
                            src={require('../../assets/img/add patients.svg').default}></img> : null}

                    {props.role === 'Clinicians'|| props.role ===  'Care Site Admins' ?
                        <img
                            className="dashboardSubcardimageWrapper"
                            src={require('../../assets/img/add clinicians.png').default}></img> : null}

                    <div className="dashboardSubcardDescription fnt24P" >
                        <p>Enrolled {props.role} across </p>
                        <p style={{ display: 'flex' }}>all Care sites. </p></div>

                    <div className="dashboardSubcardrolecount fnt40P">{props.rolecount}</div>
                </div>
            </Card>
        </div>
    );
};

export default DashboardsubCards;

