import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import "./style.css";
export default function SessionExpiryDialog(props) {

    const onLoginClick = () => {
        localStorage.clear();
        window.history.pushState({}, "Login", "/");
        window.history.go(0)
    }
    return (
        <div className='session-expiry-dialog-wrapper'>
            <Dialog
                fullScreen={true}
                open={props.open}
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        backgroundColor: "#000000ba",
                        color:'red'
                    },
                }}
                maxWidth='xl'>
                <div className='session-expiry-content'>
                    <div style={{ fontSize: "2rem", color: "white" }}>Your session has expired</div>
                    <div style={{ marginTop: "20px", display: "inline-block", fontSize: "1.3rem", color: "white" }}>
                        <div style={{ display: "inline-block", color: "#F26930", cursor: "pointer" }}
                            onClick={onLoginClick}>
                            Click here</div> to log in again</div>
                </div>
            </Dialog>
        </div>
    );
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});