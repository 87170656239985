import { CARE_SITE_DETAILS_FETCH } from "../action.list";

let defaultState = {
  caresitedetails: {},
};

export const caresiteReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CARE_SITE_DETAILS_FETCH:
      return { ...state, ...payload };
    default:
      return state;
  }
};
