import React, { useEffect, useState } from "react";
import "./styles.css";
export default function Pcircle(props) {
    const [offset, setOffset] = useState(0);
    // const [strokeWidth, setStrokeWidth] = useState(15);
    const circleOneStroke = "#c3c9d4"
    const circleTwoStroke = "#F26930"
    const {
        size,
        progress,
        strokeWidth = 15,
        // circleOneStroke,
        // circleTwoStroke,
    } = props;
    const center = size / 2;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;

    useEffect(() => {
        
        const _progress =  progress > 100 ? 100 : progress;
        const pg1= _progress== -100 ?0:_progress
        const progressOffset = ((100 - pg1) / 100) * circumference;

        setOffset(progressOffset);
    }, [setOffset, progress, circumference, offset]);
    return (
        <>
            <div className="circular-progresbar-wrapper">
                <div className="progressbar-text">
                    {parseInt(progress) > 100 ? 100 : progress== -100? '-': parseInt(progress)}
                    {/* {progress} */}
                </div>
                <svg className="circular_chart" width={size} height={size}>
                    <circle
                        className="circular_bg"
                        stroke={circleOneStroke}
                        cx={center}
                        cy={center}
                        r={radius}
                        strokeWidth={strokeWidth}
                    ></circle>
                    <circle
                        className="circle"
                        stroke={circleTwoStroke}
                        cx={center}
                        cy={center}
                        r={radius}
                        strokeWidth={strokeWidth}
                        strokeDasharray={circumference}
                        strokeDashoffset={offset}
                    ></circle>
                    {/* <text x={center} y={center}   //This is not working because im rotating the svg
                            className="percentage">
                            {progress}
                        </text> */}
                </svg>
            </div>
        </>
    );
}
