import React, { useEffect } from "react";
import './CaresitesettingsCards.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { pageHeading } from "../../redux/user/user.action";
const useStyles = makeStyles((theme) => ({


    mycard: {
        backgroundColor: '#FFFFFF',
        boxShadow: '#6A768F80 0px 3px 6px',
        borderRadius: '10px',
        marginRight: '24px',
        marginBottom: '32px',
        height: '380px',
    },
    cardcontent: {
        color: '#3D446F',
        fontSize: '16px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        padding: '0px',
        marginTop: '16px',
        marginLeft: '16px',
    },
    cardactions: {
        // marginTop : '10px',
        display: 'flex',
    }
}));

const CaresitesettingsCards = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageHeading("Care Site settings"));
    })
 
    return (
        <div>
            <Card variant="outlined" className={classes.mycard}>
                <CardContent className={classes.cardcontent}>
                    {props.cardContent}
                </CardContent>
                {props.role === 'Patients' ?
                    <><img
                        className="CaresitesettingsimageWrapper"
                        src={require('../../assets/img/add patients.svg').default} alt="" />
                        <div className="CaresitesettingsenrolledRoleWrapper">
                            {/*<div className="CaresitesettingsenrolledWrapper">Enrolled {props.role}</div>*/}
                            {/* included space to increase charecter count of 'Patient' */}
                            <div className="CaresitesettingsenrolledWrapper">Enrolled {props.role === 'clinicians' ? 'Clinicians' : props.role === 'Care site Admin' ? 'Care Site Admin' : props.role} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                            <div className="CaresitesettingsenrolledCountWrapper">{props.rolecount}</div>
                        </div></>
                        : null}

                {props.role === 'clinicians' ?
                    <><img
                        className="CaresitesettingsimageWrapper"
                        src={require('../../assets/img/add clinicians.png').default} alt="" /><div className="CaresitesettingsenrolledRoleWrapper">
                            {/*<div className="CaresitesettingsenrolledWrapper">Enrolled {props.role}</div>*/}
                            <div className="CaresitesettingsenrolledWrapper">Enrolled {props.role === 'clinicians' ? 'Clinicians' : props.role === 'Care site Admin' ? 'Care Site Admin' : props.role} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                            <div className="CaresitesettingsenrolledCountWrapper">{props.rolecount}</div>
                        </div></>
                        : null}

                {props.role === 'Care site Admin' ?
                    <><img
                        className="CaresitesettingsimageWrapper"
                        src={require('../../assets/img/Enrolled illustraion.png').default} alt="" /><div className="CaresitesettingsenrolledRoleWrapper">
                            {/*<div className="CaresitesettingsenrolledWrapper">Enrolled {props.role}</div>*/}
                            <div className="CaresitesettingsenrolledWrapper">Enrolled {props.role === 'clinicians' ? 'Clinicians' : props.role === 'Care site Admin' ? 'Care Site Admin' : props.role}</div>
                            <div className="CaresitesettingsenrolledCountWrapper">{props.rolecount}</div>
                        </div></>
                        : null}

                

                <div className="buttonWrapperDiv">
                    <div className="CaresitesettingsviewButtonWrapper"
                        onClick={() => {
                            if (props.role === 'Patients') {
                                props.viewTable("patient")
                            }
                            else if (props.role === 'Care site Admin') {
                                props.viewTable("clinicadmin")
                            }
                            else {
                                props.viewTable("clinicians")
                            }
                            if(props.scrollRef?.current){
                                props.scrollRef.current.scrollIntoView({ behavior: "smooth" })
                            }

                        }}
                    >{props.view}</div>
                    <div style={{ marginTop: '24px' }}></div>

                    <div className="CaresitesettingsinviteButtonWrapper"
                        onClick={props.invitePopupforPatients}
                    >{props.invite}</div>
                </div>


                {/* </div> */}

            </Card>
        </div>
    );
};

export default CaresitesettingsCards;

