import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import './Personalinformation.css';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { useHistory } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import { connect, useSelector } from 'react-redux'
import moment from 'moment'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { height } from "@mui/system";
import {reportsSearchUser} from "../../redux/user/user.action";


const useStyles = makeStyles((theme) => ({

  avt: {
    height: 120,
    width: 125,
  
  },

}));

const normalizeInput = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value; 

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length; 
  if (!previousValue || value.length > previousValue.length) {

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue; 

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`; 

    if (cvLength < 9)
    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`; 

    return `+${currentValue.slice(0, 1)} (${currentValue.slice(1, 4)}) ${currentValue.slice(4, 7)}-${currentValue.slice(7, 11)}`; 
  }
};

const Personalinformation = (props) => {
  const classes = useStyles();
  // const patientDetails = props.particularUserReportSearchVal;
  const patientDetails = useSelector((store) => {
    return store.user.navFromReportSearchToPatientProfile
  })

  const getPatientId = () => {
    if (patientDetails.patient_id && patientDetails.patient_id.patient_id &&
      patientDetails.patient_id.patient_id.length) {
      return patientDetails.patient_id.patient_id.length > 1 ? patientDetails.patient_id.patient_id.join(',') : patientDetails.patient_id.patient_id[0];
    } else {
      return 'Patient Id'
    }
  }
  return (
    <div>
      <Dialog
        open={props.isopen}
        onClose={props.callBack}>


        <div className="Personal_information">
          <div className="header_Personalinformation">
            Personal Info
          </div>
          <div>
            <CloseIcon onClick={props.callBack} className="caniconForPersonalInformation" />
          </div>
        </div>
        <div className="pi-container">
          <div>
            <div className="PersonalInformation_pic">

              {patientDetails && patientDetails.photo_uuid ?
                <Avatar src={`https://ucarecdn.com/${patientDetails.photo_uuid}/`}  
                
                />

                :
                <Avatar
                  src={require('../../assets/img/user1.svg').default}
                  className={classes.avt} 
                
                />

              }

            </div>
          </div>
          <form>
            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4" >
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md  mb-2"
                    style={{ color: '#858fa4' }}
                  >
                    First name
                  </label>
                  <div className="GenPersonalInfoDivs fnt24P font-bold">
                    {patientDetails && patientDetails.first_name ? patientDetails.first_name : "-"}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4" >
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md  mb-2"
                    style={{ color: '#6a768f' }}
                  >
                    Last name
                  </label>
                  <div className="GenPersonalInfoDivs  font-bold fnt24P">
                    {patientDetails && patientDetails.last_name ? patientDetails.last_name : "-"}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-blueGray-600 text-md  mb-2"
                    style={{ color: '#6a768f' }}
                  >
                    Date of birth
                  </label>
                  <div className="GenPersonalInfoDivs font-bold fnt24P">
                    {patientDetails && patientDetails.date_of_birth ? moment(patientDetails.date_of_birth).utc().format('DD MMM YYYY') : "-"}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md  mb-2"
                    style={{ color: '#6a768f' }}
                  >
                    Patient ID
                  </label>
                  <div className="GenPersonalInfoDivs font-bold fnt24P">
                   {getPatientId()}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md mb-2"
                    style={{ color: '#6a768f' }}
                  >
                    Address line 1
                  </label>
                  <div className="GenPersonalInfoDivs  font-bold fnt24P">
                    {patientDetails && patientDetails.address && patientDetails.address.address_line_1 ? patientDetails.address.address_line_1 : "-"}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md  mb-2 "
                    style={{ color: '#6a768f' }}
                  >
                    Address line 2
                  </label>
                  <div className="GenPersonalInfoDivs font-bold fnt24P">
                    {patientDetails && patientDetails.address && patientDetails.address.address_line_2 ? patientDetails.address.address_line_2 : "-"}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md  mb-2"
                    style={{ color: '#6a768f' }}
                  >
                    City
                  </label>
                  <div className="GenPersonalInfoDivs  font-bold fnt24P">
                    {patientDetails && patientDetails.address && patientDetails.address.city ? patientDetails.address.city : "-"}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md  mb-2"
                    style={{ color: '#6a768f' }}
                  >
                    State
                  </label>
                  <div className="GenPersonalInfoDivs font-bold fnt24P">
                    {patientDetails && patientDetails.address && patientDetails.address.state ? patientDetails.address.state : "-"}
                  </div>
                </div>
              </div>
            </div>


            <div className="flex flex-wrap mt-3 cardsetting">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md  mb-2"
                    style={{ color: '#6a768f' }}
                  >
                    Zip
                  </label>
                  <div className="GenPersonalInfoDivs font-bold fnt24P">
                    {patientDetails && patientDetails.address && patientDetails.address.zip ? patientDetails.address.zip : "-"}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md  mb-2"
                    style={{ color: '#6a768f' }}
                  >
                    Country
                  </label>
                  <div className="GenPersonalInfoDivs font-bold fnt24P">
                    {patientDetails && patientDetails.address && patientDetails.address.country ? patientDetails.address.country : "-"}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap mt-3 cardsetting personal-info-footer">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block  text-blueGray-600 text-md mb-2"
                    style={{ color: '#6a768f' }}
                  >
                    Phone
                  </label>
                  <div className="GenPersonalInfoDivs  font-bold fnt24P">
                    {patientDetails && patientDetails.phone === "null" ? "N/A" : normalizeInput(patientDetails.phone)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3" style={{ marginBottom: "20px" }}>
                  <label
                    className="block  text-blueGray-600 text-md  mb-2"
                    style={{ color: '#6a768f' }}
                  >
                    Email
                  </label>
                  <div className="GenPersonalInfoDivs font-bold fnt24P">
                    {patientDetails && patientDetails.email ? patientDetails.email : " "}
                  </div>
                </div>
              </div>
            </div>
          </form>

        </div>
        {/* </div> */}

      </Dialog>
    </div>
  )
}

const mapStateToProps = (store) => {
  return {
    particularUserReportSearchVal : store.user.particularUserReportSearchVal,
  };
};
export default connect(mapStateToProps, {reportsSearchUser}) (Personalinformation);
