import React, { useEffect, useState } from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import close_black from '../../assets/img/close_black.svg';
import '../styles/setNewTheorpyGoalModal.css';
import Checkbox from '@material-ui/core/Checkbox';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { loadDevice, updateDevice } from '../../redux/therapyGoals/therapyGoals.action';
import { reportsSearchUser
    // navReportSearchToPatientProfile
 } from '../../redux/user/user.action';
import AlertSnackBar from '../Alertmessage/AlertMsg';



const SetNewTheorpyGoalModal = (props) => {
    const weeks = [
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
        'Sun',
    ];

    const [selectedValue, setSelectedValue] = React.useState('a');
    const [counter, setCounter] = React.useState('0');
    const [mon, setMon] = React.useState(true);
    const [tue, setTue] = React.useState(true);
    const [wed, setWed] = React.useState(true);
    const [thu, setThu] = React.useState(true);
    const [fri, setFri] = React.useState(true);
    const [sat, setSat] = React.useState(true);
    const [sun, setSun] = React.useState(true);
   

    const days_of_week = {
        mon: mon,
        tue: tue,
        wed: wed,
        thu: thu,
        fri: fri,
        sat: sat,
        sun: sun,
    };

    const [openSnack, setOpenSnack] = useState(false);
    const [snackMsg, setSnackMsg] = React.useState('');
    const [snackSeverity, setSnackSeverity] = React.useState();

    useEffect(() => {

        loadDevice(props.navFromReportSearchToPatientProfile.pair_id);

    }, [])
    const incrementCounter = () => {
        setCounter(prev=>{
            let i = parseInt(prev);
            if(isNaN(i)){
                i = 0;
            }
            i++;
            if(i>24){i=24}
            return `${i}`
        })
    };
    let decrementCounter = () => {
        setCounter(prev=>{
            let i = parseInt(prev);
            if(isNaN(i)){
                i = 0;
            }
            i--;
            if(i<0){i=0}
            return `${i}`
        })
    };
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const counterval = counter;
 

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: 50,
        width: 41,
        height: 40,
        paddingLeft: '4px',
        paddingTop: '4px',
        boxShadow: '#6A768F80 0px 3px 6px',

        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpIconUncheck = styled('span')(({ theme }) => ({
        borderRadius: 50,
        width: 41,
        height: 40,
        paddingLeft: '4px',
        paddingTop: '4px',
        boxShadow: '#6A768F80 0px 3px 6px',
      
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'white',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#f26c33',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 30,
            height: 30,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#f26c33',
        },
    });

    const BpUnCheckedIcon = styled(BpIconUncheck)({
        backgroundColor: 'white',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 30,
            height: 30,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: 'white',
        },
    });

    // Inspired by blueprintjs
    function BpCheckbox(props) {
        return (
            <div>
                {props.value === true ?
                    <Checkbox
                        sx={{
                            '&:hover': { bgcolor: 'transparent' },
                        }}
                        disableRipple
                        color="default"
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        inputProps={{ 'aria-label': 'Checkbox demo' }}
                        {...props}
                    /> :
                    <Checkbox
                        sx={{
                            '&:hover': { bgcolor: 'transparent' },
                        }}
                        disableRipple
                        color="default"
                        checkedIcon={<BpUnCheckedIcon />}
                        icon={<BpIconUncheck />}
                        inputProps={{ 'aria-label': 'Checkbox demo' }}
                        {...props}
                    />}
            </div>
        )
    }

    function handelClose() {
        props.callback();
    }

    const newObject = {
        days_of_week: days_of_week,
        therapyDuration: parseInt(counterval),
        pairId: props.navFromReportSearchToPatientProfile.pair_id,
        profileId: props.navFromReportSearchToPatientProfile.profile_id,
        created_by: props.userId,
    }

  

    const submitHandlerForTheorpy = async () => {
        if(newObject.therapyDuration>24){
            setOpenSnack(true);
            setSnackMsg("Number of therapies per day can not greater than 24")
            setSnackSeverity('error')
            return;
        }
        try{
            await props.updateDevice(newObject);
            setOpenSnack(true);
            setSnackMsg("Therapy Goals are set successfully")
            setSnackSeverity('success')
            props.callback();
        }
        catch(e){
            setOpenSnack(true);
            setSnackMsg("An error occured while saving Therapy Goals, Try again later")
            setSnackSeverity('error')
        }
    }

    return (
        <>
            <AlertSnackBar msg={snackMsg} severity={snackSeverity} flag={openSnack} 
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    onClose={()=>{
                        setOpenSnack(false)
                    }}
                />
            <Dialog
                open={props.showModalSetTheorpy}
                maxWidth="md"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    className="inviteModalContainer"
                >
                    <div className="modalheader">
                        <div className="forheadertitletheorpy">Set new therapy goals</div>
                        <div><img src={close_black} onClick={handelClose} className="closeIcon" /></div>
                    </div>
                </DialogTitle>
                <DialogContent style={{ backgroundColor: "#f2f4f8" }}>

                    <div className="flex flex-wrap">
                        <div className="lg:w-12/12 px-2">
                            <div className="subTitleForTheorpyGOal">
                                <p>Select the days therapy should be performed</p>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '8px', marginRight: '8px', marginTop: '20px' }}>
                        <BpCheckbox defaultChecked value={mon} onClick={() => setMon(!mon)} />
                        <BpCheckbox defaultChecked value={tue} onClick={() => setTue(!tue)} />
                        <BpCheckbox defaultChecked value={wed} onClick={() => setWed(!wed)} />
                        <BpCheckbox defaultChecked value={thu} onClick={() => setThu(!thu)} />
                        <BpCheckbox defaultChecked value={fri} onClick={() => setFri(!fri)} />
                        <BpCheckbox defaultChecked value={sat} onClick={() => setSat(!sat)} />
                        <BpCheckbox defaultChecked value={sun} onClick={() => setSun(!sun)} />
                    </div>
                    <div className="weeksactiveDiv"

                    >
                        {weeks.map((week,index) => {
                            return (
                                <div className="inActiveWeeks" key={index}>
                                    {week}
                                </div>
                            )

                        })}
                    </div>
                    <div className="flex flex-wrap">
                        <div className="lg:w-12/12 px-2 mb-5">
                            <div className="subTitleForTheorpyGOal">
                                <p>Select the number of therapies per day</p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="lg:w-12/12 px-2 mb-5 mainCOuntContainer">
                            <div className="countContainer">
                                <div className="iconIncrement">
                                    <i className="fa fa-minus" aria-hidden="true"
                                        onClick={decrementCounter}
                                    ></i>
                                </div>
                                <div className="countInput">
                                    <input type="text" value={counter} onChange={(e)=>{
                                        let regx = new RegExp(/^[0-9]+$/i);
                                        let flag = regx.test(e.currentTarget.value)
                                        setCounter(old=>{
                                            return flag?`${parseInt(e.currentTarget.value)}`:(e.currentTarget.value===''?'':old);
                                        })
                                    }} className="countValue" />
                                </div>
                                <div className="iconDecrement">
                                    <i className="fa fa-plus" aria-hidden="true"
                                        onClick={incrementCounter}
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    {counterval === '0'|| counterval===''|| (days_of_week.mon == false && days_of_week.tue == false && days_of_week.wed == false && days_of_week.thu == false && days_of_week.fri == false && days_of_week.sat == false && days_of_week.sun == false) ?
                        <div className="updatebuttonCenterforTheorpy" style={{ opacity: "0.5", cursor: 'auto' }}>
                            <Button
                                variant="contained"
                                className="buttonforsubmittheropy"
                            >
                                Submit
                            </Button>
                        </div> :

                        <div className="updatebuttonCenterforTheorpy">
                            <Button
                                variant="contained"
                                className="buttonforsubmittheropy"
                                onClick={submitHandlerForTheorpy}
                            >
                                Submit
                            </Button>
                        </div>}
                </DialogContent>
            </Dialog>
        </>
    )
}

const mapStateToProps = (store) => {
    
    return {
        navFromReportSearchToPatientProfile: store.user.navFromReportSearchToPatientProfile,
        userId: store.user && store.user.id && store.user.user.id,
    };
};
export default connect(mapStateToProps, { updateDevice, loadDevice, reportsSearchUser,
    //  navReportSearchToPatientProfile
     })(SetNewTheorpyGoalModal);
