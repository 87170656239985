/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";
import InputField from "../../components/Inputs_Validation";
import { Link } from "react-router-dom";
import {
  updateInvitationItem,
  verifyuserphonenumber,
  verifynewuserconfirmOTP,
  checkEmail,
  resend_otp_newuser,
} from "../../redux/invitation/components/invitation.action";
import {
  userRegistarion,
  signupconfirmOTP,
  linkVerificaion,
  resend_otp_register,
} from "../../redux/registration/registration.action";
import { useLocation } from "react-router-dom";
// import tick from "../../assets/img/tick1.png"
//alert message
import Button from "@mui/material/Button";
import Snackbar from "@material-ui/core/Snackbar";
import CustomsSnackbar from "../../components/Alertmessage/Snackbar";
import "./styless.css";
import "./normalize1.css";
import welcome from "../../../src/assets/img/welcome.png";
import "./Register.css";
import FooterSign from "../../components/Footers/FooterSign";
import "../../../src/screens/PublicRoutes/Signin/styles.css";
import OtpInput from "react-otp-input";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import PhoneInput from "react-phone-input-2";
import Alert from "@material-ui/lab/Alert";
import LodingIcon from "../../components/LoadingIcon";
// import unknowdevice from "../../assets/img/unkown-device.png";
import apiAbmBlaze, { CHECK_USERNAME } from "../../api";
import { emailRegex } from "../../constant/utilConstants";
import { base64Encode } from "../../utils";

const Register = ({
  updateInvitationItem,
  verifyuserphonenumber,
  verifynewuserconfirmOTP,
  resend_otp_newuser,
  flagEmail,
  userRegistarion,
  signupotpToken,
  signupotpSent,
  verifynewuserotpSent,
  resendotpnewuser,
  verifynewuserOtp,
  verifynewuserotpToken,
  VerifyNewuserErrorMsg,
  newuserisNotMatch,
  otpexpirenewuser,
  newuserisMatch,
  signupemail,
  signupconfirmOTP,
  signuperror,
  otpRecived,
  checkEmail,
  linkVerificaion,
  linkVerifyingFlag,
  linkVerifiedMsg,
  resend_otp_register,
  resendotpregister,
  otpexpireregister,
  signupisMatch,
  isNotMatch,
}) => {
  let location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("param1");
  if (param1 !== null) {
    try {
      var decoded = jwt_decode(param1);
    } catch (error) {
      console.log("Not a valid token ::" + error.message);
    }
  }

  const [data, setData] = useState(param1 ? decoded : null);
  const [values, setValues] = React.useState({});
  const [hideForm, setHideForm] = React.useState(true);
  //snackbar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [withoutInvitationLinkClick, setWithoutInvitationLinkClick] =
    React.useState(false);
  const [withInvitationLinkClick, setWithInvitationLinkClick] =
    React.useState(false);
  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  const [openErrorSnack, setOpenErrorSnack] = React.useState(false);
  const handleErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  const [snackErrorSeverity, setSnackErrorSeverity] = React.useState("");
  const [emailerror, setEmailerror] = React.useState("");
  const [valid, setvalid] = React.useState(false);
  const [otp, setopt] = useState("");
  const [otpnewuser, setotpnewuser] = useState("");
  const [otpsent, setotpsent] = useState(false);
  const [userPic, setUserPic] = React.useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [counter, setCounter] = React.useState(300);
  const [resendOtpConditaion, setresendotpCondition] =
    React.useState("resendotp");
  const [resendOtpCondition, setresendotpConditionfornewuser] =
    React.useState("resendotp");
  const [msg, setmsg] = React.useState("");
  // const [passHide, setPassHide] = useState(false);
  // const [passHideConfirmPass, setPassHideConfirmPass] = useState(false);
  const [anchororigin, setanchorOrigin] = useState({
    vertical: "top",
    horizontal: "center",
  });
  const [usernameError, setUsernameError] = React.useState("");
  const [clickverify, setclickverify] = useState(false);
  const [verifiedphonenumber, setverifiedphonenumber] = React.useState("");
  const [checkphoneclicked, setcheckphoneclicked] = React.useState("");
  const [submitclicked, setsubmitclicked] = React.useState(false);

  const inputRefs = React.useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);
  function timer1(counter) {
    let minutes = Math.floor(counter / 60);
    let sec = counter % 60;
    let seconds = sec;

    if (Math.floor(sec / 10) === 0) {
      seconds = "0" + sec;
    }

    return `${minutes}:${seconds}`;
  }
  useEffect(() => {
    if (flagEmail) {
      setEmailerror("Email address already exists");
    } else {
      setEmailerror("");
    }
  }, [flagEmail]);

  useEffect(() => {
    if (data) {
      linkVerificaion(param1);
    }
  }, [param1]);

  const Submitting = (val) => {
    if (val) {
      updateInvitationItem({
        ...data,
        username: val.username,
        password: base64Encode(val.password),
        firstname: val.firstname,
        lastname: val.lastname,
        phonenumber: phoneValue,
        status: "pending",
        email: data.email,
        role: data.role,
        clinic_id: data.clinic_id,
      });
    }
  };

  const onSignupSubmit = (val) => {
    if (val) {
      userRegistarion(userPic, {
        username: val.username,
        password: base64Encode(val.password),
        firstname: val.firstname,
        lastname: val.lastname,
        phonenumber: phoneValue,
        status: "pending",
        email: val.email,
        role: val.role,
        photo_uuid: userPic,
      });
    }
  };
  const handleInputChange = (name, value) => {
    if (value && value.length > 0) {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleInputChangeforSignup = (name, value) => {
    if (value.length > 0) {
      setValues({
        ...values,
        [name]: value,
      });
    }
    if (emailRegex.test(value)) {
      checkEmail(value);
    }
  };

  const onSubmit = async () => {
    setWithInvitationLinkClick(true);
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
        setvalid(false);
      }
    }

    if (isValid === true && signuperror) {
      setOpenErrorSnack(true);
      setSnackErrorSeverity("error");
    } else if (isValid === true && !usernameError && newuserisMatch) {
      if (verifiedphonenumber === phoneValue) {
        setvalid(true);
        Submitting(values);
        setHideForm(false);
      } else {
        setmsg(
          "You seem to have entered a different mobile number than the one verified,Kindly verify this number before registering or use the number that was verified."
        );
        setSnackSeverity("error");
        setOpenSnack(true);
      }
    }
  };

  //for next button
  const onSubmitSignup = async () => {
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }

    if (isValid === true && !usernameError) {
      onSignupSubmit(values);
      setotpsent(true);
    }
  };

  useEffect(() => {
    if (resendOtpConditaion === "resendotp") {
      setresendotpCondition("mailmsg");
    }
    if (otpRecived) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      setresendotpCondition("otpexpire");
    }
    if (counter === 0) {
      setresendotpCondition("resendotp");
    }
  }, [otpRecived, counter]);

  const resendCode = () => {
    setresendotpCondition("mailmsg");
    resend_otp_register(values);
  };

  const resendCodefornewuser = () => {
    setresendotpConditionfornewuser("mailmsg");
    resend_otp_newuser(phoneValue);
  };

  useEffect(() => {
    if (resendotpregister === "resendOtpregister" && otpRecived) {
      setCounter(300);
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      setresendotpCondition("otpexpire");
    }
  }, [resendotpregister, otpRecived]);
  useEffect(() => {
    if (resendotpnewuser === "resendOtpnewuser" && verifynewuserOtp) {
      setCounter(300);
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      setresendotpCondition("otpexpire");
    }
  }, [resendotpnewuser, verifynewuserOtp]);

  useEffect(() => {
    if (resendOtpCondition === "resendotp") {
      setresendotpConditionfornewuser("mailmsg");
    }
    if (verifynewuserOtp) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      setresendotpConditionfornewuser("otpexpire");
    }
    if (counter === 0) {
      setresendotpConditionfornewuser("resendotp");
    }
  }, [verifynewuserOtp, counter]);
  //otp
  const submitOTP = () => {
    setWithoutInvitationLinkClick(true);
    if (otp.length === 4 && signupotpToken && signupemail) {
      let code = otp;
      signupconfirmOTP({ code, token: signupotpToken, signupemail });
      setOpenSnack(false);
    }
  };

  useEffect(() => {
    if (signupisMatch) {
      setHideForm(false);
    }
    if (otpexpireregister === 404) {
      setWithoutInvitationLinkClick(false);
      setHideForm(true);
      setmsg("Verification code is expired! Please try again with new one");
      setOpenSnack(true);
      setSnackSeverity("error");
    }
    if (isNotMatch === 400) {
      setWithoutInvitationLinkClick(false);
      setmsg("Incorrect code! Please try again");
      setOpenSnack(true);
      setSnackSeverity("error");
      setHideForm(true);
    }
  }, [signupisMatch, isNotMatch, otpexpireregister]);
  useEffect(() => {
    if (newuserisMatch) {
      setmsg("Your mobile numer has been successfully verified");
      setOpenSnack(true);
      setSnackSeverity("success");
      setverifiedphonenumber(phoneValue);
    }
    if (otpexpirenewuser === 404) {
      setvalid(false);
      setmsg("Verification code is expired! Please try again with new one");
      setOpenSnack(true);
      setSnackSeverity("error");
    }
    if (newuserisNotMatch === 400) {
      setvalid(false);
      setmsg("Incorrect code! Please try again");
      setOpenSnack(true);
      setSnackSeverity("error");
      setHideForm(true);
    }
  }, [newuserisMatch, newuserisNotMatch, otpexpirenewuser]);

  const handleChange = (otp) => {
    setopt(otp);
  };

  const handleChangefornewuser = (otp) => {
    setotpnewuser(otp);
  };
  const handleValidateInvitation = async () => {
    if (newuserisMatch) {
      setsubmitclicked(true);
      setWithInvitationLinkClick(true);
      await checkUsername(values.username).then((res) => {
        if (res) setWithInvitationLinkClick(false);
        onSubmit();
      });
    } else {
      setvalid(false);
      setmsg("Please verify your mobile number before submitting");
      setOpenSnack(true);
      setSnackSeverity("error");
    }
  };
  const handleverifyphonenumber = async () => {
    console.log("COMNG HERE", phoneValue);
    setcheckphoneclicked("started");
    if (phoneValue.length > 1) {
      setclickverify(true);
      await verifyuserphonenumber({ enteredphonenumber: phoneValue });
      // setcheckphoneclicked('done')
    } else {
      setmsg("Please enter your mobile number and try again");
      setOpenSnack(true);
      setSnackSeverity("error");
      setcheckphoneclicked("");
    }
  };

  useEffect(() => {
    if (VerifyNewuserErrorMsg !== "") {
      console.log("VerifyNewuserErrorMsg", VerifyNewuserErrorMsg);
      if (VerifyNewuserErrorMsg === 209) {
        setOpenSnack(true);
        setSnackSeverity("error");
        setmsg("Please enter a valid number");
        setvalid(false);
        setclickverify(false);
        setcheckphoneclicked("");
      }
      if (VerifyNewuserErrorMsg === 222) {
        setOpenSnack(true);
        setSnackSeverity("error");
        setmsg(
          "This mobile number is already registered with us.Kindly try another number if you're registering a different account"
        );
        setvalid(false);
        setclickverify(false);
        setcheckphoneclicked("");
      }
    }
  }, [VerifyNewuserErrorMsg, clickverify]);

  const submitotpfornewuser = async () => {
    if (otpnewuser.length === 4 && verifynewuserotpToken) {
      let code = otpnewuser;
      console.log("fhvbfkj", otpnewuser);
      await verifynewuserconfirmOTP({ code, token: verifynewuserotpToken });
      setOpenSnack(false);
    }
  };

  useEffect(() => {
    if (otpnewuser.length === 4 && verifynewuserotpToken) {
      submitotpfornewuser();
    }
  }, [otpnewuser, verifynewuserotpToken]);
  const handleValidateSignup = async () => {
    setWithoutInvitationLinkClick(true);
    await checkUsername(values.username).then((res) => {
      if (res) setWithoutInvitationLinkClick(false);
      onSubmitSignup();
    });
  };

  const checkUsername = async (val) => {
    // console.log("Val:",val.length,val)
    setUsernameError("");
    if (val && val.length >= 6) {
      return apiAbmBlaze
        .get(`${CHECK_USERNAME}?username=${val}`)
        .then(async (res) => {
          // console.log("checkUsername:", res);
          if (res.status === 200) {
            if (res.data === "Already exist") {
              setUsernameError("Username already exists");
            } else {
              setUsernameError("");
              return Promise.resolve(res.data);
            }
          }
        })
        .catch((error) => {
          console.log("ERROR", error);
          return Promise.reject(error);
        });
    }
  };

  const handleInputChangeUsername = (name, value) => {
    checkUsername(value);
    if (value && value.length > 0) {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  return (
    <>
      {linkVerifyingFlag ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", height: "500px" }}
        >
          <CircularProgress style={{ marginTop: "200px" }} />
        </Box>
      ) : (
        <div>
          {linkVerifiedMsg === "Link verified" ? (
            <CustomsSnackbar
              flag={openErrorSnack}
              onClose={handleErrorSnackbar}
            />
          ) : null}

          {/*  {linkVerifiedMsg == "" ? ( */}
          <RegisterAlertMessage
            flag={openSnack}
            onClose={handleSnackbar}
            anchorOrigin={anchororigin}
            severity={snackSeverity}
            msg={msg}
          />
          {/*  ) : null} */}

          {linkVerifiedMsg === "Link verified" ? (
            // ***** with invitation link ******** //
            <div className="RegisterwrapperContainer">
              <div className="lg:flex">
                <div className="flex-1 leftSideInRegisterPage">
                  <div className="RegisterimgWrapper">
                    <img src={welcome} className="image" alt="" />
                  </div>
                  <div className="textInRegister py-16">
                    Get Connected. Stay Informed. <br /> Collaborate.
                  </div>
                </div>
                <div className="flex-1 RegisterrightSide">
                  <div className="Registrtext_1">
                    {hideForm ? (
                      <div className="RegisterinnerText">
                        Enter your data to register
                        <br />
                        {/* <span className="RegisterinnerText2">
                          <span style={{ color: "#6a768f" }}>to</span> Register
                        </span> */}
                      </div>
                    ) : (
                      <div style={{ paddingTop: "80px" }}></div>
                    )}
                  </div>
                  {hideForm ? (
                    <div className="wrapperforRegister">
                      <div style={{ marginLeft: "80px", marginRight: "80px" }}>
                        <div className="flex flex-wrap">
                          <div className="w-full lg :w-full">
                            <div
                              className="labelsInCommomForRegister fnt24P"
                              style={{ display: "flex" }}
                            >
                              Username
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  // paddingTop: '3px',
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>
                            <div>
                              <InputField
                                type="text"
                                className="emailInputInRegiter fnt21P"
                                ref={inputRefs.current[0]}
                                handleInputChange={handleInputChangeUsername}
                                validation="required|min:6|username"
                                name="username"
                                submitclicked={false}
                              />
                            </div>
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginLeft: "2px",
                              }}
                            >
                              {usernameError}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-wrap">
                          <div className="w-6/12 lg : 6/12">
                            <div
                              className="labelsInCommomForRegister fnt24P"
                              style={{ display: "flex" }}
                            >
                              Password
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  // paddingTop: '3px',
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>
                            <div style={{ marginRight: "24px" }}>
                              <InputField
                                type={"password"}
                                className="emailInputInRegiter fnt21P"
                                ref={inputRefs.current[1]}
                                handleInputChange={handleInputChange}
                                validation="required|passwordpattern"
                                name="password"
                                isPassword="true"
                                submitclicked={submitclicked}
                              />
                            </div>
                          </div>

                          <div className=" w-6/12 lg:6/12">
                            <div
                              className="labelsInCommomForRegister fnt24P"
                              style={{ display: "flex" }}
                            >
                              Confirm Password
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  // paddingTop: '3px',
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>
                            <div>
                              <InputField
                                type="password"
                                className="emailInputInRegiter fnt21P"
                                ref={inputRefs.current[2]}
                                handleInputChange={handleInputChange}
                                validation="passwordmatch"
                                name="confirm password"
                                password={values.password}
                                isPassword="true"
                                submitclicked={false}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-wrap">
                          <div className="w-6/12 lg:6/12">
                            <div
                              className="labelsInCommomForRegister fnt24P"
                              style={{ display: "flex" }}
                            >
                              First Name
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  // paddingTop: '3px',
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>
                            <div className="divPadding">
                              <InputField
                                type="text"
                                className="emailInputInRegiter fnt21P"
                                ref={inputRefs.current[3]}
                                handleInputChange={handleInputChange}
                                validation="required"
                                name="firstname"
                                submitclicked={false}
                              />
                            </div>
                          </div>

                          <div className="w-6/12 lg:6/12">
                            <div
                              className="labelsInCommomForRegister fnt24P"
                              style={{ display: "flex" }}
                            >
                              Last Name
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  // paddingTop: '3px',
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>
                            <div>
                              <InputField
                                type="text"
                                className="emailInputInRegiter"
                                ref={inputRefs.current[4]}
                                handleInputChange={handleInputChange}
                                validation="required"
                                name="lastname"
                                submitclicked={false}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-wrap">
                          <div className="w-6/12 lg : w-6/12">
                            <div
                              className="labelsInCommomForRegister fnt24P"
                              style={{ display: "flex" }}
                            >
                              Phone Number
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  // paddingTop: '3px',
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>

                            {newuserisMatch ? (
                              <div className="divPadding1">
                                <PhoneInput
                                  country={"us"}
                                  className="PhoneInputforRegister"
                                  international
                                  value={phoneValue}
                                  placeholder=""
                                  inputProps={{
                                    name: "phonenumber",
                                    required: true,
                                  }}
                                  onChange={setPhoneValue}
                                  inputStyle={{
                                    width: "100%",
                                    border: "none !important",
                                    background: "#fff !important",
                                    boxShadow:
                                      "#6A768F80 0px 3px 6px !important",
                                    filter:
                                      "drop-shadow(0px 0px 3px #6a768f80)",
                                  }}
                                />
                                <i
                                  class="fa fa-check-circle"
                                  aria-hidden="true"
                                  style={{
                                    color: "green",
                                    marginLeft: "10px",
                                    fontSize: "24px",
                                  }}
                                ></i>
                              </div>
                            ) : (
                              <div className="divPadding1">
                                <PhoneInput
                                  country={"us"}
                                  className="PhoneInputforRegister"
                                  international
                                  value={phoneValue}
                                  placeholder=""
                                  inputProps={{
                                    name: "phonenumber",
                                    required: true,
                                  }}
                                  onChange={setPhoneValue}
                                  inputStyle={{
                                    width: "100%",
                                    // border: "none !important",
                                    background: "#fff !important",
                                    boxShadow:
                                      "#6A768F80 0px 3px 6px !important",
                                    filter:
                                      "drop-shadow(0px 0px 3px #6a768f80)",
                                  }}
                                />
                                {/* <img src={unknowdevice}  onClick={handleverifyphonenumber} style={{width:"40px",height:"40px",cursor:"pointer"}}></img> */}
                              </div>
                            )}
                          </div>

                          <div className="w-6/12 lg: w-6/12">
                            {!newuserisMatch && !verifynewuserotpSent ? (
                              <div className="SubmitForRegister">
                                <Button
                                  // className = "uploadcsvButton2"
                                  className="innersubmitForRegisterphoneverify customButton"
                                  onClick={handleverifyphonenumber}
                                >
                                  {/* Submit */}
                                  {checkphoneclicked === "started" ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        width: "40px",
                                        height: "29px",
                                      }}
                                    >
                                      {" "}
                                      <LodingIcon />
                                    </Box>
                                  ) : (
                                    "Verify Phone Number"
                                  )}
                                </Button>
                              </div>
                            ) : newuserisMatch && verifynewuserotpSent ? (
                              <>
                                <div
                                  className="labelsInCommomForRegisteremail fnt24P"
                                  style={{ display: "flex" }}
                                >
                                  Email Address
                                  <p
                                    style={{
                                      color: "gray",
                                      fontSize: "15px",
                                      textAlign: "left",
                                      // paddingTop: '3px',
                                      marginTop: "-7px",
                                      marginLeft: "2px",
                                    }}
                                  >
                                    *
                                  </p>
                                </div>
                                <div>
                                  <InputField
                                    type="text"
                                    className="emailInputInRegiter"
                                    value={data && data.email ? data.email : ""}
                                    // defaultValue = {data.email ? data.email : ''}
                                    disabled
                                    ref={inputRefs.current[6]}
                                    handleInputChange={handleInputChange}
                                    name="email"
                                    submitclicked={false}
                                  />
                                </div>
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginLeft: "2px",
                                  }}
                                >
                                  {emailerror}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* <div className="w-full lg :w-full">
                          <div className="labelsInCommomForRegister fnt24P">
                            Roles
                          </div>
                          <div>
                            <InputField
                              type="text"
                              className="emailInputInRegiter"
                              ref={inputRefs.current[7]}
                              handleInputChange={handleInputChange}
                              value={data.role}
                              disabled = {true}
                              name="role"
                            />
                          </div>
                        </div>   */}
                      <div className="w-6/12 lg: w-6/12">
                        <div
                          className="labelsInCommomForRegister fnt24P"
                          style={{ display: "flex" }}
                        >
                          {/* <Button
                              style={{ marginLeft: "82px", width: "70%" }}
                              className="innersubmitForRegister"
                              onClick={() => submitfornewuser()}
                            >
                              {/*  Submit 
                              {withoutInvitationLinkClick? <Box sx={{
                                display: 'flex', width: "40px", height: "35px"
                              }}>  <LodingIcon /></Box> : "Submit"}
                            </Button> */}
                        </div>
                      </div>
                      {verifynewuserotpSent && !newuserisMatch ? (
                        <div>
                          <div className="otpmsgInRegister">
                            You will receive a verification code to your phone
                            number
                          </div>
                          {/* <div className="otpmsgInRegister">OTP:- <span style={{ color: "#F26930" }}>{verifynewuserOtp}</span> </div> */}

                          <div className="labaleforRegister">Enter Code</div>

                          <div className="InputOpt">
                            <OtpInput
                              className="otpInput"
                              value={otpnewuser}
                              type="password"
                              onChange={handleChangefornewuser}
                              numInputs={4}
                              shouldAutoFocus={true}
                              inputStyle={{
                                width: "76px",
                                height: "",
                                fontSize: "18px",
                                padding: "21px",
                                marginLeft: "7px",
                                borderRadius: "13px",
                                boxShadow: "#6A768F80 0px 3px 6px",
                                border: "none",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              separator={<span></span>}
                            />
                          </div>
                          <div>
                            <div className="otp-time">
                              {resendOtpCondition === "resendotp" ? (
                                <p id="resnedotp">
                                  Your earlier verification code has been
                                  expired !{" "}
                                  <span>
                                    {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                    <a
                                      style={{
                                        color: "#f26930",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => resendCodefornewuser()}
                                    >
                                      Resend verification code
                                    </a>
                                  </span>
                                </p>
                              ) : null}
                              {resendOtpCondition === "otpexpire" ? (
                                <p id="otpexpire">
                                  Your verification code expires in :{" "}
                                  <span>{timer1(counter)} sec</span>{" "}
                                </p>
                              ) : null}

                              {resendOtpCondition === "mailmsg" ? (
                                <p style={{ color: "green" }}>
                                  New verification code has been sent to your
                                  registered mobile!
                                </p>
                              ) : null}
                            </div>
                          </div>
                          {/* <div className="divPadding">
                            <Button
                              style={{ marginLeft: "12px", width: "96%" }}
                              className="innersubmitForRegister1"
                              onClick={() => submitotpfornewuser()}
                            >
                              {/*  Submit 
                              {withoutInvitationLinkClick? <Box sx={{
                                display: 'flex', width: "40px", height: "35px"
                              }}>  <LodingIcon /></Box> : "Verify Code"}
                            </Button>
                            </div> */}
                        </div>
                      ) : null}
                      {/* !newuserisMatch?(
                          <div className="divPadding">
                            <Button   style={{ marginLeft: "72px", width: "80%" }}
                              className="innersubmitForRegister1"
                              
                              onClick={handleverifyphonenumber}
                            >
                              {/*  Next 
                              {clickverify ?<Box sx={{
                                display: 'flex', width: "20px", height: "15px"
                              }}>  <LodingIcon /></Box> : "verify your phone number"}
                            </Button>

                            
                          </div>
                        ):(null) */}

                      {!newuserisMatch &&
                      !verifynewuserotpSent ? null : newuserisMatch ? (
                        <div className="SubmitForRegister">
                          <Button
                            className="innersubmitForRegister customButton"
                            onClick={handleValidateInvitation}
                          >
                            {/* Submit */}
                            {withInvitationLinkClick && valid ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "40px",
                                  height: "29px",
                                }}
                              >
                                {" "}
                                <LodingIcon />
                              </Box>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="SignupSuccessPopup">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "116px",
                          marginTop: "32px",
                        }}
                      >
                        <img
                          alt="Success Icon"
                          src={
                            require("../../assets/img/SuccessIcon.svg").default
                          }
                        ></img>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          justifyContent: "center",
                          marginTop: "72px",
                          marginBottom: "40px",
                          marginRight: "40px",
                          marginLeft: "40px",
                        }}
                      >
                        You have been successfully registered. We are glad that
                        you joined us.
                        <Link
                          className={window.location.href.indexOf("/")}
                          to={{ pathname: "/", state: { email: data.email } }}
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button className="innersubmitForRegisterpleaselogin customButton">
                            {/* Submit */}
                            Please Login
                          </Button>
                        </Link>{" "}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : linkVerifiedMsg === "Link Expired" ? (
            // after refrsh the page it will display link expired
            <div
              style={{
                fontSize: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "500px",
              }}
            >
              <p> Link Expired !!!</p>
            </div>
          ) : linkVerifiedMsg === "" ? (
            // without invitation link
            <div className="RegisterwrapperContainer">
              <div className="lg:flex">
                <div className="flex-1 leftSideInRegisterPage">
                  <div className="RegisterimgWrapper">
                    <img src={welcome} className="image" alt="" />
                  </div>
                  <div className="textInRegister py-16">
                    Get Connected. Stay Informed. <br /> Collaborate.
                  </div>
                </div>
                <div className="flex-1 RegisterrightSide">
                  <div className="Registrtext_1">
                    {hideForm ? (
                      <div className="RegisterinnerText">
                        Enter your data
                        <br />
                        <span className="RegisterinnerText2">
                          <span style={{ color: "#6a768f" }}>to</span> Register
                        </span>
                      </div>
                    ) : (
                      <div style={{ paddingTop: "80px" }}></div>
                    )}
                  </div>
                  {hideForm ? (
                    <div className="wrapperforRegister">
                      <div style={{ marginLeft: "80px", marginRight: "80px" }}>
                        <div className="flex flex-wrap">
                          <div className="w-full lg :w-full">
                            <div
                              className="labelsInCommomForRegister fnt24P "
                              style={{ display: "flex" }}
                            >
                              Username{" "}
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>
                            <div>
                              <InputField
                                type="text"
                                className="emailInputInRegiter"
                                ref={inputRefs.current[0]}
                                handleInputChange={handleInputChangeUsername}
                                validation="required|min:6|username"
                                name="username"
                              />
                            </div>
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginLeft: "2px",
                              }}
                            >
                              {usernameError}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-wrap">
                          <div className="w-6/12 lg : 6/12">
                            <div
                              className="labelsInCommomForRegister fnt24P"
                              style={{ display: "flex" }}
                            >
                              Password
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>

                            <div style={{ marginRight: "24px" }}>
                              <InputField
                                type={"password"}
                                className="emailInputInRegiter"
                                ref={inputRefs.current[1]}
                                handleInputChange={handleInputChange}
                                validation="required|passwordpattern"
                                name="password"
                                isPassword="true"
                              />
                            </div>
                            {/*  <div className="passwrdHideIconSignUp">
                              <button
                                onClick={() => setPassHide(!passHide)}
                              >
                                {
                                  passHide ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash"></i>
                                }
                              </button>
                            </div> */}
                          </div>

                          <div className=" w-6/12 lg:6/12">
                            <div
                              className="labelsInCommomForRegister fnt24P"
                              style={{ display: "flex" }}
                            >
                              Confirm password
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>
                            <div>
                              <InputField
                                type={"password"}
                                className="emailInputInRegiter"
                                ref={inputRefs.current[2]}
                                handleInputChange={handleInputChange}
                                validation="passwordmatch"
                                name="confirm password"
                                password={values.password}
                                isPassword="true"
                              />
                            </div>
                            {/*  <div className="passwrdHideIconSignUp">
                              <button
                                onClick={() => setPassHideConfirmPass(!passHideConfirmPass)}
                              >
                                {
                                  passHideConfirmPass ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash"></i>
                                }
                              </button>
                            </div> */}
                          </div>
                        </div>

                        <div className="flex flex-wrap">
                          <div className="w-6/12 lg:6/12">
                            <div
                              className="labelsInCommomForRegister fnt24P"
                              style={{ display: "flex" }}
                            >
                              First name
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>
                            <div className="divPadding">
                              <InputField
                                type="text"
                                className="emailInputInRegiter"
                                ref={inputRefs.current[3]}
                                handleInputChange={handleInputChange}
                                validation="required"
                                name="firstname"
                              />
                            </div>
                          </div>

                          <div className="w-6/12 lg:6/12">
                            <div
                              className="labelsInCommomForRegister fnt24P"
                              style={{ display: "flex" }}
                            >
                              Last name
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>
                            <div>
                              <InputField
                                type="text"
                                className="emailInputInRegiter"
                                ref={inputRefs.current[4]}
                                handleInputChange={handleInputChange}
                                validation="required"
                                name="lastname"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-wrap">
                          <div className="w-6/12 lg : w-6/12">
                            <div
                              className="labelsInCommomForRegister fnt24P"
                              style={{ display: "flex" }}
                            >
                              Phone Number
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>
                            <div className="divPadding">
                              <PhoneInput
                                className="PhoneInputforRegister"
                                country={"us"}
                                international
                                value={phoneValue}
                                placeholder=""
                                inputProps={{
                                  name: "phonenumber",
                                  required: true,
                                }}
                                onChange={setPhoneValue}
                                inputStyle={{
                                  width: "100%",
                                  border: "none !important",
                                  background: "#fff",
                                  filter: "drop-shadow(0px 0px 3px #6a768f80)",
                                }}
                              />
                            </div>
                          </div>

                          <div className="w-6/12 lg: w-6/12">
                            <div
                              className="labelsInCommomForRegister fnt24P"
                              style={{ display: "flex" }}
                            >
                              Email Address
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  // paddingTop: '3px',
                                  marginTop: "-7px",
                                  marginLeft: "2px",
                                }}
                              >
                                *
                              </p>
                            </div>
                            <div>
                              <InputField
                                type="text"
                                className="emailInputInRegiter"
                                ref={inputRefs.current[6]}
                                handleInputChange={handleInputChangeforSignup}
                                validation="required"
                                name="email"
                              />
                            </div>
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginLeft: "2px",
                              }}
                            >
                              {emailerror}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-wrap">
                          <div className="w-full lg: w-full">
                            <div className="labelsInCommomForRegister fnt24P">
                              Roles
                            </div>
                            <InputField
                              type="text"
                              className="emailInputInRegiter"
                              ref={inputRefs.current[7]}
                              handleInputChange={handleInputChange}
                              value="clinician"
                              disabled
                              name="role"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="SubmitForRegister">
                        {otpsent && signupotpSent ? (
                          <div>
                            <div className="otpmsgInRegister">
                              You will receive a verification code to your phone
                              number
                            </div>
                            {/* <div className="otpmsgInRegister">OTP:- <span style={{ color: "#F26930" }}>{otpRecived}</span> </div> */}

                            <div className="labaleforRegister">Enter Code</div>

                            <div className="InputOpt">
                              <OtpInput
                                className="otpInput"
                                value={otp}
                                type="password"
                                onChange={handleChange}
                                numInputs={4}
                                inputStyle={{
                                  width: "76px",
                                  height: "",
                                  fontSize: "18px",
                                  padding: "21px",
                                  marginLeft: "7px",
                                  borderRadius: "13px",
                                  boxShadow: "#6A768F80 0px 3px 6px",
                                  border: "none",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                separator={<span></span>}
                              />
                            </div>
                            <div>
                              <div className="otp-time">
                                {resendOtpConditaion === "resendotp" ? (
                                  <p id="resnedotp">
                                    Your earlier Verification code has been
                                    expired !{" "}
                                    <span>
                                      <a
                                        style={{
                                          color: "#f26930",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => resendCode()}
                                      >
                                        Resend Verification code
                                      </a>
                                    </span>
                                  </p>
                                ) : null}
                                {resendOtpConditaion === "otpexpire" ? (
                                  <p id="otpexpire">
                                    Your Verification code Expire in :{" "}
                                    <span>{timer1(counter)}sec</span>{" "}
                                  </p>
                                ) : null}

                                {resendOtpConditaion === "mailmsg" ? (
                                  <p style={{ color: "green" }}>
                                    New Verification code has been sent to your
                                    registered mobile!
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <Button
                              style={{ marginLeft: "12px", width: "96%" }}
                              className="innersubmitForRegister"
                              onClick={submitOTP}
                            >
                              {/*  Submit */}
                              {withoutInvitationLinkClick ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "40px",
                                    height: "35px",
                                  }}
                                >
                                  {" "}
                                  <LodingIcon />
                                </Box>
                              ) : (
                                "Submit"
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Button
                              className="innersubmitForRegister"
                              style={{ marginBottom: "20px" }}
                              onClick={handleValidateSignup}
                            >
                              {/*  Next */}
                              {withoutInvitationLinkClick ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "40px",
                                    height: "35px",
                                  }}
                                >
                                  {" "}
                                  <LodingIcon />
                                </Box>
                              ) : (
                                "Next"
                              )}
                            </Button>

                            <div className="account">
                              <Link
                                className={window.location.href.indexOf("/")}
                                to="/"
                              >
                                <div className="account fnt32P">
                                  <u>Log In</u>
                                </div>
                              </Link>{" "}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="SignupSuccessPopup fnt32P">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "116px",
                          marginTop: "32px",
                        }}
                      >
                        <img
                          alt="Success Icon"
                          src={
                            require("../../assets/img/SuccessIcon.svg").default
                          }
                        ></img>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          justifyContent: "center",
                          marginTop: "72px",
                          marginBottom: "40px",
                          marginRight: "40px",
                          marginLeft: "40px",
                        }}
                      >
                        You have been successfully registered. We are glad that
                        you joined us.
                        <Link
                          className={window.location.href.indexOf("/")}
                          to="/"
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            color: "#F26930",
                          }}
                        >
                          <span style={{ color: "#F26930" }}>
                            <u>Please login.</u>
                          </span>
                        </Link>{" "}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          <FooterSign />
        </div>
      )}
    </>
  );
};
const mapStateToProps = (store) => {
  return {
    registerAlertMessage: store.invitation.registerAlertMessage,
    registerErrorMessage: store.invitation.registerErrorMessage,
    checkingEmail: store.invitation.checkingEmail,
    flagEmail: store.invitation.flagEmail,
    registerEmailFlag: store.invitation.registerEmailFlag,
    signupotpSent: store.registration.signupotpSent,
    verifynewuserotpSent: store.invitation.verifynewuserotpSent,
    verifynewuserotpToken: store.invitation.verifynewuserotpToken,
    verifynewuserOtp: store.invitation.verifynewuserOtp,
    newuserisNotMatch: store.invitation.newuserisNotMatch,
    otpexpirenewuser: store.invitation.otpexpirenewuser,
    newuserisMatch: store.invitation.newuserisMatch,
    resendotpnewuser: store.invitation.resendotpnewuser,
    VerifyNewuserErrorMsg: store.invitation.VerifyNewuserErrorMsg,
    signupotpToken: store.registration.signupotpToken,
    signupisMatch: store.registration.signupisMatch,
    signupemail: store.registration.signupemail,
    isNotMatch: store.registration.isNotMatch,
    signuperror: store.registration.signuperror,
    signuppicture: store.registration.signuppicture,
    otpRecived: store.registration.otp,
    linkVerifyingFlag: store.registration.linkVerifyingFlag,
    linkVerifiedMsg: store.registration.linkVerifiedMsg,
    resendotpregister: store.registration.resendotpregister,
    otpexpireregister: store.registration.otpexpireregister,
  };
};
export default connect(mapStateToProps, {
  updateInvitationItem,
  verifyuserphonenumber,
  verifynewuserconfirmOTP,
  resend_otp_newuser,
  checkEmail,
  userRegistarion,
  signupconfirmOTP,
  resend_otp_register,
  linkVerificaion,
})(Register);

export const RegisterAlertMessage = (props) => {
  return (
    <Snackbar
      anchorOrigin={
        props.anchorOrigin
          ? props.anchorOrigin
          : { vertical: "top", horizontal: "center" }
      }
      open={props.flag}
      onClose={props.onClose}
    >
      <Alert
        onClose={props.onClose}
        severity={props.severity ? props.severity : "error"}
      >
        {props.msg}
      </Alert>
    </Snackbar>
  );
};
