import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import '../styles/inActivePopUp.css';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { inActiveAlertFunc, getPatientStatus } from '../../redux/invitation/components/invitation.action';
import { useDispatch, useSelector } from 'react-redux';

export default function InactiveAlert(props) {
    const dispatch = useDispatch();

    const patientId = useSelector((store) => {
        return store.invitation.patientIdFromRed;
    })


    useEffect(() => {
        dispatch(getPatientStatus(patientId))
    }, [])

    const handleClose = () => {
        props.cancelAlert();
        props.callInactivePatient('accepted');
        dispatch(inActiveAlertFunc(patientId, 'accepted', props.userDetails && props.userDetails && props.userDetails.clinic_id));
    }

    return (
        <div>
            <Dialog
                open={props.activeAlert}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className="inActiveHedingTitle">
                        Activate the account
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className="SubTitleforInactive">
                            Are you sure to activate this user account?
                        </div>
                    </DialogContentText>
                </DialogContent>


                <div className="bottomContainerForAlert">
                    <Button onClick={() => props.cancelAlert()} className="cancelButtonAlert">Cancel</Button>
                    <div className="vl"></div>
                    <Button onClick={handleClose} className="cancelButtonAlert">Yes</Button>
                </div>

            </Dialog>
        </div>
    );
}
