import React from "react";
import './dashboardCards.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import '../../../src/assets/styles/font.css';

const useStyles = makeStyles((theme) => ({

    mycard: {
        backgroundColor: '#FFFFFF',
        boxShadow: '#6A768F80 0px 3px 6px',
        borderRadius: '8px',
        cursor: 'pointer',
        marginRight: '45px',
        marginBottom: '56px',
        height: '196px',
    },
    cardcontent: {
        color: '#3D446F',
        fontSize: '16px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        padding: '0px',
        marginTop: '16px',
        marginLeft: '16px',
    },
    cardactions: {
        display: 'flex',
    }
}));

const DashboardCards = (props) => {
    const classes = useStyles();
    return (
        <div>

            <Card variant="outlined"
                onClick={() => {

                    if (props.role === 'clinicians') {
                        props.showPopUpHandler("clinicians")
                    } else if (props.role === 'care sites') {
                        props.invitePopupforcaresite('caresites')
                    } else if(props.role=='Patients') {
                        props.handlecardclick('caresite')
                    }
                    else {
                        <Link
                            className={window.location.href.indexOf("/app/")}
                            to="/app/"
                        >
                        </Link>
                    }
                }}
                className={classes.mycard} style={{ background: props.cardcolor }}>
                <CardContent className={classes.cardcontent}>
                    {props.cardContent}
                </CardContent>
                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }}>
                    <div style={{ color: 'transparent' }}></div>
                    {props.role === 'Patients' ?
                        <img
                            className="imageWrapper"
                            src={require('../../assets/img/view care site.svg').default}></img> : null}

                    {props.role === 'clinicians' ?
                        <img
                            className="imageWrapper"
                            src={require('../../assets/img/add care site.svg').default}></img> : null}

                    {props.role === 'care sites' ?
                        <img
                            className="imageWrapper"
                            src={require('../../assets/img/invite care site.svg').default}></img> : null}

                    <img className="leftarrowimgWrapper"
                        src={require('../../assets/img/left arrow.svg').default}></img>
                </div>

                <div className="cardDescription fnt24P"
                    onClick={() => {

                        if (props.role === 'clinicians') {
                            props.showPopUpHandler("clinicians")
                        } else if (props.role === 'care sites') {
                            props.invitePopupforcaresite('caresites')
                        } else {
                            <Link
                                className={window.location.href.indexOf("/app/")}
                                to="/app/"
                            >
                            </Link>
                        }
                    }}
                >
                    {props.cardDescription}
                </div>
            </Card>
        </div>
    );
};

export default DashboardCards;

