import React from "react";
import './styles.css';
import "../../../src/assets/styles/font.css";
import packageJson from '../../../package.json'
export default function FooterAdmin() {
  return (
    <>
      <footer className=" block w-full lg : w-full bg-white
        bottom-0 
        z-10 footerHeightWrapper">
        <div className = "px-4">
          <div className="flex flex-wrap items-center" >
            <div className="w-5/12 md:w-5/12" style = {{marginRight : '210px'}}>
              <div className="text-blueGray-500  py-1 text-center md:text-left roboto-family">
                <a
                  href="https://abmrc.com"
                  className="text-blueGray-500 hover:text-blueGray-700  py-1 roboto-family fnt24P"
                  target="_blank"
                >
                  {/* ARC, By ABM © 2021 */}
                  {/* <u className="fnt24P"> */}
                    &copy;{new Date().getFullYear()} ABM Respiratory Care
                    {/* </u> */}
                </a><span style={{margin:'auto 5px auto 25px'}}>Version - {packageJson.version}</span>
              </div>
            </div>
            <div className="w-4/12 md:w-4/12 " style={{ display: "flex", justifyContent: "space-between" }}>

              <div className="text-sm text-blue  py-1  md:text-left roboto-family" style = {{marginRight : '12px'}}>
                <a
                  href="https://abmrc.com/contact/"
                  className="text-blueGray-500 hover:text-blueGray-700 fnt24P   py-1 roboto-family"
                  target="_blank"
                >
                  {/* ARC, By ABM © 2021 */}
                  {/* <u className="fnt24P"> */}
                    Contact Us
                    {/* </u> */}
                </a>
              </div>

              <div className="text-sm text-blueGray-500  py-1  md:text-left roboto-family" style = {{marginRight : '12px'}}>
                <a
                  href="https://abmrc.com/privacy-policy/"
                  className="text-blueGray-500 hover:text-blueGray-700 fnt24P   py-1 roboto-family"
                  target="_blank"
                >
                  {/* ARC, By ABM © 2021 */}
                  {/* <u className="fnt24P"> */}
                    Privacy Policy
                    {/* </u> */}
                </a>
              </div>

              <div className="text-sm text-blueGray-500  py-1  md:text-left roboto-family">
                <a
                  href="https://abmrc.com/terms-of-use/"
                  className="text-blueGray-500 hover:text-blueGray- fnt24P py-1 roboto-family"
                  target="_blank"
                >
                  {/* ARC, By ABM © 2021 */}
                  {/* <u className="fnt24P"> */}
                    Terms of Use
                    {/* </u> */}
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
