import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import './EnterEmail.css';
import InputField from '../../components/Inputs_Validation';
import { connect } from "react-redux";
import { userNewEmail, confirmEmailOTP, otpFlag, userNewPhone, confirmPhoneOTP } from "../../redux/user/user.action";
import PhoneInput from "react-phone-input-2";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const EnterEmail = (props) => {
    const [email, setEmail] = React.useState(false);
    const [values, setValues] = React.useState('');
    const [otp, setopt] = React.useState("");
    const [emailPhoneDiv, setEmailPhoneDiv] = React.useState(true);
    const [phoneValue, setPhoneValue] = React.useState("");
    const [counter, setCounter] = React.useState(300);
    const [resendOtpConditaion, setresendotpCondition] = React.useState("resendotp");
    const [openSnack, setOpenSnack] = React.useState(false);
    const handleSnackbar = () => {
        setOpenSnack(false);
    };
    const [snackSeverity, setSnackSeverity] = React.useState("");
    const [msg, setmsg] = React.useState("");
    const [anchororigin, setanchorOrigin] = useState({
        vertical: "top",
        horizontal: "center",
    });
    const [resendNewOtp, setResendNewOtp] = React.useState(false);
    const emailRef = React.useRef();
    const emailotpRef = React.useRef();
    const phoneotpRef = React.useRef();
    const [phone, setPhone] = React.useState(false);
    const scrollRef = React.useRef(null);

    useEffect(()=>{
        if(props.emailToken || phone){
            scrollRef.current.scrollIntoView({ behavior: "smooth" })
        }
    },[props.emailToken, phone])
    const emailpopup = async () => {
        if(!emailRef.current.validate()){
            return;
        }
        try{
            await props.userNewEmail(values.Email, props.id);
            setEmail(true);
        }
        catch(err){
            console.log('Caught',err)
        }
    }

    const resendEmailOtp = () => {
        props.userNewEmail(values.Email, props.id);
        emailotpRef.current.focusInput(0);
        setResendNewOtp(false);
    }

    const resendPhoneOtp = () => {
        props.userNewPhone(phoneValue, props.id);
        phoneotpRef.current.focusInput(0);
        setResendNewOtp(false);
    }
    function timer1(counter) {
        let minutes= Math.floor(counter / 60);
        let sec =  counter % 60;
        let seconds = sec
  
        if (Math.floor((sec / 10))==0){seconds = '0'+sec }
        
        return `${minutes}:${seconds}` 
         
      }
    const phonepopup = async () => {
        if (phoneValue.length<=1) {
            setmsg("Please enter your Phone number to continue!");
            setOpenSnack(true);
            setSnackSeverity("error");
        }
        else if (phoneValue.length>1&&phoneValue.length<8) {
            setmsg("Please enter a vaild Phone number to continue!");
            setOpenSnack(true);
            setSnackSeverity("error");
        }
        else{
        try{
            await props.userNewPhone(phoneValue, props.id);
            setPhone(true);
        }
        catch(err){
            console.log('Caught err--',err)
            setmsg("Please enter a vaild Phone number to continue!");
            setOpenSnack(true);
            setSnackSeverity("error");
            
        }
    }
    }

    const handleInputChange = (name, value) => {
            setValues({
                ...values,
                [name]: value,
            });

    };


    const handleChange = (otp) => {
        setopt(otp);
    };
    const [clickedsavepass, setclickedsavepass] = useState(false)
    const [clickedsave, setclickedsave] = useState(false);

    // email otp for password & security
    const submitOTP = () => {
        setclickedsavepass(true)
        if (
            otp.length === 4 &&
            props.emailToken
        ) {
            let code = otp;
            props.confirmEmailOTP({ code, token: props.emailToken, newEmail: props.newEmailSet });
        }
        if(!otp || otp.length!=4){
            setOpenSnack(true);
            setmsg("Please Enter verification code");
            return;
        }
        if (otp === props.emailOtp && resendNewOtp == false) {
            props.close();
            props.closeEmailDiv();
            setEmailPhoneDiv(false);
        }
    };

    // first otp for email
    useEffect(() => {
        if (props.emailOtp) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
            setresendotpCondition("otpexpire");
            if (counter == 0) {
                setResendNewOtp(true);
            }
        }
        if (counter === 0) {
            setresendotpCondition("resendotp");
        }
    }, [props.emailOtp, counter]);

    // after response come for email 
    useEffect(() => {
        if (resendOtpConditaion === "resendotp") {
            setresendotpCondition("mailmsg");
        }
        if (props.OtpResponseEmail) {
            setCounter(300);
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
            setresendotpCondition("otpexpire");
        }
    }, [props.OtpResponseEmail]);

    // for wrong otp and expire otp
    useEffect(() => {
        if (props.OtpNotMatchPasswordEmail === 400) {
            setmsg("Incorrect code! Please try again");
            setOpenSnack(true);
            setSnackSeverity("error");
        }
        if (props.OtpEpirePasswordEmail === 404) {
            setmsg("Verification code is expired! Please try again with new one");
            setOpenSnack(true);
            setSnackSeverity("error");
        }


    }, [props.OtpNotMatchPasswordEmail, props.OtpEpirePasswordEmail]);


    // Phone otp for password & security
    const submitPhoneOTP = () => {
        setclickedsave(true)
        if (
            otp.length === 4 &&
            props.phoneToken
        ) {
            let code = otp;
            props.confirmPhoneOTP({ code, token: props.phoneToken, newPhone: props.newPhoneSet });
        }
        if(!otp || otp.length!=4){
            setOpenSnack(true);
            setmsg("Please Enter verification code");
            return;
        }

        if (otp === props.mobileOtp && resendNewOtp == false) {
            props.onMobileClose();
            props.closeEmailDiv();
            setEmailPhoneDiv(false);
        }
    };

    // first otp for phone

    useEffect(() => {
        if (props.mobileOtp && props.phoneToken) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
            setresendotpCondition("otpexpire");
            if (counter == 0) {
                setResendNewOtp(true);
            }
        }
        if (counter === 0) {
            setresendotpCondition("resendotp");
        }
    }, [counter, props.mobileOtp, props.phoneToken]);


    useEffect(() => {
        if (resendOtpConditaion === "resendotp") {
            setresendotpCondition("mailmsg");
        }
        if (props.OtpResponsePhone) {
            setCounter(300);
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
            setresendotpCondition("otpexpire")
        }

    }, [props.OtpResponsePhone])


    // for wrong otp and expire otp
    useEffect(() => {
        if (props.OtpNotMatchPhone === 400) {
            setmsg("Incorrect code! Please try again");
            setOpenSnack(true);
            setSnackSeverity("error");
        }
        if (props.OTpexpirePhone === 404) {
            setmsg("Verification code is expired! Please try again with new one");
            setOpenSnack(true);
            setSnackSeverity("error");
        }


    }, [props.OtpNotMatchPhone, props.OTpexpirePhone]);


    return (
        <>
            <RegisterAlertMessage
                flag={openSnack}
                onClose={handleSnackbar}
                severity={snackSeverity}
                msg={msg}
                anchorOrigin={anchororigin}
            />
            {emailPhoneDiv ?
                <div style={{ borderTop: "6px solid #e8f4f8" }} ref={scrollRef} className="EnterEmailContainer">
                    {
                        props.email === false ?
                            <>
                                <h1 className="cnfrmac">
                                    <i className="fa fa-envelope psd-icon"></i>Enter your new email address</h1>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div className="inputfield">
                                        <InputField
                                            ref={emailRef}
                                            type="email"
                                            className="inputfiedforchangepwd fnt24P"
                                            validation="required|emailpattern"
                                            name="Email"
                                            handleInputChange={handleInputChange}
                                            placeholder="Email"
                                            disabled={props.newEmailSet}
                                        // onChange={handleOnChangeEmail}
                                        />
                                        <p className="alertmsg"></p>
                                    </div>
                                    {props.newEmailSet
                                        //props.emailToken 
                                        ?
                                        <div className="buttonbutnPopup">
                                            <button className="btnpopupInactive" style={{ outline: "none" }} >Next</button>
                                        </div>
                                        :
                                        <div className="buttonbutnPopup">
                                            <button className="btnpopup" style={{ outline: "none" }} onClick={emailpopup}  >Next</button>
                                        </div>
                                    }
                                </div>
                            </>
                            :
                            <>
                                <h1 className="cnfrmac">
                                    <i className="fa fa-mobile-alt psd-icon" style={{paddingRight:'4px'}}></i><span>Enter your updated mobile number</span></h1>
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
                                    <div className="inputfield">
                                        <PhoneInput
                                            // enableSearch
                                             country={'us'}
                                            className="PhoneInputforRegister"
                                            international
                                            value={phoneValue}
                                            placeholder=""
                                            inputProps={{
                                                name: "phonenumber",
                                                required: true,
                                            }}
                                            onChange={setPhoneValue}
                                            handleInputChange={handleInputChange}
                                            disabled={phone}
                                            inputStyle={{
                                                width: "92%",
                                                border: "none !important",
                                                background: "#fff !important",
                                                boxShadow: "#6A768F80 0px 3px 6px !important",
                                                filter: "drop-shadow(0px 0px 3px #6a768f80)",
                                              }}
                                        />
                                        <p className="alertmsg"></p>
                                    </div>
                                    {phone ?
                                        <div className="buttonbutnPopup">
                                            <button className="btnpopupInactive" style={{ outline: "none" }}>Next</button>
                                        </div>
                                        :
                                        <div className="buttonbutnPopup">
                                            <button className="btnpopup" onClick={phonepopup} style={{ outline: "none" }} >Next</button>
                                        </div>
                                    }
                                </div>

                            </>
                    }

                    {
                        props.emailToken ?
                            <>
                                <div style={{ borderTop: "6px solid #e8f4f8" }}>
                                    <h1 className="cnfrmac">
                                        <i className="fa fa-envelope psd-icon"></i>You will recive a verification code on your email</h1>
                                </div>

                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div className="inputfieldforotpinput">
                                        <OtpInput
                                            ref={emailotpRef}
                                            className="otp-email"
                                            value={otp}
                                            type="password"
                                            onChange={handleChange}
                                            numInputs={4}
                                            inputStyle={{
                                                width: '100%',
                                                boxShadow: '#6A768F80 0px 3px 6px',
                                                fontSize: '16px',
                                                height: '60px',
                                                padding: '21px',
                                                borderRadius: '13px',
                                            }}
                                            shouldAutoFocus={true}
                                            // onChange={(e) => setCode4(e.target.value)}
                                            separator={<span></span>}
                                        />
                                    </div>
                                    <div className="buttonbutnPopupforsaveinotp">
                                        <button
                                            className="btnpopup"
                                            style={{outline:'none',marginTop:'45px'}}
                                            onClick={submitOTP}
                                            >{/* {clickedsavepass && otp !== props.emailOtp && otp.length === 4 ? "Invalid OTP!" : "Save"} */} Save </button>
                                    </div>
                                </div>
                                <div className="otp-time">
                                    {
                                        resendOtpConditaion === "resendotp" ? <p id="resnedotp">Your earlier Verification code has been expired ! <span>
                                            <a style={{ color: "#f26930", cursor: "pointer" }} onClick={() => resendEmailOtp()}>Resend Verification code</a></span></p> : null
                                    }
                                    {
                                        resendOtpConditaion === "otpexpire" ? <p id="otpexpire">Your Verification code Expire in : <span>{timer1(counter)} sec</span> </p> : null
                                    }

                                    {resendOtpConditaion === "mailmsg" ? <p style={{ color: "green" }}>New Verification code has been sent to your email!</p> : null}

                                </div>
                            </> : phone === true ?
                                <>
                                    <div style={{ borderTop: "6px solid #e8f4f8" }}>
                                    
                                        <h1 className="cnfrmac">
                                            <i className="fa fa-envelope psd-icon"></i>You will recive a verification code on your phone</h1>
                                    </div>

                                    {/* <div className="varifyotp">OTP :- {props.mobileOtp}</div> */}

                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div className="inputfieldforotpinput">
                                            <OtpInput
                                                ref={phoneotpRef}
                                                className="otp-email"
                                                value={otp}
                                                type="password"
                                                onChange={handleChange}
                                                numInputs={4}
                                                inputStyle={{
                                                    width: '100%',
                                                    boxShadow: '#6A768F80 0px 3px 6px',
                                                    fontSize: '16px',
                                                    height: '60px',
                                                    padding: '21px',
                                                    borderRadius: '13px',
                                                }}
                                                shouldAutoFocus={true}
                                                // onChange={(e) => setCode4(e.target.value)}
                                                separator={<span></span>}
                                            />
                                        </div>

                                        <div className="buttonbutnPopupforsaveinotp">
                                            <button
                                                className="btnpopup"
                                                onClick={submitPhoneOTP}>{/* {clickedsave && otp !== props.mobileOtp && otp.length === 4 ? "Invalid OTP!" : "Save"} */}save</button>
                                        </div>
                                    </div>
                                    {/*  <div className="enteremail">
                                        <div className="resendcode" onClick={() => resendPhoneOtp()}>Resend code</div>
                                    </div> */}
                                    <div className="otp-time">
                                        {
                                            resendOtpConditaion === "resendotp" ? <p id="resnedotp">Your earlier Verification code has been expired ! <span>
                                                <a style={{ color: "#f26930", cursor: "pointer" }} onClick={() => resendPhoneOtp()}>Resend Verification code</a></span></p> : null
                                        }
                                        {
                                            resendOtpConditaion === "otpexpire" ? <p id="otpexpire">Your Verification code Expire in : <span>{timer1(counter)} sec</span> </p> : null
                                        }

                                        {resendOtpConditaion === "mailmsg" ? <p style={{ color: "green" }}>New Verification code has been sent to your registered mobile!</p> : null}

                                    </div>
                                </>
                                : null
                    }

                    <div>

                    </div>

                </div>
                : null}

        </>
    )
}
const mapStateToProps = (store) => {
    return {
        id: store.user.user && store.user.user.id,
        newEmailSet: store.user.newEmailSet,
        emailToken: store.user.emailToken,
        emailOtpMatch: store.user.emailOtpMatch,
        newPhoneSet: store.user.newPhoneSet,
        phoneToken: store.user.phoneToken,
        mobileOtp: store.user.mobileOtp,
        emailOtp: store.user.emailOtp,
        OtpResponseEmail: store.user.OtpResponseEmail,
        OtpNotMatchPasswordEmail: store.user.OtpNotMatchPasswordEmail,
        OtpEpirePasswordEmail: store.user.OtpEpirePasswordEmail,
        OtpResponsePhone: store.user.OtpResponsePhone,
        OtpNotMatchPhone: store.user.OtpNotMatchPhone,
        OTpexpirePhone: store.user.OTpexpirePhone,
    };
};

export default connect(mapStateToProps, { userNewEmail, confirmEmailOTP, otpFlag, userNewPhone, confirmPhoneOTP })(EnterEmail);
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
export const RegisterAlertMessage = (props) => {
    return (
        <Snackbar

            anchorOrigin={
                props.anchorOrigin
                    ? props.anchorOrigin
                    : { vertical: "top", horizontal: "center" }
            }
            open={props.flag}
            autoHideDuration={5000}
            onClose={props.onClose}
        >
            <Alert
                onClose={props.onClose}
                severity={props.severity ? props.severity : "error"}
            >
                {props.msg}
            </Alert>
        </Snackbar>
    );
};