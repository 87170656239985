import React, { useEffect } from "react";
import "./chatList.css";
import ChatListItems from "./ChatListItems";
import DirectChatList from "./DirectChatList";
import GroupChatList from "./GroupChatList";
import { connect } from "react-redux";
import { Avatar } from "@material-ui/core";
import {
  directChat,
  showChatWindow,
  getAcceptSharing,
} from "../../../redux/sharing/sharing.action";
import Button from "@material-ui/core/Button";
import { pageHeading } from "../../../redux/user/user.action";
import profilePicture from "../../../../src/assets/img/Top nav profile icon.png";

const ChatList = (props) => {
  const [groupChat, setGroupChat] = React.useState(false);
  const [directChat, setDirectChat] = React.useState(false);
  const [chatlistItem, setChatlistItem] = React.useState(true);
  const [currIndex, setCurrIndex] = React.useState(1);
  const [text, setText] = React.useState();

  var filteredData;

  const changeHandler = (chatlistItemVal, directChatVal, groupChatVal) => {
    setDirectChat(directChatVal);
    setChatlistItem(chatlistItemVal);
    setGroupChat(groupChatVal);
    props.showChatWindow(false);
  };

  filteredData =
    text && currIndex == 1
      ? props.allDialogs.filter((item) => {
        const itemData = item.name.toLowerCase();
        const textData = text.toLowerCase();
        return itemData.includes(textData);
      })
      : props.allDialogs;

  useEffect(() => {
    props.pageHeading("Messages");
  }, []);

  const onSearch = (val) => {
    switch (currIndex) {
      case 1:
        break;
      case 2:
        props.getAcceptSharing(val);
        break;
      case 3:
        props.getAcceptSharing(val);
        break;
      default:
    }
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;

    document.scrollingElement.scrollTop = 0;

  }, []);
  return (
    <div className="main__chatlist">
      <div className="chatlist__heading">
        <div
          className="User_icon"
          onClick={() => {

            changeHandler(true, false, false);
            setCurrIndex(1);
          }}
        >
          {props.UserProfileUpdated && props.UserProfileUpdated.photo_uuid ? (
            <Avatar

              className="user_Icon_Button"
              // className="MuiAvatar-root"
              src={`https://ucarecdn.com/${props.UserProfileUpdated.photo_uuid}/`}
            />
          ) : props.userProfile.photo_uuid ? (
            <Avatar

              className="user_Icon_Button"
              // className="MuiAvatar-root"
              src={`https://ucarecdn.com/${props.userProfile.photo_uuid}/`}
            />
          ) : (
            <Avatar src={profilePicture} alt="default pic"></Avatar>
            // <Avatar
            //   alt="."
            //   className="user_Icon_Button"
            //   className="MuiAvatar-root"
            //   src={`https://ucarecdn.com/${props.UserProfileUpdated.photo_uuid}/`}
            // />
          )}
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="groupchat_icon"
            onClick={() => {
              changeHandler(false, true, false);
              setCurrIndex(2);
            }}
            style={{ marginRight: "20px" }}
          >
            {directChat ? (
              <Button
                className="newchat"
                style={{
                  boxShadow: "#6A768F80 0px 3px 6px",
                  cursor: "pointer",
                  color: "#36434B",
                  textTransform: "capitalize",
                  // fontSize: "12px",
                }}
              >
                New Message
              </Button>
            ) : (
              <Button
                className="newchat"
                style={{
                  boxShadow: "#6A768F80 0px 3px 6px",
                  cursor: "pointer",
                  color: "#36434B",
                  textTransform: "capitalize",
                  // fontSize: "12px",
                }}
              >
                New Message
              </Button>
            )}
          </div>
          <div
            className="groupchat_icon"
            onClick={() => {
              changeHandler(false, false, true);
              setCurrIndex(3);
            }}
          >
            {
              groupChat ? (
                <Button
                  className="group-chat"
                  style={{
                    boxShadow: "#6A768F80 0px 3px 6px",
                    cursor: "pointer",
                    color: "#36434B",
                    textTransform: "capitalize",
                    // fontSize: "12px",
                  }}
                >
                  New group
                </Button>
              ) : (
                <Button
                  className="group-chat"
                  style={{
                    boxShadow: "#6A768F80 0px 3px 6px",
                    cursor: "pointer",
                    color: "#36434B",
                    textTransform: "capitalize",
                    // fontSize: "12px",
                  }}
                >
                  New group
                </Button>
              )
            }
          </div>
        </div>
      </div>
      <div className="chatList__search">
        <div className="search_wrap">
          <input
            type="text"
            placeholder="Search"
            required
            onChange={(e) => {
              onSearch(e.target.value);
              setText(e.target.value);
            }}
          />
          <button className="search-btn">
            <i className="fa fa-search"></i>
          </button>
        </div>
      </div>
      <div className="chatlist__items">

        {chatlistItem ? (
          <ChatListItems
            chatlistItem={chatlistItem}
            allDialogs={filteredData}
          />
        ) : directChat ? (
          <DirectChatList
            animationDelay={3}
          />
        ) : groupChat ? (
          <GroupChatList animationDelay={1} />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    chatUsers: store.user.chatUsers,
    showChatWindowFlag: store.sharing.showChatWindowFlag,
    UserProfileUpdated: store.invitation.UserProfileUpdated,
    userProfile: store.user.user,
    allDialogs: store.quickblox.allChats,
  };
};
export default connect(mapStateToProps, {
  directChat,
  showChatWindow,
  pageHeading,
  getAcceptSharing,
})(ChatList);
