import React from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
const ApexChart = (props) => {
   //console.log("props_value", props);

  let series1=[{x:moment(props.firstdate).format('YYYY-MM-DD')?.substring(0,10) + 'T00:00:00.000Z' ,y:'NaN'  }]
  // console.log("series1", series1);
  let x1;
  for(let i=0;i<props.value.length;i++){
    x1 =   props.value[i]?.x
     series1.push({x:x1,y:props.value[i]?.y})
     
  }
  var options = {
    chart: {
      height: 380,
      width: "100%",
      type: "area",
      animations: {
        initialAnimation: {
          enabled: true,
        },
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        zoom: {
          enabled: false,
        },

      },

    },
    series: [
      {
        name: "",
        data: series1
      },
    ],

    xaxis: {
      type: "datetime",
      labels:{
               format: 'dd MMM'
            },
    },
    plotOptions: {
      bar: {
        barHeight: '100%',
        horizontal: true   // ← "true" flip the axis, "100" is not shown anymore
      }
    },

    colors: ["#c23c04", "#fff"],
    tooltip: {
      shared: false, // added to make sure tooltip for all points is visible
      intersect: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {

        let timeStamp = moment(series1[dataPointIndex]?.x).format(
          "DD-MMM-YYYY HH:mm:ss"
        );
        var d = dataPointIndex? Number(series[seriesIndex][dataPointIndex]):-10;
        //console.log('ddddddd',d)
        var data = d > 59 ? d / 60 : d;
        var mydata2 = data.toFixed(2);
        var mydata = mydata2.toString().split(".");
        var mintsdata = (mydata[1] / 100) * 60;
        var mintsvalueFromDecimal = Math.trunc(mintsdata);
        var HMRVlueMinutsToHour;
        if (d > 59) {
          HMRVlueMinutsToHour = `${mydata[0]} hour ${mintsvalueFromDecimal} min`;
        } else {
          HMRVlueMinutsToHour = `${d} min`;
        }

        return (d === -10 ? null :
          '<div class="arrow_box" style="padding:9px;">' +
          "HMR: " +
          "<span>" +
          HMRVlueMinutsToHour +
          "</span>" +
          "<div style='margin-top : 5px;margin-bottom : 0px'>" +
          "Timestamp: " +
          "<span>" +
          timeStamp +
          "</span>" +
          "</div>"
        );
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: ["#c23c04", "#fff"],
      width: 2,
      dashArray: 0,
    },
    markers: {
      size: 5,
      colors: undefined,
      strokeColors: '#fff',
      strokeWidth: 1,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: false,
      hover: {
        size: undefined,
        sizeOffset: 1
      }
    },
    yaxis: {
      show: true,
    },
    dataLabels: { enabled: false },

  };
  return (
    <div id="chart-timeline" >
      <ReactApexChart
        options={options}
        series={options.series}
        type="area"
        height={350}
      />
    </div >
  );
};

export default ApexChart;
