import React from "react";
import "./AnnouncementCards.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import "../../screens/PrivateRoutes/clinicAdminDashboard/ClinicAdminDashboard.css";
import linkedin from "../../../src/assets/icons/linkedin.png";
import twitter from "../../../src/assets/icons/twitter.png";
import "../../../src/assets/styles/font.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mycard: {
    backgroundColor: "#FFFFFF",
    boxShadow: "#6A768F80 0px 3px 6px",
    borderRadius: "8px",
    marginRight: "30px",
    marginBottom: "46px",
  },
  cardcontent: {
    color: "#3D446F",
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: "bold",
    padding: "0px",
    // marginTop: '16px',
    marginLeft: "16px",
  },
  cardactions: {
    display: "flex",
  },
}));

const AnnouncementCards = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Card variant="outlined" className={classes.mycard}>
        <CardContent className={classes.cardcontent}>
          {props.cardContent}
        </CardContent>
        <div>
          {props.description === "AnnouncementOne" ? (
            <div className="anouncement3Blue">
              <div className="announcement-1-sent fnt24P">
                Please share your feedback{" "}
                <span>
                  {" "}
                  on the <span style={{ color: "#F26930" }}>
                    <a href="https://www.surveymonkey.com/r/ArcConnectFeedback" target="_blank" rel="noopener noreferrer">
                    Arc Connect Portal </a></span>
                </span>{" "}
                by completing this survey?
              </div>
            </div>
          ) : null}

          {props.description === "AnnouncementTwo" ? (
            <div className="anouncement3">
              <div className="announcement-2-sent fnt24P">
                <span style={{ color: "#F26930" }}>Arc Connect</span> tutorials
                are available under{" "}
                {/* <u style={{ cursor: "pointer", color: "#126AC4" }}> */}
                <a
                  href="https://abmrc.com/document_cat/videos-product-training/#doc_filters" target='_blank' rel="noopener noreferrer"
                  style={{ cursor: "pointer", color: "#126AC4", textDecoration: 'underline' }}
                >
                  Resources
                </a>.
                {/* </u> */}
              </div>
            </div>
          ) : null}

          {props.description === "AnnouncementThree" ? (
            <div className="anouncement3Green">
              <div className="announcement-3-sentence fnt24P">
                Follow{" "}
                <span style={{ color: "#F26930" }}>ABM Respiratory Care</span>
                <p>latest news on</p>
              </div>
              <div
                style={{
                  marginLeft: "64px",
                  marginRight: "64px",
                  marginTop: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    paddingTop: "24px",
                  }}
                >
                  <div className="linkedinImgWrapper">
                    <a href="https://www.linkedin.com/company/abmrc/" target="_blank">
                      <img
                        src={linkedin}
                        style={{ cursor: "pointer" }}
                        alt="no internet"
                      />
                    </a>
                  </div>
                  <div className="twitterImgWrapper">
                    <a href="https://twitter.com/abmcare?lang=en" target="_blank">
                      <img
                        src={twitter}
                        style={{ cursor: "pointer" }}
                        alt="no internet"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export default AnnouncementCards;
