import React , {useEffect} from 'react';
import './styles.css';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import userIcon from "../../assets/icons/userProfileIcon.svg";
import "../../assets/styles/font.css";
import Pagination from "../Pagination";
import excelIcon from "../../../src/assets/img/excel-1-129882.png";
import '../../screens/PrivateRoutes/clinicAdminDashboard/ClinicAdminDashboard.css';
import { useDispatch, useSelector } from 'react-redux';
import {NotificationSummaryTable,navReportSearchToPatientProfile,clear_patient_profile} from '../../redux/user/user.action'
import apiAbmBlaze,{CHECK_IF_DEVICE_IS_CONNECTED} from '../../api';
import { useHistory } from "react-router-dom";

const PatientTableForNotificationSummary = (props) => {

    const dispatch = useDispatch();
    const [orderDirection, setOrderDirection] = React.useState("asc");
    const [valueToOrderBy, setValueToOrderBy] = React.useState("lastname");
    const [totalPageNumber, setTotalPageNumber] = React.useState();
    const [page, setPage] = React.useState(1);
    const [BottomText, setBottomText] = React.useState("");
    const history = useHistory();



    //Sorting

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === "asc");
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? "desc" : "asc");
      };

    const createSortHandler = (property) => (event) => {

        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {


        if (b[orderBy] < a[orderBy]) {
    
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
    
          return 1;
        }
    
        return 0;
      };

    function getComparator(order, orderBy) {

        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }
    

    const sortedRowInformation = (rowArray, comparator) => {

        const stabilizedRowArray = rowArray.map((el, index) =>
          [el, index]
        );
    
        stabilizedRowArray.sort((a, b) => {
    
          const order = comparator(a[0], b[0]);
    
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedRowArray.map((el) => el[0]);
      };

      //---------------------0----------------------------------------------

    var sendingObj = new Object();
    sendingObj.clinicId = props.clinicId ? props.clinicId : "";
    sendingObj.page = page;
    const navToPatientProfile = async (item) => {
        clear_patient_profile();
       
        let query = Buffer.from(JSON.stringify(
          {
            profile_id: item && item.item.profile_id ? item.item.profile_id : null,
            clinic_id: item.item.clinic_id ? item.item.clinic_id : null,
            pair_id: item&&item.item2 && item.item2.pair_id ? item.item2.pair_id : null,
          })).toString('base64');
        if (query) {
          dispatch(navReportSearchToPatientProfile(item));
          history.push(`/app/PatientProfile?p=${query}`);
        
    
      }
    }
    const checkifconnectedtodevice = async (val)  => {
        await apiAbmBlaze
          .post(CHECK_IF_DEVICE_IS_CONNECTED, {
            profile_id:val.profile_id
          })
          .then(async (res) => {
            if (res.data) {
              navToPatientProfile({item:val,item2:res.data.rows[0]})
            }
          })
          .catch((error) => {
            console.log("ERROR checkifconnectedtodevice", error);
          });
      };
    

      const changingPage = (event, value) => {
        setPage(value);
        
         dispatch(NotificationSummaryTable({...sendingObj , page : value}))
      };

    
      const patientTable = useSelector((store)=>{
            return store.user.notification_summary_table;
      })

      const patientTotalCount = useSelector ((store)=>{
        return store.user.sm_PatientTable_totalCount;
      })

      const patientDataCount = useSelector ((store)=>{
        return store.user.dataCount;
      })

      

      useEffect(() => {
        if (patientTotalCount) {
            setTotalPageNumber(Math.ceil(patientTotalCount / 10 ));
        }

      }, [patientTotalCount]);

      const setDownload_file = () => {
        const csvData = objectToCSV(patientTable);
        download(csvData);
      };
      Array.prototype.remove = function(index) {
        this.splice(index, 1);
      }
      const objectToCSV = (data) => {
        //get header
        const csvRows = [];
        if (data[0]) {
          const headers = Object.keys(data[0]);
          headers.remove(1); // Removing UUIDs
          headers.remove(3); // Removing Addresses
          headers.remove(6); // Removing Total Count
          csvRows.push(headers.join(","));
    
          //loops over the row
          for (const row of data) {
            const values = headers.map((header) => {
              const escaped = (" " + row[header]).replace(/"/g, '\\"');
              return `"${escaped}"`;
            });
            csvRows.push(values.join(","));
          }
    
          return csvRows.join("\n");
        }
      };
    const download = (data) => {
        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
    
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
    
        a.setAttribute(
          "download",
          `NotificationSummary`
        );
    
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
    return (

        <div style={{ paddingLeft:'74px', paddingRight: '42px'}}>
            <table className='mainTable' >
                <tbody>
                <tr className='tableRow'>
                    <th className='tabelHeadingImg'>
                        <div style={{ padding: '10px' }}>

                        </div>
                    </th>
                    <th className='tabelHeading1 fnt22P' key="last_name">
                        <TableSortLabel
                            active={true}
                            direction={
                                valueToOrderBy === "last_name" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("last_name")}
                        >
                            <div className='tableHeadingtop fnt24P'>
                                Last Name
                            </div>
                        </TableSortLabel>
                    </th>
                    <th className='tabelHeading1 fnt22P' key="firstname">
                        <TableSortLabel
                            active={true}
                            direction={
                                valueToOrderBy === "firstname" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("firstname")}
                        >
                            <div className='tableHeadingtop fnt24P'>
                                First Name
                            </div>
                        </TableSortLabel>
                    </th>
                    <th className='tabelHeading1 fnt22P' key="ads_score">
                        <TableSortLabel
                            active={true}
                            direction={
                                valueToOrderBy === "ads_score" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("ads_score")}
                        >
                            <div className='tableHeadingtop fnt24P'>
                                Adherence %
                            </div>
                        </TableSortLabel>
                    </th>


                    <th className='tabelHeading1 fnt22P' key="SpO2">
                        <TableSortLabel
                            active={true}
                            direction={
                                valueToOrderBy === "SpO2" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("SpO2")}
                        >
                            <div className='tableHeadingtop fnt24P'>
                                SpO2 
                            </div>
                        </TableSortLabel>
                    </th>
                    <th className='tabelHeading1 fnt22P' key="deviations">
                        <TableSortLabel
                            active={true}
                            direction={
                                valueToOrderBy === "deviations" ? orderDirection : "asc"
                            }
                            onClick={createSortHandler("deviations")}
                        >
                            <div className='tableHeadingtop fnt24P'>
                               Deviation
                            </div>
                        </TableSortLabel>
                    </th>
                </tr>
                {patientTable && patientTable.length  ? (
                    sortedRowInformation(
                        patientTable,
                        getComparator(orderDirection, valueToOrderBy)
                    ).map((item, index) => {

                        return (
                            <tr className='tabelDescAndRowAfterMapping' key={index} onClick={(e) => {
                                checkifconnectedtodevice(item);
                              }} >
                                <td className='tabelDescAndRowAfterMapping'>
                                    <div style={{ padding: "8px", display: "flex", flexDirection: "row", justifyContent: "center"}}>

                                        {/*<Avatar src={userIcon}>
                                        </Avatar>*/}
                                        <Avatar src={item.photo_uuid?`https://ucarecdn.com/${item.photo_uuid}/`:userIcon}></Avatar>
                                    </div>
                                </td>

                                <td className='tabelHeading1 fnt22P'>
                                    <div className='tableDesc'>

                                        {item.last_name ? item.last_name : '-'}
                                    </div>
                                </td>

                                <td className='tabelHeading1 fnt22P'>
                                    <div className='tableDesc'>

                                        {item.first_name ? item.first_name : '-'}
                                    </div>
                                </td>

                                <td className='tabelHeading1 fnt22P'>
                                    <div className='tableDesc'>

                                        {item.ads_score ? (parseFloat(item.ads_score).toFixed(0)) : '-'}
                                    </div>
                                </td>
                                <td className='tabelHeading1 fnt22P'>
                                    <div className='tableDesc'>

                                        {item.spo2 ? item.spo2 : '-'}
                                    </div>
                                </td>
                                <td className='tabelHeading1 fnt22P'>
                                    <div className='tableDesc'>

                                        {/* {item.deviations} */}
                                        {item.deviations == "yes" ? (
                                            <Chip
                                                label="yes"
                                                style={{
                                                    boxShadow: "#6A768F80 0px 3px 6px",
                                                    backgroundColor: "#f2f8f2",
                                                    color: "#4d547a",
                                                }}
                                            />
                                        ) : (
                                            <Chip
                                                label="no"
                                                style={{
                                                    boxShadow: "#6A768F80 0px 3px 6px",
                                                    backgroundColor: "#f2f8f2",
                                                    color: "red",
                                                }}
                                            />
                                        )}
                                    </div>
                                </td>



                            </tr>
                        );

                    })
                ) : <tr>
                <td colSpan="6" className='nodatafoundWrapper fnt22P'>No data found</td>
                </tr>}
                </tbody>
            </table>
            <div className="totalCountsAtPatientProfileTableNotifiSummary fnt24P">
                
                {(((page - 1) * 10) + patientDataCount)}/{patientTotalCount}
                </div>
            <div>
            {patientTable.length ? (
                  <div className="PaginationForPatienttableNotiSummary">
                    <div className="downloadWrapper fnt24P">
                      <div style={{ paddingTop: '8px'}}> Download </div>
                      <img src={excelIcon} onClick={setDownload_file}  style={{cursor:'pointer'}} className="excelIconWrapper"></img>
                    </div>
                    <div>
                      <Pagination
                        count={totalPageNumber}
                        page={page}
                        onChange={changingPage}
                      />
                    </div>
                  </div>
                ) : null}
            </div>
        </div>
    );
}

export default PatientTableForNotificationSummary;
