import React, { useEffect } from "react";
import { loadProfile } from "../../../redux/user/user.action";
import { connect } from "react-redux";
import ClinicAdminDashboard from "../clinicAdminDashboard/ClinicAdminDashboard";
import AbmAdminDashboard from "../abmAdminDashboard/abmAdminDashboard";
import { useHistory } from "react-router-dom";
import {
  superAdmin,
  abmAdmin,
  clinicadmin,
  clinician,
  patient,
} from "../../../constant/constantRole";
const Dashboard = (props) => {
  return (
    <>
      {(props.user && props.user.role) === superAdmin ? (
        <AbmAdminDashboard />
      ) : (props.user && props.user.role) === abmAdmin ? (
        <AbmAdminDashboard />
      ) : (props.user && props.user.role) === clinicadmin ? (
        <ClinicAdminDashboard />
      ) : (props.user && props.user.role) === clinician ? (
        <ClinicAdminDashboard />
      ) : null}
    </>
  );
};
const mapStateToProps = (store) => {
  return { user: store.user.user };
};
export default connect(mapStateToProps, { loadProfile })(Dashboard);
