import { intersection } from "lodash";

export function isLoggedIn() {
  /*
   * Note:
   *  This app assume if local storage have roles it means
   *  user is authenticated you can update this logic as per your app.
   */
  return localStorage.getItem("roles");
}
export function isLoggedInUser() {
  /*
   * Note:
   *  This app assume if local storage have roles it means
   *  user is authenticated you can update this logic as per your app.
   */
  return localStorage.getItem("user");
}

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function GetAllowedRoutes(routes) {
  const roles = JSON.parse(localStorage.getItem("roles"));

  return routes.filter(({ permission, path }, item) => {
    if (localStorage.getItem("isAssociatedClinic") === 0) {
      if (path === "/") {
        return false;
      } else if (path === "/notificationSummary") {
        return false;
      }
    }

    if (!permission) {
      return true;
    } else if (!isArrayWithLength(permission)) {
      return true;
    } else {
      return intersection(permission, [roles]).length;
    }
  });
}

// To check if string has only alphabers with/out space
export function alphabetsOnlyWithSpace(str) {
  let regx = /^[A-Za-z]*$/;
  if (!str) {
    return true;
  }
  let arr = str.split(" ");
  for (let i = 0; i < arr.length; i++) {
    if (!regx.test(arr[i])) {
      return false;
    }
  }
  return true;
}

/*
@params
Pass any string to encode that to  base64
*/
export const base64Encode = (str) => {
  const buffer = Buffer.from(str, "utf-8");
  return buffer.toString("base64");
};
/*
@params
Pass any string to decode that to  base64
*/
export const base64Decode = (encodedStr) => {
  const buffer = Buffer.from(encodedStr, "base64");
  return buffer.toString("utf-8");
};

export const downloadFn = (data, reportName) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", reportName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const objectToCSV = (data) => {
  //get header
  const csvRows = [];
  if (data[0]) {
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));
    //loops over the row
    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = (" " + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }
    //from escaped comma seprater val
    return csvRows.join("\n");
  }
};

export const generateQueryParams = (obj) => {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      params.append(key, obj[key]);
    }
  }
  return params.toString();
};
export const convertToLocaleIST = (dateString) =>{
  return new Date(dateString).toLocaleString("en-US", {timeZone: "Asia/Kolkata"})
}
