import React, { memo } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import Auth from "./Auth";



function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/app">
          
          <PrivateRoutes />
          {/* <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route> */}
        </Route>

        <Route path="">
          <Auth />
        </Route>

      </Switch>
    </Router>
  );
}

export default memo(Routes);
