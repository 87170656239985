import React from "react";

// components

import CardUsers from "../../../components/Cards/InvitationTable";

export default function ClinicianTable() {
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12">
          <CardUsers />
        </div>
      </div>
    </>
  );
}
