import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const AlertSnackBar = ({ flag, onClose, anchorOrigin, msg, severity }) => {
  return (
    <Snackbar
      anchorOrigin={
        anchorOrigin ? anchorOrigin : { vertical: 'top', horizontal: 'right' }
      }
      open={flag}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity ? severity : 'success'}>
        {msg}
      </Alert>
    </Snackbar>
  );
};
export default AlertSnackBar;
