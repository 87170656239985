import React from 'react';
import './FooterSign.css';

export default function FooterSign() {
  return (
    <>
      <footer className="block  bg-white">
        <div
          className="mx-auto px-4 block"
        >
          <div className='footer-wrapper'>
            <div className='subfooter-1'>
              <a
                href="https://abmrc.com"
                className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
                target="_blank"
              >
                {/* ARC, By ABM © 2021 */}
                {/* <u> */}
                  &copy;{new Date().getFullYear()} By ABM Respiratory Care
                {/* </u> */}
              </a>
            </div>
           
             <div className=' footer-wrapper1'>
            <div className='subfooter-2'>
            <a
                  href="https://abmrc.com/contact/"
                  className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
                  target="_blank"
                >
                  {/* ARC, By ABM © 2021 */}
                  {/* <u> */}
                    Contact Us
                    {/* </u> */}
                </a>
            </div>

            <div className='subfooter-3'>
            <a
                  href="https://abmrc.com/privacy-policy/"
                  className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
                  target="_blank"
                >
                  {/* ARC, By ABM © 2021 */}
                  {/* <u> */}
                    Privacy Policy
                    {/* </u> */}
                </a>
            </div>

            <div className='subfooter-4'>
            <a
                  href="https://abmrc.com/terms-of-use/"
                  className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
                  target="_blank"
                >
                  {/* ARC, By ABM © 2021 */}
                  {/* <u> */}
                    Terms of Use
                    {/* </u> */}
                </a>
            </div>
            </div>
            </div>
        </div>
      </footer>
    </>
  );
}
