import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
// import Switch from '@mui/material/Switch';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InactiveAlert from "../ModalPopup/InactiveAlert";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  inActiveAlertFunc,
  getPatientStatus,
} from "../../redux/invitation/components/invitation.action";
import InActiveSnackBar from "../InActiveAlertSnackBar";
import ActiveAlert from "../ModalPopup/ActiveAlert";
// import ToggleButton from "react-toggle-button";
import Switch from "react-switch";
import "./styles.css";
import { reportsSearchUser } from "../../redux/user/user.action";

const PatientToggleButton = (props) => {
  const [therapySwitch, setTherapySwitch] = React.useState();
  const [inActiveAlert, setInActiveAlert] = React.useState(false);

  const [activeAlert, setActiveAlert] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");

  useEffect(() => {
    if (
      props.getPatientStat &&
      props.getPatientStat.status &&
      props.getPatientStat.status === "blocked"
    ) {
      setTherapySwitch(false);
    }
    if (
      props.getPatientStat &&
      props.getPatientStat.status &&
      props.getPatientStat.status === "accepted"
    ) {
      setTherapySwitch(true);
    }
  }, [props.getPatientStat]);

  const handleChange = (e) => {
    // setTherapySwitch(e.target.checked);
    if (therapySwitch == false) {
      therapySwitch == false ? setActiveAlert(true) : setActiveAlert(false);
    } else {
      therapySwitch == true ?
       setInActiveAlert(true)
        : setInActiveAlert(false);
    }
  };

  const cancelInvites = () => {
    setInActiveAlert(false);
    // setCancelAlert(false)
  };

  const calcelActiveAlert = () => {
    setActiveAlert(false);
  };

  const setActivePat = (val) => {
    setTherapySwitch(val === "blocked" ? false : true);
    setSnackMsg("Patient profile has been deactivated!");
    setOpenSnack(true);
    setSnackSeverity("success");
  };

  const setInactivePatient = (val) => {
    setTherapySwitch(val === "accepted" ? true : false);
    setSnackMsg("Patient profile has been activated!");
    setOpenSnack(true);
    setSnackSeverity("success");
  };

  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  setTimeout(() => {
    setOpenSnack(false);
  }, [50000]);

  props.callTherapyToggle(therapySwitch);
  return (
    <>
      {/* <InactiveAlert
        inActiveAlert={inActiveAlert}
        cancelInvite={cancelInvites}
        callActPatient={setActivePat}
        userDetails={props.navFromReportSearchToPatientProfile}
      /> */}

      <ActiveAlert
        activeAlert={activeAlert}
        cancelAlert={calcelActiveAlert}
        callInactivePatient={setInactivePatient}
        userDetails={props.navFromReportSearchToPatientProfile}
      />

      <InActiveSnackBar
        flag={openSnack}
        onClose={handleSnackbar}
        msg={snackMsg}
        // anchorOrigin={anchororigin}
        severity={snackSeverity}
      />

      {/* {props.active ? */}
        <Switch
          // onChange={handleChange}
          checked={therapySwitch}

          onColor="#8ea817"
          // offColor="#6A768F"
          uncheckedIcon={false}
          checkedIcon={false}
          height={25}

          width={50}
        />
         {/* :
        <Switch
          onChange={handleChange}
          checked={therapySwitch}

          // onColor="#CAEF59"
          offColor="#6A768F"
          uncheckedIcon={false}
          checkedIcon={false}
          height={25}

          width={50}
        /> } */}

    </>
  );
};

const mapStateToProps = (store) => {
  return {
    getPatientStat: store.invitation.getPatientStat,
    patientId: store.invitation.patientIdFromRed,
    alertSnack: store.invitation.activeInactiveStatusSnack,
    navFromReportSearchToPatientProfile: store.user.navFromReportSearchToPatientProfile
  };
};

export default connect(mapStateToProps, {
  inActiveAlertFunc,
  getPatientStatus,
  reportsSearchUser,
})(PatientToggleButton);
