import {
  SELECTED_USER_CHAT,
  DIALOGS,
  GET_ALL_MESSAGE,
  PREV_CONVERSATION,
  GET_PROFILE_QUICKBLOXID,
  PROFILE_QUICKBLOXID,
  OCCUPANTS_IDS,
  CHAT_DIALOG_ID,
  SELECTED_GROUP_CHAT,
  OPPONENENT_DETAILS,
  CURR_DIALOG,
  RESET_ALL_STATE,
} from "../action.list";

let defaultState = {
  directChat: false,
  seletedUser: "",
  selectedUserFlag: false,
  dialogs: "",
  allMessageItem: [],
  prevConversation: [],
  profileByQBID: {},
  chatProfilesByQbId: {},
  fetched: false,
  conversation: "",
  allChats: [],
  conversationFlag: false,
  chatDialogId: null,
  selectedGroup: [],
  opponentsDetails: "",
  currDialog: null,
};

export const quickbloxReducer = (state = defaultState, action) => {
 
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_MESSAGE:
      return { ...state, ...payload };
    case SELECTED_USER_CHAT:
      return { ...state, ...payload };
    case DIALOGS:
      return { ...state, ...payload };
    case PREV_CONVERSATION:
      return { ...state, ...payload };
    case GET_PROFILE_QUICKBLOXID:
      return { ...state, ...payload };
    case PROFILE_QUICKBLOXID:
      return { ...state, ...payload };
    case OCCUPANTS_IDS:
      return { ...state, ...payload };
    case CHAT_DIALOG_ID:
      return { ...state, ...payload };
    case SELECTED_GROUP_CHAT:
      return { ...state, ...payload };
    case OPPONENENT_DETAILS:
      return { ...state, ...payload };
    case CURR_DIALOG:
      return { ...state, ...payload };
      case RESET_ALL_STATE:
        return defaultState;
    default:
      return state;
  }
};
