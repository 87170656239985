import {
  GET_ACCEPTED_SHARING_REDUX,
  CANCEL_SHARING_REQ_REDUX,
  REVOKE_SHARING_REQ_REDUX,
  ACCEPT_SHARING_REQ_REDUX,
  SEND_SHARING_REQ_REDUX,
  DIRECT_CHAT,
  SHOW_CHAT_WINDOW,
} from "../action.list";
import apiAbmBlaze, {
  ACCEPTED_SHARING,
  CANCEL_SHARING_REQ,
  REVOKE_SHARING_REQ,
  ACCEPT_SHARING_REQ,
  SEND_SHARING_REQ,
} from "../../api";

export const getAcceptSharing = (val) => async (dispatch) => {
  dispatch({
    type: GET_ACCEPTED_SHARING_REDUX,
    payload: {
      acceptedSharingFlag: true,
    },
  });
  apiAbmBlaze
    .post(ACCEPTED_SHARING, {
      search: val,
    })
    .then(async (res) => {
      if (res.data && res.data.data) {
        await dispatch({
          type: GET_ACCEPTED_SHARING_REDUX,
          payload: {
            acceptedSharing: res.data.data,
            acceptedSharingFlag: false,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};
export const directChat = (val) => async (dispatch) => {
  await dispatch({
    type: DIRECT_CHAT,
    payload: {
      directChat: val,
    },
  });
};
export const showChatWindow = (val) => async (dispatch) => {
  await dispatch({
    type: SHOW_CHAT_WINDOW,
    payload: {
      showChatWindowFlag: val,
    },
  });
};
