import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchInvitation } from "../../redux/invitation/components/invitation.action";
import { fetchNotificationSummary } from "../../redux/notification/notification.action";
import { notificationData, repetedNotificationpopup } from "../../redux/notification/notification.action";
import {
  userLoginData,
  NotificationSummaryTable,
  pageHeading,
} from "../../redux/user/user.action";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import "./notifficationSummary.css";
import Select from "../../components/Select";
import NotificationSummaryCards from "../../components/Cards/notificationSummaryCards";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import "../../../src/assets/styles/font.css";
import SeparatorTopSection from "../../components/SeparatorTopSection";
import SeparatorBottomSection from "../../components/SeparatorBottomSection";
import PatientTableForNotificationSummary from "../../../src/components/PatientTableForNotificationSummary";
import LoadingIcon from "../LoadingIcon";
import apiAbmBlaze, { NOTIFICATION_SUMMARY_PATIENT_REPORT } from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import moment from "moment";



const NotifySummary = ({
  user,
  userLoginData,
  userSpClinicList,
  fetchNotificationSummary,
  notificationData,
  userLogInFlag,
  NotificationSummaryTable,
  sm_PatientTable_totalCount,
  notificationSummary,
  pageHeading,
  notificationSettingsSelectedId,
  noficationsuccess,
  repetedNotificationpopup
}) => {
  const classes = useStyles();


  const [clinicId, setClinicId] = React.useState(null);
  const [clinicName, setClinicName] = React.useState(null);
  const [clinicsids, setClinicsIds] = React.useState(); //By Poovarasan
  const [page, setPage] = React.useState(1);
  const [activeTotalPageNumber, setActiveTotalPageNumber] = React.useState();
  const [last14Days, setLast14Days] = React.useState([]);
  const [paramQuery, setParamQuery] = React.useState(0);
  const [submitSaveBtn, setSubmitSaveBtn] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const handleSnackbar = () => {
    setOpenSnack(false);
  };
  let clinic_id_for_useEffect = 0

  useEffect(() => {
    userLoginData(user.id);
    pageHeading("Notifications");
  }, []);

  useEffect(() => {
    if (sm_PatientTable_totalCount) {
      setActiveTotalPageNumber(Math.ceil(sm_PatientTable_totalCount / 10));
    }
  }, [sm_PatientTable_totalCount]);


  useEffect(() => {
    if (clinicsids) {
      NotificationSummaryTable({
        clinicId: clinicsids,
      });
    }
  }, [clinicsids]);


  useEffect(() => {
    let value = userSpClinicList.rows ? userSpClinicList.rows : null;
    if (value && value.length) {
      try {
        let local_currClinic = localStorage.getItem("currClinic");
        let _local_currClinic = JSON.parse(local_currClinic ? local_currClinic : '{}');
        let obj = value.findIndex(o => o.c_id === _local_currClinic.clinicId);

        if (_local_currClinic.clinicId && _local_currClinic.clinicName && obj !== -1) {
          setClinicsIds(_local_currClinic.clinicId);
        }
        else {
          let clinic_details = {
            clinicId: value[0].c_id,
            clinicName: value[0].clinicname
          }
          setClinicsIds(value[0].c_id);
          localStorage.setItem("currClinic", JSON.stringify(clinic_details))
        }
      } catch (err) {
        console.log("NotificationSummery Catch:", err)
      }
    }
  }, [userSpClinicList])



  useEffect(() => {
    //creating date for last 14 days
    let last14Days = [];
    for (let i = 0; i < 14; i++) {
      var today = new Date(new Date().setDate(new Date().getDate() - 1));
      var result = today.setDate(today.getDate() - i);

      last14Days.push(new Date(result));
    }

    setLast14Days(last14Days);
  }, []);
  useEffect(() => {
    document.documentElement.scrollTop = 0;

    document.scrollingElement.scrollTop = 0;

  }, []);

  // console.log("noficationsuccess", noficationsuccess);
  useEffect(() => {

    if (noficationsuccess === "success") {
      setOpenSnack(true);
      setSnackMsg("Notification settings has been updated successfully!")
    }
    return repetedNotificationpopup();
  }, [noficationsuccess]);

  const callSelect = (val) => {



    if (val !== null) {

      setClinicId(val.c_id);

      setClinicName(val.clinicname);

      setClinicsIds(val.c_id);

      fetchNotificationSummary(val.c_id);
      let query = Buffer.from(JSON.stringify({ clinicId: val.c_id })).toString('base64')
      setParamQuery(query)
      clinic_id_for_useEffect = val.c_id
    }

    //sujan sir's code


  };

  useEffect(() => {

  }, [notificationSummary])


  const onClickHandler = () => {

    if (clinicId == null) {
      var defClinicId = userSpClinicList.rows[0].c_id;
      var defClinicName = userSpClinicList.rows[0].clinicname;
      notificationData(defClinicId, defClinicName);
    } else {
      notificationData(clinicId, clinicName);
    }
  };


  return (
    <React.Fragment>
      {/* userLogInFlag */false ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            height: "100vh",
          }}
        >
          <LoadingIcon />
        </div>
      ) : (
        <div style={{ width: "100%", backgroundColor: "#F2F7F8" }}>
          <AlertSnackBar
            msg={snackMsg}
            flag={openSnack}
            onClose={handleSnackbar}
            key={"cupSnackbar"}
            anchorOrigin={customeSnackbarAnchor}
            severity={snackSeverity}
          />
          <div className="w-full lg:w-12/12 notificationSummaryHeading1 fnt32P">
            Care Site
          </div>

          <div className="notificationSummaryDropdown">
            <Select
              options={userSpClinicList.rows}
              callSelect={callSelect}
              defClinic={
                userSpClinicList &&
                  userSpClinicList.rows &&
                  userSpClinicList.rows.length !== 0
                  ? userSpClinicList.rows[0].clinicname
                  : ""
              }
              notificationSettingsSelectedId={notificationSettingsSelectedId}

            />
          </div>

          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <div className="notificationSummaryHeading2 fnt32P">
              {" "}
              Current notification settings{" "}
            </div>

            {user.role === 'clinicadmin'|| user.role === 'clinician'?(
            <Link style={{ marginTop: "10px" }} to={`/app/notificationsettings?p=${paramQuery}`}>
            {user.role === 'clinicadmin'?<img
              className="editImageWrapper"
              src={
                require("../../assets/img/notificationsummary_edit.svg")
                  .default
              }
              onClick={onClickHandler}
            ></img>
            :
            <img
              className="editImageWrapper1"
              src={
                require("../../assets/img/visibility_black_24dp.svg")
                  .default
              }
              onClick={onClickHandler}
            ></img>}
           </Link>
            
            ):""}  


          </div>

          <div
            className="flex flex-wrap"
            style={{
              marginTop: "24px",
              marginLeft: "82px",
              marginRight: "24px",
              marginBottom: "0px",
            }}
          >
            <div className="w-full lg:w-4/12 ">
              <NotificationSummaryCards description="Adherence"></NotificationSummaryCards>
            </div>

            <div className="w-full lg:w-4/12">
              <NotificationSummaryCards description="SpO2"></NotificationSummaryCards>
            </div>

            <div className="w-full lg:w-4/12">
              <NotificationSummaryCards descriptions="Therapy Adjustments"></NotificationSummaryCards>
            </div>
          </div>

          <div>
            <SeparatorTopSection />
            <div style={{ background: "white" }}>
              {/* <div className="notificationSummaryPatientslistHeadingWrapper fnt32P">
                {" "}
                Patient list: Adherence:
                {notificationSummary != null && notificationSummary.length && notificationSummary[0].notification_settings.adherence
                  ? notificationSummary[0].notification_settings.adherence !== 0
                    ? `<` + notificationSummary[0].notification_settings.adherence
                    : "Off"
                  : "Off"}{" "}
                 
                Deviation:
                {notificationSummary != null && notificationSummary[0] && notificationSummary[0].notification_settings
                  ? notificationSummary[0].notification_settings
                      .therapyAdjustment
                    ? "Yes"
                    : "No"
                  : false}{" "}
                SpO2:{" "}
                {notificationSummary != null && notificationSummary.length && notificationSummary[0].notification_settings.spo2
                  ? notificationSummary[0].notification_settings.spo2 !== 0
                    ? `<` + notificationSummary[0].notification_settings.spo2
                    : "Off"
                  : "Off"}
               
              </div> */}

              <div className="dateWrapper fnt32P">
                {"Notification for "}
                {moment().subtract(1, 'days').format("DD MMM YYYY")}
              </div> 

              <div
                className={classes.root}
                style={{ overflow: "hidden", paddingTop: "40px" }}
              >
                
                <Grid item xs={12}>
                  <PatientTableForNotificationSummary clinicId={clinicsids} />
                </Grid>
              </div>
            </div>
          </div>
          <SeparatorBottomSection />
          <div className="notificationSummaryHeadingWrapper fnt32P">
            Notification summary archive
          </div>
          <div className="notificationSummarySubHeadingWrapper fnt21P">
            Last 14 notifications summary
          </div>
          <NotificationSummaryArchive
            page={page}
            last14Days={last14Days}
            clinicsids={clinicsids}
          />
        </div>
      )}
    </React.Fragment>
  );
};

const NotificationSummaryArchive = (props) => {
  const [loading, setLoading] = useState("");

  const downloadFn = (data, reportName) => {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", reportName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setLoading("");
  };

  const objectToCSV = (data) => {
    //get header
    const csvRows = [];
    if (data[0]) {
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));
      //loops over the row
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = (" " + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      //from escaped comma seprater val
      return csvRows.join("\n");
    }
  };

  const getData = async (date, reportName) => {
    setLoading(reportName);
    await apiAbmBlaze
      .post(NOTIFICATION_SUMMARY_PATIENT_REPORT, {
        clinicId: props.clinicsids,
        choosedDate: date,
      })
      .then(async (res) => {
        if (res.data) {
          if (res.data.generatedData) {
            downloadFn(objectToCSV(res.data.val), reportName);
          } else {
            let _val = res.data.val;
            let buff = Buffer.from(_val.summary_report, "base64");
            let csv = buff.toString("ascii");
            downloadFn(csv.replace(/['"]+/g, ""), reportName);
          }
        }
      })
      .catch(() => {
        downloadFn("No notifications for this date", reportName);
      });
  };

  return (
    <div className="notify-summery-arch-wrapper">
      <Grid item xs={12}>
        <table className="mainTable">
          <tbody>
            <tr className="tableRow">
              <th
                className="tabelHeadingNotificationArchive"
                style={{ width: "50%" }}
              >
                <div className="tableHeadingTopDate fnt24P">Date</div>
              </th>
              <th className="tabelHeadingNotificationArchive">
                <div className="tableHeadingTopDate fnt24P">Report</div>
              </th>
            </tr>

            {props.last14Days.length ? (
              props.last14Days.map((item, index) => {
                let date = new Date(item);
                const month = date.toLocaleString("default", { month: "short" });
                let displayDate =
                  date.getDate() + " " + month + " " + date.getFullYear();
                let reportName =
                  "Notification_Summary_" +
                  date.getDate() +
                  "_" +
                  month +
                  "_" +
                  date.getFullYear();
                return (
                  <tr className="tabelDescAndRowafterMapping" key={index}>
                    <td className="tabelHeadingNotificationArchive">
                      <div className="tableRowDate fnt24P">{displayDate}</div>
                    </td>

                    <td className="tabelHeadingNotificationArchive">
                      <div
                        className="tableRowReport fnt24P"
                      >
                        <span style={{cursor:'pointer'}} onClick={() => getData(date, reportName)}>
                        {loading && loading === reportName
                          ? "Loading data..."
                          : reportName}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </Grid>
    </div>
  );
};

NotifySummary.defaultProps = {
  color: "light",
};

NotifySummary.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  btn: {
    color: "red",
    backgroundColor: "white",
    size: 100,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 500,
  },
  table: {
    minWidth: 650,
  },
}));

const mapStateToProps = (store) => {
  return {
    user: store.user.user,
    invitationList: store.invitation.invitationList,
    allcliniclist: store.invitation.allcliniclist,
    userSpClinicList: store.user.userLogIn,
    userLogInFlag: store.user.userLogInFlag,
    notificationSettingSave: store.notification.notificationSettingSave,
    notificationSettingsData: store.notification.notificationSettingsData,
    notificationSummary: store.notification.notificationSummary,
    notificationSettingsSelectedId: store.notification.notificationSettingsSelectedId,
    noficationsuccess: store.notification.noficationsuccess,
  };

};

export default connect(mapStateToProps, {
  fetchInvitation,
  fetchNotificationSummary,
  notificationData,
  userLoginData,
  NotificationSummaryTable,
  pageHeading,
  repetedNotificationpopup
})(NotifySummary);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AlertSnackBar = ({
  flag,
  onClose,
  anchorOrigin,
  msg,
  severity,
}) => {
  return (
    <Snackbar
      anchorOrigin={
        anchorOrigin ? anchorOrigin : { vertical: "top", horizontal: "right" }
      }
      open={flag}
      // autoHideDuration={20000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity ? severity : "success"}>
        {msg}
      </Alert>
    </Snackbar>
  );
};