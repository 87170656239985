import React, { useRef, useEffect, useState } from "react";
import moment from "moment";
import "./styles.css";
import Popover from "@mui/material/Popover";
import apiAbmBlaze, { PATIENT_PROFILE_THERAPY_DETAILS_GRAPH } from "../../api";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
export default function AdherenceElementWeeksNew(props) {
  const {
    adherence_score = [],
  } = props;
  // const usesRef = useRef();
  let intViewportWidth = window.innerWidth;
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [currentIndex, setCurrentIndex] = React.useState(0);
  const [popoverValue, setPopoverValue] = React.useState(null);
  const [ADS_Details, setADS_Details] = React.useState([]);
  const [current_adherence_score, SetCurrent_adherence_score] = React.useState(0);
  const handleClick = (event, date, adherence_score) => {
    SetCurrent_adherence_score(adherence_score);
    // console.log("_adherence_score:", adherence_score);
    // if (popoverValue  !== null) {
    patient_Profile_Therapy_ProgressBar(
      {
        profile_id: props.patientDetails.profile_id,
        pair_id: props.patientDetails.pair_id,
        date,
      },
      event
    );

    // if (popoverValue) {
    //   setAnchorEl(event.currentTarget);
    // }
  };

  /*
    times_per_day - indicates number of therapies, patient has to run on each day(of therapy goals set)
  */
  const [times_per_day, set_times_per_day] = useState([]);
  async function fetchSessions(profile_id){
    let date = moment().add(-7,'days');
    let dates = [];
    dates.push(date.format('YYYY-MM-DD'));
    for(let i=0; i<6; i++){
      date = date.add(1,'day');
      dates.push(date.format('YYYY-MM-DD'))
    }
    let res;
    try{
      res = await apiAbmBlaze.post('/api/getSetTherapySessions',{profile_id:profile_id,dates});
      set_times_per_day(res.data)
    }
    catch(er){
      console.log('FETCH ERR',er);
    }
  }
  useEffect(()=>{
    fetchSessions(props.patientDetails.profile_id);
  },[props.patientDetails.profile_id]);
  const handleClose = () => {
    setAnchorEl(null);
    // setCurrentIndex(0);
    setADS_Details([]);
  };


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    // console.log("ADS_Details.length:", ADS_Details.length, current_adherence_score)
    if (ADS_Details.length) {
      if (current_adherence_score) {
        let totalTherapiesDone = ADS_Details.length;
        let totalTherapies = ADS_Details[0].total_therapies; // Math.round((1 / Number(current_adherence_score)) * totalTherapiesDone);
        // console.log("totalTherapies:", totalTherapies);
        let _rows = []
        ADS_Details.forEach(element => {
          // console.log("element:", element);
          _rows.push({
            totCycle: element.val?.CYCLES?.length,
            totalCompleteCycle: element?.cycle_measurements ? element.cycle_measurements?.length : 0
          })

        });
        if (_rows.length < totalTherapies) {
          for (let i = _rows.length; i < totalTherapies; i++) { // This loop to create empty(grey) therapy to show if totaltherpaies is greter than deleiverd therapy
            _rows.push({
              totCycle: '',
              totalCompleteCycle: '',
            })
          }
        }
        setPopoverValue({
          totalTherapies: totalTherapies,
          totalTherapiesDone: totalTherapiesDone,
          therapyRows: _rows
        })
      }
    } else {
      // setPopoverValue(null);
    }
    // }
  }, [ADS_Details]);


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(242, 247, 248)",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
      color: "red",
    },
  }));

  const patient_Profile_Therapy_ProgressBar = async (val, event) => {
    let evenObj = event.currentTarget;
    const { profile_id, pair_id, date } = val;
    await apiAbmBlaze
      .post(PATIENT_PROFILE_THERAPY_DETAILS_GRAPH, {
        profile_id,
        pair_id,
        date,
      })
      .then((res) => {
        if (res.data.val.length) {
          setAnchorEl(evenObj);
          setADS_Details(res.data.val);
          // if (res.data.val[currentIndex].val.CYCLES) {
          //   setAnchorEl(evenObj);
          // }
          // console.log("res.data.val:", res.data.val)
        }
      })
      .catch((error) => {
        console.log("Error----", error);
      });
  };
  let popover_position_top_px = (popoverValue?.totalTherapiesDone > popoverValue?.totalTherapies) ?
    `-${60 + (30 * popoverValue?.totalTherapiesDone)}px` :
    `-${60 + (30 * popoverValue?.totalTherapies)}px`

  return (
    <div className="new-adherence-elements-week-wrapper">
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        placement="top"
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            // options: {
            //   altAxis: true,
            //   altBoundary: true,
            //   tether: true,
            //   rootBoundary: 'document',
            //   padding: 8,
            // },
          }
        ]}

        style={{ top: popover_position_top_px, left: "-75px" }}
      >
        <div className="" style={{ maxHeight: '300px', minWidth: '180px' }}>
          <div className="" style={{ fontSize: '14px', fontWeight: 'bold', padding: "12px 5px" }}>
            Therapies delivered :  {popoverValue?.totalTherapiesDone}/{popoverValue?.totalTherapies}
          </div>
          <TableContainer /* component={Paper}   */>
            <Table sx={{ minWidth: 100 }} aria-label="customized table">
              <TableBody>
                {popoverValue?.therapyRows?.map((item, index) => {

                  let _color = "#f26930";
                  if (item.totalCompleteCycle && item.totalCompleteCycle == 0) {
                    _color = 'red';
                  }
                  else if (item.totalCompleteCycle && item.totalCompleteCycle == item.totCycle) {
                    _color = 'green';
                  }
                  else if (!(item.totalCompleteCycle && item.totCycle)) {
                    _color = 'grey';
                  }

                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        style={{
                          // color:'rgb(242, 247, 248)',
                          color: _color,
                          padding: "2px 10px",
                          fontWeight: "bold",
                        }}
                        component="th"
                        scope="row"
                      >
                        Therapy {index + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          // color:'rgb(242, 247, 248)',
                          color: _color,
                          // padding: "0px",
                          fontWeight: "bold",
                          padding: "2px 10px",
                        }}
                        align="left"
                      >
                        {item.totalCompleteCycle || item.totCycle ? `${item.totalCompleteCycle} / ${item.totCycle} cycles` : null}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              position: "relative",
              mt: "10px",
              "&::before": {
                backgroundColor: "white",
                content: '""',
                display: "block",
                position: "absolute",
                width: 12,
                height: 12,
                top: -6,
                transform: "rotate(45deg)",
                left: "calc(50% - 6px)",
              },
            }}
          />
        </div>
      </Popover>
      {intViewportWidth < 600 ? (
        <div className="calendarLayoutWrapper">
          <div className="calendarLayout">
            <div className="progressElementRowWrapper">
              {adherence_score
                .slice(0, adherence_score.length - 7)
                .map((item, index) => {
                  const dayMoment = moment(item.date_day).utc();
                  let dateText = `${dayMoment.format("DD MMM")}`;
                  let value = item.cycles_count * 68/times_per_day[index]?.times_per_day;

                  const _adherence_score = value || 0;
                  return (
                    <div key={index}>
                      <div
                        className="progressElement"
                        // key={index}
                        aria-describedby={index}
                        onClick={(e) => {
                          _adherence_score > 0 ?
                            handleClick(e, moment(dayMoment).format("YYYY-MM-D"), item.max) :
                            handleClick(e, moment(dayMoment).format("YYYY-MM-D"), 0)
                        }}
                        style={{
                          cursor:
                            _adherence_score > 0
                              ? 'pointer'
                              : '',
                        }}
                      >
                        <div className="progress-outer">
                          <div
                            className="progress-inner"
                            style={{
                              height:
                                _adherence_score > 0
                                  ? `${_adherence_score}px`
                                  : 0,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="day-date-text-wrapper">
                        <div style={{ color: "#727696" }}>{dateText}</div>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="progressElementRowWrapper">
              {adherence_score
                .slice(adherence_score.length - 7, adherence_score.length - 1)
                .map((item, index) => {
                  const dayMoment = moment(item.date_day).utc();
                  let dateText = `${dayMoment.format("DD")}`;
                  let value = item.cycles_count * 68/times_per_day[index]?.times_per_day;

                  const _adherence_score = value || 0;
                  return (
                    <div
                      key={index}
                      aria-describedby={index}
                      onClick={(e) => {
                        _adherence_score > 0 ?
                          handleClick(e, moment(dayMoment).format("YYYY-MM-D"), item.max) :
                          handleClick(e, moment(dayMoment).format("YYYY-MM-D"), 0)
                      }}
                    // onClick={(e) => {
                    //   handleClick(e, moment(dayMoment).format("YYYY-MM-D"));
                    // }}
                    >
                      <div className="progressElement" key={index}
                        style={{
                          cursor:
                            _adherence_score > 0
                              ? 'pointer'
                              : '',
                        }}>
                        <div className="progress-outer">
                          <div
                            className="progress-inner"
                            style={{
                              height:
                                _adherence_score > 0
                                  ? `${_adherence_score}px`
                                  : 0,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="day-date-text-wrapper">
                        {/* <div>{dayText}</div> */}

                        <div style={{ color: "#727696" }}>{dateText}</div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      ) : (
        <div className="calendarLayoutWrapper">
          <div className="calendarLayout">
            <div className="progressElementRowWrapper">
              {adherence_score
                .slice(0,adherence_score.length-1)
                .map((item, index) => {
                  // if (i < 7) return null
                  const dayMoment = moment(item.date_day).utc();
                  let dateText = `${dayMoment.format("DD MMM")}`;
                  let value = item.cycles_count * 68/times_per_day[index]?.times_per_day;

                  const _adherence_score = value || 0;
                  return (
                    <div key={index}>
                      <div
                        className="progressElement"
                        // key={index}
                        aria-describedby={index}
                        onClick={(e) => {
                          _adherence_score > 0 ?
                            handleClick(e, moment(dayMoment).format("YYYY-MM-D"), item.max) :
                            handleClick(e, moment(dayMoment).format("YYYY-MM-D"), 0)
                        }}
                        style={{
                          cursor:
                            _adherence_score > 0
                              ? 'pointer'
                              : '',
                        }}
                      >
                        <div className="progress-outer">
                          <div
                            className="progress-inner"
                            style={{
                              height:
                                _adherence_score > 0
                                  ? `${_adherence_score}px`
                                  : 0,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="day-date-text-wrapper">

                        <div style={{ color: "#727696" }}>{dateText}</div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
