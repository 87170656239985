



import React, { useState, useEffect } from "react";


import { Link,  useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  logIn,
  signIn,
  confirmOTP,
  updatepassword,
  userLoginData,
  userPendingData,
  resetLoginErrorMsg,
  resend_otp_login,
  makeOtpResendLoginEmpty
} from "../../../../redux/user/user.action";

import {
  loginUser,
  updateInvitationItem,
  AlertMsg,
} from "../../../../redux/invitation/components/invitation.action";

import { makeStyles } from "@material-ui/core/styles";
import InputField from "../../../../components/Inputs_Validation/index";
import ".././styles.css";
import welcome from "../../../../assets/img/welcome.png";
import Button from "@mui/material/Button";

import FooterSign from "../../../../components/Footers/FooterSign";
import {
  superAdmin,
  abmAdmin,
  clinicadmin,
  clinician,

} from "../../../../constant/constantRole";
import '../../../../assets/styles/font.css';
import CustomSnackbar from "../../../../components/Alertmessage/AlertMsg";
import LodingIcon from "../../../../components/LoadingIcon";

import Box from '@mui/material/Box';
import { base64Encode } from "../../../../utils";





const Resetpassword = (props) => {
   let profile_id = null;
   const [loginscreen,setloginscreen]=useState(false);
  const[phoneormail,setphoneormail]=useState('')
   const [restorewithemail,setrestorewithemail]=useState(false);
   const [restorewithphone,setrestorewithphone]=useState(false);
  
const [otp, setopt] = useState("");
const [values, setValues] = React.useState({});
const [passwordupdated,setpasswordupdated]=React.useState(false);
const [openSnack, setOpenSnack] = React.useState(false);
const [snackSeverity, setSnackSeverity] = React.useState('error');
const [snackMsg, setSnackmsg] = React.useState('');
const [anchororigin, setanchorOrigin] = useState({
  vertical: 'top',
  horizontal: 'center',
});
var history = useHistory();
const inputRefs = React.useRef([React.createRef(), React.createRef()]);
const [counter, setCounter] = React.useState(60);
const [resendOtpConditaion, setresendotpCondition] = React.useState("resendotp");
const [clickNextButton, SetClickNextButton] = React.useState(false);
const handleInputChange = (name, value) => {
  if (value.length > 0) {
    setValues({
      ...values,
      [name]: value,
    });
  }
};



const submit = async () => {
  SetClickNextButton(true);
  let isValid = true;
  let firstErrorIndex = -1;
  for (let i = 0; i < inputRefs.current.length; i++) {
    if (inputRefs.current[i].current === null) {
      continue;
    }
    const valid = inputRefs.current[i].current.validate();
    if (!valid) {
      SetClickNextButton(false);
      if (firstErrorIndex === -1) {
        // Store the index of the first input field with an error
        firstErrorIndex = i;
      }
      isValid = false;
    }
  }
  if (!isValid) {
    // Focus on the first input field with an error
    if (firstErrorIndex !== -1 && inputRefs.current[firstErrorIndex].current) {
      inputRefs.current[firstErrorIndex].current.focusInput();
    }
    
    return;
  }
  if (isValid === true) {
    if(values.newpassword===values.confirmpassword){ 
      await props.updatepassword(props.profile_id,base64Encode(values.newpassword))
        

        
        
    } else{
        setSnackmsg("Passwords must match")
        setOpenSnack(true);
        SetClickNextButton(false);
    }

    
  }
};
useEffect(()=>{
    if(props.passwordUpdateAlertMessage==="success"){
        setpasswordupdated(true)
        setSnackSeverity("success")
        setSnackmsg("Password updated successfully")
        setOpenSnack(true);
        setloginscreen(true)
        
        
    }
},[props.passwordUpdateAlertMessage])

const handleSnackbar = () => {
setOpenSnack(false);
  };











 

return (
  <>
    <CustomSnackbar
      flag={openSnack}
      onClose={handleSnackbar}
      anchorOrigin={anchororigin}
      severity={snackSeverity}
      msg={snackMsg}
    />
    <div className="wrapperContainer">

      <div className="lg:flex">
        <div className="flex-1 leftSide">
          <div className="imgWrapper">
            <img src={welcome} className="image" alt="" />
          </div>
          <div className="LoginLeftContentWrapper py-16">
            Get Connected. Stay Informed. <br /> Collaborate.
          </div>
        </div>
        <div className="flex-1 rightSide">
          <div className="text_1">
            <div className="innerText">
              Welcome

              <div className="innerText2">
                <span style={{ color: "#6a768f" }}>to</span> Arc Connect
              </div>
            </div>
          </div>
          
          
          
          {!loginscreen?
          <div>
          <div className="innerText3 fnt20P" >
                Create New Password
                </div> 
                
                
                <div className="inputFieldHeightWidthAdjustment">
                <div className="flex flex-wrap">
                <div className="w-full lg:w-full">
                <div className="signInEmailAddress fnt24P" >
                    New  Password
                   </div>
                  <div>
                  <InputField
                      type={"password"}
                      className="pwdInputLogin fnt24P"
                      ref={inputRefs.current[0]}
                      handleInputChange={handleInputChange}
                      placeholder=""
                      validation="required|passwordpattern"
                      name="newpassword"
                      
                      isPassword={true}
                    />

                  </div>

                </div>

              </div>
              {/* </div>  */}
             
              {/* <div className="inputFieldHeightWidthAdjustment"> */}
              <div className="flex flex-wrap mt-4">
                <div className="w-full lg : w-full">
                  <div className="signInEmailPwd fnt24P " >
                    
                    Confirm Password 
                
      
            
                    </div>
            

                  <div>
                    <InputField
                      type={"password"}
                      className="pwdInputLogin fnt24P"
                      ref={inputRefs.current[1]}
                      handleInputChange={handleInputChange}
                      placeholder=""
                      validation="required|min:6"
                      name="confirmpassword"
                      
                      isPassword={true}
                    />

                  </div>

                </div>
              </div>
              
              <div>
              </div>


                {
                  <div className="nextButtonnnnOuter flex flex-wrap nextbutn">
                    <div
                      className="nextButtonnnn fnt32P  next w-full lg : w-full"
                      type="submit"
                      onClick={() =>
                          submit()
                      }
                    >
                      {/*   Next */}
                      {clickNextButton ? <Box sx={{
                        display: 'flex', width: "40px", height: "29px", marginTop: "-5px"
                      }}>  <LodingIcon /></Box> : "Submit"}
                    </div>
                    {/* </div> */}
                  </div>
                }
                
                <div className="account fnt32P">

                  {/* <span
                    style={{
                      color: "#F26930",
                      fontWeight: "500",
                      borderBottom: "2px solid #F26930",
                    }}
                  >
                    <Link
                      className={window.location.href.indexOf("/signup")}
                      to="/signup"
                    >
                      Sign Up
                    </Link>{" "}
                  </span> */}
                </div>
              </div>
              </div>
              :
              
                <div className="SignupSuccessPopup">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "116px",
                      marginTop: "32px",
                    }}
                  >
                    <img
                      src={
                        require("../../../../assets/img/SuccessIcon.svg").default
                      }
                    ></img>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      justifyContent: "center",
                      marginTop: "72px",
                      marginBottom: "40px",
                      marginRight: "20px",
                      marginLeft: "160px",
                      fontSize:'18px'
                    }}
                  >
                    Your Password has been updated successfully.
                    <Link key={{otpSent:false}}
                      className={window.location.href.indexOf("/")}
                      to="/"
                      style={{ cursor: "pointer", marginLeft: "25px" }}
                    >
                      <Button className="innersubmitForRegisterpleaselogin customButton" >
                          {/* Submit */}
                          Please Login
                        </Button>

                    </Link>{" "}
                  </div>
                </div>}
              

            
               
          


            


        
         
        </div>
      </div>
    </div>
    <div className="">
      <FooterSign />
    </div>
  </>
);
};
const mapStateToProps = (store) => {
return {
  msg: store.invitation.msg,
  otpSent: store.user.otpSent,
  otpToken: store.user.otpToken,
  user: store.user.user,
  passwordUpdateAlertMessage: store.user.passwordUpdateAlertMessage,
  isMatch: store.user.isMatch,
  loginOtp: store.user.loginOtp,
  loginErrorMsg: store.user.loginErrorMsg,
  otpexpireerror: store.user.otpexpireerror,
  resendotpcodelogin: store.user.resendotpcodelogin,
};
};
export default connect(mapStateToProps, {
logIn,
loginUser,
updateInvitationItem,
AlertMsg,
signIn,
confirmOTP,
updatepassword,
userLoginData,
userPendingData,
resetLoginErrorMsg,
resend_otp_login,
makeOtpResendLoginEmpty
})(Resetpassword);
