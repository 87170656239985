import {
  LOGIN_IN,
  CURRENT_USER_CHAT,
  CURRENT_USER_PROFILE,
  INVITATION_SUPER_ADMIN,
  INVITATION_ADMIN,
  INVITATION_CLINICAL_ADMIN,
  INVITATION_CLINIC,
  LOGIN_GET_OTP,
  FORGET_GET_OTP,
  OTP_MATCHES,
  FORGOT_OTP_MATCHES,
  PASSWORD_UPDATED,
  USER_CLINIC_DATA,
  SIDEBAR_ROLES,
  REPORT_SEARCH,
  REPORT_PROFILE_ID,
  NAV_REPORT_SEARCH_TO_PATIENT_PROFILE,
  ADHERANCE_SCORE_GRAPH_DATERANGE,
  ADHERANCE_SCORE_GRAPH_MAP,
  DELIVERED_PARAMETERS,
  ADHERANCE_SCORE_MAP,
  DASHBOARD_VAL,
  DASHBOARD_USER_DATA,
  PENDING_REQUESTS,
  HMR_GRAPH_DATE_RANGE,
  HMR_GRAPH,
  HMR_GRAPH_BOOLEAN,
  ADHERANCE_GRAPH_BOOLEAN,
  PASSWRD_CHECK,
  USER_NEW_EMAIL,
  USER_FORGOT_EMAIL,
  EMAIL_UPDATED,
  EMAIL_FORGOT,
  USER_NEW_PHONE,
  PASSWORD_UPDATE,
  PASSWORD_UPDATE_FAILED,
  NOTIFICATION_SUMMARY_PATIENTTABLE,
  ENROLL_CLINICIAN_PATIENT,
  ACTIVE_PATIENTS,
  SEARCH_CLINICS,
  SEARCH_CLINICIANS,
  SEARCHED_PATIENTS,
  FETCH_CLINIC_ID,
  ACTIVE_CLINICADMINS,
  ACTIVE_CLINICIANS,
  All_PATIENTS,
  PATIENT_PROFILE_THERAPY_GRAPH,
  LOGIN_ERROR_MSG,
  FORGET_ERROR_MSG,
  FORGET_ERROR_MAIL,
  IS_SESSION_EXPIRED,
  PATIENT_PROFILE_ADS_PROGRESSBAR,
  UPDATED_PATIENT_ID,
  RESET_ALL_STATE,
  OTP_EXPIRE_ERROR,
  FORGOTOTP_EXPIRE_ERROR,
  PASSWORD_UPDATE_ERROR,
  OTP_RESEND_LOGIN,
  OTP_RESEND_FORGOT,
  OTP_RESEND_FORGOTEMAIL,
  OTP_RESPONSE_EMAIL,
  OTP_RESPONSE_EMAIL_FORGOT,
  OTP_DOESNOT_MATCHES_PASSWORD_EMAIL,
  OTP_DOESNOT_MATCHES_FORGOT_PASSWORD_EMAIL,
  OTP_EXPIRE_PASSWORD_EMAIL,
  OTP_EXPIRE_FORGOTPASSWORD_EMAIL,
  OTP_RESPONSE_PHONE,
  OTP_DOESNOT_MATCHES_PASSWORD_PHONE,
  OTP_EXPIRE_PHONE,
  UPDATED_UNIT_VALUE,
  REPORT_SEARCH_PRTICULAR_USER,
} from "../action.list";
import apiAbmBlaze, {
  SIGN_IN,
  CHECKVALIDUSER,
  CHECKVALIDUSEREMAIL,
  UPDATE_PASSWORD,
  CONFIRM_OTP,
  FORGOT_PASSWORD_CONFIRM_OTP,
  USER_LOGIN_DATA,
  LOAD_PROFILE,
  PATIENT_REPORT_SEARCH,
  ROUTES_ADHERANCE_SCORE_DATERANGE,
  ROUTES_ADHERANCE_SCORE_GRAPH,
  ROUTES_ADHERANCE_SCORE_MAP,
  DASHBOARD,
  TOTAL_TRANSMITTED,
  WITH_DEVIATION,
  WITHOUT_DEVIATION,
  NEVER_TRANSMITTED,
  ADS_U75,
  ADS_U50,
  ADS_U25,
  USER_PENDING_REQ,
  ROUTES_HMR_GRAPH_DATERANGE,
  ROUTES_HMR_GRAPH,
  PASSWORD_VERIFICATION,
  NEW_EMAIL,
  CONFIRM_EMAIL_OTP,
  CONFIRM_FORGOTEMAIL_OTP,
  NEW_PHONE,
  CONFIRM_PHONE_OTP,
  CHANGE_PASSWORD,
  NOTIFICATION_SUMMARY_TABLE,
  ENROLLED_COUNT,
  ACTIVE_PATIENT_LIST,
  SEARCH_ON_CLINIC,
  SEARCH_ON_CLINICIAN,
  SEARCH_FOR_ABMADMINDASH,
  FETCH_CLINIC,
  ACTIVE_CLINICADMIN_LIST,
  ACTIVE_CLINICIAN_LIST,
  CARE_SITE_LIST,
  PATIENT_PROFILE_THERAPY_DETAILS_GRAPH,
  RESEND_OTP_LOGIN,
  RESEND_OTP_FORGOT,
  RESEND_OTP_FORGOTEMAIL,
  MEASURING_UNITS,
  REPORT_SEARCH_USER,
  UPDATE_THERAPY_GOALS,
  MISSED_THERAPY
} from "../../api";
import { chatInit } from "../quickbloxmessaging/quickblox.action";
import chatUsers from "../../constant/chatUsers";

export const logIn = (user) => async (dispatch) => {
  await dispatch({
    type: LOGIN_IN,
    payload: { user: user },
  });

  if (user !== null) {
    await dispatch(userLoginData(user.id));
  }
};

export const userChatConversion = (val) => async (dispatch) => {
  let userChat = chatUsers.chatItms.find((item) => item.userId === val);
  let userProfile = chatUsers.allChatUsers.find((item) => item.id === val);

  await dispatch({
    type: CURRENT_USER_CHAT,
    payload: { curUserChat: userChat },
  });
  await dispatch({
    type: CURRENT_USER_PROFILE,
    payload: { curUserProfile: userProfile },
  });
};

export const superadmininvitationlink = (val) => async (dispatch) => {
  await dispatch({
    type: INVITATION_SUPER_ADMIN,
    payload: {
      invitationAdmin: {
        email: val.email,
        role: val.role,
        clinicname: val.clinicList,
        status: val.status,
      },
    },
  });
};

export const superadinvitationlink = (val) => async (dispatch) => {
  await dispatch({
    type: INVITATION_SUPER_ADMIN,
    payload: {
      invitationAdmin: {
        email: val.email,
        role: val.role,
        clinicname: val.clinicname,
      },
    },
  });
};
export const invitationlink = (val) => async (dispatch) => {
  await dispatch({
    type: INVITATION_ADMIN,
    payload: {
      invitationAdmin: {
        email: val.email,
        role: val.role,
        clinicname: val.clinicList,
      },
    },
  });
};

export const clincalinvitationlink = (val) => async (dispatch) => {
  await dispatch({
    type: INVITATION_CLINICAL_ADMIN,
    payload: {
      invitationAdmin: {
        email: val.email,
        role: val.role,
        clinicname: val.clinicList,
        SClinicname: val.sclinicList,
        status: val.status,
      },
    },
  });
};

export const clinicinvitationlink = (val) => async (dispatch) => {
  await dispatch({
    type: INVITATION_CLINIC,
    payload: {
      invitationAdmin: {
        email: val.email,
        role: val.role,
        clinicname: val.clinicList,
        SClinicname: val.sclinicList,
        status: val.status,
      },
    },
  });
};

//Sign IN

export const signIn = (val) => async (dispatch) => {
  const { username_email, password } = val;
  apiAbmBlaze
    .post(SIGN_IN, {
      username_email,
      password,
    })
    .then(async (res) => {
      if (res.status === 200) {
        await dispatch({
          type: LOGIN_GET_OTP,
          payload: {
            otpSent: res.data.otpSent,
            otpToken: res.data.token,
            loginOtp: res.data.otp,
            phone: res.data.phone
          },
        });
        await dispatch({
          type: LOGIN_ERROR_MSG,
          payload: { loginErrorMsg: "" },
        });
      }
    })
    .catch(async (error) => {
      let err = error.toJSON();
      if (error.response) {
        if (error.response.status === 404) {
          await dispatch({
            type: LOGIN_ERROR_MSG,
            payload: { loginErrorMsg: "Incorrect email or password. Please check your login and try again." },
          });
        }

        if (
          error.response.status === 400 &&
          error.response.data &&
          error.response.data.message
        ) {
          await dispatch({
            type: LOGIN_ERROR_MSG,
            payload: { loginErrorMsg: error.response.data.message },
          });
        }
      }
    });
};

export const checkvaliduser = (val) => async (dispatch) => {
  const { phonenumber
  } = val;
  console.log('val here',val)
  apiAbmBlaze
    .post(CHECKVALIDUSER, {
      
      phonenumber

    })
    .then(async (res) => {
      console.log('responseéee',res);
      if (res.status === 200) {
        await dispatch({
          type: FORGET_GET_OTP,
          payload: {
            otpSent1: res.data.otpSent,
            otpToken1: res.data.token,
            forgetOtp: res.data.otp,
          },
        });
        await dispatch({
          type: FORGET_ERROR_MSG,
          payload: { forgetErrorMsg: "" },
        });
      }
    
        if (res.status === 404) {
          await dispatch({
            type: FORGET_ERROR_MSG,
            payload: { forgetErrorMsg: "Incorrect Phone number. Please check your Phone number and try again." },
          });
        }

        if (
          res.status === 400 &&
          res.data &&
          res.data.message
        ) {
          await dispatch({
            type: FORGET_ERROR_MSG,
            payload: { forgetErrorMsg: res.data.message },
          });
        }
      }
    ).catch(async (error)=>{
      if(error){
      await dispatch({
        type: FORGET_ERROR_MSG,
        payload: { forgetErrorMsg: error.response.data.message },
      });
    }
    })

  }
    

export const resetLoginErrorMsg = (val) => async (dispatch) => {
  await dispatch({
    type: LOGIN_ERROR_MSG,
    payload: {
      loginErrorMsg: "",
      otpexpireerror: null,
    },
  });
};

export const resetforgetErrorMsg = (val) => async (dispatch) => {
  await dispatch({
    type: FORGET_ERROR_MSG,
    payload: {
      forgetErrorMsg: "",
      forgetotpexpireerror: null,
    },
  });
};

export const resetforgetErroremail = (val) => async (dispatch) => {
  await dispatch({
    type: FORGET_ERROR_MAIL,
    payload: {
      forgetErrormail: "",
      otpexpireerrormail: null,
    },
  });
};

//Confirm OTP
export const confirmOTP = (val) => async (dispatch) => {
  const { code, token } = val;
  apiAbmBlaze
    .post(CONFIRM_OTP, {
      code,
      token,
    })
    .then(async (res) => {
      localStorage.setItem("isAssociatedClinic", res.data.isAssociatedClinic);
      localStorage.setItem("mu", res.data.userData.user.settings.mu);
      if (res.status === 200) {
        await dispatch({
          type: OTP_MATCHES,
          payload: {
            user: res.data.userData.user ? res.data.userData.user : null,
            isMatch: res.data.isMatch,
          },
        });
        await dispatch(userPendingData(res.data.userData.user));
        localStorage.setItem("authtoken", res.data.authtoken);
      }
    })
    .catch((error) => {
      dispatch({
        type: OTP_EXPIRE_ERROR,
        payload: {
          otpexpireerror: error.response.status,
        },
      });
    });
};
// forgot password confirm otp
export const forgotpasswordconfirmotp = (val) => async (dispatch) => {
  const { code, token } = val;
  console.log('valsss here bruh', code ,token)
  apiAbmBlaze
    .post(FORGOT_PASSWORD_CONFIRM_OTP, {
      code,
      token,
    })
    .then(async (res) => {
      console.log('otp confirmed bruh111',res)
      if (res.status === 200) {
         await dispatch({
          type: FORGOT_OTP_MATCHES,
          payload: {
            status: res.data.status ,
            message: res.data.message,
            forgotisMatch: res.data.forgotisMatch,
            profile_id: res.data.profile_id
          },
        });
        
        localStorage.setItem("authtoken", res.data.authtoken);
      }
    })
    .catch((error) => {
      dispatch({
        type: FORGOTOTP_EXPIRE_ERROR,
        payload: {
          forgetotpexpireerror: error.response.status,
        },
      });
    });
};

export const logOut = () => async (dispatch) => {
  await dispatch({
    type: OTP_MATCHES,
    payload: {
      user: null,
      isMatch: false,
      otpSent: false,
    },
  });
  await dispatch({
    type: RESET_ALL_STATE,
  });
};

export const updatepassword =
  ( id, password) => async (dispatch) => {
    console.log("from action", id,password)
    if (id) {

      apiAbmBlaze
        .post(UPDATE_PASSWORD, {
          profile_id: id,
          password: password
        })
        .then(async (res) => {
          // console.log("UserProfileUpdated+++", res.data)
          if (res.status == 200) {
            console.log("response.status", res.status)
            await dispatch({
              type: PASSWORD_UPDATED,
              payload: { passwordUpdateAlertMessage: "success" },
            });
          
          
        }
        }) 
        .catch((error) => {
          if (error) {
            console.log("errorfrom ++", error)
            dispatch({
              type: PASSWORD_UPDATE_ERROR,
              payload: { profileUpdateErrorMessage: "error COMING BRO" },
            });
          }
        });
    } else {
      console.log("Missing inputs");
    }
  };




//Getting logged in user data
export const userLoginData = (val) => (dispatch) => {
  dispatch({
    type: REPORT_PROFILE_ID,
    payload: {
      reportProfileId: val, //we are not using this id in backend
    },
  });
  if (val) {
    dispatch({
      type: USER_CLINIC_DATA,
      payload: {
        userLogInFlag: true,
      },
    });
  }
  apiAbmBlaze
    .get(`${USER_LOGIN_DATA}`)
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: USER_CLINIC_DATA,
          payload: {
            userLogIn: res.data,
            userLogInFlag: false,
          },
        });
      }
    })
    .catch(async (error) => {
      let err = error.toJSON();
      if (err.status === 401) {
        await dispatch({
          type: IS_SESSION_EXPIRED,
          payload: { isSessionExpired: true },
        });
      }
    });
};

export const pageHeading = (val) => async (dispatch) => {
  await dispatch({
    type: SIDEBAR_ROLES,
    payload: {
      sideBarRoles: val,
    },
  });
};

export const loadProfile = () => async (dispatch) => {
  apiAbmBlaze
    .get(LOAD_PROFILE)
    .then(async (res) => {
      if (res.data) {
        await dispatch(chatInit(res.data.profile));
      }
    })
    .catch(async (error) => {
      let err = error.toJSON();
      if (err.status === 401) {
        await dispatch({
          type: IS_SESSION_EXPIRED,
          payload: { isSessionExpired: true },
        });
      }
    });
};

export const makeReportSearchNull = () => async (dispatch) => {
  await dispatch({
    type: REPORT_SEARCH,
    payload: {
      reportSearchVal: null,
    },
  });
};

export const reportsSearch = (val, userId) => async (dispatch) => {
  apiAbmBlaze
    .post(PATIENT_REPORT_SEARCH, { queryval: val, profileid: userId })
    .then(async (res) => {
      if (res.data) {
        console.log('res.data',res.data)
        await dispatch({
          type: REPORT_SEARCH,
          payload: {
            reportSearchVal: res.data.response.rows,
          },
        });
      }
    })
    .catch((err) => {
      console.log("[err]", err);
    });
};

export const navReportSearchToPatientProfile = (val) => async (dispatch) => {
  await dispatch({
    type: NAV_REPORT_SEARCH_TO_PATIENT_PROFILE,
    payload: {
      navFromReportSearchToPatientProfile: val,
    },
  });
};

export const adheranceScoreMap = (userId) => async (dispatch) => {
  apiAbmBlaze
    .post(ROUTES_ADHERANCE_SCORE_MAP, { profileId: userId })
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: ADHERANCE_SCORE_MAP,
          payload: {
            adheranceScoreMap: res.data.response.rows,
          },
        });
      }
    })
    .catch((err) => {
      console.log("[err]", err);
    });
};

export const adheranceScoreGraphMap = (from, to, userId) => async (dispatch) => {
  if (from && to) {
    apiAbmBlaze
      .post(ROUTES_ADHERANCE_SCORE_DATERANGE, {
        from: from,
        to: to,
        profileId: userId,
      })
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: ADHERANCE_SCORE_GRAPH_MAP,
          payload: {
            adheranceScoreGraph: res.data.response.rows,
          },
        });
      }
    })
    .catch((err) => {
      console.log("[err]", err);
    });
}
}

export const adheranceScoreDateRange = (from, to, userId) => async (dispatch) => {
  if (from && to) {
    apiAbmBlaze
      .post(ROUTES_ADHERANCE_SCORE_DATERANGE, {
        from: from,
        to: to,
        profileId: userId,
      })
      .then(async (res) => {
        if (res.data) {
          await dispatch({
            type: ADHERANCE_SCORE_GRAPH_DATERANGE,
            payload: {
              adheranceScoreGraphDateRange: res.data.response.rows,
            },
          });
        }
      })
      .catch((err) => {
        console.log("[err]", err);
      });
  }
};

export const hmrGraph = (data, userId,firstdate,finaldate) => async (dispatch) => {
  apiAbmBlaze
    .post(ROUTES_HMR_GRAPH, {
      data: data ? data : null,
      profileId: userId ? userId : null,
      firstdate,
      finaldate
    })
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: HMR_GRAPH,
          payload: {
            hmrGraphval: res.data.data,
            hmrGraphvalfirstdate:res.data.date
          },
        });
      }
    })
    .catch((err) => {
      console.log("[err]", err);
    });
};

export const hmrGraphDateRange = (from, to, userId) => async (dispatch) => {
  if (from && to) {
    apiAbmBlaze
      .post(ROUTES_HMR_GRAPH_DATERANGE, { from: from, to: to, profileId: userId })
      .then(async (res) => {
        if (res.data) {
          await dispatch({
            /* type: HMR_GRAPH_DATE_RANGE,
            payload: {
              hmrGraphval: res.data,
            }, */
            type: HMR_GRAPH,
            payload: {
              hmrGraphval: res.data.data,
              hmrGraphvalfirstdate:res.data.date
            },
          });
        }
      })
      .catch((err) => {
        console.log("[err]", err);
      });
  }
};

export const deliveredParameters = (data, userId) => async (dispatch) => {
  apiAbmBlaze.post('/api/deliveredParameters/',{ data, profileId: userId })
  .then(async (res) => {
    if (res.data) {
      await dispatch({
        type: DELIVERED_PARAMETERS,
        payload: {
          deliveredParameters: res.data.response.rows,
        },
      });
    }
  })
  .catch((err) => {
    console.log("[err-deliveredParameters]", err);
  });
}

export const getCharts = (data, userId,pair_id,loggedInUser,clinic_id,from,to,fullName) => async (dispatch) => {
  return new Promise((success,fail)=>{
    let fileName = fullName?fullName+'_ARC Connect.pdf':'ARC Connect.pdf'
    console.log('GER CHARTS req',)
    apiAbmBlaze.post('/api/getCharts',{data,profileId:userId,pairId:pair_id,loggedUserId:loggedInUser,clinic_id,from,to},{ responseType: 'blob' })
    .then(async (res) => {
      return new Blob([res.data]);
    })
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      success('download succes')
      // fail('err')
    })
    .catch(e=>{
      console.log('Get CHARS err',e)
      fail('Download failed')
    })
  })
}

export const hmrGraphBoolean = (val) => async (dispatch) => {
  await dispatch({
    type: HMR_GRAPH_BOOLEAN,
    payload: {
      hmrgraphboolean: val,
    },
  });
};

export const adherenceGeaphBoolean = (val) => async (dispatch) => {
  await dispatch({
    type: ADHERANCE_GRAPH_BOOLEAN,
    payload: {
      adherancegraphboolean: val,
    },
  });
};

//FEtch Dashboard details
export const fetchDashboardDetails = (val) => async (dispatch) => {
  const { norange, clinicId, range1, range2 } = val;
  apiAbmBlaze
    .post(DASHBOARD, { norange, clinicId, range1, range2 })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: DASHBOARD_VAL,
          payload: {
            dashboard_val: res.data.val,
          },
        });
      }
    })
    .catch(async (error) => {
      let err = error.toJSON();
      console.log("Error----", error);
    });
};

//Total transmitted
export const userTotalTransmitted = (val) => async (dispatch) => {
  const { norange, clinicId, range1, range2, search, page, property, order } = val;
  /* var norange = val.norange ? val.norange : null;
  var clinicId = val.clinicId ? val.clinicId : null;
  var range1 = val.range1 ? val.range1 : null;
  var range2 = val.range2 ? val.range2 : null;
  var search = val.search ? val.search : null;
  var page = val.page ? val.page : null;
 */
  apiAbmBlaze
    .post(TOTAL_TRANSMITTED, {
      norange,
      clinicId,
      range1,
      range2,
      search,
      page,
      property,
      order
    })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: DASHBOARD_USER_DATA,
          payload: {
            dashboard_data: res.data.val,
            dashboard_totalCount:
              res.data.val && res.data.val.length
                ? res.data.val[0].total_count
                : 0,
          },
        });
      }
    })

    .catch((error) => {
      console.log("Error----", error);
    });
};
export const userPendingData = (val) => async (dispatch) => {
  if (val && (val.role === "clinicadmin" || val.role === "clinician")) {
    apiAbmBlaze
      .get(`${USER_PENDING_REQ}?id=${val.id}`) //this id we are not using in backend
      .then(async (res) => {
        if (res.data) {
          await dispatch({
            type: PENDING_REQUESTS,
            payload: { pendingInvitation: res.data[0] },
          });
        }
      })
      .catch((error) => {
        console.log("Error----", error);
      });
  }
};
//userWithDeviation
export const userWithDeviation = (val) => async (dispatch) => {
  const { norange, clinicId, range1, range2, search, page, property, order } = val;
  apiAbmBlaze
    .post(WITH_DEVIATION, { norange, clinicId, range1, range2, search, page, property, order })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: DASHBOARD_USER_DATA,
          payload: {
            dashboard_data: res.data.val,
            dashboard_totalCount:
              res.data.val && res.data.val.length
                ? res.data.val[0].total_count
                : 0,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};

export const passwrdVerify = (valPass, valId) => async (dispatch) => {
  await dispatch({
    type: PASSWRD_CHECK,
    payload: { passwrdResposne: "" },
  });
  await dispatch({
    type: USER_NEW_EMAIL,
          payload: {
            emailToken: null,
          },
  });
  if (valPass) {
    apiAbmBlaze
      .post(`${PASSWORD_VERIFICATION}`,{id:valId,password:valPass})
      .then(async (res) => {
        if (res.data) {
          await dispatch({
            type: PASSWRD_CHECK,
            payload: { passwrdResposne: res.data.message },
          });
        }
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: PASSWRD_CHECK,
            payload: { passwrdResposne: "Incorrect password" },
          });
        }
      });
  } else {
    dispatch({
      type: PASSWRD_CHECK,
      payload: { passwrdResposne: "Password required" },
    });
  }
};

export const userNewEmail = (valEmail, valId) => async (dispatch) => {
  if((!valEmail) || (!valId)){
    throw 'Email or User id missing'
  }
  dispatch({
    type: OTP_RESPONSE_EMAIL,
    payload: {
      OtpResponseEmail: false,
    },
  });
  apiAbmBlaze
    .post(`${NEW_EMAIL}?id=${valId}&&email=${valEmail}`)
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: OTP_RESPONSE_EMAIL,
          payload: {
            OtpResponseEmail: true,
          },
        });
        await dispatch({
          type: USER_NEW_EMAIL,
          payload: {
            newEmailSet: res.data.newEmail,
            emailToken: res.data.token,
            emailOtp: res.data.otp,
          },
        });
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const userNewPhone = (valPhone, valId) => async (dispatch) => {
  if((!valPhone) || (!valId)){
    throw 'Phone or User id missing'
  }
  dispatch({
    type: OTP_RESPONSE_PHONE,
    payload: {
      OtpResponsePhone: false,
    },
  });
  try{
    let res = await apiAbmBlaze.post(`${NEW_PHONE}?id=${valId}&&phone=${valPhone}`);
    dispatch({
      type: OTP_RESPONSE_PHONE,
      payload: {
        OtpResponsePhone: true,
      },
    });
    if (res.data) {
      await dispatch({
        type: USER_NEW_PHONE,
        payload: {
          newPhoneSet: res.data.newEmail,
          phoneToken: res.data.token,
          mobileOtp: res.data.otp,
        },
      });
    }
  }
  catch(err){
    throw err;
  }
};

export const confirmEmailOTP = (val) => async (dispatch) => {
  dispatch({
    type: OTP_DOESNOT_MATCHES_PASSWORD_EMAIL,
    payload: {
      OtpNotMatchPasswordEmail: false,
    },
  });
  dispatch({
    type: OTP_EXPIRE_PASSWORD_EMAIL,
    payload: {
      OtpEpirePasswordEmail: false,
    },
  });
  const { code, token, newEmail } = val;
  apiAbmBlaze
    .post(CONFIRM_EMAIL_OTP, { code, token, newEmail })
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: EMAIL_UPDATED,
          payload: {
            emailOtpMatch: res.data.otpMatched,
          },
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 400) {
        dispatch({
          type: OTP_DOESNOT_MATCHES_PASSWORD_EMAIL,
          payload: {
            OtpNotMatchPasswordEmail: error.response.status,
          },
        });
      }

      if (error.response.status === 404) {
        dispatch({
          type: OTP_EXPIRE_PASSWORD_EMAIL,
          payload: {
            OtpEpirePasswordEmail: error.response.status,
          },
        });
      }
      console.log("error", error);
    });
};


export const checkvaliduseremail = (val) => async (dispatch) => {
  const { Email
  } = val;
  console.log('val here',val)
  apiAbmBlaze
    .post(CHECKVALIDUSEREMAIL, {
      
      Email

    })
    .then(async (res) => {
      console.log('res',res)
      if (res.data) {
        dispatch({
          type: OTP_RESPONSE_EMAIL_FORGOT,
          payload: {
            OtpResponseEmailforgot: true,
          },
        });
        if (res.status==200) {
        await dispatch({
          type: USER_FORGOT_EMAIL,
          payload: {
            forgotEmailSet: res.data.forgotEmail,
            emailToken1: res.data.token,
            forgotemailOtp: res.data.otp,
          },
        });
        await dispatch({
          type: FORGET_ERROR_MAIL,
          payload: { forgetErrormail: "" },
        });
      }

      }
    })
    .catch(async (error) => {
      let err = error.toJSON();
      if (error.response) {
        console.log('responseerror',err);
        if (error.response.status === 404) {
          await dispatch({
            type: FORGET_ERROR_MAIL,
            payload: { forgetErrormail: "Incorrect email. Please check your email address and try again." },
          });
        }

        if (
          error.response.status === 400 &&
          error.response.data &&
          error.response.data.message
        ) {
          await dispatch({
            type: FORGET_ERROR_MAIL,
            payload: { forgetErrormail: error.response.data.message },
          });
        }
      }
    });
};

export const confirmforgotemailotp = (val) => async (dispatch) => {
  dispatch({
    type: OTP_DOESNOT_MATCHES_FORGOT_PASSWORD_EMAIL,
    payload: {
      OtpNotMatchforgotPasswordEmail: false,
    },
  });
  dispatch({
    type: OTP_EXPIRE_FORGOTPASSWORD_EMAIL,
    payload: {
      OtpEpireforgotPasswordEmail: false,
    },
  });
  const { code, token } = val;
  console.log('vals here bruh',code,token)
  apiAbmBlaze
    .post(CONFIRM_FORGOTEMAIL_OTP, { code, token})
    .then(async (res) => {
      
      if (res.data) {
        console.log('email otp response',res)
        if (res.status==200){
          console.log('OTP CONFIRMED BRUH',res)
          await dispatch({
          type: EMAIL_FORGOT,
          payload: {
            forgotemailOtpMatch: res.data.forgotemailOtpMatch,
            profileID: res.data.profile_id
          },
        });
      }
        
      }
    })
    .catch((error) => {
      console.log('theerror',error)
      if (error.response.status === 400) {
        console.log('theerror',error)
        dispatch({
          type: OTP_DOESNOT_MATCHES_FORGOT_PASSWORD_EMAIL,
          payload: {
            OtpNotMatchforgotPasswordEmail: error.response.status,
          },
        });
      }

      if (error.response.status === 404) {
        console.log('theerror',error)
        dispatch({
          type: OTP_EXPIRE_FORGOTPASSWORD_EMAIL,
          payload: {
            OtpEpireforgotPasswordEmail: error.response.status,
          },
        });
      }
      console.log("error", error);
    });
};

export const confirmPhoneOTP = (val) => async (dispatch) => {
  dispatch({
    type: OTP_DOESNOT_MATCHES_PASSWORD_PHONE,
    payload: {
      OtpNotMatchPhone: false,
    },
  });
  dispatch({
    type: OTP_EXPIRE_PHONE,
    payload: {
      OTpexpirePhone: false,
    },
  });
  const { code, token, newPhone } = val;
  apiAbmBlaze
    .post(CONFIRM_PHONE_OTP, { code, token, newPhone })
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: EMAIL_UPDATED,
          payload: {
            emailOtpMatch: res.data.otpMatched,
          },
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 400) {
        dispatch({
          type: OTP_DOESNOT_MATCHES_PASSWORD_PHONE,
          payload: {
            OtpNotMatchPhone: error.response.status,
          },
        });
      }

      if (error.response.status === 404) {
        dispatch({
          type: OTP_EXPIRE_PHONE,
          payload: {
            OTpexpirePhone: error.response.status,
          },
        });
      }
      console.log("error", error);
    });
};

export const otpFlag = (val) => async (dispatch) => {
  if (val == false) {
    dispatch({
      type: EMAIL_UPDATED,
      payload: {
        emailOtpMatch: false,
        passwrdResposne: "",
        emailToken: null,
        emailOtp: "",
        mobileOtp: "",
      },
    });
  }
};

export const changeUserPassword = (val) => async (dispatch) => {
  const { newConfirmPassword, id } = val;
  await dispatch({
    type: PASSWORD_UPDATE_FAILED,
    payload: {
      passwordNotUpdated: "",
    },
  });
  await dispatch({
    type: PASSWORD_UPDATE,
    payload: {
      passwordUpdate: "",
    },
  });
  apiAbmBlaze
    .post(CHANGE_PASSWORD, { newConfirmPassword, id })
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: PASSWORD_UPDATE,
          payload: {
            passwordUpdate: res.data.updatedData,
            emailOtpMatch: true,
          },
        });
      }
    })
    .catch(async (error) => {
      console.log("error", error);
      error=error.toJSON()
      if(error.status=== 400){
        await dispatch({
          type: PASSWORD_UPDATE_FAILED,
          payload: {
            passwordNotUpdated: "New password cannot be same as Old password!",
          },
        });
      }
    });
};

//without deviation
export const userWithoutDeviation = (val) => async (dispatch) => {
  const { norange, clinicId, range1, range2, search, page, property, order } = val;
  apiAbmBlaze
    .post(WITHOUT_DEVIATION, {
      norange,
      clinicId,
      range1,
      range2,
      search,
      page,
      property,
      order
    })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: DASHBOARD_USER_DATA,
          payload: {
            dashboard_data: res.data.val,
            dashboard_totalCount:
              res.data.val && res.data.val.length
                ? res.data.val[0].total_count
                : 0,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};
//never transmitted
export const userNeverTransmitted = (val) => async (dispatch) => {
  const { norange, clinicId, range1, range2, search, page, property, order } = val;
  apiAbmBlaze
    .post(NEVER_TRANSMITTED, {
      norange,
      clinicId,
      range1,
      range2,
      search,
      page,
      property,
      order
    })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: DASHBOARD_USER_DATA,
          payload: {
            dashboard_data: res.data.val,
            dashboard_totalCount:
              res.data.val && res.data.val.length
                ? res.data.val[0].total_count
                : 0,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};
//adherancescore under 75
export const userADSU75 = (val) => async (dispatch) => {
  const { norange, clinicId, range1, range2, search, page, property, order } = val;
  apiAbmBlaze
    .post(ADS_U75, { norange, clinicId, range1, range2, search, page, property, order })
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: DASHBOARD_USER_DATA,
          payload: {
            dashboard_data: res.data.val,
            dashboard_totalCount:
              res.data.val && res.data.val.length
                ? res.data.val[0].total_count
                : 0,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};
//adherancescore under 50
export const userADSU50 = (val) => async (dispatch) => {
  const { norange, clinicId, range1, range2, search, page, property, order } = val;
  apiAbmBlaze
    .post(ADS_U50, { norange, clinicId, range1, range2, search, page, property, order })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: DASHBOARD_USER_DATA,
          payload: {
            dashboard_data: res.data.val,
            dashboard_totalCount:
              res.data.val && res.data.val.length
                ? res.data.val[0].total_count
                : 0,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};
//adherancescore under 25
export const userADSU25 = (val) => async (dispatch) => {
  const { norange, clinicId, range1, range2, search, page, property, order } = val;
  apiAbmBlaze
    .post(ADS_U25, { norange, clinicId, range1, range2, search, page, property, order })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: DASHBOARD_USER_DATA,
          payload: {
            dashboard_data: res.data.val,
            dashboard_totalCount:
              res.data.val && res.data.val.length
                ? res.data.val[0].total_count
                : 0,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};
// update therapy goals
export const updateTherapyGoalsRedux = (val) => async (dispatch) => {
  const { norange, clinicId, range1, range2, search, page, property, order } = val;
  apiAbmBlaze
    .post(UPDATE_THERAPY_GOALS, { norange, clinicId, range1, range2, search, page, property, order })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: DASHBOARD_USER_DATA,
          payload: {
            dashboard_data: res.data.val,
            dashboard_totalCount:
              res.data.val && res.data.val.length
                ? Object.keys(res.data.val).length
                : 0,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};
//notification Sumarry PAtient table
export const NotificationSummaryTable = (val) => async (dispatch) => {
  const { clinicId, search, page } = val;
  apiAbmBlaze
    .post(NOTIFICATION_SUMMARY_TABLE, { clinicId, search, page })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: NOTIFICATION_SUMMARY_PATIENTTABLE,
          payload: {
            notification_summary_table: res.data.rows,
            sm_PatientTable_totalCount: res.data.rows.length
              ? res.data.rows[0].total_count
              : 0,
            dataCount: res.data.rowCount,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};

//missed therapy goals
export const userMissedtherapy = (val) => async (dispatch) => {
  const { norange, clinicId, range1, range2, search, page } = val;
  apiAbmBlaze
    .post(MISSED_THERAPY, { norange, clinicId, range1, range2, search, page })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: DASHBOARD_USER_DATA,
          payload: {
            dashboard_data: res.data.val,
            dashboard_totalCount:
              res.data.val && res.data.val.length
                ? res.data.val[0].total_count
                : 0,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
}

export const enrolledCount = (clinic_id, profile_id) => async (dispatch) => {
  if (clinic_id && profile_id) {
    apiAbmBlaze
      .post(ENROLLED_COUNT, { clinic_id, profile_id })
      .then(async (res) => {
        if (res.data) {
          dispatch({
            type: ENROLL_CLINICIAN_PATIENT,
            payload: {
              enrolledCounts: res.data.rows[0],
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error----", error);
      });
  }
};

export const activePatients = (val) => async (dispatch) => {
  const { clinic_id, page } = val;
  apiAbmBlaze
    .post(ACTIVE_PATIENT_LIST, { clinic_id, page })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: ACTIVE_PATIENTS,
          payload: {
            activePatientsList: res.data.rows,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};

export const activeClinicians = (val) => async (dispatch) => {
  const { clinic_id, page } = val;
  apiAbmBlaze
    .post(ACTIVE_CLINICIAN_LIST, { clinic_id, page })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: ACTIVE_CLINICIANS,
          payload: {
            activeClinicianList: res.data.rows,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};

export const activeClinicAdmins = (val) => async (dispatch) => {
  const { clinic_id, page } = val;
  apiAbmBlaze
    .post(ACTIVE_CLINICADMIN_LIST, { clinic_id, page })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: ACTIVE_CLINICADMINS,
          payload: {
            activeClinicadminList: res.data.rows,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};

export const searchClinician = (val) => async (dispatch) => {
  const { clinic_id, search_val, index, status, roletype, property, order } = val;
  let searchval = search_val ? search_val : "";
  if (clinic_id) {
    apiAbmBlaze
      .post(SEARCH_ON_CLINICIAN, {
        clinic_id,
        index,
        searchval,
        status,
        roletype,
        property,
        order
      })
      .then(async (res) => {
        if (res.data) {
          dispatch({
            type: SEARCH_CLINICIANS,
            payload: {
              searchedClinician: res.data.rows,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error----", error);
      });
  }
};

export const searchpatientforsuperadmin = (val) => async (dispatch) => {
  console.log('input val',val)
  const {  search_val, roletype } = val;
  let searchval = search_val ? search_val : "";
  let index = val.page;
  let property = val.property?val.property:'first_name';
  let order = val.order?val.order:'asc';
  if (roletype) {
    apiAbmBlaze
      .post(SEARCH_FOR_ABMADMINDASH, {
        // createdbefore,
        // createdafter,
        searchval,
        roletype,
        index,
        property,
        order
      })
      .then(async (res) => {
        console.log('resp in fe',res)
        if (res.data) {
          dispatch({
            type: SEARCHED_PATIENTS,
            payload: {
              searchedpatients: res.data.rows,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error----", error);
      });
  }
};

export const fetchClinicByID = (val) => async (dispatch) => {
  const { clinicId } = val;
  apiAbmBlaze
    .post(FETCH_CLINIC, { clinicId })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: FETCH_CLINIC_ID,
          payload: {
            fetchedClinicByID: res.data.rows,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};

export const searchClinic = (val, id) => async (dispatch) => {
  apiAbmBlaze
    .post(SEARCH_ON_CLINIC, { val, id })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: SEARCH_CLINICS,
          payload: {
            searchedClinic: res.data.Result.rows,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};

export const patient_Profile_Therapy_Graph =
  (val, date) => async (dispatch) => {
    const { profile_id, pair_id } = val;
    apiAbmBlaze
      .post(PATIENT_PROFILE_THERAPY_DETAILS_GRAPH, {
        profile_id,
        pair_id,
        date,
      })
      .then(async (res) => {
        if (res.data) {
          await dispatch({
            type: PATIENT_PROFILE_THERAPY_GRAPH,
            payload: {
              patientProfileTherapyGraph: res.data.val,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error----", error);
      });
  };
export const patient_Profile_Therapy_ProgressBar =
  (val) => async (dispatch) => {
    const { profile_id, pair_id, date } = val;
    apiAbmBlaze
      .post(PATIENT_PROFILE_THERAPY_DETAILS_GRAPH, {
        profile_id,
        pair_id,
        date,
      })
      .then(async (res) => {
        if (res.data) {
          await dispatch({
            type: PATIENT_PROFILE_ADS_PROGRESSBAR,
            payload: {
              patientProfile_ADS_ProgressBar: res.data.val,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error----", error);
      });
  };

export const set_updated_patient = (val) => async (dispatch) => {
  await dispatch({
    type: UPDATED_PATIENT_ID,
    payload: {
      updated_patient_id: val && val.patient_id ? val.patient_id : null,
    },
  });
};

export const resetAllState = (val) => async (dispatch) => {
  await dispatch({
    type: RESET_ALL_STATE,
  });
};

export const makeOtpResendLoginEmpty = (val) => async (dispatch) => {
  dispatch({
    type: OTP_RESEND_LOGIN,
    payload: {
      resendotpcodelogin: "",
    },
  });
};

export const resend_otp_login = (val) => async (dispatch) => {
  var username = val.username_email ? val.username_email : "";
  var password = val.password ? val.password : "";
  apiAbmBlaze
    .post(RESEND_OTP_LOGIN, { username, password })
    .then(async (res) => {
      if (res.data) {
        // console.log("resend_otp_login_response", res.data);
        await dispatch({
          type: OTP_RESEND_LOGIN,
          payload: {
            resendotpcodelogin: "resendOtplogin",
          },
        });

        await dispatch({
          type: LOGIN_GET_OTP,
          payload: {
            otpSent: res.data.otpSent,
            otpToken: res.data.token,
            loginOtp: res.data.otp,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};

export const makeOtpResendforgotEmpty = (val) => async (dispatch) => {
  dispatch({
    type: OTP_RESEND_FORGOT,
    payload: {
      resendotpcodeforgot: "",
    },
  });
};
export const makeOtpResendforgotemailEmpty = (val) => async (dispatch) => {
  dispatch({
    type: OTP_RESEND_FORGOTEMAIL,
    payload: {
      resendotpcodeforgotemail: "",
    },
  });
};


export const resend_otp_forgot = (val) => async (dispatch) => {
  var phonenumber = val.phonenumber
  console.log('phonenumber is fine bruh',phonenumber)
  apiAbmBlaze
    .post(RESEND_OTP_FORGOT, { phonenumber})
    .then(async (res) => {
      if (res.data) {
        console.log("resend_otp_FORGOT_response", res.data);
        await dispatch({
          type: OTP_RESEND_FORGOT,
          payload: {
            resendotpcodeforgot: "resendOtpforgot",
          },
        });

        await dispatch({
          type: FORGET_GET_OTP,
          payload: {
            otpSent1: res.data.otpSent,
            otpToken1: res.data.token,
            forgetOtp: res.data.otp,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};

export const resend_otp_forgotemail = (val) => async (dispatch) => {
  var email = val.Email
  console.log('phonenumber is fine bruh',email)
  apiAbmBlaze
    .post(RESEND_OTP_FORGOTEMAIL, {email})
    .then(async (res) => {
      if (res.data) {
        console.log("resend_otp_FORGOTemail_response", res.data);
        await dispatch({
          type: OTP_RESEND_FORGOTEMAIL,
          payload: {
            resendotpcodeforgotemail: "resendOtpforgotemail",
          },
        });

        await dispatch({
          type: USER_FORGOT_EMAIL,
          payload: {
            forgotEmailSet: res.data.forgotEmail,
            emailToken1: res.data.token,
            forgotemailOtp: res.data.otp,
          },
        });
      }
    })
    .catch((error) => {
      console.log("Error----", error);
    });
};


export const measurementUnit = (val) => async (dispatch) => {
  apiAbmBlaze
    .post(MEASURING_UNITS, {
      mu: val.unit,
      profileId: val.profileId
    })
    .then(async (res) => {
      if (res.data) {
        localStorage.setItem("mu", (res.data.updatedMeasuringUnitValue));
        await dispatch({
          type: UPDATED_UNIT_VALUE,
          payload: {
            emailOtpMatch: true,
          },
        });
      }
    })
    .catch((error) => {
      console.log('ERROR', error);
    });
}

//details of particular patient after report search
export const reportsSearchUser = (val) => async (dispatch) => {
  // console.clear();
  // console.log("reportsSearchUser:",val)
  apiAbmBlaze
    .post(REPORT_SEARCH_USER, { profileid: val.profile_id, clinic_id: val.clinic_id })
    .then(async (res) => {
      if (res.data) {
        await dispatch({
          type: REPORT_SEARCH_PRTICULAR_USER,
          payload: {
            navFromReportSearchToPatientProfile: res.data?.response?.rows[0] ? res.data.response.rows[0] : {},
          },
        });
      }
    })
    .catch((err) => {
      console.log("[err]", err);
    });
};


export const make_dashboard_data_empty = (val) => (dispatch) => {
  dispatch({
    type: DASHBOARD_USER_DATA,
    payload: {
      dashboard_data: [],
      dashboard_totalCount: null,
    },
  });
}
export const clear_patient_profile = (val) => (dispatch) => {
  dispatch({
    type: DASHBOARD_USER_DATA,
    payload: {
      navFromReportSearchToPatientProfile: {},
      patientProfileTherapyGraph: [],
      adheranceScoreGraph: {},
      adheranceScoreMap: [],
      hmrGraphval:[],
      hmrGraphvalfirstdate:''
    },
  });
}