import {
  NOTIFICATION_SETTINGS,
  GET_NOTIFICATION_SUMMARY,
  NOTIFICATION_CID,
  NOTIFICATION_SETTINGS_SELECTED_ID,
  RESET_ALL_STATE
} from "../action.list";

let defaultState = {
  notificationSettingSave : [],
  notificationSummary : {},
  notificationCid : 0,
  notificationCNm : '',
  notificationSettingsData : [],
  notificationSettingsSelectedId : {},
  noficationsuccess:''
};

export const notificationReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case NOTIFICATION_SETTINGS:
      return { ...state, ...payload }
    case GET_NOTIFICATION_SUMMARY:
      return { ...state, ...payload }
    case NOTIFICATION_CID:
      return { ...state, ...payload }
    case NOTIFICATION_SETTINGS_SELECTED_ID:
      return { ...state, ...payload }
    case RESET_ALL_STATE:
      return defaultState;
    default:
      return state;
  }
};
