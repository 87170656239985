import React from 'react';
import './styles.css';
import LoadingJugglingIcon from "../../assets/img/LoadingIcon.svg";

const LoadingIcon = () => {
    return (
        <div className='loadingIcon'>
            <img src = {LoadingJugglingIcon} />
        </div>
    )

}

export default LoadingIcon;