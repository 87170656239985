const superAdmin = "superadmin";
const abmAdmin = "abmadmin";
const clinicadmin = "clinicadmin";
const clinician = "clinician";
const patient = "patient";
const superAdminInvitingRole = {abmAdmin : abmAdmin, clinicAdmin : clinicadmin};
const abmAdminInvitingRole = {clinicAdmin : clinicadmin};
const clinicAdminInvitingRole = {clinicAdmin : clinicadmin, clinician : clinician, patient : patient};
const clinicianInvitingRole = {clinician : clinician, patient : patient};
export { superAdmin, abmAdmin, clinicadmin, clinician, patient, superAdminInvitingRole, abmAdminInvitingRole, clinicAdminInvitingRole, clinicianInvitingRole};
