import React, { useEffect } from "react";
import './InvitationCards.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from "@material-ui/core/styles";
import '../../assets/styles/font.css';


const useStyles = makeStyles((theme) => ({
    
    
    mycard: {
      backgroundColor: '#FFFFFF',      
      boxShadow: '#6A768F80 0px 3px 6px',
      borderRadius : '6px',
      marginRight : '45px',
      marginBottom : '32px',
    },
    cardcontent : {
        color : '#3D446F',
        fontSize : '16px',
        fontFamily : 'Roboto',
        fontWeight : 'bold',
        padding : '0px',
        marginTop : '16px',
        marginLeft : '16px',
    },
    cardactions : {
        display : 'flex',
    }
  }));


const InvitationCards = (props) => {
    const classes = useStyles();
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;   
      }, []);
    return (
        <div>
             <Card variant="outlined" className = {classes.mycard}>
                <CardContent className = {classes.cardcontent}>
                {props.cardContent}
                </CardContent>
                {props.textContent === 'New invitation' ?
                <>
                    <img
                        className="InvitationsCardsimageWrapper"
                        src={require('../../assets/img/newInvitation.svg').default} alt=""></img> 
                        <div className="InvitationsCardsenrolledRoleWrapper">
                    <div className="newinvitationtextWrapper fnt28P">{props.textContent}</div> 
                     {/* <div className="CaresitesettingsenrolledCountWrapper fnt40P">{props.rolecount}</div> */}
                </div>
                        </>
                        : null}
                          
                {props.textContent === 'Sent invitations' ?
                <>
                    <img
                        className="InvitationsCardsimageWrapper"
                        src={require('../../assets/img/sentInvitation.svg').default} alt=""></img>
                         <div className="InvitationsCardsenrolledRoleWrapper">
                    <div className="sentinvitationtextWrapper fnt28P">{props.textContent}
                    <span style={{color:'#F26930'}}>{` ${props.total_sent_invitation}`}</span></div> 
                     {/* <div className="CaresitesettingsenrolledCountWrapper fnt40P">{props.rolecount}</div> */}
                </div>
                        </>
                         : null}

                {props.textContent === 'Received invitations' ?
                <div>
                    <img
                        className="InvitationsCardsimageWrapper"
                        src={require('../../assets/img/receivedInvitation.svg').default} alt=""></img>
                         <div className="InvitationsCardsenrolledRoleWrapper">
                    <div className="receivedinvitationtextWrapper fnt28P">{props.textContent}
                    <span style={{color:'#F26930'}}>{` ${props.total_received_invitation}`}</span></div> 
                     {/* <div className="CaresitesettingsenrolledCountWrapper fnt40P">{props.rolecount}</div> */}
                </div>
                        </div>
                         : null}
                {props.textContent === 'New invitation' ? 
                     <div className= "InvitationCardsviewButtonWrapper fnt24P new-inivtaion-button-hover" 
                     style = {{backgroundColor : "#F3FCFE"}} 
                     onClick={() => {props.viewTable("newInviteModal")}
                     }
                 >{props.view}</div>
                : props.textContent === 'Received invitations' ?
                    <div className= "InvitationCardsviewButtonWrapper fnt24P received-inivtaion-button-hover"
                        onClick={() => {props.viewTable("receivedInvitation")}
                        }
                    >{props.view}</div>
                : props.textContent === 'Sent invitations' ?
                    <div className= "InvitationCardsviewButtonWrapper fnt24P sent-inivtaion-button-hover"
                        style = {{backgroundColor : "#FEF4F0"}}
                        onClick={() => {props.viewTable("sentInvitation")}
                        }
                    >{props.view}</div> : null
                } 
            </Card> 
        </div>
    );
};

export default InvitationCards;

