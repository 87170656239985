import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userChatConversion } from "../../../redux/user/user.action";
import {
  getAcceptSharing,
  showChatWindow,
} from "../../../redux/sharing/sharing.action";
import {
  chatDialogList,
  chatDialogCreate,
  selectedUserChat,
  currentDiaglogID,
  selectedGroupChat,
} from "../../../redux/quickbloxmessaging/quickblox.action";
import { Avatar } from "@material-ui/core";

import LoadingIcon from "../../../components/LoadingIcon";

const DirectChatList = (props) => {
  
  useEffect(() => {
    props.getAcceptSharing();
    // props.selectedGroupChat(null);
  }, []);

  const selectChat = (e) => {
    for (
      let index = 0;
      index < e.currentTarget.parentNode.children.length;
      index++
    ) {
      e.currentTarget.parentNode.children[index].classList.remove("active");
    }
    e.currentTarget.classList.add("active");
  };
  const getChat = (id) => {
    props.userChatConversion(id);
  };
 

  return (
    <>
      {props.acceptedSharingFlag ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <LoadingIcon />
        </div>
      ) : (
        <div>
          {props.acceptedSharingUsers &&
            props.acceptedSharingUsers.map((item) => {
              return (
                <>
                  <div
                    className="chatlist__item_for_direct"
                    // style={{ animationDelay: `0.${props.animationDelay}s` }}
                    onClick={(e) => {
                      selectChat(e);
                      getChat(props.id);
                      props.chatDialogCreate(item.quickblox_id);
                      props.selectedUserChat(item);
                      props.showChatWindow(true);
                    }}
                  >
                    <div className="MultiUserContainer">
                      
                        <div className="singleUserInNewChat">
                           {!item.photo_uuid ? (
                            <Avatar
                              // className="fa fa-user"
                              aria-hidden="true"
                              style={{}}
                            ></Avatar>
                          ) : ( 
                            <Avatar
                              style={{boxboxShadow : '#6A768F80 0px 3px 6px !important'}}
                              src={
                                item.photo_uuid ? (
                                  `https://ucarecdn.com/${item.photo_uuid}/`
                                ) : (
                                  <Avatar
                                    // className="fa fa-user userforchatgroup"
                                    aria-hidden="true"
                                  ></Avatar>
                                )
                              }
                            />
                           )} 
                        </div>
                      
                    </div>
                    <div className="userMetafordirect">
                      <div className="user">
                
                        {item.first_name || item.last_name
                          ? `${item.first_name ? item.first_name : ""} ${
                              item.last_name ? item.last_name : ""
                            }`
                          : item.email}
              
                      </div>
                      
                    </div>
                  
                  </div>
                </>
              );
            })}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    chatUsers: store.user.chatUsers,
    acceptedSharingUsers: store.sharing.acceptedSharing,
    acceptedSharingFlag: store.sharing.acceptedSharingFlag,
  };
};
export default connect(mapStateToProps, {
  userChatConversion,
  getAcceptSharing,
  chatDialogList,
  chatDialogCreate,
  selectedUserChat,
  showChatWindow,
  currentDiaglogID,
})(DirectChatList);
