import React, { useState, useEffect } from "react";
import "./styles.css";
import DropDownImage from "../../assets/img/dropDownSearchBar.svg";
import "../../../src/assets/styles/font.css";

function Select({
  options,
  callSelect,
  defClinic,
  notificationSettingsSelectedId,
}) {
  const [showDropDown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const onClickHandler = (option) => {
    callSelect(option);
    setShowDropdown(false);
    setSelectedOption(option);
    let clinic_details = {
      clinicId: option.c_id,
      clinicName: option.clinicname
    }
    localStorage.setItem("currClinic", JSON.stringify(clinic_details))
  };


  useEffect(() => {
    if (notificationSettingsSelectedId && notificationSettingsSelectedId.clinicId) {
      setSelectedOption({ c_id: notificationSettingsSelectedId.clinicId, clinicname: notificationSettingsSelectedId.clinicName });
      callSelect({ c_id: notificationSettingsSelectedId.clinicId, clinicname: notificationSettingsSelectedId.clinicName });
    } else {
      try {
        if (options && options.length) {

          let local_currClinic = localStorage.getItem("currClinic");
          let _local_currClinic = JSON.parse(local_currClinic ? local_currClinic : '{}');
          let obj = options.findIndex(o => o.c_id === _local_currClinic.clinicId);
          if (_local_currClinic.clinicId && _local_currClinic.clinicName && obj !== -1) {
            setSelectedOption({ c_id: _local_currClinic.clinicId, clinicname: _local_currClinic.clinicName });
            callSelect({ c_id: _local_currClinic.clinicId, clinicname: _local_currClinic.clinicName });
          }
          else {

            let clinic_details = {
              clinicId: options[0].c_id,
              clinicName: options[0].clinicname
            }
            setSelectedOption(options[0]);
            callSelect(options[0]);
            localStorage.setItem("currClinic", JSON.stringify(clinic_details))
          }
        }
      } catch (err) {
        console.log("ClinicList Select Catch:", err)
      }
    }
  }, [JSON.stringify(notificationSettingsSelectedId), options]);

  return (
    <div className="select fnt24P">
      <button
        className="select__label"
        onClick={() => setShowDropdown(!showDropDown)}
      >
        {(selectedOption && selectedOption.clinicname) || defClinic}
      </button>
      {showDropDown && (
        <ul className="select__options"> 
          {options && options.length ? options.map((option, index) => (           
            <li
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              key={index}
            >
              <img src={DropDownImage} alt=" " className="listImage" />

              <button onClick={() => onClickHandler(option)} className="listb"> 
              {option.clinicname}
              </button>
            </li> 
          )):<li>No clinic found</li>} 
        </ul>
      )}
    </div>
  );
}

export default Select;
