



import React, { useState, useEffect } from "react";



import { Link} from "react-router-dom";
import { connect } from "react-redux";
import {
 
  
  checkvaliduser,
  checkvaliduseremail,
  
  confirmforgotemailotp,

  
  

  
  resetforgetErroremail,

  
  resend_otp_forgotemail,
  makeOtpResendforgotemailEmpty
} from "../../../../redux/user/user.action";

import {
 
  AlertMsg,
} from "../../../../redux/invitation/components/invitation.action";

import { makeStyles } from "@material-ui/core/styles";
import InputField from "../../../../components/Inputs_Validation/index";
import ".././styles.css";
import welcome from "../../../../assets/img/welcome.png";
import back from "../../../../assets/img/backbutton.png";

import OtpInput from "react-otp-input";
import FooterSign from "../../../../components/Footers/FooterSign";

import '../../../../assets/styles/font.css';
import CustomSnackbar from "../../../../components/Alertmessage/AlertMsg";
import LodingIcon from "../../../../components/LoadingIcon";
import Box from '@mui/material/Box';

import ResetPassword from "../ResetPassword";





const Restorepasswordwithemail = (props) => {
  
 
const [otp, setopt] = useState("");
const [values, setValues] = React.useState({});

const [openSnack, setOpenSnack] = React.useState(false);
const [snackSeverity, setSnackSeverity] = React.useState('error');
const [snackMsg, setSnackmsg] = React.useState('');
const [anchororigin, setanchorOrigin] = useState({
  vertical: 'top',
  horizontal: 'center',
});

const inputRefs = React.useRef([React.createRef(), React.createRef()]);
const [counter, setCounter] = React.useState(300);
const [resendOtpConditaion, setresendotpCondition] = React.useState("resendotp");
const [clickNextButton, SetClickNextButton] = React.useState(false);
const [valid,setvalid ] =React.useState(false)
const otpInputRef = React.useRef();
const handleInputChange = (name, value) => {
  if (value.length > 0) {
    setValues({
      ...values,
      [name]: value,
    });
  }
};


console.log('OtpResponseEmailforgot',props.OtpResponseEmailforgot)
const submit = async () => {
  SetClickNextButton(true);
  let isValid = true;
  for (let i = 0; i < inputRefs.current.length; i++) {
    if (inputRefs.current[i].current === null) {
      continue;
    }
    const valid = inputRefs.current[i].current.validate();
    if (!valid) {
      isValid = false;
    }
  }
  if (isValid === true) {
    values.Email=values.Email.trim().toLowerCase()
    setvalid(true)
    console.log('values',values)
    props.checkvaliduseremail(values);
    /*   SetClickNextButton(false); */
  }
};


useEffect(() => {
  if (resendOtpConditaion === "resendotp") {
    setresendotpCondition("mailmsg");
  }

  if (props.OtpResponseEmailforgot === true) {
    SetClickNextButton(false);
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    setresendotpCondition("otpexpire")
  }
  if (counter === 0) {
    setresendotpCondition("resendotp");
  }
  return () => props.makeOtpResendforgotemailEmpty();

}, [counter, props.OtpResponseEmailforgot]);


const resendCode = () => {
  props.makeOtpResendforgotemailEmpty();
  setresendotpCondition("mailmsg");
  props.resend_otp_forgotemail(values);
  setopt('');
  otpInputRef.current.focusInput(0);
}
function timer1(counter) {
  let minutes= Math.floor(counter / 60);
  let sec =  counter % 60;
  let seconds = sec
if (Math.floor((sec / 10))==0){seconds = '0'+sec }
  
  return `${minutes}:${seconds}` 
   
}

useEffect(() => {
  if (props.resendotpcodeforgotemail === "resendOtpforgotemail" && props.OtpResponseEmailforgot === true) {
    setCounter(300);
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    setresendotpCondition("otpexpire")
  }
}, [props.resendotpcodeforgotemail, props.OtpResponseEmailforgot])



const submitOTP = () => {
  SetClickNextButton(true);
  if (
    otp.length === 4 &&
    props.emailToken1 /* && otp === props.loginOtp */
  ) {
    let code = otp;
    console.log('otptoken',props.emailToken1,code)
    props.confirmforgotemailotp({ code, token: props.emailToken1 });
    setOpenSnack(false);
  } else {
    SetClickNextButton(false);
    setSnackmsg("Incorrect code! Please try again")
    setOpenSnack(true);
  }
};


useEffect(() => {
  if (props.forgotemailOtpMatch ) {
    SetClickNextButton(false);
    
    
  }
}, [props.forgotemailOtpMatch])

  
  
 
  



const handleChange = (otp) => {
  setopt(otp);
};

const handleSnackbar = () => {
  setOpenSnack(false);
  props.resetforgetErroremail();
};


useEffect(() => {
  if (props.forgetErrormail) {
    
    setOpenSnack(true);
    setSnackmsg(props.forgetErrormail);
    SetClickNextButton(false);
  } else if (props.OtpEpireforgotPasswordEmail === 404) {
    setOpenSnack(true);
    setSnackmsg("Verification code is expired! Please try again with new one");
    SetClickNextButton(false);
  }
  else if (props.OtpNotMatchforgotPasswordEmail === 400) {
    setOpenSnack(true);
    setSnackmsg("Incorrect Code. Please check your code and try again.")
  } else {
    setOpenSnack(false);
    props.resetforgetErroremail();
  }

}, [props.forgetErrormail, props.OtpEpireforgotPasswordEmail,props.OtpNotMatchforgotPasswordEmail])



return (props.forgotemailOtpMatch? <ResetPassword profile_id={props.profile_id} />:
  <>
    <CustomSnackbar
      flag={openSnack}
      onClose={handleSnackbar}
      anchorOrigin={anchororigin}
      severity={snackSeverity}
      msg={snackMsg}
    />
    <div className="wrapperContainer">

      <div className="lg:flex">
        <div className="flex-1 leftSide">
          <div className="imgWrapper">
            <img src={welcome} className="image" alt="" />
          </div>
          <div className="LoginLeftContentWrapper py-16">
            Get Connected. Stay Informed. <br /> Collaborate.
          </div>
        </div>
        <div className="flex-1 rightSide">
        <Link to='/forgotpassword'>
        <div className="backbutton">
            <img   src={back} alt="my image" />
            </div></Link>
          <div className="text_1">
         
            <div className="innerText">
              Welcome

              <div className="innerText2">
                <span style={{ color: "#6a768f" }}>to</span> Arc Connect
              </div>
            </div>
          </div>
          

            
            
            <div className="inputFieldHeightWidthAdjustment">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-full">
                <div className="signInEmailAddress fnt24P" >
                   Enter registered Email address
 
                
                </div>
                <div>
                  <InputField
                    type="Email"
                    className="emailInputLogin fnt24P"
                    placeholder="Email address"
                    disabled={props.OtpResponseEmailforgot}
                    ref={inputRefs.current[0]}
                    handleInputChange={handleInputChange}
                    validation="required"
                    name="Email"
                    autofocus={true}
                  />
                  

                </div>

              </div>

            </div>
            </div>
            
            {props.OtpResponseEmailforgot === true ? (
              <div>

                <div className="otpMsg fnt21P">
                We have sent an email to the address we have on file.<br></br>
                <br></br>
                Please check your email and enter the verification code:
                </div>
                
                <div className="InputOpt">
                  <OtpInput
                    ref={otpInputRef}
                    value={otp}
                    type="password"
                    onChange={handleChange}
                    numInputs={4}
                    inputStyle={{
                      width: "80px",
                      fontSize: "18px",
                      padding: "21px",
                      marginLeft: "7px",
                      borderRadius: "13px",
                      boxShadow: "#6A768F80 0px 3px 6px",
                      border: "none",
                    }}
                    shouldAutoFocus={true}
                    separator={<span></span>}
                  />
                </div>
                <div className="otp-time">
                  {
                    resendOtpConditaion === "resendotp" ? <p id="resnedotp">Your earlier Verification code has been expired ! <span>
                      <a style={{ color: "#f26930", cursor: "pointer" }} onClick={() => resendCode()}>Resend Verification Code</a></span></p> : null
                  }
                  {
                    resendOtpConditaion === "otpexpire" ? <p id="otpexpire">Your Verification Code Expire in : <span>{timer1(counter)} sec</span> </p> : null
                  }

                  {resendOtpConditaion === "mailmsg" ? <p style={{ color: "green" }}>New Verification Code has been sent to your registered email!</p> : null}
                </div>
                <div className="nextButtonnnnOuter"
                  type="button"
                  onClick={() =>
                    props.OtpResponseEmailforgot === true ? submitOTP() : submit()
                  }
                  style={{cursor:'pointer'}}
                >
                  <div
                    className="nextButtonnnn fnt32P"
                  >
                    <div
                      className="next" 
                    >
                      {clickNextButton && valid ? <Box sx={{
                        display: 'flex', width: "40px", height: "29px", marginTop: "-5px"
                      }}>  <LodingIcon /></Box> : "Submit"}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {
                  <div className="nextButtonnnnOuter flex flex-wrap nextbutn">
                    <div
                      className="nextButtonnnn fnt32P  next w-full lg : w-full"
                      type="button"
                     
                      onClick={() =>
                        props.OtpResponseEmailforgot === true ? submitOTP() : submit()
                      }
                    >
                      {/*   Next */}
                      {clickNextButton  &&  valid ? <Box sx={{
                        display: 'flex', width: "40px", height: "29px", marginTop: "-5px"
                      }}>  <LodingIcon /></Box> : "Next"}
                    </div>
                    
                   <a
                 
                 href="/"
                style={{ cursor: "pointer", textAlign:"center",paddingTop:"20px",fontWeight:"bold" , color:"#F26930",textDecorationColor:"#F26930", textDecoration:"underline" }}
                 >
                
                 Login
                
                </a>

      </div>
                 
                   

                  
                }
                
               
              </div>
            )}
             
         
         
        </div>
      </div>
    </div>
    <div className="">
      <FooterSign />
    </div>
  </>
);
};
const mapStateToProps = (store) => {
return {
  msg: store.invitation.msg,
  otpSent: store.user.otpSent1,
  otpToken: store.user.otpToken1,
  user: store.user.user,
  isMatch: store.user.isMatch,
  forgotemailOtpMatch:store.user.forgotemailOtpMatch,
  forgotemailOtp:store.user.forgotemailOtp,
  forgotEmailSet:store.user.forgotEmailSet,
  emailToken1:store.user.emailToken1,
  OtpEpireforgotPasswordEmail:store.user.OtpEpireforgotPasswordEmail,
  OtpNotMatchforgotPasswordEmail:store.user.OtpNotMatchforgotPasswordEmail,
  OtpResponseEmailforgot:store.user.OtpResponseEmailforgot,
  profile_id:store.user.profileID,
  forgetOtp: store.user.forgetOtp,
  forgetErrormail: store.user.forgetErrormail,
  otpexpireerror: store.user.otpexpireerror,
  resendotpcodeforgotemail: store.user.resendotpcodeforgotemail,
};
};
export default connect(mapStateToProps, {


AlertMsg,

checkvaliduser,
checkvaliduseremail,

confirmforgotemailotp,


resetforgetErroremail,
resend_otp_forgotemail,
makeOtpResendforgotemailEmpty
})(Restorepasswordwithemail);
