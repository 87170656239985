import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./style.css";
import PageNotFoundImg from "../../../assets/img/404_one.png";
const NotFound = () => {
  /*   const history = useHistory();
    useEffect(() => {
      if (localStorage.getItem("isAssociatedClinic") == 0 && window.location.href === "http://localhost:3000/app") {
        history.push("/app/clinicadmin");
      }
    }, []); */
  return (
    <>
      <div className="not-found-container">
        <div>
          <div className="not-found-image">
            <img src={PageNotFoundImg} />
          </div>
          <h1 className="oops-page-not-found">OPPS!</h1>
          <br></br>
          <h4 className="cant-fount-text">Page not found</h4>
        </div>
      </div>
    </>
  );
};

export default NotFound;
