import React, { useEffect } from "react";
import "./Report.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { pageHeading } from "../../redux/user/user.action";
import { reportsSearch } from "../../redux/user/user.action";
import SearchIcon from "../../assets/icons/searchIcon.png";
import Avatar from "@mui/material/Avatar";
import { loadDevice } from "../../redux/therapyGoals/therapyGoals.action";
import UploadCSV from "./UploadCSV";
import ReportDataTable from "./ReportDataTabel";
const Reports = (props) => {
  const dispatch = useDispatch();
  const [openuploadcsv, setopenuploadcsv] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState();

  /*   const navPatientProfile = (item, index) => {
    dispatch(navReportSearchToPatientProfile(item));
    props.pageHeading("Patient Profile");
    dispatch(makeReportSearchNull());
    dispatch(getPatientStatus(item.profile_id));
    dispatch(getPatientIdRedFunc(item.profile_id));
    history.push("/app/PatientProfile");
  }; */

  const userId = useSelector((store) => {
    return store.user.reportProfileId;
  });

  useEffect(() => {
    props.pageHeading("Patients");
  }, []);

  function onSubmit(val) {
    dispatch(reportsSearch(val, userId));
  }
  // const handelOnKeyDown = (e) => {
  //   if (e.key === "Enter")
  //     onSubmit(e.target.value);
  // }
  useEffect(() => {
    document.documentElement.scrollTop = 0;

    document.scrollingElement.scrollTop = 0;
  }, []);

  const getPatientId = () => {
    if (
      props.reportsearch.patient_id &&
      props.reportsearch.patient_id.patient_id &&
      props.reportsearch.patient_id.patient_id.length
    ) {
      return props.reportsearch.patient_id.patient_id.length > 1
        ? props.reportsearch.patient_id.patient_id.join(",")
        : props.reportsearch.patient_id.patient_id[0];
    } else {
      return "Patient Id";
    }
  };
  function openuploadcsvpopup() {
    setopenuploadcsv(true);
  }
  function closeUploadCsvPopup() {
    setopenuploadcsv(false);
  }

  return (
    <div className="report">
      <UploadCSV isopen={openuploadcsv} closePopup={closeUploadCsvPopup} />
      <div className="flex flex-wrap">
        <div
          className="w-full lg:w-full"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="first-div">
            <div className="search-by" style={{ marginLeft: "13px" }}>
              Search by
            </div>
          </div>
          <div className="Upload-csv">
            <button
              onClick={() => {
                openuploadcsvpopup();
              }}
            >
              Decrypt log file
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full lg:w-full">
          <div className="second-div">
            <div className="criteria-div">
              Enter one or more criteria Patient ID, Patient Name, Care Site,
              City, State, Zip,Device Serial Number.
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-full ReportSearchBar">
          <div className="add-icon">
            <img src={SearchIcon} alt="" className="srchImg" />
            <input
              type="text"
              placeholder=""
              className="report_search"
              onChange={(e) => {
                setSearchValue(e.target.value);
                onSubmit(e.target.value);
              }}
            // onKeyDown={handelOnKeyDown}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "white",
          marginLeft: "40px",
          marginRight: "64px",
          borderRadius: "8px",
          boxShadow: "#6A768F80 0px 3px 6px",
          marginTop: "8px",
          overflowX: "auto",
          overflowY: "auto",
          margin: "8px 66px 45px 53px",
          marginBottom: "64px",
        }}
      >
        {searchValue &&
          searchValue.length &&
          props.reportsearch &&
          props.reportsearch.length > 0 ? (
          <ReportDataTable reportdata={props.reportsearch} />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    reportsearch: store.user.reportSearchVal,
  };
};

export default connect(mapStateToProps, {
  pageHeading,
  loadDevice,
})(Reports);
