import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
//FOR Invitation dialog
import {
  InviteModalPopup
} from "../ModalPopup";
// import { UserViewPopup } from "../ModalPopup";
//FOR FILTER FUNCTIONALITY
import Menu from "@material-ui/core/Menu";
// components
import { invitationlink,navReportSearchToPatientProfile } from "../../redux/user/user.action";
import { connect, useSelector,useDispatch } from "react-redux";
import Pagination from "../Pagination";
import InvitationCards from './InvitationCards';
import { useHistory } from "react-router-dom";
import {
  fetchInvitation,
  addInvitationItem,
  updateInvitationItem,
  fetchclinic,
  addingClinicName,
  updateExistingCLinicList,
  reSet,
  acceptinLinkClinicName,
  openInvitePopup,
  resendInvitationLink,
  openFilterPopup,
  // msg,
  searchInvitation,
  revokeUser,
  rejectInvitation,
  acceptInvitation,
  invitationPagination,
  getSentReceivedInvitationCount,
  invitationAction,
  loadOtherUserProfileForViewing,

} from "../../redux/invitation/components/invitation.action";
import apiAbmBlaze,{CHECK_IF_DEVICE_IS_CONNECTED} from '../../api'
import { userPendingData,clear_patient_profile } from "../../redux/user/user.action";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
//SORTING
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Alert from "@material-ui/lab/Alert";
//Revoke
import AbmAdminRevokePopUp from "../ModalPopup/AbmAdminRevokPopUp";
import AbmAdminActivatePopUp from "../ModalPopup/AbmAdminActivatePopUp";
//Chips
import ResendSuccess from "./ResendSuccess";
import './ABMadminTable.css';
import InvitationSuccess from "./ResendSuccess";
import SeparatorTopSection from "../../components/SeparatorTopSection";
import './InvitationTable.css';
import '../../assets/styles/font.css';
const SAInvitationTable = ({
  color,
  fetchInvitation,
  updateInvitationItem,
  clinicListName,
  addingClinicName,
  flagInput,
  addUserInvitation,
  updateExistingCLinicList,
  reSet,
  acceptinLinkClinicName,
  openPopUp,
  openInvitePopup,
  resendInvitationLink,
  msg,
  userRole,
  searchInvitation,
  resendInviteMsg,
  revokeUser,
  rejectInvitation,
  acceptInvitation,
  invitationActionFlag,
  invitationPagination,
  paginatedInvitationList,
  userPendingData,
  getSentReceivedInvitationCount,
  total_sent_invitation,
  total_received_invitation,
  invitationAction,
  invitationFlagValue,
  if_dont_have_any_clinic,
  loadOtherUserProfileForViewing
}) => {
  const [isopen, setOpen] = React.useState(false);
  //SORT
  const [orderDirection, setOrderDirection] = React.useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = React.useState("email");

  //Revoke pop up
  const [isPopUpOpen, setIsPopUpOpen] = React.useState(false);
  const [isRevokeStatus, setIsRevokeStatus] = React.useState();
  const [revokeVals, setRevokeVals] = React.useState({});
  //reject popup
  const [rejectVals, setRejectVals] = React.useState({});
  // Revoke Activated Popup
  const [isActivateOpen, setisActivateOpen] = React.useState(false);
  const [activeVals, setActiveVals] = React.useState({});

  //Alert message
  const [succsess, setSuccsess] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSuccessPopup, setOpenSuccessPopup] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [openInvitationSuccess, setOpenInvitationSuccess] = React.useState(false);
  const [successValue, setSuccessValue] = React.useState('');
  const [isTableView, setIsTableView] = React.useState("sentInvitation")
  const [openSelectPending, setOpenSelectPending] = React.useState(false);
  const [anchorPending, setAnchorPending] = React.useState(null);
  const [openSelectAccept, setOpenSelectAccept] = React.useState(false);
  const [anchorAccept, setAnchorAccept] = React.useState(null);
  const [anchorAcceptReciveInvitation, setAnchorAcceptReciveInvitation] = React.useState(null);
  const [anchorReactivateReciveInvitation, setAnchorReactivateReciveInvitation] = React.useState(null);
  const [openSelectAcceptReciveInvitation, setOpenSelectAcceptReciveInvitation] = React.useState(false);
  const [openSelectReactivateReciveInvitation, setOpenSelectReactivateReciveInvitation] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const [sentInvitationSearchVal, setSentInvitationSearchVal] = React.useState('');
  const [receiveInvitationSearchVal, setReceiveInvitationSearchVal] = React.useState('');
  const [anchorDeactivate, setAnchorDeactivate] = React.useState(null);
  const [openDeactivate, setOpenDeactivate] = React.useState(false);
  const [actionCaresiteValue, setActionCaresiteValue] = React.useState("");
  const [actionUserValue, setActionUserValue] = React.useState("");
  const scrollRef = React.useRef(null);
  const [anchorResend, setAnchorResend] = React.useState(null);
  const [openSelectResend, setOpenSelectResend] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  //alert for snackbar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const handleSnackbar = () => {
    setOpenSnack(false);
  };
  const options = {
    day: 'numeric',
    month: 'long',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'shortGeneric',
  };

  var revokeUserState;
  var valSentInvitation = new Object();
  valSentInvitation.search = sentInvitationSearchVal;
  valSentInvitation.id = userRole.id;
  valSentInvitation.sentViewTable = 'sentInvitation';
  valSentInvitation.page = page;
  valSentInvitation.userRole = userRole.role;
  valSentInvitation.property = 'email';
  valSentInvitation.order = 'asc';

  var valReciveInvitation = Object();
  valReciveInvitation.search = receiveInvitationSearchVal;
  valReciveInvitation.id = userRole.id;
  valReciveInvitation.sentViewTable = 'receivedInvitation';
  valReciveInvitation.page = page;
  valReciveInvitation.property = 'email';
  valReciveInvitation.order = 'asc';

  useEffect(()=>{
    handleSearchforSentInvitation(sentInvitationSearchVal);
  },[sentInvitationSearchVal]);

  useEffect(()=>{
    if(receiveInvitationSearchVal){
    handleSearchforRecivedInvitation(receiveInvitationSearchVal);
    }
  },[receiveInvitationSearchVal]);
  useEffect(() => {
    invitationPagination(valSentInvitation);
    getSentReceivedInvitationCount();
  }, []);

  useEffect(() => {
    if (openPopUp) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    userPendingData({ role: userRole.role });
  }, []);

  // we are checking for this useeffect if he don't have any clinic and some send invitation so we are removing isAssociatedClinic this values from localstorege and we reload the page only once time .

  useEffect(() => {
    if (
      if_dont_have_any_clinic === "UPDATE" &&
      localStorage.getItem("isAssociatedClinic") == 0
    ) {
      window.localStorage.removeItem("isAssociatedClinic");
      window.location.reload();
    }

  }, [if_dont_have_any_clinic]);
  const open = Boolean(anchorEl);

  //---------------SENDING INVITATION LINK TO CHILD AND GETTING THE VALUE FROM CHILD----------

  const callBack = () => {
    setOpen(false);
    openInvitePopup(false);
    setSuccsess(false);
  };

  const submit = (val) => {
    setTimeout(() => {
      setSuccsess(false)
    }, 5000)
    setOpen(false);
    setSuccsess(true);
  };

  //-----------------------------REVOKE CALLBACK--------------

  const closeRevokePopUp = () => {
    setIsPopUpOpen(false);
  };

  const statusRevokeCallBackToParent = (value) => {
    setPage(1);
    if (invitationFlagValue && invitationFlagValue.status !== 'resend')
      revokeUser(invitationFlagValue);
    rejectInvitation(rejectVals, invitationFlagValue);
    setIsPopUpOpen(false);
  };

  //2nd  deactivate, funtion
  const onClickRevoke = (vals) => {
    setIsPopUpOpen(true);
    invitationAction({ ...vals, status: "blocked" });
    setActionCaresiteValue(vals.clinicname);
    setActionUserValue(vals.email);
  };

  //2nd fun revoke (sent invitation)
  const onClickRevokeforSentIvitation = (vals) => {
    setIsPopUpOpen(true);
    invitationAction({ ...vals, status: "blocked", from: "sentInvitation" });
    setActionCaresiteValue(vals.clinicname);
    setActionUserValue(vals.email);
  }

  //2nd fun remove (sent invitation)
  const onClickRemoveforSentInvitation =  (vals) => {
    setIsPopUpOpen(true);
    invitationAction({ ...vals, status: "blocked", from: "sentInvitation" });
    setActionCaresiteValue(vals.clinicname);
    setActionUserValue(vals.email);
  }

  const onClickReject = (vals) => {
    setIsPopUpOpen(true);
    setRejectVals({ ...vals, status: "rejected" })
  }

  //2nd reactivate fun
  const onClickAccept = (vals) => {
    setIsPopUpOpen(true);
    invitationAction({ ...vals, status: "accepted" });
    setActionCaresiteValue(vals.clinicname);
  };

  //---------------------RESEND-------------------------

  const onClickResend = (item) => {
    let sentdate=new Date();
    sentdate.setDate(sentdate.getDate() + 7)
    let expirydate = sentdate.toLocaleTimeString('en-US',options)
    let val = { ...item, status: "Pending" ,expirydate:expirydate};
    resendInvitationLink(val);
    setOpenSuccessPopup(true);
  };

  // Activate

  const statusActiveCallBackToParent = (value) => {
    setisActivateOpen(false);
    setActiveVals(value);
    updateInvitationItem(activeVals);
  };
  const closeActivatePopUp = () => {
    setisActivateOpen(false);
  };

  //-------------SORTING THE TABLE DATAS----------

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
    if(isTableView === "sentInvitation"){
      invitationPagination({...valSentInvitation,property,order:isAscending ? "desc" : "asc"})
    }
    else{
      invitationPagination({...valReciveInvitation,property,order:isAscending ? "desc" : "asc"})
    }
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // sentInvitation search
  const handleSearchforSentInvitation = () => {
    invitationPagination({ ...valSentInvitation, page: 1 });
    setPage(1)
  }


  // reciveInvitation search
  const handleSearchforRecivedInvitation = () => {
    invitationPagination({ ...valReciveInvitation, page: 1 });
    setPage(1)
  }

  
    
  
  //routing to patientprofile
  const navToPatientProfile = async (item) => {
    clear_patient_profile();
   
    let query = Buffer.from(JSON.stringify(
      {
        profile_id: item && item.item.profile_id ? item.item.profile_id : null,
        clinic_id: item.item.clinic_id ? item.item.clinic_id : null,
        pair_id: item&&item.item2 && item.item2.pair_id ? item.item2.pair_id : null,
      })).toString('base64');
    if (query) {
      dispatch(navReportSearchToPatientProfile(item));
      history.push(`/app/PatientProfile?p=${query}`);
    

  }
}
  

  const checkifconnectedtodevice = async (val)  => {
    if(val.role !== 'patient'){
      if(val.status === 'accepted'){
        await loadOtherUserProfileForViewing(val.profile_id)
      history.push(`/app/userinfo/${val.profile_id}`);
      }
      else if (val.status === 'pending'){
        setSnackMsg(`${val.email} has not accepted the invitaion`)
        setSnackSeverity("warning")
        setOpenSnack(true)
      }
      else if (val.status === 'rejected'){
        setSnackMsg(`${val.email} has rejected the invitation`)
        setSnackSeverity("warning")
        setOpenSnack(true)
      }
    }
    else if (val.status === 'pending'){
      setSnackMsg(`${val.email} has not accepted the invitation`)
      setSnackSeverity("warning")
      setOpenSnack(true)
    }
    else if (val.status === 'rejected'){
      setSnackMsg(`${val.email} has rejected the invitation`)
      setSnackSeverity("warning")
      setOpenSnack(true)
    }
    else{
    await apiAbmBlaze
      .post(CHECK_IF_DEVICE_IS_CONNECTED, {
        profile_id:val.profile_id
      })
      .then(async (res) => {
        if (res.data) {
          
          // if(res.data.rows && res.data.rows.length>0)
          // {
          //   navToPatientProfile({item:val,item2:res.data.rows[0]})
          // }
          // else{
          //   // alert('is not associated with anydevice till date')
          //   setSnackMsg(`${val.email} has not been  associated with any BiWaze device yet`)
          //   setSnackSeverity("warning")
          //   setOpenSnack(true)
          // }
          navToPatientProfile({item:val,item2:res.data.rows[0]})
        }
      })
      .catch((error) => {
        console.log("ERROR checkifconnectedtodevice", error);
      });
  };
}
  const sortedRowInformation = (rowArray, comparator) => {

    const stabilizedRowArray = rowArray.map((el, index) =>
      [el, index]
    );

    stabilizedRowArray.sort((a, b) => {

      const order = comparator(a[0], b[0]);

      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRowArray.map((el) => el[0]);
  };

  //created by dhanush for search
  const search = useSelector((store) => {
    return store.invitation.searchInvitation
  })

  const successClosePopup = () => {
    setOpenSuccessPopup(false);
  }

  //1st accept, reject, reactivate fun
  const onAcceptInvite = (user, status) => {
    if (status == "reject") {
      setSuccessValue("rejected");
      onClickReject(user);
      setPage(1);
    } else if (status == "accept") {
      setSuccessValue("accepted");
      onClickAccept(user);
      setPage(1);
    }
    setUserData({});
    setOpenSelectAcceptReciveInvitation(false);
    setAnchorAcceptReciveInvitation(null);
    setOpenSelectReactivateReciveInvitation(false);
    setAnchorReactivateReciveInvitation(null);
  }

  //1st on click deactivate funtion
  const onDeactivateInvite = (user, status) => {
    if (status == "blocked") {
      setSuccessValue("blocked");
      onClickRevoke(user);
      setPage(1);
    }
    setUserData({});
    setAnchorDeactivate(null);
    setOpenDeactivate(false);

  };

  const changingPage = (event, value) => {
    setPage(value);
    invitationPagination({ ...valSentInvitation, page: value });
  };

  const changingPageForReceivedInvitation = (event, value) => {
    setPage(value);
    invitationPagination({ ...valReciveInvitation, page: value });
  };


  const closeInvitationSuccess = () => {
    setOpenInvitationSuccess(false);
    setSuccessValue("");
  }

  const viewTable = (val) => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" })
    if (val === "receivedInvitation") {
      setIsTableView("receivedInvitation")
      setPage(1);
      invitationPagination({ ...valReciveInvitation, page: 1 });
    } else if (val === "newInviteModal") {
      setOpen(true)
    } else if (val === "sentInvitation") {
      setPage(1);
      invitationPagination({ ...valSentInvitation, page: 1 });
      setIsTableView("sentInvitation")
    }
  }

  //1st fun of resend and remove
  const handleCloseSelectPending = (user, status) => {
    if (status == "remove") {
      setSuccessValue("remove");
      onClickRemoveforSentInvitation(user);
      // onClickRevokeforSentIvitation(user);
    } else if (status == "resend") {
      onClickResend(user);
    }
    setUserData({});
    setOpenSelectPending(false);
    setAnchorPending(null);
  }
  const handleClickSelectPending = (e, user) => {
    setOpenSelectPending(true)
    setAnchorPending(e.currentTarget)
    setUserData(user);
  }

  const handleClickSelectAccepted = (e, user) => {
    setAnchorAccept(e.currentTarget);
    setOpenSelectAccept(true);
    setUserData(user);
  }

  const handleClickSelectResend = (e, user) => {
    setAnchorResend(e.currentTarget);
    setOpenSelectResend(true);
    setUserData(user);
  }

  //receive invitation
  const handleClickSelectAcceptedReciveInvitation = (e, user) => {
    setAnchorAcceptReciveInvitation(e.currentTarget);
    setOpenSelectAcceptReciveInvitation(true);
    setUserData(user);
  }

  //reactivate invitation
  const handleClickSelectReactivateReciveInvitation = (e, user) => {
    setAnchorReactivateReciveInvitation(e.currentTarget);
    setOpenSelectReactivateReciveInvitation(true);
    setUserData(user);
  }

  //deactivate invitation
  const handleClickSelectDeactivateReciveInvitation = (e, user) => {
    setAnchorDeactivate(e.currentTarget);
    setOpenDeactivate(true);
    setUserData(user);
  }

  //1st fn of revoke (send invitation)
  const handleSelectAccept = (user, status) => {
    if (status == "revoke") {
      setSuccessValue("revoke");
      onClickRevokeforSentIvitation(user);
      setPage(1);
    }
    setUserData({});
    setOpenSelectAccept(false);
    setAnchorAccept(null);
  }


  const handleSelectResendRejected = (user, status) => {
    setSuccessValue("resend");
    if (status == "resend") {
      setRejectVals({ ...user, status: "resend" })
      setIsPopUpOpen(true);
      invitationAction({ ...user, status: "resend", from: "sentInvitation" });
      setActionCaresiteValue(user.clinicname);
      setActionUserValue(user.email);
    }
    // setPage(1);
    setUserData({});
    setAnchorResend('');
    setOpenSelectResend(false);
  }

  const handleCloseSelectAccept = (user, status) => {
    if (status == "revoke") {
      onClickRevoke(user);
      setPage(1);
    }
    setUserData({});
    setOpenSelectAccept(false);
    setAnchorAccept(null);
  }

  const handleCloseSelectResend = (user, status) => {
    // setPage(1);
    setUserData({});
    setAnchorResend('');
    setOpenSelectResend(false);
  }

  const handleCloseSelectAcceptReciveInvitation = (user, status) => {
    if (status == "accept") {
      onClickAccept(user);
    }
    setUserData({});
    setOpenSelectAcceptReciveInvitation(false);
    setAnchorAcceptReciveInvitation(null);
  }

  const handleCloseSelectReactivateReciveInvitation = (user, status) => {
    if (status == "accept") {
      onClickAccept(user);
    }
    setUserData({});
    setOpenSelectReactivateReciveInvitation(false);
    setAnchorReactivateReciveInvitation(null);
  }

  const onCloseDeactivateInvite = (user, status) => {
    if (status == "blocked") {
      setSuccessValue("blocked");
      onClickRevoke(user);
      setPage(1);
    }
    setUserData({});
    setAnchorDeactivate(null);
    setOpenDeactivate(false);
  }

  return (
    <React.Fragment>
      <AlertSnackBar
                    msg={snackMsg}
                    flag={openSnack}
                    onClose={handleSnackbar}
                    key={"cusSnackbar"}
                    anchorOrigin={customeSnackbarAnchor}
                    severity={"warning"}
                  />
      {resendInviteMsg == "Successfully resend invitation" ?
        <ResendSuccess
          open={openSuccessPopup}
          onClose={successClosePopup}
        /> : null}
      <InvitationSuccess
        open={openInvitationSuccess}
        onClose={closeInvitationSuccess}

      />
      <InviteModalPopup
        isopen={isopen}
        callBack={callBack}
        invitationlink={submit}
        clinicListName={clinicListName}
        addingClinicName={addingClinicName}
        addUserInvitation={addUserInvitation}
        flagInput={flagInput}
        updateExistingCLinicList={updateExistingCLinicList}
        reSet={reSet}
        acceptinLinkClinicName={acceptinLinkClinicName}
        msg={msg}
      />

      <AbmAdminRevokePopUp
        isOpen={isPopUpOpen}
        callBackToParent={closeRevokePopUp}
        statusRevokeCallBackToParent={statusRevokeCallBackToParent}
        successValue={successValue}
        actionCaresiteValue={actionCaresiteValue}
        actionUserValue={actionUserValue}
      // currentAction={currentAction}
      ></AbmAdminRevokePopUp>

      <AbmAdminActivatePopUp
        isOpen={isActivateOpen}
        callBackToParent={closeActivatePopUp}
        statusActiveCallBackToParent={statusActiveCallBackToParent}
      ></AbmAdminActivatePopUp>

      {msg ? (
        <div>
          <Alert
            severity="success"
            style={{
              fontSize: "12px",
              backgroundColor: "rgb(174 222 176)",
              marginTop: "20px",
              color: "#000",
              fontWeight: "bold",
            }}
          >
            {msg}
          </Alert>
        </div>
      ) : null}


      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "#6A768F66" ? "#6A768F66" : "#6A768F66 text-#126AC4")
        }
      >

        <div className="flex flex-wrap" style={{ marginLeft: '48px', marginRight: '16px', marginTop: '16px' }}>
         
         {userRole.role!="superadmin"?
         <>
         <div className="w-full lg:w-4/12">
            <InvitationCards
              textContent="New invitation"
              view="Create"
              imagepath="/assets/img/newInvitation.svg"
              viewTable={viewTable}
            ></InvitationCards>
          </div>

          <div className="w-full lg:w-4/12">
            <InvitationCards
              textContent="Sent invitations"
              view="View"
              imagepath="/assets/img/sentInvitation.svg"
              viewTable={viewTable}
              total_sent_invitation={total_sent_invitation}
            ></InvitationCards>
          </div>
          </>
           : 
           <>
          <div className="w-full lg:w-6/12">
          <InvitationCards
            textContent="New invitation"
            view="Create"
            imagepath="/assets/img/newInvitation.svg"
            viewTable={viewTable}
          ></InvitationCards>
        </div>

        <div className="w-full lg:w-6/12">
          <InvitationCards
            textContent="Sent invitations"
            view="View"
            imagepath="/assets/img/sentInvitation.svg"
            viewTable={viewTable}
            total_sent_invitation={total_sent_invitation}
          ></InvitationCards>
        </div>
        </>
             } 
          {userRole.role!="superadmin"?<div className="w-full lg:w-4/12">
            <InvitationCards
              textContent="Received invitations"
              view="View"
              imagepath="/assets/img/receivedInvitation.svg"
              viewTable={viewTable}
              total_received_invitation={total_received_invitation}
            ></InvitationCards>
          </div>:null}
          
        </div>

        <SeparatorTopSection />

        <div style={{ backgroundColor: "white" }} ref={scrollRef}>
          <p className="InvitationHeading fnt32P" style={{ marginLeft: "48px", marginBottom: "24px" }}>
            {isTableView === "sentInvitation" ? "Sent invitations" : isTableView === "receivedInvitation" ? "Received invitations" : "Invitations"}
          </p>
        </div>

        <div style={{ backgroundColor: "white" }} >
          <div className="flex flex-wrap items-center" style={{ marginBottom: '24px' }}>
            {isTableView === "sentInvitation" ?
              <div className="searchbarWrapper">
                <div className="add-icon" style={{ backgroundColor: '#F2F7F8' }}>
                  <i className="fas fa-search fa-lg" style={{ paddingTop: '12px' }}></i>
                  <input
                    id="searchBoxSentInvitation"
                    type="text"
                    placeholder="Search"
                    className="report_search"
                    onChange={(event) => {
                      setSentInvitationSearchVal(event.target.value);
                    }}
                    style={{ backgroundColor: '#F2F7F8' }}
                  />
                </div>
              </div>
              :

              isTableView === "receivedInvitation" ?
                <div className="searchbarWrapper">
                  <div className="add-icon" style={{ backgroundColor: '#F2F7F8' }}>
                    <i className="fas fa-search fa-lg" style={{ paddingTop: '12px' }}></i>
                    <input
                      id="searchBoxRecivedInvitation"
                      type="text"
                      placeholder="Search"
                      className="report_search"
                      // onKeyDown={(event) => handleEnterRecieveInvitation(event)}
                      onChange={(event) => {
                        setReceiveInvitationSearchVal(event.target.value);
                      }}

                      style={{ backgroundColor: '#F2F7F8' }}
                      value={receiveInvitationSearchVal}
                    />
                  </div>
                </div> : null}
          </div>
          {isTableView === "sentInvitation" ?
            <div className="InvitationTableWrapper">
              <div style={{ marginLeft: "48px", marginRight: "56px", backgroundColor: "white" }}>
                <table className='mainTable '>
                  <tbody>
                    <tr className='tableRow'>
                      <th className='tabelHeading2InInvitationTable' key="email">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "email" ? orderDirection : "asc"
                          }
                          onClick={createSortHandler("email")}
                        >
                          <div className='tableHeadingTopInvitationTable fnt24P'>
                            Email Address
                          </div>
                        </TableSortLabel>
                      </th>
                      <th className='tabelHeadingRole' key="role">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "role" ? orderDirection : "asc"
                          }
                          onClick={createSortHandler("role")}
                        >
                          <div className='tableHeadingTopInvitationTable fnt24P'>
                            Role
                          </div>
                        </TableSortLabel>
                      </th>
                      <th className='tabelHeadingclinicname' key="clinicname">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "clinicname" ? orderDirection : "asc"
                          }
                          onClick={createSortHandler("clinicname")}
                        >
                          <div className='tableHeadingTopInvitationTable fnt24P'>
                            Care Site Name
                          </div>
                        </TableSortLabel>
                      </th>
                      <th className='tabelHeadingStatus' key="status">
                        <TableSortLabel
                          active={true}
                          direction={
                            valueToOrderBy === "status" ? orderDirection : "asc"
                          }
                          onClick={createSortHandler("status")}
                        >
                          <div className='tableHeadingTopInvitationTable fnt24P'>
                            Status
                          </div>
                        </TableSortLabel>
                      </th>

                      <th className='tabelHeading2' key="action">
                        <div className='tableHeadingTopInvitationTable fnt24P'>
                          Action
                        </div>
                      </th>
                    </tr>
                    {paginatedInvitationList && paginatedInvitationList.length > 0 ? (paginatedInvitationList.map((item, index) => {
                      // if (item.status == "accepted" || item.status == "pending") {
                      return (
                        <>
                          <Menu
                            id="simple-menu-10"
                            anchorEl={anchorPending}
                            keepMounted
                            open={openSelectPending}
                            onClose={handleCloseSelectPending}
                            className="Revokepopup"
                            key={index}
                          >
                            <MenuItem onClick={() => handleCloseSelectPending(userData, "resend")}>Resend</MenuItem>
                            <MenuItem onClick={() => handleCloseSelectPending(userData, "remove")}>Remove</MenuItem>
                          </Menu>

                          <Menu
                            id="simple-menu-10"
                            anchorEl={anchorAccept}
                            keepMounted
                            open={openSelectAccept}
                            onClose={handleCloseSelectAccept}
                            className="Revokepopup"
                          >
                            <MenuItem onClick={() => handleSelectAccept(userData, "revoke")}>Revoke</MenuItem>
                          </Menu>

                          <Menu
                            id="simple-menu-10"
                            anchorEl={anchorResend}
                            keepMounted
                            open={openSelectResend}
                            onClose={handleCloseSelectResend}
                            className="Revokepopup"
                          >
                            <MenuItem onClick={() => handleSelectResendRejected(userData, "resend")}>Resend</MenuItem>
                          </Menu>

                          <tr className='tabelDescAndRowAfterMapping '>

                            <td className='tabelHeading2'>
                              <div className='tableDescInInvitationTable fnt22P' onClick={() => checkifconnectedtodevice(item)} >

                                {item.email ? item.email : '-'}
                              </div>
                            </td>

                            <td className='tabelHeadingRole'>
                              <div className='tableDescIn fnt22P'
                                style={{ textTransform: "capitalize" }} onClick={() => checkifconnectedtodevice(item)}  >

                                {item.role ? item.role === "clinicadmin" ? "Care Site Admin" : item.role === "abmadmin" ? "ABM Admin" : item.role : '-'}
                              </div>
                            </td>

                            <td className='tabelHeadingclinicname'>
                              <div className='tableDescIn fnt22P' onClick={() => checkifconnectedtodevice(item)} >

                                {item.clinicname ? item.clinicname : '-'}
                              </div>
                            </td>

                            <td className='tabelHeadingStatus'>
                              <div className='tableDescIn fnt22P'
                                style={{ textTransform: "capitalize" }}  onClick={() => checkifconnectedtodevice(item)}>

                                {item.status}

                              </div>
                            </td>

                            <td className='tabelHeading1' style={{ height: "40px",cursor:'pointer' }}>
                              {item.status == "pending" ?
                                <div className="selectImage" onClick={(e) => {
                                  handleClickSelectPending(e, item);
                                }}>

                                </div>
                                : item.status == "accepted" ?
                                  <div className="selectImage" onClick={(e) => {
                                    handleClickSelectAccepted(e, item);
                                  }}>
                                  </div>
                                  : item.status == "rejected" ?
                                    <div className="selectImage" onClick={(e) => {
                                      handleClickSelectResend(e, item);
                                    }}>
                                    </div> : '-'
                              }
                            </td>

                          </tr>
                        </>
                      );
                      // }

                    })
                    ) : <tr>
                      <td colSpan="6" className='nodatafoundWrapper fnt22P'>No data found</td>
                    </tr>}
                  </tbody>
                </table>
              </div>

              {paginatedInvitationList && paginatedInvitationList.length > 0 ?
                <div className="pagination" style={{ marginRight: "20px", paddingTop: '20px', paddingBottom: '20px' }}>
                  <div>
                    <Pagination
                      count={Math.ceil(paginatedInvitationList[0].total_count / 10)}
                      page={page}
                      onChange={changingPage}
                    />
                  </div>
                </div> : null}
            </div>
            :
            <div>
              <div style={{ marginLeft: "48px", marginRight: "56px" }}>
                <table className='mainTable fnt24P'>
                  <tbody>
                    <tr className='tableRow'>
                      <th className='tabelHeadingReceivedCaresite' key="clinicname">
                        <TableSortLabel
                          active={valueToOrderBy === "clinicname"}
                          direction={
                            valueToOrderBy === "clinicname" ? orderDirection : "asc"
                          }
                          onClick={createSortHandler("clinicname")}
                        >
                          <div className='tableHeadingTop'>
                            {userRole.role ==='abmadmin'?'Sent From': 'Care Site Name'}
                          </div>
                        </TableSortLabel>
                      </th>
                      <th className='tabelHeadingReceivedStatus' key="status">
                        <TableSortLabel
                          active={valueToOrderBy === "status"}
                          direction={
                            valueToOrderBy === "status" ? orderDirection : "asc"
                          }
                          onClick={createSortHandler("status")}
                        >
                          <div className='tableHeadingTop' >
                            Status
                          </div>
                        </TableSortLabel>
                      </th>

                      <th className='tabelHeading2' key="action">
                        <div className='tableHeadingTopInvitationTable fnt24P'>
                          Action
                        </div>
                      </th>
                    </tr>



                    {paginatedInvitationList && paginatedInvitationList.length > 0 ? (paginatedInvitationList.map((item, index) => {
                      // if (item.status == "pending") {
                      return (
                        <>
                          {item.status == "pending" || item.status == "rejected" ?
                            <Menu
                              id="simple-menu-10"
                              anchorEl={anchorAcceptReciveInvitation}
                              keepMounted
                              open={openSelectAcceptReciveInvitation}
                              onClose={handleCloseSelectAcceptReciveInvitation}
                              key={index}
                            >
                              <MenuItem onClick={() => onAcceptInvite(userData, "accept")}>Accept</MenuItem>
                              <MenuItem onClick={() => onAcceptInvite(userData, "reject")}>Reject</MenuItem>
                            </Menu>
                            : item.status == "accepted" ?
                              <Menu
                                id="simple-menu-10"
                                anchorEl={anchorDeactivate}
                                keepMounted
                                open={openDeactivate}
                                onClose={onCloseDeactivateInvite}
                                className="Revokepopup">
                                <MenuItem onClick={() => onDeactivateInvite(userData, "blocked")}>Deactivate</MenuItem>
                              </Menu> :
                              item.status == "blocked" ?
                                <Menu
                                  id="simple-menu-10"
                                  anchorEl={anchorReactivateReciveInvitation}
                                  keepMounted
                                  open={openSelectReactivateReciveInvitation}
                                  onClose={handleCloseSelectReactivateReciveInvitation}
                                >
                                  <MenuItem onClick={() => onAcceptInvite(userData, "accept")}>Reactivate</MenuItem>
                                </Menu> : null}


                          <tr className='tabelDescAndRowAfterMapping' style={{cursor:'default' }}>

                            <td className='tabelHeadingReciveInvitation'>
                              <div className='tableDataReciveInvitation fnt22P' style={{cursor:'default' }}>

                                {item.clinicname != null ?
                                  (<span style={{ textTransform: "capitalize" }}>
                                    {item.clinicname}
                                  </span>)
                                  : userRole.role ==='abmadmin'?'SuperAdmin': "-"}

                              </div>
                            </td>

                            <td className='tabelHeadingReciveInvitation'>
                              <div className='tableDataReciveInvitation fnt22P' style={{ textTransform: "capitalize",cursor:'default' }}>

                                {item.status}

                              </div>
                            </td>

                            <td  className='tabelHeading1' style={{ height: "60px",cursor:'pointer' }}>
                              {item.status == "pending" ?
                                <div className="selectImage" onClick={(e) => {
                                  handleClickSelectAcceptedReciveInvitation(e, item);
                                }}>
                                </div>
                                : item.status == "accepted" ?
                                  <div className="selectImage" onClick={(e) => {
                                    handleClickSelectDeactivateReciveInvitation(e, item);
                                  }}>
                                  </div>
                                  : item.status == "blocked" ?
                                    <div className="selectImage" onClick={(e) => {
                                      handleClickSelectReactivateReciveInvitation(e, item);
                                    }}>
                                    </div>
                                    : item.status == "rejected" ?
                                      <div></div>
                                      : null}
                            </td>
                          </tr>
                        </>
                      );
                      // }
                    })
                    ) : <tr>
                      <td colSpan="6" className='nodatafoundWrapper fnt22P'>No data found</td>
                    </tr>}
                  </tbody>
                </table>
              </div>
              {paginatedInvitationList && paginatedInvitationList.length > 0 ?
                <div className="pagination" style={{ marginRight: "20px", paddingTop: '20px', paddingBottom: '20px' }}>
                  <div>
                    <Pagination
                      count={Math.ceil(paginatedInvitationList[0].total_count / 10)}
                      page={page}
                      onChange={changingPageForReceivedInvitation}
                    />
                  </div>
                </div> : <div style={{ paddingTop: "40px" }}> </div>}
            </div>

          }

        </div>

      </div>
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
}));

SAInvitationTable.defaultProps = {
  color: "light",
};

SAInvitationTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
const mapStateToProps = (store) => {
  return {
    adminTable: store.user.adminTable,
    clinicListName: store.invitation.allcliniclist,
    addUserInvitation: store.invitation.addUserInvitation,
    flagInput: store.invitation.flagInput,
    openPopUp: store.invitation.openPopUp,
    msg: store.invitation.msg,
    userRole: store.user.user,
    resendInviteMsg: store.invitation.resendInviteMsg,
    invitationActionFlag: store.invitation.invitationActionFlag,
    paginatedInvitationList: store.invitation.paginatedInvitationList,
    total_sent_invitation: store.invitation.total_sent_invitation,
    total_received_invitation: store.invitation.total_received_invitation,
    invitationFlagValue: store.invitation.invitationFlagValue,
    sentInvitationFlagValue: store.invitation.sentInvitationFlagValue,
    if_dont_have_any_clinic: store.invitation.if_dont_have_any_clinic,
  };
};

export default connect(mapStateToProps, {
  invitationlink,
  fetchInvitation,
  updateExistingCLinicList,
  addInvitationItem,
  updateInvitationItem,
  fetchclinic,
  addingClinicName,
  reSet,
  acceptinLinkClinicName,
  openInvitePopup,
  resendInvitationLink,
  openFilterPopup,
  searchInvitation,
  revokeUser,
  rejectInvitation,
  acceptInvitation,
  invitationPagination,
  userPendingData,
  clear_patient_profile,
  getSentReceivedInvitationCount,
  invitationAction,
  loadOtherUserProfileForViewing
})(SAInvitationTable);

function Alertforsncackbar(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AlertSnackBar = (
  {
    flag,
    onClose,
    anchorOrigin,
    msg,
    severity,
  }) => {
  return (

    <Snackbar
      anchorOrigin={
        anchorOrigin
          ? anchorOrigin
          : { vertical: "top", horizontal: "right" }
      }
      open={flag}
      //   autoHideDuration={6000}
      onClose={onClose}
    >
      <Alertforsncackbar onClose={onClose} severity={severity ? severity : "success"}>
        {msg}
      </Alertforsncackbar>
    </Snackbar>
  );
};



































