import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./AddNote.css";
import { connect, useSelector } from "react-redux";
import {
  addNote,
  fetchNotedata,
} from "../../redux/therapyGoals/therapyGoals.action";
import SucssesPopup from "../../components/ModalPopup/SucssesPopup";

const AddNotePopup = (props) => {
  const [edit, setEdit] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = React.useState(false);

  const profile_id = useSelector((store) => {
    return store.user.navFromReportSearchToPatientProfile.profile_id;
  });
  const addNoteObj = {
    profile_id,
    value,
  };

  const onSave = () => {
    props.onClose();
    setEdit(false);
    props.addNote(addNoteObj);
    setOpenSuccessPopup(true);
    // props.fetchNotedata(profile_id);
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const onClosePopup = () => {
    props.onClose();
    setEdit(false);
  };

  const successClosePopup = () => {
    // props.closeInvitationSuccesPopup();
    setOpenSuccessPopup(false);
  };
  return (
    <>
      <SucssesPopup
        open={openSuccessPopup}
        onClose={successClosePopup}
        msg={
          props.fetchnote.note === ""
            ? "Notes added successfully"
            : "Notes added successfully"
        }
      />

      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth="lg"
        className="addNoteWrapper"
      >
        <DialogTitle
          style={{
            backgroundColor: "#F2F7F8",
            paddingLeft: "56px",
            paddingRight: "56px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              paddingTop: "16px",
            }}
          >
            <div
              style={{ color: "#3D446F", fontSize: "18px", fontWeight: "500" }}
            >
              {" "}
              Note{" "}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <img
                className="editIconWrapper"
                src={
                  require("../../assets/img/notificationsummary_edit.svg")
                    .default
                }
                alt="no internet"
                onClick={() => setEdit(true)}
              ></img>
              <CloseOutlinedIcon onClick={onClosePopup} className="closeIcon" />
            </div>
          </div>
        </DialogTitle>
        {edit === false ? (
          <DialogContent
            className="addNoteContentWrapper"
            style={{
              backgroundColor: "#f2f7f8",
              paddingLeft: "56px",
              paddingRight: "34px",
              paddingBottom: "24px",
              paddingTop: "40px",
            }}
          >
            <textarea
              disabled={true}
              rows={5}
              value={
                props.fetchnote && props.fetchnote.note
                  ? props.fetchnote.note
                  : ""
              }
              className="addNoteInputWrapperBeforeEdit"
            ></textarea>
          </DialogContent>
        ) : (
          <DialogContent
            className="addNoteContentWrapper"
            style={{
              backgroundColor: "#f2f7f8",
              paddingLeft: "56px",
              paddingRight: "34px",
              paddingBottom: "24px",
              paddingTop: "40px",
            }}
          >
            <textarea
              rows="5"
              className="addNoteInputWrapper"
              onChange={handleChange}
            >
              {props.fetchnote.note}
            </textarea>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid white",
                borderRadius: "5px",
                background: "white",
                width: "34%",
                height: "48px",
                color: "#3D446F",
                fontSize: "18px",
                fontWeight: "500",
                cursor: "pointer",
                margin: "auto",
                boxShadow: "0px 3px 10px #6a768f38",
              }}
              onClick={onSave}
            >
              Save
            </div>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    addnote: store.therapyGoals.addnote,
    fetchnote: store.therapyGoals.fetchnote,
  };
};

export default connect(mapStateToProps, { addNote, fetchNotedata })(
  AddNotePopup
);
