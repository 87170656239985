import React, { useEffect, useState } from "react";
import "./PasswordAndSecurity.css";
import Popup from "./Popup";
import CreatenewPassword from "./CreatenewPassword";
import SelectMeasurementUnits from "./SelectMeasurementUnits";
import { connect, useDispatch } from "react-redux";
import {
  userNewEmail,
  confirmEmailOTP,
  otpFlag,
  userNewPhone,
  confirmPhoneOTP,
  pageHeading,
} from "../../redux/user/user.action";
import { measurementUnit } from "../../redux/user/user.action"
import UpdateSuccess from "./UpdateSuccess";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { USER_NEW_EMAIL } from "../../redux/action.list";

const useStyles = makeStyles((theme) => ({
  buttons: {
    backgroundColor: "#F2F4F8",
    boxShadow: "#6A768F80 0px 3px 6px",
    color: "#3D446F",
    fontFamily: "Roboto",
    fontSize: "14px",
    marginBottom: "8px",
    textTransform: "none",
    fontWeight: "bold",
    padding: "10px 16px",
    borderRadius: "10px",
    //   box-shadow: #6A768F80 0px 3px 6px;
    fontWeight: "bold",
    //   font-size: 14px;
    textAlign: "center",
    //   color: #4f557c;
    //   outline: none !important;
    width: "100%",
  },
}));

const PasswordAndSecurity = (props) => {
  const [popover, setPopover] = React.useState(true);
  const [popoveremail, setPopoveremail] = React.useState(true);
  const [popovercreatpswd, setPopovercreatpswd] = React.useState(true);
  const [popoverMeasure, setPopoverMeasure] = React.useState(true);
  const [none, setNone] = React.useState("block");
  const [mobilePopup, setMobilePopUp] = React.useState(false);
  const [passwordPopup, setPasswordPopup] = React.useState(false);
  const [measurePopup, setMeasurePopup] = React.useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = React.useState(true);
  const [successValue, setSuccessValue] = React.useState("");
  const [paswrdDiv, setPaswrdDiv] = React.useState(false);
  const [measureDiv, setMeasureDiv] = React.useState(false);
  const [mobileDiv, setMobileDiv] = React.useState(false);
  const [openMeasurePopup, setOpenMeasurePopup] = React.useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    props.pageHeading("Settings");
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  const popuppic = () => {
    setCurrentTab('change_email')
    dispatch({
      type: USER_NEW_EMAIL,
      payload: {
        newEmailSet: '',
        emailToken: null,
        emailOtp: '',
      },
    })
    setPopover(false); // open email div
    setMobilePopUp(false); // close mobile div
    setMobileDiv(false); // close mobile div
    setPaswrdDiv(false); // close paswrd div
    setPasswordPopup(false); // close paswrd div
    setPopovercreatpswd(false); // close paswrd div
    setPopoverMeasure(false); // close measurement div
    setPopoveremail(false);
    setOpenSuccessPopup(true);
    setMeasureDiv(false);
    setMeasurePopup(false);
    setNone("none");
    setSuccessValue("Email");
  };
  const popupemail = () => {
    setCurrentTab('change_phone')
    setPopoveremail(true); // open mobile div
    setMobilePopUp(true); // open mobile div
    setMobileDiv(true); // open mobile div
    setPopover(true); // close email div
    setPaswrdDiv(false); // close paswrd div
    setPasswordPopup(false); // close paswrd div
    setPopovercreatpswd(false); // close paswrd div
    setPopoverMeasure(false); // close measurement div
    setOpenSuccessPopup(true);
    setMeasureDiv(false);
    setMeasurePopup(false);
    setNone("none");
    setSuccessValue("Phone Number");
  };
  const popupcreate = () => {
    setCurrentTab('change_password')
    setPaswrdDiv(true); // open paswrd div
    setPasswordPopup(true); // open paswrd div
    setPopover(true); // close email div
    setMobilePopUp(false); // close mobile div
    setMobileDiv(false); // close mobile div
    setPopovercreatpswd(true); // open paswrd div
    setSuccessValue("Password");
    setOpenSuccessPopup(true);
    setMeasureDiv(false);
    setMeasurePopup(false);
  };

  const popupmeasure = () => {
    setCurrentTab('change_measurement')
    setPaswrdDiv(false); // open paswrd div
    setPasswordPopup(false);
    setPopovercreatpswd(false);
    setMeasureDiv(true); // condition  measure paswrd div
    setMeasurePopup(true); //condition open paswrd div
    setPopover(true); // close email div
    setMobilePopUp(false); // close mobile div
    setMobileDiv(false); // close mobile div
    setPopoverMeasure(true); // open create paswrd div
    setSuccessValue("measurement units");
    setPopovercreatpswd(false); // close paswrd div
    // setOpenMeasurePopup("open measurement")
    setOpenSuccessPopup(true);
  };

  const close = () => {
    setPopover(true);
    setPopoveremail(false);
    setPopovercreatpswd(true);
    setMobilePopUp(false);
    setPasswordPopup(false);
    setMeasurePopup(false);
    setTimeout(() => {
      setSuccessValue("");
      props.otpFlag(false);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, 5000);
  };

  const onMobileClose = () => {
    setMobilePopUp(false);
    setTimeout(() => {
      setSuccessValue("");
      props.otpFlag(false);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, 5000);
  };

  const onCreatePswdClose = () => {
    setPasswordPopup(false);
    setTimeout(() => {
      setSuccessValue("");
      props.otpFlag(false);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, 5000);
  };

  const onMeasureClose = () => {
    setMeasurePopup(false);
    setTimeout(() => {
      props.otpFlag(false);
    setSuccessValue("");
    }, 5000);
  };

  const successClosePopup = () => {
    setOpenSuccessPopup(false);
    props.otpFlag(false);
    setSuccessValue("");
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  return (
    <>
      {props.emailOtpMatch == true ? (
        <UpdateSuccess
          open={openSuccessPopup}
          onClose={successClosePopup}
          onHandleValue={successValue}
        />
      ) : null}
      <div className="pswd-container">
        <div className="flex flex-wrap">
          <div className=" w-full lg:w-12/12 px-4 fullpswd-security">
            <div>
              <div className="pswrd-headercls">
                <h2 className="header-password1">
                {/* Settings */}
                </h2>
              </div>
            </div>
            <div></div>
          </div>
        </div>

        <div className="flex flex-wrap buttonController">
          {props.user.role === "superadmin" ? null : (
            <div className="w-min lg:w-3/12 mt-3" style={{ padding: "12px" }}>
              <div className="relative w-full mb-3">
                <Button
                  className={classes.buttons}
                  style={{ backgroundColor: currentTab=== "change_email"?"#f26930":"white", borderRadius: "2px" }}
                  onClick={popuppic}
                >
                  <h2 className="pswd-email responsive-text" style={{color:currentTab=== "change_email"?"white":"#6A768F"}}>
                    <i className="far fa-envelope psd-icon"></i>
                    Change email address
                  </h2>
                </Button>
              </div>
            </div>
          )}

          <div className="w-min lg:w-3/12 mt-3" style={{ padding: "12px" }}>
            <div className="relative w-full mb-3">
              <Button
                className={classes.buttons}
                style={{ backgroundColor: currentTab=== "change_phone"?"#f26930":"white", borderRadius: "2px" }}
                onClick={popupemail}
              >
                <h2 className="pswd-email responsive-text" style={{color:currentTab=== "change_phone"?"white":"#6A768F"}}>
                  <i className="fas fa-mobile-alt psd-icon-mobile"></i>
                  Change phone number
                </h2>
              </Button>
            </div>
          </div>
          <div className="w-min lg:w-3/12 mt-3" style={{ padding: "12px" }}>
            <div className="relative w-full mb-3">
              <Button
                className={classes.buttons}
                style={{ backgroundColor: currentTab=== "change_password"?"#f26930":"white", borderRadius: "2px" }}
                onClick={popupcreate}
              >
                <h2 className="pswd-email responsive-text" style={{color:currentTab=== "change_password"?"white":"#6A768F"}}>
                  <i className="fa fa-lock psd-icon"></i>
                  Change password
                </h2>
              </Button>
            </div>
          </div>
          {props.user.role === "superadmin" || props.user.role === "abmadmin" ? null : (
            <div className="w-min lg:w-3/12 mt-3" style={{ padding: "12px" }}>
              <div className="relative w-full mb-3">
                <Button
                  className={classes.buttons}
                  style={{ backgroundColor: currentTab=== "change_measurement"?"#f26930":"white", borderRadius: "2px" }}
                  onClick={popupmeasure}
                >
                  <h2 className="pswd-email responsive-text" style={{color:currentTab=== "change_measurement"?"white":"#6A768F"}}>
                    <i class="fa fa-balance-scale" style={{ marginLeft: "-20px", paddingRight: "3px" }}></i>
                    Change Measuring units
                  </h2>
                </Button>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-wrap">
          {popover === false ? (
            <>
              <Popup popover={popover} close={close} />
            </>
          ) : null}
        </div>

        {popoveremail ? (
          <div className="flex flex-wrap">
            {mobileDiv === true && mobilePopup === true ? (
              <Popup
                popoveremail={popoveremail}
                onMobileClose={onMobileClose}
              />
            ) : null}
          </div>
        ) : null}

        {popovercreatpswd ? (
          <div className="flex flex-wrap">
            {paswrdDiv === true && passwordPopup === true ? (
              <CreatenewPassword onCreatePswdClose={onCreatePswdClose} />
            ) : null}
          </div>
        ) : null}

        {popoverMeasure ? (
          <div className="flex flex-wrap">
            {measureDiv === true && measurePopup === true ? (
              <SelectMeasurementUnits onMeasureClose={onMeasureClose}
              // openMeasurePopup={openMeasurePopup}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    newEmailSet: store.user.newEmailSet,
    emailToken: store.user.emailToken,
    emailOtpMatch: store.user.emailOtpMatch,
    newPhoneSet: store.user.newPhoneSet,
    phoneToken: store.user.phoneToken,
    user: store.user.user,
    updatedUnitFlag: store.user.updatedUnitFlag,

  };
};

export default connect(mapStateToProps, {
  userNewEmail,
  confirmEmailOTP,
  otpFlag,
  userNewPhone,
  confirmPhoneOTP,
  pageHeading,
  measurementUnit
})(PasswordAndSecurity);
