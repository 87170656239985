import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from '@mui/material/TablePagination';
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./style.css";
function descendingComparator(a, b, orderBy) {
  if (orderBy === "clinicname") {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  } else {
    if ("address" in a && "address" in b) {
      if (b.address[orderBy] < a.address[orderBy]) {
        return -1;
      }
      if (b.address[orderBy] > a.address[orderBy]) {
        return 1;
      }
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "clinicname",
    numeric: false,
    disablePadding: false,
    label: "Care Site name",
    /*  width: "20px", */
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
    /*   width: "45%", */
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
    /* width: "45%", */
  },
  {
    id: "postal",
    numeric: false,
    disablePadding: false,
    label: "Zip",
    /*   width: "45%", */
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              /* style={{ marginRight: "100px" }} */
              style={{ width: headCell.width }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return <Toolbar></Toolbar>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("clinicname");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(true);
  const [searchVal, setSearchVal] = React.useState("");

  React.useEffect(() => {
    if (selected.length) {
      props.handleOnChange(selected);
    } else {
      props.handleOnChange();
    }
  }, [selected]);

  // React.useEffect(() => {
  //   console.log("props.userSpecificCL:",props.options)
  // }, [props.options])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClinicSearch = (e) => {
    setSearchVal(e);
  };

  let searchFilter =
    searchVal !== null || searchVal !== " "
      ? props.options &&
      props.options.filter((item) => {
        const itemData = item.clinicname ? item.clinicname : "";
        const textData = searchVal;
        return itemData.includes(textData);
      })
      : props.options;


  const handleSelectAllClick = (event) => {
    let ifSearchData = searchFilter ? searchFilter : props.options;
    if (event.target.checked) {
      const newSelecteds = ifSearchData.map((n) => n.c_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <EnhancedTableToolbar numSelected={selected.length} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "-77px",
        }}
      >
        <div>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            style={{ marginLeft: "9px", fontSize: "15px" }}
          >
            Select Care Site ({selected.length}/
            <sapn>{props.options.length}</sapn>)
          </Typography>
        </div>
        <div className="table-search-header ">
          <div className="w-4/12 lg:w-4/12 ClinicAdminTableSearchBar">
            <div
              className="add-icon"
              style={{ backgroundColor: "#F2F7F8", width: "350px" }}
            >
              <i
                className="fas fa-search fa-lg"
                style={{ paddingTop: "12px" }}
              ></i>
              <input
                type="text"
                placeholder="Search"
                className="report_search fnt24P"
                style={{ backgroundColor: "#F2F7F8", height: "37px" }}
                onChange={(e) => handleClinicSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="table-scroll-invitation-popup">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer
            style={{
              maxHeight: "330px",
              marginTop: "-17px",
            }}
          >
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              stickyHeader
              style={{ padding: "14px" }}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={props.options.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}

                {searchFilter && searchFilter.length > 0 ? (
                  stableSort(searchFilter, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.c_id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.c_id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <TableCell
                            padding="checkbox"
                            style={{ marginRight: "11px !important" }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            /* style={{
                            marginRight: "100px",
                          }} */
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.clinicname && row.clinicname
                              ? row.clinicname
                              : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.address && row.address.city
                              ? row.address.city
                              : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.address && row.address.state
                              ? row.address.state
                              : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            {row.address && row.address.postal
                              ? row.address.postal
                              : "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan="6"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      No care site found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </Box>
  );
}
