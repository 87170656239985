import moment from "moment";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import "../../screens/PrivateRoutes/PatientProfile/styles.css";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
const DeliveredParametersGraph = (props) => {
  // console.log('props',props)
  const [clickedDataPoint, setClickedDataPoint] = React.useState(false);
  const [singledaydata,setsingledaydata]= React.useState([]);
  const [clickedDataPointforpip, setClickedDataPointforpip] = React.useState(false);
  const [clickedDataPointforev, setClickedDataPointforev] = React.useState(false);
  const [singledaydataforpip,setsingledaydataforpip]= React.useState([]);
  const [singledaydataforev,setsingledaydataforev]= React.useState([]);
  const [date1, setDate1] = React.useState(undefined)
  const [date2, setDate2] = React.useState(undefined)
  const [date3, setDate3] = React.useState(undefined)

  function MyComponent() {
    return(
       <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <KeyboardBackspaceIcon />
  </div>
);
  }
  function handleclosedrilldown(){
    setClickedDataPoint(false);
  }
  function handleclosedrilldownforpip(){
    setClickedDataPointforpip(false);
  }
  function handleclosedrilldownforev(){
    setClickedDataPointforev(false);
  }
    let days = [],i = -1, previosTimestamp;
    props.graphData?.forEach(ele=>{
      let d = moment(ele.timestamp).format('YYYY-MM-DD');
      if(previosTimestamp!=d){
        i++;
        previosTimestamp = d;
        days[i] = [ele];
      }
      else{
        days[i] = [...days[i],ele];
      }
    });
    days = days.map(arr=>{
      let pfSUm = 0, evSum = 0, pipSum = 0;
      arr.forEach(ele=>{
        pfSUm += parseInt(ele.val.P_F);
        evSum += parseInt(ele.val.E_V);
        pipSum += parseInt(ele.val.P_IP);
      })
      return {
        timestamp:moment(arr[0].timestamp).format('YYYY-MM-DD'),
        val:{
          E_V:(evSum/arr.length).toFixed(2),
          P_F:(pfSUm/arr.length).toFixed(2),
          P_IP:(pipSum/arr.length).toFixed(2)
        },
        cycles_count:arr.length
      }
    })
    if(days[0]&&days[0].timestamp!=props.dates.from){
      days = [{
        timestamp:props.dates.from,
        val:{P_F:'undefined',P_IP:'undefined',E_V:'undefined'},
        cycles_count:undefined
      },...days]
    }
    if(days[days.length-1]&&days[days.length-1].timestamp!=props.dates.to){
      days.push({
        timestamp:props.dates.to,
        val:{P_F:'undefined',P_IP:'undefined',E_V:'undefined'},
        cycles_count:undefined
      })
    }
    // console.log('DAYS',days)
    
    let p_ip = [], p_f = [], e_v = [];

    // replacing

    days?.forEach(ele=>{
      p_ip.push(ele.val.P_IP);
      p_f.push(ele.val.P_F);
      e_v.push(ele.val.E_V);
    })
    let categories = days?.map(ele=>{
      return ele.timestamp
    });
    function getObjectsByDate(array,selectedDate) {
      const objectsWithSelectedDate = array.filter(obj => {
        const date = obj.timestamp.split(' ')[0];
        // console.log('date',date)
        return date === selectedDate;
      });

      let dataforselectedpoint = []
      objectsWithSelectedDate.map(opts=>{let timestamp=opts.timestamp
      let val=opts.val.P_F
      let ts_ref_id = opts.ts_ref_id
      let event_id= opts.event_id
      dataforselectedpoint.push({x:timestamp,y:Number(val),z:ts_ref_id,therapy_no:null,cycle_no:null,event_id:event_id})
      })
      const recurringXValues = {};
      for (const obj of dataforselectedpoint) {
       const xValue = obj.x;
       if (!recurringXValues[xValue]) {
        recurringXValues[xValue] = 1;
      } else {
         recurringXValues[xValue]++;
      }
      }
      //  console.log('recurringXValues',recurringXValues)
       for (let i = 0; i < dataforselectedpoint.length; i++) {
        const currentX = dataforselectedpoint[i].x;
         if (recurringXValues[currentX] > 1) {
          const currentTime = moment(currentX).add(i, 'millisecond');
          dataforselectedpoint[i].x = currentTime.format('YYYY-MM-DD HH:mm:ss.SSS');
          }
          }
        const groupedData = {};

        for (const obj of dataforselectedpoint) {
        const zValue = obj.z;
        if (!groupedData[zValue]) {
         groupedData[zValue] = [];
          }
        groupedData[zValue].push(obj);
        }
        const keys = Object.keys(groupedData)
        for (const obj of dataforselectedpoint) {
          const index = keys.indexOf(obj.z);
          if (index !== -1) {
             obj.therapy_no = index+1;
          }
          for(const ele of groupedData[obj.z]){
            if(obj===ele){obj.cycle_no= groupedData[obj.z].indexOf(ele)+1}}
        }
      return dataforselectedpoint;
    }
    function getObjectsByDateforpip(array,selectedDate) {
      const objectsWithSelectedDate = array.filter(obj => {
        const date = obj.timestamp.split(' ')[0];
        // console.log('date',date)
        return date === selectedDate;
      });

      let dataforselectedpoint = []
      objectsWithSelectedDate.map(opts=>{let timestamp=opts.timestamp
      let val=opts.val.P_IP
      let ts_ref_id = opts.ts_ref_id
      let event_id= opts.event_id
      dataforselectedpoint.push({x:timestamp,y:Number(val),z:ts_ref_id,therapy_no:null,cycle_no:null,event_id:event_id})
      })
      const recurringXValues = {};
      for (const obj of dataforselectedpoint) {
       const xValue = obj.x;
       if (!recurringXValues[xValue]) {
        recurringXValues[xValue] = 1;
      } else {
         recurringXValues[xValue]++;
      }
      }
      //  console.log('recurringXValues',recurringXValues)
       for (let i = 0; i < dataforselectedpoint.length; i++) {
        const currentX = dataforselectedpoint[i].x;
         if (recurringXValues[currentX] > 1) {
          const currentTime = moment(currentX).add(i, 'millisecond');
          dataforselectedpoint[i].x = currentTime.format('YYYY-MM-DD HH:mm:ss.SSS');
          }
          }
       const groupedData = {};

        for (const obj of dataforselectedpoint) {
        const zValue = obj.z;
        if (!groupedData[zValue]) {
         groupedData[zValue] = [];
          }
        groupedData[zValue].push(obj);
        }
        const keys = Object.keys(groupedData)
        for (const obj of dataforselectedpoint) {
          const index = keys.indexOf(obj.z);
          if (index !== -1) {
             obj.therapy_no = index+1;
          }
          for(const ele of groupedData[obj.z]){
            if(obj===ele){obj.cycle_no= groupedData[obj.z].indexOf(ele)+1}}
        }
      return dataforselectedpoint;
    }
    function getObjectsByDateforev(array,selectedDate) {
      const objectsWithSelectedDate = array.filter(obj => {
        const date = obj.timestamp.split(' ')[0];
        // console.log('date',date)
        return date === selectedDate;
      });

      let dataforselectedpoint = []
      objectsWithSelectedDate.map(opts=>{let timestamp=opts.timestamp
      let val=opts.val.E_V
      let ts_ref_id = opts.ts_ref_id
      let event_id= opts.event_id
      dataforselectedpoint.push({x:timestamp,y:Number(val),z:ts_ref_id,therapy_no:null,cycle_no:null,event_id:event_id})
      })
      const recurringXValues = {};
      for (const obj of dataforselectedpoint) {
       const xValue = obj.x;
       if (!recurringXValues[xValue]) {
        recurringXValues[xValue] = 1;
      } else {
         recurringXValues[xValue]++;
      }
      }
      //  console.log('recurringXValues',recurringXValues)
       for (let i = 0; i < dataforselectedpoint.length; i++) {
        const currentX = dataforselectedpoint[i].x;
         if (recurringXValues[currentX] > 1) {
          const currentTime = moment(currentX).add(i, 'millisecond');
          dataforselectedpoint[i].x = currentTime.format('YYYY-MM-DD HH:mm:ss.SSS');
          }
          }
       const groupedData = {};

        for (const obj of dataforselectedpoint) {
        const zValue = obj.z;
        if (!groupedData[zValue]) {
         groupedData[zValue] = [];
          }
        groupedData[zValue].push(obj);
        }
        const keys = Object.keys(groupedData)
        for (const obj of dataforselectedpoint) {
          const index = keys.indexOf(obj.z);
          if (index !== -1) {
             obj.therapy_no = index+1;
          }
          for(const ele of groupedData[obj.z]){
            if(obj===ele){obj.cycle_no= groupedData[obj.z].indexOf(ele)+1}}
        }
      return dataforselectedpoint;
    }
    const handleDataPointSelection = (dataPointIndex) => {
      // console.log('handleDataPointSelection',dataPointIndex)
      let selectedDate = categories[dataPointIndex];
      let singledaydata1 = getObjectsByDate(props.graphData,selectedDate)
      setDate1(moment(selectedDate).format('DD MMM YYYY'))
      setsingledaydata(singledaydata1)
      setClickedDataPoint(true)
    };
    const handleDataPointSelectionforpip = (dataPointIndex) => {
      console.log('alert')
      // console.log('handleDataPointSelectionforpip',dataPointIndex)
      let selectedDate = categories[dataPointIndex];
      let singledaydata1 = getObjectsByDateforpip(props.graphData,selectedDate)
      setDate2(moment(selectedDate).format('DD MMM YYYY'));
      setsingledaydataforpip(singledaydata1)
      setClickedDataPointforpip(true)
    };
    const handleDataPointSelectionforev = (dataPointIndex) => {
      // console.log('handleDataPointSelectionforev',dataPointIndex)
      let selectedDate = categories[dataPointIndex];
      let singledaydata1 = getObjectsByDateforev(props.graphData,selectedDate)
      setDate3(moment(selectedDate).format('DD MMM YYYY'));
      setsingledaydataforev(singledaydata1)
      setClickedDataPointforev(true)
    };
    
    // console.log('singledaydata',singledaydata,'SINGLEDAYDATAFORPIP',singledaydataforpip,'singledaydataforev',singledaydataforev)
  const series = [
    {
      name:'Peak Expiratory Flow',
      data:p_f
    },  
  ];
  const series2 = [{
    name: "Peak Inspiratory Pressure",
    data: p_ip,
  },]
  const series3 = [{
    name: "Expiratory Volume",
    data: e_v,
  },]
  const options = {
    chart: {
      height: 300,
      // width:300,
      // type: "line",
      events: {
        dataPointSelection(event, chartContext, config) {
            handleDataPointSelection(config.dataPointIndex)
            
        },
        dataPointMouseEnter: function(event) {
          event.target.style.cursor = "pointer";
        },
        dataPointMouseLeave:function(event){
          event.target.style.cursor = "none";
        }
    
      },
      animations: {
        enabled: false
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      }
    },

    dataLabels: {
      enabled: false,
    },

    xaxis: {
      type: "categories",
      categories: categories,
      tooltip:{
        enabled:false
      },
      axisBorder: {
        show: true
      },
      tickPlacement: 'on',
      tickAmount: 15,
      labels:{
        // format: 'dd MMM yyyy',
        style:{fontSize:'13px'},
        formatter: function (val) {
          return moment(val).format('DD MMM')
        }
      },
      title: {
        text: "Time",
        style: {
          color: "#113ef5",
          fontSize:'13px'
          
        }
      }
    },
    yaxis: [
      {
        show: true,
        labels:{
          style:{fontSize:'13px'},
          formatter: function (value) {
            return parseInt(value);
          },
        },
        axisBorder: {
          show: true,
          color: "#113ef5"
        },
        title: {
          text: "Peak Exp. Flow (lpm)",
          style: {
            color: "#113ef5",
            fontSize:'13px'
          }
        }
      },
    ],

    tooltip: {
      intersect: true,
      shared: false,
       
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          if(!days[dataPointIndex].cycles_count){
            return null
          }
          // console.log('SE',series,seriesIndex,dataPointIndex)
        let val1 = series[0][dataPointIndex]?`<p style="color:blue;">Avg. Peak Exp. Flow:${series[0][dataPointIndex]}</p>`:'';
        // let val2 = series[1][dataPointIndex]?`<p style="color:darkgreen">Average Expiratory Volume:  ${series[1][dataPointIndex]}</p>`:''
        return (
          '<div class="arrow_box" style="padding:8px;"}}>'+
          '<p">'+`${moment(categories[dataPointIndex]).format('DD MMM YYYY')}`+'</p>'+
          '<p>'+'Cycles count:  '+days[dataPointIndex].cycles_count+'</p>'+
          val1+
          // val2+
          "</div>"
        );
      }, 
    },
    grid: {
      show: true,
      padding:{
        // right:30,
      }
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      // colors: [ "#00ff00","#c23c04",],
      width: 2,
      dashArray: 0,
    },
    
    markers: {
      size: 3,
      colors: "#113ef5",
      strokeColors: "#113ef5",
      strokeWidth: 1,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: false,
      hover: {
        sizeOffset: 3
      }
    },
  };
  const options2 = {
    chart: {
      height: 300,
      // width:300,
      // type: "line",
      animations: {
        enabled: false
      },
      events: {
        dataPointSelection(event, chartContext, config) {
            handleDataPointSelectionforpip(config.dataPointIndex)
            
        },
        dataPointMouseEnter: function(event) {
          event.target.style.cursor = "pointer";
        },
        dataPointMouseLeave:function(event){
          event.target.style.cursor = "none";
        }
    
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      }
    },

    dataLabels: {
      enabled: false,
    },

    // colors: ["#c23c04", "#fff","#ccc"],
    xaxis: {
      type: "categories",
      categories: categories,
      tooltip:{
        enabled:false
      },
      tickPlacement: 'on',
      tickAmount: 15,
      labels:{
        // format: 'dd MMM yyyy',
        style:{fontSize:'13px'},
        formatter: function (val) {
          return moment(val).format('DD MMM')
        }
      },
      axisBorder: {
        show: true
      },
      title: {
        text: "Time",
        style: {
          color: "#f26c33",
          fontSize:'13px'
        }
       },
      },
    yaxis: [
      {
        show: true,
        // max: 100,
        labels: {
          formatter: function (value) {
            return parseInt(value);
          },
          style:{fontSize:'13px'}
        },
        axisBorder: {
          show: true,
          color: "#f26c33"
        },
        
        title: {
          text: "PIP (cmH\u2082O)",
          style: {
            color: "#f26c33",
            fontSize:'13px'
          }
        }
      },
    ],

    tooltip: {
      intersect:true,
      shared:false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          if(!days[dataPointIndex].cycles_count){
            return null
          }
        return (
          '<div class="arrow_box" style="padding:8px;"}}>'+
          '<p">'+`${moment(categories[dataPointIndex]).format('DD MMM YYYY')}`+'</p>'+
          '<p>'+'Cycles count: '+days[dataPointIndex].cycles_count+'</p>'+
          '<p style="color:#f26c33;">'+'Avg. PIP: '+series[0][dataPointIndex]+'</p>'+
          "</div>"
        );
      }, 
    },
    grid: {
      show: true,
      padding:{
        // right:30
      }
    },
    stroke: {
      show: true,
      curve: "smooth",
      // lineCap: "butt",
      colors: ["#f26c33"],
      width: 2,
      // dashArray: 0,
    },
    markers: {
      size: 3,
      colors: '#f26c33',
      strokeColors: '#f26c33',
      strokeWidth: 1,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: false,
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    },
  };
  const options3 = {
    chart: {
      height: 300,
      // width:300,
      // type: "line",
      animations: {
        enabled: false
      },
      events: {
        dataPointSelection(event, chartContext, config) {
            handleDataPointSelectionforev(config.dataPointIndex)
            
        },
        dataPointMouseEnter: function(event) {
          event.target.style.cursor = "pointer";
        },
        dataPointMouseLeave:function(event){
          event.target.style.cursor = "none";
        }
    
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      }
    },

    dataLabels: {
      enabled: false,
    },

    // colors: ["#c23c04", "#fff","#ccc"],
    xaxis: {
      type: "categories",
      categories: categories,
      tooltip:{
        enabled:false
      },
      tickPlacement: 'on',
      tickAmount: 15,
      labels:{
        style:{fontSize:'13px'},
        // format: 'dd MMM yyyy',
        formatter: function (val) {
          return moment(val).format('DD MMM')
        }
      },
      axisBorder: {
        show: true
      },
      title: {
        text: "Time",
        style: {
          color: "darkgreen",
          fontSize:'13px'
        },
      
      }
    },
    yaxis: [
      {
        show: true,
        // max: 100,
        labels: {
          style:{fontSize:'13px'},
          formatter: function (value) {
            return parseInt(value);
          },
          
        },
        axisBorder: {
          show: true,
          color: "darkgreen"
        },
        title: {
          text: "Exp. Volume (ml)",
          style: {
            color: "darkgreen",
            fontSize:'13px'
          }
        }
      },
    ],

    tooltip: {
      intersect:true,
      shared:false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          if(!days[dataPointIndex].cycles_count){
            return null
          }
        return (
          '<div class="arrow_box" style="padding:8px;"}}>'+
          '<p">'+`${moment(categories[dataPointIndex]).format('DD MMM YYYY')}`+'</p>'+
          '<p>'+'Cycles count: '+days[dataPointIndex].cycles_count+'</p>'+
          '<p style="color:darkgreen;">'+'Avg. Exp. Volume: '+series[0][dataPointIndex]+'</p>'+
          "</div>"
        );
      }, 
    },
    grid: {
      show: true,
      padding:{
        // right:40
      }
    },
    stroke: {
      show: true,
      curve: "smooth",
      // lineCap: "butt",
      colors: ["darkgreen"],
      width: 2,
      // dashArray: 0,
    },
    markers: {
      size: 3,
      colors: 'darkgreen',
      strokeColors: 'darkgreen',
      strokeWidth: 1,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: false,
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    },
  }
  const seriesforselecteddata = [
    {
      name:'Peak Expiratory Flow',
      data:singledaydata
     
    },  
  ];
  const seriesforselecteddatapip = [
    {
      name:'Peak Inspiratory Pressure',
      data:singledaydataforpip
     
    },  
  ];
  const seriesforselecteddataev = [
    {
      name:"Expiratory Volume",
      data:singledaydataforev
     
    },  
  ];
  const optionsforselecteddata = {
    chart: {
      height: 300,
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      }
    },

    dataLabels: {
      enabled: false,
    },

    // colors: ["#c23c04", "#fff","#ccc"],
    // stroke: {
    //   curve: "smooth",
    // },

    xaxis: {
      type: "categories",
    //   categories: categories,
      // min:new Date(props.dates.from).getTime(),
      // max:new Date(props.dates.to).getTime(),
      tooltip:{
        enabled:false
      },
      axisBorder: {
        show: true
      },
      tickPlacement: 'on',
      tickAmount: 15,
      labels:{
        // format: 'dd MMM yyyy',
        style:{fontSize:'13px'},
        formatter: function (val) {
          return moment(val).format('HH:mm')
        }
      },
      title: {
        text: "Time",
        style: {
          color: "#113ef5",
          fontSize:'13px'
          
        }
      }
    },
    yaxis: [
      {
        show: true,
        // axisTicks: {
        //   show: true
        // },
        labels:{
          style:{fontSize:'13px'},
          // formatter: function (value) {
          //   return parseInt(value);
          // },
        },
        axisBorder: {
          show: true,
          color: "#113ef5"
        },
        title: {
          text: "Peak Exp. Flow (lpm)",
          style: {
            color: "#113ef5",
            fontSize:'13px'
          }
        }
      },
    ],
    tooltip: {
       
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          // console.log('SE',series,seriesIndex,dataPointIndex)
        let val1 = singledaydata[dataPointIndex]?`<p style="color:blue;">Peak Exp. Flow:${singledaydata[dataPointIndex].y}</p>`:'';
        // let val2 = series[1][dataPointIndex]?`<p style="color:darkgreen">Average Expiratory Volume:  ${series[1][dataPointIndex]}</p>`:''
        return (
          '<div class="arrow_box" style="padding:8px;"}}>'+
          '<p">'+`Therapy ${singledaydata[dataPointIndex].therapy_no} Cycle ${singledaydata[dataPointIndex].cycle_no}`+'</p>'+
          '<p">'+`${moment(singledaydata[dataPointIndex]?.x).format('DD MMM YYYY HH:mm')}`+'</p>'+
          // '<p>'+'Cycles count:  '+days[dataPointIndex].cycles_count+'</p>'+
          val1+
          // val2+
          "</div>"
        );
      }, 
    },
    grid: {
      show: true,
      padding:{
        // right:30,
      }
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      // colors: [ "#00ff00","#c23c04",],
      width: 2,
      dashArray: 0,
    },
    
    markers: {
      size: 3,
      colors: "#113ef5",
      strokeColors: "#113ef5",
      strokeWidth: 1,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: false,
      hover: {
        size: undefined,
        sizeOffset: 1
      }
    },
  };
  const optionsforselecteddatapip = {
    chart: {
      height: 300,
      // width:300,
      // type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      }
    },

    dataLabels: {
      enabled: false,
    },

    // colors: ["#c23c04", "#fff","#ccc"],
    xaxis: {
      type: "categories",
      // categories: categories,
      // min:new Date(props.dates.from).getTime(),
      // max:new Date(props.dates.to).getTime(),
      tooltip:{
        enabled:false
      },
      tickPlacement: 'on',
      tickAmount: 15,
      labels:{
        // format: 'dd MMM yyyy',
        style:{fontSize:'13px'},
        formatter: function (val) {
          return moment(val).format('HH:mm')
        }
      },
      axisBorder: {
        show: true
      },
      title: {
        text: "Time",
        style: {
          color: "#f26c33",
          fontSize:'13px'
        }
       },
      },
    yaxis: [
      {
        show: true,
        // max: 100,
        labels: {
          style:{fontSize:'13px'}
        },
        axisBorder: {
          show: true,
          color: "#f26c33"
        },
        
        title: {
          text: "PIP (cmH\u2082O)",
          style: {
            color: "#f26c33",
            fontSize:'13px'
          }
        }
      },
    ],

    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // console.log('SE',series,seriesIndex,dataPointIndex)
      let val1 = singledaydataforpip[dataPointIndex]?`<p style="color:#f26c33;">PIP: ${singledaydataforpip[dataPointIndex].y}</p>`:'';
      // let val2 = series[1][dataPointIndex]?`<p style="color:darkgreen">Average Expiratory Volume:  ${series[1][dataPointIndex]}</p>`:''
      return (
        '<div class="arrow_box" style="padding:8px;"}}>'+
        '<p">'+`Therapy ${singledaydataforpip[dataPointIndex].therapy_no} Cycle ${singledaydataforpip[dataPointIndex].cycle_no}`+'</p>'+
        '<p">'+`${moment(singledaydataforpip[dataPointIndex]?.x).format('DD MMM YYYY HH:mm')}`+'</p>'+
        // '<p>'+'Cycles count:  '+days[dataPointIndex].cycles_count+'</p>'+
        val1+
        // val2+
        "</div>"
      );
    }, 
    },
    grid: {
      show: true,
      padding:{
        // right:30
      }
    },
    stroke: {
      show: true,
      curve: "smooth",
      // lineCap: "butt",
      colors: ["#f26c33"],
      width: 2,
      // dashArray: 0,
    },
    markers: {
      size: 3,
      colors: '#f26c33',
      strokeColors: '#f26c33',
      strokeWidth: 1,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined ,
      onDblClick: undefined,
      showNullDataPoints: false,
      hover: {
        size: undefined,
        sizeOffset: 1
      }
    },
  }
  const optionsforselecteddataev = {
    chart: {
      height: 300,
      // width:300,
      // type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      }
    },

    dataLabels: {
      enabled: false,
    },

    // colors: ["#c23c04", "#fff","#ccc"],
    xaxis: {
      type: "categories",
      // categories: categories,
      // min:new Date(props.dates.from).getTime(),
      // max:new Date(props.dates.to).getTime(),
      tooltip:{
        enabled:false
      },
      tickPlacement: 'on',
      tickAmount: 15,
      labels:{
        style:{fontSize:'13px'},
        // format: 'dd MMM yyyy',
        formatter: function (val) {
          return moment(val).format('HH:mm')
        }
      },
      axisBorder: {
        show: true
      },
      title: {
        text: "Time",
        style: {
          color: "darkgreen",
          fontSize:'13px'
        },
      
      }
    },
    yaxis: [
      {
        show: true,
        // max: 100,
        labels: {
          style:{fontSize:'13px'},
          formatter: function (value) {
            return parseInt(value);
          },
          
        },
        axisBorder: {
          show: true,
          color: "darkgreen"
        },
        title: {
          text: "Exp. Volume (ml)",
          style: {
            color: "darkgreen",
            fontSize:'13px'
          }
        }
      },
    ],

    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // console.log('SE',series,seriesIndex,dataPointIndex)
      let val1 = singledaydataforev[dataPointIndex]?`<p style="color:darkgreen">Exp. Volume: ${singledaydataforev[dataPointIndex].y}</p>`:'';
      // let val2 = series[1][dataPointIndex]?`<p style="color:darkgreen">Average Expiratory Volume:  ${series[1][dataPointIndex]}</p>`:''
      return (
        '<div class="arrow_box" style="padding:8px;"}}>'+
        '<p">'+`Therapy ${singledaydataforev[dataPointIndex].therapy_no} Cycle ${singledaydataforev[dataPointIndex].cycle_no}`+'</p>'+
        '<p">'+`${moment(singledaydataforev[dataPointIndex]?.x).format('DD MMM YYYY HH:mm')}`+'</p>'+
        // '<p>'+'Cycles count:  '+days[dataPointIndex].cycles_count+'</p>'+
        val1+
        // val2+
        "</div>"
      );
    }, 
    },
    grid: {
      show: true,
      padding:{
        // right:40
      }
    },
    stroke: {
      show: true,
      curve: "smooth",
      // lineCap: "butt",
      colors: ["darkgreen"],
      width: 2,
      // dashArray: 0,
    },
    markers: {
      size: 3,
      colors: 'darkgreen',
      strokeColors: 'darkgreen',
      strokeWidth: 1,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: false,
      hover: {
        size: undefined,
        sizeOffset: 1
      }
    },
  }

  if(!(props.graphData&&props.dates)){
    return <div>
        {/* <ReactApexChart
          options={options}
          // series={series}
          type="line"
          height={350}
        /> */}
      </div >
  }
  else{
    return (
      <div>
        <div className="trend-graph-heading">
          Peak Expiratory Flow(Peak Exp. Flow)
          </div>
        <div style={{paddingLeft:'10px'}}>
          {clickedDataPoint?
          <div>
             <div style={{display:'flex',justifyContent:"flex-end" ,paddingRight:'60px'}}>
              <div>
                <div style={{cursor:"pointer"}} onClick={handleclosedrilldown}><KeyboardBackspaceIcon /> BACK</div>
                <div>{date1}</div>
                </div>
                </div>
                  <ReactApexChart
                   options={optionsforselecteddata}
                   series={seriesforselecteddata}
                   type="line"
                   height={300}
                  // width={'98%'}
                  />
                  </div>
                  : 
                  <ReactApexChart
                 options={options}
                 series={series}
                 type="line"
                height={300}
                // width={'98%'}
                />
                }
                
        
        </div>
        <div className="trend-graph-heading">
          Expiratory Volume(Exp. Volume)
          </div>
          <div 
          style={{paddingLeft:'5px'}}
          >
            {clickedDataPointforev?
             <div>
             <div style={{display:'flex',justifyContent:"flex-end" ,paddingRight:'60px'}}>
             <div>
               <div onClick={handleclosedrilldownforev} style={{cursor:"pointer"}}>
               <KeyboardBackspaceIcon /> BACK
               </div>
               <div>{date3}</div>
               </div>
               </div>
            <ReactApexChart
            options={optionsforselecteddataev}
            series={seriesforselecteddataev}
            type="line"
            height={300}
            // width={'98%'}
          />
          </div>
          
          :
          <ReactApexChart
          options={options3}
          series={series3}
          type="line"
          height={300}
          // width={'98%'}
        />
            }
        </div>
        <div className="trend-graph-heading">
          Peak Inspiratory Pressure(PIP)
          </div>
          <div style={{paddingLeft:'10px'}}>
          {clickedDataPointforpip?
          <div>
             <div style={{display:'flex',justifyContent:"flex-end" ,paddingRight:'60px'}}>
             <div>
                <div onClick={handleclosedrilldownforpip} style={{cursor:"pointer"}}><KeyboardBackspaceIcon />BACK</div>
                <div>{date2}</div>
                </div>
                </div>
                  <ReactApexChart
                   options={optionsforselecteddatapip}
                   series={seriesforselecteddatapip}
                   type="line"
                   height={300}
                  // width={'98%'}
                  />
                  </div>
                  : 
                  <div>
                 
                  <ReactApexChart
                 options={options2}
                 series={series2}
                 type="line"
                height={300}
                // width={'98%'}
                />
                </div>
              }
        </div>
      </div >
    );
  }
};
export default DeliveredParametersGraph;