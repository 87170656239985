import React, { useEffect } from 'react';
import './Popup.css';
import EnterEmail from './EnterEmail';
import { connect } from "react-redux";
import { passwrdVerify, otpFlag } from "../../redux/user/user.action";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import InputField from "../../components/Inputs_Validation/index";

const Popup = (props) => {
  const [password, setPassword] = React.useState('');
  const [confirmAccount, setConfirmAccount] = React.useState(true);
  const [emailInputDiv, setEmailInputDiv] = React.useState(false);
  const [incorrectMsgDiv, setIncorrectMsgDiv] = React.useState(false)

  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [openErrorSnack, setOpenErrorSnack] = React.useState(false);
  const [values, setValues] = React.useState({});
  const [passHide, setPassHide] = React.useState(false);
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  useEffect(() => {
    if (props.passwrdResposne == "Correct Paswword") {
      setOpenSnack(false);
      setSnackMsg("your profile is updated Successfully");
      setSnackSeverity("success");
      // setIsResetClicked(false)
    } else if (props.passwrdResposne == "Incorrect password") {
      setOpenErrorSnack(true);
      setSnackMsg("Something went wrong.Try again later");
      setSnackSeverity("error");
    }
    else if (props.passwrdResposne == "Password required") {
      setOpenErrorSnack(true);
      setSnackMsg("Password is required");
      setSnackSeverity("error");
    }
  }, [props.passwrdResposne]);

  useEffect(() => {
    if (props.passwrdResposne == "Correct Paswword") {
      // setConfirmAccount(false);
      setIncorrectMsgDiv(false);
    } else if (props.passwrdResposne == "Incorrect password" || props.passwrdResposne == "Password required") {
      setConfirmAccount(true);
      setIncorrectMsgDiv(true);
    }
  }, [props.passwrdResposne]);

  const emailpopup = () => {
    props.passwrdVerify(password, props.id);
    setEmailInputDiv(true);
  }
  useEffect(() => {
    if (props.passwrdResposne === "Correct Paswword") {
        setIncorrectMsgDiv(false);
        setOpenSnack(false);
    } else if (props.passwrdResposne === "Incorrect password") {
    setSnackMsg("Incorrect Password! Please check your password and try again.")
    setSnackSeverity('error')
    setOpenSnack(true)
    }
    //setTimeout(()=>{setIncorrectMsgDiv(false)},3000);
}, [props.passwrdResposne]);
  const oldPasswordCheck = () => {
    console.log('here in oldPasswordCheck',values)
    if(!values.oldpassword)
    {
    setSnackMsg("Please enter your password to continue!")
    setSnackSeverity('error')
    setOpenSnack(true)
    }
    else{
    if(!(values.oldpassword.length<6)){
      props.passwrdVerify(values.oldpassword, props.id);
      setEmailInputDiv(true);
    
        }
    }
    }
const handleInputChange = (name, value) => {
      setValues({
        ...values,
        [name]: value,
      });
  };
  const inputRefs = React.useRef([
        React.createRef(),
        React.createRef(),
        React.createRef(),
      ]);

  const [phone, setPhone] = React.useState(false);
  const phonepopup = () => {
    setPhone(true);
  }

  const closeEmailDiv = () => {
    setEmailInputDiv(false);
  }

  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  const changeHandler = () => {
    setIncorrectMsgDiv(false);
    props.otpFlag(false)
  }
  return (
    <>

      <AlertSnackBar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cupSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />

      <div className="PopuupContainer">
        {confirmAccount ?
          <div>
            <h1 className="cnfrmac">
              <i className="fa fa-user-circle confirmaccount"></i><span>Confirm your account password</span>
            </h1>
            {/* <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="inputfield">
                <input
                  type={passHide ? "text" : "password"}
                  className="input-popup"
                  validation="required|emailpattern"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onClick={changeHandler}
                />
                {props.passwrdResposne == "Incorrect password" && incorrectMsgDiv ?
                  <div>
                    <p className="alertmsg">Incorrect Password</p>
                  </div>
                  : null}
                {props.passwrdResposne == "Password required" && incorrectMsgDiv ?
                  <div>
                    <p className="alertmsg">Required</p>
                  </div>
                  : null}
                  {password && password.length <6  && incorrectMsgDiv  ?
                  <div>
                    <p className="alertmsg">Must be at least 6 characters</p>
                  </div>
                  : null}

                <div className='buttonEyeIocnForChnagePassword'>
                  <button
                    onClick={() => setPassHide(!passHide)}
                    style={{ marginTop: (props.passwrdResposne == "Incorrect password"||props.passwrdResposne == "Password required"||password && password.length <6) && incorrectMsgDiv ? "-30px" : "0px" }}
                  >
                    {
                      passHide ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash"></i>
                    }
                  </button>
                </div>
              </div>
              <div className="buttonbutnPopup"  onClick={props.popover == false ? emailpopup : phonepopup}>
                {
                  props.passwrdResposne == "Correct Paswword" && emailInputDiv ?
                    <button className="btnpopupInactive" style={{ outline: "none" }}>
                      Next
                    </button>
                    : <button className="btnpopup" disabled={password && password.length <6} onClick={emailpopup} style={{ outline: "none" }}>
                      Next
                    </button>}
              </div>
            </div> */}
            <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className="inputfield">
                                <InputField
                                    type={"password"}
                                    className="inputfiedforchangepwd fnt24P"
                                    ref={inputRefs.current[0]}
                                    handleInputChange={handleInputChange}
                                    placeholder="Password"
                                    validation="required|min:6"
                                    name="oldpassword"
                                    isPassword={true}
                                    feildinchangepwd={true}
                                />

                                {/* {incorrectMsgDiv && props.passwrdResposne === "Incorrect password" ?
                                    <div>
                                        <p className="alertmsg">Incorrect Password</p>
                                    </div>
                                    :
                                    null
                                } */}
                                {/* <div className='buttonEyeIocnForChnagePassword'>
                                    <button
                                        onClick={() => setPassHide(!passHide)}
                                        style={{ marginTop: incorrectMsgDiv && props.passwrdResposne === "Incorrect password" ? "-51px" : "0px" }}
                                    >
                                        {
                                            passHide ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash"></i>
                                        }
                                    </button>
                                </div> */}
                            </div>
                           <div className='buttonbutn'> {props.passwrdResposne == "Correct Paswword" && emailInputDiv ?
                    <button className="btnpopupInactive" style={{ outline: "none" }}>
                      Next
                    </button>
                    : <button className="btnpopup" onClick={oldPasswordCheck} style={{ outline: "none" }}>
                      Next
                    </button>}</div>
                        </div>
          </div>
          : null}
        {
          props.passwrdResposne == "Correct Paswword" && emailInputDiv ?
            <EnterEmail email={props.popover} onMobileClose={props.onMobileClose} phone={props.popoveremail} close={props.close} closeEmailDiv={closeEmailDiv} />
            : null
        }
      </div>

    </>
  )
}

const mapStateToProps = (store) => {
  return {
    id: store.user.user && store.user.user.id,
    passwrdResposne: store.user.passwrdResposne,
  };
};

export default connect(mapStateToProps, { passwrdVerify, otpFlag })(Popup);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AlertSnackBar = ({
  flag,
  onClose,
  anchorOrigin,
  msg,
  severity,
}) => {
  return (
    <Snackbar
      anchorOrigin={
        anchorOrigin ? anchorOrigin : { vertical: "top", horizontal: "right" }
      }
      open={flag}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity ? severity : "success"}>
        {msg}
      </Alert>
    </Snackbar>
  );
};