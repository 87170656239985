import { CARE_SITE_DETAILS_FETCH } from "../action.list";

import apiAbmBlaze, { CARE_SITE_DETAILS } from "../../api";

export const getCareSiteDetailsById = (val) => async (dispatch) => {
  dispatch({
    type: CARE_SITE_DETAILS_FETCH,
    payload: { caresitedetails: [] },
  });
  apiAbmBlaze
    .post(CARE_SITE_DETAILS, { clinicID: val })
    .then(async (res) => {
      if (res.data) {
        dispatch({
          type: CARE_SITE_DETAILS_FETCH,
          payload: { caresitedetails: res.data.val },
        });
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
    });
};
