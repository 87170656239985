import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { reSet } from "../../redux/invitation/components/invitation.action";
import "./confirmInvitation.css";
function InvitationConfirmPopup(props) {
  const handleClose = () => {
    props.callBack();
    props.callBackConfirmOtp(false);
    props.reSet();
    props.valReset();
  };
  const handleClosePopup = () => {
    props.callBackConfirmOtp(false);
    props.reSet();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="background-img-confirm">
          <DialogTitle id="alert-dialog-title">
            <div className="inActiveHedingTitle">Confirmation</div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="SubTitleforInactive">
                Are you sure you want to cancel? 
              </div>
            </DialogContentText>
          </DialogContent>

          <div className="bottomContainerForAlert">
            <Button onClick={handleClosePopup} className="cancelButtonAlert">
              No
            </Button>
            <div className="vl"></div>
            <Button onClick={handleClose} className="cancelButtonAlert">
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (store) => {
  return {};
};

export default connect(mapStateToProps, { reSet })(InvitationConfirmPopup);
