import React, { useEffect } from "react";
import './MedicalInformation.css';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import { connect, useSelector } from "react-redux";
import {reportsSearchUser} from "../../redux/user/user.action";
const MedicalInformation = (props) => {
  const [close, setClose] = React.useState(true);
  const patientDetails = useSelector((store) => {
    return store.user.navFromReportSearchToPatientProfile;
  });

  // fetching the measuring value from local storage
  var fetchedUnit = localStorage.getItem("mu");

  //height conversion 
  if (patientDetails && patientDetails.condition && patientDetails.condition.height) {
    //height conversion using division (converting inches to ft and inch in case of imperial)
    var ft = parseInt(patientDetails.condition.height / 12);
    var inch = (patientDetails.condition.height % 12);

    //height conversion using division (converting inches to cm in case of metric)
    var cm = Math.round(patientDetails.condition.height * 2.54);
  }

  //weight conversion (converting lbs to kg in case of metric)
  if (patientDetails && patientDetails.condition && patientDetails.condition.weight) {
    var kg = Math.round(patientDetails.condition.weight * 0.454);
  }


  return (
    <div >
      <Dialog className="dialog-permed"
        maxWidth={"md"}
        fullWidth
        open={props.isopen}
        onClose={props.callBack}>
        <div className="containers_medic">
          <div className="medical-response">
            <div className="Medical-title">Health Summary</div>
            <div className="iconForCancel">
              <CloseIcon onClick={props.callBack} />
            </div>
          </div>

          <div className="medic-back">
            <div className="MedicalConditionInput">
              <div className="flex flex-wrap mt-3">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-md  mb-2"
                      style={{ color: '#6a768f' }}
                    >

                      Height
                    </label>

                    <div className="GenMedicalInfoDivs font-bold">

                      {fetchedUnit === 'Metric' && patientDetails && patientDetails.condition && patientDetails.condition.height ? `${cm} cm` :
                        patientDetails && patientDetails.condition && patientDetails.condition.height ? `${ft} ft  ${inch} inch ` : "-"}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-md  mb-2 "
                      style={{ color: '#6a768f' }}
                    >
                      Weight
                    </label>

                    <div className="GenMedicalInfoDivs font-bold">
                      {fetchedUnit === 'Metric' && patientDetails && patientDetails.condition && patientDetails.condition.weight ? `${kg} kg` :
                        patientDetails && patientDetails.condition && patientDetails.condition.weight ? `${patientDetails.condition.weight} lbs` : "-"}
                    </div>
                  </div>
                </div>
              </div>



              <div className="flex flex-wrap mt-3">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-md mb-2"
                      style={{ color: '#6a768f' }}
                    >

                      Gender
                    </label>

                    <div className="GenMedicalInfoDivs font-bold">
                      {patientDetails && patientDetails.condition && patientDetails.condition.gender ? patientDetails.condition.gender : "-"}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-md  mb-2 "
                      style={{ color: '#6a768f' }}
                    >
                      Blood type
                    </label>

                    <div className="GenMedicalInfoDivs font-bold">
                      {patientDetails && patientDetails.condition && patientDetails.condition.blood_type ? patientDetails.condition.blood_type : "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <div className="w-full lg:w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-md  mb-2"
                      style={{ color: '#6a768f' }}
                    >

                      Diagnosis 1
                    </label>

                    <div className="GenMedicalInfoDivs font-bold">
                      {patientDetails && patientDetails.condition && patientDetails.condition.existing_conditions && patientDetails.condition.existing_conditions.diagnoise1 ? patientDetails.condition.existing_conditions.diagnoise1 : "-"}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-md  mb-2 "
                      style={{ color: '#6a768f' }}
                    >
                      Diagnosis 2
                    </label>

                    <div className="GenMedicalInfoDivs font-bold">
                      {patientDetails && patientDetails.condition && patientDetails.condition.existing_conditions && patientDetails.condition.existing_conditions.diagnoise2 ? patientDetails.condition.existing_conditions.diagnoise2 : "-"}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-md  mb-2 "
                      style={{ color: '#6a768f' }}
                    >
                      Diagnosis 3
                    </label>

                    <div className="GenMedicalInfoDivs font-bold">
                      {patientDetails && patientDetails.condition && patientDetails.condition.existing_conditions && patientDetails.condition.existing_conditions.diagnoise3 ? patientDetails.condition.existing_conditions.diagnoise3 : "-"}
                    </div>
                  </div>
                </div>

              </div>
              <div className="flex flex-wrap mt-3">
                <div className="w-full lg:w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-md mb-2"
                      style={{ color: '#6a768f' }}
                    >

                      Allergies 1
                    </label>

                    <div className="GenMedicalInfoDivs font-bold">
                      {patientDetails && patientDetails.condition && patientDetails.condition.allergies && patientDetails.condition.allergies.alergies1 ? patientDetails.condition.allergies.alergies1 : "-"}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-md  mb-2 "
                      style={{ color: '#6a768f' }}
                    >
                      Allergies 2
                    </label>
                    <div className="GenMedicalInfoDivs font-bold">
                      {patientDetails && patientDetails.condition && patientDetails.condition.allergies && patientDetails.condition.allergies.alergies2 ? patientDetails.condition.allergies.alergies2 : "-"}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-blueGray-600 text-md  mb-2 "
                      style={{ color: '#6a768f' }}
                    >
                      Allergies 3
                    </label>

                    <div className="GenMedicalInfoDivs font-bold">
                      {patientDetails && patientDetails.condition && patientDetails.condition.allergies && patientDetails.condition.allergies.alergies3 ? patientDetails.condition.allergies.alergies3 : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (store) => {
  return {
    particularUserReportSearchVal : store.user.particularUserReportSearchVal,
  };
};
export default connect(mapStateToProps, {reportsSearchUser}) (MedicalInformation);