import React, { useState, useEffect } from "react";
import apiAbmBlaze from '../../api';
import Button from "@material-ui/core/Button";
import InputField from "../../components/Inputs_Validation";
import "./CardClinicProfile.css";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CustomsSnackbar from "../../components/Alertmessage/Snackbar";
// components
import { connect } from "react-redux";
import {
  fetchInvitation,
  updateProfile,
  updateClinicProile,
  updateClinicUserSnckMsg,
} from "../../redux/invitation/components/invitation.action";
import { } from "../../redux/user/user.action";
import PhoneInput from "react-phone-input-2";
import Box from '@mui/material/Box';
import LoadingIcon from "../LoadingIcon";
import '../../assets/styles/font.css';
import { useDispatch } from "react-redux";
import { pageHeading } from "../../redux/user/user.action";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { alphabetsOnlyWithSpace } from "../../utils";
const CardClinicProfile = (props) => {

  const inputRefs = React.useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const [values, setValues] = React.useState({});
  const [contries, setCountry] = React.useState(["United States"]);
  const [state, setState] = React.useState({});
  //snackbar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [phoneValue, setPhoneValue] = React.useState(
    props.clinicItemData && props.clinicItemData.phonenumber
      ? props.clinicItemData.phonenumber
      : "+910000000000"
  );
  const [customeSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });
  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  const [snackErrorMessage, setSnackErrorMessage] = React.useState();
  const [openErrorSnack, setOpenErrorSnack] = React.useState(false);
  const [stateError, setStateError] = React.useState({val:false,msg:'ok'})
  const [countryError, setCountryError] = React.useState({val:false,msg:'ok'})

  const [customeErrorSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });

  const [snackErrorSeverity, setSnackErrorSeverity] = React.useState("");
  const [editClickSaveButton, setEditClinicSaveButton] = React.useState(false);
  const [valid,setvalid]=React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.clinicItemData && props.clinicItemData.phonenumber) {
      setPhoneValue(props.clinicItemData.phonenumber);
    }
  }, [props.clinicItemData]);
  useEffect(()=>{
    countryData()
    stateData()
  },[])

  useEffect(() => {
    dispatch(pageHeading("Care Site Profile"));
  })
  const handleErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  function countryData() {
    apiAbmBlaze.get('js/country.json').then((response) => {
      setCountry(response.data.country)
    }).catch((err) => {
      console.log("err from country", err)
    })
  }
  function stateData() {
    apiAbmBlaze.get("/js/state.json").then((response) => {
      setState(response.data.state);
    })
  }
  console.log('props.clinicItemData',props.clinicItemData,editClickSaveButton)
  const checkValueExists = (value) => {
    return state.some((obj) => obj.name === value);
  };
  const matchKeys = () => {

    if(phoneValue !== props.clinicItemData.phonenumber){
        return false
    }
       else if(values.country !== props.clinicItemData?.address?.country){
        return false
       }
       else if(values.country ==='United States' && values.state !== props.clinicItemData?.address?.state){
        return false
       }
        else{
    const valueKeys = Object.keys(values);
    for (const key of valueKeys) {
      if (Object.keys(props.clinicItemData.address).includes(key)) {
         
          if (values[key] !== props.clinicItemData.address[key]) {
            return false;
          }

      } else {
        if (values[key] !== props.clinicItemData[key]) {
          return false;
        }
      }
    }
  
    return true;
  };
}
  const handleInputChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };
useEffect(()=>{
  setValues(prev=>{
    return {
      ...prev,
      ...props.clinicItemData.address,
      accountnumber:props.clinicItemData.accountnumber,
      website:props.clinicItemData.website,
    }
  })
},[props.clinicItemData])
  const onSubmit = async () => {
    setEditClinicSaveButton(true);
    let isValid = true;
    let incorrectstate= false;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if(countryError.val===true||stateError.val===true){isValid =false}
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (isValid === true) {
      const isValueExists = checkValueExists(values.state);
      if (values.country ==='United States' && !isValueExists ){incorrectstate=true;}
      console.log('values on submit',values)
      if (!incorrectstate){
      props.updateClinicProile({
        ...props.clinicItemData,
        ...values,
        phonenumber: phoneValue,
      });
    }
    else{
      setEditClinicSaveButton(false);
      setSnackMsg('Please select an appropriate state from the dropdown');
      setSnackSeverity('error')
      setOpenSnack(true);
    }
  }
    else{
      setEditClinicSaveButton(false);
      setOpenSnack(true);
      setSnackMsg('Please fix the inputs with errors');
      setSnackSeverity('error')
    }
  };
  console.log('props.clinicuserUpdateMsg',props.clinicuserUpdateMsg)
  useEffect(() => {
    if (props.clinicuserUpdateMsg) {
      console.log('here is changingqqqqqqqqqqqqq',editClickSaveButton)
      setOpenSnack(true);
      setEditClinicSaveButton(false);
      setSnackMsg("Clinic was updated successfully");
      setSnackSeverity("success");
    } else if (props.clinicuserErrorMsg) {
      setOpenErrorSnack(true);
      setSnackErrorMessage("Clinic was not updated. Please check the highlighted field and try again.");
      setSnackErrorSeverity("error");
    }
    return () => {
      props.updateClinicUserSnckMsg();
    }
  }, [props.clinicuserUpdateMsg, props.clinicuserErrorMsg]);
  useEffect(() => {
    const isMatch = matchKeys();
    setvalid(isMatch);
    // setCountryError({val:false,msg:'ok'})
    // setStateError({val:false,msg:'ok'})
  }, [values, props.clinicItemData,phoneValue]);
  return (
    <>
      {props.clinicItemData && props.clinicItemData.c_id ? (
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg   bg-blueGray-100 border-0 CardClinicProfileWrapper">
          <AlertSnackBar
            msg={snackMsg}
            flag={openSnack}
            onClose={handleSnackbar}
            key={"cusSnackbar"}
            anchorOrigin={customeSnackbarAnchor}
            severity={snackSeverity}
          />
          {/*   {openErrorSnack ? ( */}
          <CustomsSnackbar
            msg={snackErrorMessage}
            flag={openErrorSnack}
            onClose={handleErrorSnackbar}
            // key={"errorSnackbar"}
            anchorOrigin={customeErrorSnackbarAnchor}
            severity={snackErrorSeverity}
          />
          {/*  ) : null} */}
          <form>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0 bg-white formBackgroundforClinic">
              <div className="flex flex-wrap mt-3">
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block font-bold mb-2 clinicprofileLabel fnt24P"
                      htmlFor="grid-password"
                    >
                      Account Number
                    </label>
                    <InputField
                      type="text"
                      className="inputfeildsforallclinicProfile fnt21P"
                      ref={inputRefs.current[0]}
                      handleInputChange={handleInputChange}
                      validation="max:12|min:12"
                      name="accountnumber"
                      id="outlined-number"
                      label="Account number"
                      value={values.accountnumber}
                      defaultValue={
                        props.clinicItemData &&
                          props.clinicItemData.accountnumber
                          ? props.clinicItemData.accountnumber
                          : ""
                      }

                    />
                  </div>
                </div>
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block clinicprofileLabel text-blueGray-600 font-bold mb-2 fnt24P"
                      htmlFor="grid-password"
                    >
                      Care Site Name
                    </label>
                    <InputField
                      type="text"
                      className="inputfeildsforallclinicProfile fnt21P"
                      ref={inputRefs.current[1]}
                      handleInputChange={handleInputChange}
                      validation="required|min:6|alphabetsOnly|max:35"
                      placeholder=""
                      name="clinicname"
                      value={values.clinicname}
                      defaultValue={
                        props.clinicItemData && props.clinicItemData.clinicname
                          ? props.clinicItemData.clinicname
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap mt-3">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block text-blueGray-600 clinicprofileLabel font-bold mb-2 fnt24P"
                      htmlFor="grid-password"
                    >
                      Address line 1
                    </label>
                    <InputField
                      type="text"
                      className="inputfeildsforallclinicProfile fnt21P"
                      ref={inputRefs.current[2]}
                      handleInputChange={handleInputChange}
                      validation="max:50"
                      placeholder=""
                      name="addressline1"
                      value={values.addressline1}
                      defaultValue={
                        props.clinicItemData &&
                          props.clinicItemData.address &&
                          props.clinicItemData.address.addressline1
                          ? props.clinicItemData.address.addressline1
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block text-blueGray-600 clinicprofileLabel font-bold mb-2 fnt24P"
                      htmlFor="grid-password"
                    >
                      Address line 2
                    </label>
                    <InputField
                      type="text"
                      className="inputfeildsforallclinicProfile fnt21P"
                      ref={inputRefs.current[3]}
                      handleInputChange={handleInputChange}
                      validation="max:50"
                      placeholder=""
                      name="addressline2"
                      value={values.addressline2}
                      defaultValue={
                        props.clinicItemData &&
                          props.clinicItemData.address &&
                          props.clinicItemData.address.addressline2
                          ? props.clinicItemData.address.addressline2
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap mt-3">
                
              <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block clinicprofileLabel text-blueGray-600 fnt24P font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Country
                    </label>
                    
                    <Autocomplete
                      options={contries}
                      clearIcon={null}
                      popupIcon={null}
                      getOptionLabel={(option) => option.country || (values && values.country)}
                      onChange={(e, value) => {
                        // setCountryValue1(value && value.country)
                        handleInputChange('country',value && value.country)
                        if(!value){
                          setCountryError({val:true,msg:'Required'});
                          return;
                        }
                        else{
                          setCountryError({val:false,msg:'ok'})
                        }
                      }}
                      autoHighlight
                      id="disable-close-on-select"
                      country
                      defaultValue={
                        props.clinicItemData &&
                          props.clinicItemData.address &&
                          props.clinicItemData.address.country
                          ? props.clinicItemData.address.country
                          : 'United States'
                      }
                      renderInput={(params) => (
                        <TextField {...params}
                          onChange={(e) => { }}
                          variant="standard" />
                      )}
                    /><p style={{
                      color: 'red',
                      fontSize: '12px',
                      textAlign: 'left',
                      paddingTop: '12px',
                      marginLeft: '2px',
                  }}>{countryError.val?countryError.msg:''}</p>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block clinicprofileLabel text-blueGray-600 fnt24P font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      State
                    </label>
                    
                    {values.country === "United States" ?
                          <div>
                          <Autocomplete
                            options={state}
                            clearIcon={null}
                            popupIcon={null}
                            getOptionLabel={(option) => option.name || values && values.state
                            }
                            onChange={(e, value) => {
                              handleInputChange('state',value && value.name)
                              if(!value){
                                setStateError({val:true,msg:'Required'});
                                return;
                              }
                              else{
                                setStateError({val:false,msg:'ok'})
                              }
                              let flag = alphabetsOnlyWithSpace(value.name);
                              if(flag){
                                flag = value.name.length>35?false:true
                                setStateError(flag?{val:false,msg:'ok'}:{val:true,msg:'This input must be no longer than 35 characters long'});
                              }
                              else{
                                setStateError({val:true,msg:'should contain only alphabets and/or space'});
                              }
                            }}
                            autoHighlight
                            id="disable-close-on-select"
                            defaultValue={
                              props.clinicItemData &&
                                props.clinicItemData.address &&
                                props.clinicItemData.address.state
                                ? props.clinicItemData.address.state
                                : ""
                            }

                            renderInput={(params) => (

                              <TextField {...params} variant="standard" />
                            )}

                          /> <p style={{
                            color: 'red',
                            fontSize: '12px',
                            textAlign: 'left',
                            paddingTop: '12px',
                            marginLeft: '2px',
                        }}>{stateError.val?stateError.msg:''}</p>
                        </div>
                          :
                          <InputField
                            type="text"
                            className="inputfeildsforallcare2"
                            ref={inputRefs.current[5]}
                            handleInputChange={handleInputChange}
                            validation="required|alphabetsOnly|max:35"
                            placeholder=""
                            name="state"
                            defaultValue={
                              props.clinicItemData &&
                                props.clinicItemData.address &&
                                props.clinicItemData.address.state
                                ? props.clinicItemData.address.state
                                : ""
                            }
                          />
                        }
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap mt-3">
              <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block clinicprofileLabel text-blueGray-600  font-bold mb-2 fnt24P"
                      htmlFor="grid-password"
                    >
                      City
                    </label>
                    <InputField
                      type="text"
                      className="inputfeildsforallclinicProfile fnt21P"
                      ref={inputRefs.current[4]}
                      handleInputChange={handleInputChange}
                      validation="required|alphabetsOnly|max:35"
                      placeholder=""
                      name="city"
                      value={values.city}
                      defaultValue={
                        props.clinicItemData &&
                          props.clinicItemData.address &&
                          props.clinicItemData.address.city
                          ? props.clinicItemData.address.city
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block clinicprofileLabel text-blueGray-600 fnt24P font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Zip
                    </label>
                    <InputField
                      type="text"
                      className="inputfeildsforallclinicProfile fnt21P"
                      ref={inputRefs.current[6]}
                      handleInputChange={handleInputChange}
                      validation="required|alphanumeric|min:3|max:9"
                      placeholder=""
                      name="postal"
                      value={values.postal}
                      defaultValue={
                        props.clinicItemData &&
                          props.clinicItemData.address &&
                          props.clinicItemData.address.postal
                          ? props.clinicItemData.address.postal
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap mt-3">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block clinicprofileLabel text-blueGray-600 fnt24P font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Phone
                    </label>
                    <PhoneInput
                      className="PhoneinputForUserProfile fnt21P"
                      international
                      value={phoneValue}
                      placeholder=""
                      inputProps={{
                        name: "phonenumber",
                        required: true,
                      }}
                      onChange={(val) => setPhoneValue(val)}
                      inputStyle={{
                        width: "100%",
                        border: "none !important",
                        background: "#fff",
                      }}
                    />
                  </div>
                </div>
                {/* <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block clinicprofileLabel text-blueGray-600 fnt24P font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <InputField
                      type="text"
                      className="inputfeildsforallclinicProfile fnt21P"
                      ref={inputRefs.current[9]}
                      handleInputChange={handleInputChange}
                      validation="required"
                      placeholder=""
                      name="email"
                      value={values.email}
                      defaultValue={
                        props.clinicItemData && props.clinicItemData.email
                          ? props.clinicItemData.email
                          : ""
                      }

                    />
                  </div>
                </div> */}
              </div>

              <div>
                <div className="flex flex-wrap mt-3">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block clinicProfileWebsiteLabel text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Website
                      </label>
                      <InputField
                        type="text"
                        className="inputfeildsforallclinicProfile"
                        ref={inputRefs.current[7]}
                        handleInputChange={handleInputChange}
                        validation="website"
                        placeholder=""
                        name="website"
                        value={values.website}
                        defaultValue={
                          props.clinicItemData && props.clinicItemData.website
                            ? props.clinicItemData.website
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="updatebuttonCenter">
            <Button
              variant="contained"
              onClick={() => onSubmit()}
              className="buttonforUpdate"
              disabled={valid}
            >
              {editClickSaveButton ? <Box sx={{
                display: 'flex', width: "40px", height: "40px"
              }}>  <LoadingIcon /></Box> : "Submit"}
            </Button>
          </div>
        </div>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <LoadingIcon />
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    profileData: store.user.user,
    clinicuserUpdateMsg: store.invitation.clinicuserUpdateMsg,
    clinicuserErrorMsg: store.invitation.clinicuserErrorMsg,
  };
};

export default connect(mapStateToProps, {
  fetchInvitation,
  updateProfile,
  updateClinicProile,
  updateClinicUserSnckMsg,
})(CardClinicProfile);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AlertSnackBar = ({
  flag,
  onClose,
  anchorOrigin,
  msg,
  severity,
}) => {
  return (
    <Snackbar
      anchorOrigin={
        anchorOrigin ? anchorOrigin : { vertical: "top", horizontal: "right" }
      }
      open={flag}
        autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity ? severity : "success"}>
        {msg}
      </Alert>
    </Snackbar>
  );
};
