/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./NotificationSettings.css";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "react-toggle-button";
import { styled } from "@material-ui/core/styles";
import { Stack, Typography } from "@mui/material";
import {
  profile_id
} from "../../../redux/invitation/components/invitation.action";
import {
  addNotificationSettings,
  fetchNotificationSummary,

} from "../../../redux/notification/notification.action";
import { Cancel } from "@mui/icons-material";
import { userLoginData, pageHeading } from "../../../redux/user/user.action";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Switch from "@mui/material/Switch";
import CaresiteDetailsPopup from "../../../../src/components/CaresiteDetailsPopup";
import AlertSnackBar from "../../../components/Alertmessage/AlertMsg";
import Box from '@mui/material/Box';
//weekday picker
// import {
//   FrequencyType,
//   EndingConditionType,
// } from "react-recurrence";

//input validation
import InputField from "../../../components/Inputs_Validation";

//slider
import Grid from "@material-ui/core/Grid";

import FooterCard from "../../../components/FooterCard";
import NotificationSettingsSlider from "../../../components/Cards/NotificationSettingsSlider";
import NotificationSettingsSliderforclinician from "../../../components/Cards/NotificationSettingsSliderforclinician";
import { useHistory, useLocation } from "react-router-dom";
import LoadingIcon from "../../../components/LoadingIcon";

const useStyles = makeStyles((theme) => ({
  slider: {
    width: "100%",
  },
  root: {
    width: 290,
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 56,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#c5c82f",
      /*   backgroundColor: "#fff", */
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#fff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}



const NotificationSettings = ({
  profile_id,
  userSpClinicList,
  addNotificationSettings,
  fetchNotificationSummary,
  notificationCid,
  user,
  notificationCNm,
  clinicSpNotificationData,
  userLoginData,
  pageHeading,
  notificationSettingSave
}) => {
  let query = useQuery();
  const daysOfWeek = [
    { day: "monday", selected: false },
    { day: "tuesday", selected: false },
    { day: "wednesday", selected: false },
    { day: "thursday", selected: false },
    { day: "friday", selected: false },
    { day: "saturday", selected: false },
    { day: "sunday", selected: false },
  ];
  
  const [inputEmailValue, setInputEmailValue] = React.useState("");
  const [emailLists, setEmailLists] = React.useState([]);
  const [selectMonButtonDay, setSelectMonButtonDay] = React.useState("white");
  const [selectTusButtonDay, setSelectTusButtonDay] = React.useState("white");
  const [selectWebButtonDay, setSelectWebButtonDay] = React.useState("white");
  const [selectThrusButtonDay, setSelectThrusButtonDay] =
    React.useState("white");
  const [selectFriButtonDay, setSelectFriButtonDay] = React.useState("white");
  const [selectSatButtonDay, setSelectSatButtonDay] = React.useState("white");
  const [selectSunButtonDay, setSelectSunButtonDay] = React.useState("white");
  //buttons color
  const [selectMonButtonTextDay, setSelectMonButtonTextDay] =
    React.useState("black");
  const [selectTusButtonTextDay, setSelectTusButtonTextDay] =
    React.useState("black");
  const [selectWebButtonTextDay, setSelectWebButtonTextDay] =
    React.useState("black");
  const [selectThrusButtonTextDay, setSelectThrusButtonTextDay] =
    React.useState("black");
  const [selectFriButtonTextDay, setSelectFriButtonTextDay] =
    React.useState("black");
  const [selectSatButtonTextDay, setSelectSatButtonTextDay] =
    React.useState("black");
  const [selectSunButtonTextDay, setSelectSunButtonTextDay] =
    React.useState("black");

  const inputRefs = React.useRef([React.createRef()]);
  const [selectedDay, setSelectedDay] = React.useState(daysOfWeek);
  const [recipientList, setRecipientList] = React.useState([]);
  const [emailEntered, setEmailEntered] = React.useState(
    recipientList ? [recipientList] : []
  );
  const [adherenceScore, setAdherenceScore] = React.useState(
    (clinicSpNotificationData &&
      clinicSpNotificationData.notification_settings !== null) ||
      undefined
      ? clinicSpNotificationData.notification_settings.adherence &&
      clinicSpNotificationData.notification_settings.adherence
      : 70
  );
  const [spO2Score, setSpO2Score] = React.useState(
    (clinicSpNotificationData &&
      clinicSpNotificationData.notification_settings !== null) ||
      undefined
      ? clinicSpNotificationData.notification_settings.spo2 &&
      clinicSpNotificationData.notification_settings.spo2
      : 90
  );
  const [therapyToggle, setTherapyToggle] = React.useState({
    value:
      (clinicSpNotificationData &&
        clinicSpNotificationData.notification_settings !== null) ||
        undefined
        ? clinicSpNotificationData.notification_settings.therapyAdjustment &&
        clinicSpNotificationData.notification_settings.therapyAdjustment
        : false,
  });
  const [clinicId, setClinicId] = React.useState(
    notificationCid ? notificationCid : null
  );
  const [adherenceToggle, setAdherenceToggle] = React.useState({
    value:
      (clinicSpNotificationData &&
        clinicSpNotificationData.notification_settings !== null) ||
        (undefined && clinicSpNotificationData.notification_settings.adherence)
        ? true
        : false,
  });
  const [spO2Toggle, setSpO2Toggle] = React.useState({
    value:
      (clinicSpNotificationData &&
        clinicSpNotificationData.notification_settings !== null) ||
        (undefined && clinicSpNotificationData.notification_settings.spo2)
        ? true
        : false,
  });
  const [clinicSlUser, setClinicSlUser] = React.useState({
    notificationCNm: notificationCNm,
    notificationCid: notificationCid,
  });
  const [submitSaveBtn, setSubmitSaveBtn] = React.useState("");

  const [notificationClickSave, setNotificationClickSave] = React.useState(false);
  // const [defClinicId, setDefClinicId] = React.useState(
  //   userSpClinicList.length !== 0 ? userSpClinicList[0].c_id : ""
  // );
  const [DisplayData, setDisplayData] = React.useState(false);

  const [openSnack, setOpenSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  useEffect(() => {
    const list = clinicSpNotificationData &&
      clinicSpNotificationData.notification_settings &&
      clinicSpNotificationData.notification_settings.recipients
      ? clinicSpNotificationData.notification_settings.recipients + ""
      : ""
    if (list) setEmailLists(list.split(','))
  }, [clinicSpNotificationData])


  const handleDelete = (value) => {
    const index = emailLists.indexOf(value)
    let _emailList = [...emailLists]
    if (index > -1) {
      _emailList.splice(index, 1)
    }
    setEmailLists(_emailList)
  };

  const history = useHistory();

  let intViewportWidth = window.innerWidth;
  let decriptQuery = ""
  let paramQueryForRefresh = 0

  try {
    decriptQuery = Buffer.from(query.get("p"), 'base64').toString('ascii')
    paramQueryForRefresh = JSON.parse(decriptQuery) && JSON.parse(decriptQuery).clinicId ? JSON.parse(decriptQuery).clinicId : 0
  }
  catch (err) {
    console.log('[Error]', err)
  }

  // useEffect(() => {       //dont remove: commented by sujan 22/02/2022
  //   userLoginData(defClinicId);
  // }, [defClinicId]);

  useEffect(() => {
    savedDays();
    return () => {
      setSelectedDay(null);
    };

  }, []);

  useEffect(() => {
    pageHeading("Notifications");
  }, []);

  useEffect(() => {
    if (clinicSpNotificationData) {
      setClinicSlUser({
        notificationCNm: clinicSpNotificationData &&
          clinicSpNotificationData.clinicname
          ? clinicSpNotificationData.clinicname : "",
        notificationCid: paramQueryForRefresh,
      })
      if (paramQueryForRefresh)
        setClinicId(paramQueryForRefresh);
      savedDays()
      // setRecipientList(
      //   clinicSpNotificationData &&
      //     clinicSpNotificationData.notification_settings &&
      //     clinicSpNotificationData.notification_settings.recipients
      //     ? clinicSpNotificationData.notification_settings.recipients + ""
      //     : ""
      // )

      setAdherenceScore(
        (clinicSpNotificationData &&
          clinicSpNotificationData.notification_settings !== null)

          ? clinicSpNotificationData.notification_settings.adherence &&
          clinicSpNotificationData.notification_settings.adherence
          : 70
      )
      setAdherenceToggle({
        value:
          (clinicSpNotificationData &&
            clinicSpNotificationData.notification_settings !== null) ||
            (undefined && clinicSpNotificationData.notification_settings.adherence)
            ? true
            : false,
      })
      setSpO2Score(
        (clinicSpNotificationData &&
          clinicSpNotificationData.notification_settings !== null)

          ? clinicSpNotificationData.notification_settings.spo2 &&
          clinicSpNotificationData.notification_settings.spo2
          : 0
      )
      setSpO2Toggle({
        value:
          (clinicSpNotificationData &&
            clinicSpNotificationData.notification_settings !== null&& clinicSpNotificationData.notification_settings.spo2>0) 
            ? true
            : false,
      })
      setTherapyToggle({
        value:
          (clinicSpNotificationData &&
            clinicSpNotificationData.notification_settings !== null)
            ? clinicSpNotificationData.notification_settings.therapyAdjustment &&
            clinicSpNotificationData.notification_settings.therapyAdjustment
            : false,
      }
      )
    }
    else {
      fetchNotificationSummary(paramQueryForRefresh)

    }

  }, [clinicSpNotificationData])

  const savedDays = () => {
    var days =
      (clinicSpNotificationData &&
        clinicSpNotificationData.notification_settings !== null) ||
        (undefined && clinicSpNotificationData.notification_settings.days)
        ? clinicSpNotificationData.notification_settings.days
        : [];
    var tempObj = [...selectedDay];

    if (days !== undefined && days !== null) {
      let tempOject1 = tempObj.map((item) => {
        if (days.includes(item.day)) {
          item.selected = true;
        }
        return item;
      });
      setSelectedDay(tempOject1);
      buttonBackgroundSetting(tempOject1)
      // buttonBackground(null, tempOject1);
    }
  };

  useEffect(() => {
    profile_id();
  }, []);

  const handleNotifysentKeydown = (e) => {
    let _submitSaveBtn = Array.isArray(submitSaveBtn) ? submitSaveBtn.filter((item) => {
      return emailLists.indexOf(item.trim()) ===-1 && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(item.trim())
    }) : []
    setEmailLists(() => [...emailLists, ..._submitSaveBtn]);
    setDisplayData(!DisplayData);
    setInputEmailValue('');
  };


  //weekday picker
  const dayHandler = (valueDay) => {
    // buttonBackground(valueDay, null);
    buttonBackgroundOnUserClick(valueDay);
    var newdaysOfWeek = [];

    for (var i = 0; i < selectedDay.length; i++) {
      var item = selectedDay[i];
      if (valueDay === item.day) {
        item.selected = !item.selected;
        newdaysOfWeek.push(item);
      } else {
        newdaysOfWeek.push(item);
      }
    }

    setSelectedDay(newdaysOfWeek);
  };

  const buttonBackgroundSetting = (valueArray) => {
    if ((valueArray && valueArray.length > 0 && valueArray[0].selected ===true)) {
      if (selectMonButtonDay === "white") {
        setSelectMonButtonDay("#F26930");
        setSelectMonButtonTextDay("white");
      }
    }
    if (valueArray && valueArray.length > 1 && valueArray[1].selected ===true) {
      if (selectTusButtonDay === "white") {
        setSelectTusButtonDay("#F26930");
        setSelectTusButtonTextDay("white");
      }
    }
    if (valueArray && valueArray.length > 2 && valueArray[2].selected ===true) {
      if (selectWebButtonDay === "white") {
        setSelectWebButtonDay("#F26930");
        setSelectWebButtonTextDay("white");
      }
    }
    if (valueArray && valueArray.length > 3 && valueArray[3].selected ===true) {
      if (selectThrusButtonDay === "white") {
        setSelectThrusButtonDay("#F26930");
        setSelectThrusButtonTextDay("white");
      }
    }
    if (valueArray && valueArray.length > 4 && valueArray[4].selected ===true) {
      if (selectFriButtonDay === "white") {
        setSelectFriButtonDay("#F26930");
        setSelectFriButtonTextDay("white");
      }
    }
    if (valueArray && valueArray.length > 5 && valueArray[5].selected ===true) {
      if (selectSatButtonDay === "white") {
        setSelectSatButtonDay("#F26930");
        setSelectSatButtonTextDay("white");
      }
    }
    if (valueArray && valueArray.length > 6 && valueArray[6].selected ===true) {
      if (selectSunButtonDay === "white") {
        setSelectSunButtonDay("#F26930");
        setSelectSunButtonTextDay("white");
      }
    }
  };

  const buttonBackgroundOnUserClick = (valueDay) => {
    if (valueDay === "monday") {
      if (selectMonButtonDay === "white") {
        setSelectMonButtonDay("#F26930");
        setSelectMonButtonTextDay("white");
      } else {
        setSelectMonButtonDay("white");
        setSelectMonButtonTextDay("black");
      }
    }
    else if (valueDay === "tuesday") {
      if (selectTusButtonDay === "white") {
        setSelectTusButtonDay("#F26930");
        setSelectTusButtonTextDay("white");
      } else {
        setSelectTusButtonDay("white");
        setSelectTusButtonTextDay("black");
      }
    }
    else if (valueDay === "wednesday") {
      if (selectWebButtonDay === "white") {
        setSelectWebButtonDay("#F26930");
        setSelectWebButtonTextDay("white");
      } else {
        setSelectWebButtonDay("white");
        setSelectWebButtonTextDay("black");
      }
    }
    else if (valueDay === "thursday") {
      if (selectThrusButtonDay === "white") {
        setSelectThrusButtonDay("#F26930");
        setSelectThrusButtonTextDay("white");
      } else {
        setSelectThrusButtonDay("white");
        setSelectThrusButtonTextDay("black");
      }
    }
    else if (valueDay === "friday") {
      if (selectFriButtonDay === "white") {
        setSelectFriButtonDay("#F26930");
        setSelectFriButtonTextDay("white");
      } else {
        setSelectFriButtonDay("white");
        setSelectFriButtonTextDay("black");
      }
    }
    else if (valueDay === "saturday") {
      if (selectSatButtonDay === "white") {
        setSelectSatButtonDay("#F26930");
        setSelectSatButtonTextDay("white");
      } else {
        setSelectSatButtonDay("white");
        setSelectSatButtonTextDay("black");
      }
    }
    else if (valueDay === "sunday") {
      if (selectSunButtonDay === "white") {
        setSelectSunButtonDay("#F26930");
        setSelectSunButtonTextDay("white");
      } else {
        setSelectSunButtonDay("white");
        setSelectSunButtonTextDay("black");
      }
    }
  }

  const handleInputChange = (name, value) => {
    var emailArray = value.split(",");
    setSubmitSaveBtn(emailArray);
    setEmailEntered(emailArray);
    setInputEmailValue(value);
  };

  const calladherence = (val) => {
    setAdherenceScore(val);
  };

  const callSpO2 = (val) => {
    setSpO2Score(val);
  };

  const onSave = async () => {
    setNotificationClickSave(true);
    var notificationObj = {};
    var finalSelectedDays = [];
    var finalRecipientList = [];

    selectedDay.forEach((item) => {
      if (item.selected) {
        finalSelectedDays.push(item.day);
      }
    });

    emailEntered.forEach((item) => {
      if (/\S+@\S+\.\S+/.test(item)) {
        finalRecipientList.push(item);
      }
    });

    notificationObj.clinicId = clinicId;
    notificationObj.days = finalSelectedDays;
    notificationObj.recipients = emailLists.join(',');
    if (adherenceToggle.value === false) {
      notificationObj.adherence = 0;
    } else {
      notificationObj.adherence = adherenceScore;
    }
    if (spO2Toggle.value === false) {
      notificationObj.spo2 = 0;
    } else {
      notificationObj.spo2 = spO2Score;
    }
    notificationObj.therapyAdjustment = therapyToggle.value
      ? therapyToggle.value
      : therapyToggle;

    notificationObj.clinicName = clinicSlUser.notificationCNm
    try{
      let res = await addNotificationSettings(notificationObj);
      setNotificationClickSave(false);
      if(res!=='Nochange'){
        history.push("/app/notificationSummary");
      }
    }
    catch(e){
      setNotificationClickSave(false);
      setSnackMsg('Error occured! couldn\'t save data');
      setOpenSnack(true)
    }

  };

  // const onCancel = () => {
  //   history.push("/app/notificationSummary");
  // };

  const [careSiteDetailsPopupOpen, setcareSiteDetailsPopupOpen] = React.useState(false);

  const callbackPI = () => {
    setcareSiteDetailsPopupOpen(false);

  };
  // useEffect(()=>{
  //   if(noficationsuccess===200){
  //     setSnackMsg("successfully updated data!");
  //     setOpenSnack(true);
  //     // history.push("/app/notificationSummary");
  //   }

  // },[])
  useEffect(() => {
    document.documentElement.scrollTop = 0;

    document.scrollingElement.scrollTop = 0;

  }, []);

  return (user.role==='clinicadmin'?
    <>
      <AlertSnackBar flag={openSnack} msg={snackMsg} onClose={()=>{
        setOpenSnack(false);
        setSnackMsg('');
      }} severity='error' anchorOrigin={{vertical: "top",
    horizontal: "center",}} />
      <CaresiteDetailsPopup isopen={careSiteDetailsPopupOpen} callBack={callbackPI}
      />

      {clinicSpNotificationData && clinicSpNotificationData.notification_settings ?

        <div>
          <div className="w-12/12 lg:w-12/12 px-4">
            <div className="topHeadingForSetting fnt32P">Care Site</div>
          </div>

          <div className="w-6/12  lg:w-6/12">
            <div className="topHeadingForSettingSelect">
              {/* <InputField
                type="email"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear clinicFeildsInputs transition-all duration-150"
                disabled={true}
                value={clinicSlUser.notificationCNm}
              /> */}
              {/* </div> */}
              <select className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear clinicFeildsInputs transition-all duration-150">
                <option value={clinicSlUser.notificationCNm}>{clinicSlUser.notificationCNm}</option>
              </select>
            </div>
          </div>

          <div className="w-12/12 lg:w-12/12 px-4">
            <div className="notificationScheduleForSetting ">
              <div className="fnt32P">Notification Schedule</div>
              <div className="ScheduleText fnt24P">
                {/*  Select the days of the week you want the system to send you an email
                regarding if you have notifications to review. <br></br>The email
                will be sent at 7:00 am Central Time. */}
                Select the days of the week you want the system to send you an email regarding notifications to review.<br></br> The email will be sent at 7:00 am Central Time.
              </div>
            </div>
          </div>

          {intViewportWidth < 1000 ? (
            <div>
              <div className="AllDaY">
                <div className="PerDaY">
                  <Button
                    style={{
                      backgroundColor: selectMonButtonDay,
                      color: selectMonButtonTextDay,
                    }}
                    id="monday"
                    onClick={() => dayHandler("monday")}
                  >
                    Monday
                  </Button>
                </div>
                <div className="PerDaY">
                  <Button
                    style={{
                      backgroundColor: selectTusButtonDay,
                      color: selectTusButtonTextDay,
                    }}
                    id="tuesday"
                    onClick={() => dayHandler("tuesday")}
                  >
                    Tuesday
                  </Button>
                </div>
                <div className="PerDaY">
                  <Button
                    style={{
                      backgroundColor: selectWebButtonDay,
                      color: selectWebButtonTextDay,
                    }}
                    id="wednesday"
                    onClick={() => dayHandler("wednesday")}
                  >
                    Wednesday
                  </Button>
                </div>
                <div className="PerDaY">
                  <Button
                    style={{
                      backgroundColor: selectThrusButtonDay,
                      color: selectThrusButtonTextDay,
                    }}
                    id="thursday"
                    onClick={() => dayHandler("thursday")}
                  >
                    Thursday
                  </Button>
                </div>
              </div>

              <div className="AllDaYTow">
                <div className="PerDaY">
                  <Button
                    style={{
                      backgroundColor: selectFriButtonDay,
                      color: selectFriButtonTextDay,
                    }}
                    id="friday"
                    onClick={() => dayHandler("friday")}
                  >
                    Friday
                  </Button>
                </div>
                <div className="PerDaY">
                  <Button
                    style={{
                      backgroundColor: selectSatButtonDay,
                      color: selectSatButtonTextDay,
                    }}
                    id="saturday"
                    onClick={() => dayHandler("saturday")}
                  >
                    Saturday
                  </Button>
                </div>
                <div className="PerDaY">
                  <Button
                    style={{
                      backgroundColor: selectSunButtonDay,
                      color: selectSunButtonTextDay,
                    }}
                    id="sunday"
                    onClick={() => dayHandler("sunday")}
                  >
                    Sunday
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="AllDaY">
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectMonButtonDay,
                    color: selectMonButtonTextDay,
                  }}
                  id="monday"
                  onClick={() => dayHandler("monday")}
                >
                  Monday
                </Button>
              </div>
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectTusButtonDay,
                    color: selectTusButtonTextDay,
                  }}
                  id="tuesday"
                  onClick={() => dayHandler("tuesday")}
                >
                  Tuesday
                </Button>
              </div>
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectWebButtonDay,
                    color: selectWebButtonTextDay,
                  }}
                  id="wednesday"
                  onClick={() => dayHandler("wednesday")}
                >
                  Wednesday
                </Button>
              </div>
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectThrusButtonDay,
                    color: selectThrusButtonTextDay,
                  }}
                  id="thursday"
                  onClick={() => dayHandler("thursday")}
                >
                  Thursday
                </Button>
              </div>
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectFriButtonDay,
                    color: selectFriButtonTextDay,
                  }}
                  id="friday"
                  onClick={() => dayHandler("friday")}
                >
                  Friday
                </Button>
              </div>
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectSatButtonDay,
                    color: selectSatButtonTextDay,
                  }}
                  id="saturday"
                  onClick={() => dayHandler("saturday")}
                >
                  Saturday
                </Button>
              </div>
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectSunButtonDay,
                    color: selectSunButtonTextDay,
                  }}
                  id="sunday"
                  onClick={() => dayHandler("sunday")}
                >
                  Sunday
                </Button>
              </div>
            </div>
          )}

          <Grid container style={{ padding: "0px 10px" }}>
            <Grid item xs={12}>
              <div className="section2_part1">
                <div
                  className="notificationRepatient fnt32P"
                  style={{ marginTop: "40px", marginLeft: "40px" }}
                >
                  Notification Recipients
                </div>
                <div className="notificationRepatientInnerDiv fnt24P">
                  Enter the email address(es) for the recipients and separate
                  multiple email addresses with a comma.
                </div>



                <div
                  className="w-12/12 lg:w-12/12"
                  style={{ marginLeft: "40px", marginTop: "40px" }}
                >
                  <div style={{ display: "flex", marginBottom: '15px' }}>
                    <InputField
                      // key={recipientList}
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear rePatientInputFeilds transition-all duration-150"
                      ref={inputRefs.current[0]}
                      handleInputChange={handleInputChange}
                      // defaultValue={recipientList}
                      placeholder="example@gmail.com, others@gmail.com"
                      // validation="emailpattern"
                      name="Email"
                      value = {inputEmailValue}
                    />
                    {/* <form onSubmit={handleNotifysentKeydown}> */}

                    <button onClick={handleNotifysentKeydown} style={{
                      backgroundColor: "#87a925", padding: '10px', color: "#fff", width: "53px", borderRadius: "6px", border: 'none', marginLeft: '100px', height: "51px",
                      boxShadow: "#6a768f80 0px 3px 6px"
                    }}>Add</button>
                  </div>

                  <div className="userEmail-wrapper" style={{ cursor: 'pointer', display: 'flex', flexWrap: 'wrap' }}>
                    {emailLists.map((data, index) => {
                      return (
                        <Tags data={data}
                          //handleDelete={handleDelete}
                          handleDelete={() => {
                            handleDelete(data);
                          }}
                          key={index} />
                      );
                    })}




                  </div>
                  {/* </form> */}

                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <div className="section3_part1">
                <div className="AdheranceMainHeading">
                  <div
                    className="AdheranceHeadingTitle"
                    style={{ marginLeft: "48px" }}
                  >
                    <div className="titleTextAherance fnt32P">Adherence</div>
                    <div className="subtitleadherance fnt24P">
                      {/* Use the slider to highlight the range of adherence scores you
                      would like to be notified of a patient which is within the
                      range. */}
                      Use the slider to set the notification range. You will be notified if a patient's Adherence Score is within the highlighted range.
                    </div>
                  </div>
                  <div className="radio_button" style={{ display: "flex" }}>
                    <div className="activeTextToggleNotification">
                      {adherenceToggle.value === true ? "ON" : ""}

                    </div>
                    <ToggleButton
                      inactiveLabel={""}
                      activeLabel={""}
                      colors={{
                        active: {
                          base: "#8ea817",
                        },
                      }}
                      value={adherenceToggle.value || false}
                      onToggle={(value) => {
                        setAdherenceToggle({
                          value: !value,
                        });
                      }}
                    />
                    <div className="InactiveTextToggleNotification">
                      {adherenceToggle.value === true ? "" : "OFF"}

                    </div>
                  </div>
                </div>
                {/* adherence Slider */}



                {adherenceToggle.value ? (
                  <div className="progressbarForAdherence">
                    <NotificationSettingsSlider
                      description="Adherence"
                      calladherence={calladherence}
                      adherenceForSlCl={adherenceScore}
                    />
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="section3_part1">

                <div className="AdheranceMainHeading">
                  <div
                    className="AdheranceHeadingTitle"
                    style={{ marginLeft: "40px" }}
                  >
                    <p className="titleTextspo2 fnt32P">SpO2</p>
                    <p className="subtitlespo2 fnt24P">
                      Use the slider to set the notification range. You will be
                      notified if a patient's SpO2 reading within the highlighted
                      range.
                    </p>
                  </div>
                  <div className="radio_button" style={{ display: "flex" }}>
                    <div className="activeTextToggleNotification">
                      {spO2Toggle.value === true ? "ON" : ""}

                    </div>
                    <ToggleButton
                      inactiveLabel={""}
                      activeLabel={""}
                      colors={{
                        active: {
                          base: "#8ea817",
                        },
                      }}
                      value={spO2Toggle.value || false}
                      onToggle={(value) => {
                        setSpO2Toggle({
                          value: !value,
                        });
                      }}
                    />
                    <div className="InactiveTextToggleNotification">
                      {spO2Toggle.value === true ? "" : "OFF"}

                    </div>
                  </div>
                </div>
                {/*  {statePatientSpo2.value ===true ? ( */}

                {spO2Toggle.value ? (
                  <div className="spo2Div">
                    <NotificationSettingsSlider
                      description="SpO2"
                      callSpO2={callSpO2}
                      spO2ForSlCl={spO2Score}
                    />
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="section3_part1">

                <div className="AdheranceMainHeading">
                  <div
                    className="AdheranceHeadingTitle"
                    style={{ marginLeft: "40px" }}
                  >
                    <div className="titleTextfortheorpy fnt32P">
                      Therapy Adjustments
                    </div>
                    <div className="subtitleforTheorpy fnt24P">
                      When the toggle is
                      <span style={{ fontWeight: "600" }}> ON, </span>you will be
                      notified if a patient changes their therapy protocol.
                    </div>
                  </div>
                  <div className="radio_button" style={{ display: "flex" }}>
                    <div className="activeTextToggleNotification">
                      {therapyToggle.value ? "ON" : ""}

                    </div>
                    <ToggleButton
                      inactiveLabel={""}
                      activeLabel={""}
                      colors={{
                        active: {
                          base: "#8ea817",
                        },
                      }}
                      value={therapyToggle.value || false}
                      onToggle={(value) => {
                        setTherapyToggle({
                          value: !value,
                        });
                      }}
                    />
                    <div className="InactiveTextToggleNotification">
                      {therapyToggle.value === false ? "OFF" : ""}

                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className="updatebuttonnotificationsetting">
            {clinicSlUser.notificationCNm.length &&
              (emailEntered.length > 0 || submitSaveBtn !== "") ? (
              <Button
                variant="contained"
                onClick={onSave}
                className="buttonforUpdatenotificationsetting"
              >
                {/*  save */}
                {notificationClickSave ? <Box sx={{
                  display: 'flex', width: "50px", height: "50px"
                }}>  <LoadingIcon /> </Box> : "Save"}
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled
                className="buttonforUpdatenotificationsetting"
              >
                save
              </Button>
            )}
            {/* <div className="CancelDiv">
              <div className="cancel" onClick={onCancel}>
                Cancel
              </div>
            </div> */}
          </div>


          <FooterCard
            clinicName={clinicSpNotificationData && clinicSpNotificationData.clinicname && clinicSpNotificationData.clinicname}
            address={clinicSpNotificationData && clinicSpNotificationData.address && clinicSpNotificationData.address.addressline1 && `${clinicSpNotificationData.address.addressline1}, `}
            city={clinicSpNotificationData && clinicSpNotificationData.address && clinicSpNotificationData.address.city && `${clinicSpNotificationData.address.city}, `}
            state={clinicSpNotificationData && clinicSpNotificationData.address && clinicSpNotificationData.address.state && `${clinicSpNotificationData.address.state}, `}
            postal={clinicSpNotificationData && clinicSpNotificationData.address && clinicSpNotificationData.address.postal}
            phonenumber={clinicSpNotificationData && clinicSpNotificationData.phonenumber && clinicSpNotificationData.phonenumber}
            website={clinicSpNotificationData && clinicSpNotificationData.website && clinicSpNotificationData.website}
            link="Care site details"
            image={clinicSpNotificationData && clinicSpNotificationData.clinic_puuid && clinicSpNotificationData.clinic_puuid}
          />
          


          <span /* light */ style={{ marginTop: "35px" }}></span>
          <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={2}></Grid>
            <Grid item xs={12} style={{ marginTop: "60px" }}></Grid>
            <Grid item xs={2}></Grid>
          </Grid>

        </div> :
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <LoadingIcon />
        </div>
      }
    </>
    
    :user.role==='clinician'?
    <>
    <CaresiteDetailsPopup isopen={careSiteDetailsPopupOpen} callBack={callbackPI}
    />

    {clinicSpNotificationData && clinicSpNotificationData.notification_settings ?

      <div>
        <div className="w-12/12 lg:w-12/12 px-4">
          <div className="topHeadingForSetting fnt32P">Care Site</div>
        </div>

        <div className="w-6/12  lg:w-6/12">
          <div className="topHeadingForSettingSelect">
            {/* <InputField
              type="email"
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear clinicFeildsInputs transition-all duration-150"
              disabled={true}
              value={clinicSlUser.notificationCNm}
            /> */}
            {/* </div> */}
            <select className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear clinicFeildsInputs transition-all duration-150">
              <option value={clinicSlUser.notificationCNm}>{clinicSlUser.notificationCNm}</option>
            </select>
          </div>
        </div>

        <div className="w-12/12 lg:w-12/12 px-4">
          <div className="notificationScheduleForSetting ">
            <div className="fnt32P">Notification Schedule</div>
            <div className="ScheduleText fnt24P">
              {/*  Select the days of the week you want the system to send you an email
              regarding if you have notifications to review. <br></br>The email
              will be sent at 7:00 am Central Time. */}
              The system sends an email on the highlighted days.<br></br> The email will be sent at 7:00 am Central Time.
            </div>
          </div>
        </div>

        {intViewportWidth < 1000 ? (
          <div>
            <div className="AllDaY">
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectMonButtonDay,
                    color: selectMonButtonTextDay,
                  }}
                  id="monday"
                  // onClick={() => dayHandler("monday")}
                  disabled
                >
                  Monday
                </Button>
              </div>
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectTusButtonDay,
                    color: selectTusButtonTextDay,
                  }}
                  id="tuesday"
                  // onClick={() => dayHandler("tuesday")}
                  disabled
                >
                  Tuesday
                </Button>
              </div>
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectWebButtonDay,
                    color: selectWebButtonTextDay,
                  }}
                  id="wednesday"
                  // onClick={() => dayHandler("wednesday")}
                  disabled
                >
                  Wednesday
                </Button>
              </div>
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectThrusButtonDay,
                    color: selectThrusButtonTextDay,
                  }}
                  id="thursday"
                  // onClick={() => dayHandler("thursday")}
                  disabled
                >
                  Thursday
                </Button>
              </div>
            </div>

            <div className="AllDaYTow">
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectFriButtonDay,
                    color: selectFriButtonTextDay,
                  }}
                  id="friday"
                  // onClick={() => dayHandler("friday")}
                  disabled
                >
                  Friday
                </Button>
              </div>
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectSatButtonDay,
                    color: selectSatButtonTextDay,
                  }}
                  id="saturday"
                  // onClick={() => dayHandler("saturday")}
                  disabled
                >
                  Saturday
                </Button>
              </div>
              <div className="PerDaY">
                <Button
                  style={{
                    backgroundColor: selectSunButtonDay,
                    color: selectSunButtonTextDay,
                  }}
                  id="sunday"
                  // onClick={() => dayHandler("sunday")}
                  disabled
                >
                  Sunday
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="AllDaY">
            <div className="PerDaY">
              <Button
                style={{
                  backgroundColor: selectMonButtonDay,
                  color: selectMonButtonTextDay,
                }}
                id="monday"
                // onClick={() => dayHandler("monday")}
                disabled
              >
                Monday
              </Button>
            </div>
            <div className="PerDaY">
              <Button
                style={{
                  backgroundColor: selectTusButtonDay,
                  color: selectTusButtonTextDay,
                }}
                id="tuesday"
                // onClick={() => dayHandler("tuesday")}
                disabled
              >
                Tuesday
              </Button>
            </div>
            <div className="PerDaY">
              <Button
                style={{
                  backgroundColor: selectWebButtonDay,
                  color: selectWebButtonTextDay,
                }}
                id="wednesday"
                // onClick={() => dayHandler("wednesday")}
                disabled
              >
                Wednesday
              </Button>
            </div>
            <div className="PerDaY">
              <Button
                style={{
                  backgroundColor: selectThrusButtonDay,
                  color: selectThrusButtonTextDay,
                }}
                id="thursday"
                // onClick={() => dayHandler("thursday")}
                disabled
              >
                Thursday
              </Button>
            </div>
            <div className="PerDaY">
              <Button
                style={{
                  backgroundColor: selectFriButtonDay,
                  color: selectFriButtonTextDay,
                }}
                id="friday"
                // onClick={() => dayHandler("friday")}
                disabled
              >
                Friday
              </Button>
            </div>
            <div className="PerDaY">
              <Button
                style={{
                  backgroundColor: selectSatButtonDay,
                  color: selectSatButtonTextDay,
                }}
                id="saturday"
                // onClick={() => dayHandler("saturday")}
                disabled
              >
                Saturday
              </Button>
            </div>
            <div className="PerDaY">
              <Button
                style={{
                  backgroundColor: selectSunButtonDay,
                  color: selectSunButtonTextDay,
                }}
                id="sunday"
                // onClick={() => dayHandler("sunday")}
                disabled
              >
                Sunday
              </Button>
            </div>
          </div>
        )}

        <Grid container style={{ padding: "0px 10px" }}>
          <Grid item xs={12}>
            <div className="section2_part1">
              <div
                className="notificationRepatient fnt32P"
                style={{ marginTop: "40px", marginLeft: "40px" }}
              >
                Notification Recipients
              </div>
              <div className="notificationRepatientInnerDiv fnt24P">
                The email will be sent to the following address(es).
              </div>



              <div
                className="w-12/12 lg:w-12/12"
                style={{ marginLeft: "40px", marginTop: "40px" }}
              >
                {/* {<div style={{ display: "flex", marginBottom: '15px' }}>
                  <InputField
                    // key={recipientList}
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear rePatientInputFeilds transition-all duration-150"
                    ref={inputRefs.current[0]}
                    handleInputChange={handleInputChange}
                    // defaultValue={recipientList}
                    placeholder="example@gmail.com, others@gmail.com"
                    // validation="emailpattern"
                    name="Email"
                    value = {inputEmailValue}
                  />
                  {/* <form onSubmit={handleNotifysentKeydown}> 

                  <button onClick={handleNotifysentKeydown} style={{
                    backgroundColor: "#87a925", padding: '10px', color: "#fff", width: "53px", borderRadius: "6px", border: 'none', marginLeft: '100px', height: "51px",
                    boxShadow: "#6a768f80 0px 3px 6px"
                  }}>Add</button>
                </div>} */}

                <div className="userEmail-wrapper" style={{  display: 'flex', flexWrap: 'wrap' }}>
                  {emailLists.map((data, index) => {
                    return (
                      <Tags1 data={data}
                        //handleDelete={handleDelete}
                        // handleDelete={() => {
                        //   // handleDelete(data);
                        // }}
                        key={index} />
                    );
                  })}




                </div>
                {/* </form> */}

              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <div className="section3_part1">
              <div className="AdheranceMainHeading">
                <div
                  className="AdheranceHeadingTitle"
                  style={{ marginLeft: "48px" }}
                >
                  <div className="titleTextAherance fnt32P">Adherence</div>
                  <div className="subtitleadherance fnt24P">
                    {/* Use the slider to highlight the range of adherence scores you
                    would like to be notified of a patient which is within the
                    range. */}
                    You will be notified if a patient's Adherence Score is within the highlighted range.
                  </div>
                </div>
                <div className="radio_button" style={{ display: "flex" }}>
                  <div className="activeTextToggleNotification">
                    {adherenceToggle.value === true ? "ON" : ""}

                  </div>
                  <ToggleButton
                    inactiveLabel={""}
                    activeLabel={""}
                    colors={{
                      active: {
                        base: "#8ea817",
                      },
                    }}
                    value={adherenceToggle.value || false}
                    // onToggle={(value) => {
                    //   setAdherenceToggle({
                    //     value: !value,
                    //   });
                    // }}
                  />
                  <div className="InactiveTextToggleNotification">
                    {adherenceToggle.value === true ? "" : "OFF"}

                  </div>
                </div>
              </div>
              {/* adherence Slider */}



              {adherenceToggle.value ? (
                <div className="progressbarForAdherence">
                  <NotificationSettingsSliderforclinician
                    description="Adherence"
                    calladherence={calladherence}
                    adherenceForSlCl={adherenceScore}
                  />
                </div>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="section3_part1">

              <div className="AdheranceMainHeading">
                <div
                  className="AdheranceHeadingTitle"
                  style={{ marginLeft: "40px" }}
                >
                  <p className="titleTextspo2 fnt32P">SpO2</p>
                  <p className="subtitlespo2 fnt24P">
                    You will be notified if a patient's SpO2 reading within the highlighted
                    range.
                  </p>
                </div>
                <div className="radio_button" style={{ display: "flex" }}>
                  <div className="activeTextToggleNotification">
                    {spO2Toggle.value === true ? "ON" : ""}

                  </div>
                  <ToggleButton
                    inactiveLabel={""}
                    activeLabel={""}
                    colors={{
                      active: {
                        base: "#8ea817",
                      },
                    }}
                    value={spO2Toggle.value || false}
                    // onToggle={(value) => {
                    //   setSpO2Toggle({
                    //     value: !value,
                    //   });
                    // }}
                  />
                  <div className="InactiveTextToggleNotification">
                    {spO2Toggle.value === true ? "" : "OFF"}

                  </div>
                </div>
              </div>
              {/*  {statePatientSpo2.value ===true ? ( */}

              {spO2Toggle.value ? (
                <div className="spo2Div">
                  <NotificationSettingsSliderforclinician
                    description="SpO2"
                    callSpO2={callSpO2}
                    spO2ForSlCl={spO2Score}
                  />
                </div>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="section3_part1">

              <div className="AdheranceMainHeading">
                <div
                  className="AdheranceHeadingTitle"
                  style={{ marginLeft: "40px" }}
                >
                  <div className="titleTextfortheorpy fnt32P">
                    Therapy Adjustments
                  </div>
                  <div className="subtitleforTheorpy fnt24P">
                    When the toggle is
                    <span style={{ fontWeight: "600" }}> ON, </span>you will be
                    notified if a patient changes their therapy protocol.
                  </div>
                </div>
                <div className="radio_button" style={{ display: "flex" }}>
                  <div className="activeTextToggleNotification">
                    {therapyToggle.value ? "ON" : ""}

                  </div>
                  <ToggleButton
                    inactiveLabel={""}
                    activeLabel={""}
                    colors={{
                      active: {
                        base: "#8ea817",
                      },
                    }}
                    value={therapyToggle.value || false}
                    // onToggle={(value) => {
                    //   setTherapyToggle({
                    //     value: !value,
                    //   });
                    // }}
                  />
                  <div className="InactiveTextToggleNotification">
                    {therapyToggle.value === false ? "OFF" : ""}

                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        {/* <div className="updatebuttonnotificationsetting">
          {clinicSlUser.notificationCNm.length &&
            (emailEntered.length > 0 || submitSaveBtn !== "") ? (
            <Button
              variant="contained"
              onClick={onSave}
              className="buttonforUpdatenotificationsetting"
            >
              {/*  save 
              {notificationClickSave ? <Box sx={{
                display: 'flex', width: "50px", height: "50px"
              }}>  <LoadingIcon /> </Box> : "Save"}
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled
              className="buttonforUpdatenotificationsetting"
            >
              save
            </Button>
          )}
          {/* <div className="CancelDiv">
            <div className="cancel" onClick={onCancel}>
              Cancel
            </div>
          </div> 
        </div> */}


        <FooterCard
          clinicName={clinicSpNotificationData && clinicSpNotificationData.clinicname && clinicSpNotificationData.clinicname}
          address={clinicSpNotificationData && clinicSpNotificationData.address && clinicSpNotificationData.address.addressline1 && `${clinicSpNotificationData.address.addressline1}, `}
          city={clinicSpNotificationData && clinicSpNotificationData.address && clinicSpNotificationData.address.city && `${clinicSpNotificationData.address.city}, `}
          state={clinicSpNotificationData && clinicSpNotificationData.address && clinicSpNotificationData.address.state && `${clinicSpNotificationData.address.state}, `}
          postal={clinicSpNotificationData && clinicSpNotificationData.address && clinicSpNotificationData.address.postal}
          phonenumber={clinicSpNotificationData && clinicSpNotificationData.phonenumber && clinicSpNotificationData.phonenumber}
          website={clinicSpNotificationData && clinicSpNotificationData.website && clinicSpNotificationData.website}
          link="Care site details"
          image={clinicSpNotificationData && clinicSpNotificationData.clinic_puuid && clinicSpNotificationData.clinic_puuid}
        />
        


        <span /* light */ style={{ marginTop: "35px" }}></span>
        <Grid container style={{ marginTop: "20px" }}>
          <Grid item xs={2}></Grid>
          <Grid item xs={12} style={{ marginTop: "60px" }}></Grid>
          <Grid item xs={2}></Grid>
        </Grid>

      </div> :
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LoadingIcon />
      </div>
    }
  </>
  : null


  );
}
const mapStateToProps = (store) => {
  return {
    user: store.user.user,
    invitationList: store.invitation.invitationList,
    // allcliniclist: store.invitation.allcliniclist,//commented by dhanush on 3-2-2022
    userSpClinicList:
      store.user.userLogIn.rows !== undefined ? store.user.userLogIn.rows : [],
    notificationCid: store.notification.notificationCid,
    notificationCNm: store.notification.notificationCNm,

    clinicSpNotificationData: store.notification.notificationSummary[0],
    searchedClinic: store.user.searchedClinic,
    notificationSettingSave: store.notification.notificationSettingSave,

  };
};
const Tags = ({ data, handleDelete }) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        padding: "0.4rem",
        margin: "0 5px 5px 0px",
        justifyContent: "center",
        alignContent: "center",
        background: "#e1ecf4",
        borderRadius: "6px",
        color: "#000000f2",
        border: "1px solid #00000042",
      }}
    >
      <Stack direction='row' gap={1}>
        <Typography>{data}</Typography>
        <Cancel
          sx={{ cursor: "pointer" }}
          onClick={(data) => {
            handleDelete(data);
          }}
        />
      </Stack>
    </Box>
  );
};
const Tags1 = ({ data }) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        padding: "0.4rem",
        margin: "0 5px 5px 0px",
        justifyContent: "center",
        alignContent: "center",
        background: "#e1ecf4",
        borderRadius: "6px",
        color: "#000000f2",
        border: "1px solid #00000042",
      }}
    >
      <Stack direction='row' gap={1}>
        <Typography>{data}</Typography>
        {/* <Cancel
          sx={{ cursor: "pointer" }}
          onClick={(data) => {
            handleDelete(data);
          }}
        /> */}
      </Stack>
    </Box>
  );
};
export default connect(mapStateToProps, {
  profile_id,
  addNotificationSettings,
  fetchNotificationSummary,
  userLoginData,
  pageHeading,


})(NotificationSettings);



