import React from "react";
import whiteSeparatorOne from "../../assets/img/Group 16175.png";

const SeparatorTopSection = () => {
    return (
        <div> 
                <img src={whiteSeparatorOne} className="whiteSeparatorOneWrapper"></img>
        </div>
    );
} 
export default SeparatorTopSection;