import apiAbmBlaze, { FETCH_DEVICE_STATS_ROUTE } from "../../api";
import {
  FETCH_DEVICE_PERFORMANCE_STATS,
  DELETE_DEVICE_PERFORMANCE_STATS,
} from "../action.list";
import { generateQueryParams } from "../../utils/index";
import { INIT_DEVICE_STATS } from "./devices.reducer";

export const fetchDevicePerformanceStats =
  (pageIndex = 0, pageSize = 10, searchQuery = "", searchDateRange) =>
  async (dispatch) => {
    let startDate;
    let endDate;
    if (searchDateRange?.startDate && searchDateRange?.endDate) {
      startDate = searchDateRange.startDate;
      endDate = searchDateRange.endDate;
    }
    const queryString = generateQueryParams({
      pageIndex,
      pageSize,
      searchQuery,
      ...(startDate !== undefined && { startDate }),
      ...(endDate !== undefined && { endDate }),
    });
    console.log(startDate, endDate);
    const url =
      FETCH_DEVICE_STATS_ROUTE + queryString
        ? FETCH_DEVICE_STATS_ROUTE + "?" + queryString
        : FETCH_DEVICE_STATS_ROUTE;
    apiAbmBlaze
      .get(url)
      .then(async (res) => {
        if (res.data) {
          await dispatch({
            type: DELETE_DEVICE_PERFORMANCE_STATS,
            payload: {
              deviceStats:
                typeof res.data === "object"
                  ? res.data
                  : { ...INIT_DEVICE_STATS },
              totalRecords: 0,
            },
          });
        }
      })
      .catch((err) => {
        console.log("[err]", err);
      });
  };
export const deleteDeviceStats = () => async (dispatch) => {
  const url = FETCH_DEVICE_STATS_ROUTE;
  try {
    const res = await apiAbmBlaze.delete(url);
    if (res.data) {
      const deviceStats =
        typeof res.data === "object" ? res.data : { ...INIT_DEVICE_STATS };
      dispatch({
        type: FETCH_DEVICE_PERFORMANCE_STATS,
        payload: { deviceStats },
      });
    }
  } catch (err) {
    console.log("[err]", err);
  }
};
