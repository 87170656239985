import React, { useEffect } from "react";
import "./chatBody.css";
import ChatList from "../chatList/ChatList";
import ChatContent from "../chatContent/ChatContent";
import { loadProfile } from "../../../redux/user/user.action";
import { connect } from "react-redux";
const ChatBody = ({ loadProfile }) => {
  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <div className="flex flex-wrap" style={{ marginTop: "-48px" }}>
      <div className="w-full lg:w-4/12">
        <ChatList />
      </div>
      <div className="w-full lg:w-8/12">
        <ChatContent />
      </div>
    </div>
  );
};
const mapStateToProps = (store) => {
  return { chatUsers: store.user.chatUsers };
};
export default connect(mapStateToProps, { loadProfile })(ChatBody);
