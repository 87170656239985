import React from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
const AreaGraphAdheranceScore = (props) => {
  const series2 =  props.xaxislabel.map((a)=>{
    return moment(a).add(1,'days').format("D MMM")
  })
  const series = [
    {
      name: "Adherance   ",
      data: props.value,
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      }
    },

    dataLabels: {
      enabled: false,
    },

    colors: ["#c23c04", "#fff"],
    stroke: {
      curve: "smooth",
    },

    xaxis: {
      type: "",
      // categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
      // categories : ["Mon", "Tue", "Wed", "Thu","Fri","Sat","Sun"]
      categories: series2,
    },
    yaxis: {
      show: true,
      max: 100,
      labels: {
        formatter: function (value) {
          return parseInt(value);
        }
      },
    },

    tooltip: {
      x: {
        show: false,
      },
      y: {
        show: true,
      },
      marker: {
        show: false,
      },
      /*  custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var d = Number(series[seriesIndex][dataPointIndex]);
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);
 
        var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
        // return hDisplay + mDisplay + sDisplay;
        return (
          '<div class="arrow_box" style="padding:8px;"}}>' +
          "HMR :" +
          "<span>" +
          hDisplay +
          mDisplay +
          sDisplay +
          "</span>" +
          "<div></div>" +
          "</div>"
        );
      }, */
    },
    grid: {
      show: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: ["#c23c04", "#fff"],
      width: 2,
      dashArray: 0,
    },
    markers: {
      size: 5,
      colors: undefined,
      strokeColors: '#fff',
      strokeWidth: 1,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: false,
      hover: {
        size: undefined,
        sizeOffset: 1
      }
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {

        var AdheranceValue = parseInt(series[0][dataPointIndex]);
        return (
          '<div class="arrow_box" style="padding:10px 10px;margin-bottom : 5px;">' +
          "Adherance: " +
          "<span>" +
          +  AdheranceValue +
          "</span>" +
          "<div>" +
          "</div>"
        );
      },
    }
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </div >
  );
};
export default AreaGraphAdheranceScore;
