import {
  NUMBER_OF_THERAPYS,
  FETCH_NOTE_DATA,
  LOAD_THERAPIES,
  RESET_ALL_STATE,
} from '../action.list';

let defaultState = {
  therapiesSelected: false,
  addnote: "",
  fetchnote: "",
  loadTherapise: [],
  updatedUnitFlag: false
};

export const therapyGoalsReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case NUMBER_OF_THERAPYS:
      return { ...state, ...payload };
    case FETCH_NOTE_DATA:
      return { ...state, ...payload };
    case LOAD_THERAPIES:
      return { ...state, ...payload };
    case RESET_ALL_STATE:
      return defaultState;
    default:
      return state;
  }
};