import React from 'react'
import caresiteIcon from '../../assets/img/caresiteIcon.svg';
import './styles.css'
import Home from "../../assets/icons/helpcenter/home.svg"
import phone from "../../assets/icons/helpcenter/phone.svg"
import Website from "../../assets/icons/helpcenter/website.svg"
import Link from "../../assets/icons/helpcenter/link.svg"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avt: {
    height: "250px",
    width: "250px",
  },

}));

const FooterCard = (props) => {
  const classes = useStyles();
  const style = {
    width: '100%',
    maxWidth: 360,
    bgcolor: 'background.paper',
  };

  return (
    <div>
      <div className="flex flex-wrap bottomContainerInFooterCard">
        <div className="w-4/12 lg:w-4/12 ABMLogocontainerInFooterCard">
          {props.footercareclinicpuuid ?
              <a href={`//${props.website}`} target="_blank" rel="noreferrer noopener">
                <img
                  src={`https://ucarecdn.com/${props.footercareclinicpuuid}/`}
                  alt=""
                  className="profilepicuploadImageWrapper"
                  style={{
                    cursor: "pointer", width: "250px",
                    borderRadius: "50%",
                    height: "250px"
                  }}
                />
              </a>
             : props.image ?
              <img
                src={`https://ucarecdn.com/${props.image}/`}
                alt=""
                className="profilepicuploadImageWrapper"
                style={{
                  cursor: "pointer", width: "250px",
                  borderRadius: "50%",
                  height: "250px"
                }}
              />
              : <a href={`//${props.website}`} target="_blank" rel="noreferrer noopener">
                  <img src={caresiteIcon} className={classes.avt} />
                </a>
          }
        </div>

        <div className="w-8/12 lg:8/12 CareSiteDetailsContainer">
          <div className="ClinicNameHeading fnt32P">
            {props.clinicName}
          </div>

          <div className="bottomSectionContainer1InFooterCard">
            <div className="EmailIconInFooterCard">
              <img src={Home} />
            </div>
            <div className="addressContainer fnt24P">
              {props.address}
              {props.city}
              {props.state}
              {props.postal}
            </div>
          </div>

          <div className="bottomSectionContainer1InFooterCard">
            <div className="phoneIconInFooterCard ">
              <img src={phone} />
            </div>
            <div className="customservice fnt24P">
              {props.phonenumber}
            </div>
          </div>

          <div className="bottomSectionContainer1InFooterCard">
            <div className="websiteIconInFooterIcon">
              <img src={Website} />
            </div>
            <div className="customservice websiteanvhortag fnt24P">
              <a href={`//${props.website}`} target="_blank" rel="noreferrer noopener">{props.website}</a>
            </div>
          </div>

          <div className="bottomSectionContainer1InFooterCard mb-5" onClick={props.callbackfromfooter} >
            <div className="LinkIcon">
              <img src={Link} />
            </div>
            <div className="linkservice fnt24P" >
              <div className="link_helpInFooterCard">
                <u style={{ cursor: "pointer" }}>{props.link}</u>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default FooterCard;