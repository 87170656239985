/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./Helpcenter.css";
// import InputField from "../../components/Inputs_Validation";
import ABMLogo from "../../assets/img/ABMRespiretorycare.svg";
import { connect } from "react-redux";
import CustomsSnackbar from "../../components/Alertmessage/AlertMsg";
import WebsiteIcon from "../../assets/icons/helpcenter/website.svg";
import EmailIcon from "../../assets/icons/helpcenter/email.svg";
import { pageHeading } from "../../redux/user/user.action";

import apiAbmBlaze, { HELP_CENTER_API } from "../../api";
import LoadingIcon from "../../components/LoadingIcon";
import "../../../src/assets/styles/font.css";
import Box from "@mui/material/Box";

const HelpCenterComponent = (props) => {
  const inputRefs = React.useRef([React.createRef(), React.createRef()]);
  // const [values, setValues] = React.useState({});
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [textAreaEmpty, setTextAreaEmpty] = React.useState(false);
  const [subjectAreaEmpty, setSubjectAreaEmpty] = React.useState(false);
  const [subject, setSubject] = React.useState("");
  const [description, setDescription] = React.useState("");
  const customeSnackbarAnchor = {
    vertical: "top",
    horizontal: "center",
  };
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    props.pageHeading("Help Center");
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const handleInputChange = (e) => {
    if (e.target.value) {
      setSubject(e.target.value);
      setSubjectAreaEmpty(false);
    }
  };

  const handleInputChangeTextarea = (e) => {
    // if (e.target.value) {
      setDescription(e.target.value);
      setTextAreaEmpty(false);
    // }
  };

  const onSubmit = () => {
    if (!loading) {
      let isValid = true;
      for (let i = 0; i < inputRefs.current.length; i++) {
        if (inputRefs.current[i].current === null) {
          continue;
        }
        let text = document.getElementById("help-center-text-body").value;
        let subject = document.getElementById("lNameId").value;
        if (text === "") {
          setTextAreaEmpty(true);
          isValid = false;
        }
        if (subject === "") {
          setSubjectAreaEmpty(true);
          isValid = false;
        }
      }
      if (isValid === true) {
        setLoading(true);
        sendHelpCenter({ subject: subject, description: description });
      }
    }
  };

  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  const sendHelpCenter = (val) => {
    if (val.subject && val.description) {
      apiAbmBlaze
        .post(HELP_CENTER_API, { subject: val.subject, help: val.description })
        .then((res) => {
          if (res.data) {
            if (res.status === 200) {
              setOpenSnack(true);
              setSnackMsg("Your message has been sent to Customer Service");
              setSnackSeverity("success");
              document.getElementById("help-center-text-body").value = "";
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          console.log("HELPCENTER SEND MAIL ERR:", error);
          setOpenSnack(true);
          setSnackMsg("Something went wrong. Try again later");
          setSnackSeverity("error");
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />

      <div className="helpCenterBackground">
        <div className="subjectHeading fnt24P">Subject</div>

        <div className="flex flex-wrap ">
          <div className="w-full lg:w-full subjectInputField">
            <input
              id="lNameId"
              type="text"
              className="subjectInputName"
              onChange={handleInputChange}
              ref={inputRefs.current[0]}
              maxLength={50}
              name="subject"
              //value={subject}
            ></input>

            {subjectAreaEmpty ? (
              <div className="subjectValidation"> subject required</div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="w-full lg:w-full">
            <div className="howCanWeHelp fnt24P">How can we help?</div>
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="w-full lg:w-full">
            <div className="helpCenterTextArea">
              <textarea
                id="help-center-text-body"
                type="text"
                className="textAreaHelpCenter"
                onChange={handleInputChangeTextarea}
                ref={inputRefs.current[1]}
                //  value={description}
                style={{
                  resize:'none',
                }}
                maxLength={300}
              ></textarea><span style={{float:'right'}}>{description?.length}/300</span>
            </div>
            {textAreaEmpty ? (
              <div className="textAreaVlidation"> description required</div>
            ) : null}
          </div>
        </div>

        <div className="submitSegment">
          <button className="submitSection" onClick={onSubmit}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  width: "50px",
                  height: "40px",
                  marginLeft: "80px",
                  marginTop: "-7px",
                }}
              >
                <LoadingIcon />
              </Box>
            ) : (
              " Submit"
            )}
          </button>
        </div>

        <div style={{ paddingBottom: "80px" }} className="AbmContainer">
          <div className="flex flex-wrap bottomContainer">
            <div className="w-4/12 lg:w-4/12 ABMLogocontainer">
              <img alt="logo" src={ABMLogo} className="ABMLogo" />
            </div>

            <div className="w-8/12 lg:8/12 ABMWordingsContainer">
              <div className="bottomContainerHeading fnt32P">
                ABM Respiratory Care
              </div>

              <div className="bottomSectionContainer1">
                <div className="EmailIcon">
                  <img alt="email" src={EmailIcon} width="20px" height="16px" />
                </div>
                <div className="customservice fnt24P">
                support.arc.connect@abmrc.com
                </div>
              </div>

              <div className="bottomSectionContainer1">
                <div className="websiteIcon">
                  <img src={WebsiteIcon} alt="icon" />
                </div>
                <div className="customservice fnt24P">
                  <a href=" https://abmrc.com/" className="link_help">
                    {" "}
                    https://abmrc.com/
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (store) => {
  return {};
};
export default connect(mapStateToProps, {
  pageHeading,
})(HelpCenterComponent);
