import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userReducer } from "./redux/user/user.reducer";
import { invitation } from "./redux/invitation/components/invitation.reducer";
import { notificationReducer } from "./redux/notification/notification.reducer";
import { registrationReducer } from "./redux/registration/registration.reducer";
import { sharingReducer } from "./redux/sharing/sharing.reducer";
import { quickbloxReducer } from "./redux/quickbloxmessaging/quickblox.reducer";
import { therapyGoalsReducer } from "./redux/therapyGoals/therapyGoals.reducer";
import { caresiteReducer } from "./redux/careSite/caresite.reducer";
//import list from "./constant/list";
import { deviceReducer } from "./redux/devices/devices.reducer";
const reducer = combineReducers({
  user: userReducer,
  invitation: invitation,
  notification: notificationReducer,
  registration: registrationReducer,
  sharing: sharingReducer,
  therapyGoals: therapyGoalsReducer,
  quickblox: quickbloxReducer,
  caresite: caresiteReducer,
  devices: deviceReducer,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
